var i18n={
    en:{
        overtime_1:`Error code: `,
        overtime_2:`, plesase retry it`,
        isnetwork:`Internet error. Please check and retry`,
        email_placeholder:`Email`,
        email_empty:`Please input email`,
        email_not_valid:`Email is not valid`,
        email_not_valid_1:`Please input your email`,
        email_not_valid_2:`Email is not valid, please use a different address.`,
        email_not_valid_3:`No email input`,
        password_placeholder:`Password`,
        password_empty:`Please input password`,
        password_not_valid:`Incorrect account or password`,
        password_not_valid_1:`Password above 8 characters is required`,
        password_not_valid_2:`Please create a password`,
        password_placeholder_1:`Create your password`,
        password_placeholder_2:`Confirm your Password`,
        password_placeholder_3:`Create new password`,
        password_placeholder_4:`Confirm new password`,
        password_placeholder_5:`Input old password`,
        copy_password_empty:`Please confirm password`,
        copy_password_not_valid:`Please confirm your password`,
        copy_passwords_inconsistent:`Your password confirmation does not match`,
        code_empty:`Please input verification code`,
        code_not_valid:`Invalid verification code`,
        code_placeholder:`Verification Code`,
        not_received_code:`If your mailbox has not received the verification code for a long time, please get the verification code again.`,
        get_first_code:`Please get the verification code first.`,
        connect_your_email_placeholder:`Connect your email`,
        last_name_placeholder:`Please enter your last name`,
        first_name_placeholder:`Please enter your first name`,
        address_placeholder:`Please enter your address`,
        no_code_text:`We've sent a verification code. Please enter your code. <span class='tips'>Didn't receive a code?`,
        no_code_text_1:`1. Please make sure the email address is valid and it can receive emails.`,
        no_code_text_2:`2. For the email is sent by system automatically, it may be flagged as the spam or junk email. Please check whether the email is in the Trash folder.`,
        no_code_text_3:`3. Cannot solve your problem? `,
        no_code_text_3_span:`Then click here to contact us.`,
        order_no:`You have not purchased any product, <a href="https://www.aiseesoft.com/store/">shop now</a>! If you have any question, please <a href="https://www.aiseesoft.com/contact.html">contact us</a>.`,
        error_24901:`Current account has no email linked, and cannot find the orders. Please connect an email.`,
        user_guide:`User Guide>>`,
        download:`Download`,
        order_number:`Order Number:`,
        Refund:`Refund`,
        Disabled:`Disabled`,
        Normal:`Normal`,
        Modify:`Modify`,
        Modify_1:`Modify>>`,
        Connect:`Connect`,
        unlink_success:`Unlink successfully`,
        connect_success:`Connect successfully`,
        feedback_title:`Thanks for your feedback. Please leave your problem and we will respond to you within 24 hours.`,
        feedback_thank_you:`Thank You!<br />Your Feedback has been submitted successfully.`,
        feedback_email:`Enter your email here!`,
        feedback_content:`Leave any problem or suggestion you encountered here.`,
        feedback_submit:`Submit`,
        form_contents:`You have not entered any description. Please enter it and submit again.`,
        old_password:`Please input old password`,
        new_password:`Please create a new password`,
        old_new_password:`The new password cannot be the same as the old one`,
        incorrect_password:`Incorrect password`,
        delete_no:`Delete Now`,
        Caps:`Caps lock is on`,
        Get:`Get`,
        Done:`Done`,
        error_20001:`Error: 20001. Please log in again.`,
        error_20002:`Error: 20002. Please log in again.`,
        error_20003:`Error: 20003. Please log in again.`,
        error_20004:`Request failed (error code: 20004). Please try it again.`,
        error_20005:`Login session has expired (Error: 20005). Please log in again.`,
        error_20006:`Request failed (error code: 20006). Please try it again.`,
        error_20007:`Login session has expired (Error: 20007). Please log in again.`,
        error_20008:`Login session has expired (Error: 20008). Please log in again.`,
        error_20009:`Login session has expired (Error: 20009). Please log in again.`,
        error_20101:`Please input your email (error code: 20101)`,
        error_20102:`Email is not valid (error code: 20102)`,
        error_20103:`Request failed (error code: 20103). Please try it again`,
        error_20104:`Email is already in use, please <a href="https://account.aiseesoft.com/login">log in</a> or register with a new one`,
        error_20105:`Request failed (error code: 20105). Please try it again`,
        error_20106:`Failed to send email, please retry it`,
        error_20201:`Please input your email (error code: 20201)`,
        error_20202:`Please input your password (error code: 20202)`,
        error_20203:`Please input verification code (error code: 20203)`,
        error_20204:`Email is not valid (error code: 20204)`,
        error_20205:`Password above 8 characters is required (error code: 20205)`,
        error_20206:`Request failed (error code: 20206). Please try it again`,
        error_20207:`Invalid verification code`,
        error_20208:`Request failed (error code: 20208). Please try it again`,
        error_20209:`Request failed (error code: 20209). Please try it again`,
        error_20301:`Please input your email (error code: 20301)`,
        error_20302:`Error: 20302. Please contact us`,
        error_20303:`Email is not valid (error code: 20303)`,
        error_20304:`Request failed (error code: 20304). Please try it again`,
        error_20305:`Account does not exist. Please re-enter or <a href="https://account.aiseesoft.com/register">Create it</a> first.`,
        error_20306:`No password yet. Use <a href="https://account.aiseesoft.com/passwordless-login">Passwordless login</a> or <a href="create-password">set a password</a> and log in.`,
        error_20308:`Request failed (error code: 20308). Please try it again`,
        error_20401:`Failed to log out (error code: 20401). Please try it again`,
        error_20501:`Please input your email (error code: 20501)`,
        error_20502:`Email is not valid (error code: 20502)`,
        error_20503:`Request failed (error code: 20503). Please try it again`,
        error_20504:`Failed to send email. Please try it again.`,
        error_20601:`Please input your email (error code: 20601)`,
        error_20602:`Please input verification code (error code: 20602)`,
        error_20603:`Email is not valid (error code: 20603)`,
        error_20604:`Request failed (error code: 20604). Please try it again`,
        error_20606:`Request failed (error code: 20606). Please try it again`,
        error_20607:`Request failed (error code: 20607). Please try it again`,
        error_20608:`Request failed (error code: 20608). Please try it again`,
        error_20701:`Please input your email (error code: 20701)`,
        error_20702:`Email is not valid (error code: 20702)`,
        error_20703:`Request failed (error code: 20703). Please try it again`,
        error_20704:`Account does not exist. Please re-enter or <a href="https://account.aiseesoft.com/register">Create it</a> first.`,
        error_20705:`Request failed (error code: 20705). Please try it again`,
        error_20706:`Failed to send email. Please try it again`,
        error_20801:`Please input your email (error code: 20801)`,
        error_20802:`Error: 20802. Please contact us`,
        error_20803:`Please input verification code (error code: 20803)`,
        error_20804:`Email is not valid (error code: 20804)`,
        error_20805:`Password above 8 characters is required (error code: 20805)`,
        error_20806:`Request failed (error code: 20806). Please try it again`,
        error_20808:`Request failed (error code: 20808). Please try it again`,
        error_20901:`Request failed (error code: 20901). Please try it again`,
        error_20902:`Request failed (error code: 20902). Please try it again`,
        error_21000:`Changes are saved`,
        error_21001:`No information is submitted (error code: 21001)`,
        error_21002:`Request failed (error code: 21002). Please try it again`,
        error_21101:`Please input your email (error code: 21101)`,
        error_21102:`Email is not valid (error code: 21102)`,
        error_21103:`Request failed (error code: 21103), plesase retry it again`,
        error_21104:`Email is already connected, please use a new one`,
        error_21105:`Request failed (error code: 21105), plesase retry it again`,
        error_21106:`Failed to send email. Please try it again`,
        error_21201:`Please input your email (error code: 21201)`,
        error_21202:`Please input verification code (error code: 21202)`,
        error_21203:`Email is not valid (error code: 21203)`,
        error_21204:`Error: 21204. Please contact us`,
        error_21205:`Error: 21205. Please contact us`,
        error_21206:`Password above 8 characters is required (error code: 21206)`,
        error_21207:`Request failed (error code: 21207). Please try it again`,
        error_21209:`Request failed (error code: 21209). Please try it again`,
        error_21301:`Please input old password (error code: 21301)`,
        error_21302:`Please create a new password (error code: 21302)`,
        error_21303:`The new password cannot be the same as the old one. (Error: 21303)`,
        error_21304:`Password above 8 characters is required (error code: 21304)`,
        error_21306:`Request failed (error code: 21306). Please try it again`,
        error_21402:`Request failed (error code: 21402). Please try it again`,
        error_21403:`Failed to send verification code. Please resend it`,
        error_21500:`The account has been deleted`,
        error_21501:`Please input verification code (error code: 21501)`,
        error_21502:`Login session has expired (Error: 21502). Please log in again.`,
        error_21503:`Request failed (error code: 21503). Please try it again`,
        error_21505:`Request failed (error code: 21505), plesase retry it`,
        error_21601:`Error: 20601. Please contact us`,
        error_21602:`Invalid Verification (Error: 20602). Please try it again.`,
        error_21603:`Error: 20603. Please try it again`,
        error_21604:`Request failed (error code: 21604). Please try it again`,
        error_21606:`Request failed (error code: 21606). Please try it again`,
        error_21611:`Request failed (error code: 21611). Please try it again`,
        error_21801:`Error: 21801. Please contact us`,
        error_21802:`Request failed (Error: 21802). Please try it again`,
        error_21803:`Error: 21803. Please try it again`,
        error_21804:`Request failed (error code: 21804). Please try it again`,
        error_21806:`Error: 21806. Please try it again`,
        error_21807:`Error: 21807. Please contact us`,
        error_21808:`Error: 21808. Please contact us`,
        error_21809:`Error: 21809. Please contact us`,
        error_21810:`Error: 21810. Please contact us`,
        error_21811:`Error: 21811. Please contact us`,
        error_21812:`Error: 21812. Please contact us`,
        error_21813:`Request failed (error code: 21813). Please try it again`,
        error_21814:`Error: 21814. Please contact us`,
        error_21815:`Request failed (error code: 21815). Please try it again`,
        error_21816:`Error: 21816. Please contact us`,
        error_21817:`Error: 21817. Please contact us`,
        error_21818:`Error: 21818. Please contact us`,
        error_21819:`Request failed (error code: 21819). Please try it again`,
        error_21820:`Error: 21820. Please contact us`,
        error_21821:`Error: 21821. Please contact us`,
        error_21822:`Error: 21822. Please contact us`,
        error_21823:`Request failed (error code: 21823). Please try it again`,
        error_21824:`Request failed (error code: 21824). Please try it again`,
        error_21825:`Request failed (error code: 21825). Please try it again`,
        error_21826:`Request failed (error code: 21826). Please try it again`,
        error_21828:`Error: 21828. Please contact us`,
        error_21901:`Error: 21901. Please contact us`,
        error_21902:`Request failed (error code: 21902). Please try it again`,
        error_21903:`The account status has changed (error code: 21903), please refresh the page and try again`,
        error_21904:`Error: 21904. Please try it again`,
        error_21905:`Error: 21905. Please try it again`,
        error_21906:`Request failed (error code: 21906). Please try it again`,
        error_21907:`Google account has been linked to other account`,
        error_21908:`Request failed (error code: 21908). Please try it again`,
        error_22001:`Request failed (error code: 22001). Please try it again`,
        error_22002:`Unlink failed with no additional login`,
        error_22003:`Request failed (error code: 22003). Please try it again`,
        error_22101:`Error: 22101. Please contact us`,
        error_22102:`The account status has changed (error code: 22102), please refresh the page and try again`,
        error_22103:`Request failed (error code: 22103). Please try it again`,
        error_22104:`The account status has changed (error code: 22104), please refresh the page and try again`,
        error_22105:`Error: 22105. Please try it again`,
        error_22106:`Error: 22106. Please try it again`,
        error_22107:`Error: 22107. Please contact us`,
        error_22108:`Request failed (error code: 22108). Please try it again`,
        error_22201:`Error: 22201. Please contact us`,
        error_22202:`Invalid Verification (Error: 22202). Please try it again.`,
        error_22203:`Error: 22203. Please try it again"`,
        error_22204:`Request failed (error code: 22204). Please try it again`,
        error_22206:`Request failed (error code: 22206). Please try it again`,
        error_22401:`Error: 22401. Please contact us`,
        error_22402:`Invalid Verification (Error: 22402). Please try it again.`,
        error_22403:`Error: 22403. Please try it again`,
        error_22404:`Request failed (error code: 22404). Please try it again`,
        error_22405:`Facebook account has been linked to other email`,
        error_22406:`Error: 22406. Please try it again`,
        error_22407:`Error: 22407. Please contact us`,
        error_22408:`Error: 22408. Please contact us`,
        error_22409:`Error: 22409. Please contact us`,
        error_22410:`Error: 224010. Please contact us`,
        error_22411:`Error: 224011. Please contact us`,
        error_22412:`Error: 224012. Please contact us`,
        error_22413:`Error: 22413. Please contact us`,
        error_22414:`Request failed (error code: 22414). Please try it again`,
        error_22415:`Error: 22415. Please contact us`,
        error_22416:`Error: 22416. Please contact us`,
        error_22417:`Error: 22417. Please contact us`,
        error_22418:`Request failed (error code: 22418). Please try it again`,
        error_22419:`Error: 22419. Please contact us`,
        error_22420:`Error: 22420. Please contact us`,
        error_22421:`Error: 22421. Please contact us`,
        error_22422:`Request failed (error code: 22422). Please try it again`,
        error_22423:`Request failed (error code: 22423). Please try it again`,
        error_22424:`Request failed (error code: 22424). Please try it again`,
        error_22425:`Request failed (error code: 22425). Please try it again`,
        error_20098:`Error code: 20098. If you are browsing in Private mode, please switch to Normal mode and try again.`,
        error_22298:`Request for Google failed (Error: 22298). Please try it again.`,
        error_22498:`Request for Facebook failed (Error: 22498). Please try it again.`,
        error_24902:`Request failed (error code: 24902). Please try it again`,
        error_24903:`Request failed (error code: 24903). Please try it again`,
        error_24904:`Request failed (error code: 24904). Please try it again`,
        error_24905:`Request failed (error code: 24905). Please try it again`,
        login_title:`Log in to Aiseesoft`,
        password_placeholder:`Password`,
        log_in:`Log In`,
        no_account:`No account?`,
        create_it:`Create it`,
        or_log_in_with:`Or log in with`,
        passwordless_login:`Passwordless login`,
        log_in_done:`Log In Done`,
        three_rd_account_connect_info:`Congratulations! You have logged in successfully. Now you can connect one email account that is used to log in in the future.`,
        connect_now:`Connect now`,
        see_my_account:`See my account`,
        three_rd_login_merge_account:`The third-party account email address has been signed up, do you want to connect and log in with this email address directly?`,
        connect_log_in:`Connect & Log In`,
        create_an_account:`Create an account`,
        connect_account:`Connect Account`,
        connect_account_info:`After connecting, you can log in with this account.`,
        back_to_log_in:`Back to log in`,
        create_password:`Create Password`,
        create_now:`Create Now`,
        password_login_subtitle:`Passwordless login with email`,
        account_login:`Account Login`,
        rights:`By creating this account, you agree to <a href="https://www.aiseesoft.com/company/terms-and-conditions.html">Terms of Service</a> and <a href="https://www.aiseesoft.com/privacy-policy.html">Privacy Policy</a>`,
        passwordless_login_done:`Passwordless login done`,
        passwordless_login_info:`Congratulations, you have completed passwordless login successfully. You can create a password for this account and log in with the account and password in the future. <a href="/create-password" style="display: initial;">Create Now</a>`,
        sign_up:`Sign Up`,
        register_info:`Create your account`,
        reset_now:`Reset Now`,
        forgot_password:`Forgot Password`,
        reset_password_subtitle:`Use your account email to reset password`,
        plan_products:`Plans & Products`,
        nick_name:`Username:`,
        email:`Email:`,
        my_products:`My Products`,
        my_orders:`My Orders`,
        unlink:`Unlink`,
        link:`Link`,
        connected_accounts:`Connected accounts`,
        last_name:`Last name:`,
        first_name:`First name:`,
        Gender:`Gender:`,
        Birth:`Birth:`,
        Month:`Month`,
        Year:`Year`,
        Country_Region:`Country/Region:`,
        Address:`Address:`,
        Save:`Save`,
        Date:`Date`,
        Male:`Male`,
        Female:`Female`,
        Unspecified:`Unspecified`,
        Security:`Security`,
        change_password:`Change Password`,
        change_now:`Change Now`,
        connect_email:`Connect email`,
        delete_account:`Delete Account`,
        delete_account_info:`When your account is deleted, all the data in Plan & Products associated with your account will be permanently deleted, and you might not be able to recover it. We recommend you tread warily.`,
        Delete:`Delete`,
        Logout:`Logout`,
        my_profile:`My Profile`,
        guides_faqs:`Guides & FAQs`,
        More:`More`,
        guides:`Guides`,
        register:`register`,
        hot_faq:`Hot FAQ`,
        Contents:`Contents:`,
        contact_us:`Contact us>>`,
        plan:`Plan`,
        unregistered:`Unregistered`,
        buy_more:`Purchase More`,
        buy_again:`Buy Again`,
        buy_now:`Buy Now`,
        free_no_limit:`Free & No limit`,
        expired:`Expired`,
        lifetime:`Lifetime`,
        remain:`Remain`,
        day_s:`Day(s)`,
        error_24801:`Request failed (error code: 24801). Please try it again`,
        no_app_found:`No app found！<a href="/">Refresh</a> or <a href="https://www.aiseesoft.com/">Go to the official website</a>`,
        get_more:`Get More >>`,
        edit_photo:`Edit Photo`,
        select_photo:`Select photo`,
        change_photo:`Change photo`,
        cancel:`Cancel`,
        hide_password:`Hide password`,
        show_password:`Show password`,
        zoom_in:`Zoom in`,
        zoom_out:`Zoom out`,
        rotate:`Rotate`,
        horizontal_flip:`Horizontal flip`,
        vertical_flip:`Vertical flip`,
        country:`Country`,
        country_1:`Select your country/region`,
        country_2:`Åaland Island`,
        country_3:`Afghanistan`,
        country_4:`Albania`,
        country_5:`Algeria`,
        country_6:`American Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarctica`,
        country_11:`Antigua & Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaijan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Belarus`,
        country_22:`Belgium`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia & Herzegovina`,
        country_29:`Botswana`,
        country_30:`Bouvet Island`,
        country_31:`Brazil`,
        country_32:`British Indian Ocean Territory`,
        country_33:`British Virgin Islands`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodia`,
        country_39:`Cameroon`,
        country_40:`Canada`,
        country_41:`Cape Verde`,
        country_42:`Caribbean Netherlands`,
        country_43:`Cayman Islands`,
        country_44:`Central African Republic`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`China`,
        country_48:`Christmas Island`,
        country_49:`Cocos (Keeling) Islands`,
        country_50:`Colombia`,
        country_51:`Cook Islands`,
        country_52:`Costa Rica`,
        country_53:`Côte d'Ivoire`,
        country_54:`Croatia`,
        country_55:`Cuba`,
        country_56:`Cyprus`,
        country_57:`Czech Republic`,
        country_58:`Democratic Republic of the Congo`,
        country_59:`Denmark`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominican Republic`,
        country_63:`Ecuador`,
        country_64:`Egypt`,
        country_65:`El Salvador`,
        country_66:`Equatorial Guinea`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Ethiopia`,
        country_70:`Falkland Islands`,
        country_71:`Faroe Islands`,
        country_72:`Federated States of Micronesia`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`France`,
        country_76:`French Guiana`,
        country_77:`French polynesia`,
        country_78:`French Southern Territories`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Germany`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Great Britain (United Kingdom; England)`,
        country_86:`Greece`,
        country_87:`Greenland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island and McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hungary`,
        country_101:`Iceland`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Iraq`,
        country_106:`Ireland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italy`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kazakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kyrgyzstan`,
        country_119:`Laos`,
        country_120:`Latvia`,
        country_121:`Lebanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libya`,
        country_125:`Liechtenstein`,
        country_126:`Lithuania`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldives`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshall islands`,
        country_136:`Martinique`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexico`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Morocco`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Netherlands`,
        country_153:`New Caledonia`,
        country_154:`New Zealand`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolk Island`,
        country_160:`North Korea`,
        country_161:`Northern Mariana Islands`,
        country_162:`Norway`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestinian territories`,
        country_167:`Panama`,
        country_168:`Papua New Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairn Islands`,
        country_172:`Poland`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republic of Macedonia (FYROM)`,
        country_177:`Republic of the Congo`,
        country_178:`Réunion`,
        country_179:`Romania`,
        country_180:`Russian Federation`,
        country_181:`Rwanda`,
        country_182:`Saint Barthélemy`,
        country_183:`Saint Martin (France)`,
        country_184:`Saint-Pierre and Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome & Principe`,
        country_188:`Saudi Arabia`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakia`,
        country_195:`Slovenia`,
        country_196:`Solomon Islands`,
        country_197:`Somalia`,
        country_198:`South Africa`,
        country_199:`South Georgia and the South Sandwich Isla`,
        country_200:`South Korea`,
        country_201:`South Sudan`,
        country_202:`Spain`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Dependencies`,
        country_205:`St. Kitts & Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent & the Grenadines`,
        country_208:`Sudan`,
        country_209:`Suriname`,
        country_210:`Swaziland`,
        country_211:`Sweden`,
        country_212:`Switzerland`,
        country_213:`Syria`,
        country_214:`Taiwan`,
        country_215:`Tajikistan`,
        country_216:`Tanzania`,
        country_217:`Template:Country data SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`The Bahamas`,
        country_220:`The Comoros`,
        country_221:`The Philippines`,
        country_222:`Timor-Leste (East Timor)`,
        country_223:`Togo`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunisia`,
        country_228:`Turkey`,
        country_229:`Turkmenistan`,
        country_230:`Turks & Caicos Islands`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraine`,
        country_234:`United Arab Emirates`,
        country_235:`United States Minor Outlying Islands`,
        country_236:`United States of America (USA)`,
        country_237:`United States Virgin Islands`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatican City (The Holy See)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis and Futuna`,
        country_245:`Western Sahara`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Sign in with Google`,
        State:`State`,
        Activation_code:`Activation code`,
        Question:`List all the apps you have logged in`,
        Copy_complete:`Copy complete`,
        footer:`Copyright © 2024 Aiseesoft Studio. All rights reserved.`,
        store:`Store`,
        resource:`Resource`,
        support:`support`,
        video_converter:`Video Converter`,
        multimedia:`Multimedia`,
        data_recovery:`Data Recovery`,
        utility:`Utility`,
        free_tools:`Free Tools`,
        for_windows:`For Windows`,
        for_mac:`For Mac`,
        pdf_solutions:`PDF Solutions`,
        data_manager:`Data Manager`,
        other_tools:`Other Tools`,
        free_online_solutions:`Free Online Solutions`,
        free_online_tools:`Free Online Tools`,
        desktop_freeware:`Desktop Freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS Converter`,
        product_4:`4K Converter`,
        product_5:`MXF Converter`,
        product_6:`3D Converter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter for Mac`,
        product_9:`MTS Converter for Mac`,
        product_10:`4K Converter for Mac`,
        product_11:`MXF Converter for Mac`,
        product_12:`3D Converter for Mac`,
        product_13:`Blu-ray Player`,
        product_14:`Video Enhancer`,
        product_15:`Video Editor`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray Player`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video Editor for Mac`,
        product_23:`DVD Creator for Mac`,
        product_24:`MP4 Converter for Mac`,
        product_25:`Windows Data Recovery`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab iOS System Recovery`,
        product_28:`FoneLab iOS Data Backup &amp; Restore`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Broken Android Data Extraction`,
        product_31:`Android Data Backup &amp; Restore`,
        product_32:`Mac Data Recovery`,
        product_33:`Mac FoneLab iPhone Data Recovery`,
        product_34:`Mac FoneLab iOS System Recovery`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Restore`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; Restore`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate for Mac`,
        product_40:`PDF Merger`,
        product_41:`Mac PDF to ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer for iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`PPT to Video Converter`,
        product_49:`HEIC Converter`,
        product_50:`iPhone Unlocker`,
        product_51:`Free Video Converter Online`,
        product_52:`Free Audio Converter Online`,
        product_53:`Free Video Compressor Online`,
        product_54:`Free Video Merger Online`,
        product_55:`Free HEIC Converter`,
        product_56:`Free Image Compressor`,
        product_57:`Free Watermark Remover`,
        product_58:`Free PDF Compressor Online`,
        product_59:`Free Image Upscaler Online`,
        product_60:`Free Background Remover Online`,
        product_61:`Free Image Converter Online`,
        product_62:`Free PDF PNG Converter Online`,
        product_63:`Free Video Converter`,
        product_64:`Free Video to GIF Converter`,
        product_65:`Free Android Data Recovery`,
        product_66:`Slideshow Maker`,
        product_67:`Free Video Editor`,
        product_68:`Free PDF Viewer`,
        product_69:`Screen Recorder`,
        product_70:`Mac Screen Recorder`,
        product_71:`Free Screen Recorder Online`,
        product_72:`Free Audio Recorder Online`,

        product_73:`Phone Mirror`,
        product_74:`AnyCoord - Location Changer`,
        product_75:`iPhone Password Manager`,
        product_76:`Free Audio Compressor Online`,
        product_77:`Free Video Trimmer Online`,
        product_78:`Free Video Cropper Online`,
        product_79:`Free Video Rotator Online`,
        product_80:`Free Video Looper Online`,
        product_81:`Free PDF JPG Converter Online`,
        product_81:`Free JPG Word Converter Online`,

        category_1:`Photo Editing`,
        category_2:`Video Editing`,
        category_3:`Eraser`,
        category_4:`Media Recorder`,
        posted_by:`Posted by`,
        to:`to`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`How to Remove Text from Pictures Efficiently`,
        article_2:`How to Increase Image Size in KB to MB without Damaging Quality`,
        article_3:`How to Remove Getty Images Watermark on Windows and Mac`,
        article_4:`The Best Method to Convert Low-resolution Image to High-resolution Online for Free`,
        article_5:`TikTok Guide | Here’s How to Make a Video Collage on TikTok`,
        article_6:`5 Easy Ways to Combine VOB Files You Should Know`,
        article_7:`How to Upscale Video and Get Higher Resolution Easily`,
        article_8:`How to Combine MP4 Files on MacBook Pro/iMac/MacBook and More`,
        article_9:`2 Simple Ways to Delete Purchase History on iPhone [Solved]`,
        article_10:`How to Delete Snapchat Pictures Permanently from Chat and Memories`,
        article_11:`How to Delete Spotlight Search Suggestions and History on iPhone/iPad`,
        article_12:`[Solved] How to Clear Instagram Cache on iPhone with 3 Easy Ways`,
        article_13:`[Solved] How to Fix QuickTime Player Not Working on Mac`,
        article_14:`How to Record System Audio on Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Effective Ways to Fix PowerPoint Screen Recording Not Working`,
        article_16:`How to Record Audio with VLC [Solved]`,
        faq_title_1:`1. How to fix 4104 error?`,
        faq_title_2:`2. When can I get my money back after the refund request is approved?`,
        faq_title_3:`3. What is "1 Year Subscription" and how to turn off the "Automatic Renewal Service"?`,
        faq_title_4:`4. Can I cancel my order after placing Aiseesoft product?`,
        faq_title_5:`5. How to submit a review to you?`,
        faq_info_1:`This is due to the limited License you got. We adopt Single License in case the registration code stolen and prevent the violation use of the registration code. So Reg Code you got is Single License which can only be used on one computer. If you use it on more than one computer, there will be Error 4104. You cannot use it until we reset it for you.`,
        faq_info_2:`If you change or reinstall your computer, we can reset it for you. Please contact the customer service: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> to request reset the registration code for you.`,
        faq_info_3:`Once your refund request is approved, normally it will take 3~7 business days for the credit to appear on your account. Sometimes during holidays, it may take longer but it is not expected to take over 10 days. If you have not got the refund 20 days after the approval, please contact us at <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> to check the refund.`,
        faq_info_4:`Automatic Renewal Service means that if you buy a 1 Year Subscription, the payment platform will charge the amount automatically (including applicable taxes) when the product license expires. But the payment flatform (Swreg or 2Checkout) will send you a letter to remind you that the order is going to be expired 7 days before the expiration. The e-mail will also include instructions of how to turn off the "Automatic Renewal Service".`,
        faq_info_5:`Since the order is processed automatically, it is not allowed to cancel the order. Besides, the free trial version will enable you to know more about Aiseesoft program and check whether it can meet your needs before purchasing it.`,
        faq_info_6:`If you really want to get a refund, please check our <a href="https://www.aiseesoft.com/refund-policy.html">Refund Policy</a>.`,
        faq_info_7:`We appreciate your time and attention to submit a review to us. Contact us (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) to submit your review and we will seriously consider your review for our product updates.`,
        change_password_success:`Password changed successfully`,
        successful_login_title:`Login Success`,
        product_page:`Product Page>>`,
        successful_login_info:`Login completed. Please close the current page and go back to the original tab to continue with the following process. The current page will be closed automatically in 5 seconds. If auto-closing or closing by clicking the "Done" button fails, please close this tab directly.`,
        successful_login_info_firefox:`Login completed. Please close the current page and go back to the original tab to continue with the following process.`,
        my_account:`My Account`,
        my_history:`My History`,
        remove_watermark:`Remove Watermark`,
        no_history:`No History`,
        history_all:`Select all`,
        history_open:`Open`,
        history_down:`Download`,
        history_delete:`Delete`,
        history_clear:`Clear invalid`,
        images:`image(s)`,
        use_this_function:`Use This Product>>`,
        hd_downloading:`HD original images downloading benefits:`,
        lifetimeRemaining:`Lifetime-Remaining`,
        Remaining:`Remaining`,
        email_verification:`Email Verification`,
        email_verification_info:`We have sent the verification message to your email <span class="email"></span>, and please finish the verification. After verifying, the benefits will be in sync automatically.`,
        wrong_email:`Wrong email address?`,
        click_here_to_modify:`Click here to modify`,
        get_more_help:`Get more help?`,
        click_here:`Click here`,
        email_verification_info_success:`Congratulations for verifying your email account.`,
        email_verification_info_error:`Verification failed because of the expired link.`,
        registration_succeeded:`Registration Succeeded`,
        registration_succeeded_info_1:`Congratulations! You have registered successfully. We have sent the verification message to your email <span class="email"></span>, and please finish verifying to use the benefits of this email.`,
        registration_succeeded_info_2:`Click "Done" to return to the Home page and use this product.`,
        registration_succeeded_info_3:`Click "Done" to close the current page and return to the Home page for the following operations. If closing fails, please manually close the tab.`,
        verify_email:`Verify Email`,
        registered_email_not_verified:`The registered email has not been verified, please verify it at once.`,
        email_verification_time_1:`Didn't receive a verification email?`,
        email_verification_time_2:`After`,
        email_verification_time_3:`Click here to resend it`,
        error_26301:`Error code: 26301, please contact us`,
        error_26302:`Error code: 26302, please contact us`,
        error_26303:`Email format error (error code: 26303). Please enter it again`,
        error_26304:`Password above 8 characters is recommended (error code: 26304)`,
        error_26305:`Reuqest failed (error code: 26305). Please retry it`,
        error_26306:`Email has been registered, please <a href="https://account.aiseesoft.com/login">go to log in</a>`,
        error_26307:`Reuqest failed (error code: 26307). Please retry it`,
        error_26308:`Reuqest failed (error code: 26308). Please retry it`,
        error_26401:`Error code: 26401, please retry it`,
        error_26402:`Email has been verified (error code: 26402), please retry it`,
        error_26403:`Reuqest failed (error code: 26403). Please retry it`,
        error_26404:`Request failed (error code: 26404). Please retry it`,
        error_26501:`Error code: 26501, please contact us`,
        error_26502:`Error code: 26502, please contact us`,
        error_26503:`Email format error (error code: 26503). Please enter it again`,
        error_26504:`Reuqest failed (error code: 26504). Please retry it`,
        error_26505:`Email has not been registered, please <a href="https://account.aiseesoft.com/register">register it first</a>`,
        error_26506:`Email has been verified.`,
        error_26507:`Reuqest failed (error code: 26507). Please retry it`,
        error_26508:`Verification fialed (error code: 26508), please retry it`,
        error_26509:`Request failed (error code: 26509), please retry it`,
        error_26510:`Error code: 26510, please contact us`,
        error_26601:`Error code: 26601, please contact us`,
        error_26602:`Error code: 26602, please contact us`,
        error_26603:`Reuqest failed (error code: 26603). Please retry it`,
        error_26604:`Error code: 26604, please contact us`,
        error_26605:`Error code: 26605, please contact us`,
        error_26701:`Error code: 26701, please contact us`,
        error_26702:`Reuqest failed (error code: 26702). Please retry it`,
        error_26703:`Error code: 26703, please contact us`,
        error_26704:`Error code: 26704, please contact us`,
        error_26705:`Wait for logging in (error code: 26705). Please retry it`,
        no_cookie:`You have turned on the Block all cookies function in your browser, so you cannot log in. Please go to Settings to check the box of Allow all cookies.`,
        error_26801:`Error code: 26801, please contact us`,
        error_26802:`Error code: 26802, please contact us`,
        error_26803:`Request failed (error code: 26803). Please retry it`,
        error_26804:`Request failed (error code: 26804). Please retry it`,
        error_order:`Request failed (error code: 27098), please try it again!`,
        error_order1:`The order query is incomplete（error code: `,
        error_order2:`）, please refresh and try it again.`,
        modify_email_title:`Modify Email`,
        modify_email_info:`You can use the modified email to log into your account.`,
        images_per:`Images per`,
        error_26101:`Error: 26101. Please contact us`,
        error_26102:`Error: 26102. Please contact us`,
        error_26103:`Request failed (error code:26103). Please try it again`,
        error_26104:`Error code: 26104, plesase retry it`,
        error_26105:`Error code: 26105, plesase retry it`,
        error_26106:`Delete failed (error code: 26106). Please try it again`,
        error_26201:`Error: 26201. Please contact us`,
        error_26202:`Request failed (error code:26202). Please try it again`,
        error_26001:`Error: 26001. Please contact us`,
        error_26002:`Error: 26002. Please contact us`,
        error_26003:`Error: 26003. Please contact us`,
        error_26004:`Error: 26004. Please contact us`,
        error_26005:`Request failed (error code:26005). Please try it again`,
        error_26006:`Error code: 26006, plesase retry it`,
        error_26008:`Error: 26008. Please contact us`,
        go_to_the_home_page:`Go to the Home page`,

        error_27101:`Request failed (error code: 27101). Please retry ist`,
        error_27201:`Error code: 27201, please contact us`,
        error_27202:`Error code: 27202, please retry it`,
        error_27203:`Request failed (error code: 27203). Please retry it`,
        error_27204:`Invalid code (error code: 27204).`,
        error_27205:`Request failed (error code: 27205). Please retry it`,
        error_27206:`Request failed (error code: 27206). Please retry it`,
        error_27207:`Request failed (error code: 27207). Please retry it`,
        no_history_found:`You haven't used any tool! <a href="/">Refresh</a> or <a href="https://www.aiseesoft.com/">Go to the official website</a>`,

        error_25301:`Error: 25301. Please contact us`,
        error_25302:`Error: 25302. Please contact us`,
        error_25303:`Request failed (error code: 25303). Please try it again`,
        error_25304:`Request failed (error code: 25304). Please try it again`,
        error_25305:`Request failed (error code: 25305). Please try it again`,
        error_25306:`Request failed (error code: 25306). Please try it again`,
        image_upscaler_p:`Image without watermark downloading benefits:`,
        Available_for:`Available for:`,
        credit_per:`%s credit(s) per HD image`,
        still_valid:`Purchased plan(s) is still valid`,
        credit:`credit(s)`,
        pc_3rd_info:`Login successfully. Please go to the application for further operation.`,
        use_online:`Use the online service`,
        use_download:`Use the desktop program`,
        use_immediately:`Use Immediately`,
        Use_in_browser:`Use in Browser`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    cs:{
        overtime_1:`Chybový kód:`,
        overtime_2:`, plesase, zkus to znovu`,
        isnetwork:`Chyba internetu. Zkontrolujte a zkuste to znovu`,
        email_placeholder:`E-mailem`,
        email_empty:`Zadejte prosím e -mail`,
        email_not_valid:`E -mail není platný`,
        email_not_valid_1:`Zadejte prosím svůj e -mail`,
        email_not_valid_2:`E -mail není platný, použijte prosím jinou adresu.`,
        email_not_valid_3:`Žádné zadávání e -mailů`,
        password_placeholder:`Heslo`,
        password_empty:`Zadejte heslo`,
        password_not_valid:`Nesprávný účet nebo heslo`,
        password_not_valid_1:`Je vyžadováno heslo nad 8 znaků`,
        password_not_valid_2:`Vytvořte si heslo`,
        password_placeholder_1:`Vytvořte vaše heslo`,
        password_placeholder_2:`Potvrzení hesla`,
        password_placeholder_3:`Vytvořte nové heslo`,
        password_placeholder_4:`Potvrďte nové heslo`,
        password_placeholder_5:`Zadejte staré heslo`,
        copy_password_empty:`Potvrďte prosím heslo`,
        copy_password_not_valid:`Potvrďte prosím své heslo`,
        copy_passwords_inconsistent:`Potvrzení vašeho hesla se neshoduje`,
        code_empty:`Zadejte prosím ověřovací kód`,
        code_not_valid:`neplatný ověřovací kód`,
        code_placeholder:`Ověřovací kód`,
        not_received_code:`Pokud vaše poštovní schránka delší dobu neobdržela ověřovací kód, získejte prosím ověřovací kód znovu.`,
        get_first_code:`Nejprve prosím získejte ověřovací kód.`,
        connect_your_email_placeholder:`Připojte svůj e -mail`,
        last_name_placeholder:`Prosím zadejte své příjmení`,
        first_name_placeholder:`Prosím zadej své křestní jméno`,
        address_placeholder:`Zadejte prosím svoji adresu`,
        no_code_text:`Odeslali jsme ověřovací kód. Zadejte prosím svůj kód. <span class='tips'>Nedostali jste kód?`,
        no_code_text_1:`1. Ujistěte se, že je e -mailová adresa platná a může přijímat e -maily.`,
        no_code_text_2:`2. E -mail je automaticky odesílán systémem a může být označen jako nevyžádaná nebo nevyžádaná pošta. Zkontrolujte, zda je e -mail ve složce Koš.`,
        no_code_text_3:`3. Nemůžete vyřešit svůj problém?`,
        no_code_text_3_span:`Pak nás kontaktujte kliknutím sem.`,
        order_no:`Nezakoupili jste žádné produkty. Jakékoli dotazy prosím <a href="https://cs.aiseesoft.com/store/">Nakupujte nyní</a> Nebo <a href="https://cs.aiseesoft.com/cs/contact.html"> Kontaktujte nás</a>`,
        error_24901:`K běžnému účtu není připojen žádný e -mail a nelze najít objednávky. Připojte prosím e -mail.`,
        user_guide:`Uživatelská příručka >>`,
        download:`Stažení`,
        order_number:`Číslo objednávky`,
        Refund:`Vrácení peněz`,
        Disabled:`Zakázáno`,
        Normal:`Normální`,
        Modify:`Upravit`,
        Modify_1:`Upravit >>`,
        Connect:`Připojit`,
        unlink_success:`Úspěšně odpojit`,
        connect_success:`Připojte se úspěšně`,
        feedback_title:`Děkujeme za vaši odezvu. Zanechte prosím svůj problém a my vám odpovíme do 24 hodin.`,
        feedback_thank_you:`Děkujeme! <br /> Vaše zpětná vazba byla úspěšně odeslána.`,
        feedback_email:`Sem zadejte svůj e -mail!`,
        feedback_content:`Zde zanechte jakýkoli problém nebo návrh, se kterým jste se setkali.`,
        feedback_submit:`Předložit`,
        form_contents:`Nezadali jste žádný popis. Zadejte jej a odešlete znovu.`,
        old_password:`Zadejte prosím staré heslo`,
        new_password:`Vytvořte si prosím nové heslo`,
        old_new_password:`Nové heslo nemůže být stejné jako staré`,
        incorrect_password:`Nesprávné heslo`,
        delete_no:`Odstranit nyní`,
        Caps:`Caps Lock je zapnutý`,
        Get:`Dostat`,
        Done:`Hotovo`,
        error_20001:`Chyba: 20001. Přihlaste se prosím znovu.`,
        error_20002:`Chyba: 20002. Přihlaste se prosím znovu.`,
        error_20003:`Chyba: 20003. Přihlaste se prosím znovu.`,
        error_20004:`Požadavek se nezdařil (kód chyby: 20004). Zkuste to prosím znovu.`,
        error_20005:`Platnost relace přihlášení vypršela (chyba: 20005). Přihlaste se prosím znovu.`,
        error_20006:`Požadavek se nezdařil (kód chyby: 20006). Zkuste to prosím znovu.`,
        error_20007:`Platnost relace přihlášení vypršela (chyba: 20007). Přihlaste se prosím znovu.`,
        error_20008:`Platnost relace přihlášení vypršela (chyba: 20008). Přihlaste se prosím znovu.`,
        error_20009:`Platnost relace přihlášení vypršela (chyba: 20009). Přihlaste se prosím znovu.`,
        error_20101:`Zadejte prosím svůj e -mail (kód chyby: 20101)`,
        error_20102:`E -mail není platný (kód chyby: 20102)`,
        error_20103:`Požadavek se nezdařil (kód chyby: 20103). Zkuste to prosím znovu`,
        error_20104:`E -mail se již používá. <a href="https://account.aiseesoft.com/login"> Přihlaste se </a> nebo se zaregistrujte pomocí nového`,
        error_20105:`Požadavek se nezdařil (kód chyby: 20105). Zkuste to prosím znovu`,
        error_20106:`Odeslání e -mailu se nezdařilo, zkuste to prosím znovu`,
        error_20201:`Zadejte prosím svůj e -mail (kód chyby: 20201)`,
        error_20202:`Zadejte prosím heslo (kód chyby: 20202)`,
        error_20203:`Zadejte prosím ověřovací kód (kód chyby: 20203)`,
        error_20204:`E -mail není platný (kód chyby: 20204)`,
        error_20205:`Je vyžadováno heslo nad 8 znaků (kód chyby: 20205)`,
        error_20206:`Požadavek se nezdařil (kód chyby: 20206). Zkuste to prosím znovu`,
        error_20207:`neplatný ověřovací kód`,
        error_20208:`Požadavek se nezdařil (kód chyby: 20208). Zkuste to prosím znovu`,
        error_20209:`Požadavek se nezdařil (kód chyby: 20209). Zkuste to prosím znovu`,
        error_20301:`Zadejte prosím svůj e -mail (kód chyby: 20301)`,
        error_20302:`Chyba: 20302. Kontaktujte nás`,
        error_20303:`E -mail není platný (kód chyby: 20303)`,
        error_20304:`Požadavek se nezdařil (kód chyby: 20304). Zkuste to prosím znovu`,
        error_20305:`Účet neexistuje. Nejprve prosím zadejte znovu nebo se <a href="https://account.aiseesoft.com/register">Vytvořit to</a>.`,
        error_20306:`Zatím žádné heslo. Použijte <a href="https://account.aiseesoft.com/passwordless-login"> přihlášení bez hesla </a> nebo <a href="create-password"> nastavení hesla </a> a přihlaste se.`,
        error_20308:`Požadavek se nezdařil (kód chyby: 20308). Zkuste to prosím znovu`,
        error_20401:`Odhlášení se nezdařilo (kód chyby: 20401). Zkuste to prosím znovu`,
        error_20501:`Zadejte prosím svůj e -mail (kód chyby: 20501)`,
        error_20502:`E -mail není platný (kód chyby: 20502)`,
        error_20503:`Požadavek se nezdařil (kód chyby: 20503). Zkuste to prosím znovu`,
        error_20504:`Odeslání e -mailu se nezdařilo. Zkuste to prosím znovu.`,
        error_20601:`Zadejte prosím svůj e -mail (kód chyby: 20601)`,
        error_20602:`Zadejte prosím ověřovací kód (kód chyby: 20602)`,
        error_20603:`E -mail není platný (kód chyby: 20603)`,
        error_20604:`Požadavek se nezdařil (kód chyby: 20604). Zkuste to prosím znovu`,
        error_20606:`Požadavek se nezdařil (kód chyby: 20606). Zkuste to prosím znovu`,
        error_20607:`Požadavek se nezdařil (kód chyby: 20607). Zkuste to prosím znovu`,
        error_20608:`Požadavek se nezdařil (kód chyby: 20608). Zkuste to prosím znovu`,
        error_20701:`Zadejte prosím svůj e -mail (kód chyby: 20701)`,
        error_20702:`E -mail není platný (kód chyby: 20702)`,
        error_20703:`Požadavek se nezdařil (kód chyby: 20703). Zkuste to prosím znovu`,
        error_20704:`Účet neexistuje. Nejprve prosím zadejte znovu nebo se <a href="https://account.aiseesoft.com/register">Vytvořit to</a>.`,
        error_20705:`Požadavek se nezdařil (kód chyby: 20705). Zkuste to prosím znovu`,
        error_20706:`Odeslání e -mailu se nezdařilo. Zkuste to prosím znovu`,
        error_20801:`Zadejte prosím svůj e -mail (kód chyby: 20801)`,
        error_20802:`Chyba: 20802. Kontaktujte nás`,
        error_20803:`Zadejte prosím ověřovací kód (kód chyby: 20803)`,
        error_20804:`E -mail není platný (kód chyby: 20804)`,
        error_20805:`Je vyžadováno heslo nad 8 znaků (kód chyby: 20805)`,
        error_20806:`Požadavek se nezdařil (kód chyby: 20806). Zkuste to prosím znovu`,
        error_20808:`Požadavek se nezdařil (kód chyby: 20808). Zkuste to prosím znovu`,
        error_20901:`Požadavek se nezdařil (kód chyby: 20901). Zkuste to prosím znovu`,
        error_20902:`Požadavek se nezdařil (kód chyby: 20902). Zkuste to prosím znovu`,
        error_21000:`Změny se uloží`,
        error_21001:`Nebyly odeslány žádné informace (kód chyby: 21001)`,
        error_21002:`Požadavek se nezdařil (kód chyby: 21002). Zkuste to prosím znovu`,
        error_21101:`Zadejte prosím svůj e -mail (kód chyby: 21101)`,
        error_21102:`E -mail není platný (kód chyby: 21102)`,
        error_21103:`Požadavek se nezdařil (kód chyby: 21103), plesase to zkuste znovu`,
        error_21104:`E -mail je již připojen, použijte prosím nový`,
        error_21105:`Požadavek se nezdařil (kód chyby: 21105), plesase to zkuste znovu`,
        error_21106:`Odeslání e -mailu se nezdařilo. Zkuste to prosím znovu`,
        error_21201:`Zadejte prosím svůj e -mail (kód chyby: 21201)`,
        error_21202:`Zadejte prosím ověřovací kód (kód chyby: 21202)`,
        error_21203:`E -mail není platný (kód chyby: 21203)`,
        error_21204:`Chyba: 21204. Kontaktujte nás`,
        error_21205:`Chyba: 21205. Kontaktujte nás`,
        error_21206:`Je vyžadováno heslo nad 8 znaků (kód chyby: 21206)`,
        error_21207:`Požadavek se nezdařil (kód chyby: 21207). Zkuste to prosím znovu`,
        error_21209:`Požadavek se nezdařil (kód chyby: 21209). Zkuste to prosím znovu`,
        error_21301:`Zadejte prosím staré heslo (kód chyby: 21301)`,
        error_21302:`Vytvořte prosím nové heslo (kód chyby: 21302)`,
        error_21303:`Nové heslo nemůže být stejné jako staré. (Chyba: 21303)`,
        error_21304:`Je vyžadováno heslo nad 8 znaků (kód chyby: 21304)`,
        error_21306:`Požadavek se nezdařil (kód chyby: 21306). Zkuste to prosím znovu`,
        error_21402:`Požadavek se nezdařil (kód chyby: 21402). Zkuste to prosím znovu`,
        error_21403:`Odeslání ověřovacího kódu se nezdařilo. Pošlete to prosím znovu`,
        error_21500:`Účet byl smazán`,
        error_21501:`Zadejte prosím ověřovací kód (kód chyby: 21501)`,
        error_21502:`Platnost relace přihlášení vypršela (chyba: 21502). Přihlaste se prosím znovu.`,
        error_21503:`Požadavek se nezdařil (kód chyby: 21503). Zkuste to prosím znovu`,
        error_21505:`Požadavek se nezdařil (kód chyby: 21505), plesase to zkuste znovu`,
        error_21601:`Chyba: 20601. Kontaktujte nás`,
        error_21602:`Neplatné ověření (chyba: 20602). Zkuste to prosím znovu.`,
        error_21603:`Chyba: 20603. Zkuste to prosím znovu`,
        error_21604:`Požadavek se nezdařil (kód chyby: 21604). Zkuste to prosím znovu`,
        error_21606:`Požadavek se nezdařil (kód chyby: 21606). Zkuste to prosím znovu`,
        error_21611:`Požadavek se nezdařil (kód chyby: 21611). Zkuste to prosím znovu`,
        error_21801:`Chyba: 21801. Kontaktujte nás`,
        error_21802:`Požadavek se nezdařil (chyba: 21802). Zkuste to prosím znovu`,
        error_21803:`Chyba: 21803. Zkuste to prosím znovu`,
        error_21804:`Požadavek se nezdařil (kód chyby: 21804). Zkuste to prosím znovu`,
        error_21806:`Chyba: 21806. Zkuste to prosím znovu`,
        error_21807:`Chyba: 21807. Kontaktujte nás`,
        error_21808:`Chyba: 21808. Kontaktujte nás`,
        error_21809:`Chyba: 21809. Kontaktujte nás`,
        error_21810:`Chyba: 21810. Kontaktujte nás`,
        error_21811:`Chyba: 21811. Kontaktujte nás`,
        error_21812:`Chyba: 21812. Kontaktujte nás`,
        error_21813:`Požadavek se nezdařil (kód chyby: 21813). Zkuste to prosím znovu`,
        error_21814:`Chyba: 21814. Kontaktujte nás`,
        error_21815:`Požadavek se nezdařil (kód chyby: 21815). Zkuste to prosím znovu`,
        error_21816:`Chyba: 21816. Kontaktujte nás`,
        error_21817:`Chyba: 21817. Kontaktujte nás`,
        error_21818:`Chyba: 21818. Kontaktujte nás`,
        error_21819:`Požadavek se nezdařil (kód chyby: 21819). Zkuste to prosím znovu`,
        error_21820:`Chyba: 21820. Kontaktujte nás`,
        error_21821:`Chyba: 21821. Kontaktujte nás`,
        error_21822:`Chyba: 21822. Kontaktujte nás`,
        error_21823:`Požadavek se nezdařil (kód chyby: 21823). Zkuste to prosím znovu`,
        error_21824:`Požadavek se nezdařil (kód chyby: 21824). Zkuste to prosím znovu`,
        error_21825:`Požadavek se nezdařil (kód chyby: 21825). Zkuste to prosím znovu`,
        error_21826:`Požadavek se nezdařil (kód chyby: 21826). Zkuste to prosím znovu`,
        error_21828:`Chyba: 21828. Kontaktujte nás`,
        error_21901:`Chyba: 21901. Kontaktujte nás`,
        error_21902:`Požadavek se nezdařil (kód chyby: 21902). Zkuste to prosím znovu`,
        error_21903:`Stav účtu se změnil (kód chyby: 21903), obnovte stránku a zkuste to znovu`,
        error_21904:`Chyba: 21904. Zkuste to prosím znovu`,
        error_21905:`Chyba: 21905. Zkuste to prosím znovu`,
        error_21906:`Požadavek se nezdařil (kód chyby: 21906). Zkuste to prosím znovu`,
        error_21907:`Účet Google byl propojen s jiným účtem`,
        error_21908:`Požadavek se nezdařil (kód chyby: 21908). Zkuste to prosím znovu`,
        error_22001:`Požadavek se nezdařil (kód chyby: 22001). Zkuste to prosím znovu`,
        error_22002:`Odpojení se nezdařilo bez dalšího přihlášení`,
        error_22003:`Požadavek se nezdařil (kód chyby: 22003). Zkuste to prosím znovu`,
        error_22101:`Chyba: 22101. Kontaktujte nás`,
        error_22102:`Stav účtu se změnil (kód chyby: 22102), obnovte stránku a zkuste to znovu`,
        error_22103:`Požadavek se nezdařil (kód chyby: 22103). Zkuste to prosím znovu`,
        error_22104:`Stav účtu se změnil (kód chyby: 22104), obnovte stránku a zkuste to znovu`,
        error_22105:`Chyba: 22105. Zkuste to prosím znovu`,
        error_22106:`Chyba: 22106. Zkuste to prosím znovu`,
        error_22107:`Chyba: 22107. Kontaktujte nás`,
        error_22108:`Požadavek se nezdařil (kód chyby: 22108). Zkuste to prosím znovu`,
        error_22201:`Chyba: 22201. Kontaktujte nás`,
        error_22202:`Neplatné ověření (chyba: 22202). Zkuste to prosím znovu.`,
        error_22203:`Chyba: 22203. Zkuste to prosím znovu "`,
        error_22204:`Požadavek se nezdařil (kód chyby: 22204). Zkuste to prosím znovu`,
        error_22206:`Požadavek se nezdařil (kód chyby: 22206). Zkuste to prosím znovu`,
        error_22401:`Chyba: 22401. Kontaktujte nás`,
        error_22402:`Neplatné ověření (chyba: 22402). Zkuste to prosím znovu.`,
        error_22403:`Chyba: 22403. Zkuste to prosím znovu`,
        error_22404:`Požadavek se nezdařil (kód chyby: 22404). Zkuste to prosím znovu`,
        error_22405:`Účet na Facebooku byl propojen s jiným e -mailem`,
        error_22406:`Chyba: 22406. Zkuste to prosím znovu`,
        error_22407:`Chyba: 22407. Kontaktujte nás`,
        error_22408:`Chyba: 22408. Kontaktujte nás`,
        error_22409:`Chyba: 22409. Kontaktujte nás`,
        error_22410:`Chyba: 224010. Kontaktujte nás`,
        error_22411:`Chyba: 224011. Kontaktujte nás`,
        error_22412:`Chyba: 224012. Kontaktujte nás`,
        error_22413:`Chyba: 22413. Kontaktujte nás`,
        error_22414:`Požadavek se nezdařil (kód chyby: 22414). Zkuste to prosím znovu`,
        error_22415:`Chyba: 22415. Kontaktujte nás`,
        error_22416:`Chyba: 22416. Kontaktujte nás`,
        error_22417:`Chyba: 22417. Kontaktujte nás`,
        error_22418:`Požadavek se nezdařil (kód chyby: 22418). Zkuste to prosím znovu`,
        error_22419:`Chyba: 22419. Kontaktujte nás`,
        error_22420:`Chyba: 22420. Kontaktujte nás`,
        error_22421:`Chyba: 22421. Kontaktujte nás`,
        error_22422:`Požadavek se nezdařil (kód chyby: 22422). Zkuste to prosím znovu`,
        error_22423:`Požadavek se nezdařil (kód chyby: 22423). Zkuste to prosím znovu`,
        error_22424:`Požadavek se nezdařil (kód chyby: 22424). Zkuste to prosím znovu`,
        error_22425:`Požadavek se nezdařil (kód chyby: 22425). Zkuste to prosím znovu`,
        error_20098:`Kód chyby: 20098. Pokud procházíte v soukromém režimu, přepněte prosím do normálního režimu a zkuste to znovu.`,
        error_22298:`Požadavek na Google se nezdařil (chyba: 22298). Zkuste to prosím znovu.`,
        error_22498:`Žádost o Facebook se nezdařila (chyba: 22498). Zkuste to prosím znovu.`,
        error_24902:`Požadavek se nezdařil (kód chyby: 24902). Zkuste to prosím znovu`,
        error_24903:`Požadavek se nezdařil (kód chyby: 24903). Zkuste to prosím znovu`,
        error_24904:`Požadavek se nezdařil (kód chyby: 24904). Zkuste to prosím znovu`,
        error_24905:`Požadavek se nezdařil (kód chyby: 24905). Zkuste to prosím znovu`,
        login_title:`Přihlaste se do Aiseesoft`,
        password_placeholder:`Heslo`,
        log_in:`Přihlásit se`,
        no_account:`Žádný účet?`,
        create_it:`Vytvořit to`,
        or_log_in_with:`Nebo se přihlaste pomocí`,
        passwordless_login:`Přihlášení bez hesla`,
        log_in_done:`Přihlásit se Hotovo`,
        three_rd_account_connect_info:`Gratulujeme! Úspěšně jste se přihlásili. Nyní můžete připojit jeden e -mailový účet, který slouží k budoucímu přihlášení.`,
        connect_now:`Připojte se hned`,
        see_my_account:`Viz můj účet`,
        three_rd_login_merge_account:`E-mailová adresa účtu třetí strany byla zaregistrována. Chcete se připojit a přihlásit se pomocí této e-mailové adresy přímo?`,
        connect_log_in:`Připojte se a přihlaste se`,
        create_an_account:`Vytvořit účet`,
        connect_account:`Připojit účet`,
        connect_account_info:`Po připojení se můžete přihlásit pomocí tohoto účtu.`,
        back_to_log_in:`Zpět k přihlášení`,
        create_password:`Vytvořit heslo`,
        create_now:`Vytvořit hned`,
        password_login_subtitle:`Přihlášení bez hesla pomocí e -mailu`,
        account_login:`Přihlášení k účtu`,
        rights:`Vytvořením tohoto účtu souhlasíte se <a href="https://cs.aiseesoft.com/company/terms-and-conditions.html">smluvními podmínkami</a> a <a href="https://cs.aiseesoft.com/privacy-policy.html">Zásady ochrany osobních údajů</a>`,
        passwordless_login_done:`Přihlášení bez hesla dokončeno`,
        passwordless_login_info:`Gratulujeme, úspěšně jste dokončili přihlášení bez hesla. Pro tento účet můžete vytvořit heslo a v budoucnu se pomocí účtu a hesla přihlásit. <a href="/create-password" style="display: initial;"> Vytvořit nyní </a>`,
        sign_up:`Přihlásit se`,
        register_info:`Vytvořte si účet`,
        reset_now:`Resetovat nyní`,
        forgot_password:`Zapomenuté heslo`,
        reset_password_subtitle:`K obnovení hesla použijte svůj e -mailový účet`,
        plan_products:`Plány a produkty`,
        nick_name:`uživatelské jméno`,
        email:`E-mailem`,
        my_products:`Moje produkty`,
        my_orders:`Mé objednávky`,
        unlink:`Odpojit`,
        link:`Odkaz`,
        connected_accounts:`Propojené účty`,
        last_name:`Příjmení`,
        first_name:`Jméno`,
        Gender:`Rod`,
        Birth:`Narození`,
        Month:`Měsíc`,
        Year:`Rok`,
        Country_Region:`Země/oblast`,
        Address:`Adresa`,
        Save:`Uložit`,
        Date:`datum`,
        Male:`mužský`,
        Female:`ženský`,
        Unspecified:`Nespecifikováno`,
        Security:`Bezpečnostní`,
        change_password:`Změnit heslo`,
        change_now:`Změnit hned`,
        connect_email:`Připojit e -mail`,
        delete_account:`Smazat účet`,
        delete_account_info:`Když bude váš účet smazán, všechna data v plánu a produktech přidružená k vašemu účtu budou trvale smazána a možná je nebudete moci obnovit. Doporučujeme opatrně šlapat.`,
        Delete:`Vymazat`,
        Logout:`Odhlásit se`,
        my_profile:`Můj profil`,
        guides_faqs:`Průvodci a časté dotazy`,
        More:`Více`,
        guides:`Průvodci`,
        register:`Registrovat`,
        hot_faq:`Nejčastější dotazy`,
        Contents:`Obsah:`,
        contact_us:`Kontaktujte nás >>`,
        plan:`Plán`,
        unregistered:`Neregistrovaný`,
        buy_more:`Koupit více`,
        buy_again:`Koupit znovu`,
        buy_now:`Kup nyní`,
        free_no_limit:`Zdarma a bez omezení`,
        expired:`Platnost vypršela`,
        lifetime:`Život`,
        remain:`Zůstat`,
        day_s:`dny`,
        error_24801:`Požadavek se nezdařil (kód chyby: 24801). Zkuste to znovu`,
        no_app_found:`Nebyla nalezena žádná aplikace! <a href="/">Obnovte</a> nebo <a href="https://cs.aiseesoft.com/">Přejděte na oficiální web</a>`,
        get_more:`Získejte více >>`,
        edit_photo:`Upravit fotografii`,
        select_photo:`Vyberte fotografii`,
        change_photo:`Změnit fotku`,
        cancel:`zrušení`,
        hide_password:`Skrýt heslo`,
        show_password:`Zobrazit heslo`,
        zoom_in:`Přiblížit`,
        zoom_out:`Oddálit`,
        rotate:`Točit se`,
        horizontal_flip:`Horizontální překlopení`,
        vertical_flip:`Vertikální překlopení`,
        country:`Země`,
        country_1:`Vyberte svou zemi/oblast`,
        country_2:`Ostrov Åaland`,
        country_3:`Afghánistán`,
        country_4:`Albánie`,
        country_5:`Alžírsko`,
        country_6:`Americká Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktida`,
        country_11:`Antigua a Barbuda`,
        country_12:`Argentina`,
        country_13:`Arménie`,
        country_14:`Aruba`,
        country_15:`Austrálie`,
        country_16:`Rakousko`,
        country_17:`Ázerbajdžán`,
        country_18:`Bahrajn`,
        country_19:`Bangladéš`,
        country_20:`Barbados`,
        country_21:`Bělorusko`,
        country_22:`Belgie`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermudy`,
        country_26:`Bhútán`,
        country_27:`Bolívie`,
        country_28:`Bosna a Hercegovina`,
        country_29:`Botswana`,
        country_30:`Bouvetův ostrov`,
        country_31:`Brazílie`,
        country_32:`Britské indickooceánské území`,
        country_33:`Britské Panenské ostrovy`,
        country_34:`Brunej`,
        country_35:`Bulharsko`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodža`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Kapverdy`,
        country_42:`Karibské Nizozemsko`,
        country_43:`Kajmanské ostrovy`,
        country_44:`Středoafrická republika`,
        country_45:`Čad`,
        country_46:`Chile`,
        country_47:`Čína`,
        country_48:`Vánoční ostrov`,
        country_49:`Kokosové (Keelingovy) ostrovy`,
        country_50:`Kolumbie`,
        country_51:`Cookovy ostrovy`,
        country_52:`Kostarika`,
        country_53:`"Pobřeží slonoviny"`,
        country_54:`Chorvatsko`,
        country_55:`Kuba`,
        country_56:`Kypr`,
        country_57:`Česká republika`,
        country_58:`Demokratická republika Kongo`,
        country_59:`Dánsko`,
        country_60:`Džibutsko`,
        country_61:`Dominika`,
        country_62:`Dominikánská republika`,
        country_63:`Ekvádor`,
        country_64:`Egypt`,
        country_65:`El Salvador`,
        country_66:`Rovníková Guinea`,
        country_67:`Eritrea`,
        country_68:`Estonsko`,
        country_69:`Etiopie`,
        country_70:`Falklandy`,
        country_71:`Faerské ostrovy`,
        country_72:`Federativní státy Mikronésie`,
        country_73:`Fidži`,
        country_74:`Finsko`,
        country_75:`Francie`,
        country_76:`Francouzská Guyana`,
        country_77:`Francouzská Polynésie`,
        country_78:`Francouzská jižní území`,
        country_79:`Gabon`,
        country_80:`Gambie`,
        country_81:`Gruzie`,
        country_82:`Německo`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Velká Británie (Spojené království; Anglie)`,
        country_86:`Řecko`,
        country_87:`Grónsko`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heardův ostrov a McDonaldovy ostrovy`,
        country_98:`Honduras`,
        country_99:`Hongkong`,
        country_100:`Maďarsko`,
        country_101:`Island`,
        country_102:`Indie`,
        country_103:`Indonésie`,
        country_104:`Írán`,
        country_105:`Irák`,
        country_106:`Irsko`,
        country_107:`Ostrov Man`,
        country_108:`Izrael`,
        country_109:`Itálie`,
        country_110:`Jamaica`,
        country_111:`Japonsko`,
        country_112:`Trikot`,
        country_113:`Jordán`,
        country_114:`Kazachstán`,
        country_115:`Keňa`,
        country_116:`Kiribati`,
        country_117:`Kuvajt`,
        country_118:`Kyrgyzstán`,
        country_119:`Laos`,
        country_120:`Lotyšsko`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Libérie`,
        country_124:`Libye`,
        country_125:`Lichtenštejnsko`,
        country_126:`Litva`,
        country_127:`Lucembursko`,
        country_128:`Macao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malajsie`,
        country_132:`Maledivy`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshallovy ostrovy`,
        country_136:`Martinik`,
        country_137:`Mauritánie`,
        country_138:`Mauricius`,
        country_139:`Mayotte`,
        country_140:`Mexiko`,
        country_141:`Moldavsko`,
        country_142:`Monako`,
        country_143:`Mongolsko`,
        country_144:`Černá Hora`,
        country_145:`Montserrat`,
        country_146:`Maroko`,
        country_147:`Mosambik`,
        country_148:`Myanmar (Barma)`,
        country_149:`Namibie`,
        country_150:`Nauru`,
        country_151:`Nepál`,
        country_152:`Holandsko`,
        country_153:`Nová Kaledonie`,
        country_154:`Nový Zéland`,
        country_155:`Nikaragua`,
        country_156:`Niger`,
        country_157:`Nigérie`,
        country_158:`Niue`,
        country_159:`Ostrov Norfolk`,
        country_160:`Severní Korea`,
        country_161:`Severní Mariany`,
        country_162:`Norsko`,
        country_163:`Omán`,
        country_164:`Pákistán`,
        country_165:`Palau`,
        country_166:`Palestinská území`,
        country_167:`Panama`,
        country_168:`Papua-Nová Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairnovy ostrovy`,
        country_172:`Polsko`,
        country_173:`Portugalsko`,
        country_174:`Portoriko`,
        country_175:`Katar`,
        country_176:`Makedonie (FYROM)`,
        country_177:`republika Kongo`,
        country_178:`Shledání`,
        country_179:`Rumunsko`,
        country_180:`Ruská Federace`,
        country_181:`Rwanda`,
        country_182:`Svatý Bartoloměj`,
        country_183:`Svatý Martin (Francie)`,
        country_184:`Saint-Pierre a Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Svatý Tomáš a Princův ostrov`,
        country_188:`Saudská arábie`,
        country_189:`Senegal`,
        country_190:`Srbsko`,
        country_191:`Seychely`,
        country_192:`Sierra Leone`,
        country_193:`Singapur`,
        country_194:`Slovensko`,
        country_195:`Slovinsko`,
        country_196:`Solomonovy ostrovy`,
        country_197:`Somálsko`,
        country_198:`Jižní Afrika`,
        country_199:`Jižní Georgie a Jižní Sandwich Isla`,
        country_200:`Jižní Korea`,
        country_201:`jižní Súdán`,
        country_202:`Španělsko`,
        country_203:`Srí Lanka`,
        country_204:`Svatá Helena & Závislosti`,
        country_205:`Svatý Kryštof a Nevis`,
        country_206:`Svatá Lucie`,
        country_207:`Svatý Vincenc a Grenadiny`,
        country_208:`Súdán`,
        country_209:`Surinam`,
        country_210:`Svazijsko`,
        country_211:`Švédsko`,
        country_212:`Švýcarsko`,
        country_213:`Sýrie`,
        country_214:`Tchaj-wan`,
        country_215:`Tádžikistán`,
        country_216:`Tanzanie`,
        country_217:`Šablona:Údaje o zemi SJM Svalbard`,
        country_218:`Thajsko`,
        country_219:`Bahamy`,
        country_220:`Komory`,
        country_221:`Filipíny`,
        country_222:`Timor-Leste (Východní Timor)`,
        country_223:`Jít`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad a Tobago`,
        country_227:`Tunisko`,
        country_228:`krocan`,
        country_229:`Turkmenistán`,
        country_230:`Ostrovy Turks a Caicos`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukrajina`,
        country_234:`Spojené arabské emiráty`,
        country_235:`Menší odlehlé ostrovy Spojených států amerických`,
        country_236:`Spojené státy americké (USA)`,
        country_237:`Panenské ostrovy Spojených států amerických`,
        country_238:`Uruguay`,
        country_239:`Uzbekistán`,
        country_240:`Vanuatu`,
        country_241:`Vatikán (Svatý stolec)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis a Futuna`,
        country_245:`západní Sahara`,
        country_246:`Jemen`,
        country_247:`Zambie`,
        country_248:`Zimbabwe`,
        google_login:`Přihlaste se pomocí Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Všechna práva vyhrazena.`,
        State:`Stát`,
        Activation_code:`Aktivační kód`,
        Question:`Seznam všech aplikací, ke kterým jste přihlášeni`,
        Copy_complete:`Kopírování dokončeno`,

        store:`Obchod`,
        resource:`Zdroj`,
        support:`Podpěra, podpora`,
        video_converter:`Video konvertor`,
        multimedia:`Multimédia`,
        data_recovery:`Obnova dat`,
        utility:`Utility`,
        free_tools:`Nástroje zdarma`,
        for_windows:`Pro Windows`,
        for_mac:`Pro Mac`,
        pdf_solutions:`PDF řešení`,
        data_manager:`Správce dat`,
        other_tools:`Další nástroje`,
        free_online_solutions:`Bezplatné online řešení`,
        free_online_tools:`Bezplatné online nástroje`,
        desktop_freeware:`Desktopový freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`Převodník MTS`,
        product_4:`4K převodník`,
        product_5:`Převodník MXF`,
        product_6:`3D převodník`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter pro Mac`,
        product_9:`MTS Converter pro Mac`,
        product_10:`4K konvertor pro Mac`,
        product_11:`MXF Converter pro Mac`,
        product_12:`3D převodník pro Mac`,
        product_13:`Blu-ray přehrávač`,
        product_14:`Video Enhancer`,
        product_15:`Editor videa`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Blu-ray přehrávač pro Mac`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video Editor pro Mac`,
        product_23:`DVD Creator pro Mac`,
        product_24:`Převaděč MP4 pro Mac`,
        product_25:`Záchrana dat systému Windows`,
        product_26:`Záchrana dat pro iPhone FoneLab`,
        product_27:`Obnova systému FoneLab iOS`,
        product_28:`FoneLab iOS Data Backup &amp; Obnovit`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Nefunkční extrakce dat Android`,
        product_31:`Android Data Backup &amp; Obnovit`,
        product_32:`Záchrana dat Mac`,
        product_33:`Mac FoneLab iPhone Data Recovery`,
        product_34:`Obnova systému Mac FoneLab iOS`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Obnovit`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; Obnovit`,
        product_38:`Konvertor PDF Ultimate`,
        product_39:`PDF Converter Ultimate pro Mac`,
        product_40:`Sloučení PDF`,
        product_41:`Mac PDF to ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer pro iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Čistič pro iPhone`,
        product_48:`PPT to Video Converter`,
        product_49:`HEIC převodník`,
        product_50:`iPhone Unlocker`,
        product_51:`Zdarma Video Converter online`,
        product_52:`Zdarma online audio převodník`,
        product_53:`Video kompresor online zdarma`,
        product_54:`Sloučení videa zdarma online`,
        product_55:`Zdarma převodník HEIC`,
        product_56:`Kompresor obrázků zdarma`,
        product_57:`Zdarma odstraňovač vodoznaku`,
        product_58:`Bezplatný PDF kompresor online`,
        product_59:`Zdarma Image Upscaler Online`,
        product_60:`Odstraňovač pozadí zdarma online`,
        product_61:`Převodník obrázků online zdarma`,
        product_62:`Zdarma online převodník PDF PNG`,
        product_63:`Zdarma Video Converter`,
        product_64:`Převaděč videa na GIF zdarma`,
        product_65:`Bezplatné obnovení dat pro Android`,
        product_66:`Slideshow Maker`,
        product_67:`Zdarma Video Editor`,
        product_68:`Prohlížeč PDF zdarma`,
        product_69:`Screen Recorder`,
        product_70:`Mac Screen Recorder`,
        product_71:`Zdarma Screen Recorder online`,
        product_72:`Zdarma Audio rekordér online`,
        product_73:`Zrcadlo telefonu`,
        product_74:`AnyCoord - Změna polohy`,
        product_75:`Správce hesel pro iPhone`,
        product_76:`Zdarma online audio kompresor`,
        product_77:`Zastřihovač videa zdarma online`,
        product_78:`Video Cropper online zdarma`,
        product_79:`Video rotátor online zdarma`,
        product_80:`Video Looper online zdarma`,
        product_81:`Zdarma online převodník PDF JPG`,
        product_81:`Zdarma online konvertor JPG Word`,
        category_1:`Úprava fotografie`,
        category_2:`Úprava videa`,
        category_3:`Guma`,
        category_4:`Záznamník médií`,
        posted_by:`Autor:`,
        to:`na`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryanová`,
        author_3:`Nicole Jonesová`,
        author_4:`Amanda Brownová`,
        article_1:`Jak efektivně odstranit text z obrázků`,
        article_2:`Jak zvětšit velikost obrázku v KB na MB bez poškození kvality`,
        article_3:`Jak odstranit vodoznak Getty Images na Windows a Mac`,
        article_4:`Nejlepší způsob, jak zdarma převést obrázek s nízkým rozlišením na vysoké rozlišení online`,
        article_5:`Průvodce TikTok | Zde je návod, jak vytvořit video koláž na TikTok`,
        article_6:`5 snadných způsobů, jak kombinovat soubory VOB, které byste měli znát`,
        article_7:`Jak snadno převzorkovat video a získat vyšší rozlišení`,
        article_8:`Jak kombinovat soubory MP4 na MacBooku Pro/iMac/MacBook a dalších`,
        article_9:`2 jednoduché způsoby, jak smazat historii nákupů na iPhone [Vyřešeno]`,
        article_10:`Jak trvale odstranit obrázky Snapchat z chatu a vzpomínek`,
        article_11:`Jak odstranit návrhy a historii vyhledávání Spotlight na iPhone/iPad`,
        article_12:`[Vyřešeno] Jak vymazat mezipaměť Instagramu na iPhone pomocí 3 snadných způsobů`,
        article_13:`[Vyřešeno] Jak opravit nefunkčnost přehrávače QuickTime na Macu`,
        article_14:`Jak nahrávat systémový zvuk na Mac (Big Sur/Catalina/Mojave…)`,
        article_15:`Efektivní způsoby, jak opravit nefunkční nahrávání obrazovky PowerPoint`,
        article_16:`Jak nahrávat zvuk pomocí VLC [Vyřešeno]`,
        faq_title_1:`1. Jak opravit chybu 4104?`,
        faq_title_2:`2. Kdy mohu dostat své peníze zpět po schválení žádosti o vrácení peněz?`,
        faq_title_3:`3. Co je to „1roční předplatné“ a jak vypnout „Službu automatického obnovení“?`,
        faq_title_4:`4. Mohu zrušit svou objednávku po umístění produktu Aiseesoft?`,
        faq_title_5:`5. Jak vám zaslat recenzi?`,
        faq_info_1:`To je způsobeno omezenou licencí, kterou jste získali. Přijmeme jednotnou licenci v případě odcizení registračního kódu a zabráníme zneužití registračního kódu. Takže Reg Code, který máte, je Single License, kterou lze použít pouze na jednom počítači. Pokud jej používáte na více než jednom počítači, zobrazí se chyba 4104. Nemůžete jej používat, dokud jej za vás neresetujeme.`,
        faq_info_2:`Pokud svůj počítač změníte nebo přeinstalujete, můžeme jej resetovat za vás. Kontaktujte prosím zákaznický servis: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> a vyžádejte si resetování registračního kódu.`,
        faq_info_3:`Po schválení vaší žádosti o vrácení peněz obvykle trvá 3–7 pracovních dnů, než se kredit objeví na vašem účtu. Někdy během prázdnin to může trvat déle, ale neočekává se, že to bude trvat déle než 10 dní. Pokud neobdržíte vrácení peněz do 20 dnů po schválení, kontaktujte nás prosím na adrese <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> a zkontrolujte vrácení peněz.`,
        faq_info_4:`Služba automatického obnovení znamená, že pokud si zakoupíte předplatné na 1 rok, bude vám platební platforma účtovat částku automaticky (včetně příslušných daní), jakmile vyprší platnost licence produktu. Platební plochý formulář (Swreg nebo 2Checkout) vám však zašle dopis, který vám připomene, že platnost objednávky vyprší 7 dní před jejím vypršením. Součástí e-mailu bude i návod, jak vypnout „Službu automatického obnovení“.`,
        faq_info_5:`Vzhledem k tomu, že objednávka je zpracovávána automaticky, není možné objednávku zrušit. Kromě toho vám bezplatná zkušební verze umožní dozvědět se více o programu Aiseesoft a zkontrolovat, zda může splňovat vaše potřeby, než si jej zakoupíte.`,
        faq_info_6:`Pokud opravdu chcete získat vrácení peněz, přečtěte si naše <a href="https://cs.aiseesoft.com/refund-policy.html">Zásady vrácení peněz</a>.`,
        faq_info_7:`Vážíme si vašeho času a pozornosti, kterou nám věnujete, abyste nám zaslali recenzi. Kontaktujte nás (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) a odešlete svou recenzi a my vaši recenzi pro aktualizace našich produktů vážně zvážíme.`,
        change_password_success:`heslo bylo úspěšně změněno`,
        successful_login_title:`Přihlášení bylo úspěšné`,
        product_page:`Stránka produktu >>`,
        successful_login_info:`Přihlášení dokončeno. Zavřete prosím aktuální stránku a vraťte se na původní kartu, abyste mohli pokračovat v následujícím procesu. Aktuální stránka se automaticky zavře za 5 sekund. Pokud se automatické zavření nebo zavření kliknutím na tlačítko "Hotovo" nezdaří, zavřete prosím přímo tuto kartu.`,
        successful_login_info_firefox:`Přihlášení dokončeno. Zavřete prosím aktuální stránku a vraťte se na původní kartu, abyste mohli pokračovat v následujícím procesu.`,
        my_account:`Můj účet`,
        my_history:`Moje historie`,
        remove_watermark:`Odstraňte vodoznak`,
        no_history:`Žádná historie`,
        history_all:`Vybrat vše`,
        history_open:`Otevřeno`,
        history_down:`Stažení`,
        history_delete:`Vymazat`,
        history_clear:`Vymazat neplatné`,
        images:`snímky)`,
        use_this_function:`Použít tento produkt >>`,
        hd_downloading:`Výhody stahování originálních HD obrázků:`,
        lifetimeRemaining:`Životnost-zbývající`,
        Remaining:`Zbývající`,
        email_verification:`potvrzení e-mailu`,
        email_verification_info:`Ověřovací zprávu jsme odeslali na váš e-mail <span class="email"></span> a dokončete prosím ověření. Po ověření budou výhody automaticky synchronizovány.`,
        wrong_email:`Špatná e-mailová adresa?`,
        click_here_to_modify:`Klikněte zde pro úpravu`,
        get_more_help:`Získat další pomoc?`,
        click_here:`Klikněte zde`,
        email_verification_info_success:`Gratulujeme k ověření vašeho e-mailového účtu.`,
        email_verification_info_error:`Ověření se nezdařilo z důvodu vypršení platnosti odkazu.`,
        registration_succeeded:`Registrace byla úspěšná`,
        registration_succeeded_info_1:`Gratulujeme! Úspěšně jste se zaregistrovali. Ověřovací zprávu jsme odeslali na váš e-mail <span class="email"></span> a dokončete ověření, abyste mohli využívat výhod tohoto e-mailu.`,
        registration_succeeded_info_2:`Kliknutím na „Hotovo“ se vraťte na domovskou stránku a použijte tento produkt.`,
        registration_succeeded_info_3:`Kliknutím na „Hotovo“ zavřete aktuální stránku a vrátíte se na domovskou stránku pro následující operace. Pokud se zavření nezdaří, zavřete kartu ručně.`,
        verify_email:`ověřovací email`,
        registered_email_not_verified:`Registrovaný e-mail nebyl ověřen, prosím jej ihned ověřte.`,
        email_verification_time_1:`Neobdrželi jste ověřovací e-mail?`,
        email_verification_time_2:`Po`,
        email_verification_time_3:`Kliknutím sem jej znovu odešlete`,
        error_26301:`Kód chyby: 26301, kontaktujte nás`,
        error_26302:`Kód chyby: 26302, kontaktujte nás`,
        error_26303:`Chyba formátu e-mailu (kód chyby: 26303). Zadejte jej znovu`,
        error_26304:`Doporučuje se heslo delší než 8 znaků (kód chyby: 26304)`,
        error_26305:`Požadavek se nezdařil (kód chyby: 26305). Zkuste to znovu`,
        error_26306:`E-mail byl zaregistrován, <a href="https://account.aiseesoft.com/login">přejděte k přihlášení</a>`,
        error_26307:`Požadavek se nezdařil (kód chyby: 26307). Zkuste to znovu`,
        error_26308:`Požadavek se nezdařil (kód chyby: 26308). Zkuste to znovu`,
        error_26401:`Kód chyby: 26401, zkuste to znovu`,
        error_26402:`E-mail byl ověřen (kód chyby: 26402), zkuste to prosím znovu`,
        error_26403:`Požadavek se nezdařil (kód chyby: 26403). Zkuste to znovu`,
        error_26404:`Požadavek se nezdařil (kód chyby: 26404). Zkuste to znovu`,
        error_26501:`Kód chyby: 26501, kontaktujte nás`,
        error_26502:`Kód chyby: 26502, kontaktujte nás`,
        error_26503:`Chyba formátu e-mailu (kód chyby: 26503). Zadejte jej znovu`,
        error_26504:`Požadavek se nezdařil (kód chyby: 26504). Zkuste to znovu`,
        error_26505:`E-mail nebyl zaregistrován, prosím <a href="https://account.aiseesoft.com/register">nejprve jej zaregistrujte</a>`,
        error_26506:`E-mail byl ověřen.`,
        error_26507:`Požadavek se nezdařil (kód chyby: 26507). Zkuste to znovu`,
        error_26508:`Ověření proběhlo úspěšně (kód chyby: 26508), zkuste to znovu`,
        error_26509:`Požadavek se nezdařil (kód chyby: 26509), zkuste to znovu`,
        error_26510:`Kód chyby: 26510, kontaktujte nás`,
        error_26601:`Kód chyby: 26601, kontaktujte nás`,
        error_26602:`Kód chyby: 26602, kontaktujte nás`,
        error_26603:`Požadavek se nezdařil (kód chyby: 26603). Zkuste to znovu`,
        error_26604:`Kód chyby: 26604, kontaktujte nás`,
        error_26605:`Kód chyby: 26605, kontaktujte nás`,
        error_26701:`Kód chyby: 26701, kontaktujte nás prosím`,
        error_26702:`Požadavek se nezdařil (kód chyby: 26702). Zkuste to znovu`,
        error_26703:`Kód chyby: 26703, kontaktujte nás`,
        error_26704:`Kód chyby: 26704, kontaktujte nás`,
        error_26705:`Počkejte na přihlášení (kód chyby: 26705). Zkuste to znovu`,
        no_cookie:`Ve svém prohlížeči jste zapnuli funkci Blokovat všechny soubory cookie, takže se nemůžete přihlásit. Přejděte prosím do Nastavení a zaškrtněte políčko Povolit všechny soubory cookie.`,
        error_26801:`Kód chyby: 26801, kontaktujte nás prosím`,
        error_26802:`Kód chyby: 26802, kontaktujte nás`,
        error_26803:`Požadavek se nezdařil (kód chyby: 26803). Zkuste to znovu`,
        error_26804:`Požadavek se nezdařil (kód chyby: 26804). Zkuste to znovu`,
        error_order:`Požadavek se nezdařil (kód chyby: 27098), zkuste to prosím znovu!`,
        error_order1:`Dotaz na objednávku je neúplný (kód chyby:`,
        error_order2:`), obnovte stránku a zkuste to znovu.`,
        modify_email_title:`Upravit e-mail`,
        modify_email_info:`Upravený e-mail můžete použít k přihlášení ke svému účtu.`,
        images_per:`Obrázky za`,
        error_26101:`Chyba: 26101. Kontaktujte nás`,
        error_26102:`Chyba: 26102. Kontaktujte nás`,
        error_26103:`Požadavek se nezdařil (kód chyby: 26103). Zkuste to znovu`,
        error_26104:`Kód chyby: 26104, zkuste to prosím znovu`,
        error_26105:`Kód chyby: 26105, zkuste to prosím znovu`,
        error_26106:`Smazání se nezdařilo (kód chyby: 26106). Zkuste to znovu`,
        error_26201:`Chyba: 26201. Kontaktujte nás`,
        error_26202:`Požadavek se nezdařil (kód chyby: 26202). Zkuste to znovu`,
        error_26001:`Chyba: 26001. Kontaktujte nás`,
        error_26002:`Chyba: 26002. Kontaktujte nás`,
        error_26003:`Chyba: 26003. Kontaktujte nás`,
        error_26004:`Chyba: 26004. Kontaktujte nás`,
        error_26005:`Požadavek se nezdařil (kód chyby: 26005). Zkuste to znovu`,
        error_26006:`Kód chyby: 26006, zkuste to prosím znovu`,
        error_26008:`Chyba: 26008. Kontaktujte nás`,
        go_to_the_home_page:`Přejděte na domovskou stránku`,
        error_27101:`Požadavek se nezdařil (kód chyby: 27101). Zkuste to znovu ist`,
        error_27201:`Kód chyby: 27201, kontaktujte nás`,
        error_27202:`Kód chyby: 27202, zkuste to znovu`,
        error_27203:`Požadavek se nezdařil (kód chyby: 27203). Zkuste to znovu`,
        error_27204:`Neplatný kód (kód chyby: 27204).`,
        error_27205:`Požadavek se nezdařil (kód chyby: 27205). Zkuste to znovu`,
        error_27206:`Požadavek se nezdařil (kód chyby: 27206). Zkuste to znovu`,
        error_27207:`Požadavek se nezdařil (kód chyby: 27207). Zkuste to znovu`,
        no_history_found:`Nepoužili jste žádný nástroj! <a href="/">Obnovte</a> nebo <a href="https://cs.aiseesoft.com/">Přejděte na oficiální web</a>`,
        error_25301:`Chyba: 25301. Kontaktujte nás`,
        error_25302:`Chyba: 25302. Kontaktujte nás`,
        error_25303:`Požadavek se nezdařil (kód chyby: 25303). Zkuste to znovu`,
        error_25304:`Požadavek se nezdařil (kód chyby: 25304). Zkuste to znovu`,
        error_25305:`Požadavek se nezdařil (kód chyby: 25305). Zkuste to znovu`,
        error_25306:`Požadavek se nezdařil (kód chyby: 25306). Zkuste to znovu`,
        image_upscaler_p:`Výhody stahování obrázku bez vodoznaku:`,
        Available_for:`Dostupné pro:`,
        credit_per:`%s kreditů za HD obrázek`,
        still_valid:`Zakoupený plán(y) je stále platný`,
        credit:`kredit(y)`,
        pc_3rd_info:`Přihlášení proběhlo úspěšně. Pro další operaci přejděte do aplikace.`,
        use_online:`Použijte online službu`,
        use_download:`Použijte desktopový program`,
        use_immediately:`Použijte Ihned`,
        Use_in_browser:`Použijte v prohlížeči`,
        win_desktop:`Okna`,
        Mac_desktop:`Mac`,
        
    },
    da:{
        overtime_1:`Fejlkode:`,
        overtime_2:`, prøv igen`,
        isnetwork:`Internetfejl. Tjek venligst, og prøv igen`,
        email_placeholder:`E -mail`,
        email_empty:`Indtast venligst e -mail`,
        email_not_valid:`E -mail er ikke gyldig`,
        email_not_valid_1:`Indtast venligst din e -mail`,
        email_not_valid_2:`E -mail er ikke gyldig. Brug en anden adresse.`,
        email_not_valid_3:`Ingen e -mail -input`,
        password_placeholder:`Adgangskode`,
        password_empty:`Indtast venligst adgangskode`,
        password_not_valid:`Forkert konto eller adgangskode`,
        password_not_valid_1:`Adgangskode over 8 tegn er påkrævet`,
        password_not_valid_2:`Opret en adgangskode`,
        password_placeholder_1:`Opret din adgangskode`,
        password_placeholder_2:`Bekræft dit kodeord`,
        password_placeholder_3:`Opret ny adgangskode`,
        password_placeholder_4:`Bekræft ny adgangskode`,
        password_placeholder_5:`Indtast gammel adgangskode`,
        copy_password_empty:`Bekræft venligst adgangskoden`,
        copy_password_not_valid:`Bekræft venligst din adgangskode`,
        copy_passwords_inconsistent:`Din adgangskodebekræftelse stemmer ikke overens`,
        code_empty:`Indtast venligst verifikationskode`,
        code_not_valid:`Ugyldig verifikationskode`,
        code_placeholder:`Verifikationskode`,
        not_received_code:`Hvis din postkasse ikke har modtaget verifikationskoden i lang tid, skal du få bekræftelseskoden igen.`,
        get_first_code:`Få venligst bekræftelseskoden først.`,
        connect_your_email_placeholder:`Tilslut din e -mail`,
        last_name_placeholder:`Indtast venligst dit efternavn`,
        first_name_placeholder:`Indtast venligst dit fornavn`,
        address_placeholder:`Indtast venligst din adresse`,
        no_code_text:`Vi har sendt en verifikationskode. Angiv venligst din kode. <span class='tips'>Har du ikke modtaget en kode?`,
        no_code_text_1:`1.Kontroller venligst, at e -mailadressen er gyldig, og at den kan modtage e -mails.`,
        no_code_text_2:`2.For e -mailen sendes automatisk af systemet, kan den markeres som spam- eller uønsket e -mail. Kontroller, om e -mailen er i papirkurven.`,
        no_code_text_3:`3. kan ikke løse dit problem?`,
        no_code_text_3_span:`Klik derefter her for at kontakte os.`,
        order_no:`Du har ikke købt nogen produkter. Eventuelle spørgsmål, venligst <a href="https://da.aiseesoft.com/store/">Køb nu</a> Eller <a href="https://da.aiseesoft.com/contact.html"> Kontakt os</a>`,
        error_24901:`Nuværende konto har ingen link til e -mail, og kan ikke finde ordrerne. Tilslut venligst en e -mail.`,
        user_guide:`Brugervejledning >>`,
        download:`Hent`,
        order_number:`Ordrenummer`,
        Refund:`Tilbagebetale`,
        Disabled:`handicappet`,
        Normal:`Normal`,
        Modify:`Modificere`,
        Modify_1:`Rediger >>`,
        Connect:`Forbinde`,
        unlink_success:`Fjern forbindelsen med succes`,
        connect_success:`Opret forbindelse`,
        feedback_title:`tak for din tilbagemelding. Forlad venligst dit problem, og vi vil svare dig inden for 24 timer.`,
        feedback_thank_you:`Tak! <br /> Din feedback er blevet indsendt.`,
        feedback_email:`Indtast din email her!`,
        feedback_content:`Efterlad ethvert problem eller forslag, du stødte på her.`,
        feedback_submit:`Indsend`,
        form_contents:`Du har ikke angivet nogen beskrivelse. Indtast det, og send det igen.`,
        old_password:`Indtast venligst den gamle adgangskode`,
        new_password:`Opret en ny adgangskode`,
        old_new_password:`Den nye adgangskode kan ikke være den samme som den gamle`,
        incorrect_password:`forkert kodeord`,
        delete_no:`Slet nu`,
        Caps:`Caps Lock er på`,
        Get:`Få`,
        Done:`Færdig`,
        error_20001:`Fejl: 20001. Log venligst ind igen.`,
        error_20002:`Fejl: 20002. Log venligst ind igen.`,
        error_20003:`Fejl: 20003. Log venligst ind igen.`,
        error_20004:`Anmodning mislykkedes (fejlkode: 20004). Prøv det igen.`,
        error_20005:`Login session er udløbet (fejl: 20005). Log venligst ind igen.`,
        error_20006:`Anmodning mislykkedes (fejlkode: 20006). Prøv det igen.`,
        error_20007:`Login session er udløbet (fejl: 20007). Log venligst ind igen.`,
        error_20008:`Login session er udløbet (fejl: 20008). Log venligst ind igen.`,
        error_20009:`Login session er udløbet (fejl: 20009). Log venligst ind igen.`,
        error_20101:`Indtast venligst din e -mail (fejlkode: 20101)`,
        error_20102:`E -mail er ikke gyldig (fejlkode: 20102)`,
        error_20103:`Anmodning mislykkedes (fejlkode: 20103). Prøv det igen`,
        error_20104:`E -mail er allerede i brug. <a href="https://account.aiseesoft.com/login"> log ind </a> eller registrer dig med en ny`,
        error_20105:`Anmodning mislykkedes (fejlkode: 20105). Prøv det igen`,
        error_20106:`Mailen kunne ikke sendes. Prøv igen`,
        error_20201:`Indtast venligst din e -mail (fejlkode: 20201)`,
        error_20202:`Indtast venligst din adgangskode (fejlkode: 20202)`,
        error_20203:`Indtast venligst verifikationskode (fejlkode: 20203)`,
        error_20204:`E -mail er ikke gyldig (fejlkode: 20204)`,
        error_20205:`Adgangskode over 8 tegn er påkrævet (fejlkode: 20205)`,
        error_20206:`Anmodning mislykkedes (fejlkode: 20206). Prøv det igen`,
        error_20207:`Ugyldig verifikationskode`,
        error_20208:`Anmodning mislykkedes (fejlkode: 20208). Prøv det igen`,
        error_20209:`Anmodning mislykkedes (fejlkode: 20209). Prøv det igen`,
        error_20301:`Indtast venligst din e -mail (fejlkode: 20301)`,
        error_20302:`Fejl: 20302. Kontakt os venligst`,
        error_20303:`E -mail er ikke gyldig (fejlkode: 20303)`,
        error_20304:`Anmodning mislykkedes (fejlkode: 20304). Prøv det igen`,
        error_20305:`Kontoen findes ikke. Angiv igen, eller <a href="https://account.aiseesoft.com/register">Opret det</a> først.`,
        error_20306:`Ingen adgangskode endnu. Brug <a href="https://account.aiseesoft.com/passwordless-login"> login uden adgangskode </a> eller <a href="create-password"> angiv en adgangskode </a>, og log ind.`,
        error_20308:`Anmodning mislykkedes (fejlkode: 20308). Prøv det igen`,
        error_20401:`Kunne ikke logge ud (fejlkode: 20401). Prøv det igen`,
        error_20501:`Indtast venligst din e -mail (fejlkode: 20501)`,
        error_20502:`E -mail er ikke gyldig (fejlkode: 20502)`,
        error_20503:`Anmodning mislykkedes (fejlkode: 20503). Prøv det igen`,
        error_20504:`Kunne ikke sende e -mail. Prøv det igen.`,
        error_20601:`Indtast venligst din e -mail (fejlkode: 20601)`,
        error_20602:`Angiv venligst verifikationskode (fejlkode: 20602)`,
        error_20603:`E -mail er ikke gyldig (fejlkode: 20603)`,
        error_20604:`Anmodning mislykkedes (fejlkode: 20604). Prøv det igen`,
        error_20606:`Anmodning mislykkedes (fejlkode: 20606). Prøv det igen`,
        error_20607:`Anmodning mislykkedes (fejlkode: 20607). Prøv det igen`,
        error_20608:`Anmodning mislykkedes (fejlkode: 20608). Prøv det igen`,
        error_20701:`Indtast venligst din e -mail (fejlkode: 20701)`,
        error_20702:`E -mail er ikke gyldig (fejlkode: 20702)`,
        error_20703:`Anmodning mislykkedes (fejlkode: 20703). Prøv det igen`,
        error_20704:`Kontoen findes ikke. Angiv igen, eller <a href="https://account.aiseesoft.com/register">Opret det</a> først.`,
        error_20705:`Anmodning mislykkedes (fejlkode: 20705). Prøv det igen`,
        error_20706:`Kunne ikke sende e -mail. Prøv det igen`,
        error_20801:`Indtast venligst din e -mail (fejlkode: 20801)`,
        error_20802:`Fejl: 20802. Kontakt os venligst`,
        error_20803:`Indtast venligst verifikationskode (fejlkode: 20803)`,
        error_20804:`E -mail er ikke gyldig (fejlkode: 20804)`,
        error_20805:`Adgangskode over 8 tegn er påkrævet (fejlkode: 20805)`,
        error_20806:`Anmodning mislykkedes (fejlkode: 20806). Prøv det igen`,
        error_20808:`Anmodning mislykkedes (fejlkode: 20808). Prøv det igen`,
        error_20901:`Anmodning mislykkedes (fejlkode: 20901). Prøv det igen`,
        error_20902:`Anmodning mislykkedes (fejlkode: 20902). Prøv det igen`,
        error_21000:`Ændringer gemmes`,
        error_21001:`Der indsendes ingen oplysninger (fejlkode: 21001)`,
        error_21002:`Anmodning mislykkedes (fejlkode: 21002). Prøv det igen`,
        error_21101:`Indtast venligst din e -mail (fejlkode: 21101)`,
        error_21102:`E -mail er ikke gyldig (fejlkode: 21102)`,
        error_21103:`Anmodningen mislykkedes (fejlkode: 21103), prøv igen`,
        error_21104:`E -mail er allerede forbundet, brug en ny`,
        error_21105:`Anmodningen mislykkedes (fejlkode: 21105), prøv igen`,
        error_21106:`Kunne ikke sende e -mail. Prøv det igen`,
        error_21201:`Indtast venligst din e -mail (fejlkode: 21201)`,
        error_21202:`Indtast venligst verifikationskode (fejlkode: 21202)`,
        error_21203:`E -mail er ikke gyldig (fejlkode: 21203)`,
        error_21204:`Fejl: 21204. Kontakt os venligst`,
        error_21205:`Fejl: 21205. Kontakt os venligst`,
        error_21206:`Adgangskode over 8 tegn er påkrævet (fejlkode: 21206)`,
        error_21207:`Anmodning mislykkedes (fejlkode: 21207). Prøv det igen`,
        error_21209:`Anmodning mislykkedes (fejlkode: 21209). Prøv det igen`,
        error_21301:`Indtast venligst den gamle adgangskode (fejlkode: 21301)`,
        error_21302:`Opret en ny adgangskode (fejlkode: 21302)`,
        error_21303:`Den nye adgangskode kan ikke være den samme som den gamle. (Fejl: 21303)`,
        error_21304:`Adgangskode over 8 tegn er påkrævet (fejlkode: 21304)`,
        error_21306:`Anmodning mislykkedes (fejlkode: 21306). Prøv det igen`,
        error_21402:`Anmodning mislykkedes (fejlkode: 21402). Prøv det igen`,
        error_21403:`Verificeringskoden kunne ikke sendes. Send det venligst igen`,
        error_21500:`Kontoen er blevet slettet`,
        error_21501:`Angiv venligst verifikationskode (fejlkode: 21501)`,
        error_21502:`Login session er udløbet (fejl: 21502). Log venligst ind igen.`,
        error_21503:`Anmodning mislykkedes (fejlkode: 21503). Prøv det igen`,
        error_21505:`Anmodningen mislykkedes (fejlkode: 21505). Prøv igen`,
        error_21601:`Fejl: 20601. Kontakt os venligst`,
        error_21602:`Ugyldig verifikation (fejl: 20602). Prøv det igen.`,
        error_21603:`Fejl: 20603. Prøv det igen`,
        error_21604:`Anmodning mislykkedes (fejlkode: 21604). Prøv det igen`,
        error_21606:`Anmodning mislykkedes (fejlkode: 21606). Prøv det igen`,
        error_21611:`Anmodning mislykkedes (fejlkode: 21611). Prøv det igen`,
        error_21801:`Fejl: 21801. Kontakt os venligst`,
        error_21802:`Anmodning mislykkedes (fejl: 21802). Prøv det igen`,
        error_21803:`Fejl: 21803. Prøv det igen`,
        error_21804:`Anmodning mislykkedes (fejlkode: 21804). Prøv det igen`,
        error_21806:`Fejl: 21806. Prøv det igen`,
        error_21807:`Fejl: 21807. Kontakt os venligst`,
        error_21808:`Fejl: 21808. Kontakt os venligst`,
        error_21809:`Fejl: 21809. Kontakt os venligst`,
        error_21810:`Fejl: 21810. Kontakt os venligst`,
        error_21811:`Fejl: 21811. Kontakt os venligst`,
        error_21812:`Fejl: 21812. Kontakt os venligst`,
        error_21813:`Anmodning mislykkedes (fejlkode: 21813). Prøv det igen`,
        error_21814:`Fejl: 21814. Kontakt os venligst`,
        error_21815:`Anmodning mislykkedes (fejlkode: 21815). Prøv det igen`,
        error_21816:`Fejl: 21816. Kontakt os venligst`,
        error_21817:`Fejl: 21817. Kontakt os venligst`,
        error_21818:`Fejl: 21818. Kontakt os venligst`,
        error_21819:`Anmodning mislykkedes (fejlkode: 21819). Prøv det igen`,
        error_21820:`Fejl: 21820. Kontakt os venligst`,
        error_21821:`Fejl: 21821. Kontakt os venligst`,
        error_21822:`Fejl: 21822. Kontakt os venligst`,
        error_21823:`Anmodning mislykkedes (fejlkode: 21823). Prøv det igen`,
        error_21824:`Anmodning mislykkedes (fejlkode: 21824). Prøv det igen`,
        error_21825:`Anmodning mislykkedes (fejlkode: 21825). Prøv det igen`,
        error_21826:`Anmodning mislykkedes (fejlkode: 21826). Prøv det igen`,
        error_21828:`Fejl: 21828. Kontakt os venligst`,
        error_21901:`Fejl: 21901. Kontakt os venligst`,
        error_21902:`Anmodning mislykkedes (fejlkode: 21902). Prøv det igen`,
        error_21903:`Kontostatus er ændret (fejlkode: 21903). Opdater venligst siden, og prøv igen`,
        error_21904:`Fejl: 21904. Prøv det igen`,
        error_21905:`Fejl: 21905. Prøv det igen`,
        error_21906:`Anmodning mislykkedes (fejlkode: 21906). Prøv det igen`,
        error_21907:`Google -konto er blevet linket til en anden konto`,
        error_21908:`Anmodning mislykkedes (fejlkode: 21908). Prøv det igen`,
        error_22001:`Anmodning mislykkedes (fejlkode: 22001). Prøv det igen`,
        error_22002:`Fjernelse af link mislykkedes uden yderligere login`,
        error_22003:`Anmodning mislykkedes (fejlkode: 22003). Prøv det igen`,
        error_22101:`Fejl: 22101. Kontakt os venligst`,
        error_22102:`Kontostatus er ændret (fejlkode: 22102). Opdater venligst siden, og prøv igen`,
        error_22103:`Anmodning mislykkedes (fejlkode: 22103). Prøv det igen`,
        error_22104:`Kontostatus er ændret (fejlkode: 22104). Opdater venligst siden, og prøv igen`,
        error_22105:`Fejl: 22105. Prøv det igen`,
        error_22106:`Fejl: 22106. Prøv det igen`,
        error_22107:`Fejl: 22107. Kontakt os venligst`,
        error_22108:`Anmodning mislykkedes (fejlkode: 22108). Prøv det igen`,
        error_22201:`Fejl: 22201. Kontakt os venligst`,
        error_22202:`Ugyldig verifikation (fejl: 22202). Prøv det igen.`,
        error_22203:`Fejl: 22203. Prøv det igen "`,
        error_22204:`Anmodning mislykkedes (fejlkode: 22204). Prøv det igen`,
        error_22206:`Anmodning mislykkedes (fejlkode: 22206). Prøv det igen`,
        error_22401:`Fejl: 22401. Kontakt os venligst`,
        error_22402:`Ugyldig verifikation (fejl: 22402). Prøv det igen.`,
        error_22403:`Fejl: 22403. Prøv det igen`,
        error_22404:`Anmodning mislykkedes (fejlkode: 22404). Prøv det igen`,
        error_22405:`Facebook -kontoen er blevet linket til anden e -mail`,
        error_22406:`Fejl: 22406. Prøv det igen`,
        error_22407:`Fejl: 22407. Kontakt os venligst`,
        error_22408:`Fejl: 22408. Kontakt os venligst`,
        error_22409:`Fejl: 22409. Kontakt os venligst`,
        error_22410:`Fejl: 224010. Kontakt os venligst`,
        error_22411:`Fejl: 224011. Kontakt os venligst`,
        error_22412:`Fejl: 224012. Kontakt os venligst`,
        error_22413:`Fejl: 22413. Kontakt os venligst`,
        error_22414:`Anmodning mislykkedes (fejlkode: 22414). Prøv det igen`,
        error_22415:`Fejl: 22415. Kontakt os venligst`,
        error_22416:`Fejl: 22416. Kontakt os venligst`,
        error_22417:`Fejl: 22417. Kontakt os venligst`,
        error_22418:`Anmodning mislykkedes (fejlkode: 22418). Prøv det igen`,
        error_22419:`Fejl: 22419. Kontakt os venligst`,
        error_22420:`Fejl: 22420. Kontakt os venligst`,
        error_22421:`Fejl: 22421. Kontakt os venligst`,
        error_22422:`Anmodning mislykkedes (fejlkode: 22422). Prøv det igen`,
        error_22423:`Anmodning mislykkedes (fejlkode: 22423). Prøv det igen`,
        error_22424:`Anmodning mislykkedes (fejlkode: 22424). Prøv det igen`,
        error_22425:`Anmodning mislykkedes (fejlkode: 22425). Prøv det igen`,
        error_20098:`Fejlkode: 20098. Hvis du søger i privat tilstand, skal du skifte til normal tilstand og prøve igen.`,
        error_22298:`Anmodning om Google mislykkedes (fejl: 22298). Prøv det igen.`,
        error_22498:`Anmodning om Facebook mislykkedes (fejl: 22498). Prøv det igen.`,
        error_24902:`Anmodning mislykkedes (fejlkode: 24902). Prøv det igen`,
        error_24903:`Anmodning mislykkedes (fejlkode: 24903). Prøv det igen`,
        error_24904:`Anmodning mislykkedes (fejlkode: 24904). Prøv det igen`,
        error_24905:`Anmodning mislykkedes (fejlkode: 24905). Prøv det igen`,
        login_title:`Log ind på Aiseesoft`,
        password_placeholder:`Adgangskode`,
        log_in:`Log på`,
        no_account:`Ingen konto?`,
        create_it:`Opret det`,
        or_log_in_with:`Eller log ind med`,
        passwordless_login:`Login uden adgangskode`,
        log_in_done:`Log på Udført`,
        three_rd_account_connect_info:`Tillykke! Du har logget ind. Nu kan du forbinde en e -mail -konto, der bruges til at logge ind i fremtiden.`,
        connect_now:`Opret forbindelse nu`,
        see_my_account:`Se min konto`,
        three_rd_login_merge_account:`Tredjeparts kontos e-mail-adresse er blevet tilmeldt. Vil du oprette forbindelse til og logge på med denne e-mail-adresse direkte?`,
        connect_log_in:`Opret forbindelse og log ind`,
        create_an_account:`Opret en konto`,
        connect_account:`Tilslut konto`,
        connect_account_info:`Efter tilslutning kan du logge ind med denne konto.`,
        back_to_log_in:`Tilbage for at logge ind`,
        create_password:`Opret adgangskode`,
        create_now:`Opret nu`,
        password_login_subtitle:`Login uden adgangskode med e -mail`,
        account_login:`Konto -login`,
        rights:`Ved at oprette denne konto accepterer du <a href="https://da.aiseesoft.com/company/terms-and-conditions.html"> servicevilkår </a> og <a href="https://da.aiseesoft.com/privacy-policy.html"> Fortrolighedspolitik </a>`,
        passwordless_login_done:`Login uden adgangskode udført`,
        passwordless_login_info:`Tillykke, du har fuldført adgangskodefri login. Du kan oprette en adgangskode til denne konto og logge ind med kontoen og adgangskoden i fremtiden. <a href="/create-password" style="display: initial;"> Opret nu </a>`,
        sign_up:`Tilmelde`,
        register_info:`Opret din konto`,
        reset_now:`Nulstil nu`,
        forgot_password:`Glemt kodeord`,
        reset_password_subtitle:`Brug din kontos e -mail til at nulstille adgangskoden`,
        plan_products:`Planer og produkter`,
        nick_name:`Brugernavn`,
        email:`E -mail`,
        my_products:`Mine produkter`,
        my_orders:`Mine Ordrer`,
        unlink:`Fjern tilknytningen`,
        link:`Link`,
        connected_accounts:`Tilsluttede konti`,
        last_name:`Efternavn`,
        first_name:`Fornavn`,
        Gender:`Køn`,
        Birth:`Fødsel`,
        Month:`Måned`,
        Year:`År`,
        Country_Region:`Land/region`,
        Address:`Adresse`,
        Save:`Gemme`,
        Date:`Dato`,
        Male:`Han`,
        Female:`Kvinde`,
        Unspecified:`Uspecificeret`,
        Security:`Sikkerhed`,
        change_password:`Skift kodeord`,
        change_now:`Skift nu`,
        connect_email:`Tilslut e -mail`,
        delete_account:`Slet konto`,
        delete_account_info:`Når din konto slettes, slettes alle data i Plan & Produkter, der er knyttet til din konto, permanent, og du kan muligvis ikke gendanne dem. Vi anbefaler, at du træder forsigtigt.`,
        Delete:`Slet`,
        Logout:`Log ud`,
        my_profile:`Min profil`,
        guides_faqs:`Guider og ofte stillede spørgsmål`,
        More:`Mere`,
        guides:`Guider`,
        register:`Tilmeld`,
        hot_faq:`Hot FAQ`,
        Contents:`Indhold:`,
        contact_us:`Kontakt os >>`,
        plan:`Plan`,
        unregistered:`Uregistreret`,
        buy_more:`Køb mere`,
        buy_again:`Køb igen`,
        buy_now:`Køb nu`,
        free_no_limit:`Gratis & ingen grænse`,
        expired:`Udløbet`,
        lifetime:`Livstid`,
        remain:`Forblive`,
        day_s:`Dage)`,
        error_24801:`Forespørgsel mislykkedes (fejlkode: 24801). Prøv det igen`,
        no_app_found:`Ingen app fundet!<a href="/">Opdater</a> eller <a href="https://da.aiseesoft.com/">Gå til det officielle websted</a>`,
        get_more:`Få mere >>`,
        edit_photo:`Rediger billede`,
        select_photo:`Vælg foto`,
        change_photo:`Ændr Foto`,
        cancel:`Afbestille`,
        hide_password:`Skjul adgangskode`,
        show_password:`Vis adgangskode`,
        zoom_in:`Zoom ind`,
        zoom_out:`Zoome ud`,
        rotate:`Rotere`,
        horizontal_flip:`Vandret flip`,
        vertical_flip:`Lodret flip`,
        country:`Land`,
        country_1:`Vælg dit land/område`,
        country_2:`Åaland`,
        country_3:`Afghanistan`,
        country_4:`Albanien`,
        country_5:`Algeriet`,
        country_6:`Amerikansk Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktis`,
        country_11:`Antigua og Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenien`,
        country_14:`Aruba`,
        country_15:`Australien`,
        country_16:`Østrig`,
        country_17:`Aserbajdsjan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Hviderusland`,
        country_22:`Belgien`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnien og Hercegovina`,
        country_29:`Botswana`,
        country_30:`Bouvetøen`,
        country_31:`Brasilien`,
        country_32:`Britisk territorium i Det Indiske Ocean`,
        country_33:`Britiske Jomfruøer`,
        country_34:`Brunei`,
        country_35:`Bulgarien`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodja`,
        country_39:`Cameroun`,
        country_40:`Canada`,
        country_41:`Kap Verde`,
        country_42:`Caribiske Holland`,
        country_43:`Caymanøerne`,
        country_44:`Den Centralafrikanske Republik`,
        country_45:`Tchad`,
        country_46:`Chile`,
        country_47:`Kina`,
        country_48:`Juleøen`,
        country_49:`Cocos (Keeling) Islands`,
        country_50:`Colombia`,
        country_51:`Cookøerne`,
        country_52:`Costa Rica`,
        country_53:`"Côte d'Ivoire"`,
        country_54:`Kroatien`,
        country_55:`Cuba`,
        country_56:`Cypern`,
        country_57:`Tjekkiet`,
        country_58:`Den Demokratiske Republik Congo`,
        country_59:`Danmark`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominikanske republik`,
        country_63:`Ecuador`,
        country_64:`Egypten`,
        country_65:`El Salvador`,
        country_66:`Ækvatorial Guinea`,
        country_67:`Eritrea`,
        country_68:`Estland`,
        country_69:`Etiopien`,
        country_70:`Falklandsøerne`,
        country_71:`Færøerne`,
        country_72:`Mikronesiens fødererede stater`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`Frankrig`,
        country_76:`Fransk Guyana`,
        country_77:`Fransk polynesien`,
        country_78:`franske sydlige territorier`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgien`,
        country_82:`Tyskland`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Storbritannien (Storbritannien; England)`,
        country_86:`Grækenland`,
        country_87:`Grønland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island og McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Ungarn`,
        country_101:`Island`,
        country_102:`Indien`,
        country_103:`Indonesien`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italien`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kasakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirgisistan`,
        country_119:`Laos`,
        country_120:`Letland`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libyen`,
        country_125:`Liechtenstein`,
        country_126:`Litauen`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldiverne`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshalløerne`,
        country_136:`Martinique`,
        country_137:`Mauretanien`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexico`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongoliet`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marokko`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Holland`,
        country_153:`Ny Kaledonien`,
        country_154:`New Zealand`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolk Island`,
        country_160:`Nordkorea`,
        country_161:`Nordmarianerne`,
        country_162:`Norge`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`palæstinensiske områder`,
        country_167:`Panama`,
        country_168:`Papua Ny Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairnøerne`,
        country_172:`Polen`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republikken Makedonien (FYROM)`,
        country_177:`Republikken Congo`,
        country_178:`Genforening`,
        country_179:`Rumænien`,
        country_180:`Den Russiske Føderation`,
        country_181:`Rwanda`,
        country_182:`Sankt Barthélemy`,
        country_183:`Saint Martin (Frankrig)`,
        country_184:`Saint-Pierre og Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome og Principe`,
        country_188:`Saudi Arabien`,
        country_189:`Senegal`,
        country_190:`Serbien`,
        country_191:`Seychellerne`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakiet`,
        country_195:`Slovenien`,
        country_196:`Salomonøerne`,
        country_197:`Somalia`,
        country_198:`Sydafrika`,
        country_199:`South Georgia og South Sandwich Isla`,
        country_200:`Sydkorea`,
        country_201:`Sydsudan`,
        country_202:`Spanien`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & afhængigheder`,
        country_205:`St. Kitts og Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent og Grenadinerne`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Swaziland`,
        country_211:`Sverige`,
        country_212:`Schweiz`,
        country_213:`Syrien`,
        country_214:`Taiwan`,
        country_215:`Tadsjikistan`,
        country_216:`Tanzania`,
        country_217:`Skabelon:Landedata SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`Bahamas`,
        country_220:`Comorerne`,
        country_221:`Filippinerne`,
        country_222:`Timor-Leste (Østtimor)`,
        country_223:`At gå`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad og Tobago`,
        country_227:`Tunesien`,
        country_228:`Kalkun`,
        country_229:`Turkmenistan`,
        country_230:`Turks- og Caicosøerne`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraine`,
        country_234:`Forenede Arabiske Emirater`,
        country_235:`Forenede Staters mindre afsidesliggende øer`,
        country_236:`USA (USA)`,
        country_237:`De Forenede Staters Jomfruøer`,
        country_238:`Uruguay`,
        country_239:`Usbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikanstaten (Den Hellige Stol)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis og Futuna`,
        country_245:`Vestsahara`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Log ind med Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Alle rettigheder forbeholdes.`,
        State:`Stat`,
        Activation_code:`Aktiveringskode`,
        Question:`Liste over alle de apps, du har logget på`,
        Copy_complete:`Kopi færdig`,
        
        store:`butik`,
        resource:`Ressource`,
        support:`support`,
        video_converter:`Video Converter`,
        multimedia:`Multimedier`,
        data_recovery:`Datagendannelse`,
        utility:`Utility`,
        free_tools:`Gratis værktøjer`,
        for_windows:`Til Windows`,
        for_mac:`Til Mac`,
        pdf_solutions:`PDF-løsninger`,
        data_manager:`Data Manager`,
        other_tools:`Andre værktøjer`,
        free_online_solutions:`Gratis online løsninger`,
        free_online_tools:`Gratis online værktøjer`,
        desktop_freeware:`Desktop Freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS konverter`,
        product_4:`4K konverter`,
        product_5:`MXF konverter`,
        product_6:`3D konverter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter til Mac`,
        product_9:`MTS Converter til Mac`,
        product_10:`4K Converter til Mac`,
        product_11:`MXF Converter til Mac`,
        product_12:`3D Converter til Mac`,
        product_13:`Blu-ray-afspiller`,
        product_14:`Video Enhancer`,
        product_15:`Video Editor`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray-afspiller`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video Editor til Mac`,
        product_23:`DVD Creator til Mac`,
        product_24:`MP4 Converter til Mac`,
        product_25:`Windows datagendannelse`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab iOS Systemgendannelse`,
        product_28:`FoneLab iOS Data Backup &amp; Gendan`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Ødelagt Android-dataudtrækning`,
        product_31:`Android Data Backup &amp; Gendan`,
        product_32:`Mac-datagendannelse`,
        product_33:`Mac FoneLab iPhone datagendannelse`,
        product_34:`Mac FoneLab iOS Systemgendannelse`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Gendan`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; Gendan`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate til Mac`,
        product_40:`PDF-fusion`,
        product_41:`Mac PDF til ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`foneTrans`,
        product_44:`WhatsApp Transfer til iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone renser`,
        product_48:`PPT til videokonverter`,
        product_49:`HEIC konverter`,
        product_50:`iPhone Unlocker`,
        product_51:`Gratis videokonverter online`,
        product_52:`Gratis Audio Converter online`,
        product_53:`Gratis videokompressor online`,
        product_54:`Gratis videofusion online`,
        product_55:`Gratis HEIC-konverter`,
        product_56:`Gratis billedkompressor`,
        product_57:`Gratis fjernelse af vandmærker`,
        product_58:`Gratis PDF-kompressor online`,
        product_59:`Gratis Image Upscaler online`,
        product_60:`Gratis baggrundsfjerner online`,
        product_61:`Gratis billedkonvertering online`,
        product_62:`Gratis PDF PNG Converter online`,
        product_63:`Gratis videokonverter`,
        product_64:`Gratis video til GIF-konverter`,
        product_65:`Gratis Android datagendannelse`,
        product_66:`Slideshow Maker`,
        product_67:`Gratis video editor`,
        product_68:`Gratis PDF-fremviser`,
        product_69:`Skærmoptager`,
        product_70:`Mac skærmoptager`,
        product_71:`Gratis skærmoptager online`,
        product_72:`Gratis lydoptager online`,
        product_73:`Telefon spejl`,
        product_74:`AnyCoord - Location Changer`,
        product_75:`iPhone Password Manager`,
        product_76:`Gratis lydkompressor online`,
        product_77:`Gratis video trimmer online`,
        product_78:`Gratis video Cropper online`,
        product_79:`Gratis videorotator online`,
        product_80:`Gratis video Looper online`,
        product_81:`Gratis PDF JPG Converter online`,
        product_81:`Gratis JPG Word Converter online`,
        category_1:`Fotoredigering`,
        category_2:`Video redigering`,
        category_3:`Viskelæder`,
        category_4:`Medieoptager`,
        posted_by:`Sendt af`,
        to:`til`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Sådan fjerner du tekst fra billeder effektivt`,
        article_2:`Sådan øges billedstørrelsen i KB til MB uden at skade kvaliteten`,
        article_3:`Sådan fjerner du Getty Images Watermark på Windows og Mac`,
        article_4:`Den bedste metode til at konvertere lavopløsningsbillede til høj opløsning online gratis`,
        article_5:`TikTok Guide | Sådan laver du en videocollage på TikTok`,
        article_6:`5 nemme måder at kombinere VOB-filer, du bør kende`,
        article_7:`Sådan opskalerer du video og får nemt højere opløsning`,
        article_8:`Sådan kombinerer du MP4-filer på MacBook Pro/iMac/MacBook og mere`,
        article_9:`2 enkle måder at slette købshistorik på iPhone [Løst]`,
        article_10:`Sådan sletter du Snapchat-billeder permanent fra chat og minder`,
        article_11:`Sådan sletter du Spotlight-søgeforslag og -historik på iPhone/iPad`,
        article_12:`[Løst] Sådan rydder du Instagram-cache på iPhone med 3 nemme måder`,
        article_13:`[Løst] Sådan rettes QuickTime Player, der ikke virker på Mac`,
        article_14:`Sådan optager du systemlyd på Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Effektive måder at ordne PowerPoint-skærmoptagelse, der ikke virker`,
        article_16:`Sådan optager du lyd med VLC [Løst]`,
        faq_title_1:`1. Hvordan rettes 4104 fejl?`,
        faq_title_2:`2. Hvornår kan jeg få mine penge tilbage, efter at anmodningen om tilbagebetaling er godkendt?`,
        faq_title_3:`3. Hvad er "1 års abonnement", og hvordan slår man "Automatisk fornyelsestjeneste" fra?`,
        faq_title_4:`4. Kan jeg annullere min ordre efter at have placeret Aiseesoft-produktet?`,
        faq_title_5:`5. Hvordan sender man en anmeldelse til dig?`,
        faq_info_1:`Dette skyldes den begrænsede licens, du har. Vi vedtager Single License i tilfælde af at registreringskoden bliver stjålet og forhindrer overtrædelse af brugen af registreringskoden. Så Reg Code du har er Single License, som kun kan bruges på én computer. Hvis du bruger det på mere end én computer, vil der være fejl 4104. Du kan ikke bruge det, før vi nulstiller det for dig.`,
        faq_info_2:`Hvis du ændrer eller geninstallerer din computer, kan vi nulstille den for dig. Kontakt venligst kundeservice: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> for at anmode om nulstilling af registreringskoden for dig.`,
        faq_info_3:`Når din anmodning om tilbagebetaling er godkendt, vil det normalt tage 3~7 hverdage, før kreditten vises på din konto. Nogle gange i ferier kan det tage længere tid, men det forventes ikke at tage over 10 dage. Hvis du ikke har modtaget refusionen 20 dage efter godkendelsen, bedes du kontakte os på <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> for at kontrollere refusionen.`,
        faq_info_4:`Automatisk fornyelsesservice betyder, at hvis du køber et 1-års abonnement, vil betalingsplatformen automatisk opkræve beløbet (inklusive gældende afgifter), når produktlicensen udløber. Men betalingsformularen (Swreg eller 2Checkout) sender dig et brev for at minde dig om, at ordren udløber 7 dage før udløbet. E-mailen vil også indeholde instruktioner om, hvordan du slår "Automatisk fornyelsestjeneste" fra.`,
        faq_info_5:`Da ordren behandles automatisk, er det ikke tilladt at annullere ordren. Desuden vil den gratis prøveversion give dig mulighed for at vide mere om Aiseesoft-programmet og kontrollere, om det kan opfylde dine behov, før du køber det.`,
        faq_info_6:`Hvis du virkelig ønsker at få en refusion, skal du tjekke vores <a href="https://da.aiseesoft.com/refund-policy.html">Refusionspolitik</a>.`,
        faq_info_7:`Vi sætter pris på din tid og opmærksomhed til at indsende en anmeldelse til os. Kontakt os (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) for at indsende din anmeldelse, og vi vil seriøst overveje din anmeldelse af vores produktopdateringer.`,
        change_password_success:`Adgangskoden blev ændret`,
        successful_login_title:`Login succes`,
        product_page:`Produktside >>`,
        successful_login_info:`Login gennemført. Luk venligst den aktuelle side og gå tilbage til den oprindelige fane for at fortsætte med følgende proces. Den aktuelle side lukkes automatisk om 5 sekunder. Hvis automatisk lukning eller lukning ved at klikke på knappen "Udført" mislykkes, skal du lukke denne fane direkte.`,
        successful_login_info_firefox:`Login gennemført. Luk venligst den aktuelle side og gå tilbage til den oprindelige fane for at fortsætte med følgende proces.`,
        my_account:`Min konto`,
        my_history:`Min historie`,
        remove_watermark:`Fjern vandmærke`,
        no_history:`Ingen historie`,
        history_all:`Vælg alle`,
        history_open:`Åben`,
        history_down:`Hent`,
        history_delete:`Slet`,
        history_clear:`Ryd ugyldig`,
        images:`billede(r)`,
        use_this_function:`Brug denne funktion >>`,
        hd_downloading:`Fordele ved download af originale HD-billeder:`,
        lifetimeRemaining:`Levetid tilbage`,
        Remaining:`Tilbage`,
        email_verification:`E-mailbekræftelse`,
        email_verification_info:`Vi har sendt bekræftelsesmeddelelsen til din e-mail <span class="email"></span>, og bedes venligst afslutte bekræftelsen. Efter bekræftelse vil fordelene automatisk blive synkroniseret.`,
        wrong_email:`Forkert e-mailadresse?`,
        click_here_to_modify:`Klik her for at ændre`,
        get_more_help:`Få mere hjælp?`,
        click_here:`Klik her`,
        email_verification_info_success:`Tillykke med din bekræftelse af din e-mail-konto.`,
        email_verification_info_error:`Bekræftelse mislykkedes på grund af det udløbne link.`,
        registration_succeeded:`Registreringen lykkedes`,
        registration_succeeded_info_1:`Tillykke! Du har registreret dig. Vi har sendt bekræftelsesmeddelelsen til din e-mail <span class="email"></span>, og bedes afslutte bekræftelsen for at bruge fordelene ved denne e-mail.`,
        registration_succeeded_info_2:`Klik på "Udført" for at vende tilbage til startsiden og bruge dette produkt.`,
        registration_succeeded_info_3:`Klik på "Udført" for at lukke den aktuelle side og vende tilbage til startsiden for følgende handlinger. Hvis lukningen mislykkes, skal du lukke fanen manuelt.`,
        verify_email:`Bekræft e-mail`,
        registered_email_not_verified:`Den registrerede e-mail er ikke blevet bekræftet. Bekræft den med det samme.`,
        email_verification_time_1:`Modtog du ikke en bekræftelses-e-mail?`,
        email_verification_time_2:`Efter`,
        email_verification_time_3:`Klik her for at sende den igen`,
        error_26301:`Fejlkode: 26301, kontakt os venligst`,
        error_26302:`Fejlkode: 26302, kontakt os venligst`,
        error_26303:`E-mail-formatfejl (fejlkode: 26303). Indtast det igen`,
        error_26304:`Adgangskode over 8 tegn anbefales (fejlkode: 26304)`,
        error_26305:`Reuqest mislykkedes (fejlkode: 26305). Prøv det igen`,
        error_26306:`E-mail er blevet registreret. <a href="https://account.aiseesoft.com/login">gå til log ind</a>`,
        error_26307:`Reuqest mislykkedes (fejlkode: 26307). Prøv det igen`,
        error_26308:`Reuqest mislykkedes (fejlkode: 26308). Prøv det igen`,
        error_26401:`Fejlkode: 26401, prøv venligst igen`,
        error_26402:`E-mail er blevet bekræftet (fejlkode: 26402), prøv venligst igen`,
        error_26403:`Reuqest mislykkedes (fejlkode: 26403). Prøv det igen`,
        error_26404:`Forespørgsel mislykkedes (fejlkode: 26404). Prøv det igen`,
        error_26501:`Fejlkode: 26501, kontakt os venligst`,
        error_26502:`Fejlkode: 26502, kontakt os venligst`,
        error_26503:`E-mail-formatfejl (fejlkode: 26503). Indtast det igen`,
        error_26504:`Reuqest mislykkedes (fejlkode: 26504). Prøv det igen`,
        error_26505:`E-mail er ikke blevet registreret, venligst <a href="https://account.aiseesoft.com/register">registrer den først</a>`,
        error_26506:`E-mail er blevet bekræftet.`,
        error_26507:`Reuqest mislykkedes (fejlkode: 26507). Prøv det igen`,
        error_26508:`Bekræftelse mislykkedes (fejlkode: 26508), prøv venligst igen`,
        error_26509:`Forespørgsel mislykkedes (fejlkode: 26509), prøv venligst igen`,
        error_26510:`Fejlkode: 26510, kontakt os venligst`,
        error_26601:`Fejlkode: 26601, kontakt os venligst`,
        error_26602:`Fejlkode: 26602, kontakt os venligst`,
        error_26603:`Reuqest mislykkedes (fejlkode: 26603). Prøv det igen`,
        error_26604:`Fejlkode: 26604, kontakt os venligst`,
        error_26605:`Fejlkode: 26605, kontakt os venligst`,
        error_26701:`Fejlkode: 26701, kontakt os venligst`,
        error_26702:`Reuqest mislykkedes (fejlkode: 26702). Prøv det igen`,
        error_26703:`Fejlkode: 26703, kontakt os venligst`,
        error_26704:`Fejlkode: 26704, kontakt os venligst`,
        error_26705:`Vent på at logge ind (fejlkode: 26705). Prøv det igen`,
        no_cookie:`Du har aktiveret funktionen Bloker alle cookies i din browser, så du kan ikke logge ind. Gå venligst til Indstillinger for at markere feltet Tillad alle cookies.`,
        error_26801:`Fejlkode: 26801, kontakt os venligst`,
        error_26802:`Fejlkode: 26802, kontakt os venligst`,
        error_26803:`Forespørgsel mislykkedes (fejlkode: 26803). Prøv det igen`,
        error_26804:`Forespørgsel mislykkedes (fejlkode: 26804). Prøv det igen`,
        error_order:`Forespørgsel mislykkedes (fejlkode: 27098), prøv venligst igen!`,
        error_order1:`Ordreforespørgslen er ufuldstændig (fejlkode:`,
        error_order2:`）, opdater venligst og prøv det igen.`,
        modify_email_title:`Rediger e-mail`,
        modify_email_info:`Du kan bruge den ændrede e-mail til at logge ind på din konto.`,
        images_per:`Obrázky za`,
        error_26101:`Fejl: 26101. Kontakt os venligst`,
        error_26102:`Fejl: 26102. Kontakt os venligst`,
        error_26103:`Anmodningen mislykkedes (fejlkode:26103). Prøv det igen`,
        error_26104:`Fejlkode: 26104, prøv det igen`,
        error_26105:`Fejlkode: 26105, prøv venligst igen`,
        error_26106:`Sletning mislykkedes (fejlkode: 26106). Prøv det igen`,
        error_26201:`Fejl: 26201. Kontakt os venligst`,
        error_26202:`Anmodningen mislykkedes (fejlkode:26202). Prøv det igen`,
        error_26001:`Fejl: 26001. Kontakt os venligst`,
        error_26002:`Fejl: 26002. Kontakt os venligst`,
        error_26003:`Fejl: 26003. Kontakt os venligst`,
        error_26004:`Fejl: 26004. Kontakt os venligst`,
        error_26005:`Anmodningen mislykkedes (fejlkode:26005). Prøv det igen`,
        error_26006:`Fejlkode: 26006, prøv det igen`,
        error_26008:`Fejl: 26008. Kontakt os venligst`,
        go_to_the_home_page:`Gå til startsiden`,
        error_27101:`Forespørgsel mislykkedes (fejlkode: 27101). Prøv venligst igen`,
        error_27201:`Fejlkode: 27201, kontakt os venligst`,
        error_27202:`Fejlkode: 27202, prøv venligst igen`,
        error_27203:`Forespørgsel mislykkedes (fejlkode: 27203). Prøv det igen`,
        error_27204:`Ugyldig kode (fejlkode: 27204).`,
        error_27205:`Forespørgsel mislykkedes (fejlkode: 27205). Prøv det igen`,
        error_27206:`Forespørgsel mislykkedes (fejlkode: 27206). Prøv det igen`,
        error_27207:`Forespørgsel mislykkedes (fejlkode: 27207). Prøv det igen`,
        no_history_found:`Du har ikke brugt noget værktøj!<a href="/">Opdater</a> eller <a href="https://da.aiseesoft.com/">Gå til det officielle websted</a>`,
        error_25301:`Fejl: 25301. Kontakt os venligst`,
        error_25302:`Fejl: 25302. Kontakt os venligst`,
        error_25303:`Anmodningen mislykkedes (fejlkode: 25303). Prøv det igen`,
        error_25304:`Anmodningen mislykkedes (fejlkode: 25304). Prøv det igen`,
        error_25305:`Anmodningen mislykkedes (fejlkode: 25305). Prøv det igen`,
        error_25306:`Anmodningen mislykkedes (fejlkode: 25306). Prøv det igen`,
        image_upscaler_p:`Fordele ved download af billede uden vandmærke:`,
        Available_for:`Tilgængelig for:`,
        credit_per:`%s kredit(er) pr. HD-billede`,
        still_valid:`Købte abonnement(er) er stadig gyldige`,
        credit:`kredit(er)`,
        pc_3rd_info:`Login successfully. Please go to the application for further operation.`,
        use_online:`Brug onlinetjenesten`,
        use_download:`Brug skrivebordsprogrammet`,
        use_immediately:`Brug med det samme`,
        Use_in_browser:`Brug i browser`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    de:{
        overtime_1:`Fehlercode:`,
        overtime_2:`, bitte versuche es noch einmal`,
        isnetwork:`Internetfehler. Bitte überprüfen Sie und versuchen Sie erneut`,
        email_placeholder:`Email`,
        email_empty:`Bitte geben Sie Ihre E-Mail-Adresse ein`,
        email_not_valid:`Email ist ungültig`,
        email_not_valid_1:`Bitte geben Sie Ihre E-Mail-Adresse ein`,
        email_not_valid_2:`E-Mail ist ungültig, bitte verwenden Sie eine andere Adresse.`,
        email_not_valid_3:`Keine E-Mail-Eingabe`,
        password_placeholder:`Passwort`,
        password_empty:`Bitte geben Sie Ihr Passwort ein`,
        password_not_valid:`Falsches Konto oder Passwort`,
        password_not_valid_1:`Passwort mit mehr als 8 Zeichen ist erforderlich`,
        password_not_valid_2:`Bitte erstellen Sie ein Passwort`,
        password_placeholder_1:`Bitte erstellen Sie Ihr Passwort`,
        password_placeholder_2:`Bestätigen Sie Ihr Passwort`,
        password_placeholder_3:`Neues Passwort erstellen`,
        password_placeholder_4:`Bestätigen Sie neues Passwort`,
        password_placeholder_5:`Altes Passwort eingeben`,
        copy_password_empty:`Bitte bestätigen Sie das Passwort`,
        copy_password_not_valid:`Bitte bestätigen Sie Ihr Passwort`,
        copy_passwords_inconsistent:`Ihre Passwortbestätigung stimmt nicht überein`,
        code_empty:`Bitte geben Sie den Bestätigungscode ein`,
        code_not_valid:`Ungültiger Bestätigungscode`,
        code_placeholder:`Verifizierungs-Schlüssel`,
        not_received_code:`Wenn Ihr Postfach den Verifizierungscode längere Zeit nicht erhalten hat, holen Sie sich bitte den Verifizierungscode erneut.`,
        get_first_code:`Bitte holen Sie sich zuerst den Bestätigungscode.`,
        connect_your_email_placeholder:`Verbinden Sie Ihre E-Mail`,
        last_name_placeholder:`Bitte geben Sie Ihren Nachnamen ein`,
        first_name_placeholder:`Bitte geben Sie Ihren Vornamen ein`,
        address_placeholder:`Bitte geben Sie Ihre Adresse ein`,
        no_code_text:`Wir haben einen Bestätigungscode gesendet. Bitte geben Sie Ihren Code ein. <span class='tips'>Keinen Code erhalten?`,
        no_code_text_1:`1.Bitte stellen Sie sicher, dass die E-Mail-Adresse gültig ist und E-Mails empfangen kann.`,
        no_code_text_2:`2.Da die E-Mail automatisch vom System gesendet wird, kann sie als Spam- oder Junk-E-Mail gekennzeichnet werden. Bitte überprüfen Sie, ob sich die E-Mail im Papierkorb befindet.`,
        no_code_text_3:`3. Können Sie Ihr Problem nicht lösen?`,
        no_code_text_3_span:`Dann klicken Sie hier, um uns zu kontaktieren.`,
        order_no:`Sie haben keine Produkte gekauft. Bei Fragen <a href="https://www.aiseesoft.de/store/">Jetzt einkaufen</a> Oder <a href="https://www.aiseesoft.de/contact.html"> Kontaktieren Sie uns</a>`,
        error_24901:`Das aktuelle Konto hat keine E-Mail-Verknüpfung und kann die Bestellungen nicht finden. Bitte verbinden Sie eine E-Mail.`,
        user_guide:`Benutzerhandbuch>>`,
        download:`Herunterladen`,
        order_number:`Bestellnummer`,
        Refund:`zurückerstattet`,
        Disabled:`Deaktiviert`,
        Normal:`Normal`,
        Modify:`Ändern`,
        Modify_1:`Ändern>>`,
        Connect:`Verbinden`,
        unlink_success:`Verknüpfung erfolgreich aufheben`,
        connect_success:`Erfolgreich verbinden`,
        feedback_title:`Vielen Dank für Ihr Feedback. Bitte notieren Sie Ihre Frage und wir werden uns innerhalb von 24 Stunden bei Ihnen melden.`,
        feedback_thank_you:`Vielen Dank!<br />Ihr Feedback wurde erfolgreich übermittelt.`,
        feedback_email:`Geben Sie Ihre E-Mail hier ein!`,
        feedback_content:`Schreiben Sie hier alle Probleme oder Vorschläge auf, auf die Sie stoßen.`,
        feedback_submit:`einreichen`,
        form_contents:`Sie haben keine Beschreibung eingegeben. Bitte geben Sie sie ein und senden Sie es erneut.`,
        old_password:`Bitte geben Sie altes Passwort ein`,
        new_password:`Bitte erstellen Sie ein neues Passwort`,
        old_new_password:`Das neue Passwort darf nicht mit dem alten identisch sein`,
        incorrect_password:`Falsches Passwort`,
        delete_no:`Jetzt löschen`,
        Caps:`Die Feststelltaste ist aktiviert`,
        Get:`Holen`,
        Done:`Fertig`,
        error_20001:`Fehler: 20001. Bitte melden Sie sich erneut an.`,
        error_20002:`Fehler: 20002. Bitte melden Sie sich erneut an.`,
        error_20003:`Fehler: 20003. Bitte melden Sie sich erneut an.`,
        error_20004:`Anfrage fehlgeschlagen (Fehlercode: 20004). Bitte versuchen Sie es erneut.`,
        error_20005:`Die Anmeldesitzung ist abgelaufen (Fehler: 20005). Bitte melden Sie sich erneut an.`,
        error_20006:`Anfrage fehlgeschlagen (Fehlercode: 20006). Bitte versuchen Sie es erneut.`,
        error_20007:`Die Anmeldesitzung ist abgelaufen (Fehler: 20007). Bitte melden Sie sich erneut an.`,
        error_20008:`Die Anmeldesitzung ist abgelaufen (Fehler: 20008). Bitte melden Sie sich erneut an.`,
        error_20009:`Die Anmeldesitzung ist abgelaufen (Fehler: 20009). Bitte melden Sie sich erneut an.`,
        error_20101:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20101)`,
        error_20102:`E-Mail ist ungültig (Fehlercode: 20102)`,
        error_20103:`Anfrage fehlgeschlagen (Fehlercode: 20103). Bitte versuchen Sie es erneut`,
        error_20104:`E-Mail wird bereits verwendet, bitte <a href="https://account.aiseesoft.com/login">melden Sie sich an</a> oder registrieren Sie sich mit einer neuen`,
        error_20105:`Anfrage fehlgeschlagen (Fehlercode: 20105). Bitte versuchen Sie es erneut`,
        error_20106:`Fehler beim Senden der E-Mail, bitte versuchen Sie es erneut`,
        error_20201:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20201)`,
        error_20202:`Bitte geben Sie Ihr Passwort ein (Fehlercode: 20202)`,
        error_20203:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20203)`,
        error_20204:`E-Mail ist ungültig (Fehlercode: 20204)`,
        error_20205:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 20205)`,
        error_20206:`Anfrage fehlgeschlagen (Fehlercode: 20206). Bitte versuchen Sie es erneut`,
        error_20207:`Ungültiger Bestätigungscode`,
        error_20208:`Anfrage fehlgeschlagen (Fehlercode: 20208). Bitte versuchen Sie es erneut`,
        error_20209:`Anfrage fehlgeschlagen (Fehlercode: 20209). Bitte versuchen Sie es erneut`,
        error_20301:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20301)`,
        error_20302:`Fehler: 20302. Bitte kontaktieren Sie uns`,
        error_20303:`E-Mail ist ungültig (Fehlercode: 20303)`,
        error_20304:`Anfrage fehlgeschlagen (Fehlercode: 20304). Bitte versuchen Sie es erneut`,
        error_20305:`Konto existiert nicht. Bitte melden Sie sich erneut an oder <a href="https://account.aiseesoft.com/register">Erstellen Sie es</a> zuerst.`,
        error_20306:`Noch kein Passwort. Verwenden Sie <a href="https://account.aiseesoft.com/passwordless-login">Passwortlose Anmeldung</a> oder <a href="create-password">Erstellen Sie ein Passwort</a> und melden Sie sich an.`,
        error_20308:`Anfrage fehlgeschlagen (Fehlercode: 20308). Bitte versuchen Sie es erneut`,
        error_20401:`Abmelden fehlgeschlagen (Fehlercode: 20401). Bitte versuchen Sie es erneut`,
        error_20501:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20501)`,
        error_20502:`E-Mail ist ungültig (Fehlercode: 20502)`,
        error_20503:`Anfrage fehlgeschlagen (Fehlercode: 20503). Bitte versuchen Sie es erneut`,
        error_20504:`E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut.`,
        error_20601:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20601)`,
        error_20602:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20602)`,
        error_20603:`E-Mail ist ungültig (Fehlercode: 20603)`,
        error_20604:`Anfrage fehlgeschlagen (Fehlercode: 20604). Bitte versuchen Sie es erneut`,
        error_20606:`Anfrage fehlgeschlagen (Fehlercode: 20606). Bitte versuchen Sie es erneut`,
        error_20607:`Anfrage fehlgeschlagen (Fehlercode: 20607). Bitte versuchen Sie es erneut`,
        error_20608:`Anfrage fehlgeschlagen (Fehlercode: 20608). Bitte versuchen Sie es erneut`,
        error_20701:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20701)`,
        error_20702:`E-Mail ist ungültig (Fehlercode: 20702)`,
        error_20703:`Anfrage fehlgeschlagen (Fehlercode: 20703). Bitte versuchen Sie es erneut`,
        error_20704:`Konto existiert nicht. Bitte melden Sie sich erneut an oder <a href="https://account.aiseesoft.com/register">Erstellen Sie es</a> zuerst.`,
        error_20705:`Anfrage fehlgeschlagen (Fehlercode: 20705). Bitte versuchen Sie es erneut`,
        error_20706:`E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut`,
        error_20801:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 20801)`,
        error_20802:`Fehler: 20802. Bitte kontaktieren Sie uns`,
        error_20803:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 20803)`,
        error_20804:`E-Mail ist ungültig (Fehlercode: 20804)`,
        error_20805:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 20805)`,
        error_20806:`Anfrage fehlgeschlagen (Fehlercode: 20806). Bitte versuchen Sie es erneut`,
        error_20808:`Anfrage fehlgeschlagen (Fehlercode: 20808). Bitte versuchen Sie es erneut`,
        error_20901:`Anfrage fehlgeschlagen (Fehlercode: 20901). Bitte versuchen Sie es erneut`,
        error_20902:`Anfrage fehlgeschlagen (Fehlercode: 20902). Bitte versuchen Sie es erneut`,
        error_21000:`Änderungen werden gespeichert`,
        error_21001:`Es werden keine Informationen übermittelt (Fehlercode: 21001)`,
        error_21002:`Anfrage fehlgeschlagen (Fehlercode: 21002). Bitte versuchen Sie es erneut`,
        error_21101:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 21101)`,
        error_21102:`E-Mail ist ungültig (Fehlercode: 21102)`,
        error_21103:`Anfrage fehlgeschlagen (Fehlercode: 21103), bitte erneut versuchen`,
        error_21104:`E-Mail ist bereits verbunden, bitte verwenden Sie eine neue`,
        error_21105:`Anfrage fehlgeschlagen (Fehlercode: 21105), bitte erneut versuchen`,
        error_21106:`E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es erneut`,
        error_21201:`Bitte geben Sie Ihre E-Mail ein (Fehlercode: 21201)`,
        error_21202:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 21202)`,
        error_21203:`E-Mail ist ungültig (Fehlercode: 21203)`,
        error_21204:`Fehler: 21204. Bitte kontaktieren Sie uns`,
        error_21205:`Fehler: 21205. Bitte kontaktieren Sie uns`,
        error_21206:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 21206)`,
        error_21207:`Anfrage fehlgeschlagen (Fehlercode: 21207). Bitte versuchen Sie es erneut`,
        error_21209:`Anfrage fehlgeschlagen (Fehlercode: 21209). Bitte versuchen Sie es erneut`,
        error_21301:`Bitte geben Sie altes Passwort ein (Fehlercode: 21301)`,
        error_21302:`Bitte erstellen Sie ein neues Passwort (Fehlercode: 21302)`,
        error_21303:`Das neue Passwort darf nicht mit dem alten identisch sein. (Fehler: 21303)`,
        error_21304:`Passwort mit mehr als 8 Zeichen ist erforderlich (Fehlercode: 21304)`,
        error_21306:`Anfrage fehlgeschlagen (Fehlercode: 21306). Bitte versuchen Sie es erneut`,
        error_21402:`Anfrage fehlgeschlagen (Fehlercode: 21402). Bitte versuchen Sie es erneut`,
        error_21403:`Bestätigungscode konnte nicht gesendet werden. Bitte senden Sie es erneut`,
        error_21500:`Das Konto wurde gelöscht`,
        error_21501:`Bitte geben Sie den Bestätigungscode ein (Fehlercode: 21501)`,
        error_21502:`Die Anmeldesitzung ist abgelaufen (Fehler: 21502). Bitte melden Sie sich erneut an.`,
        error_21503:`Anfrage fehlgeschlagen (Fehlercode: 21503). Bitte versuchen Sie es erneut`,
        error_21505:`Anfrage fehlgeschlagen (Fehlercode: 21505), bitte versuchen Sie es erneut`,
        error_21601:`Fehler: 20601. Bitte kontaktieren Sie uns`,
        error_21602:`Ungültige Bestätigung (Fehler: 20602). Bitte versuchen Sie es erneut.`,
        error_21603:`Fehler: 20603. Bitte versuchen Sie es erneut`,
        error_21604:`Anfrage fehlgeschlagen (Fehlercode: 21604). Bitte versuchen Sie es erneut`,
        error_21606:`Anfrage fehlgeschlagen (Fehlercode: 21606). Bitte versuchen Sie es erneut`,
        error_21611:`Anfrage fehlgeschlagen (Fehlercode: 21611). Bitte versuchen Sie es erneut`,
        error_21801:`Fehler: 21801. Bitte kontaktieren Sie uns`,
        error_21802:`Anfrage fehlgeschlagen (Fehler: 21802). Bitte versuchen Sie es erneut`,
        error_21803:`Fehler: 21803. Bitte versuchen Sie es erneut`,
        error_21804:`Anfrage fehlgeschlagen (Fehlercode: 21804). Bitte versuchen Sie es erneut`,
        error_21806:`Fehler: 21806. Bitte versuchen Sie es erneut`,
        error_21807:`Fehler: 21807. Bitte kontaktieren Sie uns`,
        error_21808:`Fehler: 21808. Bitte kontaktieren Sie uns`,
        error_21809:`Fehler: 21809. Bitte kontaktieren Sie uns`,
        error_21810:`Fehler: 21810. Bitte kontaktieren Sie uns`,
        error_21811:`Fehler: 21811. Bitte kontaktieren Sie uns`,
        error_21812:`Fehler: 21812. Bitte kontaktieren Sie uns`,
        error_21813:`Anfrage fehlgeschlagen (Fehlercode: 21813). Bitte versuchen Sie es erneut`,
        error_21814:`Fehler: 21814. Bitte kontaktieren Sie uns`,
        error_21815:`Anfrage fehlgeschlagen (Fehlercode: 21815). Bitte versuchen Sie es erneut`,
        error_21816:`Fehler: 21816. Bitte kontaktieren Sie uns`,
        error_21817:`Fehler: 21817. Bitte kontaktieren Sie uns`,
        error_21818:`Fehler: 21818. Bitte kontaktieren Sie uns`,
        error_21819:`Anfrage fehlgeschlagen (Fehlercode: 21819). Bitte versuchen Sie es erneut`,
        error_21820:`Fehler: 21820. Bitte kontaktieren Sie uns`,
        error_21821:`Fehler: 21821. Bitte kontaktieren Sie uns`,
        error_21822:`Fehler: 21822. Bitte kontaktieren Sie uns`,
        error_21823:`Anfrage fehlgeschlagen (Fehlercode: 21823). Bitte versuchen Sie es erneut`,
        error_21824:`Anfrage fehlgeschlagen (Fehlercode: 21824). Bitte versuchen Sie es erneut`,
        error_21825:`Anfrage fehlgeschlagen (Fehlercode: 21825). Bitte versuchen Sie es erneut`,
        error_21826:`Anfrage fehlgeschlagen (Fehlercode: 21826). Bitte versuchen Sie es erneut`,
        error_21828:`Fehler: 21828. Bitte kontaktieren Sie uns`,
        error_21901:`Fehler: 21901. Bitte kontaktieren Sie uns`,
        error_21902:`Anfrage fehlgeschlagen (Fehlercode: 21902). Bitte versuchen Sie es erneut`,
        error_21903:`Der Kontostatus hat sich geändert (Fehlercode: 21903), bitte aktualisieren Sie die Seite und versuchen Sie es erneut`,
        error_21904:`Fehler: 21904. Bitte versuchen Sie es erneut`,
        error_21905:`Fehler: 21905. Bitte versuchen Sie es erneut`,
        error_21906:`Anfrage fehlgeschlagen (Fehlercode: 21906). Bitte versuchen Sie es erneut`,
        error_21907:`Das Google-Konto wurde mit einem anderen Konto verknüpft`,
        error_21908:`Anfrage fehlgeschlagen (Fehlercode: 21908). Bitte versuchen Sie es erneut`,
        error_22001:`Anfrage fehlgeschlagen (Fehlercode: 22001). Bitte versuchen Sie es erneut`,
        error_22002:`Aufheben der Verknüpfung ohne zusätzliche Anmeldung fehlgeschlagen`,
        error_22003:`Anfrage fehlgeschlagen (Fehlercode: 22003). Bitte versuchen Sie es erneut`,
        error_22101:`Fehler: 22101. Bitte kontaktieren Sie uns`,
        error_22102:`Der Kontostatus hat sich geändert (Fehlercode: 22102), bitte aktualisieren Sie die Seite und versuchen Sie es erneut`,
        error_22103:`Anfrage fehlgeschlagen (Fehlercode: 22103). Bitte versuchen Sie es erneut`,
        error_22104:`Der Kontostatus hat sich geändert (Fehlercode: 22104), bitte aktualisieren Sie die Seite und versuchen Sie es erneut`,
        error_22105:`Fehler: 22105. Bitte versuchen Sie es erneut`,
        error_22106:`Fehler: 22106. Bitte versuchen Sie es erneut`,
        error_22107:`Fehler: 22107. Bitte kontaktieren Sie uns`,
        error_22108:`Anfrage fehlgeschlagen (Fehlercode: 22108). Bitte versuchen Sie es erneut`,
        error_22201:`Fehler: 22201. Bitte kontaktieren Sie uns`,
        error_22202:`Ungültige Verifizierung (Fehler: 22202). Bitte versuchen Sie es erneut.`,
        error_22203:`Fehler: 22203. Bitte versuchen Sie es erneut."`,
        error_22204:`Anfrage fehlgeschlagen (Fehlercode: 22204). Bitte versuchen Sie es erneut`,
        error_22206:`Anfrage fehlgeschlagen (Fehlercode: 22206). Bitte versuchen Sie es erneut`,
        error_22401:`Fehler: 22401. Bitte kontaktieren Sie uns`,
        error_22402:`Ungültige Bestätigung (Fehler: 22402). Bitte versuchen Sie es erneut.`,
        error_22403:`Fehler: 22403. Bitte versuchen Sie es erneut`,
        error_22404:`Anfrage fehlgeschlagen (Fehlercode: 22404). Bitte versuchen Sie es erneut`,
        error_22405:`Facebook-Konto wurde mit anderer E-Mail verknüpft`,
        error_22406:`Fehler: 22406. Bitte versuchen Sie es erneut`,
        error_22407:`Fehler: 22407. Bitte kontaktieren Sie uns`,
        error_22408:`Fehler: 22408. Bitte kontaktieren Sie uns`,
        error_22409:`Fehler: 22409. Bitte kontaktieren Sie uns`,
        error_22410:`Fehler: 224010. Bitte kontaktieren Sie uns`,
        error_22411:`Fehler: 224011. Bitte kontaktieren Sie uns`,
        error_22412:`Fehler: 224012. Bitte kontaktieren Sie uns`,
        error_22413:`Fehler: 22413. Bitte kontaktieren Sie uns`,
        error_22414:`Anfrage fehlgeschlagen (Fehlercode: 22414). Bitte versuchen Sie es erneut`,
        error_22415:`Fehler: 22415. Bitte kontaktieren Sie uns`,
        error_22416:`Fehler: 22416. Bitte kontaktieren Sie uns`,
        error_22417:`Fehler: 22417. Bitte kontaktieren Sie uns`,
        error_22418:`Anfrage fehlgeschlagen (Fehlercode: 22418). Bitte versuchen Sie es erneut`,
        error_22419:`Fehler: 22419. Bitte kontaktieren Sie uns`,
        error_22420:`Fehler: 22420. Bitte kontaktieren Sie uns`,
        error_22421:`Fehler: 22421. Bitte kontaktieren Sie uns`,
        error_22422:`Anfrage fehlgeschlagen (Fehlercode: 22422). Bitte versuchen Sie es erneut`,
        error_22423:`Anfrage fehlgeschlagen (Fehlercode: 22423). Bitte versuchen Sie es erneut`,
        error_22424:`Anfrage fehlgeschlagen (Fehlercode: 22424). Bitte versuchen Sie es erneut`,
        error_22425:`Anfrage fehlgeschlagen (Fehlercode: 22425). Bitte versuchen Sie es erneut`,
        error_20098:`Fehlercode: 20098. Wenn Sie im privaten Modus surfen, wechseln Sie bitte in den normalen Modus und versuchen Sie es erneut.`,
        error_22298:`Anfrage an Google fehlgeschlagen (Fehler: 22298). Bitte versuchen Sie es erneut.`,
        error_22498:`Anfrage an Facebook fehlgeschlagen (Fehler: 22498). Bitte versuchen Sie es erneut.`,
        error_24902:`Anfrage fehlgeschlagen (Fehlercode: 24902). Bitte versuchen Sie es erneut`,
        error_24903:`Anfrage fehlgeschlagen (Fehlercode: 24903). Bitte versuchen Sie es erneut`,
        error_24904:`Anfrage fehlgeschlagen (Fehlercode: 24904). Bitte versuchen Sie es erneut`,
        error_24905:`Anfrage fehlgeschlagen (Fehlercode: 24905). Bitte versuchen Sie es erneut`,
        login_title:`Melden Sie sich bei Aiseesoft an`,
        password_placeholder:`Passwort`,
        log_in:`Einloggen`,
        no_account:`Kein Account?`,
        create_it:`Erstellen Sie es`,
        or_log_in_with:`Oder loggen Sie sich mit`,
        passwordless_login:`Passwortfreies Login`,
        log_in_done:`Anmelden Fertig`,
        three_rd_account_connect_info:`Herzliche Glückwünsche! Sie haben sich erfolgreich angemeldet. Jetzt können Sie ein E-Mail-Konto verbinden, das für die zukünftige Anmeldung verwendet wird.`,
        connect_now:`Jetzt verbinden`,
        see_my_account:`Mein Konto sehen`,
        three_rd_login_merge_account:`Die E-Mail-Adresse des Drittanbieterkontos wurde registriert. Möchten Sie sich direkt mit dieser E-Mail-Adresse verbinden und anmelden?`,
        connect_log_in:`Verbinden und einloggen`,
        create_an_account:`Ein Konto erstellen`,
        connect_account:`Konto verbinden`,
        connect_account_info:`Nach der Verbindung können Sie sich mit diesem Konto anmelden.`,
        back_to_log_in:`Zurück zur Anmeldung`,
        create_password:`Passwort erstellen`,
        create_now:`Jetzt erstellen`,
        password_login_subtitle:`Passwortlose Anmeldung mit E-Mail`,
        account_login:`Account Login`,
        rights:`Durch das Erstellen dieses Kontos stimmen Sie den <a href="https://www.aiseesoft.de/terms-conditions.html">Nutzungsbedingungen</a> und <a href="https://www.aiseesoft.de/privacy-policy.html">Datenschutzerklärung</a>`,
        passwordless_login_done:`Passwortlose Anmeldung abgeschlossen`,
        passwordless_login_info:`Herzlichen Glückwunsch, Sie haben die passwortlose Anmeldung erfolgreich abgeschlossen. Sie können für dieses Konto ein Passwort erstellen und sich zukünftig mit dem Konto und Passwort anmelden. <a href="/create-password" style="display: initial;">Jetzt erstellen</a>`,
        sign_up:`Anmeldung`,
        register_info:`Ihren Account erstellen`,
        reset_now:`Jetzt zurücksetzen`,
        forgot_password:`Passwort vergessen`,
        reset_password_subtitle:`Verwenden Sie Ihre Konto-E-Mail, um das Passwort zurückzusetzen`,
        plan_products:`Pläne und Produkte`,
        nick_name:`Nutzername`,
        email:`Email`,
        my_products:`Meine Produkte`,
        my_orders:`Meine Bestellungen`,
        unlink:`Verknüpfung aufheben`,
        link:`Verknüpfung`,
        connected_accounts:`Verbundene Konten`,
        last_name:`Nachname`,
        first_name:`Vorname`,
        Gender:`Geschlecht`,
        Birth:`Geburt`,
        Month:`Monat`,
        Year:`Jahr`,
        Country_Region:`Land/Region`,
        Address:`Die Anschrift`,
        Save:`Speichern`,
        Date:`Datum`,
        Male:`Männlich`,
        Female:`Weiblich`,
        Unspecified:`Nicht spezifiziert`,
        Security:`Sicherheit`,
        change_password:`Passwort ändern`,
        change_now:`Jetzt ändern`,
        connect_email:`E-Mail verbinden`,
        delete_account:`Konto löschen`,
        delete_account_info:`Wenn Ihr Konto gelöscht wird, werden alle Plan- und Produktdaten Ihres Kontos dauerhaft gelöscht und Sie können es möglicherweise nicht wiederherstellen. Wir empfehlen Ihnen, mit Vorsicht vorzugehen.`,
        Delete:`Löschen`,
        Logout:`Ausloggen`,
        my_profile:`Mein Profil`,
        guides_faqs:`Anleitungen & FAQs`,
        More:`Mehr`,
        guides:`Anleitungen`,
        register:`registrieren`,
        hot_faq:`Beliebte FAQ`,
        Contents:`Inhalt:`,
        contact_us:`Kontaktieren Sie uns>>`,
        plan:`Plan`,
        unregistered:`Nicht registriert`,
        buy_more:`Mehr kaufen`,
        buy_again:`Nochmal kaufen`,
        buy_now:`Jetzt kaufen`,
        free_no_limit:`Kostenlos & unbegrenzt`,
        expired:`Abgelaufen`,
        lifetime:`Lebenszeit`,
        remain:`Bleiben übrig`,
        day_s:`Tage)`,
        error_24801:`Anfrage fehlgeschlagen (Fehlercode: 24801). Bitte versuchen Sie es erneut`,
        no_app_found:`Keine App gefunden！<a href="/">Aktualisieren</a> oder <a href="https://www.aiseesoft.de">Gehen Sie zur offiziellen Website</a>`,
        get_more:`Mehr erfahren >>`,
        edit_photo:`Foto bearbeiten`,
        select_photo:`Foto auswählen`,
        change_photo:`Foto ändern`,
        cancel:`Abbrechen`,
        hide_password:`Passwort verbergen`,
        show_password:`Passwort anzeigen`,
        zoom_in:`Hineinzoomen`,
        zoom_out:`Rauszoomen`,
        rotate:`Drehen`,
        horizontal_flip:`Horizontaler Flip`,
        vertical_flip:`Vertikaler Flip`,
        country:`Land`,
        country_1:`Wählen Sie Ihr Land/Ihre Region aus`,
        country_2:`aland-Insel`,
        country_3:`Afghanistan`,
        country_4:`Albanien`,
        country_5:`Algerien`,
        country_6:`Amerikanischen Samoa-Inseln`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktis`,
        country_11:`Antigua und Barbuda`,
        country_12:`Argentinien`,
        country_13:`Armenien`,
        country_14:`Aruba`,
        country_15:`Australien`,
        country_16:`Österreich`,
        country_17:`Aserbaidschan`,
        country_18:`Bahrain`,
        country_19:`Bangladesch`,
        country_20:`Barbados`,
        country_21:`Weißrussland`,
        country_22:`Belgien`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivien`,
        country_28:`Bosnien & Herzegowina`,
        country_29:`Botswana`,
        country_30:`Bouvet-Insel`,
        country_31:`Brasilien`,
        country_32:`Britisches Territorium des Indischen Ozeans`,
        country_33:`Britische Jungferninseln`,
        country_34:`Brunei`,
        country_35:`Bulgarien`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodscha`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Kap Verde`,
        country_42:`Karibik Niederlande`,
        country_43:`Cayman Inseln`,
        country_44:`Zentralafrikanische Republik`,
        country_45:`Tschad`,
        country_46:`Chile`,
        country_47:`China`,
        country_48:`Weihnachtsinsel`,
        country_49:`Kokosinseln (Keelinginseln)`,
        country_50:`Kolumbien`,
        country_51:`Cookinseln`,
        country_52:`Costa Rica`,
        country_53:`"Côte d'Ivoire"`,
        country_54:`Kroatien`,
        country_55:`Kuba`,
        country_56:`Zypern`,
        country_57:`Tschechien`,
        country_58:`Demokratische Republik Kongo`,
        country_59:`Dänemark`,
        country_60:`Dschibuti`,
        country_61:`Dominica`,
        country_62:`Dominikanische Republik`,
        country_63:`Ecuador`,
        country_64:`Ägypten`,
        country_65:`El Salvador`,
        country_66:`Äquatorialguinea`,
        country_67:`Eritrea`,
        country_68:`Estland`,
        country_69:`Äthiopien`,
        country_70:`Falkland Inseln`,
        country_71:`Färöer Inseln`,
        country_72:`Die Verbündeten Staaten von Micronesia`,
        country_73:`Fidschi`,
        country_74:`Finnland`,
        country_75:`Frankreich`,
        country_76:`Französisch-Guayana`,
        country_77:`Französisch Polynesien`,
        country_78:`Südfranzösische Territorien`,
        country_79:`Gabun`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Deutschland`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Großbritannien (Großbritannien; England)`,
        country_86:`Griechenland`,
        country_87:`Grönland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island und McDonald-Inseln`,
        country_98:`Honduras`,
        country_99:`Hongkong`,
        country_100:`Ungarn`,
        country_101:`Island`,
        country_102:`Indien`,
        country_103:`Indonesien`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italien`,
        country_110:`Jamaika`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordanien`,
        country_114:`Kasachstan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirgisistan`,
        country_119:`Laos`,
        country_120:`Lettland`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libyen`,
        country_125:`Liechtenstein`,
        country_126:`Litauen`,
        country_127:`Luxemburg`,
        country_128:`Macau`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Malediven`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshallinseln`,
        country_136:`Martinique`,
        country_137:`Mauretanien`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexiko`,
        country_141:`Moldawien`,
        country_142:`Monaco`,
        country_143:`Mongolei`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marokko`,
        country_147:`Mosambik`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Niederlande`,
        country_153:`Neu-Kaledonien`,
        country_154:`Neuseeland`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolkinsel`,
        country_160:`Nord Korea`,
        country_161:`Nördliche Marianneninseln`,
        country_162:`Norwegen`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palästinensische Gebiete`,
        country_167:`Panama`,
        country_168:`Papua Neu-Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairninseln`,
        country_172:`Polen`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Katar`,
        country_176:`Republik Mazedonien (FYROM)`,
        country_177:`Republik Kongo`,
        country_178:`Wiedervereinigung`,
        country_179:`Rumänien`,
        country_180:`Russische Föderation`,
        country_181:`Ruanda`,
        country_182:`Saint-Barthélemy`,
        country_183:`Saint-Martin (Frankreich)`,
        country_184:`Saint-Pierre und Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome & Principe`,
        country_188:`Saudi Arabien`,
        country_189:`Senegal`,
        country_190:`Serbien`,
        country_191:`Seychellen`,
        country_192:`Sierra Leone`,
        country_193:`Singapur`,
        country_194:`Slowakei`,
        country_195:`Slowenien`,
        country_196:`Salomon-Inseln`,
        country_197:`Somalia`,
        country_198:`Südafrika`,
        country_199:`Südgeorgien und die Südliche Sandwichinsel`,
        country_200:`Südkorea`,
        country_201:`Südsudan`,
        country_202:`Spanien`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Abhängigkeiten`,
        country_205:`St. Kitts & Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent und die Grenadinen`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Swasiland`,
        country_211:`Schweden`,
        country_212:`Schweiz`,
        country_213:`Syrien`,
        country_214:`Taiwan`,
        country_215:`Tadschikistan`,
        country_216:`Tansania`,
        country_217:`Vorlage:Länderdaten SJM Spitzbergen`,
        country_218:`Thailand`,
        country_219:`Die Bahamas`,
        country_220:`Die Komoren`,
        country_221:`Die Phillipinen`,
        country_222:`Timor-Leste (Osttimor)`,
        country_223:`Gehen`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunesien`,
        country_228:`Truthahn`,
        country_229:`Turkmenistan`,
        country_230:`Turks- und Caicosinseln`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraine`,
        country_234:`Vereinigte Arabische Emirate`,
        country_235:`Kleinere vorgelagerte Inseln der Vereinigten Staaten`,
        country_236:`Vereinigte Staaten von Amerika (USA)`,
        country_237:`Amerikanische Jungferninseln`,
        country_238:`Uruguay`,
        country_239:`Usbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikanstadt (Der Heilige Stuhl)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis und Futuna`,
        country_245:`Westsahara`,
        country_246:`Jemen`,
        country_247:`Sambia`,
        country_248:`Zimbabwe`,
        google_login:`Anmeldung mit Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Alle Rechte vorbehalten.`,
        State:`Status`,
        Activation_code:`Aktivierungscode`,
        Question:`Liste alle APPs auf, die Sie eingeloggt haben`,
        Copy_complete:`Kopieren abgeschlossen`,

        store:`Store`,
        resource:`Anleitungen`,
        support:`Support`,
        video_converter:`Video Converter`,
        multimedia:`Multimedia`,
        data_recovery:`Data Recovery`,
        utility:`Utility`,
        free_tools:`Free Tools`,
        for_windows:`Für Windows`,
        for_mac:`Für Mac`,
        pdf_solutions:`PDF-Lösungen`,
        data_manager:`Datenmanager`,
        other_tools:`Andere Werkzeuge`,
        free_online_solutions:`Kostenlose Online-Lösungen`,
        free_online_tools:`Kostenlose Online-Tools`,
        desktop_freeware:`Desktop-Freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS Konverter`,
        product_4:`4K Konverter`,
        product_5:`MXF Konverter`,
        product_6:`3D Konverter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter für Mac`,
        product_9:`MTS Converter für Mac`,
        product_10:`4K Converter für Mac`,
        product_11:`MXF Converter für Mac`,
        product_12:`3D Converter für Mac`,
        product_13:`Blu-ray Player`,
        product_14:`Video Enhancer`,
        product_15:`Video Editor`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray Player`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video Editor für Mac`,
        product_23:`DVD Creator für Mac`,
        product_24:`MP4 Converter für Mac`,
        product_25:`Data Recovery`,
        product_26:`FoneLab iPhone Datenrettung`,
        product_27:`iOS Systemwiederherstellung`,
        product_28:`iOS Datensicherung &amp; Wiederherstellung`,
        product_29:`FoneLab Android Datenrettung`,
        product_30:`Android Datenextrahierung`,
        product_31:`Android Datensicherung &amp; Wiederherstellung`,
        product_32:`Mac Data Recovery`,
        product_33:`Mac FoneLab iPhone Datenrettung`,
        product_34:`iOS Systemwiederherstellung für Mac`,
        product_35:`iOS Datensicherung &amp; Wiederherstellung für Mac`,
        product_36:`Mac FoneLab Android Datenrettung`,
        product_37:`Android Datensicherung &amp; Wiederherstellung für Mac`,
        product_38:`PDF Converter Ultimate`,
        product_39:`Mac PDF Converter Ultimate`,
        product_40:`PDF Merger`,
        product_41:`Mac PDF to ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer für iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`PPT to Video Converter`,
        product_49:`HEIC Converter`,
        product_50:`iPhone Unlocker`,
        product_51:`Kostenloser Online-Videokonverter`,
        product_52:`Kostenloser Online-Audiokonverter`,
        product_53:`Kostenloser Online-Videokompressor`,
        product_54:`Kostenlose Online-Videozusammenführung`,
        product_55:`Kostenloser HEIC-Konverter`,
        product_56:`Kostenloser Bildkompressor`,
        product_57:`Kostenloser Wasserzeichen-Entferner`,
        product_58:`Kostenloser PDF-Kompressor Online`,
        product_59:`Kostenloser Online-Bild-Upscaler`,
        product_60:`Kostenloser Online-Hintergrundentferner`,
        product_61:`Kostenloser Online-Bildkonverter`,
        product_62:`Kostenloser Online-PDF-PNG-Konverter`,
        product_63:`Kostenloser Videokonverter`,
        product_64:`Kostenloser Video-zu-GIF-Konverter`,
        product_65:`Kostenlose Android-Datenwiederherstellung`,
        product_66:`Diashow-Ersteller`,
        product_67:`Kostenloser Video-Editor`,
        product_68:`Kostenloser PDF-Viewer`,
        product_69:`Bildschirm recorder`,
        product_70:`Mac-Bildschirmrekorder`,
        product_71:`Kostenloser Online-Bildschirmrekorder`,
        product_72:`Kostenloser Online-Audiorecorder`,
        product_73:`Telefonspiegel`,
        product_74:`AnyCoord - Standortwechsler`,
        product_75:`iPhone Passwort-Manager`,
        product_76:`Kostenloser Online-Audiokompressor`,
        product_77:`Kostenloser Video-Trimmer online`,
        product_78:`Kostenloser Online-Videoschneider`,
        product_79:`Kostenloser Online-Videorotator`,
        product_80:`Kostenloser Video-Looper online`,
        product_81:`Kostenloser Online-PDF-JPG-Konverter`,
        product_81:`Kostenloser JPG-Word-Konverter online`,
        category_1:`Fotobearbeitung`,
        category_2:`Videobearbeitung`,
        category_3:`Radiergummi`,
        category_4:`Medienrekorder`,
        posted_by:`Geschrieben von`,
        to:`zu`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`So entfernen Sie effizient Text aus Bildern`,
        article_2:`So erhöhen Sie die Bildgröße in KB auf MB, ohne die Qualität zu beeinträchtigen`,
        article_3:`So entfernen Sie das Getty Images-Wasserzeichen unter Windows und Mac`,
        article_4:`Die beste Methode, um ein Bild mit niedriger Auflösung online kostenlos in ein hochauflösendes zu konvertieren`,
        article_5:`TikTok-Anleitung | So erstellen Sie eine Videocollage auf TikTok`,
        article_6:`5 einfache Möglichkeiten zum Kombinieren von VOB-Dateien, die Sie kennen sollten`,
        article_7:`So skalieren Sie Videos und erhalten einfach eine höhere Auflösung`,
        article_8:`So kombinieren Sie MP4-Dateien auf MacBook Pro/iMac/MacBook und mehr`,
        article_9:`2 einfache Möglichkeiten zum Löschen des Kaufverlaufs auf dem iPhone [Gelöst]`,
        article_10:`So löschen Sie Snapchat-Bilder dauerhaft aus Chat und Erinnerungen`,
        article_11:`So löschen Sie Spotlight-Suchvorschläge und den Verlauf auf dem iPhone/iPad`,
        article_12:`[Gelöst] So löschen Sie den Instagram-Cache auf dem iPhone mit 3 einfachen Möglichkeiten`,
        article_13:`[Gelöst] So beheben Sie, dass QuickTime Player auf dem Mac nicht funktioniert`,
        article_14:`So nehmen Sie Systemaudio auf einem Mac auf (Big Sur/Catalina/Mojave …)`,
        article_15:`Effektive Möglichkeiten, um zu beheben, dass die PowerPoint-Bildschirmaufzeichnung nicht funktioniert`,
        article_16:`So nehmen Sie Audio mit VLC auf [Gelöst]`,
        faq_title_1:`1. Wie behebt man den 4104-Fehler?`,
        faq_title_2:`2. Wann kann ich mein Geld zurückerhalten, nachdem der Rückerstattungsantrag genehmigt wurde?`,
        faq_title_3:`3. Was ist ein "1 Jahresabonnement" und wie kann man den "Automatischen Verlängerungsdienst" deaktivieren?`,
        faq_title_4:`4. Kann ich meine Bestellung stornieren, nachdem ich ein Aiseesoft-Produkt aufgegeben habe?`,
        faq_title_5:`5. Wie reiche ich Ihnen eine Bewertung ein?`,
        faq_info_1:`Das liegt an der begrenzten Lizenz, die Sie erhalten haben. Wir übernehmen die Einzellizenz für den Fall, dass der Registrierungscode gestohlen wird und verhindern die Verletzung des Registrierungscodes. Der Reg-Code, den Sie erhalten haben, ist eine Einzellizenz, die nur auf einem Computer verwendet werden kann. Wenn Sie es auf mehr als einem Computer verwenden, wird Fehler 4104 angezeigt. Sie können es nicht verwenden, bis wir es für Sie zurückgesetzt haben.`,
        faq_info_2:`Wenn Sie Ihren Computer ändern oder neu installieren, können wir ihn für Sie zurücksetzen. Bitte wenden Sie sich an den Kundenservice: <a href="mailto:support@aiseesoft.de">support@aiseesoft.de</a>, um das Zurücksetzen des Registrierungscodes für Sie anzufordern.`,
        faq_info_3:`Sobald Ihr Rückerstattungsantrag genehmigt wurde, dauert es normalerweise 3 bis 7 Werktage, bis die Gutschrift auf Ihrem Konto angezeigt wird. Während der Ferien kann es manchmal länger dauern, aber es wird nicht erwartet, dass es länger als 10 Tage dauert. Wenn Sie die Rückerstattung 20 Tage nach der Genehmigung nicht erhalten haben, kontaktieren Sie uns bitte unter <a href="mailto:support@aiseesoft.de">support@aiseesoft.de</a>, um die Rückerstattung zu überprüfen.`,
        faq_info_4:`Automatischer Verlängerungsservice bedeutet, dass beim Kauf eines 1-Jahres-Abonnements die Zahlungsplattform den Betrag (einschließlich der anfallenden Steuern) automatisch berechnet, wenn die Produktlizenz abläuft. Aber das Zahlungs-Flatform (wie z.B. 2Checkout) sendet Ihnen einen Brief, um Sie daran zu erinnern, dass die Bestellung 7 Tage vor Ablauf abläuft. Die E-Mail enthält auch Anweisungen zum Deaktivieren des „Automatischen Verlängerungsdienstes“.`,
        faq_info_5:`Da die Bestellung automatisch verarbeitet wird, ist eine Stornierung der Bestellung nicht zulässig. Außerdem können Sie mit der kostenlosen Testversion mehr über das Aiseesoft-Programm erfahren und prüfen, ob es Ihren Anforderungen entspricht, bevor Sie es kaufen.`,
        faq_info_6:`Wenn Sie wirklich eine Rückerstattung wünschen, lesen Sie bitte unsere <a href="https://www.aiseesoft.de/refund-policy.html">Erstattungsrichtlinien</a>.`,
        faq_info_7:`Wir schätzen Ihre Zeit und Aufmerksamkeit, um uns eine Bewertung zu senden. Kontaktieren Sie uns (<a href="mailto:support@aiseesoft.de">support@aiseesoft.de</a>), um Ihre Bewertung abzugeben, und wir werden Ihre Bewertung für unsere Produktaktualisierungen ernsthaft berücksichtigen.`,
        change_password_success:`das Passwort wurde erfolgreich geändert`,
        successful_login_title:`Einloggen erfolgreich`,
        product_page:`Produktseite>>`,
        successful_login_info:`Anmeldung abgeschlossen. Bitte schließen Sie die aktuelle Seite und kehren Sie zum ursprünglichen Tab zurück, um mit dem folgenden Vorgang fortzufahren. Die aktuelle Seite wird in 5 Sekunden automatisch geschlossen. Wenn das automatische Schließen oder Schließen durch Klicken auf die Schaltfläche "Fertig" fehlschlägt, schließen Sie diese Registerkarte bitte direkt.`,
        successful_login_info_firefox:`Anmeldung abgeschlossen. Bitte schließen Sie die aktuelle Seite und kehren Sie zum ursprünglichen Tab zurück, um mit dem folgenden Vorgang fortzufahren.`,
        my_account:`Mein Konto`,
        my_history:`Meine Geschichte`,
        remove_watermark:`Wasserzeichen entfernen`,
        no_history:`Keine Historie`,
        history_all:`Wählen Sie Alle`,
        history_open:`Offen`,
        history_down:`Herunterladen`,
        history_delete:`Löschen`,
        history_clear:`Ungültig löschen`,
        images:`Bild(er)`,
        use_this_function:`Verwenden Sie diese Funktion>>`,
        hd_downloading:`Vorteile beim Herunterladen von HD-Originalbildern:`,
        lifetimeRemaining:`Restlebensdauer`,
        Remaining:`Verbleibend`,
        email_verification:`E-Mail-Verifizierung`,
        email_verification_info:`Wir haben die Bestätigungsnachricht an Ihre E-Mail-Adresse <span class="email"></span> gesendet, und bitte schließen Sie die Bestätigung ab. Nach der Überprüfung werden die Vorteile automatisch synchronisiert.`,
        wrong_email:`Falsche E-Mail Adresse?`,
        click_here_to_modify:`Klicken Sie hier, um zu ändern`,
        get_more_help:`Weitere Hilfe erhalten?`,
        click_here:`Hier klicken`,
        email_verification_info_success:`Herzlichen Glückwunsch zur Bestätigung Ihres E-Mail-Kontos.`,
        email_verification_info_error:`Die Überprüfung ist aufgrund des abgelaufenen Links fehlgeschlagen.`,
        registration_succeeded:`Registrierung erfolgreich`,
        registration_succeeded_info_1:`Herzliche Glückwünsche! Du hast dich erfolgreich registriert. Wir haben die Bestätigungsnachricht an Ihre E-Mail-Adresse <span class="email"></span> gesendet. Bitte schließen Sie die Bestätigung ab, um die Vorteile dieser E-Mail nutzen zu können.`,
        registration_succeeded_info_2:`Klicken Sie auf „Fertig“, um zur Startseite zurückzukehren und dieses Produkt zu verwenden.`,
        registration_succeeded_info_3:`Klicken Sie auf „Fertig“, um die aktuelle Seite zu schließen und für die folgenden Vorgänge zur Startseite zurückzukehren. Wenn das Schließen fehlschlägt, schließen Sie die Registerkarte bitte manuell.`,
        verify_email:`E-Mail bestätigen`,
        registered_email_not_verified:`Die registrierte E-Mail wurde nicht verifiziert, bitte verifizieren Sie sie sofort.`,
        email_verification_time_1:`Keine Bestätigungs-E-Mail erhalten?`,
        email_verification_time_2:`Nach`,
        email_verification_time_3:`Klicken Sie hier, um es erneut zu senden`,
        error_26301:`Fehlercode: 26301, bitte kontaktieren Sie uns`,
        error_26302:`Fehlercode: 26302, bitte kontaktieren Sie uns`,
        error_26303:`E-Mail-Formatfehler (Fehlercode: 26303). Bitte geben Sie es erneut ein`,
        error_26304:`Passwort mit mehr als 8 Zeichen wird empfohlen (Fehlercode: 26304)`,
        error_26305:`Anforderung fehlgeschlagen (Fehlercode: 26305). Bitte versuchen Sie es erneut`,
        error_26306:`E-Mail wurde registriert, bitte <a href="https://account.aisesoft.com/login">gehen Sie zum Anmelden</a>`,
        error_26307:`Anforderung fehlgeschlagen (Fehlercode: 26307). Bitte versuchen Sie es erneut`,
        error_26308:`Anforderung fehlgeschlagen (Fehlercode: 26308). Bitte versuchen Sie es erneut`,
        error_26401:`Fehlercode: 26401, bitte versuchen Sie es erneut`,
        error_26402:`E-Mail wurde verifiziert (Fehlercode: 26402), bitte versuchen Sie es erneut`,
        error_26403:`Anforderung fehlgeschlagen (Fehlercode: 26403). Bitte versuchen Sie es erneut`,
        error_26404:`Anfrage fehlgeschlagen (Fehlercode: 26404). Bitte versuchen Sie es erneut`,
        error_26501:`Fehlercode: 26501, bitte kontaktieren Sie uns`,
        error_26502:`Fehlercode: 26502, bitte kontaktieren Sie uns`,
        error_26503:`E-Mail-Formatfehler (Fehlercode: 26503). Bitte geben Sie es erneut ein`,
        error_26504:`Anforderung fehlgeschlagen (Fehlercode: 26504). Bitte versuchen Sie es erneut`,
        error_26505:`E-Mail wurde nicht registriert, bitte <a href="https://account.aisesoft.com/register">registrieren Sie es zuerst</a>`,
        error_26506:`E-Mail wurde bestätigt.`,
        error_26507:`Anforderung fehlgeschlagen (Fehlercode: 26507). Bitte versuchen Sie es erneut`,
        error_26508:`Überprüfung fehlgeschlagen (Fehlercode: 26508), bitte versuchen Sie es erneut`,
        error_26509:`Anfrage fehlgeschlagen (Fehlercode: 26509), bitte versuchen Sie es erneut`,
        error_26510:`Fehlercode: 26510, bitte kontaktieren Sie uns`,
        error_26601:`Fehlercode: 26601, bitte kontaktieren Sie uns`,
        error_26602:`Fehlercode: 26602, bitte kontaktieren Sie uns`,
        error_26603:`Anforderung fehlgeschlagen (Fehlercode: 26603). Bitte versuchen Sie es erneut`,
        error_26604:`Fehlercode: 26604, bitte kontaktieren Sie uns`,
        error_26605:`Fehlercode: 26605, bitte kontaktieren Sie uns`,
        error_26701:`Fehlercode: 26701, bitte kontaktieren Sie uns`,
        error_26702:`Anforderung fehlgeschlagen (Fehlercode: 26702). Bitte versuchen Sie es erneut`,
        error_26703:`Fehlercode: 26703, bitte kontaktieren Sie uns`,
        error_26704:`Fehlercode: 26704, bitte kontaktieren Sie uns`,
        error_26705:`Warten Sie auf die Anmeldung (Fehlercode: 26705). Bitte versuchen Sie es erneut`,
        no_cookie:`Sie haben die Funktion „Alle Cookies blockieren“ in Ihrem Browser aktiviert, sodass Sie sich nicht anmelden können. Bitte gehen Sie zu den Einstellungen, um das Kontrollkästchen „Alle Cookies zulassen“ zu aktivieren.`,
        error_26801:`Fehlercode: 26801, bitte kontaktieren Sie uns`,
        error_26802:`Fehlercode: 26802, bitte kontaktieren Sie uns`,
        error_26803:`Anfrage fehlgeschlagen (Fehlercode: 26803). Bitte versuchen Sie es erneut`,
        error_26804:`Anfrage fehlgeschlagen (Fehlercode: 26804). Bitte versuchen Sie es erneut`,
        error_order:`Anfrage fehlgeschlagen (Fehlercode: 27098), bitte versuchen Sie es erneut!`,
        error_order1:`Die Bestellabfrage ist unvollständig（Fehlercode:`,
        error_order2:`）, bitte aktualisieren und erneut versuchen.`,
        modify_email_title:`E-Mail ändern`,
        modify_email_info:`Sie können die geänderte E-Mail verwenden, um sich bei Ihrem Konto anzumelden.`,
        images_per:`Billeder pr`,
        error_26101:`Fehler: 26101. Bitte kontaktieren Sie uns`,
        error_26102:`Fehler: 26102. Bitte kontaktieren Sie uns`,
        error_26103:`Anfrage fehlgeschlagen (Fehlercode: 26103). Bitte versuchen Sie es erneut`,
        error_26104:`Fehlercode: 26104, bitte versuchen Sie es erneut`,
        error_26105:`Fehlercode: 26105, bitte versuchen Sie es erneut`,
        error_26106:`Löschen fehlgeschlagen (Fehlercode: 26106). Bitte versuchen Sie es erneut`,
        error_26201:`Fehler: 26201. Bitte kontaktieren Sie uns`,
        error_26202:`Anfrage fehlgeschlagen (Fehlercode: 26202). Bitte versuchen Sie es erneut`,
        error_26001:`Fehler: 26001. Bitte kontaktieren Sie uns`,
        error_26002:`Fehler: 26002. Bitte kontaktieren Sie uns`,
        error_26003:`Fehler: 26003. Bitte kontaktieren Sie uns`,
        error_26004:`Fehler: 26004. Bitte kontaktieren Sie uns`,
        error_26005:`Anfrage fehlgeschlagen (Fehlercode: 26005). Bitte versuchen Sie es erneut`,
        error_26006:`Fehlercode: 26006, bitte versuchen Sie es erneut`,
        error_26008:`Fehler: 26008. Bitte kontaktieren Sie uns`,
        go_to_the_home_page:`Gehen Sie zur Startseite`,
        error_27101:`Anfrage fehlgeschlagen (Fehlercode: 27101). Bitte versuchen Sie es erneut`,
        error_27201:`Fehlercode: 27201, bitte kontaktieren Sie uns`,
        error_27202:`Fehlercode: 27202, bitte versuchen Sie es erneut`,
        error_27203:`Anfrage fehlgeschlagen (Fehlercode: 27203). Bitte versuchen Sie es erneut`,
        error_27204:`Ungültiger Code (Fehlercode: 27204).`,
        error_27205:`Anfrage fehlgeschlagen (Fehlercode: 27205). Bitte versuchen Sie es erneut`,
        error_27206:`Anfrage fehlgeschlagen (Fehlercode: 27206). Bitte versuchen Sie es erneut`,
        error_27207:`Anfrage fehlgeschlagen (Fehlercode: 27207). Bitte versuchen Sie es erneut`,
        no_history_found:`Sie haben kein Tool verwendet! <a href="/">Aktualisieren</a> oder <a href="https://www.aiseesoft.de">Gehen Sie zur offiziellen Website</a>`,
        error_25301:`Fehler: 25301. Bitte kontaktieren Sie uns`,
        error_25302:`Fehler: 25302. Bitte kontaktieren Sie uns`,
        error_25303:`Anfrage fehlgeschlagen (Fehlercode: 25303). Bitte versuchen Sie es erneut`,
        error_25304:`Anfrage fehlgeschlagen (Fehlercode: 25304). Bitte versuchen Sie es erneut`,
        error_25305:`Anfrage fehlgeschlagen (Fehlercode: 25305). Bitte versuchen Sie es erneut`,
        error_25306:`Anfrage fehlgeschlagen (Fehlercode: 25306). Bitte versuchen Sie es erneut`,
        image_upscaler_p:`Bilder ohne Wasserzeichen herunterladen:`,
        Available_for:`Gültig für:`,
        credit_per:`%s Punkt(e) pro HD-Bild`,
        still_valid:`Gekaufte(r) Plan(e) ist/sind noch gültig`,
        credit:`Punkt(e)`,
        pc_3rd_info:`Erfolgreich angemeldet, bitte gehen Sie zurück zum Programm, um mit der Nutzung fortzufahren.`,
        use_online:`Der online Dienst verwenden`,
        use_download:`Das Desktop-Programm verwenden`,
        use_immediately:`Sofort verwenden`,
        Use_in_browser:`Im Browser verwenden`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    el:{
        overtime_1:`Κωδικός λάθους:`,
        overtime_2:`, παρακαλώ δοκιμάστε το ξανά`,
        isnetwork:`Σφάλμα διαδικτύου. Ελέγξτε και δοκιμάστε ξανά`,
        email_placeholder:`ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ`,
        email_empty:`Παρακαλώ εισάγετε email`,
        email_not_valid:`Το email δεν είναι έγκυρο`,
        email_not_valid_1:`Παρακαλώ εισάγετε το email σας`,
        email_not_valid_2:`Το email δεν είναι έγκυρο, χρησιμοποιήστε διαφορετική διεύθυνση.`,
        email_not_valid_3:`Χωρίς εισαγωγή email`,
        password_placeholder:`Κωδικός πρόσβασης`,
        password_empty:`Παρακαλώ εισάγετε τον κωδικό πρόσβασης`,
        password_not_valid:`Λάθος λογαριασμός ή κωδικός πρόσβασης`,
        password_not_valid_1:`Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων`,
        password_not_valid_2:`Παρακαλώ δημιουργήστε έναν κωδικό πρόσβασης`,
        password_placeholder_1:`Δημιούργησε τον κωδικό σου`,
        password_placeholder_2:`Επιβεβαιώστε τον κωδικό πρόσβασής σας`,
        password_placeholder_3:`Δημιουργία νέου κωδικού πρόσβασης`,
        password_placeholder_4:`Επιβεβαιώστε τον καινούριο σας κωδικό`,
        password_placeholder_5:`Εισαγάγετε τον παλιό κωδικό πρόσβασης`,
        copy_password_empty:`Παρακαλώ επιβεβαιώστε τον κωδικό πρόσβασης`,
        copy_password_not_valid:`Παρακαλώ επιβεβαιώστε τον κωδικό πρόσβασής σας`,
        copy_passwords_inconsistent:`Η επιβεβαίωση του κωδικού πρόσβασής σας δεν ταιριάζει`,
        code_empty:`Εισαγάγετε τον κωδικό επαλήθευσης`,
        code_not_valid:`Μη έγκυρος κωδικός επαλήθευσης`,
        code_placeholder:`Κωδικός επαλήθευσης`,
        not_received_code:`Εάν το γραμματοκιβώτιό σας δεν έχει λάβει τον κωδικό επαλήθευσης για μεγάλο χρονικό διάστημα, λάβετε ξανά τον κωδικό επαλήθευσης.`,
        get_first_code:`Πάρτε πρώτα τον κωδικό επαλήθευσης.`,
        connect_your_email_placeholder:`Συνδέστε το email σας`,
        last_name_placeholder:`Παρακαλώ εισάγετε το επώνυμό σας`,
        first_name_placeholder:`Παρακαλώ εισάγετε το ονομα σας`,
        address_placeholder:`Παρακαλώ εισάγετε τη διεύθυνσή σας`,
        no_code_text:`Έχουμε στείλει έναν κωδικό επαλήθευσης. Εισαγάγετε τον κωδικό σας. <span class='tips'>Δεν λάβατε κωδικό;`,
        no_code_text_1:`1. Βεβαιωθείτε ότι η διεύθυνση ηλεκτρονικού ταχυδρομείου είναι έγκυρη και ότι μπορεί να λαμβάνει μηνύματα ηλεκτρονικού ταχυδρομείου.`,
        no_code_text_2:`2. Για το μήνυμα ηλεκτρονικού ταχυδρομείου που αποστέλλεται αυτόματα από το σύστημα, μπορεί να επισημανθεί ως ανεπιθύμητο ή ανεπιθύμητο μήνυμα ηλεκτρονικού ταχυδρομείου. Ελέγξτε αν το μήνυμα ηλεκτρονικού ταχυδρομείου βρίσκεται στον φάκελο Κάδος απορριμμάτων.`,
        no_code_text_3:`3. Δεν μπορείτε να λύσετε το πρόβλημά σας;`,
        no_code_text_3_span:`Στη συνέχεια, κάντε κλικ εδώ για να επικοινωνήσετε μαζί μας.`,
        order_no:`Δεν έχετε αγοράσει κανένα προϊόν. Οποιεσδήποτε ερωτήσεις, <a href="https://el.aiseesoft.com/store/">Αγοράστε τώρα</a> Ή <a href="https://el.aiseesoft.com/contact.html"> Επικοινωνήστε μαζί μας</a>`,
        error_24901:`Ο τρέχων λογαριασμός δεν έχει συνδεδεμένο email και δεν μπορεί να βρει τις παραγγελίες. Συνδέστε ένα email.`,
        user_guide:`Οδηγός χρήσης >>`,
        download:`Κατεβάστε`,
        order_number:`Αριθμός παραγγελίας`,
        Refund:`Επιστροφή χρημάτων`,
        Disabled:`άτομα με ειδικές ανάγκες`,
        Normal:`Κανονικός`,
        Modify:`Τροποποιώ`,
        Modify_1:`Τροποποίηση >>`,
        Connect:`Συνδέω-συωδεομαι`,
        unlink_success:`Αποσύνδεση με επιτυχία`,
        connect_success:`Συνδεθείτε με επιτυχία`,
        feedback_title:`Ευχαριστούμε για την ανταπόκριση σας. Αφήστε το πρόβλημά σας και θα σας απαντήσουμε εντός 24 ωρών.`,
        feedback_thank_you:`Σας ευχαριστούμε! <br /> Τα σχόλιά σας υποβλήθηκαν με επιτυχία.`,
        feedback_email:`Εισάγετε το email σας εδώ!`,
        feedback_content:`Αφήστε οποιοδήποτε πρόβλημα ή πρόταση αντιμετωπίσατε εδώ.`,
        feedback_submit:`υποβάλλουν`,
        form_contents:`Δεν έχετε εισαγάγει καμία περιγραφή. Παρακαλώ εισάγετε το και υποβάλετε ξανά.`,
        old_password:`Παρακαλώ εισάγετε τον παλιό κωδικό πρόσβασης`,
        new_password:`Δημιουργήστε έναν νέο κωδικό πρόσβασης`,
        old_new_password:`Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον παλιό`,
        incorrect_password:`Λάθος κωδικός`,
        delete_no:`Διαγραφή τώρα`,
        Caps:`Το Caps lock είναι ενεργοποιημένο`,
        Get:`Παίρνω`,
        Done:`Εγινε`,
        error_20001:`Σφάλμα: 20001. Συνδεθείτε ξανά.`,
        error_20002:`Σφάλμα: 20002. Συνδεθείτε ξανά.`,
        error_20003:`Σφάλμα: 20003. Συνδεθείτε ξανά.`,
        error_20004:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20004). Δοκιμάστε το ξανά.`,
        error_20005:`Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20005). Παρακαλώ συνδεθείτε ξανά.`,
        error_20006:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20006). Δοκιμάστε το ξανά.`,
        error_20007:`Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20007). Παρακαλώ συνδεθείτε ξανά.`,
        error_20008:`Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20008). Παρακαλώ συνδεθείτε ξανά.`,
        error_20009:`Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20009). Παρακαλώ συνδεθείτε ξανά.`,
        error_20101:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20101)`,
        error_20102:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20102)`,
        error_20103:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20103). Δοκιμάστε το ξανά`,
        error_20104:`Το email χρησιμοποιείται ήδη, <a href="https://account.aiseesoft.com/login"> συνδεθείτε </a> ή εγγραφείτε με νέο`,
        error_20105:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20105). Δοκιμάστε το ξανά`,
        error_20106:`Η αποστολή μηνύματος ηλεκτρονικού ταχυδρομείου απέτυχε, δοκιμάστε ξανά`,
        error_20201:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20201)`,
        error_20202:`Παρακαλώ εισάγετε τον κωδικό πρόσβασής σας (κωδικός σφάλματος: 20202)`,
        error_20203:`Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20203)`,
        error_20204:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20204)`,
        error_20205:`Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 20205)`,
        error_20206:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20206). Δοκιμάστε το ξανά`,
        error_20207:`Μη έγκυρος κωδικός επαλήθευσης`,
        error_20208:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20208). Δοκιμάστε το ξανά`,
        error_20209:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20209). Δοκιμάστε το ξανά`,
        error_20301:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20301)`,
        error_20302:`Σφάλμα: 20302. Επικοινωνήστε μαζί μας`,
        error_20303:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20303)`,
        error_20304:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20304). Δοκιμάστε το ξανά`,
        error_20305:`Λογαριασμός δεν υπάρχει. Εισαγάγετε ξανά ή <a href="https://account.aiseesoft.com/register">Δημιουργησε το</a> πρώτα.`,
        error_20306:`Δεν υπάρχει ακόμη κωδικός πρόσβασης. Χρησιμοποιήστε <a href="https://account.aiseesoft.com/passwordless-login"> Σύνδεση χωρίς κωδικό πρόσβασης </a> ή <a href="create-password"> ορίστε έναν κωδικό πρόσβασης </a> και συνδεθείτε.`,
        error_20308:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20308). Δοκιμάστε το ξανά`,
        error_20401:`Αποτυχία αποσύνδεσης (κωδικός σφάλματος: 20401). Δοκιμάστε το ξανά`,
        error_20501:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20501)`,
        error_20502:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20502)`,
        error_20503:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20503). Δοκιμάστε το ξανά`,
        error_20504:`Αποτυχία αποστολής μηνύματος ηλεκτρονικού ταχυδρομείου. Δοκιμάστε το ξανά.`,
        error_20601:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20601)`,
        error_20602:`Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20602)`,
        error_20603:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20603)`,
        error_20604:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20604). Δοκιμάστε το ξανά`,
        error_20606:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20606). Δοκιμάστε το ξανά`,
        error_20607:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20607). Δοκιμάστε το ξανά`,
        error_20608:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20608). Δοκιμάστε το ξανά`,
        error_20701:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20701)`,
        error_20702:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20702)`,
        error_20703:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20703). Δοκιμάστε το ξανά`,
        error_20704:`Λογαριασμός δεν υπάρχει. Εισαγάγετε ξανά ή <a href="https://account.aiseesoft.com/register">Δημιουργησε το</a> πρώτα.`,
        error_20705:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20705). Δοκιμάστε το ξανά`,
        error_20706:`Αποτυχία αποστολής μηνύματος ηλεκτρονικού ταχυδρομείου. Δοκιμάστε το ξανά`,
        error_20801:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20801)`,
        error_20802:`Σφάλμα: 20802. Επικοινωνήστε μαζί μας`,
        error_20803:`Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20803)`,
        error_20804:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20804)`,
        error_20805:`Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 20805)`,
        error_20806:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20806). Δοκιμάστε το ξανά`,
        error_20808:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20808). Δοκιμάστε το ξανά`,
        error_20901:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20901). Δοκιμάστε το ξανά`,
        error_20902:`Το αίτημα απέτυχε (κωδικός σφάλματος: 20902). Δοκιμάστε το ξανά`,
        error_21000:`Οι αλλαγές αποθηκεύονται`,
        error_21001:`Δεν υποβάλλονται πληροφορίες (κωδικός σφάλματος: 21001)`,
        error_21002:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21002). Δοκιμάστε το ξανά`,
        error_21101:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 21101)`,
        error_21102:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 21102)`,
        error_21103:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21103), δοκιμάστε ξανά`,
        error_21104:`Το email είναι ήδη συνδεδεμένο, χρησιμοποιήστε ένα νέο`,
        error_21105:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21105), δοκιμάστε το ξανά`,
        error_21106:`Αποτυχία αποστολής μηνύματος ηλεκτρονικού ταχυδρομείου. Δοκιμάστε το ξανά`,
        error_21201:`Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 21201)`,
        error_21202:`Παρακαλώ εισάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 21202)`,
        error_21203:`Το email δεν είναι έγκυρο (κωδικός σφάλματος: 21203)`,
        error_21204:`Σφάλμα: 21204. Επικοινωνήστε μαζί μας`,
        error_21205:`Σφάλμα: 21205. Επικοινωνήστε μαζί μας`,
        error_21206:`Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 21206)`,
        error_21207:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21207). Δοκιμάστε το ξανά`,
        error_21209:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21209). Δοκιμάστε το ξανά`,
        error_21301:`Παρακαλώ εισάγετε τον παλιό κωδικό πρόσβασης (κωδικός σφάλματος: 21301)`,
        error_21302:`Δημιουργήστε έναν νέο κωδικό πρόσβασης (κωδικός σφάλματος: 21302)`,
        error_21303:`Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον παλιό. (Σφάλμα: 21303)`,
        error_21304:`Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 21304)`,
        error_21306:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21306). Δοκιμάστε το ξανά`,
        error_21402:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21402). Δοκιμάστε το ξανά`,
        error_21403:`Αποτυχία αποστολής κωδικού επαλήθευσης. Παρακαλώ στείλτε το ξανά`,
        error_21500:`Ο λογαριασμός έχει διαγραφεί`,
        error_21501:`Παρακαλώ εισάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 21501)`,
        error_21502:`Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 21502). Παρακαλώ συνδεθείτε ξανά.`,
        error_21503:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21503). Δοκιμάστε το ξανά`,
        error_21505:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21505), δοκιμάστε το ξανά`,
        error_21601:`Σφάλμα: 20601. Επικοινωνήστε μαζί μας`,
        error_21602:`Μη έγκυρη επαλήθευση (Σφάλμα: 20602). Δοκιμάστε το ξανά.`,
        error_21603:`Σφάλμα: 20603. Δοκιμάστε ξανά`,
        error_21604:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21604). Δοκιμάστε το ξανά`,
        error_21606:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21606). Δοκιμάστε το ξανά`,
        error_21611:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21611). Δοκιμάστε το ξανά`,
        error_21801:`Σφάλμα: 21801. Επικοινωνήστε μαζί μας`,
        error_21802:`Το αίτημα απέτυχε (Σφάλμα: 21802). Δοκιμάστε το ξανά`,
        error_21803:`Σφάλμα: 21803. Δοκιμάστε το ξανά`,
        error_21804:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21804). Δοκιμάστε το ξανά`,
        error_21806:`Σφάλμα: 21806. Δοκιμάστε το ξανά`,
        error_21807:`Σφάλμα: 21807. Επικοινωνήστε μαζί μας`,
        error_21808:`Σφάλμα: 21808. Επικοινωνήστε μαζί μας`,
        error_21809:`Σφάλμα: 21809. Επικοινωνήστε μαζί μας`,
        error_21810:`Σφάλμα: 21810. Επικοινωνήστε μαζί μας`,
        error_21811:`Σφάλμα: 21811. Επικοινωνήστε μαζί μας`,
        error_21812:`Σφάλμα: 21812. Επικοινωνήστε μαζί μας`,
        error_21813:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21813). Δοκιμάστε το ξανά`,
        error_21814:`Σφάλμα: 21814. Επικοινωνήστε μαζί μας`,
        error_21815:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21815). Δοκιμάστε το ξανά`,
        error_21816:`Σφάλμα: 21816. Επικοινωνήστε μαζί μας`,
        error_21817:`Σφάλμα: 21817. Επικοινωνήστε μαζί μας`,
        error_21818:`Σφάλμα: 21818. Επικοινωνήστε μαζί μας`,
        error_21819:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21819). Δοκιμάστε το ξανά`,
        error_21820:`Σφάλμα: 21820. Επικοινωνήστε μαζί μας`,
        error_21821:`Σφάλμα: 21821. Επικοινωνήστε μαζί μας`,
        error_21822:`Σφάλμα: 21822. Επικοινωνήστε μαζί μας`,
        error_21823:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21823). Δοκιμάστε το ξανά`,
        error_21824:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21824). Δοκιμάστε το ξανά`,
        error_21825:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21825). Δοκιμάστε το ξανά`,
        error_21826:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21826). Δοκιμάστε το ξανά`,
        error_21828:`Σφάλμα: 21828. Επικοινωνήστε μαζί μας`,
        error_21901:`Σφάλμα: 21901. Επικοινωνήστε μαζί μας`,
        error_21902:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21902). Δοκιμάστε το ξανά`,
        error_21903:`Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 21903), ανανεώστε τη σελίδα και δοκιμάστε ξανά`,
        error_21904:`Σφάλμα: 21904. Δοκιμάστε το ξανά`,
        error_21905:`Σφάλμα: 21905. Δοκιμάστε το ξανά`,
        error_21906:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21906). Δοκιμάστε το ξανά`,
        error_21907:`Ο λογαριασμός Google έχει συνδεθεί με άλλο λογαριασμό`,
        error_21908:`Το αίτημα απέτυχε (κωδικός σφάλματος: 21908). Δοκιμάστε το ξανά`,
        error_22001:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22001). Δοκιμάστε το ξανά`,
        error_22002:`Η αποσύνδεση απέτυχε χωρίς επιπλέον σύνδεση`,
        error_22003:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22003). Δοκιμάστε το ξανά`,
        error_22101:`Σφάλμα: 22101. Επικοινωνήστε μαζί μας`,
        error_22102:`Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 22102), ανανεώστε τη σελίδα και δοκιμάστε ξανά`,
        error_22103:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22103). Δοκιμάστε το ξανά`,
        error_22104:`Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 22104), ανανεώστε τη σελίδα και δοκιμάστε ξανά`,
        error_22105:`Σφάλμα: 22105. Δοκιμάστε το ξανά`,
        error_22106:`Σφάλμα: 22106. Δοκιμάστε το ξανά`,
        error_22107:`Σφάλμα: 22107. Επικοινωνήστε μαζί μας`,
        error_22108:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22108). Δοκιμάστε το ξανά`,
        error_22201:`Σφάλμα: 22201. Επικοινωνήστε μαζί μας`,
        error_22202:`Μη έγκυρη επαλήθευση (Σφάλμα: 22202). Δοκιμάστε το ξανά.`,
        error_22203:`Σφάλμα: 22203. Δοκιμάστε το ξανά "`,
        error_22204:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22204). Δοκιμάστε το ξανά`,
        error_22206:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22206). Δοκιμάστε το ξανά`,
        error_22401:`Σφάλμα: 22401. Επικοινωνήστε μαζί μας`,
        error_22402:`Μη έγκυρη επαλήθευση (Σφάλμα: 22402). Δοκιμάστε το ξανά.`,
        error_22403:`Σφάλμα: 22403. Δοκιμάστε το ξανά`,
        error_22404:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22404). Δοκιμάστε το ξανά`,
        error_22405:`Ο λογαριασμός Facebook έχει συνδεθεί με άλλα μηνύματα ηλεκτρονικού ταχυδρομείου`,
        error_22406:`Σφάλμα: 22406. Δοκιμάστε το ξανά`,
        error_22407:`Σφάλμα: 22407. Επικοινωνήστε μαζί μας`,
        error_22408:`Σφάλμα: 22408. Επικοινωνήστε μαζί μας`,
        error_22409:`Σφάλμα: 22409. Επικοινωνήστε μαζί μας`,
        error_22410:`Σφάλμα: 224010. Επικοινωνήστε μαζί μας`,
        error_22411:`Σφάλμα: 224011. Επικοινωνήστε μαζί μας`,
        error_22412:`Σφάλμα: 224012. Επικοινωνήστε μαζί μας`,
        error_22413:`Σφάλμα: 22413. Επικοινωνήστε μαζί μας`,
        error_22414:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22414). Δοκιμάστε το ξανά`,
        error_22415:`Σφάλμα: 22415. Επικοινωνήστε μαζί μας`,
        error_22416:`Σφάλμα: 22416. Επικοινωνήστε μαζί μας`,
        error_22417:`Σφάλμα: 22417. Επικοινωνήστε μαζί μας`,
        error_22418:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22418). Δοκιμάστε το ξανά`,
        error_22419:`Σφάλμα: 22419. Επικοινωνήστε μαζί μας`,
        error_22420:`Σφάλμα: 22420. Επικοινωνήστε μαζί μας`,
        error_22421:`Σφάλμα: 22421. Επικοινωνήστε μαζί μας`,
        error_22422:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22422). Δοκιμάστε το ξανά`,
        error_22423:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22423). Δοκιμάστε το ξανά`,
        error_22424:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22424). Δοκιμάστε το ξανά`,
        error_22425:`Το αίτημα απέτυχε (κωδικός σφάλματος: 22425). Δοκιμάστε το ξανά`,
        error_20098:`Κωδικός σφάλματος: 20098. Εάν κάνετε περιήγηση σε ιδιωτική λειτουργία, μεταβείτε στην Κανονική λειτουργία και δοκιμάστε ξανά.`,
        error_22298:`Το αίτημα για Google απέτυχε (Σφάλμα: 22298). Δοκιμάστε το ξανά.`,
        error_22498:`Το αίτημα για το Facebook απέτυχε (Σφάλμα: 22498). Δοκιμάστε το ξανά.`,
        error_24902:`Το αίτημα απέτυχε (κωδικός σφάλματος: 24902). Δοκιμάστε το ξανά`,
        error_24903:`Το αίτημα απέτυχε (κωδικός σφάλματος: 24903). Δοκιμάστε το ξανά`,
        error_24904:`Το αίτημα απέτυχε (κωδικός σφάλματος: 24904). Δοκιμάστε το ξανά`,
        error_24905:`Το αίτημα απέτυχε (κωδικός σφάλματος: 24905). Δοκιμάστε το ξανά`,
        login_title:`Συνδεθείτε στο Aiseesoft`,
        password_placeholder:`Κωδικός πρόσβασης`,
        log_in:`Σύνδεση`,
        no_account:`Χωρίς λογαριασμό;`,
        create_it:`Δημιουργησε το`,
        or_log_in_with:`Or συνδεθείτε με`,
        passwordless_login:`Σύνδεση χωρίς κωδικό πρόσβασης`,
        log_in_done:`Σύνδεση Ολοκληρώθηκε`,
        three_rd_account_connect_info:`Συγχαρητήρια! Έχετε συνδεθεί με επιτυχία. Τώρα μπορείτε να συνδέσετε έναν λογαριασμό email που χρησιμοποιείται για να συνδεθείτε στο μέλλον.`,
        connect_now:`Συνδεθείτε τώρα`,
        see_my_account:`Δείτε τον λογαριασμό μου`,
        three_rd_login_merge_account:`Η διεύθυνση ηλεκτρονικού ταχυδρομείου του λογαριασμού τρίτου μέρους έχει εγγραφεί, θέλετε να συνδεθείτε και να συνδεθείτε απευθείας με αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου;`,
        connect_log_in:`Σύνδεση & Σύνδεση`,
        create_an_account:`Δημιουργία λογαριασμού`,
        connect_account:`Σύνδεση λογαριασμού`,
        connect_account_info:`Μετά τη σύνδεση, μπορείτε να συνδεθείτε με αυτόν τον λογαριασμό.`,
        back_to_log_in:`Επιστροφή στην σελίδα εισόδου`,
        create_password:`Δημιούργησε κωδικό`,
        create_now:`Δημιουργία τώρα`,
        password_login_subtitle:`Σύνδεση χωρίς κωδικό πρόσβασης με email`,
        account_login:`Είσοδος σε λογαριασμό`,
        rights:`Με τη δημιουργία αυτού του λογαριασμού, συμφωνείτε με τους <a href="https://el.aiseesoft.com/company/terms-and-conditions.html"> Όρους Παροχής Υπηρεσιών </a> και <a href="https://el.aiseesoft.com/privacy-policy.html"> Πολιτική απορρήτου </a>`,
        passwordless_login_done:`Ολοκληρώθηκε η σύνδεση χωρίς κωδικό πρόσβασης`,
        passwordless_login_info:`Συγχαρητήρια, ολοκληρώσατε επιτυχώς τη σύνδεση χωρίς κωδικό πρόσβασης. Μπορείτε να δημιουργήσετε έναν κωδικό πρόσβασης για αυτόν τον λογαριασμό και να συνδεθείτε με τον λογαριασμό και τον κωδικό πρόσβασης στο μέλλον. <a href="/create-password" style="display: initial;"> Δημιουργία τώρα </a>`,
        sign_up:`Εγγραφείτε`,
        register_info:`Δημιούργησε τον λογαριασμό σου`,
        reset_now:`Επαναφορά τώρα`,
        forgot_password:`Ξεχάσατε τον κωδικό`,
        reset_password_subtitle:`Χρησιμοποιήστε το email του λογαριασμού σας για να επαναφέρετε τον κωδικό πρόσβασης`,
        plan_products:`Σχέδιο & Προϊόντα`,
        nick_name:`Όνομα χρήστη`,
        email:`ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ`,
        my_products:`Τα Προϊόντα Μου`,
        my_orders:`Οι παραγγελίες μου`,
        unlink:`Αποσύνδεση`,
        link:`Σύνδεσμος`,
        connected_accounts:`Συνδεδεμένοι λογαριασμοί`,
        last_name:`Επίθετο`,
        first_name:`Ονομα`,
        Gender:`Γένος`,
        Birth:`Γέννηση`,
        Month:`Μήνας`,
        Year:`Ετος`,
        Country_Region:`Χώρα/Περιφέρεια`,
        Address:`Διεύθυνση`,
        Save:`Αποθηκεύσετε`,
        Date:`Ημερομηνία`,
        Male:`Αρσενικός`,
        Female:`Θηλυκός`,
        Unspecified:`Απροσδιόριστος`,
        Security:`Ασφάλεια`,
        change_password:`Αλλαξε κωδικό`,
        change_now:`Αλλάξτε τώρα`,
        connect_email:`Συνδέστε το email`,
        delete_account:`Διαγραφή λογαριασμού`,
        delete_account_info:`Όταν διαγραφεί ο λογαριασμός σας, όλα τα δεδομένα στο Plan & Products που σχετίζονται με τον λογαριασμό σας θα διαγραφούν οριστικά και ενδέχεται να μην μπορείτε να τα ανακτήσετε. Σας προτείνουμε να πατήσετε πολεμικά.`,
        Delete:`Διαγράφω`,
        Logout:`Αποσύνδεση`,
        my_profile:`Το ΠΡΟΦΙΛ μου`,
        guides_faqs:`Οδηγοί & Συχνές Ερωτήσεις`,
        More:`Περισσότερο`,
        guides:`Οδηγοί`,
        register:`κανω ΕΓΓΡΑΦΗ`,
        hot_faq:`Καυτό FAQ`,
        Contents:`Περιεχόμενα:`,
        contact_us:`Επικοινωνήστε μαζί μας >>`,
        plan:`Σχέδιο`,
        unregistered:`Αδήλωτος`,
        buy_more:`Αγόρασε περισσότερα`,
        buy_again:`Αγοράστε ξανά`,
        buy_now:`Αγορασε τωρα`,
        free_no_limit:`Δωρεάν & Χωρίς όριο`,
        expired:`έχει λήξει`,
        lifetime:`Διάρκεια Ζωής`,
        remain:`Παραμένει`,
        day_s:`Μέρες)`,
        error_24801:`Το αίτημα απέτυχε (κωδικός σφάλματος: 24801). Δοκιμάστε το ξανά`,
        no_app_found:`Δεν βρέθηκε εφαρμογή！<a href="/">Ανανέωση</a> ή <a href="https://el.aiseesoft.com/">Μεταβείτε στον επίσημο ιστότοπο</a>`,
        get_more:`Λάβετε περισσότερα >>`,
        edit_photo:`Επεξεργασία φωτογραφίας`,
        select_photo:`Επιλέξτε φωτογραφία`,
        change_photo:`Αλλαξε φωτογραφία`,
        cancel:`Ματαίωση`,
        hide_password:`Απόκρυψη κωδικού πρόσβασης`,
        show_password:`Δείξε τον κωδικό`,
        zoom_in:`Μεγέθυνση`,
        zoom_out:`Σμίκρυνση`,
        rotate:`Γυρίζω`,
        horizontal_flip:`Οριζόντια ανατροπή`,
        vertical_flip:`Κατακόρυφη ανατροπή`,
        country:`Χώρα`,
        country_1:`Επιλέξτε τη χώρα/περιοχή σας`,
        country_2:`Νησί Åaland`,
        country_3:`Αφγανιστάν`,
        country_4:`Αλβανία`,
        country_5:`Αλγερία`,
        country_6:`Αμερικάνικη Σαμόα`,
        country_7:`Ανδόρα`,
        country_8:`Αγκόλα`,
        country_9:`Ανγκουίλα`,
        country_10:`Ανταρκτική`,
        country_11:`Αντίγκουα & Μπαρμπούντα`,
        country_12:`Αργεντίνη`,
        country_13:`Αρμενία`,
        country_14:`Αρούμπα`,
        country_15:`Αυστραλία`,
        country_16:`Αυστρία`,
        country_17:`Αζερμπαϊτζάν`,
        country_18:`Μπαχρέιν`,
        country_19:`Μπαγκλαντές`,
        country_20:`Μπαρμπάντος`,
        country_21:`Λευκορωσία`,
        country_22:`Βέλγιο`,
        country_23:`Μπελίζ`,
        country_24:`Μπενίν`,
        country_25:`Βερμούδα`,
        country_26:`Μπουτάν`,
        country_27:`Βολιβία`,
        country_28:`Βοσνία-Ερζεγοβίνη`,
        country_29:`Μποτσουάνα`,
        country_30:`Νησί Μπουβέ`,
        country_31:`Βραζιλία`,
        country_32:`Βρετανική επικράτεια Ινδικού Ωκεανού`,
        country_33:`Βρετανικές Παρθένοι Νήσοι`,
        country_34:`Μπρουνέι`,
        country_35:`Βουλγαρία`,
        country_36:`Μπουρκίνα`,
        country_37:`Μπουρούντι`,
        country_38:`Καμπότζη`,
        country_39:`Καμερούν`,
        country_40:`Καναδάς`,
        country_41:`Πράσινο Ακρωτήριο`,
        country_42:`Καραϊβική Ολλανδία`,
        country_43:`Νησιά Κέιμαν`,
        country_44:`Δημοκρατία Κεντρικής Αφρικής`,
        country_45:`Τσαντ`,
        country_46:`χιλή`,
        country_47:`Κίνα`,
        country_48:`Νησί των Χριστουγέννων`,
        country_49:`Νησιά Cocos (Keeling).`,
        country_50:`Κολομβία`,
        country_51:`Νησιά Κουκ`,
        country_52:`Κόστα Ρίκα`,
        country_53:`"Ακτή Ελεφαντοστού"`,
        country_54:`την Κροατία`,
        country_55:`Κούβα`,
        country_56:`Κύπρος`,
        country_57:`Τσεχική Δημοκρατία`,
        country_58:`Λαϊκή Δημοκρατία του Κονγκό`,
        country_59:`Δανία`,
        country_60:`Τζιμπουτί`,
        country_61:`Δομίνικα`,
        country_62:`Δομινικανή Δημοκρατία`,
        country_63:`Εκουαδόρ`,
        country_64:`Αίγυπτος`,
        country_65:`Ελ Σαλβαδόρ`,
        country_66:`Ισημερινή Γουινέα`,
        country_67:`Ερυθραία`,
        country_68:`Εσθονία`,
        country_69:`Αιθιοπία`,
        country_70:`Νησιά Φώκλαντ`,
        country_71:`Νήσοι Φερόες`,
        country_72:`Ομόσπονδες Πολιτείες της Μικρονησίας`,
        country_73:`Φίτζι`,
        country_74:`Φινλανδία`,
        country_75:`Γαλλία`,
        country_76:`Γαλλική Γουιάνα`,
        country_77:`Γαλλική Πολυνησία`,
        country_78:`Γαλλικά Νότια Εδάφη`,
        country_79:`Γκαμπόν`,
        country_80:`Γκάμπια`,
        country_81:`Γεωργία`,
        country_82:`Γερμανία`,
        country_83:`Γκάνα`,
        country_84:`Γιβραλτάρ`,
        country_85:`Μεγάλη Βρετανία (Ηνωμένο Βασίλειο, Αγγλία)`,
        country_86:`Ελλάδα`,
        country_87:`Γροιλανδία`,
        country_88:`Γρενάδα`,
        country_89:`Γουαδελούπη`,
        country_90:`Γκουάμ`,
        country_91:`Γουατεμάλα`,
        country_92:`Γκέρνσεϊ`,
        country_93:`Γκινέα`,
        country_94:`Γουινέα-Μπισάου`,
        country_95:`Γουιάνα`,
        country_96:`Αΐτη`,
        country_97:`Heard Island και McDonald Islands`,
        country_98:`Ονδούρα`,
        country_99:`Χονγκ Κονγκ`,
        country_100:`Ουγγαρία`,
        country_101:`Ισλανδία`,
        country_102:`Ινδία`,
        country_103:`Ινδονησία`,
        country_104:`Ιράν`,
        country_105:`Ιράκ`,
        country_106:`Ιρλανδία`,
        country_107:`Isle of Man`,
        country_108:`Ισραήλ`,
        country_109:`Ιταλία`,
        country_110:`Ιαμαϊκή`,
        country_111:`Ιαπωνία`,
        country_112:`Φανέλα`,
        country_113:`Ιορδανία`,
        country_114:`Καζακστάν`,
        country_115:`Κενύα`,
        country_116:`Κιριμπάτι`,
        country_117:`Κουβέιτ`,
        country_118:`Κιργιζιστάν`,
        country_119:`Λάος`,
        country_120:`Λετονία`,
        country_121:`Λίβανος`,
        country_122:`Λεσότο`,
        country_123:`Λιβερία`,
        country_124:`Λιβύη`,
        country_125:`Λιχτενστάιν`,
        country_126:`Λιθουανία`,
        country_127:`Λουξεμβούργο`,
        country_128:`Μακάο`,
        country_129:`Μαδαγασκάρη`,
        country_130:`Μαλάουι`,
        country_131:`Μαλαισία`,
        country_132:`Μαλδίβες`,
        country_133:`Μάλι`,
        country_134:`Μάλτα`,
        country_135:`Νήσοι Μάρσαλ`,
        country_136:`Μαρτινίκα`,
        country_137:`Μαυριτανία`,
        country_138:`Μαυρίκιος`,
        country_139:`Μαγιότ`,
        country_140:`Μεξικό`,
        country_141:`Μολδαβία`,
        country_142:`Μονακό`,
        country_143:`Μογγολία`,
        country_144:`Μαυροβούνιο`,
        country_145:`Μονσεράτ`,
        country_146:`Μαρόκο`,
        country_147:`Μοζαμβίκη`,
        country_148:`Μιανμάρ (Βιρμανία)`,
        country_149:`Ναμίμπια`,
        country_150:`Ναούρου`,
        country_151:`Νεπάλ`,
        country_152:`Ολλανδία`,
        country_153:`Νέα Καληδονία`,
        country_154:`Νέα Ζηλανδία`,
        country_155:`Νικαράγουα`,
        country_156:`Νίγηρας`,
        country_157:`Νιγηρία`,
        country_158:`Niue`,
        country_159:`Νησί Νόρφολκ`,
        country_160:`Βόρεια Κορέα`,
        country_161:`Βορεια Νησια Μαριανα`,
        country_162:`Νορβηγία`,
        country_163:`Ομάν`,
        country_164:`Πακιστάν`,
        country_165:`Παλάου`,
        country_166:`παλαιστινιακά εδάφη`,
        country_167:`Παναμάς`,
        country_168:`Παπούα Νέα Γουινέα`,
        country_169:`Παραγουάη`,
        country_170:`Περού`,
        country_171:`Νησιά Πίτκερν`,
        country_172:`Πολωνία`,
        country_173:`Πορτογαλία`,
        country_174:`Πουέρτο Ρίκο`,
        country_175:`Κατάρ`,
        country_176:`Δημοκρατία της Μακεδονίας (FYROM)`,
        country_177:`Δημοκρατία του Κονγκό`,
        country_178:`Επανένωση`,
        country_179:`Ρουμανία`,
        country_180:`Ρωσική Ομοσπονδία`,
        country_181:`Ρουάντα`,
        country_182:`Άγιος Βαρθολομαίος`,
        country_183:`Saint Martin (Γαλλία)`,
        country_184:`Σεν Πιερ και Μικελόν`,
        country_185:`Σαμόα`,
        country_186:`Σαν Μαρίνο`,
        country_187:`Σάο Τομέ & Πρίνσιπε`,
        country_188:`Σαουδική Αραβία`,
        country_189:`Σενεγάλη`,
        country_190:`Σερβία`,
        country_191:`Σεϋχέλλες`,
        country_192:`Σιέρρα Λεόνε`,
        country_193:`Σιγκαπούρη`,
        country_194:`Σλοβακία`,
        country_195:`Σλοβενία`,
        country_196:`Νησιά του Σολομώντα`,
        country_197:`Σομαλία`,
        country_198:`Νότια Αφρική`,
        country_199:`Νότια Γεωργία και το Νότιο Σάντουιτς Νήσος`,
        country_200:`Νότια Κορέα`,
        country_201:`Νότιο Σουδάν`,
        country_202:`Ισπανία`,
        country_203:`Σρι Λάνκα`,
        country_204:`Αγία Ελένη & Εξαρτήσεις`,
        country_205:`Σεντ Κιτς & Νέβις`,
        country_206:`Αγία Λουκία`,
        country_207:`Άγιος Βικέντιος & Γρεναδίνες`,
        country_208:`Σουδάν`,
        country_209:`Σουρινάμ`,
        country_210:`Σουαζιλάνδη`,
        country_211:`Σουηδία`,
        country_212:`Ελβετία`,
        country_213:`Συρία`,
        country_214:`Ταϊβάν`,
        country_215:`Τατζικιστάν`,
        country_216:`Τανζανία`,
        country_217:`Πρότυπο:Country data SJM Svalbard`,
        country_218:`Ταϊλάνδη`,
        country_219:`Οι Μπαχάμες`,
        country_220:`Οι Κομόρες`,
        country_221:`Οι Φιλιππίνες`,
        country_222:`Ανατολικό Τιμόρ (Ανατολικό Τιμόρ)`,
        country_223:`Να πάω`,
        country_224:`Τοκελάου`,
        country_225:`Τόνγκα`,
        country_226:`Τρινιντάντ & Τομπάγκο`,
        country_227:`Τυνησία`,
        country_228:`Τουρκία`,
        country_229:`Τουρκμενιστάν`,
        country_230:`Νησιά Turks & Caicos`,
        country_231:`Τουβαλού`,
        country_232:`Ουγκάντα`,
        country_233:`Ουκρανία`,
        country_234:`Ηνωμένα Αραβικά Εμιράτα`,
        country_235:`Μικρά Απομακρυσμένα Νησιά των Ηνωμένων Πολιτειών`,
        country_236:`Ηνωμένες Πολιτείες Αμερικής (ΗΠΑ)`,
        country_237:`Παρθένοι Νήσοι των Ηνωμένων Πολιτειών`,
        country_238:`Ουρουγουάη`,
        country_239:`Ουζμπεκιστάν`,
        country_240:`Βανουάτου`,
        country_241:`Πόλη του Βατικανού (Αγία Έδρα)`,
        country_242:`Βενεζουέλα`,
        country_243:`Βιετνάμ`,
        country_244:`Wallis και Futuna`,
        country_245:`Δυτική Σαχάρα`,
        country_246:`Γέμενη`,
        country_247:`Ζάμπια`,
        country_248:`Ζιμπάμπουε`,
        google_login:`Συνδεθείτε με το Google`,
        footer:`Πνευματικά δικαιώματα © 2024 Aiseesoft Studio. Ολα τα δικαιώματα διατηρούνται.`,
        State:`κατάσταση`,
        Activation_code:`Κωδικός ενεργοποίησης`,
        Question:`Καταχωρίστε όλες τις εφαρμογές στις οποίες έχετε συνδεθεί`,
        Copy_complete:`Η αντιγραφή ολοκληρώθηκε`,

        
        store:`Κατάστημα`,
        resource:`Πόρος`,
        support:`υποστήριξη`,
        video_converter:`Μετατροπέας βίντεο`,
        multimedia:`ΠΟΛΥΜΕΣΑ`,
        data_recovery:`Ανάκτηση δεδομένων`,
        utility:`Χρησιμότητα`,
        free_tools:`Δωρεάν Εργαλεία`,
        for_windows:`Για Windows`,
        for_mac:`Για Mac`,
        pdf_solutions:`Λύσεις PDF`,
        data_manager:`Διαχειριστής δεδομένων`,
        other_tools:`Άλλα εργαλεία`,
        free_online_solutions:`Δωρεάν διαδικτυακές λύσεις`,
        free_online_tools:`Δωρεάν διαδικτυακά εργαλεία`,
        desktop_freeware:`Δωρεάν λογισμικό για επιτραπέζιους υπολογιστές`,
        product_1:`Video Converter Ultimate`,
        product_2:`Συνολικός μετατροπέας βίντεο`,
        product_3:`Μετατροπέας MTS`,
        product_4:`Μετατροπέας 4K`,
        product_5:`Μετατροπέας MXF`,
        product_6:`Μετατροπέας 3D`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Μετατροπέας βίντεο για Mac`,
        product_9:`Μετατροπέας MTS για Mac`,
        product_10:`Μετατροπέας 4K για Mac`,
        product_11:`Μετατροπέας MXF για Mac`,
        product_12:`Μετατροπέας 3D για Mac`,
        product_13:`Blu-ray player`,
        product_14:`Βελτιωτή βίντεο`,
        product_15:`Επεξεργαστής βίντεο`,
        product_16:`Δημιουργός παρουσίασης`,
        product_17:`Δημιουργός Blu-ray`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`Δημιουργός DVD`,
        product_20:`Mac Blu-ray Player`,
        product_21:`Mac Video Enhancer`,
        product_22:`Πρόγραμμα επεξεργασίας βίντεο για Mac`,
        product_23:`DVD Creator για Mac`,
        product_24:`Μετατροπέας MP4 για Mac`,
        product_25:`Ανάκτηση δεδομένων των Windows`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab System Recovery iOS`,
        product_28:`Δημιουργία αντιγράφων ασφαλείας δεδομένων FoneLab iOS &amp; Επαναφέρω`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Κατεστραμμένη εξαγωγή δεδομένων Android`,
        product_31:`Δημιουργία αντιγράφων ασφαλείας δεδομένων Android &amp; Επαναφέρω`,
        product_32:`Ανάκτηση δεδομένων Mac`,
        product_33:`Ανάκτηση δεδομένων iPhone Mac FoneLab`,
        product_34:`Ανάκτηση συστήματος Mac FoneLab iOS`,
        product_35:`Δημιουργία αντιγράφων ασφαλείας δεδομένων Mac FoneLab iOS &amp; Επαναφέρω`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Backup δεδομένων &amp; Επαναφέρω`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate για Mac`,
        product_40:`Συγχώνευση PDF`,
        product_41:`Μετατροπέας Mac PDF σε ePub`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer για iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Καθαριστικό iPhone`,
        product_48:`Μετατροπέας PPT σε βίντεο`,
        product_49:`Μετατροπέας HEIC`,
        product_50:`Ξεκλείδωμα iPhone`,
        product_51:`Δωρεάν Online Μετατροπέας βίντεο`,
        product_52:`Δωρεάν διαδικτυακός μετατροπέας ήχου`,
        product_53:`Δωρεάν Online Video Compressor`,
        product_54:`Δωρεάν συγχώνευση βίντεο στο Διαδίκτυο`,
        product_55:`Δωρεάν μετατροπέας HEIC`,
        product_56:`Δωρεάν Συμπιεστής Εικόνας`,
        product_57:`Δωρεάν εργαλείο αφαίρεσης υδατογραφημάτων`,
        product_58:`Δωρεάν διαδικτυακός συμπιεστής PDF`,
        product_59:`Δωρεάν Online Upscaler εικόνας`,
        product_60:`Δωρεάν Online Remover Background`,
        product_61:`Δωρεάν Online Μετατροπέας εικόνας`,
        product_62:`Δωρεάν διαδικτυακός μετατροπέας PDF PNG`,
        product_63:`Δωρεάν μετατροπέας βίντεο`,
        product_64:`Δωρεάν μετατροπέας βίντεο σε GIF`,
        product_65:`Δωρεάν ανάκτηση δεδομένων Android`,
        product_66:`Slideshow Maker`,
        product_67:`Δωρεάν πρόγραμμα επεξεργασίας βίντεο`,
        product_68:`Δωρεάν πρόγραμμα προβολής PDF`,
        product_69:`Εγγραφή οθόνης`,
        product_70:`Εγγραφή οθόνης Mac`,
        product_71:`Δωρεάν συσκευή εγγραφής οθόνης σε απευθείας σύνδεση`,
        product_72:`Δωρεάν συσκευή εγγραφής ήχου σε απευθείας σύνδεση`,
        product_73:`Καθρέφτης τηλεφώνου`,
        product_74:`AnyCoord - Αλλαγή τοποθεσίας`,
        product_75:`Διαχειριστής κωδικών πρόσβασης iPhone`,
        product_76:`Δωρεάν Συμπιεστής Ήχου Online`,
        product_77:`Δωρεάν Video Trimmer Online`,
        product_78:`Δωρεάν Video Cropper Online`,
        product_79:`Δωρεάν Video Rotator Online`,
        product_80:`Δωρεάν Video Looper Online`,
        product_81:`Δωρεάν διαδικτυακός μετατροπέας PDF JPG`,
        product_81:`Δωρεάν JPG Word Converter Online`,
        category_1:`ΕΠΕΞΕΡΓΑΣΙΑ ΦΩΤΟΓΡΑΦΙΑΣ`,
        category_2:`Επεξεργασία βίντεο`,
        category_3:`Γόμα`,
        category_4:`Συσκευή εγγραφής πολυμέσων`,
        posted_by:`αναρτήθηκε από`,
        to:`προς το`,
        author_1:`Ashley Mae`,
        author_2:`Τζένι Ράιαν`,
        author_3:`Νικόλ Τζόουνς`,
        author_4:`Αμάντα Μπράουν`,
        article_1:`Πώς να αφαιρέσετε το κείμενο από τις εικόνες αποτελεσματικά`,
        article_2:`Πώς να αυξήσετε το μέγεθος της εικόνας σε KB σε MB χωρίς να καταστρέψετε την ποιότητα`,
        article_3:`Πώς να αφαιρέσετε το υδατογράφημα Getty Images σε Windows και Mac`,
        article_4:`Η καλύτερη μέθοδος μετατροπής εικόνας χαμηλής ανάλυσης σε υψηλής ανάλυσης Online δωρεάν`,
        article_5:`Οδηγός TikTok | Δείτε πώς να φτιάξετε ένα κολάζ βίντεο στο TikTok`,
        article_6:`5 εύκολοι τρόποι για να συνδυάσετε αρχεία VOB που πρέπει να γνωρίζετε`,
        article_7:`Πώς να αναβαθμίσετε το βίντεο και να αποκτήσετε εύκολα υψηλότερη ανάλυση`,
        article_8:`Πώς να συνδυάσετε αρχεία MP4 σε MacBook Pro/iMac/MacBook και άλλα`,
        article_9:`2 απλοί τρόποι για να διαγράψετε το ιστορικό αγορών στο iPhone [Επίλυση]`,
        article_10:`Πώς να διαγράψετε μόνιμα εικόνες Snapchat από τη συνομιλία και τις αναμνήσεις`,
        article_11:`Πώς να διαγράψετε τις προτάσεις και το ιστορικό αναζήτησης Spotlight στο iPhone/iPad`,
        article_12:`[Επιλύθηκε] Πώς να εκκαθαρίσετε την προσωρινή μνήμη Instagram στο iPhone με 3 εύκολους τρόπους`,
        article_13:`[Επιλύθηκε] Πώς να διορθώσετε το QuickTime Player που δεν λειτουργεί σε Mac`,
        article_14:`Πώς να εγγράψετε ήχο συστήματος σε Mac (Big Sur/Catalina/Mojave…)`,
        article_15:`Αποτελεσματικοί τρόποι για να διορθώσετε ότι η εγγραφή στην οθόνη του PowerPoint δεν λειτουργεί`,
        article_16:`Τρόπος εγγραφής ήχου με VLC [Λύθηκε]`,
        faq_title_1:`1. Πώς να διορθώσετε το σφάλμα 4104;`,
        faq_title_2:`2. Πότε μπορώ να πάρω πίσω τα χρήματά μου αφού εγκριθεί το αίτημα επιστροφής χρημάτων;`,
        faq_title_3:`3. Τι είναι η "Συνδρομή 1 έτους" και πώς να απενεργοποιήσετε την "Υπηρεσία αυτόματης ανανέωσης";`,
        faq_title_4:`4. Μπορώ να ακυρώσω την παραγγελία μου μετά την τοποθέτηση του προϊόντος Aiseesoft;`,
        faq_title_5:`5. Πώς να υποβάλετε μια κριτική σε εσάς;`,
        faq_info_1:`Αυτό οφείλεται στην περιορισμένη άδεια χρήσης που έχετε. Υιοθετούμε Ενιαία Άδεια σε περίπτωση κλοπής του κωδικού εγγραφής και αποτρέπουμε την παραβίαση της χρήσης του κωδικού εγγραφής. Έτσι ο Reg Code που λάβατε είναι Ενιαία Άδεια Χρήσης που μπορεί να χρησιμοποιηθεί μόνο σε έναν υπολογιστή. Εάν το χρησιμοποιείτε σε περισσότερους από έναν υπολογιστές, θα υπάρχει Σφάλμα 4104. Δεν μπορείτε να το χρησιμοποιήσετε μέχρι να το επαναφέρουμε για εσάς.`,
        faq_info_2:`Εάν αλλάξετε ή επανεγκαταστήσετε τον υπολογιστή σας, μπορούμε να τον επαναφέρουμε για εσάς. Επικοινωνήστε με την εξυπηρέτηση πελατών: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> για να ζητήσετε επαναφορά του κωδικού εγγραφής για εσάς.`,
        faq_info_3:`Μόλις εγκριθεί το αίτημα επιστροφής χρημάτων σας, κανονικά θα χρειαστούν 3~7 εργάσιμες ημέρες για να εμφανιστεί η πίστωση στον λογαριασμό σας. Μερικές φορές κατά τη διάρκεια των διακοπών, μπορεί να διαρκέσει περισσότερο, αλλά δεν αναμένεται να διαρκέσει πάνω από 10 ημέρες. Εάν δεν έχετε λάβει την επιστροφή χρημάτων 20 ημέρες μετά την έγκριση, επικοινωνήστε μαζί μας στη διεύθυνση <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> για να ελέγξετε την επιστροφή χρημάτων.`,
        faq_info_4:`Υπηρεσία αυτόματης ανανέωσης σημαίνει ότι εάν αγοράσετε Συνδρομή 1 έτους, η πλατφόρμα πληρωμών θα χρεώσει αυτόματα το ποσό (συμπεριλαμβανομένων των ισχυόντων φόρων) όταν λήξει η άδεια χρήσης προϊόντος. Αλλά η κατ' αποκοπή μορφή πληρωμής (Swreg ή 2Checkout) θα σας στείλει μια επιστολή για να σας υπενθυμίσει ότι η παραγγελία θα λήξει 7 ημέρες πριν από τη λήξη. Το e-mail θα περιλαμβάνει επίσης οδηγίες για τον τρόπο απενεργοποίησης της «Υπηρεσίας αυτόματης ανανέωσης».`,
        faq_info_5:`Δεδομένου ότι η παραγγελία πραγματοποιείται αυτόματα, δεν επιτρέπεται η ακύρωση της παραγγελίας. Επιπλέον, η δωρεάν δοκιμαστική έκδοση θα σας επιτρέψει να μάθετε περισσότερα για το πρόγραμμα Aiseesoft και να ελέγξετε αν μπορεί να καλύψει τις ανάγκες σας πριν το αγοράσετε.`,
        faq_info_6:`Εάν θέλετε πραγματικά να λάβετε επιστροφή χρημάτων, ανατρέξτε στην <a href="https://el.aiseesoft.com/refund-policy.html">Πολιτική επιστροφής χρημάτων</a>.`,
        faq_info_7:`Εκτιμούμε το χρόνο και την προσοχή σας για να μας υποβάλετε μια κριτική. Επικοινωνήστε μαζί μας (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) για να υποβάλετε την κριτική σας και θα εξετάσουμε σοβαρά την κριτική σας για τις ενημερώσεις των προϊόντων μας.`,
        change_password_success:`Ο κωδικός άλλαξε επιτυχώς`,
        successful_login_title:`Επιτυχία σύνδεσης`,
        product_page:`Σελίδα προϊόντος >>`,
        successful_login_info:`Η σύνδεση ολοκληρώθηκε. Κλείστε την τρέχουσα σελίδα και επιστρέψτε στην αρχική καρτέλα για να συνεχίσετε με την ακόλουθη διαδικασία. Η τρέχουσα σελίδα θα κλείσει αυτόματα σε 5 δευτερόλεπτα. Εάν το αυτόματο κλείσιμο ή το κλείσιμο κάνοντας κλικ στο κουμπί "Τέλος" αποτύχει, κλείστε απευθείας αυτήν την καρτέλα.`,
        successful_login_info_firefox:`Η σύνδεση ολοκληρώθηκε. Κλείστε την τρέχουσα σελίδα και επιστρέψτε στην αρχική καρτέλα για να συνεχίσετε με την ακόλουθη διαδικασία.`,
        my_account:`Ο λογαριασμός μου`,
        my_history:`Η Ιστορία μου`,
        remove_watermark:`Αφαιρέστε το υδατογράφημα`,
        no_history:`Χωρίς Ιστορία`,
        history_all:`Επιλογή όλων`,
        history_open:`Ανοιξε`,
        history_down:`Κατεβάστε`,
        history_delete:`Διαγράφω`,
        history_clear:`Διαγραφή μη έγκυρου`,
        images:`εικόνα(εις)`,
        use_this_function:`Χρησιμοποιήστε αυτήν τη συνάρτηση >>`,
        hd_downloading:`Πλεονεκτήματα λήψης πρωτότυπων εικόνων HD:`,
        lifetimeRemaining:`Lifetime-Remaining`,
        Remaining:`Παραμένων`,
        email_verification:`Επαλήθευση email`,
        email_verification_info:`Έχουμε στείλει το μήνυμα επαλήθευσης στο email σας <span class="email"></span> και ολοκληρώστε την επαλήθευση. Μετά την επαλήθευση, τα οφέλη θα συγχρονιστούν αυτόματα.`,
        wrong_email:`Λάθος διεύθυνση email;`,
        click_here_to_modify:`Κάντε κλικ εδώ για τροποποίηση`,
        get_more_help:`Λάβετε περισσότερη βοήθεια;`,
        click_here:`Κάντε κλικ ΕΔΩ`,
        email_verification_info_success:`Συγχαρητήρια για την επαλήθευση του λογαριασμού email σας.`,
        email_verification_info_error:`Η επαλήθευση απέτυχε λόγω του ληγμένου συνδέσμου.`,
        registration_succeeded:`Η εγγραφή ολοκληρώθηκε`,
        registration_succeeded_info_1:`Συγχαρητήρια! Έχετε εγγραφεί επιτυχώς. Έχουμε στείλει το μήνυμα επαλήθευσης στο email σας <span class="email"></span> και ολοκληρώστε την επαλήθευση για να χρησιμοποιήσετε τα πλεονεκτήματα αυτού του μηνύματος ηλεκτρονικού ταχυδρομείου.`,
        registration_succeeded_info_2:`Κάντε κλικ στο "Τέλος" για να επιστρέψετε στην Αρχική σελίδα και να χρησιμοποιήσετε αυτό το προϊόν.`,
        registration_succeeded_info_3:`Κάντε κλικ στο "Τέλος" για να κλείσετε την τρέχουσα σελίδα και να επιστρέψετε στην Αρχική σελίδα για τις ακόλουθες λειτουργίες. Εάν το κλείσιμο αποτύχει, κλείστε χειροκίνητα την καρτέλα.`,
        verify_email:`Επαλήθευση email`,
        registered_email_not_verified:`Το καταχωρημένο email δεν έχει επαληθευτεί, επαληθεύστε το αμέσως.`,
        email_verification_time_1:`Δεν λάβατε email επαλήθευσης;`,
        email_verification_time_2:`Μετά`,
        email_verification_time_3:`Κάντε κλικ εδώ για να το ξαναστείλετε`,
        error_26301:`Κωδικός σφάλματος: 26301, επικοινωνήστε μαζί μας`,
        error_26302:`Κωδικός σφάλματος: 26302, επικοινωνήστε μαζί μας`,
        error_26303:`Σφάλμα μορφής email (κωδικός σφάλματος: 26303). Εισαγάγετε το ξανά`,
        error_26304:`Συνιστάται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 26304)`,
        error_26305:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26305). Παρακαλώ δοκιμάστε το ξανά`,
        error_26306:`Το email έχει καταχωρηθεί, <a href="https://account.aiseesoft.com/login">μεταβείτε για να συνδεθείτε</a>`,
        error_26307:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26307). Παρακαλώ δοκιμάστε το ξανά`,
        error_26308:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26308). Παρακαλώ δοκιμάστε το ξανά`,
        error_26401:`Κωδικός σφάλματος: 26401, δοκιμάστε ξανά`,
        error_26402:`Το email έχει επαληθευτεί (κωδικός σφάλματος: 26402), δοκιμάστε το ξανά`,
        error_26403:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26403). Παρακαλώ δοκιμάστε το ξανά`,
        error_26404:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26404). Παρακαλώ δοκιμάστε το ξανά`,
        error_26501:`Κωδικός σφάλματος: 26501, επικοινωνήστε μαζί μας`,
        error_26502:`Κωδικός σφάλματος: 26502, επικοινωνήστε μαζί μας`,
        error_26503:`Σφάλμα μορφής email (κωδικός σφάλματος: 26503). Εισαγάγετε το ξανά`,
        error_26504:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26504). Παρακαλώ δοκιμάστε το ξανά`,
        error_26505:`Το email δεν έχει καταχωρηθεί, <a href="https://account.aiseesoft.com/register">εγγραφείτε πρώτα</a>`,
        error_26506:`Το email έχει επαληθευτεί.`,
        error_26507:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26507). Παρακαλώ δοκιμάστε το ξανά`,
        error_26508:`Η επαλήθευση ολοκληρώθηκε (κωδικός σφάλματος: 26508), δοκιμάστε ξανά`,
        error_26509:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26509), δοκιμάστε το ξανά`,
        error_26510:`Κωδικός σφάλματος: 26510, επικοινωνήστε μαζί μας`,
        error_26601:`Κωδικός σφάλματος: 26601, επικοινωνήστε μαζί μας`,
        error_26602:`Κωδικός σφάλματος: 26602, επικοινωνήστε μαζί μας`,
        error_26603:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26603). Παρακαλώ δοκιμάστε το ξανά`,
        error_26604:`Κωδικός σφάλματος: 26604, επικοινωνήστε μαζί μας`,
        error_26605:`Κωδικός σφάλματος: 26605, επικοινωνήστε μαζί μας`,
        error_26701:`Κωδικός σφάλματος: 26701, επικοινωνήστε μαζί μας`,
        error_26702:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26702). Παρακαλώ δοκιμάστε το ξανά`,
        error_26703:`Κωδικός σφάλματος: 26703, επικοινωνήστε μαζί μας`,
        error_26704:`Κωδικός σφάλματος: 26704, επικοινωνήστε μαζί μας`,
        error_26705:`Περιμένετε να συνδεθείτε (κωδικός σφάλματος: 26705). Παρακαλώ δοκιμάστε το ξανά`,
        no_cookie:`Έχετε ενεργοποιήσει τη λειτουργία Αποκλεισμός όλων των cookies στο πρόγραμμα περιήγησής σας, επομένως δεν μπορείτε να συνδεθείτε. Μεταβείτε στις Ρυθμίσεις για να επιλέξετε το πλαίσιο Επιτρέπονται όλα τα cookies.`,
        error_26801:`Κωδικός σφάλματος: 26801, επικοινωνήστε μαζί μας`,
        error_26802:`Κωδικός σφάλματος: 26802, επικοινωνήστε μαζί μας`,
        error_26803:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26803). Παρακαλώ δοκιμάστε το ξανά`,
        error_26804:`Το αίτημα απέτυχε (κωδικός σφάλματος: 26804). Παρακαλώ δοκιμάστε το ξανά`,
        error_order:`Το αίτημα απέτυχε (κωδικός σφάλματος: 27098), δοκιμάστε το ξανά!`,
        error_order1:`Το ερώτημα παραγγελίας είναι ελλιπές (κωδικός σφάλματος:`,
        error_order2:`）, ανανεώστε και δοκιμάστε το ξανά.`,
        modify_email_title:`Τροποποίηση email`,
        modify_email_info:`Μπορείτε να χρησιμοποιήσετε το τροποποιημένο email για να συνδεθείτε στο λογαριασμό σας.`,
        images_per:`Εικόνες ανά`,
        error_26101:`Σφάλμα: 26101. Επικοινωνήστε μαζί μας`,
        error_26102:`Σφάλμα: 26102. Επικοινωνήστε μαζί μας`,
        error_26103:`Το αίτημα απέτυχε (κωδικός λάθους:26103). Δοκιμάστε το ξανά`,
        error_26104:`Κωδικός σφάλματος: 26104, παρακαλώ δοκιμάστε το ξανά`,
        error_26105:`Κωδικός σφάλματος: 26105, παρακαλώ δοκιμάστε το ξανά`,
        error_26106:`Η διαγραφή απέτυχε (κωδικός σφάλματος: 26106). Δοκιμάστε το ξανά`,
        error_26201:`Σφάλμα: 26201. Επικοινωνήστε μαζί μας`,
        error_26202:`Το αίτημα απέτυχε (κωδικός λάθους:26202). Δοκιμάστε το ξανά`,
        error_26001:`Σφάλμα: 26001. Επικοινωνήστε μαζί μας`,
        error_26002:`Σφάλμα: 26002. Επικοινωνήστε μαζί μας`,
        error_26003:`Σφάλμα: 26003. Επικοινωνήστε μαζί μας`,
        error_26004:`Σφάλμα: 26004. Επικοινωνήστε μαζί μας`,
        error_26005:`Το αίτημα απέτυχε (κωδικός λάθους:26005). Δοκιμάστε το ξανά`,
        error_26006:`Κωδικός σφάλματος: 26006, παρακαλώ δοκιμάστε το ξανά`,
        error_26008:`Σφάλμα: 26008. Επικοινωνήστε μαζί μας`,
        go_to_the_home_page:`Μεταβείτε στην Αρχική σελίδα`,
        error_27101:`Το αίτημα απέτυχε (κωδικός σφάλματος: 27101). Παρακαλώ δοκιμάστε ξανά`,
        error_27201:`Κωδικός σφάλματος: 27201, επικοινωνήστε μαζί μας`,
        error_27202:`Κωδικός σφάλματος: 27202, δοκιμάστε ξανά`,
        error_27203:`Το αίτημα απέτυχε (κωδικός σφάλματος: 27203). Παρακαλώ δοκιμάστε το ξανά`,
        error_27204:`Μη έγκυρος κωδικός (κωδικός σφάλματος: 27204).`,
        error_27205:`Το αίτημα απέτυχε (κωδικός σφάλματος: 27205). Παρακαλώ δοκιμάστε το ξανά`,
        error_27206:`Το αίτημα απέτυχε (κωδικός σφάλματος: 27206). Παρακαλώ δοκιμάστε το ξανά`,
        error_27207:`Το αίτημα απέτυχε (κωδικός σφάλματος: 27207). Παρακαλώ δοκιμάστε το ξανά`,
        no_history_found:`Δεν έχεις χρησιμοποιήσει κανένα εργαλείο! <a href="/">Ανανέωση</a> ή <a href="https://el.aiseesoft.com/">Μεταβείτε στον επίσημο ιστότοπο</a>`,
        error_25301:`Σφάλμα: 25301. Επικοινωνήστε μαζί μας`,
        error_25302:`Σφάλμα: 25302. Επικοινωνήστε μαζί μας`,
        error_25303:`Το αίτημα απέτυχε (κωδικός λάθους: 25303). Δοκιμάστε το ξανά`,
        error_25304:`Το αίτημα απέτυχε (κωδικός λάθους: 25304). Δοκιμάστε το ξανά`,
        error_25305:`Το αίτημα απέτυχε (κωδικός λάθους: 25305). Δοκιμάστε το ξανά`,
        error_25306:`Το αίτημα απέτυχε (κωδικός λάθους: 25306). Δοκιμάστε το ξανά`,
        image_upscaler_p:`Πλεονεκτήματα λήψης εικόνας χωρίς υδατογράφημα:`,
        Available_for:`Διαθέσιμο για:`,
        credit_per:`%s πιστώσεις ανά εικόνα HD`,
        still_valid:`Τα αγορασμένα προγράμματα εξακολουθούν να ισχύουν`,
        credit:`πίστωση(ες)`,
        pc_3rd_info:`Συνδεθείτε με επιτυχία. Μεταβείτε στην εφαρμογή για περαιτέρω λειτουργία.`,
        use_online:`Χρησιμοποιήστε την ηλεκτρονική υπηρεσία`,
        use_download:`Χρησιμοποιήστε το πρόγραμμα επιφάνειας εργασίας`,
        use_immediately:`Χρήση Αμέσως`,
        Use_in_browser:`Χρήση στο πρόγραμμα περιήγησης`,
        win_desktop:`Windows`,
        Mac_desktop:`Μακ`,
    },
    fi:{
        overtime_1:`Virhekoodi:`,
        overtime_2:`, yritä uudelleen`,
        isnetwork:`Internet -virhe. Tarkista ja yritä uudelleen`,
        email_placeholder:`Sähköposti`,
        email_empty:`Anna sähköpostiosoite`,
        email_not_valid:`Sähköpostiosoite ei kelpaa`,
        email_not_valid_1:`Anna sähköpostiosoitteesi`,
        email_not_valid_2:`Sähköpostiosoite ei kelpaa, käytä toista osoitetta.`,
        email_not_valid_3:`Ei sähköpostiosoitetta`,
        password_placeholder:`Salasana`,
        password_empty:`Anna salasana`,
        password_not_valid:`Väärä tili tai salasana`,
        password_not_valid_1:`Yli 8 merkin pituinen salasana vaaditaan`,
        password_not_valid_2:`Luo salasana`,
        password_placeholder_1:`Luo salasanasi`,
        password_placeholder_2:`Vahvista salasana`,
        password_placeholder_3:`Luo uusi salasana`,
        password_placeholder_4:`Vahvista uusi salasana`,
        password_placeholder_5:`Syötä vanha salasana`,
        copy_password_empty:`Vahvista salasana`,
        copy_password_not_valid:`Vahvista salasanasi`,
        copy_passwords_inconsistent:`Salasanavahvistus ei täsmää`,
        code_empty:`Anna vahvistuskoodi`,
        code_not_valid:`Virheellinen vahvistuskoodi`,
        code_placeholder:`Vahvistuskoodi`,
        not_received_code:`Jos postilaatikkosi ei ole saanut vahvistuskoodia pitkään aikaan, hanki vahvistuskoodi uudelleen.`,
        get_first_code:`Hanki ensin vahvistuskoodi.`,
        connect_your_email_placeholder:`Yhdistä sähköpostiosoitteesi`,
        last_name_placeholder:`Anna sukunimesi`,
        first_name_placeholder:`Kirjoita etunimesi, ole hyvä`,
        address_placeholder:`Anna osoitteesi`,
        no_code_text:`Lähetimme vahvistuskoodin. Anna koodi. <span class='tips'>Etkö saanut koodia?`,
        no_code_text_1:`1.Varmista, että sähköpostiosoite on kelvollinen ja että se voi vastaanottaa sähköposteja.`,
        no_code_text_2:`2.Jos järjestelmä lähettää sähköpostin automaattisesti, se voidaan merkitä roskapostiksi tai roskapostiksi. Tarkista, onko sähköposti Roskakori -kansiossa.`,
        no_code_text_3:`3.Etkö voi ratkaista ongelmaa?`,
        no_code_text_3_span:`Ota sitten yhteyttä napsauttamalla tätä.`,
        order_no:`Et ole ostanut yhtään tuotetta. Jos sinulla on kysyttävää, <a href="https://fi.aiseesoft.com/store/">Osta nyt</a> Tai <a href="https://fi.aiseesoft.com/contact.html"> Ota yhteyttä</a>`,
        error_24901:`Nykyisellä tilillä ei ole linkitettyä sähköpostia, eikä se löydä tilauksia. Liitä sähköposti.`,
        user_guide:`Käyttöopas >>`,
        download:`ladata`,
        order_number:`Tilausnumero`,
        Refund:`Maksun palautus`,
        Disabled:`Liikuntarajoitteinen`,
        Normal:`Normaali`,
        Modify:`Muuttaa`,
        Modify_1:`Muokkaa >>`,
        Connect:`Kytkeä`,
        unlink_success:`Linkityksen poistaminen onnistui`,
        connect_success:`Yhdistäminen onnistui`,
        feedback_title:`Kiitos palautteestasi. Jätä ongelmasi, niin vastaamme sinulle 24 tunnin kuluessa.`,
        feedback_thank_you:`Kiitos! <br /> Palautteen lähetys onnistui.`,
        feedback_email:`Kirjoita sähköpostiosoitteesi tähän!`,
        feedback_content:`Jätä kohtaamasi ongelma tai ehdotus tänne.`,
        feedback_submit:`Lähetä`,
        form_contents:`Et ole kirjoittanut kuvausta. Anna se ja lähetä se uudelleen.`,
        old_password:`Anna vanha salasana`,
        new_password:`Luo uusi salasana`,
        old_new_password:`Uusi salasana ei voi olla sama kuin vanha`,
        incorrect_password:`Väärä salasana`,
        delete_no:`Poista nyt`,
        Caps:`CAPS LOCK on päällä`,
        Get:`Saada`,
        Done:`Tehty`,
        error_20001:`Virhe: 20001. Kirjaudu sisään uudelleen.`,
        error_20002:`Virhe: 20002. Kirjaudu sisään uudelleen.`,
        error_20003:`Virhe: 20003. Kirjaudu sisään uudelleen.`,
        error_20004:`Pyyntö epäonnistui (virhekoodi: 20004). Yritä uudelleen.`,
        error_20005:`Kirjautumisistunto on vanhentunut (virhe: 20005). Kirjaudu sisään uudelleen.`,
        error_20006:`Pyyntö epäonnistui (virhekoodi: 20006). Yritä uudelleen.`,
        error_20007:`Kirjautumisistunto on vanhentunut (virhe: 20007). Kirjaudu sisään uudelleen.`,
        error_20008:`Kirjautumisistunto on vanhentunut (virhe: 20008). Kirjaudu sisään uudelleen.`,
        error_20009:`Kirjautumisistunto on vanhentunut (virhe: 20009). Kirjaudu sisään uudelleen.`,
        error_20101:`Anna sähköpostiosoitteesi (virhekoodi: 20101)`,
        error_20102:`Sähköpostiosoite ei kelpaa (virhekoodi: 20102)`,
        error_20103:`Pyyntö epäonnistui (virhekoodi: 20103). Yritä uudelleen`,
        error_20104:`Sähköposti on jo käytössä, <a href="https://account.aiseesoft.com/login"> kirjaudu sisään </a> tai rekisteröidy uuteen`,
        error_20105:`Pyyntö epäonnistui (virhekoodi: 20105). Yritä uudelleen`,
        error_20106:`Sähköpostin lähettäminen epäonnistui. Yritä uudelleen`,
        error_20201:`Anna sähköpostiosoitteesi (virhekoodi: 20201)`,
        error_20202:`Anna salasanasi (virhekoodi: 20202)`,
        error_20203:`Anna vahvistuskoodi (virhekoodi: 20203)`,
        error_20204:`Sähköpostiosoite ei kelpaa (virhekoodi: 20204)`,
        error_20205:`Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 20205)`,
        error_20206:`Pyyntö epäonnistui (virhekoodi: 20206). Yritä uudelleen`,
        error_20207:`Virheellinen vahvistuskoodi`,
        error_20208:`Pyyntö epäonnistui (virhekoodi: 20208). Yritä uudelleen`,
        error_20209:`Pyyntö epäonnistui (virhekoodi: 20209). Yritä uudelleen`,
        error_20301:`Anna sähköpostiosoitteesi (virhekoodi: 20301)`,
        error_20302:`Virhe: 20302. Ota yhteyttä`,
        error_20303:`Sähköpostiosoite ei kelpaa (virhekoodi: 20303)`,
        error_20304:`Pyyntö epäonnistui (virhekoodi: 20304). Yritä uudelleen`,
        error_20305:`Tiliä ei ole olemassa. Anna ensin uudelleen tai <a href="https://account.aiseesoft.com/register">Luo se</a> ensin.`,
        error_20306:`Ei vielä salasanaa. Käytä <a href="https://account.aiseesoft.com/passwordless-login"> salasanatonta kirjautumista </a> tai <a href="create-password"> aseta salasana </a> ja kirjaudu sisään.`,
        error_20308:`Pyyntö epäonnistui (virhekoodi: 20308). Yritä uudelleen`,
        error_20401:`Uloskirjautuminen epäonnistui (virhekoodi: 20401). Yritä uudelleen`,
        error_20501:`Anna sähköpostiosoitteesi (virhekoodi: 20501)`,
        error_20502:`Sähköpostiosoite ei kelpaa (virhekoodi: 20502)`,
        error_20503:`Pyyntö epäonnistui (virhekoodi: 20503). Yritä uudelleen`,
        error_20504:`Sähköpostin lähettäminen epäonnistui. Yritä uudelleen.`,
        error_20601:`Anna sähköpostiosoitteesi (virhekoodi: 20601)`,
        error_20602:`Anna vahvistuskoodi (virhekoodi: 20602)`,
        error_20603:`Sähköpostiosoite ei kelpaa (virhekoodi: 20603)`,
        error_20604:`Pyyntö epäonnistui (virhekoodi: 20604). Yritä uudelleen`,
        error_20606:`Pyyntö epäonnistui (virhekoodi: 20606). Yritä uudelleen`,
        error_20607:`Pyyntö epäonnistui (virhekoodi: 20607). Yritä uudelleen`,
        error_20608:`Pyyntö epäonnistui (virhekoodi: 20608). Yritä uudelleen`,
        error_20701:`Anna sähköpostiosoitteesi (virhekoodi: 20701)`,
        error_20702:`Sähköpostiosoite ei kelpaa (virhekoodi: 20702)`,
        error_20703:`Pyyntö epäonnistui (virhekoodi: 20703). Yritä uudelleen`,
        error_20704:`Tiliä ei ole olemassa. Anna ensin uudelleen tai <a href="https://account.aiseesoft.com/register">Luo se</a> ensin.`,
        error_20705:`Pyyntö epäonnistui (virhekoodi: 20705). Yritä uudelleen`,
        error_20706:`Sähköpostin lähettäminen epäonnistui. Yritä uudelleen`,
        error_20801:`Anna sähköpostiosoitteesi (virhekoodi: 20801)`,
        error_20802:`Virhe: 20802. Ota yhteyttä`,
        error_20803:`Anna vahvistuskoodi (virhekoodi: 20803)`,
        error_20804:`Sähköpostiosoite ei kelpaa (virhekoodi: 20804)`,
        error_20805:`Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 20805)`,
        error_20806:`Pyyntö epäonnistui (virhekoodi: 20806). Yritä uudelleen`,
        error_20808:`Pyyntö epäonnistui (virhekoodi: 20808). Yritä uudelleen`,
        error_20901:`Pyyntö epäonnistui (virhekoodi: 20901). Yritä uudelleen`,
        error_20902:`Pyyntö epäonnistui (virhekoodi: 20902). Yritä uudelleen`,
        error_21000:`Muutokset tallennetaan`,
        error_21001:`Tietoja ei toimiteta (virhekoodi: 21001)`,
        error_21002:`Pyyntö epäonnistui (virhekoodi: 21002). Yritä uudelleen`,
        error_21101:`Anna sähköpostiosoitteesi (virhekoodi: 21101)`,
        error_21102:`Sähköpostiosoite ei kelpaa (virhekoodi: 21102)`,
        error_21103:`Pyyntö epäonnistui (virhekoodi: 21103). Yritä uudelleen`,
        error_21104:`Sähköposti on jo yhdistetty, käytä uutta`,
        error_21105:`Pyyntö epäonnistui (virhekoodi: 21105). Yritä uudelleen`,
        error_21106:`Sähköpostin lähettäminen epäonnistui. Yritä uudelleen`,
        error_21201:`Anna sähköpostiosoitteesi (virhekoodi: 21201)`,
        error_21202:`Anna vahvistuskoodi (virhekoodi: 21202)`,
        error_21203:`Sähköpostiosoite ei kelpaa (virhekoodi: 21203)`,
        error_21204:`Virhe: 21204. Ota yhteyttä`,
        error_21205:`Virhe: 21205. Ota yhteyttä`,
        error_21206:`Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 21206)`,
        error_21207:`Pyyntö epäonnistui (virhekoodi: 21207). Yritä uudelleen`,
        error_21209:`Pyyntö epäonnistui (virhekoodi: 21209). Yritä uudelleen`,
        error_21301:`Anna vanha salasana (virhekoodi: 21301)`,
        error_21302:`Luo uusi salasana (virhekoodi: 21302)`,
        error_21303:`Uusi salasana ei voi olla sama kuin vanha. (Virhe: 21303)`,
        error_21304:`Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 21304)`,
        error_21306:`Pyyntö epäonnistui (virhekoodi: 21306). Yritä uudelleen`,
        error_21402:`Pyyntö epäonnistui (virhekoodi: 21402). Yritä uudelleen`,
        error_21403:`Vahvistuskoodin lähettäminen epäonnistui. Lähetä se uudelleen`,
        error_21500:`Tili on poistettu`,
        error_21501:`Anna vahvistuskoodi (virhekoodi: 21501)`,
        error_21502:`Kirjautumisistunto on vanhentunut (virhe: 21502). Kirjaudu sisään uudelleen.`,
        error_21503:`Pyyntö epäonnistui (virhekoodi: 21503). Yritä uudelleen`,
        error_21505:`Pyyntö epäonnistui (virhekoodi: 21505). Yritä uudelleen`,
        error_21601:`Virhe: 20601. Ota yhteyttä`,
        error_21602:`Virheellinen vahvistus (virhe: 20602). Yritä uudelleen.`,
        error_21603:`Virhe: 20603. Yritä uudelleen`,
        error_21604:`Pyyntö epäonnistui (virhekoodi: 21604). Yritä uudelleen`,
        error_21606:`Pyyntö epäonnistui (virhekoodi: 21606). Yritä uudelleen`,
        error_21611:`Pyyntö epäonnistui (virhekoodi: 21611). Yritä uudelleen`,
        error_21801:`Virhe: 21801. Ota yhteyttä`,
        error_21802:`Pyyntö epäonnistui (Virhe: 21802). Yritä uudelleen`,
        error_21803:`Virhe: 21803. Yritä uudelleen`,
        error_21804:`Pyyntö epäonnistui (virhekoodi: 21804). Yritä uudelleen`,
        error_21806:`Virhe: 21806. Yritä uudelleen`,
        error_21807:`Virhe: 21807. Ota yhteyttä`,
        error_21808:`Virhe: 21808. Ota yhteyttä`,
        error_21809:`Virhe: 21809. Ota yhteyttä`,
        error_21810:`Virhe: 21810. Ota yhteyttä`,
        error_21811:`Virhe: 21811. Ota yhteyttä`,
        error_21812:`Virhe: 21812. Ota yhteyttä`,
        error_21813:`Pyyntö epäonnistui (virhekoodi: 21813). Yritä uudelleen`,
        error_21814:`Virhe: 21814. Ota yhteyttä`,
        error_21815:`Pyyntö epäonnistui (virhekoodi: 21815). Yritä uudelleen`,
        error_21816:`Virhe: 21816. Ota yhteyttä`,
        error_21817:`Virhe: 21817. Ota yhteyttä`,
        error_21818:`Virhe: 21818. Ota yhteyttä`,
        error_21819:`Pyyntö epäonnistui (virhekoodi: 21819). Yritä uudelleen`,
        error_21820:`Virhe: 21820. Ota yhteyttä`,
        error_21821:`Virhe: 21821. Ota yhteyttä`,
        error_21822:`Virhe: 21822. Ota yhteyttä`,
        error_21823:`Pyyntö epäonnistui (virhekoodi: 21823). Yritä uudelleen`,
        error_21824:`Pyyntö epäonnistui (virhekoodi: 21824). Yritä uudelleen`,
        error_21825:`Pyyntö epäonnistui (virhekoodi: 21825). Yritä uudelleen`,
        error_21826:`Pyyntö epäonnistui (virhekoodi: 21826). Yritä uudelleen`,
        error_21828:`Virhe: 21828. Ota yhteyttä`,
        error_21901:`Virhe: 21901. Ota yhteyttä`,
        error_21902:`Pyyntö epäonnistui (virhekoodi: 21902). Yritä uudelleen`,
        error_21903:`Tilin tila on muuttunut (virhekoodi: 21903). Päivitä sivu ja yritä uudelleen`,
        error_21904:`Virhe: 21904. Yritä uudelleen`,
        error_21905:`Virhe: 21905. Yritä uudelleen`,
        error_21906:`Pyyntö epäonnistui (virhekoodi: 21906). Yritä uudelleen`,
        error_21907:`Google -tili on linkitetty toiseen tiliin`,
        error_21908:`Pyyntö epäonnistui (virhekoodi: 21908). Yritä uudelleen`,
        error_22001:`Pyyntö epäonnistui (virhekoodi: 22001). Yritä uudelleen`,
        error_22002:`Linkityksen poistaminen epäonnistui ilman lisäkirjautumista`,
        error_22003:`Pyyntö epäonnistui (virhekoodi: 22003). Yritä uudelleen`,
        error_22101:`Virhe: 22101. Ota yhteyttä`,
        error_22102:`Tilin tila on muuttunut (virhekoodi: 22102). Päivitä sivu ja yritä uudelleen`,
        error_22103:`Pyyntö epäonnistui (virhekoodi: 22103). Yritä uudelleen`,
        error_22104:`Tilin tila on muuttunut (virhekoodi: 22104). Päivitä sivu ja yritä uudelleen`,
        error_22105:`Virhe: 22105. Yritä uudelleen`,
        error_22106:`Virhe: 22106. Yritä uudelleen`,
        error_22107:`Virhe: 22107. Ota yhteyttä`,
        error_22108:`Pyyntö epäonnistui (virhekoodi: 22108). Yritä uudelleen`,
        error_22201:`Virhe: 22201. Ota yhteyttä`,
        error_22202:`Virheellinen vahvistus (virhe: 22202). Yritä uudelleen.`,
        error_22203:`Virhe: 22203. Yritä uudelleen "`,
        error_22204:`Pyyntö epäonnistui (virhekoodi: 22204). Yritä uudelleen`,
        error_22206:`Pyyntö epäonnistui (virhekoodi: 22206). Yritä uudelleen`,
        error_22401:`Virhe: 22401. Ota yhteyttä`,
        error_22402:`Virheellinen vahvistus (virhe: 22402). Yritä uudelleen.`,
        error_22403:`Virhe: 22403. Yritä uudelleen`,
        error_22404:`Pyyntö epäonnistui (virhekoodi: 22404). Yritä uudelleen`,
        error_22405:`Facebook -tili on linkitetty toiseen sähköpostiosoitteeseen`,
        error_22406:`Virhe: 22406. Yritä uudelleen`,
        error_22407:`Virhe: 22407. Ota yhteyttä`,
        error_22408:`Virhe: 22408. Ota yhteyttä`,
        error_22409:`Virhe: 22409. Ota yhteyttä`,
        error_22410:`Virhe: 224010. Ota yhteyttä`,
        error_22411:`Virhe: 224011. Ota yhteyttä`,
        error_22412:`Virhe: 224012. Ota yhteyttä`,
        error_22413:`Virhe: 22413. Ota yhteyttä`,
        error_22414:`Pyyntö epäonnistui (virhekoodi: 22414). Yritä uudelleen`,
        error_22415:`Virhe: 22415. Ota yhteyttä`,
        error_22416:`Virhe: 22416. Ota yhteyttä`,
        error_22417:`Virhe: 22417. Ota yhteyttä`,
        error_22418:`Pyyntö epäonnistui (virhekoodi: 22418). Yritä uudelleen`,
        error_22419:`Virhe: 22419. Ota yhteyttä`,
        error_22420:`Virhe: 22420. Ota yhteyttä`,
        error_22421:`Virhe: 22421. Ota yhteyttä`,
        error_22422:`Pyyntö epäonnistui (virhekoodi: 22422). Yritä uudelleen`,
        error_22423:`Pyyntö epäonnistui (virhekoodi: 22423). Yritä uudelleen`,
        error_22424:`Pyyntö epäonnistui (virhekoodi: 22424). Yritä uudelleen`,
        error_22425:`Pyyntö epäonnistui (virhekoodi: 22425). Yritä uudelleen`,
        error_20098:`Virhekoodi: 20098. Jos selaat yksityisessä tilassa, vaihda normaalitilaan ja yritä uudelleen.`,
        error_22298:`Google -pyyntö epäonnistui (virhe: 22298). Yritä uudelleen.`,
        error_22498:`Facebook -pyyntö epäonnistui (virhe: 22498). Yritä uudelleen.`,
        error_24902:`Pyyntö epäonnistui (virhekoodi: 24902). Yritä uudelleen`,
        error_24903:`Pyyntö epäonnistui (virhekoodi: 24903). Yritä uudelleen`,
        error_24904:`Pyyntö epäonnistui (virhekoodi: 24904). Yritä uudelleen`,
        error_24905:`Pyyntö epäonnistui (virhekoodi: 24905). Yritä uudelleen`,
        login_title:`Kirjaudu Aiseesoftiin`,
        password_placeholder:`Salasana`,
        log_in:`Kirjaudu sisään`,
        no_account:`Ei tiliä?`,
        create_it:`Luo se`,
        or_log_in_with:`Tai kirjaudu sisään`,
        passwordless_login:`Salasanaton kirjautuminen`,
        log_in_done:`Kirjaudu sisään Valmis`,
        three_rd_account_connect_info:`Onnittelut! Olet kirjautunut sisään onnistuneesti. Nyt voit yhdistää yhden sähköpostitilin, jota käytetään kirjautumiseen tulevaisuudessa.`,
        connect_now:`Yhdistä nyt`,
        see_my_account:`Katso tilini`,
        three_rd_login_merge_account:`Kolmannen osapuolen tilin sähköpostiosoite on rekisteröity, haluatko muodostaa yhteyden ja kirjautua suoraan tähän sähköpostiosoitteeseen?`,
        connect_log_in:`Yhdistä ja kirjaudu sisään`,
        create_an_account:`Luo tili`,
        connect_account:`Yhdistä tili`,
        connect_account_info:`Yhdistymisen jälkeen voit kirjautua sisään tällä tilillä.`,
        back_to_log_in:`Takaisin sisäänkirjautumiseen`,
        create_password:`Luo salasana`,
        create_now:`Luo nyt`,
        password_login_subtitle:`Salasanaton kirjautuminen sähköpostilla`,
        account_login:`Tilille kirjautuminen`,
        rights:`Luomalla tämän tilin hyväksyt <a href="https://fi.aiseesoft.com/company/terms-and-conditions.html"> käyttöehdot </a> ja <a href="https://fi.aiseesoft.com/privacy-policy.html">Tietosuojakäytäntö </a>`,
        passwordless_login_done:`Salasanaton kirjautuminen tehty`,
        passwordless_login_info:`Onnittelut, olet suorittanut salasanaton kirjautuminen onnistuneesti. Voit luoda salasanan tälle tilille ja kirjautua sisään tilillä ja salasanalla tulevaisuudessa. <a href="/create-password" style="display: initial;"> Luo nyt </a>`,
        sign_up:`Kirjaudu`,
        register_info:`luo tilisi`,
        reset_now:`Nollaa nyt`,
        forgot_password:`Unohtuiko salasana`,
        reset_password_subtitle:`Vaihda salasana tilisi sähköpostilla`,
        plan_products:`Suunnitelma ja tuotteet`,
        nick_name:`Käyttäjätunnus`,
        email:`Sähköposti`,
        my_products:`Omat tuotteet`,
        my_orders:`Tilaukseni`,
        unlink:`Poista linkitys`,
        link:`Linkki`,
        connected_accounts:`Yhdistetyt tilit`,
        last_name:`Sukunimi`,
        first_name:`Etunimi`,
        Gender:`Sukupuoli`,
        Birth:`Syntymä`,
        Month:`Kuukausi`,
        Year:`Vuosi`,
        Country_Region:`Maa/alue`,
        Address:`Osoite`,
        Save:`Tallentaa`,
        Date:`Päivämäärä`,
        Male:`Uros`,
        Female:`Nainen`,
        Unspecified:`Määrittelemätön`,
        Security:`Turvallisuus`,
        change_password:`Vaihda salasana`,
        change_now:`Vaihda nyt`,
        connect_email:`Yhdistä sähköposti`,
        delete_account:`Poista tili`,
        delete_account_info:`Kun tilisi poistetaan, kaikki tiliisi liittyvät Plan & Products -tiedot poistetaan pysyvästi, etkä ehkä pysty palauttamaan niitä. Suosittelemme polkemista varovasti.`,
        Delete:`Poistaa`,
        Logout:`Kirjautua ulos`,
        my_profile:`Profiilini`,
        guides_faqs:`Oppaat ja usein kysytyt kysymykset`,
        More:`Lisää`,
        guides:`Oppaat`,
        register:`rekisteröidy`,
        hot_faq:`Kuumat UKK`,
        Contents:`Sisällys:`,
        contact_us:`Ota yhteyttä >>`,
        plan:`Suunnitelma`,
        unregistered:`Rekisteröimätön`,
        buy_more:`Osta lisää`,
        buy_again:`Osta uudelleen`,
        buy_now:`Osta nyt`,
        free_no_limit:`Ilmainen & Ei rajoituksia`,
        expired:`Vanhentunut`,
        lifetime:`Elinikä`,
        remain:`Jäädä jäljelle`,
        day_s:`päivä(t)`,
        error_24801:`Pyyntö epäonnistui (virhekoodi: 24801). Yritä uudelleen`,
        no_app_found:`Sovellusta ei löydy!<a href="/">Päivitä</a> tai <a href="https://fi.aiseesoft.com/">siirry viralliselle verkkosivustolle</a>`,
        get_more:`Hanki lisää >>`,
        edit_photo:`Muokkaa kuvaa`,
        select_photo:`Valitse valokuva`,
        change_photo:`Vaihda kuva`,
        cancel:`Peruuttaa`,
        hide_password:`Piilota salasana`,
        show_password:`Näytä salasana`,
        zoom_in:`Lähennä`,
        zoom_out:`Loitontaa`,
        rotate:`Kiertää`,
        horizontal_flip:`Vaakasuora läppä`,
        vertical_flip:`Pystysuora kääntö`,
        country:`Maa`,
        country_1:`Valitse maasi/alueesi`,
        country_2:`Ahvenanmaa`,
        country_3:`Afganistan`,
        country_4:`Albania`,
        country_5:`Algeria`,
        country_6:`Amerikan Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktis`,
        country_11:`Antigua & Barbuda`,
        country_12:`Argentiina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Itävalta`,
        country_17:`Azerbaidžan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Valko-Venäjä`,
        country_22:`Belgia`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia ja Hertsegovina`,
        country_29:`Botswana`,
        country_30:`Bouvetin saari`,
        country_31:`Brasilia`,
        country_32:`Brittiläinen Intian valtameren alue`,
        country_33:`Britannian Neitsytsaaret`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodža`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Kap Verde`,
        country_42:`Karibian Alankomaat`,
        country_43:`Caymansaaret`,
        country_44:`Keski-Afrikan tasavalta`,
        country_45:`Tšad`,
        country_46:`Chile`,
        country_47:`Kiina`,
        country_48:`Joulusaari`,
        country_49:`Kookossaaret (Keeling).`,
        country_50:`Kolumbia`,
        country_51:`Cook saaret`,
        country_52:`Costa Rica`,
        country_53:`"Norsunluurannikko"`,
        country_54:`Kroatia`,
        country_55:`Kuuba`,
        country_56:`Kypros`,
        country_57:`Tšekin tasavalta`,
        country_58:`Kongon demokraattinen tasavalta`,
        country_59:`Tanska`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominikaaninen tasavalta`,
        country_63:`Ecuador`,
        country_64:`Egypti`,
        country_65:`El Salvador`,
        country_66:`Päiväntasaajan Guinea`,
        country_67:`Eritrea`,
        country_68:`Viro`,
        country_69:`Etiopia`,
        country_70:`Falkland saaret`,
        country_71:`Färsaaret`,
        country_72:`Mikronesian liittovaltiot`,
        country_73:`Fidži`,
        country_74:`Suomi`,
        country_75:`Ranska`,
        country_76:`Ranskan Guayana`,
        country_77:`Ranskan Polynesia`,
        country_78:`Ranskan eteläiset alueet`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Saksa`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Iso-Britannia (Yhdistynyt kuningaskunta; Englanti)`,
        country_86:`Kreikka`,
        country_87:`Grönlanti`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernseyssä`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island ja McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Unkari`,
        country_101:`Islanti`,
        country_102:`Intia`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irlanti`,
        country_107:`Mansaari`,
        country_108:`Israel`,
        country_109:`Italia`,
        country_110:`Jamaika`,
        country_111:`Japani`,
        country_112:`Jersey`,
        country_113:`Jordania`,
        country_114:`Kazakstan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirgisia`,
        country_119:`Laos`,
        country_120:`Latvia`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libya`,
        country_125:`Liechtenstein`,
        country_126:`Liettua`,
        country_127:`Luxemburg`,
        country_128:`Macao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malesia`,
        country_132:`Malediivit`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshallsaaret`,
        country_136:`Martinique`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Meksiko`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marokko`,
        country_147:`Mosambik`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Alankomaat`,
        country_153:`Uusi-Caledonia`,
        country_154:`Uusi Seelanti`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolkin saari`,
        country_160:`Pohjois-Korea`,
        country_161:`Pohjois-Mariaanit`,
        country_162:`Norja`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestiinan alueet`,
        country_167:`Panama`,
        country_168:`Papua-Uusi-Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairnin saaret`,
        country_172:`Puola`,
        country_173:`Portugali`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Makedonian tasavalta (FYROM)`,
        country_177:`Kongon tasavalta`,
        country_178:`Réunion`,
        country_179:`Romania`,
        country_180:`Venäjän federaatio`,
        country_181:`Ruanda`,
        country_182:`Saint Barthélemy`,
        country_183:`Saint Martin (Ranska)`,
        country_184:`Saint-Pierre ja Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`São Tome & Principe`,
        country_188:`Saudi-Arabia`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychellit`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakia`,
        country_195:`Slovenia`,
        country_196:`Solomonsaaret`,
        country_197:`Somalia`,
        country_198:`Etelä-Afrikka`,
        country_199:`Etelä-Georgia ja South Sandwich Isla`,
        country_200:`Etelä-Korea`,
        country_201:`eteläsudan`,
        country_202:`Espanja`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Dependencies`,
        country_205:`St. Kitts & Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent ja Grenadiinit`,
        country_208:`Sudan`,
        country_209:`Suriname`,
        country_210:`Swazimaa`,
        country_211:`Ruotsi`,
        country_212:`Sveitsi`,
        country_213:`Syyria`,
        country_214:`Taiwan`,
        country_215:`Tadžikistan`,
        country_216:`Tansania`,
        country_217:`Malli:Maatiedot SJM Svalbard`,
        country_218:`Thaimaa`,
        country_219:`Bahama-saaret`,
        country_220:`Komorit`,
        country_221:`Filippiinit`,
        country_222:`Itä-Timor (Itä-Timor)`,
        country_223:`Mennä`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunisia`,
        country_228:`Turkki`,
        country_229:`Turkmenistan`,
        country_230:`Turks- ja Caicossaaret`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraina`,
        country_234:`Yhdistyneet Arabiemiirikunnat`,
        country_235:`Yhdysvaltain pienet syrjäiset saaret`,
        country_236:`Amerikan Yhdysvallat (USA)`,
        country_237:`Yhdysvaltain Neitsytsaaret`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikaani (Pyhä istuin)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis ja Futuna`,
        country_245:`Länsi-Sahara`,
        country_246:`Jemen`,
        country_247:`Sambia`,
        country_248:`Zimbabwe`,
        google_login:`Kirjaudu sisään Googlella`,
        footer:`Copyright © 2024 Aiseesoft Studio. Kaikki oikeudet pidätetään.`,
        State:`Osavaltio`,
        Activation_code:`Aktivointikoodi`,
        Question:`Luettele kaikki sovellukset, jotka olet kirjautunut sisään`,
        Copy_complete:`Kopiointi valmis`,

        store:`Store`,
        resource:`Resurssi`,
        support:`tuki`,
        video_converter:`Video Converter`,
        multimedia:`Multimedia`,
        data_recovery:`Tietojen palautus`,
        utility:`Apuohjelma`,
        free_tools:`Ilmaiset työkalut`,
        for_windows:`Windowsille`,
        for_mac:`Macille`,
        pdf_solutions:`PDF-ratkaisut`,
        data_manager:`Data Manager`,
        other_tools:`Muut työkalut`,
        free_online_solutions:`Ilmaiset online-ratkaisut`,
        free_online_tools:`Ilmaiset online-työkalut`,
        desktop_freeware:`Ilmainen työpöytäohjelma`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS muunnin`,
        product_4:`4K Muunnin`,
        product_5:`MXF muunnin`,
        product_6:`3D-muunnin`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter for Mac`,
        product_9:`MTS Converter for Mac`,
        product_10:`4K-muunnin Macille`,
        product_11:`MXF Converter for Mac`,
        product_12:`3D Converter for Mac`,
        product_13:`Blu-ray-soitin`,
        product_14:`Videon tehostin`,
        product_15:`Videoeditori`,
        product_16:`Diaesityksen luoja`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray-soitin`,
        product_21:`Mac Video Enhancer`,
        product_22:`Videoeditori Macille`,
        product_23:`DVD Creator for Mac`,
        product_24:`MP4-muunnin Macille`,
        product_25:`Windowsin tietojen palautus`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab iOS -järjestelmän palautus`,
        product_28:`FoneLab iOS Data Backup &amp; Palauttaa`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Rikkinäinen Android-tietojen purku`,
        product_31:`Android Data Backup &amp; Palauttaa`,
        product_32:`Macin tietojen palautus`,
        product_33:`Mac FoneLab iPhone Data Recovery`,
        product_34:`Mac FoneLab iOS -järjestelmän palautus`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Palauttaa`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; Palauttaa`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate for Mac`,
        product_40:`PDF-fuusio`,
        product_41:`Macin PDF-ePub-muunnin`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp-siirto iOS:lle`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhonen puhdistusaine`,
        product_48:`PPT-videomuunnin`,
        product_49:`HEIC muunnin`,
        product_50:`iPhone Unlocker`,
        product_51:`Ilmainen Video Converter verkossa`,
        product_52:`Ilmainen Audio Converter verkossa`,
        product_53:`Ilmainen videokompressori verkossa`,
        product_54:`Ilmainen videofuusio verkossa`,
        product_55:`Ilmainen HEIC-muunnin`,
        product_56:`Ilmainen kuvan kompressori`,
        product_57:`Ilmainen vesileimanpoistoaine`,
        product_58:`Ilmainen PDF-kompressori verkossa`,
        product_59:`Ilmainen Image Upscaler verkossa`,
        product_60:`Ilmainen taustanpoistoaine verkossa`,
        product_61:`Ilmainen kuvanmuunnin verkossa`,
        product_62:`Ilmainen PDF PNG Converter verkossa`,
        product_63:`Ilmainen Video Converter`,
        product_64:`Ilmainen Video GIF-muunnin`,
        product_65:`Ilmainen Android-tietojen palautus`,
        product_66:`Slideshow Maker`,
        product_67:`Ilmainen videoeditori`,
        product_68:`Ilmainen PDF-katseluohjelma`,
        product_69:`Näytön tallennin`,
        product_70:`Mac-näytön tallennin`,
        product_71:`Ilmainen näytön tallennin verkossa`,
        product_72:`Ilmainen Äänitallennin verkossa`,
        product_73:`Puhelimen peili`,
        product_74:`AnyCoord - Location Changer`,
        product_75:`iPhonen salasananhallinta`,
        product_76:`Ilmainen äänikompressori verkossa`,
        product_77:`Ilmainen videoleikkuri verkossa`,
        product_78:`Ilmainen Video Cropper verkossa`,
        product_79:`Ilmainen Video Rotator verkossa`,
        product_80:`Ilmainen Video Looper verkossa`,
        product_81:`Ilmainen PDF JPG Converter verkossa`,
        product_81:`Ilmainen JPG Word Converter verkossa`,
        category_1:`Kuvan muokkaus`,
        category_2:`Videon editoiminen`,
        category_3:`Pyyhekumi`,
        category_4:`Media Recorder`,
        posted_by:`lähettänyt`,
        to:`to`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Kuinka poistaa tekstiä kuvista tehokkaasti`,
        article_2:`Kuinka suurentaa kuvan kokoa kilotavuina megatavuiksi laadun heikkenemättä`,
        article_3:`Getty Images -vesileiman poistaminen Windowsista ja Macista`,
        article_4:`Paras tapa muuntaa matalaresoluutioinen kuva korkearesoluutioiseksi verkossa ilmaiseksi`,
        article_5:`TikTok-opas | Näin teet videokollaasin TikTokissa`,
        article_6:`5 helppoa tapaa yhdistää VOB-tiedostoja, jotka sinun pitäisi tietää`,
        article_7:`Kuinka skaalata videota ja saada korkeampi resoluutio helposti`,
        article_8:`MP4-tiedostojen yhdistäminen MacBook Prossa/iMacissa/MacBookissa ja muissa`,
        article_9:`2 yksinkertaista tapaa poistaa ostohistoria iPhonesta [ratkaistu]`,
        article_10:`Kuinka poistaa Snapchat-kuvia pysyvästi chatista ja muistoista`,
        article_11:`Spotlight-hakuehdotusten ja -historian poistaminen iPhonesta/iPadista`,
        article_12:`[Ratkaistu] Kuinka tyhjentää Instagram-välimuisti iPhonessa kolmella helpolla tavalla`,
        article_13:`[Ratkaistu] Kuinka korjata QuickTime Player, joka ei toimi Macissa`,
        article_14:`Järjestelmän äänen tallentaminen Macissa (Big Sur/Catalina/Mojave…)`,
        article_15:`Tehokkaita tapoja korjata PowerPoint-näytön tallennus ei toimi`,
        article_16:`Äänen tallentaminen VLC:llä [ratkaistu]`,
        faq_title_1:`1. Kuinka korjata 4104-virhe?`,
        faq_title_2:`2. Milloin saan rahani takaisin sen jälkeen, kun hyvityspyyntö on hyväksytty?`,
        faq_title_3:`3. Mikä on "1 vuoden tilaus" ja kuinka "Automaattinen uusimispalvelu" poistetaan käytöstä?`,
        faq_title_4:`4. Voinko peruuttaa tilaukseni Aiseesoft-tuotteen lähettämisen jälkeen?`,
        faq_title_5:`5. Kuinka lähettää sinulle arvostelu?`,
        faq_info_1:`Tämä johtuu saamastasi rajoitetusta lisenssistä. Otamme käyttöön kertalisenssin rekisteröintikoodin varastamisen varalta ja estämme rekisteröintikoodin rikkomisen. Joten saamasi Reg Code on Single License, jota voidaan käyttää vain yhdessä tietokoneessa. Jos käytät sitä useammassa kuin yhdessä tietokoneessa, tulee virhe 4104. Et voi käyttää sitä ennen kuin nollaamme sen puolestasi.`,
        faq_info_2:`Jos muutat tietokonettasi tai asennat sen uudelleen, voimme nollata sen puolestasi. Ota yhteyttä asiakaspalveluun: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> pyytääksesi rekisteröintikoodin nollaamista puolestasi.`,
        faq_info_3:`Kun hyvityspyyntösi on hyväksytty, kestää tavallisesti 3–7 arkipäivää, ennen kuin hyvitys näkyy tililläsi. Joskus lomien aikana se voi kestää kauemmin, mutta sen ei odoteta kestävän yli 10 päivää. Jos et ole saanut hyvitystä 20 päivän kuluessa hyväksymisestä, ota meihin yhteyttä osoitteessa <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> tarkistaaksesi hyvityksen.`,
        faq_info_4:`Automaattinen uusimispalvelu tarkoittaa, että jos ostat 1 vuoden tilauksen, maksualusta veloittaa summan automaattisesti (mukaan lukien sovellettavat verot), kun tuotteen käyttöoikeus vanhenee. Mutta maksun vakiolomake (Swreg tai 2Checkout) lähettää sinulle kirjeen, joka muistuttaa, että tilaus vanhenee 7 päivää ennen voimassaolon päättymistä. Sähköposti sisältää myös ohjeet "Automaattisen uusimispalvelun" kytkemisestä pois päältä.`,
        faq_info_5:`Koska tilaus käsitellään automaattisesti, tilausta ei voi peruuttaa. Lisäksi ilmainen kokeiluversio antaa sinun tietää enemmän Aiseesoft-ohjelmasta ja tarkistaa, vastaako se tarpeitasi ennen sen ostamista.`,
        faq_info_6:`Jos todella haluat saada hyvityksen, tutustu <a href="https://www.aiseesoft.com/fi/refund-policy.html">hyvityskäytäntöömme</a>.`,
        faq_info_7:`Arvostamme aikaasi ja huomiotasi lähettääksesi meille arvostelun. Ota meihin yhteyttä (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) lähettääksesi arvostelusi, niin harkitsemme vakavasti tuotepäivitystämme.`,
        change_password_success:`salasana vaihdettu onnistuneesti`,
        successful_login_title:`Kirjautuminen onnistui`,
        product_page:`Tuotesivu >>`,
        successful_login_info:`Kirjautuminen suoritettu. Sulje nykyinen sivu ja palaa alkuperäiseen välilehteen jatkaaksesi seuraavaa prosessia. Nykyinen sivu sulkeutuu automaattisesti 5 sekunnin kuluttua. Jos automaattinen sulkeminen tai sulkeminen napsauttamalla "Valmis"-painiketta epäonnistuu, sulje tämä välilehti suoraan.`,
        successful_login_info_firefox:`Kirjautuminen suoritettu. Sulje nykyinen sivu ja palaa alkuperäiseen välilehteen jatkaaksesi seuraavaa prosessia.`,
        my_account:`Tilini`,
        my_history:`Minun historiani`,
        remove_watermark:`Poista vesileima`,
        no_history:`Ei historiaa`,
        history_all:`Valitse kaikki`,
        history_open:`Avata`,
        history_down:`ladata`,
        history_delete:`Poistaa`,
        history_clear:`Tyhjennä virheellinen`,
        images:`kuva(t)`,
        use_this_function:`Käytä tätä toimintoa >>`,
        hd_downloading:`Alkuperäisten HD-kuvien lataamisen edut:`,
        lifetimeRemaining:`Elinikäinen jäljellä`,
        Remaining:`Jäljelle jäänyt`,
        email_verification:`sähköpostivarmistus`,
        email_verification_info:`Olemme lähettäneet vahvistusviestin sähköpostiisi <span class="email"></span> ja viimeistele vahvistus. Vahvistuksen jälkeen edut synkronoidaan automaattisesti.`,
        wrong_email:`Väärä sähköpostiosoite?`,
        click_here_to_modify:`Napsauta tästä muokataksesi`,
        get_more_help:`Saatko lisää apua?`,
        click_here:`Klikkaa tästä`,
        email_verification_info_success:`Onnittelut sähköpostitilisi vahvistamisesta.`,
        email_verification_info_error:`Vahvistus epäonnistui vanhentuneen linkin vuoksi.`,
        registration_succeeded:`Ilmoittautuminen Onnistui`,
        registration_succeeded_info_1:`Onnittelut! Olet rekisteröitynyt onnistuneesti. Olemme lähettäneet vahvistusviestin sähköpostiisi <span class="email"></span> ja viimeistele vahvistus, jotta voit hyödyntää tämän sähköpostin edut.`,registration_succeeded_info_2:`Napsauta "Valmis" palataksesi etusivulle ja käytät tätä tuotetta.`,
        registration_succeeded_info_3:`Napsauta "Valmis" sulkeaksesi nykyisen sivun ja palataksesi aloitussivulle seuraavien toimintojen suorittamiseksi. Jos sulkeminen epäonnistuu, sulje välilehti manuaalisesti.`,
        verify_email:`vahvista sähköposti`,
        registered_email_not_verified:`Rekisteröityä sähköpostiosoitetta ei ole vahvistettu, tarkista se heti.`,
        email_verification_time_1:`Etkö saanut vahvistussähköpostia?`,
        email_verification_time_2:`Jälkeen`,
        email_verification_time_3:`Napsauta tätä lähettääksesi sen uudelleen`,
        error_26301:`Virhekoodi: 26301, ota yhteyttä`,
        error_26302:`Virhekoodi: 26302, ota yhteyttä`,
        error_26303:`Sähköpostin muotovirhe (virhekoodi: 26303). Anna se uudelleen`,
        error_26304:`Yli 8 merkkiä pitkä salasana on suositeltavaa (virhekoodi: 26304)`,
        error_26305:`Reuquest epäonnistui (virhekoodi: 26305). Yritä uudelleen`,
        error_26306:`Sähköposti on rekisteröity, <a href="https://account.aisesoft.com/login">kirjaudu sisään</a>`,
        error_26307:`Reuquest epäonnistui (virhekoodi: 26307). Yritä uudelleen`,
        error_26308:`Reuquest epäonnistui (virhekoodi: 26308). Yritä uudelleen`,
        error_26401:`Virhekoodi: 26401, yritä uudelleen`,
        error_26402:`Sähköposti on vahvistettu (virhekoodi: 26402), yritä uudelleen`,
        error_26403:`Reuquest epäonnistui (virhekoodi: 26403). Yritä uudelleen`,
        error_26404:`Pyyntö epäonnistui (virhekoodi: 26404). Yritä uudelleen`,
        error_26501:`Virhekoodi: 26501, ota yhteyttä`,
        error_26502:`Virhekoodi: 26502, ota yhteyttä`,
        error_26503:`Sähköpostin muotovirhe (virhekoodi: 26503). Anna se uudelleen`,
        error_26504:`Reuquest epäonnistui (virhekoodi: 26504). Yritä uudelleen`,
        error_26505:`Sähköpostiosoitetta ei ole rekisteröity, <a href="https://account.aisesoft.com/register">rekisteröi se ensin</a>`,
        error_26506:`Sähköposti on vahvistettu.`,
        error_26507:`Reuquest epäonnistui (virhekoodi: 26507). Yritä uudelleen`,
        error_26508:`Vahvistus lähetetty (virhekoodi: 26508), yritä uudelleen`,
        error_26509:`Pyyntö epäonnistui (virhekoodi: 26509), yritä uudelleen`,
        error_26510:`Virhekoodi: 26510, ota yhteyttä`,
        error_26601:`Virhekoodi: 26601, ota yhteyttä`,
        error_26602:`Virhekoodi: 26602, ota yhteyttä`,
        error_26603:`Reuquest epäonnistui (virhekoodi: 26603). Yritä uudelleen`,
        error_26604:`Virhekoodi: 26604, ota yhteyttä`,
        error_26605:`Virhekoodi: 26605, ota yhteyttä`,
        error_26701:`Virhekoodi: 26701, ota yhteyttä`,
        error_26702:`Reuquest epäonnistui (virhekoodi: 26702). Yritä uudelleen`,
        error_26703:`Virhekoodi: 26703, ota yhteyttä`,
        error_26704:`Virhekoodi: 26704, ota yhteyttä`,
        error_26705:`Odota kirjautumista (virhekoodi: 26705). Yritä uudelleen`,
        no_cookie:`Olet ottanut käyttöön Estä kaikki evästeet -toiminnon selaimessasi, joten et voi kirjautua sisään. Mene Asetuksiin ja valitse Salli kaikki evästeet -valintaruutu.`,
        error_26801:`Virhekoodi: 26801, ota yhteyttä`,
        error_26802:`Virhekoodi: 26802, ota yhteyttä`,
        error_26803:`Pyyntö epäonnistui (virhekoodi: 26803). Yritä uudelleen`,
        error_26804:`Pyyntö epäonnistui (virhekoodi: 26804). Yritä uudelleen`,
        error_order:`Pyyntö epäonnistui (virhekoodi: 27098), yritä uudelleen!`,
        error_order1:`Tilauskysely on epätäydellinen (virhekoodi:`,
        error_order2:`）, päivitä ja yritä uudelleen.`,
        modify_email_title:`Muokkaa sähköpostia`,
        modify_email_info:`Voit kirjautua tilillesi muokatun sähköpostiosoitteen avulla.`,
        images_per:`Kuvat per`,
        error_26101:`Virhe: 26101. Ota yhteyttä`,
        error_26102:`Virhe: 26102. Ota yhteyttä`,
        error_26103:`Pyyntö epäonnistui (virhekoodi:26103). Yritä uudelleen`,
        error_26104:`Virhekoodi: 26104, yritä uudelleen`,
        error_26105:`Virhekoodi: 26105, yritä uudelleen`,
        error_26106:`Poistaminen epäonnistui (virhekoodi: 26106). Yritä uudelleen`,
        error_26201:`Virhe: 26201. Ota yhteyttä`,
        error_26202:`Pyyntö epäonnistui (virhekoodi:26202). Yritä uudelleen`,
        error_26001:`Virhe: 26001. Ota yhteyttä`,
        error_26002:`Virhe: 26002. Ota yhteyttä`,
        error_26003:`Virhe: 26003. Ota yhteyttä`,
        error_26004:`Virhe: 26004. Ota yhteyttä`,
        error_26005:`Pyyntö epäonnistui (virhekoodi:26005). Yritä uudelleen`,
        error_26006:`Virhekoodi: 26006, yritä uudelleen`,
        error_26008:`Virhe: 26008. Ota yhteyttä`,
        go_to_the_home_page:`Siirry etusivulle`,
        error_27101:`Pyyntö epäonnistui (virhekoodi: 27101). Yritä uudelleen ist`,
        error_27201:`Virhekoodi: 27201, ota yhteyttä`,
        error_27202:`Virhekoodi: 27202, yritä uudelleen`,
        error_27203:`Pyyntö epäonnistui (virhekoodi: 27203). Yritä uudelleen`,
        error_27204:`Virheellinen koodi (virhekoodi: 27204).`,
        error_27205:`Pyyntö epäonnistui (virhekoodi: 27205). Yritä uudelleen`,
        error_27206:`Pyyntö epäonnistui (virhekoodi: 27206). Yritä uudelleen`,
        error_27207:`Pyyntö epäonnistui (virhekoodi: 27207). Yritä uudelleen`,
        no_history_found:`Et ole käyttänyt mitään työkalua! <a href="/">Päivitä</a> tai <a href="https://fi.aiseesoft.com/">siirry viralliselle verkkosivustolle</a>`,
        error_25301:`Virhe: 25301. Ota yhteyttä`,
        error_25302:`Virhe: 25302. Ota yhteyttä`,
        error_25303:`Pyyntö epäonnistui (virhekoodi: 25303). Yritä uudelleen`,
        error_25304:`Pyyntö epäonnistui (virhekoodi: 25304). Yritä uudelleen`,
        error_25305:`Pyyntö epäonnistui (virhekoodi: 25305). Yritä uudelleen`,
        error_25306:`Pyyntö epäonnistui (virhekoodi: 25306). Yritä uudelleen`,
        image_upscaler_p:`Kuva ilman vesileiman latausetuja:`,
        Available_for:`Saatavilla:`,
        credit_per:`%s saldoa HD-kuvaa kohden`,
        still_valid:`Ostetut suunnitelmat ovat edelleen voimassa`,
        credit:`luotto(t)`,
        pc_3rd_info:`Login successfully. Please go to the application for further operation.`,
        use_online:`Käytä verkkopalvelua`,
        use_download:`Käytä työpöytäohjelmaa`,
        use_immediately:`Käytä Välittömästi`,
        Use_in_browser:`Käytä selaimessa`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    fr:{
        overtime_1:`Code d'erreur:`,
        overtime_2:`, veuillez réessayer`,
        isnetwork:`Erreur Internet. Veuillez vérifier et réessayer`,
        email_placeholder:`E-mail`,
        email_empty:`Veuillez saisir l'e-mail`,
        email_not_valid:`L'email n'est pas valide`,
        email_not_valid_1:`Veuillez saisir votre email`,
        email_not_valid_2:`L'e-mail n'est pas valide, veuillez utiliser une autre adresse.`,
        email_not_valid_3:`Pas de saisie d'e-mail`,
        password_placeholder:`Mot de passe`,
        password_empty:`Veuillez saisir le mot de passe`,
        password_not_valid:`Compte ou mot de passe incorrect`,
        password_not_valid_1:`Un mot de passe supérieur à 8 caractères est requis`,
        password_not_valid_2:`Veuillez créer un mot de passe`,
        password_placeholder_1:`Créez votre mot de passe`,
        password_placeholder_2:`Confirmez votre mot de passe`,
        password_placeholder_3:`Créer un nouveau mot de passe`,
        password_placeholder_4:`Confirmer le nouveau mot de passe`,
        password_placeholder_5:`Saisir l'ancien mot de passe`,
        copy_password_empty:`Veuillez confirmer le mot de passe`,
        copy_password_not_valid:`Veuillez confirmer votre mot de passe`,
        copy_passwords_inconsistent:`Votre confirmation de mot de passe ne correspond pas`,
        code_empty:`Veuillez saisir le code de vérification`,
        code_not_valid:`Code de vérification invalide`,
        code_placeholder:`Code de Vérification`,
        not_received_code:`Si votre boîte aux lettres n'a pas reçu le code de vérification depuis longtemps, veuillez obtenir à nouveau le code de vérification.`,
        get_first_code:`Veuillez d'abord obtenir le code de vérification.`,
        connect_your_email_placeholder:`Connectez votre E-mail`,
        last_name_placeholder:`Veuillez entrer votre nom de famille`,
        first_name_placeholder:`Veuillez entrer votre prénom`,
        address_placeholder:`Veuillez entrer votre adresse`,
        no_code_text:`Nous avons envoyé un code de vérification. Veuillez saisir votre code. <span class='tips'>Vous n'avez pas reçu de code ?`,
        no_code_text_1:`1.Veuillez vous assurer que l'adresse e-mail est valide et qu'elle peut recevoir des e-mails.`,
        no_code_text_2:`2.Pour que l'e-mail soit envoyé automatiquement par le système, il peut être signalé comme spam ou courrier indésirable. Veuillez vérifier si l'e-mail se trouve dans le dossier indésirable.`,
        no_code_text_3:`3.Impossible résoudre votre problème ?`,
        no_code_text_3_span:`Alors cliquez ici pour nous contacter.`,
        order_no:`Vous n'avez acheté aucun produit. Pour toute question, veuillez <a href="https://www.aiseesoft.fr/store/">Acheter maintenant</a> Ou <a href="https://www.aiseesoft.fr/contact.html"> Contactez-nous</a>`,
        error_24901:`Le compte actuel n'a pas d'adresse e-mail liée et ne trouve pas les commandes. Veuillez ajouter un e-mail.`,
        user_guide:`Guide de l'utilisateur>>`,
        download:`Télécharger`,
        order_number:`Numéro de commande`,
        Refund:`Remboursement`,
        Disabled:`Désactivée`,
        Normal:`Normal`,
        Modify:`Modifier`,
        Modify_1:`Modifier>>`,
        Connect:`Relier`,
        unlink_success:`Dissocier avec succès`,
        connect_success:`Connectez-vous avec succès`,
        feedback_title:`Merci pour vos commentaires. Veuillez laisser votre problème et nous vous répondrons dans les 24 heures.`,
        feedback_thank_you:`Merci !<br />Vos commentaires ont été soumis avec succès.`,
        feedback_email:`Entrez votre e-mail ici !`,
        feedback_content:`Laissez ici des problèmes ou suggestions que vous avez rencontrés.`,
        feedback_submit:`Soumettre`,
        form_contents:`Vous n'avez entré aucune description. Veuillez le saisir et soumettre à nouveau.`,
        old_password:`Veuillez saisir l'ancien mot de passe`,
        new_password:`Veuillez créer un nouveau mot de passe`,
        old_new_password:`Le nouveau mot de passe ne peut pas être le même que l'ancien`,
        incorrect_password:`Mot de passe incorrect`,
        delete_no:`Effacer maintenant`,
        Caps:`Touche Maj activée`,
        Get:`Obtenir`,
        Done:`Terminé`,
        error_20001:`Erreur : 20001. Veuillez vous reconnecter.`,
        error_20002:`Erreur : 20002. Veuillez vous reconnecter.`,
        error_20003:`Erreur : 20003. Veuillez vous reconnecter.`,
        error_20004:`Échec de la demande (code d'erreur : 20004). Veuillez réessayer.`,
        error_20005:`La session de connexion a expiré (Erreur : 20005). Veuillez vous reconnecter.`,
        error_20006:`Échec de la demande (code d'erreur : 20006). Veuillez réessayer.`,
        error_20007:`La session de connexion a expiré (Erreur : 20007). Veuillez vous reconnecter.`,
        error_20008:`La session de connexion a expiré (Erreur : 20008). Veuillez vous reconnecter.`,
        error_20009:`La session de connexion a expiré (Erreur : 20009). Veuillez vous reconnecter.`,
        error_20101:`Veuillez saisir votre email (code d'erreur : 20101)`,
        error_20102:`L'e-mail n'est pas valide (code d'erreur : 20102)`,
        error_20103:`Échec de la demande (code d'erreur : 20103). Veuillez réessayer`,
        error_20104:`L'e-mail est déjà utilisé, veuillez <a href="https://account.aiseesoft.com/login">vous connecter</a> ou vous inscrire avec un nouveau`,
        error_20105:`Échec de la demande (code d'erreur : 20105). Veuillez réessayer`,
        error_20106:`Échec de l'envoi de l'e-mail, veuillez réessayer`,
        error_20201:`Veuillez saisir votre e-mail (code d'erreur : 20201)`,
        error_20202:`Veuillez saisir votre mot de passe (code d'erreur : 20202)`,
        error_20203:`Veuillez saisir le code de vérification (code d'erreur : 20203)`,
        error_20204:`L'e-mail n'est pas valide (code d'erreur : 20204)`,
        error_20205:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20205)`,
        error_20206:`Échec de la demande (code d'erreur : 20206). Veuillez réessayer`,
        error_20207:`Code de vérification invalide`,
        error_20208:`Échec de la demande (code d'erreur : 20208). Veuillez réessayer`,
        error_20209:`Échec de la demande (code d'erreur : 20209). Veuillez réessayer`,
        error_20301:`Veuillez saisir votre e-mail (code d'erreur : 20301)`,
        error_20302:`Erreur : 20302. Veuillez nous contacter`,
        error_20303:`L'e-mail n'est pas valide (code d'erreur : 20303)`,
        error_20304:`Échec de la demande (code d'erreur : 20304). Veuillez réessayer`,
        error_20305:`Le compte n'existe pas. Veuillez saisir à nouveau ou <a href="https://account.aiseesoft.com/register">Créer un nouveau</a> d'abord.`,
        error_20306:`Pas encore de mot de passe. Utilisez la <a href="https://account.aiseesoft.com/passwordless-login">connexion sans mot de passe</a> ou <a href="create-password">définissez un mot de passe</a> et connectez-vous.`,
        error_20308:`Échec de la demande (code d'erreur : 20308). Veuillez réessayer`,
        error_20401:`Échec de la déconnexion (code d'erreur : 20401). Veuillez réessayer`,
        error_20501:`Veuillez saisir votre e-mail (code d'erreur : 20501)`,
        error_20502:`L'e-mail n'est pas valide (code d'erreur : 20502)`,
        error_20503:`Échec de la demande (code d'erreur : 20503). Veuillez réessayer`,
        error_20504:`Échec de l'envoi de l'e-mail. Veuillez réessayer.`,
        error_20601:`Veuillez saisir votre email (code d'erreur : 20601)`,
        error_20602:`Veuillez saisir le code de vérification (code d'erreur : 20602)`,
        error_20603:`L'e-mail n'est pas valide (code d'erreur : 20603)`,
        error_20604:`Échec de la demande (code d'erreur : 20604). Veuillez réessayer`,
        error_20606:`Échec de la demande (code d'erreur : 20606). Veuillez réessayer`,
        error_20607:`Échec de la demande (code d'erreur : 20607). Veuillez réessayer`,
        error_20608:`Échec de la demande (code d'erreur : 20608). Veuillez réessayer`,
        error_20701:`Veuillez saisir votre email (code d'erreur : 20701)`,
        error_20702:`L'e-mail n'est pas valide (code d'erreur : 20702)`,
        error_20703:`Échec de la demande (code d'erreur : 20703). Veuillez réessayer`,
        error_20704:`Le compte n'existe pas. Veuillez saisir à nouveau ou <a href="https://account.aiseesoft.com/register">Créer un nouveau</a> d'abord.`,
        error_20705:`Échec de la demande (code d'erreur : 20705). Veuillez réessayer`,
        error_20706:`Échec de l'envoi de l'e-mail. Veuillez réessayer`,
        error_20801:`Veuillez saisir votre e-mail (code d'erreur : 20801)`,
        error_20802:`Erreur : 20802. Veuillez nous contacter`,
        error_20803:`Veuillez saisir le code de vérification (code d'erreur : 20803)`,
        error_20804:`L'e-mail n'est pas valide (code d'erreur : 20804)`,
        error_20805:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20805)`,
        error_20806:`Échec de la demande (code d'erreur : 20806). Veuillez réessayer`,
        error_20808:`Échec de la demande (code d'erreur : 20808). Veuillez réessayer`,
        error_20901:`Échec de la demande (code d'erreur : 20901). Veuillez réessayer`,
        error_20902:`Échec de la demande (code d'erreur : 20902). Veuillez réessayer`,
        error_21000:`Les modifications sont enregistrées`,
        error_21001:`Aucune information n'est soumise (code d'erreur : 21001)`,
        error_21002:`Échec de la demande (code d'erreur : 21002). Veuillez réessayer`,
        error_21101:`Veuillez saisir votre email (code d'erreur : 21101)`,
        error_21102:`L'e-mail n'est pas valide (code d'erreur : 21102)`,
        error_21103:`Échec de la demande (code d'erreur : 21103), veuillez réessayer`,
        error_21104:`L'e-mail est déjà connecté, veuillez en utiliser un nouveau`,
        error_21105:`Échec de la demande (code d'erreur : 21105), veuillez réessayer`,
        error_21106:`Échec de l'envoi de l'e-mail. Veuillez réessayer`,
        error_21201:`Veuillez saisir votre email (code d'erreur : 21201)`,
        error_21202:`Veuillez saisir le code de vérification (code d'erreur : 21202)`,
        error_21203:`L'e-mail n'est pas valide (code d'erreur : 21203)`,
        error_21204:`Erreur : 21204. Veuillez nous contacter`,
        error_21205:`Erreur : 21205. Veuillez nous contacter`,
        error_21206:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21206)`,
        error_21207:`Échec de la demande (code d'erreur : 21207). Veuillez réessayer`,
        error_21209:`Échec de la demande (code d'erreur : 21209). Veuillez réessayer`,
        error_21301:`Veuillez saisir l'ancien mot de passe (code d'erreur : 21301)`,
        error_21302:`Veuillez créer un nouveau mot de passe (code d'erreur : 21302)`,
        error_21303:`Le nouveau mot de passe ne peut pas être le même que l'ancien. (Erreur : 21303)`,
        error_21304:`Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21304)`,
        error_21306:`Échec de la demande (code d'erreur : 21306). Veuillez réessayer`,
        error_21402:`Échec de la demande (code d'erreur : 21402). Veuillez réessayer`,
        error_21403:`Échec de l'envoi du code de vérification. Veuillez le renvoyer`,
        error_21500:`Le compte a été supprimé`,
        error_21501:`Veuillez saisir le code de vérification (code d'erreur : 21501)`,
        error_21502:`La session de connexion a expiré (Erreur : 21502). Veuillez vous reconnecter.`,
        error_21503:`Échec de la demande (code d'erreur : 21503). Veuillez réessayer`,
        error_21505:`Échec de la demande (code d'erreur : 21505), veuillez réessayer`,
        error_21601:`Erreur : 20601. Veuillez nous contacter`,
        error_21602:`Vérification non valide (erreur : 20602). Veuillez réessayer.`,
        error_21603:`Erreur : 20603. Veuillez réessayer`,
        error_21604:`Échec de la demande (code d'erreur : 21604). Veuillez réessayer`,
        error_21606:`Échec de la demande (code d'erreur : 21606). Veuillez réessayer`,
        error_21611:`Échec de la demande (code d'erreur : 21611). Veuillez réessayer`,
        error_21801:`Erreur : 21801. Veuillez nous contacter`,
        error_21802:`Échec de la demande (Erreur : 21802). Veuillez réessayer`,
        error_21803:`Erreur : 21803. Veuillez réessayer`,
        error_21804:`Échec de la demande (code d'erreur : 21804). Veuillez réessayer`,
        error_21806:`Erreur : 21806. Veuillez réessayer`,
        error_21807:`Erreur : 21807. Veuillez nous contacter`,
        error_21808:`Erreur : 21808. Veuillez nous contacter`,
        error_21809:`Erreur : 21809. Veuillez nous contacter`,
        error_21810:`Erreur : 21810. Veuillez nous contacter`,
        error_21811:`Erreur : 21811. Veuillez nous contacter`,
        error_21812:`Erreur : 21812. Veuillez nous contacter`,
        error_21813:`Échec de la demande (code d'erreur : 21813). Veuillez réessayer`,
        error_21814:`Erreur : 21814. Veuillez nous contacter`,
        error_21815:`Échec de la demande (code d'erreur : 21815). Veuillez réessayer`,
        error_21816:`Erreur : 21816. Veuillez nous contacter`,
        error_21817:`Erreur : 21817. Veuillez nous contacter`,
        error_21818:`Erreur : 21818. Veuillez nous contacter`,
        error_21819:`Échec de la demande (code d'erreur : 21819). Veuillez réessayer`,
        error_21820:`Erreur : 21820. Veuillez nous contacter`,
        error_21821:`Erreur : 21821. Veuillez nous contacter`,
        error_21822:`Erreur : 21822. Veuillez nous contacter`,
        error_21823:`Échec de la demande (code d'erreur : 21823). Veuillez réessayer`,
        error_21824:`Échec de la demande (code d'erreur : 21824). Veuillez réessayer`,
        error_21825:`Échec de la demande (code d'erreur : 21825). Veuillez réessayer`,
        error_21826:`Échec de la demande (code d'erreur : 21826). Veuillez réessayer`,
        error_21828:`Erreur : 21828. Veuillez nous contacter`,
        error_21901:`Erreur : 21901. Veuillez nous contacter`,
        error_21902:`Échec de la demande (code d'erreur : 21902). Veuillez réessayer`,
        error_21903:`Le statut du compte a changé (code d'erreur : 21903), veuillez actualiser la page et réessayer`,
        error_21904:`Erreur : 21904. Veuillez réessayer`,
        error_21905:`Erreur : 21905. Veuillez réessayer`,
        error_21906:`Échec de la demande (code d'erreur : 21906). Veuillez réessayer`,
        error_21907:`Le compte Google a été associé à un autre compte`,
        error_21908:`Échec de la demande (code d'erreur : 21908). Veuillez réessayer`,
        error_22001:`Échec de la demande (code d'erreur : 22001). Veuillez réessayer`,
        error_22002:`Échec de la dissociation sans connexion supplémentaire`,
        error_22003:`Échec de la demande (code d'erreur : 22003). Veuillez réessayer`,
        error_22101:`Erreur : 22101. Veuillez nous contacter`,
        error_22102:`Le statut du compte a changé (code d'erreur : 22102), veuillez actualiser la page et réessayer`,
        error_22103:`Échec de la demande (code d'erreur : 22103). Veuillez réessayer`,
        error_22104:`Le statut du compte a changé (code d'erreur : 22104), veuillez actualiser la page et réessayer`,
        error_22105:`Erreur : 22105. Veuillez réessayer`,
        error_22106:`Erreur : 22106. Veuillez réessayer`,
        error_22107:`Erreur : 22107. Veuillez nous contacter`,
        error_22108:`Échec de la demande (code d'erreur : 22 108). Veuillez réessayer`,
        error_22201:`Erreur : 22201. Veuillez nous contacter`,
        error_22202:`Vérification non valide (Erreur : 22202). Veuillez réessayer.`,
        error_22203:`Erreur : 22203. Veuillez réessayer`,
        error_22204:`Échec de la demande (code d'erreur : 22204). Veuillez réessayer`,
        error_22206:`Échec de la demande (code d'erreur : 22206). Veuillez réessayer`,
        error_22401:`Erreur : 22401. Veuillez nous contacter`,
        error_22402:`Vérification non valide (erreur : 22402). Veuillez réessayer.`,
        error_22403:`Erreur : 22403. Veuillez réessayer`,
        error_22404:`Échec de la demande (code d'erreur : 22404). Veuillez réessayer`,
        error_22405:`Le compte Facebook a été lié à d'autres e-mails`,
        error_22406:`Erreur : 22406. Veuillez réessayer`,
        error_22407:`Erreur : 22407. Veuillez nous contacter`,
        error_22408:`Erreur : 22408. Veuillez nous contacter`,
        error_22409:`Erreur : 22409. Veuillez nous contacter`,
        error_22410:`Erreur : 224010. Veuillez nous contacter`,
        error_22411:`Erreur : 224011. Veuillez nous contacter`,
        error_22412:`Erreur : 224012. Veuillez nous contacter`,
        error_22413:`Erreur : 22413. Veuillez nous contacter`,
        error_22414:`Échec de la demande (code d'erreur : 22414). Veuillez réessayer`,
        error_22415:`Erreur : 22415. Veuillez nous contacter`,
        error_22416:`Erreur : 22416. Veuillez nous contacter`,
        error_22417:`Erreur : 22417. Veuillez nous contacter`,
        error_22418:`Échec de la demande (code d'erreur : 22418). Veuillez réessayer`,
        error_22419:`Erreur : 22419. Veuillez nous contacter`,
        error_22420:`Erreur : 22420. Veuillez nous contacter`,
        error_22421:`Erreur : 22421. Veuillez nous contacter`,
        error_22422:`Échec de la demande (code d'erreur : 22422). Veuillez réessayer`,
        error_22423:`Échec de la demande (code d'erreur : 22423). Veuillez réessayer`,
        error_22424:`Échec de la demande (code d'erreur : 22424). Veuillez réessayer`,
        error_22425:`Échec de la demande (code d'erreur : 22425). Veuillez réessayer`,
        error_20098:`Code d'erreur : 20098. Si vous naviguez en mode privé, veuillez passer en mode normal et réessayer.`,
        error_22298:`La demande pour Google a échoué (Erreur : 22298). Veuillez réessayer.`,
        error_22498:`La demande de Facebook a échoué (Erreur : 22498). Veuillez réessayer.`,
        error_24902:`Échec de la demande (code d'erreur : 24902). Veuillez réessayer`,
        error_24903:`Échec de la demande (code d'erreur : 24903). Veuillez réessayer`,
        error_24904:`Échec de la demande (code d'erreur : 24904). Veuillez réessayer`,
        error_24905:`Échec de la demande (code d'erreur : 24905). Veuillez réessayer`,
        login_title:`Connexion à Aiseesoft`,
        password_placeholder:`Mot de passe`,
        log_in:`Connexion`,
        no_account:`Pas de compte?`,
        create_it:`Créer un nouveau`,
        or_log_in_with:`Ou connexion avec`,
        passwordless_login:`Connexion par E-mail`,
        log_in_done:`Connexion Terminé`,
        three_rd_account_connect_info:`Félicitations! Vous vous êtes connecté avec succès. Vous pouvez maintenant connecter un compte de E-mail qui sera utilisé pour vous connecter à l'avenir.`,
        connect_now:`Connexion maintenant`,
        see_my_account:`Voir mon compte`,
        three_rd_login_merge_account:`L'adresse e-mail du compte tiers a été enregistrée, voulez-vous vous connecter et vous connecter directement avec cette adresse e-mail ?`,
        connect_log_in:`Associer & Se Coonecter`,
        create_an_account:`Créer un compte`,
        connect_account:`Associer le compte`,
        connect_account_info:`Après l'association, vous pouvez vous connecter avec ce compte.`,
        back_to_log_in:`Retour connexion`,
        create_password:`Créer un mot de passe`,
        create_now:`Créer maintenant`,
        password_login_subtitle:`Connexion sans mot de passe avec e-mail`,
        account_login:`Connexion au compte`,
        rights:`En créant ce compte, vous acceptez les <a href="https://www.aiseesoft.fr/terms-conditions.html">Conditions d'utilisation</a> et les <a href="https://www.aiseesoft.fr/privacy-policy.html">Politique de confidentialité</a>`,
        passwordless_login_done:`Connexion sans mot de passe effectuée`,
        passwordless_login_info:`Félicitations, vous avez terminé avec succès la connexion sans mot de passe. Vous pouvez créer un mot de passe pour ce compte et vous connecter avec le compte et le mot de passe à l'avenir. <a href="/create-password" style="display: initial;">Créer maintenant</a>`,
        sign_up:`S'inscrire`,
        register_info:`Créez votre compte`,
        reset_now:`Réinitialiser maintenant`,
        forgot_password:`Mot de passe oublié`,
        reset_password_subtitle:`Utilisez l'e-mail de votre compte pour réinitialiser le mot de passe`,
        plan_products:`Plan & Produits`,
        nick_name:`Surnom`,
        email:`E-mail`,
        my_products:`Mes produits`,
        my_orders:`Mes commandes`,
        unlink:`Dissocier`,
        link:`Associer`,
        connected_accounts:`Comptes associés`,
        last_name:`Nom de famille`,
        first_name:`Prénom`,
        Gender:`Genre`,
        Birth:`Naissance`,
        Month:`Mois`,
        Year:`Année`,
        Country_Region:`Pays/Région`,
        Address:`Adresse`,
        Save:`Enregistrer`,
        Date:`Date`,
        Male:`Homme`,
        Female:`Femme`,
        Unspecified:`Non spécifié`,
        Security:`Sécurité`,
        change_password:`Changer le mot de passe`,
        change_now:`Changer maintenant`,
        connect_email:`Connecter email`,
        delete_account:`Supprimer le compte`,
        delete_account_info:`Lorsque votre compte est supprimé, toutes les données du forfait et des produits associées à votre compte seront définitivement supprimées et vous ne pourrez peut-être pas les récupérer. Nous vous recommandons de faire preuve de prudence.`,
        Delete:`Supprimer`,
        Logout:`Se déconnecter`,
        my_profile:`Mon profil`,
        guides_faqs:`Guides & FAQs`,
        More:`Plus`,
        guides:`Guides`,
        register:`S'inscrire`,
        hot_faq:`FAQ chaude`,
        Contents:`Contenu:`,
        contact_us:`Contactez-nous>>`,
        plan:`Plan`,
        unregistered:`Non enregistré`,
        buy_more:`Acheter plus`,
        buy_again:`Acheter à nouveau`,
        buy_now:`Acheter maintenant`,
        free_no_limit:`Gratuit et sans limite`,
        expired:`Expiré`,
        lifetime:`Durée de vie`,
        remain:`Rester`,
        day_s:`Jours)`,
        error_24801:`Échec de la demande (code d'erreur : 24801). Veuillez réessayer`,
        no_app_found:`Aucune application trouvée！<a href="/">Actualiser</a> ou <a href="https://www.aiseesoft.fr/">Accéder au site officiel</a>`,
        get_more:`Obtenir plus >>`,
        edit_photo:`Modifier photo`,
        select_photo:`Sélectionnez une photo`,
        change_photo:`Changer la photo`,
        cancel:`Annuler`,
        hide_password:`Masquer le mot de passe`,
        show_password:`Montrer le mot de passe`,
        zoom_in:`Agrandir`,
        zoom_out:`Dézoomer`,
        rotate:`Tourner`,
        horizontal_flip:`Retournement horizontal`,
        vertical_flip:`Retournement vertical`,
        country:`Pays`,
        country_1:`Sélectionnez votre pays/région`,
        country_2:`Île de land`,
        country_3:`Afghanistan`,
        country_4:`Albanie`,
        country_5:`Algérie`,
        country_6:`Samoa américaines`,
        country_7:`Andorre`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarctique`,
        country_11:`Antigua-et-Barbuda`,
        country_12:`Argentine`,
        country_13:`Arménie`,
        country_14:`Aruba`,
        country_15:`Australie`,
        country_16:`L'Autriche`,
        country_17:`Azerbaïdjan`,
        country_18:`Bahreïn`,
        country_19:`Bangladesh`,
        country_20:`Barbade`,
        country_21:`Biélorussie`,
        country_22:`la Belgique`,
        country_23:`Belize`,
        country_24:`Bénin`,
        country_25:`Bermudes`,
        country_26:`Bhoutan`,
        country_27:`Bolivie`,
        country_28:`Bosnie-Herzégovine`,
        country_29:`Botswana`,
        country_30:`Île Bouvet`,
        country_31:`Brésil`,
        country_32:`Territoire britannique de l'océan Indien`,
        country_33:`Îles Vierges britanniques`,
        country_34:`Brunéi`,
        country_35:`Bulgarie`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodge`,
        country_39:`Cameroun`,
        country_40:`Canada`,
        country_41:`Cap-Vert`,
        country_42:`Pays-Bas caribéens`,
        country_43:`Îles Caïmans`,
        country_44:`République centrafricaine`,
        country_45:`Tchad`,
        country_46:`Chili`,
        country_47:`Chine`,
        country_48:`L'île de noël`,
        country_49:`Îles Cocos (Keeling)`,
        country_50:`Colombie`,
        country_51:`les Îles Cook`,
        country_52:`Costa Rica`,
        country_53:`"Côte d'Ivoire"`,
        country_54:`Croatie`,
        country_55:`Cuba`,
        country_56:`Chypre`,
        country_57:`République Tchèque`,
        country_58:`République Démocratique du Congo`,
        country_59:`Danemark`,
        country_60:`Djibouti`,
        country_61:`Dominique`,
        country_62:`République dominicaine`,
        country_63:`Équateur`,
        country_64:`Egypte`,
        country_65:`Le Salvador`,
        country_66:`Guinée Équatoriale`,
        country_67:`Érythrée`,
        country_68:`Estonie`,
        country_69:`Ethiopie`,
        country_70:`les îles Falkland`,
        country_71:`Îles Féroé`,
        country_72:`États fédérés de Micronésie`,
        country_73:`Fidji`,
        country_74:`Finlande`,
        country_75:`La France`,
        country_76:`Guyane Française`,
        country_77:`Polynésie française`,
        country_78:`Terres Australes Françaises`,
        country_79:`Gabon`,
        country_80:`Gambie`,
        country_81:`Géorgie`,
        country_82:`Allemagne`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Grande-Bretagne (Royaume-Uni, Angleterre)`,
        country_86:`Grèce`,
        country_87:`Groenland`,
        country_88:`Grenade`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernesey`,
        country_93:`Guinée`,
        country_94:`Guinée-Bissau`,
        country_95:`Guyane`,
        country_96:`Haïti`,
        country_97:`Île Heard et Îles McDonald`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hongrie`,
        country_101:`Islande`,
        country_102:`Inde`,
        country_103:`Indonésie`,
        country_104:`L'Iran`,
        country_105:`Irak`,
        country_106:`Irlande`,
        country_107:`île de Man`,
        country_108:`Israël`,
        country_109:`Italie`,
        country_110:`Jamaïque`,
        country_111:`Japon`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kazakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Koweit`,
        country_118:`Kirghizistan`,
        country_119:`Laos`,
        country_120:`Lettonie`,
        country_121:`Liban`,
        country_122:`Lesotho`,
        country_123:`Libéria`,
        country_124:`Libye`,
        country_125:`Liechtenstein`,
        country_126:`Lituanie`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaisie`,
        country_132:`Maldives`,
        country_133:`Mali`,
        country_134:`Malte`,
        country_135:`Iles Marshall`,
        country_136:`Martinique`,
        country_137:`Mauritanie`,
        country_138:`Maurice`,
        country_139:`Mayotte`,
        country_140:`Mexique`,
        country_141:`Moldavie`,
        country_142:`Monaco`,
        country_143:`Mongolie`,
        country_144:`Monténégro`,
        country_145:`Montserrat`,
        country_146:`Maroc`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Birmanie)`,
        country_149:`Namibie`,
        country_150:`Nauru`,
        country_151:`Népal`,
        country_152:`Pays-Bas`,
        country_153:`Nouvelle Calédonie`,
        country_154:`Nouvelle-Zélande`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Nioué`,
        country_159:`l'ile de Norfolk`,
        country_160:`Corée du Nord`,
        country_161:`Îles Mariannes du Nord`,
        country_162:`Norvège`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palaos`,
        country_166:`Territoires palestiniens`,
        country_167:`Panama`,
        country_168:`Papouasie Nouvelle Guinée`,
        country_169:`Paraguay`,
        country_170:`Pérou`,
        country_171:`Îles Pitcairn`,
        country_172:`Pologne`,
        country_173:`le Portugal`,
        country_174:`Porto Rico`,
        country_175:`Qatar`,
        country_176:`République de Macédoine (ARYM)`,
        country_177:`République du Congo`,
        country_178:`Réunion`,
        country_179:`Roumanie`,
        country_180:`Fédération Russe`,
        country_181:`Rwanda`,
        country_182:`Saint-Barthélemy`,
        country_183:`Saint-Martin (France)`,
        country_184:`Saint-Pierre-et-Miquelon`,
        country_185:`Samoa`,
        country_186:`Saint Marin`,
        country_187:`Sao Tomé et Principe`,
        country_188:`Arabie Saoudite`,
        country_189:`Sénégal`,
        country_190:`Serbie`,
        country_191:`les Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapour`,
        country_194:`Slovaquie`,
        country_195:`Slovénie`,
        country_196:`Les îles Salomon`,
        country_197:`Somalie`,
        country_198:`Afrique du Sud`,
        country_199:`Géorgie du Sud et l'île de Sandwich du Sud`,
        country_200:`Corée du Sud`,
        country_201:`Soudan du sud`,
        country_202:`Espagne`,
        country_203:`Sri Lanka`,
        country_204:`Sainte-Hélène & Dépendances`,
        country_205:`Saint-Kitts-et-Nevis`,
        country_206:`Sainte Lucie`,
        country_207:`Saint-Vincent-et-les Grenadines`,
        country_208:`Soudan`,
        country_209:`Surinam`,
        country_210:`Swaziland`,
        country_211:`Suède`,
        country_212:`la Suisse`,
        country_213:`Syrie`,
        country_214:`Taïwan`,
        country_215:`Tadjikistan`,
        country_216:`Tanzanie`,
        country_217:`Modèle : Données du pays SJM Svalbard`,
        country_218:`Thaïlande`,
        country_219:`Les Bahamas`,
        country_220:`Les Comores`,
        country_221:`Les Philippines`,
        country_222:`Timor-Leste (Timor oriental)`,
        country_223:`Aller`,
        country_224:`Tokélaou`,
        country_225:`Tonga`,
        country_226:`Trinité-et-Tobago`,
        country_227:`Tunisie`,
        country_228:`Turquie`,
        country_229:`Turkménistan`,
        country_230:`Îles Turques et Caïques`,
        country_231:`Tuvalu`,
        country_232:`Ouganda`,
        country_233:`Ukraine`,
        country_234:`Emirats Arabes Unis`,
        country_235:`Îles mineures éloignées des États-Unis`,
        country_236:`États-Unis d'Amérique (États-Unis)`,
        country_237:`Îles Vierges des États-Unis`,
        country_238:`Uruguay`,
        country_239:`Ouzbékistan`,
        country_240:`Vanuatu`,
        country_241:`Cité du Vatican (Saint-Siège)`,
        country_242:`Venezuela`,
        country_243:`Viêt Nam`,
        country_244:`Wallis et Futuna`,
        country_245:`Sahara occidental`,
        country_246:`Yémen`,
        country_247:`Zambie`,
        country_248:`Zimbabwe`,
        google_login:`Connexion avec Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Tous les droits sont réservés.`,
        State:`État`,
        Activation_code:`Code d'activation`,
        Question:`Répertoriez toutes les applications auxquelles vous vous êtes connecté`,
        Copy_complete:`Copie terminée`,

        
        store:`Boutique`,
        resource:`Ressource`,
        support:`Support`,
        video_converter:`Convertisseur vidéo`,
        multimedia:`Multimédia`,
        data_recovery:`Récupération de données`,
        utility:`Utilitaire`,
        free_tools:`Outils gratuits`,
        for_windows:`Pour les fenêtres`,
        for_mac:`Pour Mac`,
        pdf_solutions:`Solutions PDF`,
        data_manager:`Manager de données`,
        other_tools:`D'autres Outils`,
        free_online_solutions:`Solutions en ligne gratuites`,
        free_online_tools:`Outils en ligne gratuits`,
        desktop_freeware:`Logiciel de bureau gratuit`,
        product_1:`Convertisseur vidéo ultime`,
        product_2:`Convertisseur vidéo total`,
        product_3:`Convertisseur MTS`,
        product_4:`Convertisseur 4K`,
        product_5:`Convertisseur MXF`,
        product_6:`Convertisseur 3D`,
        product_7:`Convertisseur vidéo Mac ultime`,
        product_8:`Convertisseur vidéo pour Mac`,
        product_9:`Convertisseur MTS pour Mac`,
        product_10:`Convertisseur 4K pour Mac`,
        product_11:`Convertisseur MXF pour Mac`,
        product_12:`Convertisseur 3D pour Mac`,
        product_13:`Lecteur Blu-ray`,
        product_14:`Vidéo Enhancer`,
        product_15:`Vidéo Editor`,
        product_16:`Slideshow Créateur`,
        product_17:`Créateur Blu-ray`,
        product_18:`Burnova - Créateur de DVD Pro`,
        product_19:`Créateur de DVD`,
        product_20:`Lecteur Blu-ray Mac`,
        product_21:`Mac Vidéo Enhancer`,
        product_22:`Vidéo Editor pour Mac`,
        product_23:`Créateur de DVD pour Mac`,
        product_24:`Convertisseur MP4 pour Mac`,
        product_25:`Récupération de données Windows`,
        product_26:`FoneLab Récupération de données iPhone`,
        product_27:`FoneLab Récupération du système iOS`,
        product_28:`FoneLab Sauvegarde &amp; Restauration de données iOS`,
        product_29:`FoneLab Récupération de données Android`,
        product_30:`Extraction de données Android cassée`,
        product_31:`Sauvegarde &amp; Restauration de données Android`,
        product_32:`Récupération de données Mac`,
        product_33:`Mac FoneLab Récupération de données iPhone`,
        product_34:`Mac FoneLab Récupération du système iOS`,
        product_35:`Mac FoneLab Sauvegarde &amp; Restauration de données iOS`,
        product_36:`Mac FoneLab Récupération de données Android`,
        product_37:`Mac FoneLab Sauvegarde &amp; Restauration de données Android`,
        product_38:`Convertisseur PDF Ultime`,
        product_39:`PDF Converter Ultimate pour Mac`,
        product_40:`Fusion de PDF`,
        product_41:`Convertisseur Mac PDF en ePub`,
        product_42:`Nettoyeur Mac`,
        product_43:`FoneTrans`,
        product_44:`Transfert WhatsApp pour iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`Convertisseur PPT en vidéo`,
        product_49:`Convertisseur HEIC`,
        product_50:`iPhone Unlocker`,
        product_51:`Convertisseur vidéo gratuit en ligne`,
        product_52:`Convertisseur audio gratuit en ligne`,
        product_53:`Compresseur vidéo gratuit en ligne`,
        product_54:`Fusion vidéo gratuite en ligne`,
        product_55:`Convertisseur HEIC gratuit`,
        product_56:`Compresseur d'images gratuit`,
        product_57:`Suppresseur de filigrane gratuit`,
        product_58:`Compresseur PDF gratuit en ligne`,
        product_59:`Upscaler d'images gratuit en ligne`,
        product_60:`Supprimer l'arrière-plan gratuit en ligne`,
        product_61:`Convertisseur d'images gratuit en ligne`,
        product_62:`Convertisseur PDF PNG gratuit en ligne`,
        product_63:`Convertisseur vidéo gratuit`,
        product_64:`Convertisseur gratuit de vidéo en GIF`,
        product_65:`Récupération de données Android gratuite`,
        product_66:`Créateur de diaporamas`,
        product_67:`Éditeur vidéo gratuit`,
        product_68:`Visionneuse PDF gratuite`,
        product_69:`Enregistreur d'écran`,
        product_70:`Enregistreur d'écran Mac`,
        product_71:`Enregistreur d'écran gratuit en ligne`,
        product_72:`Enregistreur audio gratuit en ligne`,
        product_73:`Téléphone Miroir`,
        product_74:`AnyCoord - Changeur d'emplacement`,
        product_75:`Gestionnaire de mots de passe iPhone`,
        product_76:`Compresseur audio gratuit en ligne`,
        product_77:`Découpeur vidéo gratuit en ligne`,
        product_78:`Recadrage vidéo gratuit en ligne`,
        product_79:`Rotateur vidéo gratuit en ligne`,
        product_80:`Boucleur vidéo gratuit en ligne`,
        product_81:`Convertisseur PDF JPG gratuit en ligne`,
        product_81:`Convertisseur de mots JPG gratuit en ligne`,
        category_1:`Retouche photo`,
        category_2:`Montage vidéo`,
        category_3:`La gomme`,
        category_4:`Enregistreur multimédia`,
        posted_by:`posté par`,
        to:`à`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Marron`,
        article_1:`Comment supprimer efficacement le texte des images`,
        article_2:`Comment augmenter la taille de l'image de Ko à Mo sans endommager la qualité`,
        article_3:`Comment supprimer le filigrane Getty Images sur Windows et Mac`,
        article_4:`La meilleure méthode pour convertir une image basse résolution en haute résolution en ligne gratuitement`,
        article_5:`Guide TikTok | Voici comment faire un collage vidéo sur TikTok`,
        article_6:`5 façons simples de combiner des fichiers VOB que vous devez connaître`,
        article_7:`Comment améliorer la vidéo et obtenir facilement une résolution plus élevée`,
        article_8:`Comment combiner des fichiers MP4 sur MacBook Pro/iMac/MacBook et plus`,
        article_9:`2 façons simples de supprimer l'historique des achats sur iPhone [Résolu]`,
        article_10:`Comment supprimer définitivement les images Snapchat du chat et des souvenirs`,
        article_11:`Comment supprimer les suggestions et l'historique de recherche Spotlight sur iPhone/iPad`,
        article_12:`[Résolu] Comment effacer le cache Instagram sur iPhone avec 3 manières simples`,
        article_13:`[Résolu] Comment réparer QuickTime Player ne fonctionne pas sur Mac`,
        article_14:`Comment enregistrer l'audio du système sur Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Moyens efficaces pour réparer l'enregistrement d'écran PowerPoint ne fonctionne pas`,
        article_16:`Comment enregistrer de l'audio avec VLC [Résolu]`,
        faq_title_1:`1. Comment corriger l'erreur 4104 ?`,
        faq_title_2:`2. Quand puis-je récupérer mon argent une fois la demande de remboursement approuvée ?`,
        faq_title_3:`3. Qu'est-ce qu'un « abonnement d'un an » et comment désactiver le « service de renouvellement automatique » ?`,
        faq_title_4:`4. Puis-je annuler ma commande après avoir placé un produit Aiseesoft ?`,
        faq_title_5:`5. Comment soumettre un avis ?`,
        faq_info_1:`Cela est dû à la licence limitée que vous avez. Nous adoptons une licence unique en cas de vol du code d'enregistrement et empêchons l'utilisation abusive du code d'enregistrement. Ainsi, le code Reg que vous avez obtenu est une licence unique qui ne peut être utilisée que sur un seul ordinateur. Si vous l'utilisez sur plusieurs ordinateurs, il y aura l'erreur 4104. Vous ne pouvez pas l'utiliser tant que nous ne l'avons pas réinitialisé pour vous.`,
        faq_info_2:`Si vous modifiez ou réinstallez votre ordinateur, nous pouvons le réinitialiser pour vous. Veuillez contacter le service client : <a href="mailto:support@aiseesoft.fr">support@aiseesoft.fr</a> pour demander la réinitialisation du code d'enregistrement.`,
        faq_info_3:`Une fois votre demande de remboursement approuvée, il faut normalement 3 à 7 jours ouvrables pour que le crédit apparaisse sur votre compte. Parfois, pendant les vacances, cela peut prendre plus de temps, mais cela ne devrait pas prendre plus de 10 jours. Si vous n'avez pas reçu le remboursement 20 jours après l'approbation, veuillez nous contacter à <a href="mailto:support@aiseesoft.fr">support@aiseesoft.fr</a> pour vérifier le remboursement.`,
        faq_info_4:`Le service de renouvellement automatique signifie que si vous achetez un abonnement d'un an, la plate-forme de paiement facturera le montant automatiquement (y compris les taxes applicables) à l'expiration de la licence du produit. Mais la plateforme de paiement (2Checkout) vous enverra une lettre pour vous rappeler que la commande va expirer 7 jours avant l'expiration. L'e-mail comprendra également des instructions sur la façon de désactiver le « Service de renouvellement automatique ».`,
        faq_info_5:`Étant donné que la commande est traitée automatiquement, il n'est pas permis d'annuler la commande. En outre, la version d'essai gratuite vous permettra d'en savoir plus sur le programme Aiseesoft et de vérifier s'il peut répondre à vos besoins avant de l'acheter.`,
        faq_info_6:`Si vous souhaitez vraiment obtenir un remboursement, veuillez consulter notre <a href="https://www.aiseesoft.fr/politique-remboursement.html">Politique de remboursement</a>.`,
        faq_info_7:`Nous apprécions votre temps et votre attention pour nous soumettre un avis. Contactez-nous (<a href="mailto:support@aiseesoft.fr">support@aiseesoft.fr</a>) pour soumettre votre avis et nous considérerons sérieusement votre avis pour les mises à jour de nos produits.`,
        change_password_success:`Le mot de passe a été changé avec succès`,
        successful_login_title:`Connexion réussie`,
        product_page:`Fiche produit>>`,
        successful_login_info:`Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant. La page en cours se fermera automatiquement dans 5 secondes. Si la fermeture automatique ou la fermeture en cliquant sur le bouton "Terminé" échoue, veuillez fermer cet onglet directement.`,
        successful_login_info_firefox:`Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant.`,
        my_account:`Mon compte`,
        my_history:`Mon histoire`,
        remove_watermark:`Supprimer filigrane`,
        no_history:`Pas d'historique`,
        history_all:`Tout sélectionner`,
        history_open:`Ouvert`,
        history_down:`Télécharger`,
        history_delete:`Supprimer`,
        history_clear:`Effacer invalide`,
        images:`image(s)`,
        use_this_function:`Utiliser cette fonction>>`,
        hd_downloading:`Avantages du téléchargement d'images originales HD :`,
        lifetimeRemaining:`Durée de vie restante`,
        Remaining:`Restant`,
        email_verification:`vérification de l'E-mail`,
        email_verification_info:`Nous avons envoyé le message de vérification à votre adresse e-mail <span class="email"></span>, et veuillez terminer la vérification. Après vérification, les avantages seront synchronisés automatiquement.`,
        wrong_email:`Mauvaise adresse email?`,
        click_here_to_modify:`Cliquez ici pour modifier`,
        get_more_help:`Obtenir plus d'aide ?`,
        click_here:`Cliquez ici`,
        email_verification_info_success:`Félicitations pour la vérification de votre compte de messagerie.`,
        email_verification_info_error:`La vérification a échoué en raison de l'expiration du lien.`,
        registration_succeeded:`Inscription réussie`,
        registration_succeeded_info_1:`Toutes nos félicitations! Vous vous êtes bien inscrit. Nous avons envoyé le message de vérification à votre adresse e-mail <span class="email"></span>, et veuillez terminer la vérification pour utiliser les avantages de cet e-mail.`,
        registration_succeeded_info_2:`Cliquez sur "Terminé" pour revenir à la page d'accueil et utiliser ce produit.`,
        registration_succeeded_info_3:`Cliquez sur "Terminé" pour fermer la page en cours et revenir à la page d'accueil pour les opérations suivantes. Si la fermeture échoue, veuillez fermer manuellement l'onglet.`,
        verify_email:`Vérifier les courriels`,
        registered_email_not_verified:`L'e-mail enregistré n'a pas été vérifié, veuillez le vérifier immédiatement.`,
        email_verification_time_1:`Vous n'avez pas reçu d'e-mail de vérification ?`,
        email_verification_time_2:`Après`,
        email_verification_time_3:`Cliquez ici pour le ré-expédier`,
        error_26301:`Code d'erreur : 26301, veuillez nous contacter`,
        error_26302:`Code d'erreur : 26302, veuillez nous contacter`,
        error_26303:`Erreur de format d'e-mail (code d'erreur : 26303). Veuillez le saisir à nouveau`,
        error_26304:`Un mot de passe supérieur à 8 caractères est recommandé (code d'erreur : 26304)`,
        error_26305:`Reuqest a échoué (code d'erreur : 26305). Veuillez réessayer`,
        error_26306:`L'e-mail a été enregistré, veuillez <a href="https://account.aiseesoft.com/login">aller à la connexion</a>`,
        error_26307:`Reuqest a échoué (code d'erreur : 26307). Veuillez réessayer`,
        error_26308:`Reuqest a échoué (code d'erreur : 26308). Veuillez réessayer`,
        error_26401:`Code d'erreur : 26401, veuillez réessayer`,
        error_26402:`L'e-mail a été vérifié (code d'erreur : 26402), veuillez réessayer`,
        error_26403:`Reuqest a échoué (code d'erreur : 26403). Veuillez réessayer`,
        error_26404:`Échec de la demande (code d'erreur : 26404). Veuillez réessayer`,
        error_26501:`Code d'erreur : 26501, veuillez nous contacter`,
        error_26502:`Code d'erreur : 26502, veuillez nous contacter`,
        error_26503:`Erreur de format d'e-mail (code d'erreur : 26503). Veuillez le saisir à nouveau`,
        error_26504:`Reuqest a échoué (code d'erreur : 26504). Veuillez réessayer`,
        error_26505:`L'e-mail n'a pas été enregistré, veuillez <a href="https://account.aiseesoft.com/register">l'enregistrer d'abord</a>`,
        error_26506:`L'e-mail a été vérifié.`,
        error_26507:`Reuqest a échoué (code d'erreur : 26507). Veuillez réessayer`,
        error_26508:`La vérification a échoué (code d'erreur : 26508), veuillez réessayer`,
        error_26509:`Échec de la demande (code d'erreur : 26509), veuillez réessayer`,
        error_26510:`Code d'erreur : 26510, veuillez nous contacter`,
        error_26601:`Code d'erreur : 26601, veuillez nous contacter`,
        error_26602:`Code d'erreur : 26602, veuillez nous contacter`,
        error_26603:`Reuqest a échoué (code d'erreur : 26603). Veuillez réessayer`,
        error_26604:`Code d'erreur : 26604, veuillez nous contacter`,
        error_26605:`Code d'erreur : 26605, veuillez nous contacter`,
        error_26701:`Code d'erreur : 26701, veuillez nous contacter`,
        error_26702:`Reuqest a échoué (code d'erreur : 26702). Veuillez réessayer`,
        error_26703:`Code d'erreur : 26703, veuillez nous contacter`,
        error_26704:`Code d'erreur : 26704, veuillez nous contacter`,
        error_26705:`Attendez la connexion (code d'erreur : 26705). Veuillez réessayer`,
        no_cookie:`Vous avez activé la fonction Bloquer tous les cookies dans votre navigateur, vous ne pouvez donc pas vous connecter. Veuillez vous rendre dans Paramètres pour cocher la case Autoriser tous les cookies.`,
        error_26801:`Code d'erreur : 26801, veuillez nous contacter`,
        error_26802:`Code d'erreur : 26802, veuillez nous contacter`,
        error_26803:`La demande a échoué (code d'erreur : 26803). Veuillez réessayer`,
        error_26804:`Échec de la demande (code d'erreur : 26804). Veuillez réessayer`,
        error_order:`Échec de la demande (code d'erreur : 27098), veuillez réessayer !`,
        error_order1:`La demande de commande est incomplète (code d'erreur :`,
        error_order2:`）, veuillez actualiser et réessayer.`,
        modify_email_title:`Modifier l'e-mail`,
        modify_email_info:`Vous pouvez utiliser l'e-mail modifié pour vous connecter à votre compte.`,
        images_per:`Images par`,
        error_26101:`Erreur : 26101. Veuillez nous contacter`,
        error_26102:`Erreur : 26102. Veuillez nous contacter`,
        error_26103:`Échec de la demande (code d'erreur : 26103). Veuillez réessayer`,
        error_26104:`Code d'erreur : 26104, veuillez réessayer`,
        error_26105:`Code d'erreur : 26105, veuillez réessayer`,
        error_26106:`Échec de la suppression (code d'erreur : 26106). Veuillez réessayer`,
        error_26201:`Erreur : 26201. Veuillez nous contacter`,
        error_26202:`Échec de la demande (code d'erreur : 26202). Veuillez réessayer`,
        error_26001:`Erreur : 26001. Veuillez nous contacter`,
        error_26002:`Erreur : 26002. Veuillez nous contacter`,
        error_26003:`Erreur : 26003. Veuillez nous contacter`,
        error_26004:`Erreur : 26004. Veuillez nous contacter`,
        error_26005:`Échec de la demande (code d'erreur : 26005). Veuillez réessayer`,
        error_26006:`Code d'erreur : 26006, veuillez réessayer`,
        error_26008:`Erreur : 26008. Veuillez nous contacter`,
        go_to_the_home_page:`Aller à la page d'accueil`,
        error_27101:`Échec de la demande (code d'erreur : 27101). Veuillez réessayer`,
        error_27201:`Code d'erreur : 27201, veuillez nous contacter`,
        error_27202:`Code d'erreur : 27202, veuillez réessayer`,
        error_27203:`Échec de la demande (code d'erreur : 27203). Veuillez réessayer`,
        error_27204:`Code invalide (code d'erreur : 27204).`,
        error_27205:`Échec de la demande (code d'erreur : 27205). Veuillez réessayer`,
        error_27206:`Échec de la requête (code d'erreur : 27206). Veuillez réessayer`,
        error_27207:`Échec de la demande (code d'erreur : 27207). Veuillez réessayer`,
        no_history_found:`Vous n'avez utilisé aucun outil! <a href="/">Actualiser</a> ou <a href="https://www.aiseesoft.fr/">Accéder au site officiel</a>`,
        error_25301:`Erreur : 25301. Veuillez nous contacter`,
        error_25302:`Erreur : 25302. Veuillez nous contacter`,
        error_25303:`Échec de la demande (code d'erreur : 25303). Veuillez réessayer`,
        error_25304:`Échec de la demande (code d'erreur : 25304). Veuillez réessayer`,
        error_25305:`Échec de la demande (code d'erreur : 25305). Veuillez réessayer`,
        error_25306:`Échec de la demande (code d'erreur : 25306). Veuillez réessayer`,
        image_upscaler_p:`Télécharger des images sans filigrane:`,
        Available_for:`Disponible pour :`,
        credit_per:`%s crédit(s) par image HD`,
        still_valid:`Les forfaits achetés sont toujours valides`,
        credit:`crédit(s)`,
        pc_3rd_info:`Vous vous êtes connecté avec succès, veuillez vous rendre sur l'application pour continuer à l'utiliser.`,
        use_online:`Utiliser le service en ligne`,
        use_download:`Utiliser le programme de bureau`,
        use_immediately:`Utiliser maintenant`,
        Use_in_browser:`Utiliser dans le navigateur`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    hu:{
        overtime_1:`Hibakód:`,
        overtime_2:`, próbálja újra`,
        isnetwork:`Internet hiba. Kérjük, ellenőrizze és próbálja újra`,
        email_placeholder:`Email`,
        email_empty:`Kérjük, adja meg az e -mailt`,
        email_not_valid:`az e-mail nem érvényes`,
        email_not_valid_1:`Kérjük, adja meg e -mail címét`,
        email_not_valid_2:`Az e -mail nem érvényes, kérjük, használjon másik címet.`,
        email_not_valid_3:`Nincs e -mail beviteli lehetőség`,
        password_placeholder:`Jelszó`,
        password_empty:`Kérjük, adja meg a jelszót`,
        password_not_valid:`Helytelen fiók vagy jelszó`,
        password_not_valid_1:`8 karakter feletti jelszó szükséges`,
        password_not_valid_2:`Kérjük, hozzon létre jelszót`,
        password_placeholder_1:`Hozd létre a jelszavad`,
        password_placeholder_2:`Erősítse meg a jelszót`,
        password_placeholder_3:`Hozzon létre új jelszót`,
        password_placeholder_4:`Erősítse meg az új jelszavát`,
        password_placeholder_5:`Írja be a régi jelszót`,
        copy_password_empty:`Kérjük, erősítse meg a jelszót`,
        copy_password_not_valid:`Kérjük, erősítse meg jelszavát`,
        copy_passwords_inconsistent:`A jelszó megerősítése nem egyezik`,
        code_empty:`Kérjük, adja meg az ellenőrző kódot`,
        code_not_valid:`Érvénytelen ellenörző kód`,
        code_placeholder:`Ellenőrző kód`,
        not_received_code:`Ha a postafiókja hosszú ideig nem kapja meg az ellenőrző kódot, kérjük, szerezze be újra az ellenőrző kódot.`,
        get_first_code:`Kérjük, először szerezze be az ellenőrző kódot.`,
        connect_your_email_placeholder:`Csatlakoztassa az e -mail címét`,
        last_name_placeholder:`Kérlek írd be a vezetékneved`,
        first_name_placeholder:`Kérjük, adja meg keresztnevét`,
        address_placeholder:`Kérjük, adja meg címét`,
        no_code_text:`Elküldtünk egy ellenőrző kódot. Kérjük, írja be a kódot. <span class='tips'>Nem kapott kódot?`,
        no_code_text_1:`1. Kérjük, győződjön meg arról, hogy az e -mail cím érvényes, és képes e -maileket fogadni.`,
        no_code_text_2:`2. Ha az e -mailt a rendszer automatikusan küldi, előfordulhat, hogy spamként vagy levélszemétként van megjelölve. Kérjük, ellenőrizze, hogy az e -mail a Kuka mappában van -e.`,
        no_code_text_3:`3. Nem tudja megoldani a problémát?`,
        no_code_text_3_span:`Ezután kattintson ide, és lépjen kapcsolatba velünk.`,
        order_no:`Még nem vásárolt terméket. Bármilyen kérdése van, kérjük, <a href="https://hu.aiseesoft.com/store/">Vásároljon most</a> vagy <a href="https://hu.aiseesoft.com/contact.html"> Vegye fel velünk a kapcsolatot</a>`,
        error_24901:`A jelenlegi fiókhoz nincs összekapcsolva e -mail, és nem találja a rendeléseket. Kérjük, csatlakoztasson egy e -mailt.`,
        user_guide:`Felhasználói útmutató >>`,
        download:`Letöltés`,
        order_number:`Rendelésszám`,
        Refund:`Visszatérítés`,
        Disabled:`Tiltva`,
        Normal:`Normál`,
        Modify:`Módosít`,
        Modify_1:`Módosítás >>`,
        Connect:`Csatlakozás`,
        unlink_success:`A leválasztás sikeresen megtörtént`,
        connect_success:`Csatlakozás sikeres`,
        feedback_title:`Köszönjük a visszajelzését. Hagyja el problémáját, és 24 órán belül válaszolunk.`,
        feedback_thank_you:`Köszönjük! <br /> Visszajelzését sikeresen elküldtük.`,
        feedback_email:`Írja be ide az e -mail címét!`,
        feedback_content:`Hagyjon itt bármilyen problémát vagy javaslatot, amellyel találkozott.`,
        feedback_submit:`Beküldés`,
        form_contents:`Nem adott meg leírást. Kérjük, adja meg, és küldje be újra.`,
        old_password:`Kérjük, adja meg a régi jelszót`,
        new_password:`Kérjük, hozzon létre új jelszót`,
        old_new_password:`Az új jelszó nem lehet ugyanaz, mint a régi`,
        incorrect_password:`hibás jelszó`,
        delete_no:`Törölje most`,
        Caps:`A Caps lock be van kapcsolva`,
        Get:`Kap`,
        Done:`Kész`,
        error_20001:`Hiba: 20001. Kérjük, jelentkezzen be újra.`,
        error_20002:`Hiba: 20002. Kérjük, jelentkezzen be újra.`,
        error_20003:`Hiba: 20003. Kérjük, jelentkezzen be újra.`,
        error_20004:`A kérés sikertelen (hibakód: 20004). Kérjük, próbálja újra.`,
        error_20005:`A bejelentkezési munkamenet lejárt (hiba: 20005). Kérjük, jelentkezzen be újra.`,
        error_20006:`A kérés sikertelen (hibakód: 20006). Kérjük, próbálja újra.`,
        error_20007:`A bejelentkezési munkamenet lejárt (Hiba: 20007). Kérjük, jelentkezzen be újra.`,
        error_20008:`A bejelentkezési munkamenet lejárt (Hiba: 20008). Kérjük, jelentkezzen be újra.`,
        error_20009:`A bejelentkezési munkamenet lejárt (hiba: 20009). Kérjük, jelentkezzen be újra.`,
        error_20101:`Kérjük, adja meg e -mail címét (hibakód: 20101)`,
        error_20102:`Az e -mail -cím érvénytelen (hibakód: 20102)`,
        error_20103:`A kérés sikertelen (hibakód: 20103). Kérjük, próbálja újra`,
        error_20104:`Az e -mail -cím már használatban van, kérjük, <a href="https://account.aiseesoft.com/login"> jelentkezzen be </a>, vagy regisztráljon egy újat`,
        error_20105:`A kérés sikertelen (hibakód: 20105). Kérjük, próbálja újra`,
        error_20106:`Nem sikerült elküldeni az e -mailt, próbálja újra`,
        error_20201:`Kérjük, adja meg e -mail címét (hibakód: 20201)`,
        error_20202:`Kérjük, adja meg jelszavát (hibakód: 20202)`,
        error_20203:`Kérjük, adja meg az ellenőrző kódot (hibakód: 20203)`,
        error_20204:`Az e -mail -cím érvénytelen (hibakód: 20204)`,
        error_20205:`8 karakter feletti jelszó szükséges (hibakód: 20205)`,
        error_20206:`A kérés sikertelen (hibakód: 20206). Kérjük, próbálja újra`,
        error_20207:`Érvénytelen ellenörző kód`,
        error_20208:`A kérés sikertelen (hibakód: 20208). Kérjük, próbálja újra`,
        error_20209:`A kérés sikertelen (hibakód: 20209). Kérjük, próbálja újra`,
        error_20301:`Kérjük, adja meg e -mail címét (hibakód: 20301)`,
        error_20302:`Hiba: 20302. Kérjük, vegye fel velünk a kapcsolatot`,
        error_20303:`Az e -mail -cím érvénytelen (hibakód: 20303)`,
        error_20304:`A kérés sikertelen (hibakód: 20304). Kérjük, próbálja újra`,
        error_20305:`Fiók nem létezik. Kérjük, először írja be újra, vagy <a href="https://account.aiseesoft.com/register">Létrehozni</a>.`,
        error_20306:`Még nincs jelszó. Használja a <a href="https://account.aiseesoft.com/passwordless-login"> Jelszó nélküli bejelentkezés </a> vagy a <a href="create-password"> jelszó beállítását </a>, és jelentkezzen be.`,
        error_20308:`A kérés sikertelen (hibakód: 20308). Kérjük, próbálja újra`,
        error_20401:`Nem sikerült kijelentkezni (hibakód: 20401). Kérjük, próbálja újra`,
        error_20501:`Kérjük, adja meg e -mail címét (hibakód: 20501)`,
        error_20502:`Az e -mail -cím érvénytelen (hibakód: 20502)`,
        error_20503:`A kérés sikertelen (hibakód: 20503). Kérjük, próbálja újra`,
        error_20504:`Nem sikerült elküldeni az e -mailt. Kérjük, próbálja újra.`,
        error_20601:`Kérjük, adja meg e -mail címét (hibakód: 20601)`,
        error_20602:`Kérjük, adja meg az ellenőrző kódot (hibakód: 20602)`,
        error_20603:`Az e -mail -cím érvénytelen (hibakód: 20603)`,
        error_20604:`A kérés sikertelen (hibakód: 20604). Kérjük, próbálja újra`,
        error_20606:`A kérés sikertelen (hibakód: 20606). Kérjük, próbálja újra`,
        error_20607:`A kérés sikertelen (hibakód: 20607). Kérjük, próbálja újra`,
        error_20608:`A kérés sikertelen (hibakód: 20608). Kérjük, próbálja újra`,
        error_20701:`Kérjük, adja meg e -mail címét (hibakód: 20701)`,
        error_20702:`Az e -mail -cím érvénytelen (hibakód: 20702)`,
        error_20703:`A kérés sikertelen (hibakód: 20703). Kérjük, próbálja újra`,
        error_20704:`Fiók nem létezik. Kérjük, először írja be újra, vagy <a href="https://account.aiseesoft.com/register">Létrehozni</a>.`,
        error_20705:`A kérés sikertelen (hibakód: 20705). Kérjük, próbálja újra`,
        error_20706:`Nem sikerült elküldeni az e -mailt. Kérjük, próbálja újra`,
        error_20801:`Kérjük, adja meg e -mail címét (hibakód: 20801)`,
        error_20802:`Hiba: 20802. Kérjük, vegye fel velünk a kapcsolatot`,
        error_20803:`Kérjük, adja meg az ellenőrző kódot (hibakód: 20803)`,
        error_20804:`Az e -mail -cím érvénytelen (hibakód: 20804)`,
        error_20805:`8 karakter feletti jelszó szükséges (hibakód: 20805)`,
        error_20806:`A kérés sikertelen (hibakód: 20806). Kérjük, próbálja újra`,
        error_20808:`A kérés sikertelen (hibakód: 20808). Kérjük, próbálja újra`,
        error_20901:`A kérés sikertelen (hibakód: 20901). Kérjük, próbálja újra`,
        error_20902:`A kérés sikertelen (hibakód: 20902). Kérjük, próbálja újra`,
        error_21000:`A módosítások mentésre kerülnek`,
        error_21001:`Nincs információ benyújtva (hibakód: 21001)`,
        error_21002:`A kérés sikertelen (hibakód: 21002). Kérjük, próbálja újra`,
        error_21101:`Kérjük, adja meg e -mail címét (hibakód: 21101)`,
        error_21102:`Az e -mail -cím érvénytelen (hibakód: 21102)`,
        error_21103:`A kérés sikertelen (hibakód: 21103). Kérjük, próbálja újra`,
        error_21104:`Az e -mail már csatlakoztatva van, használjon újat`,
        error_21105:`A kérés sikertelen (hibakód: 21105). Próbálkozzon újra`,
        error_21106:`Nem sikerült elküldeni az e -mailt. Kérjük, próbálja újra`,
        error_21201:`Kérjük, adja meg e -mail címét (hibakód: 21201)`,
        error_21202:`Kérjük, adja meg az ellenőrző kódot (hibakód: 21202)`,
        error_21203:`Az e -mail -cím érvénytelen (hibakód: 21203)`,
        error_21204:`Hiba: 21204. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21205:`Hiba: 21205. Kérjük, lépjen kapcsolatba velünk`,
        error_21206:`8 karakter feletti jelszó megadása szükséges (hibakód: 21206)`,
        error_21207:`A kérés sikertelen (hibakód: 21207). Kérjük, próbálja újra`,
        error_21209:`A kérés sikertelen (hibakód: 21209). Kérjük, próbálja újra`,
        error_21301:`Kérjük, adja meg a régi jelszót (hibakód: 21301)`,
        error_21302:`Kérjük, hozzon létre új jelszót (hibakód: 21302)`,
        error_21303:`Az új jelszó nem lehet ugyanaz, mint a régi. (Hiba: 21303)`,
        error_21304:`8 karakter feletti jelszó megadása szükséges (hibakód: 21304)`,
        error_21306:`A kérés sikertelen (hibakód: 21306). Kérjük, próbálja újra`,
        error_21402:`A kérés sikertelen (hibakód: 21402). Kérjük, próbálja újra`,
        error_21403:`Nem sikerült elküldeni az ellenőrző kódot. Kérjük, küldje el újra`,
        error_21500:`A fiók törölve lett`,
        error_21501:`Kérjük, adja meg az ellenőrző kódot (hibakód: 21501)`,
        error_21502:`A bejelentkezési munkamenet lejárt (hiba: 21502). Kérjük, jelentkezzen be újra.`,
        error_21503:`A kérés sikertelen (hibakód: 21503). Kérjük, próbálja újra`,
        error_21505:`A kérés sikertelen (hibakód: 21505), próbálja újra`,
        error_21601:`Hiba: 20601. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21602:`Érvénytelen ellenőrzés (hiba: 20602). Kérjük, próbálja újra.`,
        error_21603:`Hiba: 20603. Kérjük, próbálja újra`,
        error_21604:`A kérés sikertelen (hibakód: 21604). Kérjük, próbálja újra`,
        error_21606:`A kérés sikertelen (hibakód: 21606). Kérjük, próbálja újra`,
        error_21611:`A kérés sikertelen (hibakód: 21611). Kérjük, próbálja újra`,
        error_21801:`Hiba: 21801. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21802:`A kérés sikertelen (hiba: 21802). Kérjük, próbálja újra`,
        error_21803:`Hiba: 21803. Kérjük, próbálja újra`,
        error_21804:`A kérés sikertelen (hibakód: 21804). Kérjük, próbálja újra`,
        error_21806:`Hiba: 21806. Kérjük, próbálja újra`,
        error_21807:`Hiba: 21807. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21808:`Hiba: 21808. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21809:`Hiba: 21809. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21810:`Hiba: 21810. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21811:`Hiba: 21811. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21812:`Hiba: 21812. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21813:`A kérés sikertelen (hibakód: 21813). Kérjük, próbálja újra`,
        error_21814:`Hiba: 21814. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21815:`A kérés sikertelen (hibakód: 21815). Kérjük, próbálja újra`,
        error_21816:`Hiba: 21816. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21817:`Hiba: 21817. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21818:`Hiba: 21818. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21819:`A kérés sikertelen (hibakód: 21819). Kérjük, próbálja újra`,
        error_21820:`Hiba: 21820. Kérjük, lépjen kapcsolatba velünk`,
        error_21821:`Hiba: 21821. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21822:`Hiba: 21822. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21823:`A kérés sikertelen (hibakód: 21823). Kérjük, próbálja újra`,
        error_21824:`A kérés sikertelen (hibakód: 21824). Kérjük, próbálja újra`,
        error_21825:`A kérés sikertelen (hibakód: 21825). Kérjük, próbálja újra`,
        error_21826:`A kérés sikertelen (hibakód: 21826). Kérjük, próbálja újra`,
        error_21828:`Hiba: 21828. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21901:`Hiba: 21901. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21902:`A kérés sikertelen (hibakód: 21902). Kérjük, próbálja újra`,
        error_21903:`A fiók állapota megváltozott (hibakód: 21903). Kérjük, frissítse az oldalt, és próbálja újra`,
        error_21904:`Hiba: 21904. Kérjük, próbálja újra`,
        error_21905:`Hiba: 21905. Kérjük, próbálja újra`,
        error_21906:`A kérés sikertelen (hibakód: 21906). Kérjük, próbálja újra`,
        error_21907:`A Google -fiók össze van kapcsolva egy másik fiókkal`,
        error_21908:`A kérés sikertelen (hibakód: 21908). Kérjük, próbálja újra`,
        error_22001:`A kérés sikertelen (hibakód: 22001). Kérjük, próbálja újra`,
        error_22002:`A leválasztás további bejelentkezés nélkül nem sikerült`,
        error_22003:`A kérés sikertelen (hibakód: 22003). Kérjük, próbálja újra`,
        error_22101:`Hiba: 22101. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22102:`A fiók állapota megváltozott (hibakód: 22102). Kérjük, frissítse az oldalt, és próbálja újra`,
        error_22103:`A kérés sikertelen (hibakód: 22103). Kérjük, próbálja újra`,
        error_22104:`A fiók állapota megváltozott (hibakód: 22104). Kérjük, frissítse az oldalt, és próbálja újra`,
        error_22105:`Hiba: 22105. Próbálja újra`,
        error_22106:`Hiba: 22106. Kérjük, próbálja újra`,
        error_22107:`Hiba: 22107. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22108:`A kérés sikertelen (hibakód: 22108). Kérjük, próbálja újra`,
        error_22201:`Hiba: 22201. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22202:`Érvénytelen ellenőrzés (Hiba: 22202). Kérjük, próbálja újra.`,
        error_22203:`Hiba: 22203. Kérjük, próbálja újra "`,
        error_22204:`A kérés sikertelen (hibakód: 22204). Kérjük, próbálja újra`,
        error_22206:`A kérés sikertelen (hibakód: 22206). Kérjük, próbálja újra`,
        error_22401:`Hiba: 22401. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22402:`Érvénytelen ellenőrzés (Hiba: 22402). Kérjük, próbálja újra.`,
        error_22403:`Hiba: 22403. Kérjük, próbálja újra`,
        error_22404:`A kérés sikertelen (hibakód: 22404). Kérjük, próbálja újra`,
        error_22405:`A Facebook -fiók össze van kapcsolva más e -mailekkel`,
        error_22406:`Hiba: 22406. Kérjük, próbálja újra`,
        error_22407:`Hiba: 22407. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22408:`Hiba: 22408. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22409:`Hiba: 22409. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22410:`Hiba: 224010. Kérjük, lépjen kapcsolatba velünk`,
        error_22411:`Hiba: 224011. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22412:`Hiba: 224012. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22413:`Hiba: 22413. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22414:`A kérés sikertelen (hibakód: 22414). Kérjük, próbálja újra`,
        error_22415:`Hiba: 22415. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22416:`Hiba: 22416. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22417:`Hiba: 22417. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22418:`A kérés sikertelen (hibakód: 22418). Kérjük, próbálja újra`,
        error_22419:`Hiba: 22419. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22420:`Hiba: 22420. Kérjük, lépjen kapcsolatba velünk`,
        error_22421:`Hiba: 22421. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22422:`A kérés sikertelen (hibakód: 22422). Kérjük, próbálja újra`,
        error_22423:`A kérés sikertelen (hibakód: 22423). Kérjük, próbálja újra`,
        error_22424:`A kérés sikertelen (hibakód: 22424). Kérjük, próbálja újra`,
        error_22425:`A kérés sikertelen (hibakód: 22425). Kérjük, próbálja újra`,
        error_20098:`Hibakód: 20098. Ha Privát módban böngészik, váltson Normál módba, és próbálja újra.`,
        error_22298:`A Google -kérés sikertelen (hiba: 22298). Kérjük, próbálja újra.`,
        error_22498:`A Facebook -kérés sikertelen (hiba: 22498). Kérjük, próbálja újra.`,
        error_24902:`A kérés sikertelen (hibakód: 24902). Kérjük, próbálja újra`,
        error_24903:`A kérés sikertelen (hibakód: 24903). Kérjük, próbálja újra`,
        error_24904:`A kérés sikertelen (hibakód: 24904). Kérjük, próbálja újra`,
        error_24905:`A kérés sikertelen (hibakód: 24905). Kérjük, próbálja újra`,
        login_title:`Jelentkezzen be az Aiseesoftba`,
        password_placeholder:`Jelszó`,
        log_in:`Belépés`,
        no_account:`Nincs fiókja?`,
        create_it:`Létrehozni`,
        or_log_in_with:`Vagy jelentkezzen be a`,
        passwordless_login:`Jelszó nélküli bejelentkezés`,
        log_in_done:`Bejelentkezés Kész`,
        three_rd_account_connect_info:`Gratulálunk! Sikeresen bejelentkezett. Most egy e -mail fiókot csatlakoztathat a jövőbeni bejelentkezéshez.`,
        connect_now:`Csatlakozzon most`,
        see_my_account:`Lásd a fiókomat`,
        three_rd_login_merge_account:`A harmadik fél fiókjának e-mail címe regisztrálva van. Szeretne közvetlenül ezzel az e-mail címmel csatlakozni és bejelentkezni?`,
        connect_log_in:`Csatlakozás és bejelentkezés`,
        create_an_account:`Hozzon létre egy fiókot`,
        connect_account:`Fiók összekapcsolása`,
        connect_account_info:`A csatlakozás után bejelentkezhet ezzel a fiókkal.`,
        back_to_log_in:`Vissza a bejelentkezéshez`,
        create_password:`Jelszó létrehozása`,
        create_now:`Létrehozás most`,
        password_login_subtitle:`Jelszó nélküli bejelentkezés e -mailben`,
        account_login:`Fiók Belépés`,
        rights:`A fiók létrehozásával Ön elfogadja az <a href="https://hu.aiseesoft.com/company/terms-and-conditions.html"> Általános Szerződési Feltételeket </a> és <a href="https://hu.aiseesoft.com/privacy-policy.html">Adatvédelmi irányelvek </a>`,
        passwordless_login_done:`Jelszó nélküli bejelentkezés megtörtént`,
        passwordless_login_info:`Gratulálunk, sikeresen befejezte a jelszó nélküli bejelentkezést. Létrehozhat jelszót ehhez a fiókhoz, és a jövőben bejelentkezhet ezzel a fiókkal és jelszóval. <a href="/create-password" style="display: initial;"> Létrehozás </a>`,
        sign_up:`Regisztrálj`,
        register_info:`Hozd létre fiókodat`,
        reset_now:`Állítsa vissza most`,
        forgot_password:`Elfelejtett jelszó`,
        reset_password_subtitle:`Használja fiókja e -mail címét a jelszó visszaállításához`,
        plan_products:`Terv és termékek`,
        nick_name:`Felhasználónév`,
        email:`Email`,
        my_products:`Saját termékek`,
        my_orders:`Rendeléseim`,
        unlink:`Leválasztás`,
        link:`Link`,
        connected_accounts:`Összekapcsolt fiókok`,
        last_name:`Vezetéknév`,
        first_name:`Keresztnév`,
        Gender:`Nem`,
        Birth:`Születés`,
        Month:`Hónap`,
        Year:`Év`,
        Country_Region:`Ország/régió`,
        Address:`Cím`,
        Save:`Megment`,
        Date:`Dátum`,
        Male:`Férfi`,
        Female:`Női`,
        Unspecified:`Meg nem határozott`,
        Security:`Biztonság`,
        change_password:`Jelszó módosítása`,
        change_now:`Változtass most`,
        connect_email:`Csatlakoztassa az e -mailt`,
        delete_account:`Fiók törlése`,
        delete_account_info:`A fiók törlésekor a tervhez és termékekhez tartozó összes, a fiókjához társított adat véglegesen törlődik, és előfordulhat, hogy nem tudja helyreállítani. Javasoljuk, hogy óvatosan lépjen.`,
        Delete:`Töröl`,
        Logout:`Kijelentkezés`,
        my_profile:`A profilom`,
        guides_faqs:`Útmutatók és GYIK`,
        More:`Több`,
        guides:`Útmutatók`,
        register:`Regisztráció`,
        hot_faq:`Forró GYIK`,
        Contents:`Tartalom:`,
        contact_us:`Vegye fel velünk a kapcsolatot >>`,
        plan:`Terv`,
        unregistered:`Nem regisztrált`,
        buy_more:`Többet vásárolnak`,
        buy_again:`Vásároljon újra`,
        buy_now:`Vásárolj most`,
        free_no_limit:`Ingyenes és nincs korlátozás`,
        expired:`Lejárt`,
        lifetime:`Élettartam`,
        remain:`Marad`,
        day_s:`Napok)`,
        error_24801:`A kérés sikertelen (hibakód: 24801). Kérjük, próbálja meg újra`,
        no_app_found:`Nem található alkalmazás!<a href="/">Frissítés</a> vagy <a href="https://hu.aiseesoft.com/">Ugrás a hivatalos webhelyre</a>`,
        get_more:`További információ >>`,
        edit_photo:`Fénykép szerkesztése`,
        select_photo:`Válassza ki a fényképet`,
        change_photo:`Fotó megváltoztatása`,
        cancel:`Megszünteti`,
        hide_password:`Jelszó elrejtése`,
        show_password:`Mutasd a jelszót`,
        zoom_in:`Nagyítás`,
        zoom_out:`Kicsinyítés`,
        rotate:`Forog`,
        horizontal_flip:`Vízszintes flip`,
        vertical_flip:`Függőleges flip`,
        country:`Ország`,
        country_1:`Válassza ki országát/régióját`,
        country_2:`Åaland-sziget`,
        country_3:`Afganisztán`,
        country_4:`Albánia`,
        country_5:`Algéria`,
        country_6:`Amerikai Szamoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktisz`,
        country_11:`Antigua és Barbuda`,
        country_12:`Argentína`,
        country_13:`Örményország`,
        country_14:`Aruba`,
        country_15:`Ausztrália`,
        country_16:`Ausztria`,
        country_17:`Azerbajdzsán`,
        country_18:`Bahrein`,
        country_19:`Banglades`,
        country_20:`Barbados`,
        country_21:`Fehéroroszország`,
        country_22:`Belgium`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhután`,
        country_27:`Bolívia`,
        country_28:`Bosznia és Hercegovina`,
        country_29:`Botswana`,
        country_30:`Bouvet-sziget`,
        country_31:`Brazília`,
        country_32:`Brit Indiai-óceáni Terület`,
        country_33:`Brit Virgin szigetek`,
        country_34:`Brunei`,
        country_35:`Bulgária`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodzsa`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`zöld-fok`,
        country_42:`karibi Hollandia`,
        country_43:`Kajmán-szigetek`,
        country_44:`Közép-Afrikai Köztársaság`,
        country_45:`Csád`,
        country_46:`Chile`,
        country_47:`Kína`,
        country_48:`Karácsony-sziget`,
        country_49:`Kókusz (Keeling)-szigetek`,
        country_50:`Colombia`,
        country_51:`Cook-szigetek`,
        country_52:`Costa Rica`,
        country_53:`"Elefántcsontpart"`,
        country_54:`Horvátország`,
        country_55:`Kuba`,
        country_56:`Ciprus`,
        country_57:`Cseh Köztársaság`,
        country_58:`Kongói Demokratikus Köztársaság`,
        country_59:`Dánia`,
        country_60:`Dzsibuti`,
        country_61:`Dominika`,
        country_62:`Dominikai Köztársaság`,
        country_63:`Ecuador`,
        country_64:`Egyiptom`,
        country_65:`El Salvador`,
        country_66:`Egyenlítői-Guinea`,
        country_67:`Eritrea`,
        country_68:`Észtország`,
        country_69:`Etiópia`,
        country_70:`Falkland-szigetek`,
        country_71:`Faroe Szigetek`,
        country_72:`Mikronéziai Szövetségi Államok`,
        country_73:`Fidzsi-szigetek`,
        country_74:`Finnország`,
        country_75:`Franciaország`,
        country_76:`Francia Guyana`,
        country_77:`Francia Polinézia`,
        country_78:`Francia déli területek`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Grúzia`,
        country_82:`Németország`,
        country_83:`Ghána`,
        country_84:`Gibraltár`,
        country_85:`Nagy-Britannia (Egyesült Királyság; Anglia)`,
        country_86:`Görögország`,
        country_87:`Grönland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Bissau-Guinea`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard-sziget és McDonald-szigetek`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Magyarország`,
        country_101:`Izland`,
        country_102:`India`,
        country_103:`Indonézia`,
        country_104:`Irán`,
        country_105:`Irak`,
        country_106:`Írország`,
        country_107:`Man-sziget`,
        country_108:`Izrael`,
        country_109:`Olaszország`,
        country_110:`Jamaica`,
        country_111:`Japán`,
        country_112:`Jersey`,
        country_113:`Jordánia`,
        country_114:`Kazahsztán`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuvait`,
        country_118:`Kirgizisztán`,
        country_119:`Laosz`,
        country_120:`Lettország`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Libéria`,
        country_124:`Líbia`,
        country_125:`Liechtenstein`,
        country_126:`Litvánia`,
        country_127:`Luxemburg`,
        country_128:`Makaó`,
        country_129:`Madagaszkár`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldív-szigetek`,
        country_133:`Mali`,
        country_134:`Málta`,
        country_135:`Marshall-szigetek`,
        country_136:`Martinique`,
        country_137:`Mauritánia`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexikó`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongólia`,
        country_144:`Montenegró`,
        country_145:`Montserrat`,
        country_146:`Marokkó`,
        country_147:`Mozambik`,
        country_148:`Mianmar (Burma)`,
        country_149:`Namíbia`,
        country_150:`Nauru`,
        country_151:`Nepál`,
        country_152:`Hollandia`,
        country_153:`Új-Kaledónia`,
        country_154:`Új Zéland`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigéria`,
        country_158:`Niue`,
        country_159:`Norfolk-sziget`,
        country_160:`Észak Kórea`,
        country_161:`Észak Mariana szigetek`,
        country_162:`Norvégia`,
        country_163:`Omán`,
        country_164:`Pakisztán`,
        country_165:`Palau`,
        country_166:`palesztin területek`,
        country_167:`Panama`,
        country_168:`Pápua Új-Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairn-szigetek`,
        country_172:`Lengyelország`,
        country_173:`Portugália`,
        country_174:`Puerto Rico`,
        country_175:`Katar`,
        country_176:`Macedónia Köztársaság (FYROM)`,
        country_177:`Kongói Köztársaság`,
        country_178:`Réunion`,
        country_179:`Románia`,
        country_180:`Orosz Föderáció`,
        country_181:`Ruanda`,
        country_182:`Szent Barthélemy`,
        country_183:`Saint Martin (Franciaország)`,
        country_184:`Saint-Pierre és Miquelon`,
        country_185:`Szamoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome és Principe`,
        country_188:`Szaud-Arábia`,
        country_189:`Szenegál`,
        country_190:`Szerbia`,
        country_191:`Seychelle-szigetek`,
        country_192:`Sierra Leone`,
        country_193:`Szingapúr`,
        country_194:`Szlovákia`,
        country_195:`Szlovénia`,
        country_196:`Salamon-szigetek`,
        country_197:`Szomália`,
        country_198:`Dél-Afrika`,
        country_199:`Dél-Georgia és a South Sandwich-sziget`,
        country_200:`Dél-Korea`,
        country_201:`Dél Szudán`,
        country_202:`Spanyolország`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Dependencies`,
        country_205:`St. Kitts & Nevis`,
        country_206:`Szent Lucia`,
        country_207:`St. Vincent és a Grenadine-szigetek`,
        country_208:`Szudán`,
        country_209:`Suriname`,
        country_210:`Szváziföld`,
        country_211:`Svédország`,
        country_212:`Svájc`,
        country_213:`Szíria`,
        country_214:`Tajvan`,
        country_215:`Tádzsikisztán`,
        country_216:`Tanzánia`,
        country_217:`Sablon: Országadatok SJM Svalbard`,
        country_218:`Thaiföld`,
        country_219:`A Bahamák`,
        country_220:`A Comore-szigetek`,
        country_221:`Fülöp-szigetek`,
        country_222:`Kelet-Timor (Kelet-Timor)`,
        country_223:`Menni`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad és Tobago`,
        country_227:`Tunézia`,
        country_228:`pulyka`,
        country_229:`Türkmenisztán`,
        country_230:`Turks és Caicos-szigetek`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukrajna`,
        country_234:`Egyesült Arab Emírségek`,
        country_235:`Az Egyesült Államok külső szigetei`,
        country_236:`Amerikai Egyesült Államok (USA)`,
        country_237:`Egyesült Államok Virgin-szigetek`,
        country_238:`Uruguay`,
        country_239:`Üzbegisztán`,
        country_240:`Vanuatu`,
        country_241:`Vatikánváros (a Szentszék)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis és Futuna`,
        country_245:`nyugat-Szahara`,
        country_246:`Jemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Jelentkezzen be a Google-lal`,
        footer:`Copyright © 2024 Aiseesoft Studio. Minden jog fenntartva.`,
        State:`Állapot`,
        Activation_code:`Aktiváló kód`,
        Question:`Sorolja fel az összes alkalmazást, amellyel bejelentkezett`,
        Copy_complete:`Másolás kész`,

        store:`Bolt`,
        resource:`Forrás`,
        support:`támogatás`,
        video_converter:`Videó konvertáló`,
        multimedia:`Multimédia`,
        data_recovery:`Adat visszanyerés`,
        utility:`Hasznosság`,
        free_tools:`Ingyenes eszközök`,
        for_windows:`Windowshoz`,
        for_mac:`Machez`,
        pdf_solutions:`PDF megoldások`,
        data_manager:`Adatkezelő`,
        other_tools:`Egyéb eszközök`,
        free_online_solutions:`Ingyenes online megoldások`,
        free_online_tools:`Ingyenes online eszközök`,
        desktop_freeware:`Ingyenes asztali szoftver`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS konverter`,
        product_4:`4K konverter`,
        product_5:`MXF konverter`,
        product_6:`3D konverter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter for Mac`,
        product_9:`MTS Converter for Mac`,
        product_10:`4K konverter Machez`,
        product_11:`MXF Converter for Mac`,
        product_12:`3D Converter for Mac`,
        product_13:`Blu-ray lejátszó`,
        product_14:`Video Enhancer`,
        product_15:`Videó szerkesztő`,
        product_16:`Diavetítés készítő`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova – DVD Creator Pro`,
        product_19:`DVD-készítő`,
        product_20:`Mac Blu-ray lejátszó`,
        product_21:`Mac Video Enhancer`,
        product_22:`Videószerkesztő Mac-hez`,
        product_23:`DVD Creator for Mac`,
        product_24:`MP4 Converter for Mac`,
        product_25:`Windows adat-helyreállítás`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab iOS rendszer-helyreállítás`,
        product_28:`FoneLab iOS Data Backup &amp; visszaállítás`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Törött Android adatkinyerés`,
        product_31:`Android Data Backup &amp; visszaállítás`,
        product_32:`Mac adatmentés`,
        product_33:`Mac FoneLab iPhone Data Recovery`,
        product_34:`Mac FoneLab iOS rendszer-helyreállítás`,
        product_35:`Mac FoneLab iOS Data Backup &amp; visszaállítás`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; visszaállítás`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate for Mac`,
        product_40:`PDF összevonás`,
        product_41:`Mac PDF-ből ePub-ba konvertáló`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp átvitel iOS-hez`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone tisztító`,
        product_48:`PPT-ből Video Converter`,
        product_49:`HEIC konverter`,
        product_50:`iPhone Unlocker`,
        product_51:`Ingyenes Online Video Converter`,
        product_52:`Ingyenes audio konverter online`,
        product_53:`Ingyenes videokompresszor online`,
        product_54:`Ingyenes online videó egyesülés`,
        product_55:`Ingyenes HEIC konverter`,
        product_56:`Ingyenes képkompresszor`,
        product_57:`Ingyenes vízjel eltávolító`,
        product_58:`Ingyenes PDF Compressor Online`,
        product_59:`Ingyenes Képfelújító online`,
        product_60:`Ingyenes háttéreltávolító online`,
        product_61:`Ingyenes Képkonverter online`,
        product_62:`Ingyenes PDF PNG konverter online`,
        product_63:`Ingyenes Video Converter`,
        product_64:`Ingyenes videó GIF konverter`,
        product_65:`Ingyenes Android adat-helyreállítás`,
        product_66:`Slideshow Maker`,
        product_67:`Ingyenes videószerkesztő`,
        product_68:`Ingyenes PDF-nézegető`,
        product_69:`Képernyőrögzítő`,
        product_70:`Mac Screen Recorder`,
        product_71:`Ingyenes képernyőrögzítő online`,
        product_72:`Ingyenes hangrögzítő online`,
        product_73:`Telefon tükör`,
        product_74:`AnyCoord – Helyszínváltó`,
        product_75:`iPhone Jelszókezelő`,
        product_76:`Ingyenes audiokompresszor online`,
        product_77:`Ingyenes videóvágó online`,
        product_78:`Ingyenes Video Cropper Online`,
        product_79:`Ingyenes Video Rotator Online`,
        product_80:`Ingyenes Video Looper Online`,
        product_81:`Ingyenes PDF JPG konverter online`,
        product_81:`Ingyenes JPG Word Converter online`,
        category_1:`Fénykép szerkesztés`,
        category_2:`Videó szerkesztés`,
        category_3:`Radír`,
        category_4:`Médiarögzítő`,
        posted_by:`általa megosztva`,
        to:`nak nek`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Szöveg eltávolítása a képekről hatékonyan`,
        article_2:`Hogyan lehet a képméretet KB-ban MB-ra növelni a minőség romlása nélkül`,
        article_3:`A Getty Images vízjel eltávolítása Windows és Mac rendszeren`,
        article_4:`A legjobb módszer kis felbontású képek nagy felbontásúvá konvertálására online ingyen`,
        article_5:`TikTok útmutató | Így készíthet videokollázst a TikTokon`,
        article_6:`5 egyszerű módszer a VOB-fájlok kombinálására, amelyeket tudnia kell`,
        article_7:`Hogyan lehet egyszerűen felfejleszteni a videót és nagyobb felbontást elérni`,
        article_8:`MP4 fájlok kombinálása MacBook Pro/iMac/MacBook és egyebeken`,
        article_9:`2 egyszerű módszer a vásárlási előzmények törlésére iPhone-on [Megoldva]`,
        article_10:`Hogyan lehet véglegesen törölni a Snapchat képeket a csevegésből és az emlékekből`,
        article_11:`A Spotlight keresési javaslatainak és előzményeinek törlése iPhone/iPad készüléken`,
        article_12:`[Megoldva] Az Instagram gyorsítótár törlése iPhone-on 3 egyszerű módszerrel`,
        article_13:`[Megoldva] Hogyan lehet kijavítani, hogy a QuickTime Player nem működik Mac rendszeren`,
        article_14:`Rendszerhang rögzítése Mac-en (Big Sur/Catalina/Mojave…)`,
        article_15:`Hatékony módszerek a nem működő PowerPoint képernyőrögzítés javítására`,
        article_16:`Hang rögzítése VLC-vel [Megoldva]`,
        faq_title_1:`1. Hogyan lehet javítani a 4104-es hibát?`,
        faq_title_2:`2. Mikor kaphatom vissza a pénzemet a visszatérítési kérelem jóváhagyása után?`,
        faq_title_3:`3. Mi az "1 éves előfizetés", és hogyan lehet kikapcsolni az "Automatikus megújítási szolgáltatást"?`,
        faq_title_4:`4. Lemondhatom a rendelésemet az Aiseesoft termék leadása után?`,
        faq_title_5:`5. Hogyan lehet véleményt benyújtani Önnek?`,
        faq_info_1:`Ez a korlátozott licencnek köszönhető. A regisztrációs kód ellopása esetén egységes licencet fogadunk el, és megakadályozzuk a regisztrációs kód jogsértő használatát. Tehát a Reg Code egy egységes licenc, amely csak egy számítógépen használható. Ha egynél több számítógépen használja, a 4104-es hibaüzenet jelenik meg. Addig nem használhatja, amíg vissza nem állítjuk.`,
        faq_info_2:`Ha megváltoztatja vagy újratelepíti számítógépét, visszaállíthatjuk Ön helyett. Kérjük, lépjen kapcsolatba az ügyfélszolgálattal: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>, és kérje a regisztrációs kód visszaállítását.`,
        faq_info_3:`A visszatérítési kérelmének jóváhagyása után általában 3–7 munkanap kell ahhoz, hogy a jóváírás megjelenik a fiókjában. Néha az ünnepek alatt ez tovább tarthat, de várhatóan nem tart 10 napnál tovább. Ha a jóváhagyás után 20 napon belül nem kapja meg a visszatérítést, kérjük, lépjen velünk kapcsolatba a <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> címen a visszatérítés ellenőrzéséhez.`,
        faq_info_4:`Az automatikus megújítási szolgáltatás azt jelenti, hogy ha 1 éves előfizetést vásárol, a fizetési platform automatikusan megterheli az összeget (beleértve a vonatkozó adókat), amikor a termék licence lejár. De a fizetési lap (Swreg vagy 2Checkout) levélben emlékezteti Önt, hogy a megrendelés 7 nappal a lejárat előtt lejár. Az e-mail az „Automatikus megújítási szolgáltatás” kikapcsolására vonatkozó utasításokat is tartalmazni fogja.`,
        faq_info_5:`Mivel a megrendelés feldolgozása automatikusan történik, a megrendelés lemondására nincs lehetőség. Ezenkívül az ingyenes próbaverzió lehetővé teszi, hogy többet tudjon meg az Aiseesoft programról, és vásárlás előtt ellenőrizze, hogy megfelel-e az Ön igényeinek.`,
        faq_info_6:`Ha valóban visszatérítést szeretne kapni, kérjük, tekintse meg <a href="https://hu.aiseesoft.com/refund-policy.html">Visszatérítési szabályzatunkat</a>.`,
        faq_info_7:`Köszönjük, hogy időt és figyelmet fordított arra, hogy véleményt küldjön nekünk. Írjon nekünk (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) véleményének elküldéséhez, és mi komolyan megfontoljuk véleményét termékfrissítéseink kapcsán.`,
        change_password_success:`A jelszó sikeresen megváltozott`,
        successful_login_title:`Sikeres bejelentkezés`,
        product_page:`Termékoldal >>`,
        successful_login_info:`A bejelentkezés befejeződött. Kérjük, zárja be az aktuális oldalt, és lépjen vissza az eredeti lapra a következő folyamat folytatásához. Az aktuális oldal 5 másodpercen belül automatikusan bezárul. Ha az automatikus bezárás vagy bezárás a „Kész” gombra kattintva nem sikerül, kérjük, zárja be közvetlenül ezt a lapot.`,
        successful_login_info_firefox:`A bejelentkezés befejeződött. Kérjük, zárja be az aktuális oldalt, és lépjen vissza az eredeti lapra a következő folyamat folytatásához.`,
        my_account:`Fiókom`,
        my_history:`Az én történetem`,
        remove_watermark:`Távolítsa el a vízjelet`,
        no_history:`Nincs előzmény`,
        history_all:`Mindet kiválaszt`,
        history_open:`Nyisd ki`,
        history_down:`Letöltés`,
        history_delete:`Töröl`,
        history_clear:`Törölje az érvénytelen`,
        images:`kép(ek)`,
        use_this_function:`Használja ezt a funkciót>>`,
        hd_downloading:`Az eredeti HD képek letöltésének előnyei:`,
        lifetimeRemaining:`Élettartam hátralévő`,
        Remaining:`Többi`,
        email_verification:`Email megerősítés`,
        email_verification_info:`Elküldtük az ellenőrző üzenetet az Ön e-mail-címére: <span class="email"></span>, és kérjük, fejezze be az ellenőrzést. Az ellenőrzés után az előnyök automatikusan szinkronban lesznek.`,
        wrong_email:`Rossz email cím?`,
        click_here_to_modify:`Kattintson ide a módosításhoz`,
        get_more_help:`További segítséget kér?`,
        click_here:`Kattints ide`,
        email_verification_info_success:`Gratulálunk e-mail fiókjának igazolásához.`,
        email_verification_info_error:`Az ellenőrzés meghiúsult a lejárt link miatt.`,
        registration_succeeded:`Sikerült a regisztráció`,
        registration_succeeded_info_1:`Gratulálunk! Sikeresen regisztráltál. Elküldtük az ellenőrző üzenetet az Ön e-mail-címére: <span class="email"></span>, és kérjük, fejezze be az ellenőrzést, hogy kihasználhassa ennek az e-mailnek az előnyeit.`,
        registration_succeeded_info_2:`Kattintson a „Kész” gombra, hogy visszatérjen a Kezdőlapra, és használja ezt a terméket.`,
        registration_succeeded_info_3:`Kattintson a „Kész” gombra az aktuális oldal bezárásához, és a kezdőlapra való visszatéréshez a következő műveletekhez. Ha a bezárás sikertelen, zárja be manuálisan a lapot.`,        
        verify_email:`E-mail megerősítés`,
        registered_email_not_verified:`A regisztrált e-mail-címet nem ellenőriztük, kérjük, azonnal ellenőrizze.`,
        email_verification_time_1:`Nem kapott ellenőrző e-mailt?`,
        email_verification_time_2:`Utána`,
        email_verification_time_3:`Kattintson ide az újraküldéshez`,
        error_26301:`Hibakód: 26301, kérjük lépjen kapcsolatba velünk`,
        error_26302:`Hibakód: 26302, kérjük lépjen kapcsolatba velünk`,
        error_26303:`E-mail formátum hiba (hibakód: 26303). Kérjük, írja be újra`,
        error_26304:`8 karakternél hosszabb jelszó ajánlott (hibakód: 26304)`,
        error_26305:`A visszaküldés sikertelen (hibakód: 26305). Kérjük, próbálja meg újra`,
        error_26306:`Az e-mail-cím regisztrálva, kérjük, <a href="https://account.aisesoft.com/login">lépjen be a bejelentkezéshez</a>`,
        error_26307:`A visszaküldés sikertelen (hibakód: 26307). Kérjük, próbálja újra`,
        error_26308:`A visszaküldés sikertelen (hibakód: 26308). Kérjük, próbálja meg újra`,
        error_26401:`Hibakód: 26401, kérjük, próbálja újra`,
        error_26402:`Az e-mail-cím ellenőrzése megtörtént (hibakód: 26402), kérjük, próbálja újra`,
        error_26403:`A visszaküldés sikertelen (hibakód: 26403). Kérjük, próbálja újra`,
        error_26404:`A kérés sikertelen (hibakód: 26404). Kérjük, próbálja meg újra`,
        error_26501:`Hibakód: 26501, kérjük lépjen kapcsolatba velünk`,
        error_26502:`Hibakód: 26502, kérjük lépjen kapcsolatba velünk`,
        error_26503:`E-mail formátum hiba (hibakód: 26503). Kérjük, írja be újra`,
        error_26504:`A visszaküldés sikertelen (hibakód: 26504). Kérjük, próbálja meg újra`,
        error_26505:`Az e-mail-cím nincs regisztrálva, kérjük, <a href="https://account.aisesoft.com/register">először regisztrálja</a>`,
        error_26506:`Az e-mail-cím ellenőrzése megtörtént.`,
        error_26507:`A visszaküldés sikertelen (hibakód: 26507). Kérjük, próbálja meg újra`,
        error_26508:`Az ellenőrzés megtörtént (hibakód: 26508), kérjük, próbálja meg újra`,
        error_26509:`A kérés sikertelen (hibakód: 26509), kérjük, próbálja újra`,
        error_26510:`Hibakód: 26510, kérjük lépjen kapcsolatba velünk`,
        error_26601:`Hibakód: 26601, kérjük lépjen kapcsolatba velünk`,
        error_26602:`Hibakód: 26602, kérjük lépjen kapcsolatba velünk`,
        error_26603:`A visszaküldés sikertelen (hibakód: 26603). Kérjük, próbálja meg újra`,
        error_26604:`Hibakód: 26604, kérjük lépjen kapcsolatba velünk`,
        error_26605:`Hibakód: 26605, kérjük lépjen kapcsolatba velünk`,
        error_26701:`Hibakód: 26701, kérjük lépjen kapcsolatba velünk`,
        error_26702:`A visszaküldés sikertelen (hibakód: 26702). Kérjük, próbálja meg újra`,
        error_26703:`Hibakód: 26703, kérjük lépjen kapcsolatba velünk`,
        error_26704:`Hibakód: 26704, kérjük lépjen kapcsolatba velünk`,
        error_26705:`Várjon a bejelentkezésre (hibakód: 26705). Kérjük, próbálja újra`,
        no_cookie:`Böngészőjében bekapcsolta az Összes cookie letiltása funkciót, így nem tud bejelentkezni. Kérjük, lépjen a Beállításokba, és jelölje be az Összes cookie engedélyezése négyzetet.`,
        error_26801:`Hibakód: 26801, kérjük lépjen kapcsolatba velünk`,
        error_26802:`Hibakód: 26802, kérjük lépjen kapcsolatba velünk`,
        error_26803:`A kérés sikertelen (hibakód: 26803). Kérjük, próbálja újra`,
        error_26804:`A kérés sikertelen (hibakód: 26804). Kérjük, próbálja újra`,
        error_order:`A kérés sikertelen (hibakód: 27098), kérjük, próbálja újra!`,
        error_order1:`A rendelés lekérdezése hiányos (hibakód:`,
        error_order2:`）, kérjük, frissítse, és próbálja újra.`,
        modify_email_title:`E-mail módosítása`,
        modify_email_info:`A módosított e-mail címmel bejelentkezhet fiókjába.`,
        images_per:`Képek per`,
        error_26101:`Hiba: 26101. Kérjük, lépjen kapcsolatba velünk`,
        error_26102:`Hiba: 26102. Kérjük, lépjen kapcsolatba velünk`,
        error_26103:`A kérés sikertelen (hibakód: 26103). Kérjük, próbálja újra`,
        error_26104:`Hibakód: 26104, kérjük, próbálja újra`,
        error_26105:`Hibakód: 26105, kérjük, próbálja újra`,
        error_26106:`A törlés sikertelen (hibakód: 26106). Kérjük, próbálja meg újra`,
        error_26201:`Hiba: 26201. Kérjük, lépjen kapcsolatba velünk`,
        error_26202:`A kérés sikertelen (hibakód: 26202). Kérjük, próbálja újra`,
        error_26001:`Hiba: 26001. Kérjük, lépjen kapcsolatba velünk`,
        error_26002:`Hiba: 26002. Kérjük, lépjen kapcsolatba velünk`,
        error_26003:`Hiba: 26003. Kérjük, lépjen kapcsolatba velünk`,
        error_26004:`Hiba: 26004. Kérjük, lépjen kapcsolatba velünk`,
        error_26005:`A kérés sikertelen (hibakód: 26005). Kérjük, próbálja újra`,
        error_26006:`Hibakód: 26006, kérjük, próbálja újra`,
        error_26008:`Hiba: 26008. Kérjük, lépjen kapcsolatba velünk`,
        go_to_the_home_page:`Menjen a Kezdőlapra`,
        error_27101:`A kérés sikertelen (hibakód: 27101). Kérjük, próbálja újra`,
        error_27201:`Hibakód: 27201, kérjük lépjen kapcsolatba velünk`,
        error_27202:`Hibakód: 27202, kérjük, próbálja újra`,
        error_27203:`A kérés sikertelen (hibakód: 27203). Kérjük, próbálja újra`,
        error_27204:`Érvénytelen kód (hibakód: 27204).`,
        error_27205:`A kérés sikertelen (hibakód: 27205). Kérjük, próbálja újra`,
        error_27206:`A kérés sikertelen (hibakód: 27206). Kérjük, próbálja újra`,
        error_27207:`A kérés sikertelen (hibakód: 27207). Kérjük, próbálja újra`,
        no_history_found:`Nem használtál semmilyen eszközt! <a href="/">Frissítés</a> vagy <a href="https://hu.aiseesoft.com/">Ugrás a hivatalos webhelyre</a>`,
        error_25301:`Hiba: 25301. Kérjük, lépjen kapcsolatba velünk`,
        error_25302:`Hiba: 25302. Kérjük, lépjen kapcsolatba velünk`,
        error_25303:`A kérés sikertelen (hibakód: 25303). Kérjük, próbálja meg újra`,
        error_25304:`A kérés sikertelen (hibakód: 25304). Kérjük, próbálja meg újra`,
        error_25305:`A kérés sikertelen (hibakód: 25305). Kérjük, próbálja meg újra`,
        error_25306:`A kérés sikertelen (hibakód: 25306). Kérjük, próbálja meg újra`,
        image_upscaler_p:`Kép vízjel letöltés nélkül:`,
        Available_for:`Elérhető:`,
        credit_per:`%s kredit HD-képenként`,
        still_valid:`A megvásárolt csomag(ok) továbbra is érvényesek`,
        credit:`hitel(ek)`,
        pc_3rd_info:`Sikeres bejelentkezés. Kérjük, lépjen az alkalmazáshoz a további műveletekhez.`,
        use_online:`Használja az online szolgáltatást`,
        use_download:`Használja az asztali programot`,
        use_immediately:`Azonnal használja`,
        Use_in_browser:`Használd a böngészőben`,
        win_desktop:`ablakok`,
        Mac_desktop:`Mac`,
    },
    it:{
        overtime_1:`Codice di errore:`,
        overtime_2:`, per favore riprova`,
        isnetwork:`Errore Internet. Si prega di controllare e riprovare`,
        email_placeholder:`E-mail`,
        email_empty:`Si prega di inserire l'e-mail`,
        email_not_valid:`L'email non è valida`,
        email_not_valid_1:`Per favore inserisci la tua email`,
        email_not_valid_2:`L'email non è valida, utilizza un indirizzo diverso.`,
        email_not_valid_3:`Nessun input e-mail`,
        password_placeholder:`Parola d'ordine`,
        password_empty:`Si prega di inserire la password`,
        password_not_valid:`Account o password errati`,
        password_not_valid_1:`È richiesta una password superiore a 8 caratteri`,
        password_not_valid_2:`Si prega di creare una password`,
        password_placeholder_1:`crea la tua password`,
        password_placeholder_2:`Conferma la tua password`,
        password_placeholder_3:`Crea nuova password`,
        password_placeholder_4:`Conferma la nuova password`,
        password_placeholder_5:`Inserisci la vecchia password`,
        copy_password_empty:`Si prega di confermare la password`,
        copy_password_not_valid:`Si prega di confermare la password`,
        copy_passwords_inconsistent:`La conferma della password non corrisponde`,
        code_empty:`Inserisci il codice di verifica`,
        code_not_valid:`Codice di verifica non valido`,
        code_placeholder:`Codice di verifica`,
        not_received_code:`Se la tua casella di posta non riceve il codice di verifica da molto tempo, richiedi di nuovo il codice di verifica.`,
        get_first_code:`Si prega di ottenere prima il codice di verifica.`,
        connect_your_email_placeholder:`Collega la tua email`,
        last_name_placeholder:`Per favore inserisci il tuo cognome`,
        first_name_placeholder:`Per favore immettere il proprio Nome`,
        address_placeholder:`Per favore inserisci il tuo indirizzo`,
        no_code_text:`Abbiamo inviato un codice di verifica. Inserisci il tuo codice. <span class='tips'>Non hai ricevuto il codice?`,
        no_code_text_1:`1. Assicurati che l'indirizzo e-mail sia valido e che possa ricevere e-mail.`,
        no_code_text_2:`2.Se l'e-mail viene inviata automaticamente dal sistema, potrebbe essere contrassegnata come spam o posta indesiderata. Si prega di verificare se l'e-mail si trova nella cartella Cestino.`,
        no_code_text_3:`3.Non riesci a risolvere il tuo problema?`,
        no_code_text_3_span:`Allora clicca qui per contattarci.`,
        order_no:`Non hai acquistato alcun prodotto. Per qualsiasi domanda, <a href="https://it.aiseesoft.com/store/">Acquista ora</a> oppure <a href="https://it.aiseesoft.com/contact.html"> Contattaci</a>`,
        error_24901:`L'account corrente non ha un indirizzo email collegato e non riesce a trovare gli ordini. Si prega di collegare un'e-mail.`,
        user_guide:`Guida per l'utente>>`,
        download:`Scarica`,
        order_number:`Numero d'ordine`,
        Refund:`Rimborso`,
        Disabled:`Disabilitato`,
        Normal:`Normale`,
        Modify:`Modificare`,
        Modify_1:`Modifica>>`,
        Connect:`Collegare`,
        unlink_success:`Scollega con successo`,
        connect_success:`Connetti con successo`,
        feedback_title:`Grazie per il tuo feedback. Per favore lascia il tuo problema e ti risponderemo entro 24 ore.`,
        feedback_thank_you:`Grazie!<br />Il tuo feedback è stato inviato con successo.`,
        feedback_email:`Inserisci la tua email qua!`,
        feedback_content:`Lascia qui qualsiasi problema o suggerimento che hai riscontrato.`,
        feedback_submit:`Invia`,
        form_contents:`Non hai inserito alcuna descrizione. Inseriscilo e invia di nuovo.`,
        old_password:`Si prega di inserire la vecchia password`,
        new_password:`Si prega di creare una nuova password`,
        old_new_password:`La nuova password non può essere uguale a quella vecchia`,
        incorrect_password:`password errata`,
        delete_no:`Cancella ora`,
        Caps:`Blocco maiuscolo attivo`,
        Get:`Ottenere`,
        Done:`Fatto`,
        error_20001:`Errore: 20001. Effettua nuovamente l'accesso.`,
        error_20002:`Errore: 20002. Effettua nuovamente l'accesso.`,
        error_20003:`Errore: 20003. Effettua nuovamente l'accesso.`,
        error_20004:`Richiesta non riuscita (codice errore: 20004). Si prega di riprovare.`,
        error_20005:`La sessione di accesso è scaduta (errore: 20005). Per favore esegui l'accesso di nuovo.`,
        error_20006:`Richiesta non riuscita (codice errore: 20006). Si prega di riprovare.`,
        error_20007:`La sessione di accesso è scaduta (Errore: 20007). Per favore esegui l'accesso di nuovo.`,
        error_20008:`La sessione di accesso è scaduta (Errore: 20008). Per favore esegui l'accesso di nuovo.`,
        error_20009:`La sessione di accesso è scaduta (Errore: 20009). Per favore esegui l'accesso di nuovo.`,
        error_20101:`Per favore inserisci la tua email (codice errore: 20101)`,
        error_20102:`L'e-mail non è valida (codice errore: 20102)`,
        error_20103:`Richiesta non riuscita (codice errore: 20103). Per favore riprova`,
        error_20104:`L'email è già in uso, per favore <a href="https://account.aiseesoft.com/login">accedi</a> o registrati con una nuova`,
        error_20105:`Richiesta non riuscita (codice errore: 20105). Per favore riprova`,
        error_20106:`Impossibile inviare l'e-mail, riprovare`,
        error_20201:`Inserisci la tua email (codice errore: 20201)`,
        error_20202:`Inserisci la tua password (codice di errore: 20202)`,
        error_20203:`Inserisci il codice di verifica (codice errore: 20203)`,
        error_20204:`Email non valida (codice errore: 20204)`,
        error_20205:`È richiesta una password superiore a 8 caratteri (codice errore: 20205)`,
        error_20206:`Richiesta non riuscita (codice errore: 20206). Per favore riprova`,
        error_20207:`Codice di verifica non valido`,
        error_20208:`Richiesta non riuscita (codice errore: 20208). Per favore riprova`,
        error_20209:`Richiesta non riuscita (codice errore: 20209). Per favore riprova`,
        error_20301:`Per favore inserisci la tua email (codice errore: 20301)`,
        error_20302:`Errore: 20302. Contattaci`,
        error_20303:`Email non valida (codice errore: 20303)`,
        error_20304:`Richiesta non riuscita (codice errore: 20304). Per favore riprova`,
        error_20305:`L'account non esiste. Si prega di reinserire o <a href="https://account.aiseesoft.com/register">Crealo</a> prima.`,
        error_20306:`Nessuna password ancora. Usa <a href="https://account.aiseesoft.com/passwordless-login">Accesso senza password</a> o <a href="create-password">imposta una password</a> e accedi.`,
        error_20308:`Richiesta non riuscita (codice errore: 20308). Per favore riprova`,
        error_20401:`Impossibile disconnettersi (codice errore: 20401). Per favore riprova`,
        error_20501:`Per favore inserisci la tua email (codice errore: 20501)`,
        error_20502:`Email non valida (codice errore: 20502)`,
        error_20503:`Richiesta non riuscita (codice errore: 20503). Per favore riprova`,
        error_20504:`Impossibile inviare l'e-mail. Si prega di riprovare.`,
        error_20601:`Per favore inserisci la tua email (codice errore: 20601)`,
        error_20602:`Inserisci il codice di verifica (codice errore: 20602)`,
        error_20603:`Email non valida (codice errore: 20603)`,
        error_20604:`Richiesta non riuscita (codice errore: 20604). Per favore riprova`,
        error_20606:`Richiesta non riuscita (codice errore: 20606). Per favore riprova`,
        error_20607:`Richiesta non riuscita (codice errore: 20607). Per favore riprova`,
        error_20608:`Richiesta non riuscita (codice errore: 20608). Per favore riprova`,
        error_20701:`Per favore inserisci la tua email (codice errore: 20701)`,
        error_20702:`Email non valida (codice errore: 20702)`,
        error_20703:`Richiesta non riuscita (codice errore: 20703). Per favore riprova`,
        error_20704:`L'account non esiste. Si prega di reinserire o <a href="https://account.aiseesoft.com/register">Crealo</a> prima.`,
        error_20705:`Richiesta non riuscita (codice errore: 20705). Per favore riprova`,
        error_20706:`Impossibile inviare l'e-mail. Per favore riprova`,
        error_20801:`Per favore inserisci la tua email (codice errore: 20801)`,
        error_20802:`Errore: 20802. Contattaci`,
        error_20803:`Inserisci il codice di verifica (codice errore: 20803)`,
        error_20804:`Email non valida (codice errore: 20804)`,
        error_20805:`È richiesta una password superiore a 8 caratteri (codice errore: 20805)`,
        error_20806:`Richiesta non riuscita (codice errore: 20806). Per favore riprova`,
        error_20808:`Richiesta non riuscita (codice errore: 20808). Per favore riprova`,
        error_20901:`Richiesta non riuscita (codice errore: 20901). Per favore riprova`,
        error_20902:`Richiesta non riuscita (codice errore: 20902). Per favore riprova`,
        error_21000:`Le modifiche vengono salvate`,
        error_21001:`Nessuna informazione inviata (codice errore: 21001)`,
        error_21002:`Richiesta non riuscita (codice errore: 21002). Per favore riprova`,
        error_21101:`Per favore inserisci la tua email (codice errore: 21101)`,
        error_21102:`Email non valida (codice errore: 21102)`,
        error_21103:`Richiesta non riuscita (codice errore: 21103), riprovare di nuovo`,
        error_21104:`L'email è già collegata, usane una nuova`,
        error_21105:`Richiesta non riuscita (codice errore: 21105), riprovare di nuovo`,
        error_21106:`Impossibile inviare l'e-mail. Per favore riprova`,
        error_21201:`Per favore inserisci la tua email (codice errore: 21201)`,
        error_21202:`Inserisci il codice di verifica (codice errore: 21202)`,
        error_21203:`Email non valida (codice errore: 21203)`,
        error_21204:`Errore: 21204. Contattaci`,
        error_21205:`Errore: 21205. Contattaci`,
        error_21206:`È richiesta una password superiore a 8 caratteri (codice errore: 21206)`,
        error_21207:`Richiesta non riuscita (codice errore: 21207). Per favore riprova`,
        error_21209:`Richiesta non riuscita (codice errore: 21209). Per favore riprova`,
        error_21301:`Si prega di inserire la vecchia password (codice di errore: 21301)`,
        error_21302:`Si prega di creare una nuova password (codice di errore: 21302)`,
        error_21303:`La nuova password non può essere uguale a quella vecchia. (Errore: 21303)`,
        error_21304:`È richiesta una password superiore a 8 caratteri (codice errore: 21304)`,
        error_21306:`Richiesta non riuscita (codice errore: 21306). Per favore riprova`,
        error_21402:`Richiesta non riuscita (codice errore: 21402). Per favore riprova`,
        error_21403:`Impossibile inviare il codice di verifica. Per favore invialo di nuovo`,
        error_21500:`L'account è stato cancellato`,
        error_21501:`Inserisci il codice di verifica (codice errore: 21501)`,
        error_21502:`La sessione di accesso è scaduta (Errore: 21502). Per favore esegui l'accesso di nuovo.`,
        error_21503:`Richiesta non riuscita (codice errore: 21503). Per favore riprova`,
        error_21505:`Richiesta non riuscita (codice errore: 21505), riprovare`,
        error_21601:`Errore: 20601. Contattaci`,
        error_21602:`Verifica non valida (errore: 20602). Si prega di riprovare.`,
        error_21603:`Errore: 20603. Riprova`,
        error_21604:`Richiesta non riuscita (codice errore: 21604). Per favore riprova`,
        error_21606:`Richiesta non riuscita (codice errore: 21606). Per favore riprova`,
        error_21611:`Richiesta non riuscita (codice errore: 21611). Per favore riprova`,
        error_21801:`Errore: 21801. Contattaci`,
        error_21802:`Richiesta non riuscita (errore: 21802). Per favore riprova`,
        error_21803:`Errore: 21803. Riprova`,
        error_21804:`Richiesta non riuscita (codice errore: 21804). Per favore riprova`,
        error_21806:`Errore: 21806. Riprova`,
        error_21807:`Errore: 21807. Contattaci`,
        error_21808:`Errore: 21808. Contattaci`,
        error_21809:`Errore: 21809. Contattaci`,
        error_21810:`Errore: 21810. Contattaci`,
        error_21811:`Errore: 21811. Contattaci`,
        error_21812:`Errore: 21812. Contattaci`,
        error_21813:`Richiesta non riuscita (codice errore: 21813). Per favore riprova`,
        error_21814:`Errore: 21814. Contattaci`,
        error_21815:`Richiesta non riuscita (codice errore: 21815). Per favore riprova`,
        error_21816:`Errore: 21816. Contattaci`,
        error_21817:`Errore: 21817. Contattaci`,
        error_21818:`Errore: 21818. Contattaci`,
        error_21819:`Richiesta non riuscita (codice errore: 21819). Per favore riprova`,
        error_21820:`Errore: 21820. Contattaci`,
        error_21821:`Errore: 21821. Contattaci`,
        error_21822:`Errore: 21822. Contattaci`,
        error_21823:`Richiesta non riuscita (codice errore: 21823). Per favore riprova`,
        error_21824:`Richiesta non riuscita (codice errore: 21824). Per favore riprova`,
        error_21825:`Richiesta non riuscita (codice errore: 21825). Per favore riprova`,
        error_21826:`Richiesta non riuscita (codice errore: 21826). Per favore riprova`,
        error_21828:`Errore: 21828. Contattaci`,
        error_21901:`Errore: 21901. Contattaci`,
        error_21902:`Richiesta non riuscita (codice errore: 21902). Per favore riprova`,
        error_21903:`Lo stato dell'account è cambiato (codice errore: 21903), aggiorna la pagina e riprova`,
        error_21904:`Errore: 21904. Riprova`,
        error_21905:`Errore: 21905. Riprova`,
        error_21906:`Richiesta non riuscita (codice errore: 21906). Per favore riprova`,
        error_21907:`L'account Google è stato collegato ad un altro account`,
        error_21908:`Richiesta non riuscita (codice errore: 21908). Per favore riprova`,
        error_22001:`Richiesta non riuscita (codice errore: 22001). Per favore riprova`,
        error_22002:`Scollegamento non riuscito senza accesso aggiuntivo`,
        error_22003:`Richiesta non riuscita (codice errore: 22003). Per favore riprova`,
        error_22101:`Errore: 22101. Contattaci`,
        error_22102:`Lo stato dell'account è cambiato (codice errore: 22102), aggiorna la pagina e riprova`,
        error_22103:`Richiesta non riuscita (codice errore: 22103). Per favore riprova`,
        error_22104:`Lo stato dell'account è cambiato (codice errore: 22104), aggiorna la pagina e riprova`,
        error_22105:`Errore: 22105. Riprova`,
        error_22106:`Errore: 22106. Riprova`,
        error_22107:`Errore: 22107. Contattaci`,
        error_22108:`Richiesta non riuscita (codice errore: 22108). Per favore riprova`,
        error_22201:`Errore: 22201. Contattaci`,
        error_22202:`Verifica non valida (errore: 22202). Si prega di riprovare.`,
        error_22203:`Errore: 22203. Riprova"`,
        error_22204:`Richiesta non riuscita (codice errore: 22204). Per favore riprova`,
        error_22206:`Richiesta non riuscita (codice errore: 22206). Per favore riprova`,
        error_22401:`Errore: 22401. Contattaci`,
        error_22402:`Verifica non valida (errore: 22402). Si prega di riprovare.`,
        error_22403:`Errore: 22403. Riprova`,
        error_22404:`Richiesta non riuscita (codice errore: 22404). Per favore riprova`,
        error_22405:`L'account Facebook è stato collegato ad altre email`,
        error_22406:`Errore: 22406. Riprova`,
        error_22407:`Errore: 22407. Contattaci`,
        error_22408:`Errore: 22408. Contattaci`,
        error_22409:`Errore: 22409. Contattaci`,
        error_22410:`Errore: 224010. Contattaci`,
        error_22411:`Errore: 224011. Contattaci`,
        error_22412:`Errore: 224012. Contattaci`,
        error_22413:`Errore: 22413. Contattaci`,
        error_22414:`Richiesta non riuscita (codice errore: 22414). Per favore riprova`,
        error_22415:`Errore: 22415. Contattaci`,
        error_22416:`Errore: 22416. Contattaci`,
        error_22417:`Errore: 22417. Contattaci`,
        error_22418:`Richiesta non riuscita (codice errore: 22418). Per favore riprova`,
        error_22419:`Errore: 22419. Contattaci`,
        error_22420:`Errore: 22420. Contattaci`,
        error_22421:`Errore: 22421. Contattaci`,
        error_22422:`Richiesta non riuscita (codice errore: 22422). Per favore riprova`,
        error_22423:`Richiesta non riuscita (codice errore: 22423). Per favore riprova`,
        error_22424:`Richiesta non riuscita (codice errore: 22424). Per favore riprova`,
        error_22425:`Richiesta non riuscita (codice errore: 22425). Per favore riprova`,
        error_20098:`Codice di errore: 20098. Se stai navigando in modalità Privata, passa alla modalità Normale e riprova.`,
        error_22298:`Richiesta per Google non riuscita (errore: 22298). Si prega di riprovare.`,
        error_22498:`Richiesta per Facebook non riuscita (Errore: 22498). Si prega di riprovare.`,
        error_24902:`Richiesta non riuscita (codice errore: 24902). Per favore riprova`,
        error_24903:`Richiesta non riuscita (codice errore: 24903). Per favore riprova`,
        error_24904:`Richiesta non riuscita (codice errore: 24904). Per favore riprova`,
        error_24905:`Richiesta non riuscita (codice errore: 24905). Per favore riprova`,
        login_title:`Accedi ad Aiseesoft`,
        password_placeholder:`Parola d'ordine`,
        log_in:`Accesso`,
        no_account:`Nessun conto?`,
        create_it:`Crealo`,
        or_log_in_with:`Oppure accedi con`,
        passwordless_login:`Accesso senza password`,
        log_in_done:`Accedi Fatto`,
        three_rd_account_connect_info:`Congratulazioni! Hai effettuato l'accesso con successo. Ora puoi connettere un account e-mail che verrà utilizzato per accedere in futuro.`,
        connect_now:`Connettiti ora`,
        see_my_account:`Vedi il mio account`,
        three_rd_login_merge_account:`L'indirizzo email dell'account di terze parti è stato registrato, vuoi connetterti e accedere direttamente con questo indirizzo email?`,
        connect_log_in:`Connettiti e accedi`,
        create_an_account:`Creare un account`,
        connect_account:`Connetti account`,
        connect_account_info:`Dopo la connessione, puoi accedere con questo account.`,
        back_to_log_in:`Torna al login`,
        create_password:`Crea password`,
        create_now:`Crea ora`,
        password_login_subtitle:`Accesso senza password con e-mail`,
        account_login:`Account login`,
        rights:`Creando questo account, accetti i <a href="https://it.aiseesoft.com/company/terms-and-conditions.html">Termini di servizio</a> e <a href="https://it.aiseesoft.com/privacy-policy.html">Informativa sulla privacy</a>`,
        passwordless_login_done:`Accesso senza password effettuato`,
        passwordless_login_info:`Congratulazioni, hai completato con successo l'accesso senza password. Puoi creare una password per questo account e accedere con l'account e la password in futuro. <a href="/create-password" style="display: initial;">Crea ora</a>`,
        sign_up:`Iscriviti`,
        register_info:`crea il tuo account`,
        reset_now:`Ripristina ora`,
        forgot_password:`Ha dimenticato la password`,
        reset_password_subtitle:`Usa l'email del tuo account per reimpostare la password`,
        plan_products:`Piano e prodotti`,
        nick_name:`Nome utente`,
        email:`E-mail`,
        my_products:`I miei prodotti`,
        my_orders:`I miei ordini`,
        unlink:`Scollega`,
        link:`Collegamento`,
        connected_accounts:`Account collegati`,
        last_name:`Cognome`,
        first_name:`nome di battesimo`,
        Gender:`Genere`,
        Birth:`Nascita`,
        Month:`Mese`,
        Year:`Anno`,
        Country_Region:`Paese/regione`,
        Address:`Indirizzo`,
        Save:`Salva`,
        Date:`Data`,
        Male:`Maschio`,
        Female:`Femmina`,
        Unspecified:`Non specificato`,
        Security:`Sicurezza`,
        change_password:`Cambia la password`,
        change_now:`Cambia ora`,
        connect_email:`Connetti e-mail`,
        delete_account:`Eliminare l'account`,
        delete_account_info:`Quando il tuo account viene eliminato, tutti i dati in Piano e prodotti associati al tuo account verranno eliminati in modo permanente e potresti non essere in grado di recuperarli. Ti consigliamo di procedere con cautela.`,
        Delete:`Elimina`,
        Logout:`Disconnettersi`,
        my_profile:`Il mio profilo`,
        guides_faqs:`Guide e FAQ`,
        More:`Di più`,
        guides:`Guide`,
        register:`Registrati`,
        hot_faq:`Domande frequenti`,
        Contents:`Contenuti:`,
        contact_us:`Contattaci>>`,
        plan:`Piano`,
        unregistered:`Non registrato`,
        buy_more:`Comprare di più`,
        buy_again:`Acquista di nuovo`,
        buy_now:`Acquista ora`,
        free_no_limit:`Gratuito e senza limiti`,
        expired:`Scaduto`,
        lifetime:`Tutta la vita`,
        remain:`Rimanere`,
        day_s:`Giorno/i`,
        error_24801:`Richiesta non riuscita (codice errore: 24801). Per favore riprova`,
        no_app_found:`Nessuna app trovata！<a href="/">Aggiorna</a> o <a href="https://it.aiseesoft.com/">Vai al sito web ufficiale</a>`,
        get_more:`Ottieni di più >>`,
        edit_photo:`Modifica foto`,
        select_photo:`Seleziona foto`,
        change_photo:`Cambia foto`,
        cancel:`Annulla`,
        hide_password:`Nascondi password`,
        show_password:`Mostra password`,
        zoom_in:`Ingrandire`,
        zoom_out:`Rimpicciolisci`,
        rotate:`Ruotare`,
        horizontal_flip:`Capovolgimento orizzontale`,
        vertical_flip:`Capovolgimento verticale`,
        country:`Nazione`,
        country_1:`Seleziona il tuo paese/regione`,
        country_2:`isola di aland`,
        country_3:`Afghanistan`,
        country_4:`Albania`,
        country_5:`Algeria`,
        country_6:`Samoa americane`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antartide`,
        country_11:`Antigua e Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaigian`,
        country_18:`Bahrein`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Bielorussia`,
        country_22:`Belgio`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia ed Erzegovina`,
        country_29:`Botswana`,
        country_30:`Isola Bouvet`,
        country_31:`Brasile`,
        country_32:`Territorio britannico dell'Oceano Indiano`,
        country_33:`Isole Vergini Britanniche`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambogia`,
        country_39:`Camerun`,
        country_40:`Canada`,
        country_41:`capo Verde`,
        country_42:`Caraibi Paesi Bassi`,
        country_43:`Isole Cayman`,
        country_44:`Repubblica Centrafricana`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`Cina`,
        country_48:`Isola di Natale`,
        country_49:`Isole Cocos (Keeling)`,
        country_50:`Colombia`,
        country_51:`Isole Cook`,
        country_52:`Costa Rica`,
        country_53:`"Costa d'Avorio"`,
        country_54:`Croazia`,
        country_55:`Cuba`,
        country_56:`Cipro`,
        country_57:`Repubblica Ceca`,
        country_58:`Repubblica Democratica del Congo`,
        country_59:`Danimarca`,
        country_60:`Gibuti`,
        country_61:`dominica`,
        country_62:`Repubblica Dominicana`,
        country_63:`Ecuador`,
        country_64:`Egitto`,
        country_65:`El Salvador`,
        country_66:`Guinea Equatoriale`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Etiopia`,
        country_70:`Isole Falkland`,
        country_71:`Isole Faroe`,
        country_72:`Stati Federati di Micronesia`,
        country_73:`Figi`,
        country_74:`Finlandia`,
        country_75:`Francia`,
        country_76:`Guiana francese`,
        country_77:`Polinesia francese`,
        country_78:`Territori della Francia del sud`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Germania`,
        country_83:`Ghana`,
        country_84:`Gibilterra`,
        country_85:`Gran Bretagna (Regno Unito; Inghilterra)`,
        country_86:`Grecia`,
        country_87:`Groenlandia`,
        country_88:`Grenada`,
        country_89:`Guadalupa`,
        country_90:`Guami`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Isole Heard e McDonald`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Ungheria`,
        country_101:`Islanda`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Iraq`,
        country_106:`Irlanda`,
        country_107:`Isola di Man`,
        country_108:`Israele`,
        country_109:`Italia`,
        country_110:`Giamaica`,
        country_111:`Giappone`,
        country_112:`maglia`,
        country_113:`Giordania`,
        country_114:`Kazakistan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirghizistan`,
        country_119:`Laos`,
        country_120:`Lettonia`,
        country_121:`Libano`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libia`,
        country_125:`Liechtenstein`,
        country_126:`Lituania`,
        country_127:`Lussemburgo`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldive`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Isole Marshall`,
        country_136:`Martinica`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Messico`,
        country_141:`Moldavia`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marocco`,
        country_147:`Mozambico`,
        country_148:`Birmania (Birmania)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Olanda`,
        country_153:`Nuova Caledonia`,
        country_154:`Nuova Zelanda`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Isola Norfolk`,
        country_160:`Corea del nord`,
        country_161:`Isole Marianne settentrionali`,
        country_162:`Norvegia`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Territori palestinesi`,
        country_167:`Panama`,
        country_168:`Papua Nuova Guinea`,
        country_169:`Paraguay`,
        country_170:`Perù`,
        country_171:`Isole Pitcairn`,
        country_172:`Polonia`,
        country_173:`Portogallo`,
        country_174:`Porto Rico`,
        country_175:`Qatar`,
        country_176:`Repubblica di Macedonia (FYROM)`,
        country_177:`Repubblica del Congo`,
        country_178:`Riunione`,
        country_179:`Romania`,
        country_180:`Federazione Russa`,
        country_181:`Ruanda`,
        country_182:`San Bartolomeo`,
        country_183:`Saint Martin (Francia)`,
        country_184:`Saint-Pierre e Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tomé e Principe`,
        country_188:`Arabia Saudita`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovacchia`,
        country_195:`Slovenia`,
        country_196:`Isole Salomone`,
        country_197:`Somalia`,
        country_198:`Sud Africa`,
        country_199:`Georgia del Sud e Isola Sandwich Meridionale`,
        country_200:`Corea del Sud`,
        country_201:`Sudan del Sud`,
        country_202:`Spagna`,
        country_203:`Sri Lanka`,
        country_204:`Sant'Elena e dipendenze`,
        country_205:`St. Kitts e Nevis`,
        country_206:`Santa Lucia`,
        country_207:`St. Vincent e Grenadine`,
        country_208:`Sudan`,
        country_209:`Suriname`,
        country_210:`Swaziland`,
        country_211:`Svezia`,
        country_212:`Svizzera`,
        country_213:`Siria`,
        country_214:`Taiwan`,
        country_215:`Tagikistan`,
        country_216:`Tanzania`,
        country_217:`Modello:Dati nazionali SJM Svalbard`,
        country_218:`Tailandia`,
        country_219:`Le Bahamas`,
        country_220:`Le Comore`,
        country_221:`Le Filippine`,
        country_222:`Timor Est (Timor Est)`,
        country_223:`Andare`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunisia`,
        country_228:`tacchino`,
        country_229:`Turkmenistan`,
        country_230:`Isole Turks e Caicos`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ucraina`,
        country_234:`Emirati Arabi Uniti`,
        country_235:`Isole Minori Esterne degli Stati Uniti`,
        country_236:`Stati Uniti d'America (USA)`,
        country_237:`Isole Vergini americane`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Città del Vaticano (Santa Sede)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis e Futuna`,
        country_245:`Sahara occidentale`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Accedi con Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Tutti i diritti riservati.`,
        State:`Stato`,
        Activation_code:`Codice di attivazione`,
        Question:`Elenca tutte le app a cui hai effettuato l'accesso`,
        Copy_complete:`Copia completata`,

        store:`Negozio`,
        resource:`Risorsa`,
        support:`sostegno`,
        video_converter:`Convertitore video`,
        multimedia:`Multimedia`,
        data_recovery:`Recupero dati`,
        utility:`Utilità`,
        free_tools:`Strumenti gratuiti`,
        for_windows:`Per Windows`,
        for_mac:`Per Mac`,
        pdf_solutions:`Soluzioni PDF`,
        data_manager:`Gestore dati`,
        other_tools:`Altri strumenti`,
        free_online_solutions:`Soluzioni online gratuite`,
        free_online_tools:`Strumenti online gratuiti`,
        desktop_freeware:`Software gratuito per desktop`,
        product_1:`Video Converter Ultimate`,
        product_2:`Convertitore video totale`,
        product_3:`Convertitore MTS`,
        product_4:`Convertitore 4K`,
        product_5:`Convertitore MXF`,
        product_6:`Convertitore 3D`,
        product_7:`Convertitore video Mac Ultimate`,
        product_8:`Convertitore video per Mac`,
        product_9:`Convertitore MTS per Mac`,
        product_10:`Convertitore 4K per Mac`,
        product_11:`Convertitore MXF per Mac`,
        product_12:`Convertitore 3D per Mac`,
        product_13:`Lettore Blu-ray`,
        product_14:`Miglioratore video`,
        product_15:`Editor video`,
        product_16:`Creatore di presentazioni`,
        product_17:`Creatore Blu-ray`,
        product_18:`Burnova - Creatore di DVD Pro`,
        product_19:`Creatore di DVD`,
        product_20:`Lettore Blu-ray Mac`,
        product_21:`Ottimizzatore video per Mac`,
        product_22:`Editor video per Mac`,
        product_23:`Creatore di DVD per Mac`,
        product_24:`Convertitore MP4 per Mac`,
        product_25:`Recupero dati di Windows`,
        product_26:`Recupero dati iPhone FoneLab`,
        product_27:`FoneLab Ripristino del sistema iOS`,
        product_28:`FoneLab iOS Backup dei dati e amp; Ristabilire`,
        product_29:`FoneLab Recupero dati Android`,
        product_30:`Estrazione dati Android non funzionante`,
        product_31:`Backup dei dati Android e Ristabilire`,
        product_32:`Recupero Dati Mac`,
        product_33:`Recupero dati iPhone Mac FoneLab`,
        product_34:`Ripristino del sistema iOS Mac FoneLab`,
        product_35:`Backup dei dati iOS Mac FoneLab e amp; Ristabilire`,
        product_36:`Recupero dati Android Mac FoneLab`,
        product_37:`Backup e backup dei dati per Mac Android Ristabilire`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate per Mac`,
        product_40:`Fusione PDF`,
        product_41:`Convertitore da PDF a ePub per Mac`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`Trasferimento WhatsApp per iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Detergente per iPhone`,
        product_48:`Convertitore da PPT a video`,
        product_49:`Convertitore HEIC`,
        product_50:`Sblocco iPhone`,
        product_51:`Convertitore video gratuito online`,
        product_52:`Convertitore audio gratuito online`,
        product_53:`Compressore video gratuito online`,
        product_54:`Fusione video gratuita online`,
        product_55:`Convertitore HEIC gratuito`,
        product_56:`Compressore di immagini gratuito`,
        product_57:`Rimozione gratuita di filigrane`,
        product_58:`Compressore PDF gratuito online`,
        product_59:`Upscaler di immagini gratuito online`,
        product_60:`Rimozione dello sfondo gratuita online`,
        product_61:`Convertitore di immagini gratuito online`,
        product_62:`Convertitore PDF PNG gratuito online`,
        product_63:`Convertitore video gratuito`,
        product_64:`Convertitore gratuito da video a GIF`,
        product_65:`Recupero dati Android gratuito`,
        product_66:`Creatore di presentazioni`,
        product_67:`Editor video gratuito`,
        product_68:`Visualizzatore PDF gratuito`,
        product_69:`Registratore dello schermo`,
        product_70:`Registratore dello schermo per Mac`,
        product_71:`Registratore dello schermo gratuito in linea`,
        product_72:`Registratore audio gratuito online`,
        product_73:`Specchio del telefono`,
        product_74:`AnyCoord - Cambio di posizione`,
        product_75:`Gestore di password dell'iPhone`,
        product_76:`Compressore audio online gratuito`,
        product_77:`Rifinitore video gratuito online`,
        product_78:`Ritaglia video online gratuito`,
        product_79:`Rotatore video gratuito online`,
        product_80:`Video Looper online gratuito`,
        product_81:`Convertitore PDF JPG gratuito online`,
        product_81:`Convertitore gratuito di parole JPG online`,
        category_1:`Fotoritocco`,
        category_2:`Montaggio video`,
        category_3:`Gomma`,
        category_4:`Registratore multimediale`,
        posted_by:`Pubblicato da`,
        to:`a`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Come rimuovere il testo dalle immagini in modo efficiente`,
        article_2:`Come aumentare la dimensione dell'immagine da KB a MB senza danneggiare la qualità`,
        article_3:`Come rimuovere la filigrana Getty Images su Windows e Mac`,
        article_4:`Il metodo migliore per convertire gratuitamente un'immagine a bassa risoluzione in alta risoluzione online`,
        article_5:`Guida TikTok | Ecco come creare un collage video su TikTok`,
        article_6:`5 semplici modi per combinare file VOB che dovresti conoscere`,
        article_7:`Come migliorare i video e ottenere facilmente una risoluzione più alta`,
        article_8:`Come combinare file MP4 su MacBook Pro/iMac/MacBook e altro`,
        article_9:`2 semplici modi per eliminare la cronologia degli acquisti su iPhone [Risolto]`,
        article_10:`Come eliminare definitivamente le immagini di Snapchat da chat e ricordi`,
        article_11:`Come eliminare i suggerimenti e la cronologia di ricerca Spotlight su iPhone/iPad`,
        article_12:`[Risolto] Come cancellare la cache di Instagram su iPhone con 3 semplici modi`,
        article_13:`[Risolto] Come risolvere QuickTime Player che non funziona su Mac`,
        article_14:`Come registrare l'audio di sistema su Mac (Big Sur/Catalina/Mojave...)`,
        article_15:`Modi efficaci per correggere la registrazione dello schermo di PowerPoint che non funziona`,
        article_16:`Come registrare l'audio con VLC [Risolto]`,
        faq_title_1:`1. Come correggere l'errore 4104?`,
        faq_title_2:`2. Quando posso riavere i miei soldi dopo che la richiesta di rimborso è stata approvata?`,
        faq_title_3:`3. Che cos'è "Abbonamento 1 anno" e come disattivare il "Servizio di rinnovo automatico"?`,
        faq_title_4:`4. Posso cancellare il mio ordine dopo aver inserito il prodotto Aiseesoft?`,
        faq_title_5:`5. Come inviarti una recensione?`,
        faq_info_1:`Ciò è dovuto alla licenza limitata che hai. Adottiamo Licenza Unica in caso di furto del codice di registrazione e preveniamo la violazione dell'uso del codice di registrazione. Quindi il codice di registrazione che hai è una licenza singola che può essere utilizzata solo su un computer. Se lo utilizzi su più di un computer, verrà visualizzato l'errore 4104. Non potrai utilizzarlo finché non verrà ripristinato per te.`,
        faq_info_2:`Se cambi o reinstalli il computer, possiamo ripristinarlo per te. Contatta il servizio clienti: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> per richiedere la reimpostazione del codice di registrazione per te.`,
        faq_info_3:`Una volta che la tua richiesta di rimborso è stata approvata, normalmente ci vorranno 3~7 giorni lavorativi prima che il credito appaia sul tuo account. A volte durante le vacanze, potrebbe essere necessario più tempo, ma non si prevede che impiegheranno più di 10 giorni. Se non hai ricevuto il rimborso 20 giorni dopo l'approvazione, contattaci all'indirizzo <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> per verificare il rimborso.`,
        faq_info_4:`Servizio di rinnovo automatico significa che se acquisti un abbonamento di 1 anno, la piattaforma di pagamento addebiterà automaticamente l'importo (incluse le tasse applicabili) alla scadenza della licenza del prodotto. Ma il modulo di pagamento (Swreg o 2Checkout) ti invierà una lettera per ricordarti che l'ordine scadrà 7 giorni prima della scadenza. L'e-mail conterrà anche le istruzioni su come disattivare il "Servizio di rinnovo automatico".`,
        faq_info_5:`Poiché l'ordine viene elaborato automaticamente, non è consentito annullare l'ordine. Inoltre, la versione di prova gratuita ti consentirà di saperne di più sul programma Aiseesoft e di verificare se può soddisfare le tue esigenze prima di acquistarlo.`,
        faq_info_6:`Se desideri davvero ottenere un rimborso, consulta la nostra <a href="https://it.aiseesoft.com/refund-policy.html">Norme sui rimborsi</a>.`,
        faq_info_7:`Apprezziamo il tuo tempo e la tua attenzione per inviarci una recensione. Contattaci (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) per inviare la tua recensione e prenderemo in seria considerazione la tua recensione per gli aggiornamenti dei nostri prodotti.`,
        change_password_success:`password cambiata con successo`,
        successful_login_title:`Accesso riuscito`,
        product_page:`Pagina del prodotto>>`,
        successful_login_info:`Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con il seguente processo. La pagina corrente verrà chiusa automaticamente entro 5 secondi. Se la chiusura automatica o la chiusura facendo clic sul pulsante "Fine" non riesce, chiudere direttamente questa scheda.`,
        successful_login_info_firefox:`Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con il seguente processo.`,
        my_account:`Il mio account`,
        my_history:`La mia storia`,
        remove_watermark:`Rimuovi filigrana`,
        no_history:`Nessuna storia`,
        history_all:`Seleziona tutto`,
        history_open:`Aprire`,
        history_down:`Scarica`,
        history_delete:`Eliminare`,
        history_clear:`Cancella non valido`,
        images:`immagini)`,
        use_this_function:`Usa questa funzione>>`,
        hd_downloading:`Vantaggi del download di immagini originali HD:`,
        lifetimeRemaining:`Rimanente a vita`,
        Remaining:`Residuo`,
        email_verification:`verifica email`,
        email_verification_info:`Abbiamo inviato il messaggio di verifica alla tua e-mail <span class="email"></span> e completa la verifica. Dopo la verifica, i vantaggi verranno sincronizzati automaticamente.`,
        wrong_email:`Indirizzo email sbagliato?`,
        click_here_to_modify:`Clicca qui per modificare`,
        get_more_help:`Ottenere più aiuto?`,
        click_here:`Clicca qui`,
        email_verification_info_success:`Congratulazioni per aver verificato il tuo account di posta elettronica.`,
        email_verification_info_error:`Verifica non riuscita a causa del collegamento scaduto.`,
        registration_succeeded:`Registrazione riuscita`,
        registration_succeeded_info_1:`Congratulazioni! Hai completato la registrazione con successo. Abbiamo inviato il messaggio di verifica alla tua email <span class="email"></span> e completa la verifica per utilizzare i vantaggi di questa email.`,
        registration_succeeded_info_2:`Fare clic su "Fatto" per tornare alla home page e utilizzare questo prodotto.`,
        registration_succeeded_info_3:`Fare clic su "Fatto" per chiudere la pagina corrente e tornare alla Home page per le seguenti operazioni. Se la chiusura non riesce, chiudere manualmente la scheda.`,        
        verify_email:`Verifica Email`,
        registered_email_not_verified:`L'e-mail registrata non è stata verificata, verificala immediatamente.`,
        email_verification_time_1:`Non hai ricevuto un'email di verifica?`,
        email_verification_time_2:`A seguito di`,
        email_verification_time_3:`Clicca qui per inviarlo nuovamente`,
        error_26301:`Codice di errore: 26301, contattaci`,
        error_26302:`Codice di errore: 26302, contattaci`,
        error_26303:`Errore nel formato dell'e-mail (codice di errore: 26303). Si prega di inserirlo di nuovo`,
        error_26304:`Si consiglia una password superiore a 8 caratteri (codice di errore: 26304)`,
        error_26305:`Reuqest non riuscito (codice di errore: 26305). Si prega di riprovare`,
        error_26306:`L'email è stata registrata, per favore <a href="https://account.aiseesoft.com/login">accedi</a>`,
        error_26307:`Reuqest non riuscito (codice di errore: 26307). Si prega di riprovare`,
        error_26308:`Reuqest non riuscito (codice di errore: 26308). Si prega di riprovare`,
        error_26401:`Codice di errore: 26401, riprovare`,
        error_26402:`L'email è stata verificata (codice di errore: 26402), riprova`,
        error_26403:`Reuqest non riuscito (codice di errore: 26403). Si prega di riprovare`,
        error_26404:`Richiesta non riuscita (codice errore: 26404). Si prega di riprovare`,
        error_26501:`Codice di errore: 26501, contattaci`,
        error_26502:`Codice di errore: 26502, contattaci`,
        error_26503:`Errore formato email (codice errore: 26503). Si prega di inserirlo di nuovo`,
        error_26504:`Reuqest non riuscito (codice di errore: 26504). Si prega di riprovare`,
        error_26505:`L'email non è stata registrata, per favore <a href="https://account.aiseesoft.com/register">registrala prima</a>`,
        error_26506:`L'e-mail è stata verificata.`,
        error_26507:`Reuqest non riuscito (codice di errore: 26507). Si prega di riprovare`,
        error_26508:`Verifica fallita (codice di errore: 26508), riprova`,
        error_26509:`Richiesta non riuscita (codice di errore: 26509), riprovare`,
        error_26510:`Codice di errore: 26510, contattaci`,
        error_26601:`Codice di errore: 26601, contattaci`,
        error_26602:`Codice di errore: 26602, contattaci`,
        error_26603:`Reuqest non riuscito (codice di errore: 26603). Si prega di riprovare`,
        error_26604:`Codice di errore: 26604, contattaci`,
        error_26605:`Codice di errore: 26605, contattaci`,
        error_26701:`Codice di errore: 26701, contattaci`,
        error_26702:`Reuqest non riuscito (codice di errore: 26702). Si prega di riprovare`,
        error_26703:`Codice di errore: 26703, contattaci`,
        error_26704:`Codice di errore: 26704, contattaci`,
        error_26705:`Attendi l'accesso (codice di errore: 26705). Si prega di riprovare`,
        no_cookie:`Hai attivato la funzione Blocca tutti i cookie nel tuo browser, quindi non puoi accedere. Vai su Impostazioni per selezionare la casella di Consenti tutti i cookie.`,
        error_26801:`Codice di errore: 26801, contattaci`,
        error_26802:`Codice di errore: 26802, contattaci`,
        error_26803:`Richiesta non riuscita (codice errore: 26803). Si prega di riprovare`,
        error_26804:`Richiesta non riuscita (codice errore: 26804). Si prega di riprovare`,
        error_order:`Richiesta non riuscita (codice di errore: 27098), riprova!`,
        error_order1:`La query dell'ordine è incompleta（codice di errore:`,
        error_order2:`）, aggiorna e riprova.`,
        modify_email_title:`Modifica e-mail`,
        modify_email_info:`Puoi utilizzare l'e-mail modificata per accedere al tuo account.`,
        images_per:`Immagini per`,
        error_26101:`Errore: 26101. Contattaci`,
        error_26102:`Errore: 26102. Contattaci`,
        error_26103:`Richiesta non riuscita (codice di errore:26103). Per favore, riprova`,
        error_26104:`Codice di errore: 26104, riprovare`,
        error_26105:`Codice di errore: 26105, riprovare`,
        error_26106:`Eliminazione non riuscita (codice di errore: 26106). Si prega di riprovare`,
        error_26201:`Errore: 26201. Contattaci`,
        error_26202:`Richiesta non riuscita (codice di errore:26202). Per favore, riprova`,
        error_26001:`Errore: 26001. Contattaci`,
        error_26002:`Errore: 26002. Contattaci`,
        error_26003:`Errore: 26003. Contattaci`,
        error_26004:`Errore: 26004. Contattaci`,
        error_26005:`Richiesta non riuscita (codice di errore:26005). Per favore, riprova`,
        error_26006:`Codice di errore: 26006, riprovare`,
        error_26008:`Errore: 26008. Contattaci`,
        go_to_the_home_page:`Vai alla home page`,
        error_27101:`Richiesta non riuscita (codice errore: 27101). Si prega di riprovare ist`,
        error_27201:`Codice di errore: 27201, contattaci`,
        error_27202:`Codice di errore: 27202, riprovare`,
        error_27203:`Richiesta non riuscita (codice errore: 27203). Si prega di riprovare`,
        error_27204:`Codice non valido (codice errore: 27204).`,
        error_27205:`Richiesta non riuscita (codice errore: 27205). Si prega di riprovare`,
        error_27206:`Richiesta non riuscita (codice errore: 27206). Si prega di riprovare`,
        error_27207:`Richiesta non riuscita (codice errore: 27207). Si prega di riprovare`,
        no_history_found:`Non hai utilizzato alcuno strumento! <a href="/">Aggiorna</a> o <a href="https://it.aiseesoft.com/">Vai al sito web ufficiale</a>`,
        error_25301:`Errore: 25301. Contattaci`,
        error_25302:`Errore: 25302. Contattaci`,
        error_25303:`Richiesta non riuscita (codice di errore: 25303). Si prega di riprovare`,
        error_25304:`Richiesta non riuscita (codice di errore: 25304). Si prega di riprovare`,
        error_25305:`Richiesta non riuscita (codice di errore: 25305). Si prega di riprovare`,
        error_25306:`Richiesta non riuscita (codice di errore: 25306). Si prega di riprovare`,
        image_upscaler_p:`Vantaggi del download di immagini senza filigrana:`,
        Available_for:`Disponibile per:`,
        credit_per:`%s credito/i per immagine HD`,
        still_valid:`I piani acquistati sono ancora validi`,
        credit:`crediti)`,
        pc_3rd_info:`Accedi con successo. Si prega di andare all'applicazione per ulteriori operazioni.`,
        use_online:`Utilizza il servizio online`,
        use_download:`Utilizzare il programma desktop`,
        use_immediately:`Utilizzare immediatamente`,
        Use_in_browser:`Utilizzare nel browser`,
        win_desktop:`finestre`,
        Mac_desktop:`Mac`,
    },
    ja:{
        overtime_1:`エラーコード：`,
        overtime_2:`再試行してください`,
        isnetwork:`インターネットエラー。確認して再試行してください`,
        email_placeholder:`Eメール`,
        email_empty:`メールアドレスを入力してください`,
        email_not_valid:`メールが無効です`,
        email_not_valid_1:`メールアドレスを入力してください`,
        email_not_valid_2:`メールは無効です。メールを変えて再度入力してください。`,
        email_not_valid_3:`メール入力なし`,
        password_placeholder:`パスワード`,
        password_empty:`パスワードを入力してください`,
        password_not_valid:`間違えたメールまたはパスワードです`,
        password_not_valid_1:`8文字以上のパスワードが必要です`,
        password_not_valid_2:`パスワードを作成してください`,
        password_placeholder_1:`パスワードを作成してください`,
        password_placeholder_2:`パスワードを確認しましょう`,
        password_placeholder_3:`新しいパスワードを作成します`,
        password_placeholder_4:`新しいパスワードを確認します`,
        password_placeholder_5:`古いパスワードを入力してください`,
        copy_password_empty:`パスワードを確認してください`,
        copy_password_not_valid:`パスワードを確認してください`,
        copy_passwords_inconsistent:`パスワードが一致しません`,
        code_empty:`検証コードを入力してください`,
        code_not_valid:`無効な検証コード`,
        code_placeholder:`検証コード`,
        not_received_code:`メールボックスが検証コードを長期間受信していない場合は、検証コーを再度取得してください。`,
        get_first_code:`検証コーを取得してください。`,
        connect_your_email_placeholder:`メールを接続する`,
        last_name_placeholder:`姓を入力してください`,
        first_name_placeholder:`名を入力してください`,
        address_placeholder:`住所を入力してください`,
        no_code_text:`検証コードを送信しました。コードを入力してください。<span class='tips'>コードを受け取りませんでしたか？`,
        no_code_text_1:`1.メールアドレスが有効で、メールを受信できることを確認してください。`,
        no_code_text_2:`2.メールはシステムによって自動的に送信されるため、スパムまたはジャンクメールとして認識する場合があります。 メールがゴミ箱フォルダにあるかどうかを確認してください。`,
        no_code_text_3:`3.問題を解決できませんか？`,
        no_code_text_3_span:`ここをクリックしてこちらへ連絡してください。`,
        order_no:`ご購入履歴があります。ご不明な点がございましたら、<a href="https://www.aiseesoft.jp/store/">ストア</a>または<a href="https://www.aiseesoft.jp/contact/">お問い合わせ</a>までご連絡してください。`,
        error_24901:`現在のアカウントにはリンクされたメールがなく、注文を見つけることができません。メールで連絡してください。`,
        user_guide:`ユーザーガイド>>`,
        download:`ダウンロード`,
        order_number:`注文番号`,
        Refund:`返金`,
        Disabled:`無効`,
        Normal:`正常`,
        Modify:`変更`,
        Modify_1:`変更>>`,
        Connect:`接続`,
        unlink_success:`正常にリンク解除`,
        connect_success:`正常に接続します`,
        feedback_title:`ご意見をいただきありがとうございます。問題を残してください。24時間以内に対応いたします。`,
        feedback_thank_you:`ありがとうございます！<br/>フィードバックは送信されました。`,
        feedback_email:`メールアドレスをここに入力してください！`,
        feedback_content:`ここでアドバイスとか問題とかを残してください。`,
        feedback_submit:`提出`,
        form_contents:`内容が入力されません。 内容を入力してもう一度提出してください。`,
        old_password:`古いパスワードを入力してください`,
        new_password:`新しいパスワードを作成してください`,
        old_new_password:`新しいパスワードは古いパスワードと同じにすることはできません`,
        incorrect_password:`間違ったパスワード`,
        delete_no:`今すぐ削除`,
        Caps:`CapsLockがオンになっています`,
        Get:`取得する`,
        Done:`終わり`,
        error_20001:`エラー：20001。再度ログインしてください。`,
        error_20002:`エラー：20002。再度ログインしてください。`,
        error_20003:`エラー：20003。再度ログインしてください。`,
        error_20004:`請求が失敗しました (エラーコード: 20004)。再試行してください。`,
        error_20005:`ログインセッションの有効期限が切れました（エラー：20005）。もう一度ログインしてください。`,
        error_20006:`請求が失敗しました（エラーコード：20006）。もう一度お試しください。`,
        error_20007:`ログインセッションの有効期限が切れました（エラー：20007）。もう一度ログインしてください。`,
        error_20008:`ログインセッションの有効期限が切れました（エラー：20008）。もう一度ログインしてください。`,
        error_20009:`ログインセッションの有効期限が切れました（エラー：20009）。もう一度ログインしてください。`,
        error_20101:`メールアドレスを入力してください（エラーコード：20101）`,
        error_20102:`メールが無効です（エラーコード：20102）`,
        error_20103:`請求が失敗しました（エラーコード：20103）。もう一度お試しください`,
        error_20104:`メールはすでに使用されています。<a href="https://account.aiseesoft.com/login">ログイン</a>するか、新しいメールに登録してください`,
        error_20105:`請求が失敗しました（エラーコード：20105）。もう一度お試しください`,
        error_20106:`メールの送信に失敗しました。再試行してください`,
        error_20201:`メールアドレスを入力してください（エラーコード：20201）`,
        error_20202:`パスワードを入力してください（エラーコード：20202）`,
        error_20203:`検証コードを入力してください（エラーコード：20203）`,
        error_20204:`メールが無効です（エラーコード：20204）`,
        error_20205:`8文字以上のパスワードが必要です（エラーコード：20205）`,
        error_20206:`請求が失敗しました（エラーコード：20206）。もう一度お試しください`,
        error_20207:`無効な検証コード`,
        error_20208:`請求が失敗しました（エラーコード：20208）。もう一度お試しください`,
        error_20209:`請求トが失敗しました（エラーコード：20209）。もう一度お試しください`,
        error_20301:`メールアドレスを入力してください（エラーコード：20301）`,
        error_20302:`エラー：20302。お問い合わせください`,
        error_20303:`メールが無効です（エラーコード：20303）`,
        error_20304:`請求が失敗しました（エラーコード：20304）。もう一度お試しください`,
        error_20305:`アカウントが存在しません。再度入力するか、<a href="https://account.aiseesoft.com/register">作成する</a>してください。`,
        error_20306:`パスワードはまだありません。 <a href="https://account.aiseesoft.com/passwordless-login">パスワードなしのログイン</a>または<a href="create-password">パスワードの設定</a>を使用してログインします。`,
        error_20308:`請求が失敗しました（エラーコード：20308）。もう一度お試しください`,
        error_20401:`ログアウトに失敗しました（エラーコード：20401）。もう一度お試しください`,
        error_20501:`メールアドレスを入力してください（エラーコード：20501）`,
        error_20502:`メールが無効です（エラーコード：20502）`,
        error_20503:`請求が失敗しました（エラーコード：20503）。もう一度お試しください`,
        error_20504:`メール送信に失敗しました。もう一度お試しください。`,
        error_20601:`メールアドレスを入力してください（エラーコード：20601）`,
        error_20602:`検証コード（エラーコード：20602）を入力してください`,
        error_20603:`メールが無効です（エラーコード：20603）`,
        error_20604:`請求が失敗しました（エラーコード：20604）。もう一度お試しください`,
        error_20606:`請求が失敗しました（エラーコード：20606）。もう一度お試しください`,
        error_20607:`請求が失敗しました（エラーコード：20607）。もう一度お試しください`,
        error_20608:`請求が失敗しました（エラーコード：20608）。もう一度お試しください`,
        error_20701:`メールアドレスを入力してください（エラーコード：20701）`,
        error_20702:`メールが無効です（エラーコード：20702）`,
        error_20703:`請求が失敗しました（エラーコード：20703）。もう一度お試しください`,
        error_20704:`アカウントが存在しません。再度入力するか、<a href="https://account.aiseesoft.com/register">作成する</a>してください。`,
        error_20705:`請求が失敗しました（エラーコード：20705）。もう一度お試しください`,
        error_20706:`メール送信に失敗しました。もう一度お試しください`,
        error_20801:`メールアドレスを入力してください（エラーコード：20801）`,
        error_20802:`エラー：20802。お問い合わせください`,
        error_20803:`検証コード（エラーコード：20803）を入力してください`,
        error_20804:`メールが無効です（エラーコード：20804）`,
        error_20805:`8文字以上のパスワードが必要です（エラーコード：20805）`,
        error_20806:`請求が失敗しました（エラーコード：20806）。もう一度お試しください`,
        error_20808:`請求が失敗しました（エラーコード：20808）。もう一度お試しください`,
        error_20901:`請求が失敗しました（エラーコード：20901）。もう一度お試しください`,
        error_20902:`請求が失敗しました（エラーコード：20902）。もう一度お試しください`,
        error_21000:`変更が保存されます`,
        error_21001:`内容は送信されません（エラーコード：21001）`,
        error_21002:`請求が失敗しました（エラーコード：21002）。もう一度お試しください`,
        error_21101:`メールアドレスを入力してください（エラーコード：21101）`,
        error_21102:`メールが無効です（エラーコード：21102）`,
        error_21103:`請求が失敗しました（エラーコード：21103）、もう一度やり直してください`,
        error_21104:`このメールは使用された状態です。新しいメールを使用してください`,
        error_21105:`請求が失敗しました（エラーコード：21105）、もう一度やり直してください`,
        error_21106:`メール送信に失敗しました。もう一度お試しください`,
        error_21201:`メールアドレスを入力してください（エラーコード：21201）`,
        error_21202:`検証コード（エラーコード：21202）を入力してください`,
        error_21203:`メールが無効です（エラーコード：21203）`,
        error_21204:`エラー：21204。お問い合わせください`,
        error_21205:`エラー：21205。お問い合わせください`,
        error_21206:`8文字以上のパスワードが必要です（エラーコード：21206）`,
        error_21207:`請求が失敗しました（エラーコード：21207）。もう一度お試しください`,
        error_21209:`請求が失敗しました（エラーコード：21209）。もう一度お試しください`,
        error_21301:`古いパスワードを入力してください（エラーコード：21301）`,
        error_21302:`新しいパスワードを作成してください（エラーコード：21302）`,
        error_21303:`新しいパスワードは古いパスワードと同じにすることはできません。 （エラー：21303）`,
        error_21304:`8文字以上のパスワードが必要です（エラーコード：21304）`,
        error_21306:`請求が失敗しました（エラーコード：21306）。もう一度お試しください`,
        error_21402:`請求が失敗しました（エラーコード：21402）。もう一度お試しください`,
        error_21403:`検証コードは送信に失敗しました。再度送信してください`,
        error_21500:`アカウントが削除されました`,
        error_21501:`検証コード（エラーコード：21501）を入力してください`,
        error_21502:`ログインセッションの有効期限が切れました（エラー：21502）。もう一度ログインしてください。`,
        error_21503:`請求が失敗しました（エラーコード：21503）。もう一度お試しください`,
        error_21505:`請求が失敗しました（エラーコード：21505）、もう一度やり直してください`,
        error_21601:`エラー：20601。お問い合わせください`,
        error_21602:`無効な検証（エラー：20602）。もう一度お試しください。`,
        error_21603:`エラー：20603。もう一度お試しください`,
        error_21604:`請求が失敗しました（エラーコード：21604）。もう一度お試しください`,
        error_21606:`請求が失敗しました（エラーコード：21606）。もう一度お試しください`,
        error_21611:`請求が失敗しました（エラーコード：21611）。もう一度お試しください`,
        error_21801:`エラー：21801。お問い合わせください`,
        error_21802:`請求が失敗しました（エラー：21802）。もう一度お試しください`,
        error_21803:`エラー：21803。もう一度お試しください`,
        error_21804:`請求が失敗しました（エラーコード：21804）。もう一度お試しください`,
        error_21806:`エラー：21806。もう一度お試しください`,
        error_21807:`エラー：21807。お問い合わせください`,
        error_21808:`エラー：21808。お問い合わせください`,
        error_21809:`エラー：21809。お問い合わせください`,
        error_21810:`エラー：21810。お問い合わせください`,
        error_21811:`エラー：21811。お問い合わせください`,
        error_21812:`エラー：21812。お問い合わせください`,
        error_21813:`請求が失敗しました（エラーコード：21813）。もう一度お試しください`,
        error_21814:`エラー：21814。お問い合わせください`,
        error_21815:`請求が失敗しました（エラーコード：21815）。もう一度お試しください`,
        error_21816:`エラー：21816。お問い合わせください`,
        error_21817:`エラー：21817。お問い合わせください`,
        error_21818:`エラー：21818。お問い合わせください`,
        error_21819:`請求が失敗しました（エラーコード：21819）。もう一度お試しください`,
        error_21820:`エラー：21820。お問い合わせください`,
        error_21821:`エラー：21821。お問い合わせください`,
        error_21822:`エラー：21822。お問い合わせください`,
        error_21823:`請求が失敗しました（エラーコード：21823）。もう一度お試しください`,
        error_21824:`請求が失敗しました（エラーコード：21824）。もう一度お試しください`,
        error_21825:`請求が失敗しました（エラーコード：21825）。もう一度お試しください`,
        error_21826:`請求が失敗しました（エラーコード：21826）。もう一度お試しください`,
        error_21828:`エラー：21828。お問い合わせください`,
        error_21901:`エラー：21901。お問い合わせください`,
        error_21902:`請求が失敗しました（エラーコード：21902）。もう一度お試しください`,
        error_21903:`アカウント状態が変更されました（エラーコード：21903）。ページを更新してもう一度お試しください`,
        error_21904:`エラー：21904。もう一度お試しください`,
        error_21905:`エラー：21905。もう一度お試しください`,
        error_21906:`請求が失敗しました（エラーコード：21906）。もう一度お試しください`,
        error_21907:`Googleアカウントは他のアカウントにリンクされています`,
        error_21908:`請求が失敗しました（エラーコード：21908）。もう一度お試しください`,
        error_22001:`請求が失敗しました（エラーコード：22001）。もう一度お試しください`,
        error_22002:`ログインなしでリンク解除に失敗しました`,
        error_22003:`請求が失敗しました（エラーコード：22003）。もう一度お試しください`,
        error_22101:`エラー：22101。お問い合わせください`,
        error_22102:`アカウントの状態が変更されました（エラーコード：22102）。ページを更新して再試行してください`,
        error_22103:`請求が失敗しました（エラーコード：22103）。もう一度お試しください`,
        error_22104:`アカウントの状態が変更されました（エラーコード：22104）。ページを更新してもう一度お試しください`,
        error_22105:`エラー：22105。もう一度お試しください`,
        error_22106:`エラー：22106。もう一度お試しください`,
        error_22107:`エラー：22107。お問い合わせください`,
        error_22108:`請求が失敗しました（エラーコード：22108）。もう一度お試しください`,
        error_22201:`エラー：22201。お問い合わせください`,
        error_22202:`無効な検証（エラー：22202）。もう一度お試しください。`,
        error_22203:`エラー：22203。もう一度お試しください。」`,
        error_22204:`請求が失敗しました（エラーコード：22204）。もう一度お試しください`,
        error_22206:`請求が失敗しました（エラーコード：22206）。もう一度お試しください`,
        error_22401:`エラー：22401。お問い合わせください`,
        error_22402:`無効な検証（エラー：22402）。もう一度お試しください。`,
        error_22403:`エラー：22403。もう一度お試しください`,
        error_22404:`請求が失敗しました（エラーコード：22404）。もう一度お試しください`,
        error_22405:`Facebookアカウントは他のメールにリンクされています`,
        error_22406:`エラー：22406。もう一度お試しください`,
        error_22407:`エラー：22407。お問い合わせください`,
        error_22408:`エラー：22408。お問い合わせください`,
        error_22409:`エラー：22409。お問い合わせください`,
        error_22410:`エラー：224010。お問い合わせください`,
        error_22411:`エラー：224011。お問い合わせください`,
        error_22412:`エラー：224012。お問い合わせください`,
        error_22413:`エラー：22413。お問い合わせください`,
        error_22414:`請求が失敗しました（エラーコード：22414）。もう一度お試しください`,
        error_22415:`エラー：22415。お問い合わせください`,
        error_22416:`エラー：22416。お問い合わせください`,
        error_22417:`エラー：22417。お問い合わせください`,
        error_22418:`請求が失敗しました（エラーコード：22418）。もう一度お試しください`,
        error_22419:`エラー：22419。お問い合わせください`,
        error_22420:`エラー：22420。お問い合わせください`,
        error_22421:`エラー：22421。お問い合わせください`,
        error_22422:`請求が失敗しました（エラーコード：22422）。もう一度お試しください`,
        error_22423:`請求が失敗しました（エラーコード：22423）。もう一度お試しください`,
        error_22424:`請求が失敗しました（エラーコード：22424）。もう一度お試しください`,
        error_22425:`請求が失敗しました（エラーコード：22425）。もう一度お試しください`,
        error_20098:`エラーコード：20098。プライベートモードで閲覧している場合は、通常モードに切り替えて再試行してください。`,
        error_22298:`Googleの請求に失敗しました（エラー：22298）。もう一度お試しください。`,
        error_22498:`Facebookの請求に失敗しました（エラー：22498）。もう一度お試しください。`,
        error_24902:`請求が失敗しました（エラーコード：24902）。もう一度お試しください`,
        error_24903:`請求が失敗しました（エラーコード：24903）。もう一度お試しください`,
        error_24904:`請求が失敗しました（エラーコード：24904）。もう一度お試しください`,
        error_24905:`請求が失敗しました（エラーコード：24905）。もう一度お試しください`,
        login_title:`Aiseesoftにログインします`,
        password_placeholder:`パスワード`,
        log_in:`ログインする`,
        no_account:`アカウントがありませんか？`,
        create_it:`作成する`,
        or_log_in_with:`またはログイン`,
        passwordless_login:`パスワード不要のログイン`,
        log_in_done:`ログイン完了`,
        three_rd_account_connect_info:`おめでとう！成功にログインしました。これで、ログイン用の電子メールアカウントに接続できます。`,
        connect_now:`今すぐ接続`,
        see_my_account:`アカウントを確認`,
        three_rd_login_merge_account:`サードパーティアカウントの電子メールアドレスがサインアップされました。この電子メールアドレスに直接接続してログインしますか？`,
        connect_log_in:`接続してログイン`,
        create_an_account:`アカウントを作成する`,
        connect_account:`アカウントを接続する`,
        connect_account_info:`接続後、このアカウントでログインできます。`,
        back_to_log_in:`ログインに戻る`,
        create_password:`パスワードの作成`,
        create_now:`今すぐ作成`,
        password_login_subtitle:`スワードなしメールでパログイン`,
        account_login:`アカウントログイン`,
        rights:`このアカウントを作成する場合、<a href="https://www.aiseesoft.jp/terms-and-conditions.html">利用規約</a>と<a href="https://www.aiseesoft.jp/privacy-policy/">プライバシーポリシー</a>を同意します`,
        passwordless_login_done:`パスワードなしログインしました`,
        passwordless_login_info:`おめでとうございます。パスワードなしログインしました。アカウントのパスワードを作成し、そのアカウントとパスワードでログインすることができます。 <a href="/create-password" style="display:initial;">今すぐ作成</a>`,
        sign_up:`サインアップ`,
        register_info:`アカウントを作成`,
        reset_now:`今すぐリセット`,
        forgot_password:`パスワードをお忘れですか`,
        reset_password_subtitle:`アカウントのメールアドレスを使用してパスワードをリセットします`,
        plan_products:`プランと製品`,
        nick_name:`ニックネーム`,
        email:`Eメール`,
        my_products:`私の製品`,
        my_orders:`私の注文`,
        unlink:`リンクを解除する`,
        link:`リンク`,
        connected_accounts:`接続されたアカウント`,
        last_name:`名`,
        first_name:`姓`,
        Gender:`性別`,
        Birth:`誕生日`,
        Month:`月`,
        Year:`年`,
        Country_Region:`国/地域`,
        Address:`住所`,
        Save:`保存`,
        Date:`日付け`,
        Male:`男`,
        Female:`女`,
        Unspecified:`詳細不明`,
        Security:`安全＆プライバシー`,
        change_password:`パスワードを変更する`,
        change_now:`今すぐ変更`,
        connect_email:`メールを接続する`,
        delete_account:`アカウントを削除する`,
        delete_account_info:`アカウントを削除すると、関連されているプランと注文情報のデータがすべて削除され、復元するのはできません。アカウントを削除してもよろしいでしょうか?`,
        Delete:`削除`,
        Logout:`ログアウト`,
        my_profile:`プロフィール`,
        guides_faqs:`ガイドとFAQ`,
        More:`もっと`,
        guides:`ガイド`,
        register:`登録`,
        hot_faq:`ホットFAQ`,
        Contents:`コンテンツ：`,
        contact_us:`お問い合わせ>>`,
        plan:`プラン`,
        unregistered:`未登録`,
        buy_more:`もっと買う`,
        buy_again:`もう一度購入する`,
        buy_now:`今すぐ購入`,
        free_no_limit:`無料＆無制限`,
        expired:`期限切れ`,
        lifetime:`一生`,
        remain:`残る`,
        day_s:`日々）`,
        error_24801:`リクエストが失敗しました（エラーコード：24801）。もう一度お試しください`,
        no_app_found:`ソフトリストがありません。<a href="/">更新</a>または<a href="https://www.aiseesoft.jp">公式ウェブサイト</a>にアクセスしてください。`,
        
        get_more:`もっと見る>>`,
        edit_photo:`写真を編集する`,
        select_photo:`写真を選択`,
        change_photo:`写真を変更する`,
        cancel:`キャンセル`,
        hide_password:`パスワードを隠す`,
        show_password:`パスワードを表示`,
        zoom_in:`ズームイン`,
        zoom_out:`ズームアウトする`,
        rotate:`回転する`,
        horizontal_flip:`水平フリップ`,
        vertical_flip:`垂直フリップ`,
        country:`国`,
        country_1:`国/地域を選択してください`,
        country_2:`オーランド島`,
        country_3:`アフガニスタン`,
        country_4:`アルバニア`,
        country_5:`アルジェリア`,
        country_6:`アメリカ領サモア`,
        country_7:`アンドラ`,
        country_8:`アンゴラ`,
        country_9:`アンギラ`,
        country_10:`南極大陸`,
        country_11:`アンティグアバーブーダ`,
        country_12:`アルゼンチン`,
        country_13:`アルメニア`,
        country_14:`アルバ`,
        country_15:`オーストラリア`,
        country_16:`オーストリア`,
        country_17:`アゼルバイジャン`,
        country_18:`バーレーン`,
        country_19:`バングラデシュ`,
        country_20:`バルバドス`,
        country_21:`ベラルーシ`,
        country_22:`ベルギー`,
        country_23:`ベリーズ`,
        country_24:`ベナン`,
        country_25:`バミューダ`,
        country_26:`ブータン`,
        country_27:`ボリビア`,
        country_28:`ボスニア・ヘルツェゴビナ`,
        country_29:`ボツワナ`,
        country_30:`ブーベ島`,
        country_31:`ブラジル`,
        country_32:`イギリス領インド洋地域`,
        country_33:`イギリス領バージン諸島`,
        country_34:`ブルネイ`,
        country_35:`ブルガリア`,
        country_36:`ブルキナファソ`,
        country_37:`ブルンジ`,
        country_38:`カンボジア`,
        country_39:`カメルーン`,
        country_40:`カナダ`,
        country_41:`カーボベルデ`,
        country_42:`カリブ海オランダ`,
        country_43:`ケイマン諸島`,
        country_44:`中央アフリカ共和国`,
        country_45:`チャド`,
        country_46:`チリ`,
        country_47:`中国`,
        country_48:`クリスマス島`,
        country_49:`ココス（キーリング）諸島`,
        country_50:`コロンビア`,
        country_51:`クック諸島`,
        country_52:`コスタリカ`,
        country_53:`「コートジボワール」`,
        country_54:`クロアチア`,
        country_55:`キューバ`,
        country_56:`キプロス`,
        country_57:`チェコ共和国`,
        country_58:`コンゴ民主共和国`,
        country_59:`デンマーク`,
        country_60:`ジブチ`,
        country_61:`ドミニカ`,
        country_62:`ドミニカ共和国`,
        country_63:`エクアドル`,
        country_64:`エジプト`,
        country_65:`エルサルバドル`,
        country_66:`赤道ギニア`,
        country_67:`エリトリア`,
        country_68:`エストニア`,
        country_69:`エチオピア`,
        country_70:`フォークランド諸島`,
        country_71:`フェロー諸島`,
        country_72:`ミクロネシア連邦`,
        country_73:`フィジー`,
        country_74:`フィンランド`,
        country_75:`フランス`,
        country_76:`フランス領ギアナ`,
        country_77:`フランス領ポリネシア`,
        country_78:`フランス領南方南方領土`,
        country_79:`ガボン`,
        country_80:`ガンビア`,
        country_81:`ジョージア`,
        country_82:`ドイツ`,
        country_83:`ガーナ`,
        country_84:`ジブラルタル`,
        country_85:`イギリス（イギリス;イギリス）`,
        country_86:`ギリシャ`,
        country_87:`グリーンランド`,
        country_88:`グレナダ`,
        country_89:`グアドループ`,
        country_90:`グアム`,
        country_91:`グアテマラ`,
        country_92:`ガーンジー`,
        country_93:`ギニア`,
        country_94:`ギニアビサウ`,
        country_95:`ガイアナ`,
        country_96:`ハイチ`,
        country_97:`ハード島とマクドナルド諸島`,
        country_98:`ホンジュラス`,
        country_99:`香港`,
        country_100:`ハンガリー`,
        country_101:`アイスランド`,
        country_102:`インド`,
        country_103:`インドネシア`,
        country_104:`イラン`,
        country_105:`イラク`,
        country_106:`アイルランド`,
        country_107:`マン島`,
        country_108:`イスラエル`,
        country_109:`イタリア`,
        country_110:`ジャマイカ`,
        country_111:`日本`,
        country_112:`ジャージー`,
        country_113:`ヨルダン`,
        country_114:`カザフスタン`,
        country_115:`ケニア`,
        country_116:`キリバス`,
        country_117:`クウェート`,
        country_118:`キルギスタン`,
        country_119:`ラオス`,
        country_120:`ラトビア`,
        country_121:`レバノン`,
        country_122:`レソト`,
        country_123:`リベリア`,
        country_124:`リビア`,
        country_125:`リヒテンシュタイン`,
        country_126:`リトアニア`,
        country_127:`ルクセンブルク`,
        country_128:`マカオ`,
        country_129:`マダガスカル`,
        country_130:`マラウイ`,
        country_131:`マレーシア`,
        country_132:`モルディブ`,
        country_133:`マリ`,
        country_134:`マルタ`,
        country_135:`マーシャル諸島`,
        country_136:`マルティニーク`,
        country_137:`モーリタニア`,
        country_138:`モーリシャス`,
        country_139:`マヨット`,
        country_140:`メキシコ`,
        country_141:`モルドバ`,
        country_142:`モナコ`,
        country_143:`モンゴル`,
        country_144:`モンテネグロ`,
        country_145:`モントセラト`,
        country_146:`モロッコ`,
        country_147:`モザンビーク`,
        country_148:`ミャンマー（ビルマ）`,
        country_149:`ナミビア`,
        country_150:`ナウル`,
        country_151:`ネパール`,
        country_152:`オランダ`,
        country_153:`ニューカレドニア`,
        country_154:`ニュージーランド`,
        country_155:`ニカラグア`,
        country_156:`ニジェール`,
        country_157:`ナイジェリア`,
        country_158:`ニウエ`,
        country_159:`ノーフォーク島`,
        country_160:`北朝鮮`,
        country_161:`北マリアナ諸島`,
        country_162:`ノルウェー`,
        country_163:`オマーン`,
        country_164:`パキスタン`,
        country_165:`パラオ`,
        country_166:`パレスチナ領土`,
        country_167:`パナマ`,
        country_168:`パプアニューギニア`,
        country_169:`パラグアイ`,
        country_170:`ペルー`,
        country_171:`ピトケアン諸島`,
        country_172:`ポーランド`,
        country_173:`ポルトガル`,
        country_174:`プエルトリコ`,
        country_175:`カタール`,
        country_176:`マケドニア共和国（FYROM）`,
        country_177:`コンゴ共和国`,
        country_178:`再会`,
        country_179:`ルーマニア`,
        country_180:`ロシア連邦`,
        country_181:`ルワンダ`,
        country_182:`サンバルテルミー`,
        country_183:`サンマルタン（フランス）`,
        country_184:`サンピエール島とミクロン島`,
        country_185:`サモア`,
        country_186:`サンマリノ`,
        country_187:`サントメ・プリンシペ`,
        country_188:`サウジアラビア`,
        country_189:`セネガル`,
        country_190:`セルビア`,
        country_191:`セイシェル`,
        country_192:`シエラレオネ`,
        country_193:`シンガポール`,
        country_194:`スロバキア`,
        country_195:`スロベニア`,
        country_196:`ソロモン諸島`,
        country_197:`ソマリア`,
        country_198:`南アフリカ`,
        country_199:`サウスジョージア島とサウスサンドイッチ島`,
        country_200:`韓国`,
        country_201:`南スーダン`,
        country_202:`スペイン`,
        country_203:`スリランカ`,
        country_204:`セントヘレナと依存関係`,
        country_205:`セントクリストファーネイビス`,
        country_206:`セントルシア`,
        country_207:`セントビンセントおよびグレナディーン諸島`,
        country_208:`スーダン`,
        country_209:`スリナム`,
        country_210:`スワジランド`,
        country_211:`スウェーデン`,
        country_212:`スイス`,
        country_213:`シリア`,
        country_214:`台湾`,
        country_215:`タジキスタン`,
        country_216:`タンザニア`,
        country_217:`テンプレート：国データSJMスバールバル`,
        country_218:`タイ`,
        country_219:`バハマ`,
        country_220:`コモロ`,
        country_221:`フィリピン`,
        country_222:`東ティモール（東ティモール）`,
        country_223:`トーゴ`,
        country_224:`トケラウ`,
        country_225:`トンガ`,
        country_226:`トリニダード・トバゴ`,
        country_227:`チュニジア`,
        country_228:`七面鳥`,
        country_229:`トルクメニスタン`,
        country_230:`タークス・カイコス諸島`,
        country_231:`ツバル`,
        country_232:`ウガンダ`,
        country_233:`ウクライナ`,
        country_234:`アラブ首長国連邦`,
        country_235:`合衆国領有小島`,
        country_236:`アメリカ合衆国（USA）`,
        country_237:`アメリカ領バージン諸島`,
        country_238:`ウルグアイ`,
        country_239:`ウズベキスタン`,
        country_240:`バヌアツ`,
        country_241:`バチカン市国（聖座）`,
        country_242:`ベネズエラ`,
        country_243:`ベトナム`,
        country_244:`ウォリス・フツナ`,
        country_245:`西サハラ`,
        country_246:`イエメン`,
        country_247:`ザンビア`,
        country_248:`ジンバブエ`,
        google_login:`Googleでサインイン`,
        footer:`Copyright©2023AiseesoftStudio。全著作権所有。`,
        State:`州`,
        Activation_code:`アクティベーションコード`,
        Question:`登録したソフトを表示します`,
        Copy_complete:`コピー完了`,


        store:`店`,
        resource:`リソース`,
        support:`サポート`,
        video_converter:`ビデオコンバーター`,
        multimedia:`マルチメディア`,
        data_recovery:`データ復旧`,
        utility:`効用`,
        free_tools:`無料ツール`,
        for_windows:`Windowsの場合`,
        for_mac:`Mac用`,
        pdf_solutions:`PDFソリューション`,
        data_manager:`データ管理者`,
        other_tools:`その他のツール`,
        free_online_solutions:`無料のオンラインソリューション`,
        free_online_tools:`無料のオンラインツール`,
        desktop_freeware:`デスクトップフリーウェア`,
        product_1:`ビデオコンバーターアルティメット`,
        product_2:`トータルビデオコンバータ`,
        product_3:`MTSコンバーター`,
        product_4:`4Kコンバーター`,
        product_5:`MXFコンバーター`,
        product_6:`3Dコンバーター`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Mac用のビデオコンバーター`,
        product_9:`Mac用のMTSコンバーター`,
        product_10:`Mac用4Kコンバーター`,
        product_11:`Mac用MXFコンバーター`,
        product_12:`Mac用の3Dコンバーター`,
        product_13:`ブルーレイプレイヤー`,
        product_14:`ビデオエンハンサー`,
        product_15:`動画編集者`,
        product_16:`スライドショークリエーター`,
        product_17:`ブルーレイクリエーター`,
        product_18:`Burnova - DVD 作成プロ`,
        product_19:`DVDクリエーター`,
        product_20:`Macブルーレイプレーヤー`,
        product_21:`Macビデオエンハンサー`,
        product_22:`Mac用のビデオエディタ`,
        product_23:`Mac用DVDクリエーター`,
        product_24:`Mac用MP4コンバーター`,
        product_25:`Windowsデータ回復`,
        product_26:`FoneLabiPhoneデータリカバリ`,
        product_27:`FoneLabiOSシステムリカバリ`,
        product_28:`FoneLab iOSデータバックアップ＆amp;戻す`,
        product_29:`FoneLabAndroidデータリカバリ`,
        product_30:`壊れたAndroidデータの抽出`,
        product_31:`Androidデータのバックアップと戻す`,
        product_32:`Macのデータ回復`,
        product_33:`Mac FoneLabiPhoneデータリカバリ`,
        product_34:`Mac FoneLabiOSシステムリカバリ`,
        product_35:`Mac FoneLab iOSデータバックアップ＆amp;戻す`,
        product_36:`Mac FoneLabAndroidデータリカバリ`,
        product_37:`Mac Androidデータバックアップ＆amp;戻す`,
        product_38:`PDFコンバーターアルティメット`,
        product_39:`Mac用PDFコンバーターアルティメット`,
        product_40:`PDF合併`,
        product_41:`MacPDFからePubへのコンバーター`,
        product_42:`Macクリーナー`,
        product_43:`FoneTrans`,
        product_44:`iOS用のWhatsApp転送`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhoneクリーナー`,
        product_48:`PPTからビデオへのコンバーター`,
        product_49:`HEICコンバーター`,
        product_50:`iPhone Unlocker`,
        product_51:`無料のビデオコンバーターオンライン`,
        product_52:`無料のオーディオコンバータオンライン`,
        product_53:`無料のビデオコンプレッサーオンライン`,
        product_54:`オンライン無料ビデオ合併`,
        product_55:`無料のHEICコンバーター`,
        product_56:`無料の画像圧縮機`,
        product_57:`無料のウォーターマークリムーバー`,
        product_58:`無料のPDFコンプレッサーオンライン`,
        product_59:`無料画像アップスケーラーオンライン`,
        product_60:`無料のバックグラウンドリムーバーオンライン`,
        product_61:`無料の画像コンバーターオンライン`,
        product_62:`無料のPDFPNGコンバーターオンライン`,
        product_63:`無料のビデオコンバーター`,
        product_64:`無料のビデオからGIFへのコンバーター`,
        product_65:`無料のAndroidデータリカバリ`,
        product_66:`スライドショーメーカー`,
        product_67:`無料のビデオエディタ`,
        product_68:`無料のPDFビューア`,
        product_69:`スクリーンレコーダー`,
        product_70:`Macスクリーンレコーダー`,
        product_71:`オンライン無料スクリーンレコーダー`,
        product_72:`無料のオーディオレコーダーオンライン`,
        product_73:`電話ミラー`,
        product_74:`AnyCoord - ロケーションチェンジャー`,
        product_75:`iPhone パスワード マネージャー`,
        product_76:`無料のオーディオコンプレッサーオンライン`,
        product_77:`無料のビデオ トリマー オンライン`,
        product_78:`無料のビデオクロッパーオンライン`,
        product_79:`無料のビデオローテーターオンライン`,
        product_80:`無料ビデオ ルーパー オンライン`,
        product_81:`無料の PDF JPG コンバーター オンライン`,
        product_81:`無料の JPG Word コンバーター オンライン`,
        faq_title_1:`1. 4104エラーを修正するにはどうすればよいですか？`,
        faq_title_2:`2.返金リクエストが承認された後、いつ返金できますか？`,
        faq_title_3:`3.「1年間のサブスクリプション」とは何ですか？「自動更新サービス」をオフにする方法は？`,
        faq_title_4:`4. Aiseesoft製品を注文した後、注文をキャンセルできますか？`,
        faq_title_5:`5.レビューを送信するにはどうすればよいですか？`,
        faq_info_1:`これは、ライセンスが制限されているためです。登録コードの違反使用を防止するために、登録コードが盗まれたことに防ぎ、シングルライセンスを採用します。したがって、取得した登録コードは、1台のコンピューターしか使用できません。複数のコンピューターで使用すると、エラー4104が発生します。リセットするまで使用できません。`,
        faq_info_2:`コンピュータを変更または再インストールした場合は、リセットできます。 <a href="mailto:support@aiseesoft.jp"> support@aiseesoft.jp </a> のカスタマーサービスに連絡して、登録コードのリセットをリクエストしてください。`,
        faq_info_3:`払い戻しリクエストが承認されてから、通常、クレジットがアカウントに表示されるまで3〜7営業日かかります。休日の場合はもっと時間がかかることもありますが、10日以上かかることはありません。承認後20日以内に払い戻しがない場合は、<a href="mailto:support@aiseesoft.jp"> support@aiseesoft.jp </a>に連絡して払い戻しを確認してください。`,
        faq_info_4:`自動更新サービスとは、1年間のサブスクリプションを購入した場合、製品ライセンスの有効期限が切れると、支払いプラットフォームが自動的に金額（該当する税金を含む）を請求することを意味します。ただし、支払いフラットフォーム（Swregまたは2Checkout）から、注文の有効期限が切れる7日前に通知が届きます。電子メールには、「自動更新サービス」をオフにする方法の説明も含まれています。`,
        faq_info_5:`注文は自動的に処理されるため、注文をキャンセルすることはできません。さらに、無料試用版では、Aiseesoftプログラムについて詳しく知り、購入する前にニーズを満たすことができるかどうかを確認できます。`,
        faq_info_6:`本当に払い戻しを希望する場合は、<a href="https://www.aiseesoft.jp/refund-policy.html">払い戻しポリシー</a>を確認してください。`,
        faq_info_7:`レビューを送信していただき、誠にありがとうございます。お客様のレビューを提出するために弊社（<a href="mailto:support@aiseesoft.jp">support@aiseesoft.jp</a>）に連絡してください。私達は私達の製品アップデートのためにお客様のレビューを真剣に検討します。`,
        change_password_success:`パスワードは正常に変更されました`,
        successful_login_title:`ログイン成功`,
        product_page:`製品ページ>>`,
        successful_login_info:`ログインが完了しました。現在のページを閉じて元のタブに戻り、次のプロセスを続行してください。現在のページは5秒後に自動的に閉じられます。自動クローズまたは[完了]ボタンをクリックして閉じることができない場合は、このタブを直接閉じてください。`,
        successful_login_info_firefox:`ログインが完了しました。現在のページを閉じて元のタブに戻り、次のプロセスを続行してください。`,
        my_account:`マイアカウント`,
        my_history:`私の歴史`,
        remove_watermark:`ウォーターマークを削除する`,
        no_history:`履歴はありません`,
        history_all:`すべて選択`,
        history_open:`開ける`,
        history_down:`ダウンロード`,
        history_delete:`消去`,
        history_clear:`無効をクリア`,
        images:`画像`,
        use_this_function:`この関数を使用する>>`,
        hd_downloading:`HDオリジナル画像のダウンロードのメリット：`,
        lifetimeRemaining:`生涯-残り`,
        Remaining:`残り`,
        email_verification:`メールによる確認`,
        email_verification_info:`確認メッセージをメール<spanclass = "email"> </ span>に送信しましたので、確認を完了してください。確認後、メリットは自動的に同期されます。`,
        wrong_email:`間違ったメールアドレス？`,
        click_here_to_modify:`変更するには、ここをクリックしてください`,
        get_more_help:`さらにヘルプが必要ですか？`,
        click_here:`ここをクリック`,
        email_verification_info_success:`メールアカウントを確認していただき、ありがとうございます。`,
        email_verification_info_error:`リンクの有効期限が切れているため、検証に失敗しました。`,
        registration_succeeded:`登録に成功しました`,
        registration_succeeded_info_1:`おめでとう！あなたが正常に登録されています。確認メッセージをメール<spanclass = "email"> </ span>に送信しました。このメールのメリットを利用するには、確認を完了してください。`,
        registration_succeeded_info_2:`「完了」をクリックしてホームページに戻り、この製品を使用してください。`,
        registration_succeeded_info_3:`「完了」をクリックして現在のページを閉じ、ホームページに戻って次の操作を行います。閉じられない場合は、手動でタブを閉じてください。`,
        verify_email:`Eメールを確認します`,
        registered_email_not_verified:`登録されたメールアドレスは確認されていません。すぐに確認してください。`,
        email_verification_time_1:`確認メールが届きませんでしたか？`,
        email_verification_time_2:`後`,
        email_verification_time_3:`ここをクリックして再送してください`,
        error_26301:`エラーコード：26301、お問い合わせください`,
        error_26302:`エラーコード：26302、お問い合わせください`,
        error_26303:`メールフォーマットエラー（エラーコード：26303）。もう一度入力してください`,
        error_26304:`8文字を超えるパスワードをお勧めします（エラーコード：26304）`,
        error_26305:`Reuqestが失敗しました（エラーコード：26305）。再試行してください`,
        error_26306:`メールが登録されました。<a href="https://account.aiseesoft.com/login">ログインしてください</a>`,
        error_26307:`Reuqestが失敗しました（エラーコード：26307）。再試行してください`,
        error_26308:`Reuqestが失敗しました（エラーコード：26308）。再試行してください`,
        error_26401:`エラーコード：26401、再試行してください`,
        error_26402:`メールが確認されました（エラーコード：26402）。再試行してください`,
        error_26403:`Reuqestが失敗しました（エラーコード：26403）。再試行してください`,
        error_26404:`リクエストが失敗しました（エラーコード：26404）。再試行してください`,
        error_26501:`エラーコード：26501、お問い合わせください`,
        error_26502:`エラーコード：26502、お問い合わせください`,
        error_26503:`メールフォーマットエラー（エラーコード：26503）。もう一度入力してください`,
        error_26504:`Reuqestが失敗しました（エラーコード：26504）。再試行してください`,
        error_26505:`メールはまだ登録されていません。<a href="https://account.aiseesoft.com/register">最初に登録</a>してください`,
        error_26506:`メールが確認されました。`,
        error_26507:`Reuqestが失敗しました（エラーコード：26507）。再試行してください`,
        error_26508:`検証が失敗しました（エラーコード：26508）、再試行してください`,
        error_26509:`リクエストに失敗しました（エラーコード：26509）。再試行してください`,
        error_26510:`エラーコード：26510、お問い合わせください`,
        error_26601:`エラーコード：26601、お問い合わせください`,
        error_26602:`エラーコード：26602、お問い合わせください`,
        error_26603:`Reuqestが失敗しました（エラーコード：26603）。再試行してください`,
        error_26604:`エラーコード：26604、お問い合わせください`,
        error_26605:`エラーコード：26605、お問い合わせください`,
        error_26701:`エラーコード：26701、お問い合わせください`,
        error_26702:`Reuqestが失敗しました（エラーコード：26702）。再試行してください`,
        error_26703:`エラーコード：26703、お問い合わせください`,
        error_26704:`エラーコード：26704、お問い合わせください`,
        error_26705:`ログインを待ちます（エラーコード：26705）。再試行してください`,
        no_cookie:`ブラウザで[すべてのCookieをブロックする]機能をオンにしているため、ログインできません。[設定]に移動して、[すべてのCookieを許可する]チェックボックスをオンにしてください。`,
        error_26801:`エラーコード：26801、お問い合わせください`,
        error_26802:`エラーコード：26802、お問い合わせください`,
        error_26803:`リクエストが失敗しました（エラーコード：26803）。再試行してください`,
        error_26804:`リクエストが失敗しました（エラーコード：26804）。再試行してください`,
        error_order:`リクエストに失敗しました（エラーコード：27098）。もう一度やり直してください。`,
        error_order1:`注文クエリが不完全です（エラーコード：`,
        error_order2:`）、更新してもう一度お試しください。`,
        modify_email_title:`メールを変更する`,
        modify_email_info:`変更したメールを使用して、アカウントにログインできます。`,
        images_per:`あたりの画像`,
        error_26101:`エラー：26101。お問い合わせください`,
        error_26102:`エラー：26102。お問い合わせください`,
        error_26103:`リクエストが失敗しました（エラーコード：26103）。もう一度お試しください`,
        error_26104:`エラーコード：26104、plesase再試行してください`,
        error_26105:`エラーコード：26105、もう一度やり直してください`,
        error_26106:`削除に失敗しました（エラーコード：26106）。もう一度お試しください`,
        error_26201:`エラー：26201。お問い合わせください`,
        error_26202:`リクエストが失敗しました（エラーコード：26202）。もう一度お試しください`,
        error_26001:`エラー：26001。お問い合わせください`,
        error_26002:`エラー：26002。お問い合わせください`,
        error_26003:`エラー：26003。お問い合わせください`,
        error_26004:`エラー：26004。お問い合わせください`,
        error_26005:`リクエストが失敗しました（エラーコード：26005）。もう一度お試しください`,
        error_26006:`エラーコード：26006、もう一度やり直してください`,
        error_26008:`エラー：26008。お問い合わせください`,
        go_to_the_home_page:`ホームページに移動します`,
        error_27101:`リクエストが失敗しました（エラーコード：27101）。 istを再試行してください`,
        error_27201:`エラーコード：27201、お問い合わせください`,
        error_27202:`エラーコード：27202、再試行してください`,
        error_27203:`リクエストが失敗しました（エラーコード：27203）。再試行してください`,
        error_27204:`無効なコード（エラーコード：27204）。`,
        error_27205:`リクエストが失敗しました（エラーコード：27205）。再試行してください`,
        error_27206:`リクエストが失敗しました（エラーコード：27206）。再試行してください`,
        error_27207:`リクエストが失敗しました（エラーコード：27207）。再試行してください`,
        no_history_found:`あなたはどんな道具も使ったことがありません！<a href="/">更新</a>または<a href="https://www.aiseesoft.jp">公式ウェブサイト</a>にアクセスしてください。`,
        error_25301:`エラー：25301。お問い合わせください`,
        error_25302:`エラー：25302。お問い合わせください`,
        error_25303:`リクエストが失敗しました（エラーコード：25303）。もう一度お試しください`,
        error_25304:`リクエストが失敗しました（エラーコード：25304）。もう一度お試しください`,
        error_25305:`リクエストが失敗しました（エラーコード：25305）。もう一度お試しください`,
        error_25306:`リクエストが失敗しました（エラーコード：25306）。もう一度お試しください`,
        image_upscaler_p:`透かしなし画像ダウンロードのメリット:`,
        Available_for:`できることは下記の通り：`,
        credit_per:`HD画像を1枚保存するには%sクレジットずつかかります`,
        still_valid:`購入したプランは依然として有効である`,
        credit:`クレジット`,
        pc_3rd_info:`登録済み。ソフト内で継続使用してください。`,
        use_online:`オンラインサービスを利用します`,
        use_download:`デスクトップアプリケーションを利用します`,
        use_immediately:`今すぐ利用`,
        Use_in_browser:`ブラウザで利用`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    nl:{
        overtime_1:`Foutcode:`,
        overtime_2:`, probeer het alstublieft opnieuw`,
        isnetwork:`Internetfout. Controleer en probeer het opnieuw`,
        email_placeholder:`E-mail`,
        email_empty:`Voer e-mail in`,
        email_not_valid:`E-mail is niet geldig`,
        email_not_valid_1:`Voer uw e-mailadres in`,
        email_not_valid_2:`E-mail is niet geldig, gebruik een ander adres.`,
        email_not_valid_3:`Geen e-mailinvoer`,
        password_placeholder:`Wachtwoord`,
        password_empty:`Voer wachtwoord in`,
        password_not_valid:`Onjuist account of wachtwoord`,
        password_not_valid_1:`Wachtwoord van meer dan 8 tekens is vereist`,
        password_not_valid_2:`Maak een wachtwoord aan`,
        password_placeholder_1:`Maak je wachtwoord aan`,
        password_placeholder_2:`Bevestig uw wachtwoord`,
        password_placeholder_3:`Nieuw wachtwoord aanmaken`,
        password_placeholder_4:`Bevestig nieuw wachtwoord`,
        password_placeholder_5:`oud wachtwoord invoeren`,
        copy_password_empty:`Bevestig het wachtwoord a.u.b.`,
        copy_password_not_valid:`bevestig alstublieft uw wachtwoord`,
        copy_passwords_inconsistent:`Uw wachtwoordbevestiging komt niet overeen`,
        code_empty:`Voer de verificatiecode in`,
        code_not_valid:`Ongeldige verificatie code`,
        code_placeholder:`Verificatie code`,
        not_received_code:`Als uw mailbox de verificatiecode lange tijd niet heeft ontvangen, vraag dan de verificatiecode opnieuw aan.`,
        get_first_code:`Vraag eerst de verificatiecode aan.`,
        connect_your_email_placeholder:`Verbind je e-mail`,
        last_name_placeholder:`Gelieve uw achternaam in te voeren`,
        first_name_placeholder:`Gelieve uw voornaam in te vullen`,
        address_placeholder:`Voer uw adres in`,
        no_code_text:`We hebben een verificatiecode gestuurd. Voer uw code in. <span class='tips'>Geen code ontvangen?`,
        no_code_text_1:`1.Zorg ervoor dat het e-mailadres geldig is en dat het e-mails kan ontvangen.`,
        no_code_text_2:`2.Omdat de e-mail automatisch door het systeem wordt verzonden, kan deze worden gemarkeerd als spam of ongewenste e-mail. Controleer of de e-mail zich in de Prullenbak bevindt.`,
        no_code_text_3:`3.Kan je je probleem niet oplossen?`,
        no_code_text_3_span:`Klik dan hier om contact met ons op te nemen.`,
        order_no:`U heeft geen producten gekocht. Voor vragen kunt u <a href="https://nl.aiseesoft.com/store/">Nu winkelen</a> Of <a href="https://nl.aiseesoft.com/contact.html"> Neem contact met ons op</a>`,
        error_24901:`Aan de huidige account is geen e-mail gekoppeld en kan de bestellingen niet vinden. Sluit een e-mail aan.`,
        user_guide:`Gebruikershandleiding>>`,
        download:`Downloaden`,
        order_number:`Bestelnummer`,
        Refund:`Terugbetaling`,
        Disabled:`Gehandicapt`,
        Normal:`normaal`,
        Modify:`Aanpassen`,
        Modify_1:`Wijzigen>>`,
        Connect:`Aansluiten`,
        unlink_success:`Ontkoppel succesvol`,
        connect_success:`Succesvol verbinden`,
        feedback_title:`Bedankt voor je feedback. Laat uw probleem achter en wij zullen binnen 24 uur op u reageren.`,
        feedback_thank_you:`Bedankt!<br />Uw feedback is succesvol verzonden.`,
        feedback_email:`Vul je email hier in!`,
        feedback_content:`Laat hier een probleem of suggestie achter.`,
        feedback_submit:`Indienen`,
        form_contents:`U heeft geen omschrijving ingevuld. Vul het in en verzend het opnieuw.`,
        old_password:`Voer a.u.b. oud wachtwoord in`,
        new_password:`Maak een nieuw wachtwoord aan`,
        old_new_password:`Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude`,
        incorrect_password:`Incorrect wachtwoord`,
        delete_no:`Verwijder nu`,
        Caps:`Caps Lock staat aan`,
        Get:`Krijgen`,
        Done:`Gedaan`,
        error_20001:`Fout: 20001. Log opnieuw in.`,
        error_20002:`Fout: 20002. Log opnieuw in.`,
        error_20003:`Fout: 20003. Log opnieuw in.`,
        error_20004:`Verzoek mislukt (foutcode: 20004). Probeer het opnieuw.`,
        error_20005:`Inlogsessie is verlopen (Fout: 20005). Log alsjeblieft nogmaals in.`,
        error_20006:`Verzoek mislukt (foutcode: 20006). Probeer het opnieuw.`,
        error_20007:`Inlogsessie is verlopen (Fout: 20007). Log alsjeblieft nogmaals in.`,
        error_20008:`Inlogsessie is verlopen (Fout: 20008). Log alsjeblieft nogmaals in.`,
        error_20009:`Inlogsessie is verlopen (Fout: 20009). Log alsjeblieft nogmaals in.`,
        error_20101:`Voer uw e-mailadres in (foutcode: 20101)`,
        error_20102:`E-mail is niet geldig (foutcode: 20102)`,
        error_20103:`Verzoek mislukt (foutcode: 20103). Probeer het alstublieft nog een keer`,
        error_20104:`E-mail is al in gebruik, <a href="https://account.aiseesoft.com/login">log in</a> of registreer met een nieuwe`,
        error_20105:`Verzoek mislukt (foutcode: 20105). Probeer het alstublieft nog een keer`,
        error_20106:`Kan e-mail niet verzenden, probeer het opnieuw`,
        error_20201:`Voer uw e-mailadres in (foutcode: 20201)`,
        error_20202:`Voer uw wachtwoord in (foutcode: 20202)`,
        error_20203:`Voer de verificatiecode in (foutcode: 20203)`,
        error_20204:`E-mail is niet geldig (foutcode: 20204)`,
        error_20205:`Wachtwoord van meer dan 8 tekens is vereist (foutcode: 20205)`,
        error_20206:`Verzoek mislukt (foutcode: 20206). Probeer het alstublieft nog een keer`,
        error_20207:`Ongeldige verificatie code`,
        error_20208:`Verzoek mislukt (foutcode: 20208). Probeer het alstublieft nog een keer`,
        error_20209:`Verzoek mislukt (foutcode: 20209). Probeer het alstublieft nog een keer`,
        error_20301:`Voer uw e-mailadres in (foutcode: 20301)`,
        error_20302:`Fout: 20302. Neem contact met ons op`,
        error_20303:`E-mail is niet geldig (foutcode: 20303)`,
        error_20304:`Verzoek mislukt (foutcode: 20304). Probeer het alstublieft nog een keer`,
        error_20305:`Account bestaat niet. Voer eerst opnieuw in of <a href="https://account.aiseesoft.com/register">Creëer het</a>.`,
        error_20306:`Nog geen wachtwoord. Gebruik <a href="https://account.aiseesoft.com/passwordless-login">Inloggen zonder wachtwoord</a> of <a href="create-password">stel een wachtwoord in</a> en log in.`,
        error_20308:`Verzoek mislukt (foutcode: 20308). Probeer het alstublieft nog een keer`,
        error_20401:`Uitloggen mislukt (foutcode: 20401). Probeer het alstublieft nog een keer`,
        error_20501:`Voer uw e-mailadres in (foutcode: 20501)`,
        error_20502:`E-mail is niet geldig (foutcode: 20502)`,
        error_20503:`Verzoek mislukt (foutcode: 20503). Probeer het alstublieft nog een keer`,
        error_20504:`Kan e-mail niet verzenden. Probeer het opnieuw.`,
        error_20601:`Voer uw e-mailadres in (foutcode: 20601)`,
        error_20602:`Voer de verificatiecode in (foutcode: 20602)`,
        error_20603:`E-mail is niet geldig (foutcode: 20603)`,
        error_20604:`Verzoek mislukt (foutcode: 20604). Probeer het alstublieft nog een keer`,
        error_20606:`Verzoek mislukt (foutcode: 20606). Probeer het alstublieft nog een keer`,
        error_20607:`Verzoek mislukt (foutcode: 20607). Probeer het alstublieft nog een keer`,
        error_20608:`Verzoek mislukt (foutcode: 20608). Probeer het alstublieft nog een keer`,
        error_20701:`Voer uw e-mailadres in (foutcode: 20701)`,
        error_20702:`E-mail is niet geldig (foutcode: 20702)`,
        error_20703:`Verzoek mislukt (foutcode: 20703). Probeer het alstublieft nog een keer`,
        error_20704:`Account bestaat niet. Voer eerst opnieuw in of <a href="https://account.aiseesoft.com/register">Creëer het</a>.`,
        error_20705:`Verzoek mislukt (foutcode: 20705). Probeer het alstublieft nog een keer`,
        error_20706:`Kan e-mail niet verzenden. Probeer het alstublieft nog een keer`,
        error_20801:`Voer uw e-mailadres in (foutcode: 20801)`,
        error_20802:`Fout: 20802. Neem contact met ons op`,
        error_20803:`Voer de verificatiecode in (foutcode: 20803)`,
        error_20804:`E-mail is niet geldig (foutcode: 20804)`,
        error_20805:`Wachtwoord van meer dan 8 tekens is vereist (foutcode: 20805)`,
        error_20806:`Verzoek mislukt (foutcode: 20806). Probeer het alstublieft nog een keer`,
        error_20808:`Verzoek mislukt (foutcode: 20808). Probeer het alstublieft nog een keer`,
        error_20901:`Verzoek mislukt (foutcode: 20901). Probeer het alstublieft nog een keer`,
        error_20902:`Verzoek mislukt (foutcode: 20902). Probeer het alstublieft nog een keer`,
        error_21000:`Wijzigingen worden opgeslagen`,
        error_21001:`Er wordt geen informatie ingediend (foutcode: 21001)`,
        error_21002:`Verzoek mislukt (foutcode: 21002). Probeer het alstublieft nog een keer`,
        error_21101:`Voer uw e-mailadres in (foutcode: 21101)`,
        error_21102:`E-mail is niet geldig (foutcode: 21102)`,
        error_21103:`Verzoek mislukt (foutcode: 21103), probeer het opnieuw`,
        error_21104:`E-mail is al verbonden, gebruik een nieuwe`,
        error_21105:`Verzoek mislukt (foutcode: 21105), probeer het opnieuw`,
        error_21106:`Kan e-mail niet verzenden. Probeer het alstublieft nog een keer`,
        error_21201:`Voer uw e-mailadres in (foutcode: 21201)`,
        error_21202:`Voer de verificatiecode in (foutcode: 21202)`,
        error_21203:`E-mail is niet geldig (foutcode: 21203)`,
        error_21204:`Fout: 21204. Neem contact met ons op`,
        error_21205:`Fout: 21205. Neem contact met ons op`,
        error_21206:`Wachtwoord van meer dan 8 tekens is vereist (foutcode: 21206)`,
        error_21207:`Verzoek mislukt (foutcode: 21207). Probeer het alstublieft nog een keer`,
        error_21209:`Verzoek mislukt (foutcode: 21209). Probeer het alstublieft nog een keer`,
        error_21301:`Gelieve het oude wachtwoord in te voeren (foutcode: 21301)`,
        error_21302:`Maak een nieuw wachtwoord aan (foutcode: 21302)`,
        error_21303:`Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude. (Fout: 21303)`,
        error_21304:`Wachtwoord van meer dan 8 tekens is vereist (foutcode: 21304)`,
        error_21306:`Verzoek mislukt (foutcode: 21306). Probeer het alstublieft nog een keer`,
        error_21402:`Verzoek mislukt (foutcode: 21402). Probeer het alstublieft nog een keer`,
        error_21403:`Kan verificatiecode niet verzenden. Stuur het alstublieft opnieuw`,
        error_21500:`Het account is verwijderd`,
        error_21501:`Voer de verificatiecode in (foutcode: 21501)`,
        error_21502:`Inlogsessie is verlopen (Fout: 21502). Log alsjeblieft nogmaals in.`,
        error_21503:`Verzoek mislukt (foutcode: 21503). Probeer het alstublieft nog een keer`,
        error_21505:`Verzoek mislukt (foutcode: 21505), probeer het opnieuw`,
        error_21601:`Fout: 20601. Neem contact met ons op`,
        error_21602:`Ongeldige verificatie (Fout: 20602). Probeer het opnieuw.`,
        error_21603:`Fout: 20603. Probeer het opnieuw`,
        error_21604:`Verzoek mislukt (foutcode: 21604). Probeer het alstublieft nog een keer`,
        error_21606:`Verzoek mislukt (foutcode: 21606). Probeer het alstublieft nog een keer`,
        error_21611:`Verzoek mislukt (foutcode: 21611). Probeer het alstublieft nog een keer`,
        error_21801:`Fout: 21801. Neem contact met ons op`,
        error_21802:`Verzoek mislukt (Fout: 21802). Probeer het alstublieft nog een keer`,
        error_21803:`Fout: 21803. Probeer het opnieuw`,
        error_21804:`Verzoek mislukt (foutcode: 21804). Probeer het alstublieft nog een keer`,
        error_21806:`Fout: 21806. Probeer het opnieuw`,
        error_21807:`Fout: 21807. Neem contact met ons op`,
        error_21808:`Fout: 21808. Neem contact met ons op`,
        error_21809:`Fout: 21809. Neem contact met ons op`,
        error_21810:`Fout: 21810. Neem contact met ons op`,
        error_21811:`Fout: 21811. Neem contact met ons op`,
        error_21812:`Fout: 21812. Neem contact met ons op`,
        error_21813:`Verzoek mislukt (foutcode: 21813). Probeer het alstublieft nog een keer`,
        error_21814:`Fout: 21814. Neem contact met ons op`,
        error_21815:`Verzoek mislukt (foutcode: 21815). Probeer het alstublieft nog een keer`,
        error_21816:`Fout: 21816. Neem contact met ons op`,
        error_21817:`Fout: 21817. Neem contact met ons op`,
        error_21818:`Fout: 21818. Neem contact met ons op`,
        error_21819:`Verzoek mislukt (foutcode: 21819). Probeer het alstublieft nog een keer`,
        error_21820:`Fout: 21820. Neem contact met ons op`,
        error_21821:`Fout: 21821. Neem contact met ons op`,
        error_21822:`Fout: 21822. Neem contact met ons op`,
        error_21823:`Verzoek mislukt (foutcode: 21823). Probeer het alstublieft nog een keer`,
        error_21824:`Verzoek mislukt (foutcode: 21824). Probeer het alstublieft nog een keer`,
        error_21825:`Verzoek mislukt (foutcode: 21825). Probeer het alstublieft nog een keer`,
        error_21826:`Verzoek mislukt (foutcode: 21826). Probeer het alstublieft nog een keer`,
        error_21828:`Fout: 21828. Neem contact met ons op`,
        error_21901:`Fout: 21901. Neem contact met ons op`,
        error_21902:`Verzoek mislukt (foutcode: 21902). Probeer het alstublieft nog een keer`,
        error_21903:`De accountstatus is gewijzigd (foutcode: 21903), ververs de pagina en probeer het opnieuw`,
        error_21904:`Fout: 21904. Probeer het opnieuw`,
        error_21905:`Fout: 21905. Probeer het opnieuw`,
        error_21906:`Verzoek mislukt (foutcode: 21906). Probeer het alstublieft nog een keer`,
        error_21907:`Google-account is gekoppeld aan een ander account`,
        error_21908:`Verzoek mislukt (foutcode: 21908). Probeer het alstublieft nog een keer`,
        error_22001:`Verzoek mislukt (foutcode: 22001). Probeer het alstublieft nog een keer`,
        error_22002:`Ontkoppelen mislukt zonder extra aanmelding`,
        error_22003:`Verzoek mislukt (foutcode: 22003). Probeer het alstublieft nog een keer`,
        error_22101:`Fout: 22101. Neem contact met ons op`,
        error_22102:`De accountstatus is gewijzigd (foutcode: 22102), ververs de pagina en probeer het opnieuw`,
        error_22103:`Verzoek mislukt (foutcode: 22103). Probeer het alstublieft nog een keer`,
        error_22104:`De accountstatus is gewijzigd (foutcode: 22104), ververs de pagina en probeer het opnieuw`,
        error_22105:`Fout: 22105. Probeer het opnieuw`,
        error_22106:`Fout: 22106. Probeer het opnieuw`,
        error_22107:`Fout: 22107. Neem contact met ons op`,
        error_22108:`Verzoek mislukt (foutcode: 22108). Probeer het alstublieft nog een keer`,
        error_22201:`Fout: 22201. Neem contact met ons op`,
        error_22202:`Ongeldige verificatie (Fout: 22202). Probeer het opnieuw.`,
        error_22203:`Fout: 22203. Probeer het opnieuw"`,
        error_22204:`Verzoek mislukt (foutcode: 22204). Probeer het alstublieft nog een keer`,
        error_22206:`Verzoek mislukt (foutcode: 22206). Probeer het alstublieft nog een keer`,
        error_22401:`Fout: 22401. Neem contact met ons op`,
        error_22402:`Ongeldige verificatie (Fout: 22402). Probeer het opnieuw.`,
        error_22403:`Fout: 22403. Probeer het opnieuw`,
        error_22404:`Verzoek mislukt (foutcode: 22404). Probeer het alstublieft nog een keer`,
        error_22405:`Facebook-account is gekoppeld aan andere e-mail`,
        error_22406:`Fout: 22406. Probeer het opnieuw`,
        error_22407:`Fout: 22407. Neem contact met ons op`,
        error_22408:`Fout: 22408. Neem contact met ons op`,
        error_22409:`Fout: 22409. Neem contact met ons op`,
        error_22410:`Fout: 224010. Neem contact met ons op`,
        error_22411:`Fout: 224011. Neem contact met ons op`,
        error_22412:`Fout: 224012. Neem contact met ons op`,
        error_22413:`Fout: 22413. Neem contact met ons op`,
        error_22414:`Verzoek mislukt (foutcode: 22414). Probeer het alstublieft nog een keer`,
        error_22415:`Fout: 22415. Neem contact met ons op`,
        error_22416:`Fout: 22416. Neem contact met ons op`,
        error_22417:`Fout: 22417. Neem contact met ons op`,
        error_22418:`Verzoek mislukt (foutcode: 22418). Probeer het alstublieft nog een keer`,
        error_22419:`Fout: 22419. Neem contact met ons op`,
        error_22420:`Fout: 22420. Neem contact met ons op`,
        error_22421:`Fout: 22421. Neem contact met ons op`,
        error_22422:`Verzoek mislukt (foutcode: 22422). Probeer het alstublieft nog een keer`,
        error_22423:`Verzoek mislukt (foutcode: 22423). Probeer het alstublieft nog een keer`,
        error_22424:`Verzoek mislukt (foutcode: 22424). Probeer het alstublieft nog een keer`,
        error_22425:`Verzoek mislukt (foutcode: 22425). Probeer het alstublieft nog een keer`,
        error_20098:`Foutcode: 20098. Als u browst in de privémodus, schakelt u over naar de normale modus en probeert u het opnieuw.`,
        error_22298:`Verzoek voor Google is mislukt (Fout: 22298). Probeer het opnieuw.`,
        error_22498:`Verzoek om Facebook is mislukt (Fout: 22498). Probeer het opnieuw.`,
        error_24902:`Verzoek mislukt (foutcode: 24902). Probeer het alstublieft nog een keer`,
        error_24903:`Verzoek mislukt (foutcode: 24903). Probeer het alstublieft nog een keer`,
        error_24904:`Verzoek mislukt (foutcode: 24904). Probeer het alstublieft nog een keer`,
        error_24905:`Verzoek mislukt (foutcode: 24905). Probeer het alstublieft nog een keer`,
        login_title:`Log in op Aiseesoft`,
        password_placeholder:`Wachtwoord`,
        log_in:`Log in`,
        no_account:`Geen account?`,
        create_it:`Creëer het`,
        or_log_in_with:`Of log in met`,
        passwordless_login:`Wachtwoordloos inloggen`,
        log_in_done:`Inloggen Gereed`,
        three_rd_account_connect_info:`Gefeliciteerd! U bent succesvol ingelogd. Nu kunt u één e-mailaccount koppelen dat wordt gebruikt om in de toekomst in te loggen.`,
        connect_now:`Verbind nu`,
        see_my_account:`Zie mijn account`,
        three_rd_login_merge_account:`Het e-mailadres van het account van de derde partij is aangemeld, wil je direct verbinding maken en inloggen met dit e-mailadres?`,
        connect_log_in:`Verbinding maken en inloggen`,
        create_an_account:`Account aanmaken`,
        connect_account:`Account koppelen`,
        connect_account_info:`Na het verbinden kunt u inloggen met dit account.`,
        back_to_log_in:`Terug naar Inloggen`,
        create_password:`Maak een wachtwoord`,
        create_now:`Nu maken`,
        password_login_subtitle:`Wachtwoordloos inloggen met e-mail`,
        account_login:`Account login`,
        rights:`Door dit account aan te maken, gaat u akkoord met de <a href="https://nl.aiseesoft.com/company/terms-and-conditions.html">Servicevoorwaarden</a> en <a href="https://nl.aiseesoft.com/privacy-policy.html">Privacybeleid</a>`,
        passwordless_login_done:`Inloggen zonder wachtwoord voltooid`,
        passwordless_login_info:`Gefeliciteerd, u heeft succesvol inloggen zonder wachtwoord voltooid. U kunt voor dit account een wachtwoord aanmaken en in de toekomst inloggen met het account en wachtwoord. <a href="/create-password" style="display: initial;">Nu maken</a>`,
        sign_up:`Inschrijven`,
        register_info:`Maak een account aan`,
        reset_now:`Nu resetten`,
        forgot_password:`Wachtwoord vergeten`,
        reset_password_subtitle:`Gebruik het e-mailadres van uw account om het wachtwoord opnieuw in te stellen`,
        plan_products:`Plan & Producten`,
        nick_name:`gebruikersnaam`,
        email:`E-mail`,
        my_products:`Mijn producten`,
        my_orders:`Mijn bestellingen`,
        unlink:`Ontkoppelen`,
        link:`Koppeling`,
        connected_accounts:`Verbonden accounts`,
        last_name:`Achternaam`,
        first_name:`Voornaam`,
        Gender:`Geslacht`,
        Birth:`Geboorte`,
        Month:`Maand`,
        Year:`Jaar`,
        Country_Region:`Land/Regio`,
        Address:`Adres`,
        Save:`Opslaan`,
        Date:`Datum`,
        Male:`Mannelijk`,
        Female:`Vrouwelijk`,
        Unspecified:`Niet gespecificeerd`,
        Security:`Veiligheid`,
        change_password:`Verander wachtwoord`,
        change_now:`Verander nu`,
        connect_email:`E-mail koppelen`,
        delete_account:`Account verwijderen`,
        delete_account_info:`Wanneer uw account wordt verwijderd, worden alle gegevens in Plan & Producten die aan uw account zijn gekoppeld, permanent verwijderd en kunt u deze mogelijk niet meer herstellen. We raden u aan om voorzichtig te werk te gaan.`,
        Delete:`Verwijderen`,
        Logout:`Uitloggen`,
        my_profile:`Mijn profiel`,
        guides_faqs:`Gidsen en veelgestelde vragen`,
        More:`Meer`,
        guides:`Gidsen`,
        register:`register`,
        hot_faq:`Veelgestelde vragen`,
        Contents:`Inhoud:`,
        contact_us:`Neem contact met ons op>>`,
        plan:`Plan`,
        unregistered:`niet geregistreerd`,
        buy_more:`Koop meer`,
        buy_again:`Opnieuw kopen`,
        buy_now:`Nu kopen`,
        free_no_limit:`Gratis & Geen limiet`,
        expired:`Verlopen`,
        lifetime:`Levenslang`,
        remain:`Blijven`,
        day_s:`Dag(en)`,
        error_24801:`Verzoek mislukt (foutcode: 24801). Probeer het alstublieft nog een keer`,
        no_app_found:`Geen app gevonden！<a href="/">Vernieuwen</a> of <a href="https://nl.aiseesoft.com/">Ga naar de officiële website</a>`,
        get_more:`Krijg meer >>`,
        edit_photo:`Bewerk foto`,
        select_photo:`Selecteer foto`,
        change_photo:`Verander foto`,
        cancel:`Annuleren`,
        hide_password:`Verberg wachtwoord`,
        show_password:`Laat wachtwoord zien`,
        zoom_in:`In zoomen`,
        zoom_out:`Uitzoomen`,
        rotate:`Draaien`,
        horizontal_flip:`Horizontaal omdraaien`,
        vertical_flip:`Verticale omslag`,
        country:`Land`,
        country_1:`Selecteer uw land/regio`,
        country_2:`Åaland-eiland`,
        country_3:`Afghanistan`,
        country_4:`Albanië`,
        country_5:`Algerije`,
        country_6:`Amerikaans Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarctica`,
        country_11:`Antigua en Barbuda`,
        country_12:`Argentinië`,
        country_13:`Armenië`,
        country_14:`Aruba`,
        country_15:`Australië`,
        country_16:`Oostenrijk`,
        country_17:`Azerbeidzjan`,
        country_18:`Bahrein`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Wit-Rusland`,
        country_22:`België`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnië en Herzegovina`,
        country_29:`Botswana`,
        country_30:`Bouvet-eiland`,
        country_31:`Brazilië`,
        country_32:`Brits-Indisch oceaan gebied`,
        country_33:`Britse Maagdeneilanden`,
        country_34:`Brunei`,
        country_35:`Bulgarije`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Cambodja`,
        country_39:`Kameroen`,
        country_40:`Canada`,
        country_41:`Kaapverdië`,
        country_42:`Caribisch Nederland`,
        country_43:`Kaaiman Eilanden`,
        country_44:`Centraal Afrikaanse Republiek`,
        country_45:`Tsjaad`,
        country_46:`Chili`,
        country_47:`China`,
        country_48:`Kersteiland`,
        country_49:`Cocos (Keeling) Eilanden`,
        country_50:`Colombia`,
        country_51:`Cook Eilanden`,
        country_52:`Costa Rica`,
        country_53:`"Ivoorkust"`,
        country_54:`Kroatië`,
        country_55:`Cuba`,
        country_56:`Cyprus`,
        country_57:`Tsjechië`,
        country_58:`Democratische Republiek Congo`,
        country_59:`Denemarken`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominicaanse Republiek`,
        country_63:`Ecuador`,
        country_64:`Egypte`,
        country_65:`El Salvador`,
        country_66:`Equatoriaal-Guinea`,
        country_67:`Eritrea`,
        country_68:`Estland`,
        country_69:`Ethiopië`,
        country_70:`Falkland Eilanden`,
        country_71:`Faeröer`,
        country_72:`Federale Staten van Micronesië`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`Frankrijk`,
        country_76:`Frans Guyana`,
        country_77:`Frans-Polynesië`,
        country_78:`Franse zuidelijke gebieden`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgië`,
        country_82:`Duitsland`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Groot-Brittannië (Verenigd Koninkrijk; Engeland)`,
        country_86:`Griekenland`,
        country_87:`Groenland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinee-Bissau`,
        country_95:`Guyana`,
        country_96:`Haïti`,
        country_97:`Heard- en McDonaldeilanden`,
        country_98:`Honduras`,
        country_99:`Hongkong`,
        country_100:`Hongarije`,
        country_101:`IJsland`,
        country_102:`India`,
        country_103:`Indonesië`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Ierland`,
        country_107:`eiland Man`,
        country_108:`Israël`,
        country_109:`Italië`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordanië`,
        country_114:`Kazachstan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Koeweit`,
        country_118:`Kirgizië`,
        country_119:`Laos`,
        country_120:`Letland`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libië`,
        country_125:`Liechtenstein`,
        country_126:`Litouwen`,
        country_127:`Luxemburg`,
        country_128:`Macau`,
        country_129:`Madagascar`,
        country_130:`Malawi`,
        country_131:`Maleisië`,
        country_132:`Maldiven`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshall eilanden`,
        country_136:`Martinique`,
        country_137:`Mauritanië`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexico`,
        country_141:`Moldavië`,
        country_142:`Monaco`,
        country_143:`Mongolië`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marokko`,
        country_147:`Mozambique`,
        country_148:`Birma (Birma)`,
        country_149:`Namibië`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Nederland`,
        country_153:`Nieuw-Caledonië`,
        country_154:`Nieuw-Zeeland`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolkeiland`,
        country_160:`Noord Korea`,
        country_161:`noordelijke Mariana eilanden`,
        country_162:`Noorwegen`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestijnse grondgebieden`,
        country_167:`Panama`,
        country_168:`Papoea-Nieuw-Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairneilanden`,
        country_172:`Polen`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republiek Macedonië (FYROM)`,
        country_177:`Republiek Congo`,
        country_178:`Bijeenkomst`,
        country_179:`Roemenië`,
        country_180:`Russische Federatie`,
        country_181:`Rwanda`,
        country_182:`Sint-Bartholomeus`,
        country_183:`Sint Maarten (Frankrijk)`,
        country_184:`Saint-Pierre en Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tomé & Principe`,
        country_188:`Saoedi-Arabië`,
        country_189:`Senegal`,
        country_190:`Servië`,
        country_191:`Seychellen`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slowakije`,
        country_195:`Slovenië`,
        country_196:`Solomon eilanden`,
        country_197:`Somalië`,
        country_198:`Zuid-Afrika`,
        country_199:`Zuid-Georgië en de Zuid-Sandwich Isla`,
        country_200:`Zuid-Korea`,
        country_201:`Zuid Soedan`,
        country_202:`Spanje`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena en afhankelijkheden`,
        country_205:`St. Kitts en Nevis`,
        country_206:`St Lucia`,
        country_207:`St. Vincent en de Grenadines`,
        country_208:`Soedan`,
        country_209:`Suriname`,
        country_210:`Swaziland`,
        country_211:`Zweden`,
        country_212:`Zwitserland`,
        country_213:`Syrië`,
        country_214:`Taiwan`,
        country_215:`Tadzjikistan`,
        country_216:`Tanzania`,
        country_217:`Sjabloon:Landgegevens SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`De Bahamas`,
        country_220:`de Comoren`,
        country_221:`De Filipijnen`,
        country_222:`Oost-Timor (Oost-Timor)`,
        country_223:`Gaan`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad en Tobago`,
        country_227:`Tunesië`,
        country_228:`kalkoen`,
        country_229:`Turkmenistan`,
        country_230:`Turks- en Caicoseilanden`,
        country_231:`Tuvalu`,
        country_232:`Oeganda`,
        country_233:`Oekraïne`,
        country_234:`Verenigde Arabische Emiraten`,
        country_235:`Kleine afgelegen eilanden van de Verenigde Staten`,
        country_236:`Verenigde Staten van Amerika (VS)`,
        country_237:`Amerikaanse Maagdeneilanden`,
        country_238:`Uruguay`,
        country_239:`Oezbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vaticaanstad (De Heilige Stoel)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis en Futuna`,
        country_245:`Westelijke Sahara`,
        country_246:`Jemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Inloggen met Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Alle rechten voorbehouden.`,
        State:`Staat`,
        Activation_code:`Activatie code`,
        Question:`Maak een lijst van alle apps die u hebt aangemeld`,
        Copy_complete:`Kopie voltooid`,


        store:`Winkel`,
        resource:`Bron`,
        support:`steun`,
        video_converter:`Video converteerder`,
        multimedia:`Multimedia`,
        data_recovery:`Data herstel`,
        utility:`Nut`,
        free_tools:`Gratis gereedschap`,
        for_windows:`Voor ramen`,
        for_mac:`Voor Mac`,
        pdf_solutions:`PDF-oplossingen`,
        data_manager:`Gegevens manager`,
        other_tools:`Andere hulpmiddelen`,
        free_online_solutions:`Gratis online oplossingen`,
        free_online_tools:`Gratis online tools`,
        desktop_freeware:`Desktop-freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS-omzetter`,
        product_4:`4K-omzetter`,
        product_5:`MXF-omzetter`,
        product_6:`3D-omzetter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video-omzetter voor Mac`,
        product_9:`MTS-converter voor Mac`,
        product_10:`4K-converter voor Mac`,
        product_11:`MXF Converter voor Mac`,
        product_12:`3D-converter voor Mac`,
        product_13:`Blu-ray speler`,
        product_14:`Videoverbeteraar`,
        product_15:`Video bewerker`,
        product_16:`Maker van diavoorstelling`,
        product_17:`Blu-ray-maker`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`dvd-maker`,
        product_20:`Mac Blu-ray-speler`,
        product_21:`Mac Video Enhancer`,
        product_22:`Video-editor voor Mac`,
        product_23:`DVD Creator voor Mac`,
        product_24:`MP4-converter voor Mac`,
        product_25:`Windows-gegevensherstel`,
        product_26:`FoneLab iPhone-gegevensherstel`,
        product_27:`FoneLab iOS-systeemherstel`,
        product_28:`FoneLab iOS-gegevensback-up &amp; Herstellen`,
        product_29:`FoneLab Android-gegevensherstel`,
        product_30:`Gebroken Android-gegevensextractie`,
        product_31:`Android-gegevensback-up & amp; Herstellen`,
        product_32:`Mac-gegevensherstel`,
        product_33:`Mac FoneLab iPhone-gegevensherstel`,
        product_34:`Mac FoneLab iOS-systeemherstel`,
        product_35:`Mac FoneLab iOS-gegevensback-up &amp; Herstellen`,
        product_36:`Mac FoneLab Android-gegevensherstel`,
        product_37:`Mac Android-gegevensback-up &amp; Herstellen`,
        product_38:`PDF Converter Ultiem`,
        product_39:`PDF Converter Ultimate voor Mac`,
        product_40:`PDF-fusie`,
        product_41:`Mac PDF naar ePub-converter`,
        product_42:`Mac-opruimer`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp-overdracht voor iOS`,
        product_45:`MobieSync`,
        product_46:`FoneGum`,
        product_47:`iPhone-reiniger`,
        product_48:`PPT naar video-omzetter`,
        product_49:`HEIC-omzetter`,
        product_50:`iPhone-ontgrendeling`,
        product_51:`Gratis video-omzetter online`,
        product_52:`Gratis audio-omzetter online`,
        product_53:`Gratis videocompressor online`,
        product_54:`Gratis videofusie online`,
        product_55:`Gratis HEIC-converter`,
        product_56:`Gratis beeldcompressor`,
        product_57:`Gratis watermerkverwijderaar`,
        product_58:`Gratis PDF-compressor online`,
        product_59:`Gratis afbeelding Upscaler online`,
        product_60:`Gratis achtergrondverwijderaar online`,
        product_61:`Gratis beeldconversie online`,
        product_62:`Gratis PDF PNG-converter online`,
        product_63:`Gratis video-omzetter`,
        product_64:`Gratis video naar GIF-converter`,
        product_65:`Gratis Android-gegevensherstel`,
        product_66:`Slideshow Maker`,
        product_67:`Gratis video-editor`,
        product_68:`Gratis PDF-viewer`,
        product_69:`Scherm opnemer`,
        product_70:`Mac-schermrecorder`,
        product_71:`Gratis schermrecorder online`,
        product_72:`Gratis audiorecorder online`,        
        product_73:`Telefoon Spiegel`,
        product_74:`AnyCoord - Locatiewisselaar`,
        product_75:`iPhone-wachtwoordbeheerder`,
        product_76:`Gratis audiocompressor online`,
        product_77:`Gratis videotrimmer online`,
        product_78:`Gratis videobijsnijder online`,
        product_79:`Gratis videorotator online`,
        product_80:`Gratis videolooper online`,
        product_81:`Gratis online PDF-JPG-converter`,
        product_81:`Gratis JPG Word-converter online`,
        category_1:`Fotobewerking`,
        category_2:`Video bewerking`,
        category_3:`Gom`,
        category_4:`Mediarecorder`,
        posted_by:`Gepost door`,
        to:`tot`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Hoe u op een efficiënte manier tekst uit afbeeldingen kunt verwijderen`,
        article_2:`Hoe de afbeeldingsgrootte in KB naar MB te vergroten zonder de kwaliteit te schaden`,
        article_3:`Getty Images Watermark verwijderen op Windows en Mac`,
        article_4:`De beste methode om een afbeelding met een lage resolutie gratis online naar een hoge resolutie te converteren`,
        article_5:`TikTok-gids | Zo maak je een videocollage op TikTok`,
        article_6:`5 eenvoudige manieren om VOB-bestanden te combineren die u moet kennen`,
        article_7:`Hoe u video kunt opschalen en gemakkelijk een hogere resolutie kunt krijgen`,
        article_8:`MP4-bestanden combineren op MacBook Pro/iMac/MacBook en meer`,
        article_9:`2 eenvoudige manieren om aankoopgeschiedenis op iPhone te verwijderen [opgelost]`,
        article_10:`Hoe Snapchat-foto's permanent uit chat en herinneringen te verwijderen`,
        article_11:`Hoe Spotlight-zoeksuggesties en geschiedenis op iPhone/iPad te verwijderen`,
        article_12:`[Opgelost] Hoe Instagram Cache op iPhone op 3 eenvoudige manieren te wissen`,
        article_13:`[Opgelost] QuickTime Player repareren werkt niet op Mac`,
        article_14:`Systeemaudio opnemen op Mac (Big Sur/Catalina/Mojave...)`,
        article_15:`Effectieve manieren om PowerPoint-schermopname te repareren werkt niet`,
        article_16:`Audio opnemen met VLC [opgelost]`,
        faq_title_1:`1. Hoe de 4104-fout op te lossen?`,
        faq_title_2:`2. Wanneer kan ik mijn geld terugkrijgen nadat het restitutieverzoek is goedgekeurd?`,
        faq_title_3:`3. Wat is "1 jaar abonnement" en hoe schakel ik de "Automatische verlengingsservice" uit?`,
        faq_title_4:`4. Kan ik mijn bestelling annuleren nadat ik een Aiseesoft-product heb geplaatst?`,
        faq_title_5:`5. Hoe kunt u een recensie bij u indienen?`,
        faq_info_1:`Dit komt door de beperkte licentie die je hebt gekregen. We gebruiken een enkele licentie voor het geval de registratiecode wordt gestolen en voorkomen dat de registratiecode wordt geschonden. Dus Reg Code die je hebt is een enkele licentie die alleen op één computer kan worden gebruikt. Als u het op meer dan één computer gebruikt, is er fout 4104. U kunt het pas gebruiken nadat we het voor u hebben gereset.`,
        faq_info_2:`Als u uw computer wijzigt of opnieuw installeert, kunnen wij deze voor u resetten. Neem contact op met de klantenservice: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> om de registratiecode voor u opnieuw in te stellen.`,
        faq_info_3:`Zodra uw teruggaveverzoek is goedgekeurd, duurt het normaal gesproken 3 tot 7 werkdagen voordat het tegoed op uw account wordt weergegeven. Soms kan het tijdens vakanties langer duren, maar het zal naar verwachting niet meer dan 10 dagen duren. Als u 20 dagen na de goedkeuring nog geen restitutie heeft ontvangen, neem dan contact met ons op via <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> om de restitutie te controleren.`,
        faq_info_4:`Automatische verlengingsservice betekent dat als u een abonnement van 1 jaar koopt, het betalingsplatform het bedrag automatisch in rekening brengt (inclusief toepasselijke belastingen) wanneer de productlicentie verloopt. Maar het betalingsformulier (Swreg of 2Checkout) stuurt u een brief om u eraan te herinneren dat de bestelling 7 dagen voor de vervaldatum verloopt. De e-mail bevat ook instructies voor het uitschakelen van de "Automatische verlengingsservice".`,
        faq_info_5:`Aangezien de bestelling automatisch wordt verwerkt, is het niet toegestaan om de bestelling te annuleren. Bovendien stelt de gratis proefversie u in staat meer te weten te komen over het Aiseesoft-programma en te controleren of het aan uw behoeften kan voldoen voordat u het aanschaft.`,
        faq_info_6:`Als je echt geld terug wilt krijgen, raadpleeg dan ons <a href="https://nl.aiseesoft.com/refund-policy.html">Teruggavebeleid</a>.`,
        faq_info_7:`We waarderen uw tijd en aandacht om ons een beoordeling te sturen. Neem contact met ons op (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) om uw recensie in te dienen en we zullen uw recensie serieus in overweging nemen voor onze productupdates.`,
        change_password_success:`wachtwoord succesvol veranderd`,
        successful_login_title:`Succesvol ingelogd`,
        product_page:`Productpagina>>`,
        successful_login_info:`Inloggen voltooid. Sluit de huidige pagina en ga terug naar het oorspronkelijke tabblad om door te gaan met het volgende proces. De huidige pagina wordt automatisch gesloten in 5 seconden. Als automatisch sluiten of sluiten door op de knop "Gereed" te klikken niet lukt, sluit dit tabblad dan direct.`,
        successful_login_info_firefox:`Inloggen voltooid. Sluit de huidige pagina en ga terug naar het oorspronkelijke tabblad om door te gaan met het volgende proces.`,
        my_account:`Mijn rekening`,
        my_history:`Mijn geschiedenis`,
        remove_watermark:`Verwijder watermerk`,
        no_history:`Geen geschiedenis`,
        history_all:`Selecteer alles`,
        history_open:`Open`,
        history_down:`Downloaden`,
        history_delete:`Verwijderen`,
        history_clear:`Wis ongeldig`,
        images:`afbeeldingen)`,
        use_this_function:`Gebruik deze functie>>`,
        hd_downloading:`Voordelen voor het downloaden van originele HD-afbeeldingen:`,
        lifetimeRemaining:`Resterende levensduur`,
        Remaining:`Overig`,
        email_verification:`email verificatie`,
        email_verification_info:`We hebben het verificatiebericht naar uw e-mailadres <span class="email"></span> gestuurd en voltooi de verificatie. Na verificatie worden de voordelen automatisch gesynchroniseerd.`,
        wrong_email:`Verkeerd email adres?`,
        click_here_to_modify:`Klik hier om te wijzigen`,
        get_more_help:`Meer hulp krijgen?`,
        click_here:`Klik hier`,
        email_verification_info_success:`Gefeliciteerd met het verifiëren van uw e-mailaccount.`,
        email_verification_info_error:`Verificatie mislukt vanwege de verlopen link.`,
        registration_succeeded:`Registratie geslaagd`,
        registration_succeeded_info_1:`Gefeliciteerd! U bent succesvol geregistreerd. We hebben het verificatiebericht naar uw e-mailadres <span class="email"></span> gestuurd en voltooi de verificatie om gebruik te kunnen maken van de voordelen van deze e-mail.`,
        registration_succeeded_info_2:`Klik op "Gereed" om terug te keren naar de startpagina en dit product te gebruiken.`,
        registration_succeeded_info_3:`Klik op "Gereed" om de huidige pagina te sluiten en terug te keren naar de startpagina voor de volgende bewerkingen. Als het sluiten mislukt, sluit u het tabblad handmatig.`,        
        verify_email:`Verifieer Email`,
        registered_email_not_verified:`Het geregistreerde e-mailadres is niet geverifieerd. Verifieer het alstublieft meteen.`,
        email_verification_time_1:`Geen verificatie-e-mail ontvangen?`,
        email_verification_time_2:`Na`,
        email_verification_time_3:`Klik hier om het opnieuw te verzenden`,
        error_26301:`Foutcode: 26301, neem contact met ons op`,
        error_26302:`Foutcode: 26302, neem contact met ons op`,
        error_26303:`E-mailformaatfout (foutcode: 26303). Voer het opnieuw in`,
        error_26304:`Wachtwoord van meer dan 8 tekens wordt aanbevolen (foutcode: 26304)`,
        error_26305:`Reuqest mislukt (foutcode: 26305). Probeer het opnieuw`,
        error_26306:`E-mail is geregistreerd, <a href="https://account.aiseesoft.com/login">ga naar inloggen</a>`,
        error_26307:`Reuqest mislukt (foutcode: 26307). Probeer het opnieuw`,
        error_26308:`Reuqest mislukt (foutcode: 26308). Probeer het opnieuw`,
        error_26401:`Foutcode: 26401, probeer het opnieuw`,
        error_26402:`E-mail is geverifieerd (foutcode: 26402), probeer het opnieuw`,
        error_26403:`Reuqest mislukt (foutcode: 26403). Probeer het opnieuw`,
        error_26404:`Verzoek mislukt (foutcode: 26404). Probeer het opnieuw`,
        error_26501:`Foutcode: 26501, neem contact met ons op`,
        error_26502:`Foutcode: 26502, neem contact met ons op`,
        error_26503:`E-mailformaatfout (foutcode: 26503). Voer het opnieuw in`,
        error_26504:`Reuqest mislukt (foutcode: 26504). Probeer het opnieuw`,
        error_26505:`E-mail is niet geregistreerd, <a href="https://account.aiseesoft.com/register">registreer het eerst</a>`,
        error_26506:`E-mail is geverifieerd.`,
        error_26507:`Reuqest mislukt (foutcode: 26507). Probeer het opnieuw`,
        error_26508:`Verificatie mislukt (foutcode: 26508), probeer het opnieuw`,
        error_26509:`Verzoek mislukt (foutcode: 26509), probeer het opnieuw`,
        error_26510:`Foutcode: 26510, neem contact met ons op`,
        error_26601:`Foutcode: 26601, neem contact met ons op`,
        error_26602:`Foutcode: 26602, neem contact met ons op`,
        error_26603:`Reuqest mislukt (foutcode: 26603). Probeer het opnieuw`,
        error_26604:`Foutcode: 26604, neem contact met ons op`,
        error_26605:`Foutcode: 26605, neem contact met ons op`,
        error_26701:`Foutcode: 26701, neem contact met ons op`,
        error_26702:`Reuqest mislukt (foutcode: 26702). Probeer het opnieuw`,
        error_26703:`Foutcode: 26703, neem contact met ons op`,
        error_26704:`Foutcode: 26704, neem contact met ons op`,
        error_26705:`Wacht met inloggen (foutcode: 26705). Probeer het opnieuw`,
        no_cookie:`U heeft de functie Alle cookies blokkeren in uw browser ingeschakeld, dus u kunt niet inloggen. Ga naar Instellingen om het vakje Alle cookies toestaan aan te vinken.`,
        error_26801:`Foutcode: 26801, neem contact met ons op`,
        error_26802:`Foutcode: 26802, neem contact met ons op`,
        error_26803:`Verzoek mislukt (foutcode: 26803). Probeer het opnieuw`,
        error_26804:`Verzoek mislukt (foutcode: 26804). Probeer het opnieuw`,
        error_order:`Aanvraag mislukt (foutcode: 27098), probeer het opnieuw!`,
        error_order1:`De bestellingsquery is onvolledig, foutcode:`,
        error_order2:`, ververs a.u.b. en probeer het opnieuw.`,
        modify_email_title:`E-mail wijzigen`,
        modify_email_info:`U kunt het aangepaste e-mailadres gebruiken om in te loggen op uw account.`,
        images_per:`Afbeeldingen per`,
        error_26101:`Fout: 26101. Neem contact met ons op`,
        error_26102:`Fout: 26102. Neem contact met ons op`,
        error_26103:`Verzoek mislukt (foutcode:26103). Probeer het nog een keer`,
        error_26104:`Foutcode: 26104, probeer het opnieuw`,
        error_26105:`Foutcode: 26105, probeer het opnieuw`,
        error_26106:`Verwijderen mislukt (foutcode: 26106). Probeer het alstublieft nog een keer`,
        error_26201:`Fout: 26201. Neem contact met ons op`,
        error_26202:`Verzoek mislukt (foutcode:26202). Probeer het nog een keer`,
        error_26001:`Fout: 26001. Neem contact met ons op`,
        error_26002:`Fout: 26002. Neem contact met ons op`,
        error_26003:`Fout: 26003. Neem contact met ons op`,
        error_26004:`Fout: 26004. Neem contact met ons op`,
        error_26005:`Verzoek mislukt (foutcode:26005). Probeer het nog een keer`,
        error_26006:`Foutcode: 26006, probeer het opnieuw`,
        error_26008:`Fout: 26008. Neem contact met ons op`,
        go_to_the_home_page:`Ga naar de startpagina`,
        error_27101:`Verzoek mislukt (foutcode: 27101). Probeer het opnieuw`,
        error_27201:`Foutcode: 27201, neem contact met ons op`,
        error_27202:`Foutcode: 27202, probeer het opnieuw`,
        error_27203:`Verzoek mislukt (foutcode: 27203). Probeer het opnieuw`,
        error_27204:`Ongeldige code (foutcode: 27204).`,
        error_27205:`Verzoek mislukt (foutcode: 27205). Probeer het opnieuw`,
        error_27206:`Verzoek mislukt (foutcode: 27206). Probeer het opnieuw`,
        error_27207:`Verzoek mislukt (foutcode: 27207). Probeer het opnieuw`,
        no_history_found:`Je hebt geen gereedschap gebruikt! <a href="/">Vernieuwen</a> of <a href="https://nl.aiseesoft.com/">Ga naar de officiële website</a>`,
        error_25301:`Fout: 25301. Neem contact met ons op`,
        error_25302:`Fout: 25302. Neem contact met ons op`,
        error_25303:`Verzoek mislukt (foutcode: 25303). Probeer het alstublieft nog een keer`,
        error_25304:`Verzoek mislukt (foutcode: 25304). Probeer het alstublieft nog een keer`,
        error_25305:`Verzoek mislukt (foutcode: 25305). Probeer het alstublieft nog een keer`,
        error_25306:`Verzoek mislukt (foutcode: 25306). Probeer het alstublieft nog een keer`,
        image_upscaler_p:`Afbeelding zonder watermerk downloaden voordelen:`,
        Available_for:`Beschikbaar voor:`,
        credit_per:`%s credit(s) per HD-afbeelding`,
        still_valid:`Gekochte abonnement(en) zijn nog steeds geldig`,
        credit:`tegoed(en)`,
        pc_3rd_info:`Succesvol inloggen. Ga naar de applicatie voor verdere bediening.`,
        use_online:`Maak gebruik van de onlinedienst`,
        use_download:`Gebruik het desktopprogramma`,
        use_immediately:`Gebruik onmiddellijk`,
        Use_in_browser:`Gebruik in browser`,
        win_desktop:`ramen`,
        Mac_desktop:`Mac`,
    },
    no:{
        overtime_1:`Feil kode:`,
        overtime_2:`, prøv igjen`,
        isnetwork:`Internett -feil. Sjekk og prøv igjen`,
        email_placeholder:`E -post`,
        email_empty:`Skriv inn e -post`,
        email_not_valid:`E -post er ikke gyldig`,
        email_not_valid_1:`Skriv inn e -posten din`,
        email_not_valid_2:`E -post er ikke gyldig. Bruk en annen adresse.`,
        email_not_valid_3:`Ingen e -postinngang`,
        password_placeholder:`Passord`,
        password_empty:`Skriv inn passord`,
        password_not_valid:`Feil konto eller passord`,
        password_not_valid_1:`Passord over 8 tegn kreves`,
        password_not_valid_2:`Opprett et passord`,
        password_placeholder_1:`lag ditt passord`,
        password_placeholder_2:`Bekreft passordet ditt`,
        password_placeholder_3:`Opprett nytt passord`,
        password_placeholder_4:`Bekrefte nytt passord`,
        password_placeholder_5:`Skriv inn det gamle passordet`,
        copy_password_empty:`Bekreft passord`,
        copy_password_not_valid:`Bekreft passordet ditt`,
        copy_passwords_inconsistent:`Passordbekreftelsen din stemmer ikke overens`,
        code_empty:`Skriv inn bekreftelseskoden`,
        code_not_valid:`Ugyldig bekreftelseskode`,
        code_placeholder:`Bekreftelseskode`,
        not_received_code:`Hvis postboksen din ikke har mottatt bekreftelseskoden på lenge, må du få bekreftelseskoden igjen.`,
        get_first_code:`Vennligst få bekreftelseskoden først.`,
        connect_your_email_placeholder:`Koble til e -posten din`,
        last_name_placeholder:`Vennligst fyll inn ditt etternavn`,
        first_name_placeholder:`Skriv inn fornavnet ditt`,
        address_placeholder:`Skriv inn adressen din`,
        no_code_text:`Vi har sendt en bekreftelseskode. Skriv inn koden din. <span class='tips'>Mottok du ikke en kode?`,
        no_code_text_1:`1.Vennligst sørg for at e -postadressen er gyldig og at den kan motta e -post.`,
        no_code_text_2:`2.For e -posten blir sendt av systemet automatisk, kan den bli flagget som søppelpost eller søppelpost. Kontroller om e -posten er i papirkurven.`,
        no_code_text_3:`3. kan ikke løse problemet ditt?`,
        no_code_text_3_span:`Klikk deretter her for å kontakte oss.`,
        order_no:`Du har ikke kjøpt noen produkter. Eventuelle spørsmål, vennligst <a href="https://no.aiseesoft.com/store/">Kjøp nå</a> eller <a href="https://no.aiseesoft.com/contact.html"> Kontakt oss</a>`,
        error_24901:`Nåværende konto har ingen e -postkobling, og kan ikke finne bestillingene. Vennligst koble til en e -post.`,
        user_guide:`Brukerhåndbok >>`,
        download:`nedlasting`,
        order_number:`Ordrenummer`,
        Refund:`Refusjon`,
        Disabled:`Funksjonshemmet`,
        Normal:`Vanlig`,
        Modify:`Endre`,
        Modify_1:`Endre >>`,
        Connect:`Koble`,
        unlink_success:`Koble fra koblingen`,
        connect_success:`Koble til`,
        feedback_title:`Takk for din tilbakemelding. Vennligst la problemet være, så svarer vi deg innen 24 timer.`,
        feedback_thank_you:`Takk! <br /> Tilbakemeldingen din er sendt.`,
        feedback_email:`Skriv inn din epost her!`,
        feedback_content:`Legg igjen et problem eller forslag du møtte her.`,
        feedback_submit:`Sende inn`,
        form_contents:`Du har ikke angitt noen beskrivelse. Skriv inn det og send det på nytt.`,
        old_password:`Skriv inn gammelt passord`,
        new_password:`Opprett et nytt passord`,
        old_new_password:`Det nye passordet kan ikke være det samme som det gamle`,
        incorrect_password:`feil passord`,
        delete_no:`Slett nå`,
        Caps:`Caps Lock er på`,
        Get:`Få`,
        Done:`Ferdig`,
        error_20001:`Feil: 20001. Logg på igjen.`,
        error_20002:`Feil: 20002. Logg på igjen.`,
        error_20003:`Feil: 20003. Logg på igjen.`,
        error_20004:`Forespørselen mislyktes (feilkode: 20004). Prøv igjen.`,
        error_20005:`Påloggingsøkten er utløpt (Feil: 20005). Logg inn igjen.`,
        error_20006:`Forespørselen mislyktes (feilkode: 20006). Prøv igjen.`,
        error_20007:`Påloggingsøkten er utløpt (Feil: 20007). Logg inn igjen.`,
        error_20008:`Påloggingsøkten er utløpt (Feil: 20008). Logg inn igjen.`,
        error_20009:`Påloggingsøkten er utløpt (Feil: 20009). Logg inn igjen.`,
        error_20101:`Skriv inn e -posten din (feilkode: 20101)`,
        error_20102:`E -postadressen er ikke gyldig (feilkode: 20102)`,
        error_20103:`Forespørselen mislyktes (feilkode: 20103). Prøv igjen`,
        error_20104:`E -post er allerede i bruk. <a href="https://account.aiseesoft.com/login"> logg inn </a> eller registrer deg hos en ny`,
        error_20105:`Forespørselen mislyktes (feilkode: 20105). Prøv igjen`,
        error_20106:`Kunne ikke sende e -post. Prøv den på nytt`,
        error_20201:`Skriv inn e -posten din (feilkode: 20201)`,
        error_20202:`Skriv inn passordet ditt (feilkode: 20202)`,
        error_20203:`Skriv inn bekreftelseskode (feilkode: 20203)`,
        error_20204:`E -postadressen er ikke gyldig (feilkode: 20204)`,
        error_20205:`Passord over 8 tegn kreves (feilkode: 20205)`,
        error_20206:`Forespørselen mislyktes (feilkode: 20206). Prøv igjen`,
        error_20207:`Ugyldig bekreftelseskode`,
        error_20208:`Forespørselen mislyktes (feilkode: 20208). Prøv igjen`,
        error_20209:`Forespørselen mislyktes (feilkode: 20209). Prøv igjen`,
        error_20301:`Skriv inn e -posten din (feilkode: 20301)`,
        error_20302:`Feil: 20302. Ta kontakt med oss`,
        error_20303:`E -postadressen er ikke gyldig (feilkode: 20303)`,
        error_20304:`Forespørselen mislyktes (feilkode: 20304). Prøv igjen`,
        error_20305:`Kontoen eksisterer ikke. Skriv inn på nytt eller <a href="https://account.aiseesoft.com/register">Lag det</a> på nytt.`,
        error_20306:`Ingen passord ennå. Bruk <a href="https://account.aiseesoft.com/passwordless-login"> Påloggingsløs pålogging </a> eller <a href="create-password"> angi et passord </a> og logg inn.`,
        error_20308:`Forespørselen mislyktes (feilkode: 20308). Prøv igjen`,
        error_20401:`Kunne ikke logge ut (feilkode: 20401). Prøv igjen`,
        error_20501:`Skriv inn e -posten din (feilkode: 20501)`,
        error_20502:`E -postadressen er ikke gyldig (feilkode: 20502)`,
        error_20503:`Forespørselen mislyktes (feilkode: 20503). Prøv igjen`,
        error_20504:`Kunne ikke sende e -post. Prøv igjen.`,
        error_20601:`Skriv inn e -posten din (feilkode: 20601)`,
        error_20602:`Skriv inn bekreftelseskode (feilkode: 20602)`,
        error_20603:`E -postadressen er ikke gyldig (feilkode: 20603)`,
        error_20604:`Forespørselen mislyktes (feilkode: 20604). Prøv igjen`,
        error_20606:`Forespørselen mislyktes (feilkode: 20606). Prøv igjen`,
        error_20607:`Forespørselen mislyktes (feilkode: 20607). Prøv igjen`,
        error_20608:`Forespørselen mislyktes (feilkode: 20608). Prøv igjen`,
        error_20701:`Skriv inn e -posten din (feilkode: 20701)`,
        error_20702:`E -post er ikke gyldig (feilkode: 20702)`,
        error_20703:`Forespørselen mislyktes (feilkode: 20703). Prøv igjen`,
        error_20704:`Kontoen eksisterer ikke. Skriv inn på nytt eller <a href="https://account.aiseesoft.com/register">Lag det</a> på nytt.`,
        error_20705:`Forespørselen mislyktes (feilkode: 20705). Prøv igjen`,
        error_20706:`Kunne ikke sende e -post. Prøv igjen`,
        error_20801:`Skriv inn e -posten din (feilkode: 20801)`,
        error_20802:`Feil: 20802. Ta kontakt med oss`,
        error_20803:`Skriv inn bekreftelseskode (feilkode: 20803)`,
        error_20804:`E -postadressen er ikke gyldig (feilkode: 20804)`,
        error_20805:`Passord over 8 tegn kreves (feilkode: 20805)`,
        error_20806:`Forespørselen mislyktes (feilkode: 20806). Prøv igjen`,
        error_20808:`Forespørselen mislyktes (feilkode: 20808). Prøv igjen`,
        error_20901:`Forespørselen mislyktes (feilkode: 20901). Prøv igjen`,
        error_20902:`Forespørselen mislyktes (feilkode: 20902). Prøv igjen`,
        error_21000:`Endringer lagres`,
        error_21001:`Ingen informasjon er gitt (feilkode: 21001)`,
        error_21002:`Forespørselen mislyktes (feilkode: 21002). Prøv igjen`,
        error_21101:`Skriv inn e -posten din (feilkode: 21101)`,
        error_21102:`E -post er ikke gyldig (feilkode: 21102)`,
        error_21103:`Forespørselen mislyktes (feilkode: 21103). Prøv den på nytt`,
        error_21104:`E -post er allerede tilkoblet. Bruk en ny`,
        error_21105:`Forespørselen mislyktes (feilkode: 21105). Prøv den på nytt`,
        error_21106:`Kunne ikke sende e -post. Prøv igjen`,
        error_21201:`Skriv inn e -posten din (feilkode: 21201)`,
        error_21202:`Skriv inn bekreftelseskode (feilkode: 21202)`,
        error_21203:`E -post er ikke gyldig (feilkode: 21203)`,
        error_21204:`Feil: 21204. Ta kontakt med oss`,
        error_21205:`Feil: 21205. Ta kontakt med oss`,
        error_21206:`Passord over 8 tegn kreves (feilkode: 21206)`,
        error_21207:`Forespørselen mislyktes (feilkode: 21207). Prøv igjen`,
        error_21209:`Forespørselen mislyktes (feilkode: 21209). Prøv igjen`,
        error_21301:`Skriv inn gammelt passord (feilkode: 21301)`,
        error_21302:`Opprett et nytt passord (feilkode: 21302)`,
        error_21303:`Det nye passordet kan ikke være det samme som det gamle. (Feil: 21303)`,
        error_21304:`Passord over 8 tegn kreves (feilkode: 21304)`,
        error_21306:`Forespørselen mislyktes (feilkode: 21306). Prøv igjen`,
        error_21402:`Forespørselen mislyktes (feilkode: 21402). Prøv igjen`,
        error_21403:`Kunne ikke sende bekreftelseskoden. Send den på nytt`,
        error_21500:`Kontoen er slettet`,
        error_21501:`Skriv inn bekreftelseskode (feilkode: 21501)`,
        error_21502:`Påloggingsøkten er utløpt (Feil: 21502). Logg inn igjen.`,
        error_21503:`Forespørselen mislyktes (feilkode: 21503). Prøv igjen`,
        error_21505:`Forespørselen mislyktes (feilkode: 21505). Prøv den på nytt`,
        error_21601:`Feil: 20601. Ta kontakt med oss`,
        error_21602:`Ugyldig bekreftelse (feil: 20602). Prøv igjen.`,
        error_21603:`Feil: 20603. Prøv igjen`,
        error_21604:`Forespørselen mislyktes (feilkode: 21604). Prøv igjen`,
        error_21606:`Forespørselen mislyktes (feilkode: 21606). Prøv igjen`,
        error_21611:`Forespørselen mislyktes (feilkode: 21611). Prøv igjen`,
        error_21801:`Feil: 21801. Ta kontakt med oss`,
        error_21802:`Forespørselen mislyktes (feil: 21802). Prøv igjen`,
        error_21803:`Feil: 21803. Prøv igjen`,
        error_21804:`Forespørselen mislyktes (feilkode: 21804). Prøv igjen`,
        error_21806:`Feil: 21806. Prøv igjen`,
        error_21807:`Feil: 21807. Ta kontakt med oss`,
        error_21808:`Feil: 21808. Ta kontakt med oss`,
        error_21809:`Feil: 21809. Ta kontakt med oss`,
        error_21810:`Feil: 21810. Ta kontakt med oss`,
        error_21811:`Feil: 21811. Ta kontakt med oss`,
        error_21812:`Feil: 21812. Ta kontakt med oss`,
        error_21813:`Forespørselen mislyktes (feilkode: 21813). Prøv igjen`,
        error_21814:`Feil: 21814. Ta kontakt med oss`,
        error_21815:`Forespørselen mislyktes (feilkode: 21815). Prøv igjen`,
        error_21816:`Feil: 21816. Ta kontakt med oss`,
        error_21817:`Feil: 21817. Ta kontakt med oss`,
        error_21818:`Feil: 21818. Ta kontakt med oss`,
        error_21819:`Forespørselen mislyktes (feilkode: 21819). Prøv igjen`,
        error_21820:`Feil: 21820. Ta kontakt med oss`,
        error_21821:`Feil: 21821. Ta kontakt med oss`,
        error_21822:`Feil: 21822. Ta kontakt med oss`,
        error_21823:`Forespørselen mislyktes (feilkode: 21823). Prøv igjen`,
        error_21824:`Forespørselen mislyktes (feilkode: 21824). Prøv igjen`,
        error_21825:`Forespørselen mislyktes (feilkode: 21825). Prøv igjen`,
        error_21826:`Forespørselen mislyktes (feilkode: 21826). Prøv igjen`,
        error_21828:`Feil: 21828. Ta kontakt med oss`,
        error_21901:`Feil: 21901. Ta kontakt med oss`,
        error_21902:`Forespørselen mislyktes (feilkode: 21902). Prøv igjen`,
        error_21903:`Kontostatusen er endret (feilkode: 21903). Oppdater siden og prøv igjen`,
        error_21904:`Feil: 21904. Prøv igjen`,
        error_21905:`Feil: 21905. Prøv igjen`,
        error_21906:`Forespørselen mislyktes (feilkode: 21906). Prøv igjen`,
        error_21907:`Google -kontoen er knyttet til en annen konto`,
        error_21908:`Forespørselen mislyktes (feilkode: 21908). Prøv igjen`,
        error_22001:`Forespørselen mislyktes (feilkode: 22001). Prøv igjen`,
        error_22002:`Koble fra mislyktes uten ytterligere pålogging`,
        error_22003:`Forespørselen mislyktes (feilkode: 22003). Prøv igjen`,
        error_22101:`Feil: 22101. Ta kontakt med oss`,
        error_22102:`Kontostatusen er endret (feilkode: 22102). Oppdater siden og prøv igjen`,
        error_22103:`Forespørselen mislyktes (feilkode: 22103). Prøv igjen`,
        error_22104:`Kontostatusen er endret (feilkode: 22104). Oppdater siden og prøv igjen`,
        error_22105:`Feil: 22105. Prøv igjen`,
        error_22106:`Feil: 22106. Prøv igjen`,
        error_22107:`Feil: 22107. Ta kontakt med oss`,
        error_22108:`Forespørselen mislyktes (feilkode: 22108). Prøv igjen`,
        error_22201:`Feil: 22201. Ta kontakt med oss`,
        error_22202:`Ugyldig bekreftelse (feil: 22202). Prøv igjen.`,
        error_22203:`Feil: 22203. Prøv det igjen "`,
        error_22204:`Forespørselen mislyktes (feilkode: 22204). Prøv igjen`,
        error_22206:`Forespørselen mislyktes (feilkode: 22206). Prøv igjen`,
        error_22401:`Feil: 22401. Ta kontakt med oss`,
        error_22402:`Ugyldig bekreftelse (feil: 22402). Prøv igjen.`,
        error_22403:`Feil: 22403. Prøv igjen`,
        error_22404:`Forespørselen mislyktes (feilkode: 22404). Prøv igjen`,
        error_22405:`Facebook -kontoen er knyttet til annen e -post`,
        error_22406:`Feil: 22406. Prøv igjen`,
        error_22407:`Feil: 22407. Ta kontakt med oss`,
        error_22408:`Feil: 22408. Ta kontakt med oss`,
        error_22409:`Feil: 22409. Ta kontakt med oss`,
        error_22410:`Feil: 224010. Ta kontakt med oss`,
        error_22411:`Feil: 224011. Ta kontakt med oss`,
        error_22412:`Feil: 224012. Ta kontakt med oss`,
        error_22413:`Feil: 22413. Ta kontakt med oss`,
        error_22414:`Forespørselen mislyktes (feilkode: 22414). Prøv igjen`,
        error_22415:`Feil: 22415. Ta kontakt med oss`,
        error_22416:`Feil: 22416. Ta kontakt med oss`,
        error_22417:`Feil: 22417. Ta kontakt med oss`,
        error_22418:`Forespørselen mislyktes (feilkode: 22418). Prøv igjen`,
        error_22419:`Feil: 22419. Ta kontakt med oss`,
        error_22420:`Feil: 22420. Ta kontakt med oss`,
        error_22421:`Feil: 22421. Ta kontakt med oss`,
        error_22422:`Forespørselen mislyktes (feilkode: 22422). Prøv igjen`,
        error_22423:`Forespørselen mislyktes (feilkode: 22423). Prøv igjen`,
        error_22424:`Forespørselen mislyktes (feilkode: 22424). Prøv igjen`,
        error_22425:`Forespørselen mislyktes (feilkode: 22425). Prøv igjen`,
        error_20098:`Feilkode: 20098. Hvis du surfer i privat modus, kan du bytte til normal modus og prøve igjen.`,
        error_22298:`Forespørsel om Google mislyktes (feil: 22298). Prøv igjen.`,
        error_22498:`Forespørsel om Facebook mislyktes (feil: 22498). Prøv igjen.`,
        error_24902:`Forespørselen mislyktes (feilkode: 24902). Prøv igjen`,
        error_24903:`Forespørselen mislyktes (feilkode: 24903). Prøv igjen`,
        error_24904:`Forespørselen mislyktes (feilkode: 24904). Prøv igjen`,
        error_24905:`Forespørselen mislyktes (feilkode: 24905). Prøv igjen`,
        login_title:`Logg på Aiseesoft`,
        password_placeholder:`Passord`,
        log_in:`Logg Inn`,
        no_account:`Ingen konto?`,
        create_it:`Lag det`,
        or_log_in_with:`Eller logg inn med`,
        passwordless_login:`Passordløs pålogging`,
        log_in_done:`Logg på Ferdig`,
        three_rd_account_connect_info:`Gratulerer! Du har logget deg på. Nå kan du koble til en e -postkonto som brukes til å logge på i fremtiden.`,
        connect_now:`Koble til nå`,
        see_my_account:`Se kontoen min`,
        three_rd_login_merge_account:`Tredjeparts konto-e-postadressen er registrert. Vil du koble deg til og logge på med denne e-postadressen direkte?`,
        connect_log_in:`Koble til og logg inn`,
        create_an_account:`Opprett en konto`,
        connect_account:`Koble til konto`,
        connect_account_info:`Etter tilkobling kan du logge på med denne kontoen.`,
        back_to_log_in:`Tilbake til innlogging`,
        create_password:`Lag passord`,
        create_now:`Opprett nå`,
        password_login_subtitle:`Passordløs pålogging med e -post`,
        account_login:`Kontoinnlogging`,
        rights:`Ved å opprette denne kontoen godtar du <a href="https://no.aiseesoft.com/company/terms-and-conditions.html"> vilkår for bruk </a> og <a href="https://no.aiseesoft.com/privacy-policy.html"> Personvernpolicy </a>`,
        passwordless_login_done:`Passordløs pålogging utført`,
        passwordless_login_info:`Gratulerer, du har fullført passordløs pålogging. Du kan opprette et passord for denne kontoen og logge på med kontoen og passordet i fremtiden. <a href="/create-password" style="display: initial;"> Opprett nå </a>`,
        sign_up:`Melde deg på`,
        register_info:`lag din konto`,
        reset_now:`Tilbakestill nå`,
        forgot_password:`Glemt passord`,
        reset_password_subtitle:`Bruk kontoen din for å tilbakestille passordet`,
        plan_products:`Plan og produkter`,
        nick_name:`Brukernavn`,
        email:`E -post`,
        my_products:`Mine produkter`,
        my_orders:`Mine bestillinger`,
        unlink:`Fjern koblingen`,
        link:`Lenke`,
        connected_accounts:`Tilkoblede kontoer`,
        last_name:`Etternavn`,
        first_name:`Fornavn`,
        Gender:`Kjønn`,
        Birth:`Fødsel`,
        Month:`Måned`,
        Year:`År`,
        Country_Region:`Land/region`,
        Address:`Adresse`,
        Save:`Lagre`,
        Date:`Dato`,
        Male:`Hann`,
        Female:`Hunn`,
        Unspecified:`Uspesifisert`,
        Security:`Sikkerhet`,
        change_password:`Bytt passord`,
        change_now:`Endre nå`,
        connect_email:`Koble til e -post`,
        delete_account:`Slett konto`,
        delete_account_info:`Når kontoen din slettes, blir alle dataene i Plan og produkter knyttet til kontoen din slettet permanent, og det er ikke sikkert du kan gjenopprette den. Vi anbefaler at du går forsiktig.`,
        Delete:`Slett`,
        Logout:`Logg ut`,
        my_profile:`Min profil`,
        guides_faqs:`Guider og vanlige spørsmål`,
        More:`Mer`,
        guides:`Guider`,
        register:`registrere`,
        hot_faq:`Hot FAQ`,
        Contents:`Innhold:`,
        contact_us:`Kontakt oss >>`,
        plan:`Plan`,
        unregistered:`Uregistrert`,
        buy_more:`Kjøp mer`,
        buy_again:`Kjøp igjen`,
        buy_now:`Kjøp nå`,
        free_no_limit:`Gratis og ingen grense`,
        expired:`Utløpt`,
        lifetime:`Livstid`,
        remain:`Forbli`,
        day_s:`Dager)`,
        error_24801:`Forespørselen mislyktes (feilkode: 24801). Vennligst prøv igjen`,
        no_app_found:`Ingen app funnet!<a href="/">Oppdater</a> eller <a href="https://no.aiseesoft.com/">gå til det offisielle nettstedet</a>`,
        get_more:`Få mer >>`,
        edit_photo:`Rediger bilde`,
        select_photo:`Velg bilde`,
        change_photo:`Endre Foto`,
        cancel:`Avbryt`,
        hide_password:`Skjul passord`,
        show_password:`Vis passord`,
        zoom_in:`Zoom inn`,
        zoom_out:`Zoome ut`,
        rotate:`Rotere`,
        horizontal_flip:`Horisontal flipp`,
        vertical_flip:`Vertikal flipp`,
        country:`Land`,
        country_1:`Velg ditt land/region`,
        country_2:`Åaland Island`,
        country_3:`Afghanistan`,
        country_4:`Albania`,
        country_5:`Algerie`,
        country_6:`Amerikansk Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktis`,
        country_11:`Antigua og Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Østerrike`,
        country_17:`Aserbajdsjan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Hviterussland`,
        country_22:`Belgia`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnia og Hercegovina`,
        country_29:`Botswana`,
        country_30:`Bouvetøya`,
        country_31:`Brasil`,
        country_32:`Britisk territorium i det indiske hav`,
        country_33:`De britiske jomfruøyene`,
        country_34:`Brunei`,
        country_35:`Bulgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodsja`,
        country_39:`Kamerun`,
        country_40:`Canada`,
        country_41:`Kapp Verde`,
        country_42:`Karibiske Nederland`,
        country_43:`Caymanøyene`,
        country_44:`Den sentralafrikanske republikk`,
        country_45:`Tsjad`,
        country_46:`Chile`,
        country_47:`Kina`,
        country_48:`Juleøya`,
        country_49:`Kokosøyene (Keeling).`,
        country_50:`Colombia`,
        country_51:`Cook-øyene`,
        country_52:`Costa Rica`,
        country_53:`"Elfenbenskysten"`,
        country_54:`Kroatia`,
        country_55:`Cuba`,
        country_56:`Kypros`,
        country_57:`Tsjekkisk Republikk`,
        country_58:`Den demokratiske republikken Kongo`,
        country_59:`Danmark`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`den dominikanske republikk`,
        country_63:`Ecuador`,
        country_64:`Egypt`,
        country_65:`El Salvador`,
        country_66:`Ekvatorial-Guinea`,
        country_67:`Eritrea`,
        country_68:`Estland`,
        country_69:`Etiopia`,
        country_70:`Falklandsøyene`,
        country_71:`Færøyene`,
        country_72:`Mikronesias fødererte stater`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`Frankrike`,
        country_76:`fransk Guyana`,
        country_77:`Fransk Polynesia`,
        country_78:`franske sørlige territorier`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Tyskland`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Storbritannia (Storbritannia; England)`,
        country_86:`Hellas`,
        country_87:`Grønland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island og McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Ungarn`,
        country_101:`Island`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irland`,
        country_107:`Isle of Man`,
        country_108:`Israel`,
        country_109:`Italia`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordan`,
        country_114:`Kasakhstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirgisistan`,
        country_119:`Laos`,
        country_120:`Latvia`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libya`,
        country_125:`Liechtenstein`,
        country_126:`Litauen`,
        country_127:`Luxembourg`,
        country_128:`Macao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldivene`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshalløyene`,
        country_136:`Martinique`,
        country_137:`Mauritania`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexico`,
        country_141:`Moldova`,
        country_142:`Monaco`,
        country_143:`Mongolia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marokko`,
        country_147:`Mosambik`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Nederland`,
        country_153:`Ny Caledonia`,
        country_154:`New Zealand`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolkøya`,
        country_160:`Nord-Korea`,
        country_161:`Nord-Marianene`,
        country_162:`Norge`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestinske områder`,
        country_167:`Panama`,
        country_168:`Papua Ny-Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairnøyene`,
        country_172:`Polen`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republikken Makedonia (FYROM)`,
        country_177:`Republikken Kongo`,
        country_178:`Gjenforening`,
        country_179:`Romania`,
        country_180:`Den russiske føderasjonen`,
        country_181:`Rwanda`,
        country_182:`Saint Barthélemy`,
        country_183:`Saint Martin (Frankrike)`,
        country_184:`Saint-Pierre og Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tome og Principe`,
        country_188:`Saudi-Arabia`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychellene`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakia`,
        country_195:`Slovenia`,
        country_196:`Solomon øyene`,
        country_197:`Somalia`,
        country_198:`Sør-Afrika`,
        country_199:`Sør-Georgia og Sør-Sandwich-øya`,
        country_200:`Sør-Korea`,
        country_201:`Sør-Sudan`,
        country_202:`Spania`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena & Dependencies`,
        country_205:`St. Kitts og Nevis`,
        country_206:`St. Lucia`,
        country_207:`St. Vincent og Grenadinene`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Swaziland`,
        country_211:`Sverige`,
        country_212:`Sveits`,
        country_213:`Syria`,
        country_214:`Taiwan`,
        country_215:`Tadsjikistan`,
        country_216:`Tanzania`,
        country_217:`Mal:Landsdata SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`Bahamas`,
        country_220:`Komorene`,
        country_221:`Filippinene`,
        country_222:`Timor-Leste (Øst-Timor)`,
        country_223:`Å gå`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad og Tobago`,
        country_227:`Tunisia`,
        country_228:`Tyrkia`,
        country_229:`Turkmenistan`,
        country_230:`Turks- og Caicosøyene`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraina`,
        country_234:`De forente arabiske emirater`,
        country_235:`USAs mindre ytre øyer`,
        country_236:`Amerikas forente stater (USA)`,
        country_237:`De amerikanske jomfruøyene`,
        country_238:`Uruguay`,
        country_239:`Usbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikanstaten (Den hellige stol)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis og Futuna`,
        country_245:`Vestre Sahara`,
        country_246:`Jemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Logg på med Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Alle rettigheter forbeholdt.`,
        State:`Stat`,
        Activation_code:`Aktiviseringskode`,
        Question:`List opp alle appene du har logget på`,
        Copy_complete:`Kopi fullført`,


        store:`butikk`,
        resource:`Ressurs`,
        support:`Brukerstøtte`,
        video_converter:`Video-konverterer`,
        multimedia:`Multimedia`,
        data_recovery:`Datarekonstruksjon`,
        utility:`Nytte`,
        free_tools:`Gratis verktøy`,
        for_windows:`For Windows`,
        for_mac:`For Mac`,
        pdf_solutions:`PDF-løsninger`,
        data_manager:`Databehandler`,
        other_tools:`Andre verktøy`,
        free_online_solutions:`Gratis nettløsninger`,
        free_online_tools:`Gratis online verktøy`,
        desktop_freeware:`Desktop Freeware`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS-konverter`,
        product_4:`4K-konverter`,
        product_5:`MXF-konverter`,
        product_6:`3D-konverter`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter for Mac`,
        product_9:`MTS Converter for Mac`,
        product_10:`4K Converter for Mac`,
        product_11:`MXF Converter for Mac`,
        product_12:`3D Converter for Mac`,
        product_13:`Blu-ray-spiller`,
        product_14:`Video Enhancer`,
        product_15:`Video redigerer`,
        product_16:`Slideshow Creator`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray-spiller`,
        product_21:`Mac Video Enhancer`,
        product_22:`Videoredigerer for Mac`,
        product_23:`DVD Creator for Mac`,
        product_24:`MP4 Converter for Mac`,
        product_25:`Windows datagjenoppretting`,
        product_26:`FoneLab iPhone datagjenoppretting`,
        product_27:`FoneLab iOS Systemgjenoppretting`,
        product_28:`FoneLab iOS Data Backup &amp; Restaurere`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Ødelagte Android-datautvinning`,
        product_31:`Android Data Backup &amp; Restaurere`,
        product_32:`Mac datagjenoppretting`,
        product_33:`Mac FoneLab iPhone datagjenoppretting`,
        product_34:`Mac FoneLab iOS Systemgjenoppretting`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Restaurere`,
        product_36:`Mac FoneLab Android Datagjenoppretting`,
        product_37:`Mac Android Data Backup &amp; Restaurere`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate for Mac`,
        product_40:`PDF-sammenslåing`,
        product_41:`Mac PDF til ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`foneTrans`,
        product_44:`WhatsApp Transfer for iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone-rens`,
        product_48:`PPT til Video Converter`,
        product_49:`HEIC-omformer`,
        product_50:`iPhone Unlocker`,
        product_51:`Gratis videokonvertering online`,
        product_52:`Gratis Audio Converter Online`,
        product_53:`Gratis videokompressor online`,
        product_54:`Gratis videosammenslåing på nett`,
        product_55:`Gratis HEIC-omformer`,
        product_56:`Gratis bildekompressor`,
        product_57:`Gratis vannmerkefjerner`,
        product_58:`Gratis PDF-kompressor online`,
        product_59:`Gratis bildeoppskalering på nett`,
        product_60:`Gratis bakgrunnsfjerner på nett`,
        product_61:`Gratis bildekonvertering online`,
        product_62:`Gratis PDF PNG Converter Online`,
        product_63:`Gratis videokonvertering`,
        product_64:`Gratis video til GIF-konvertering`,
        product_65:`Gratis Android-datagjenoppretting`,
        product_66:`Slideshow Maker`,
        product_67:`Gratis videoredigerer`,
        product_68:`Gratis PDF Viewer`,
        product_69:`Skjermopptaker`,
        product_70:`Mac skjermopptaker`,
        product_71:`Gratis skjermopptaker online`,
        product_72:`Gratis lydopptaker online`,
        product_73:`Telefonspeil`,
        product_74:`AnyCoord - posisjonsskifter`,
        product_75:`iPhone Passordbehandling`,
        product_76:`Gratis lydkompressor online`,
        product_77:`Gratis videotrimmer på nett`,
        product_78:`Gratis Video Cropper Online`,
        product_79:`Gratis videorotator på nett`,
        product_80:`Gratis Video Looper Online`,
        product_81:`Gratis PDF JPG Converter Online`,
        product_81:`Gratis JPG Word Converter Online`,
        category_1:`Foto redigering`,
        category_2:`Videoredigering`,
        category_3:`Viskelær`,
        category_4:`Medieopptaker`,
        posted_by:`Postet av`,
        to:`til`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Hvordan fjerne tekst fra bilder effektivt`,
        article_2:`Hvordan øke bildestørrelsen i KB til MB uten å skade kvaliteten`,
        article_3:`Slik fjerner du Getty Images Watermark på Windows og Mac`,
        article_4:`Den beste metoden for å konvertere lavoppløselig bilde til høyoppløselig online gratis`,
        article_5:`TikTok-veiledning | Slik lager du en videokollasje på TikTok`,
        article_6:`5 enkle måter å kombinere VOB-filer du bør kjenne til`,
        article_7:`Hvordan oppskalere video og enkelt få høyere oppløsning`,
        article_8:`Hvordan kombinere MP4-filer på MacBook Pro/iMac/MacBook og mer`,
        article_9:`2 enkle måter å slette kjøpshistorikk på iPhone [Løst]`,
        article_10:`Slik sletter du Snapchat-bilder permanent fra chat og minner`,
        article_11:`Slik sletter du Spotlight-søkeforslag og -historikk på iPhone/iPad`,
        article_12:`[Løst] Slik tømmer du Instagram-buffer på iPhone med 3 enkle måter`,
        article_13:`[Løst] Hvordan fikse QuickTime Player som ikke fungerer på Mac`,
        article_14:`Hvordan ta opp systemlyd på Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Effektive måter å fikse PowerPoint-skjermopptak som ikke fungerer`,
        article_16:`Hvordan ta opp lyd med VLC [Løst]`,
        faq_title_1:`1. Hvordan fikser jeg 4104-feil?`,
        faq_title_2:`2. Når kan jeg få pengene mine tilbake etter at refusjonsforespørselen er godkjent?`,
        faq_title_3:`3. Hva er "1 års abonnement" og hvordan slå av "Automatisk fornyelsestjeneste"?`,
        faq_title_4:`4. Kan jeg kansellere bestillingen min etter å ha plassert Aiseesoft-produktet?`,
        faq_title_5:`5. Hvordan sende inn en anmeldelse til deg?`,
        faq_info_1:`Dette er på grunn av den begrensede lisensen du har. Vi bruker Single License i tilfelle registreringskoden blir stjålet og forhindrer brudd på bruk av registreringskoden. Så Reg Code du har er Single License som bare kan brukes på én datamaskin. Hvis du bruker den på mer enn én datamaskin, vil det være feil 4104. Du kan ikke bruke den før vi har tilbakestilt den for deg.`,
        faq_info_2:`Hvis du endrer eller installerer datamaskinen på nytt, kan vi tilbakestille den for deg. Vennligst kontakt kundeservice: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> for å be om tilbakestilling av registreringskoden for deg.`,
        faq_info_3:`Når tilbakebetalingsforespørselen din er godkjent, vil det normalt ta 3-7 virkedager før kreditten vises på kontoen din. Noen ganger i ferier kan det ta lengre tid, men det forventes ikke å ta over 10 dager. Hvis du ikke har fått refusjonen 20 dager etter godkjenningen, vennligst kontakt oss på <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> for å sjekke refusjonen.`,
        faq_info_4:`Automatisk fornyelsestjeneste betyr at hvis du kjøper et 1 års abonnement, vil betalingsplattformen belaste beløpet automatisk (inkludert gjeldende avgifter) når produktlisensen utløper. Men betalingsskjemaet (Swreg eller 2Checkout) vil sende deg et brev for å minne deg på at bestillingen kommer til å utløpe 7 dager før utløpet. E-posten vil også inneholde instruksjoner om hvordan du slår av "Automatisk fornyelsestjeneste".`,
        faq_info_5:`Siden bestillingen behandles automatisk, er det ikke tillatt å kansellere bestillingen. Dessuten vil den gratis prøveversjonen gjøre det mulig for deg å vite mer om Aiseesoft-programmet og sjekke om det kan dekke dine behov før du kjøper det.`,
        faq_info_6:`Hvis du virkelig ønsker å få en refusjon, vennligst sjekk våre <a href="https://no.aiseesoft.com/refund-policy.html">refusjonsretningslinjer</a>.`,
        faq_info_7:`Vi setter pris på din tid og oppmerksomhet til å sende inn en anmeldelse til oss. Kontakt oss (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) for å sende inn din anmeldelse, så vil vi seriøst vurdere anmeldelsen din for produktoppdateringene våre.`,
        change_password_success:`Passordet ble endret`,
        successful_login_title:`Innlogging vellykket`,
        product_page:`Produktside >>`,
        successful_login_info:`Innlogging fullført. Lukk gjeldende side og gå tilbake til den opprinnelige fanen for å fortsette med følgende prosess. Den gjeldende siden lukkes automatisk om 5 sekunder. Hvis automatisk lukking eller lukking ved å klikke på "Ferdig"-knappen mislykkes, vennligst lukk denne fanen direkte.`,
        successful_login_info_firefox:`Innlogging fullført. Lukk gjeldende side og gå tilbake til den opprinnelige fanen for å fortsette med følgende prosess.`,
        my_account:`Min konto`,
        my_history:`Min historie`,
        remove_watermark:`Fjern vannmerke`,
        no_history:`Ingen historie`,
        history_all:`Velg alle`,
        history_open:`Åpen`,
        history_down:`nedlasting`,
        history_delete:`Slett`,
        history_clear:`Fjern ugyldig`,
        images:`Bilder)`,
        use_this_function:`Bruk denne funksjonen >>`,
        hd_downloading:`Fordeler med å laste ned originale HD-bilder:`,
        lifetimeRemaining:`Levetid gjenstår`,
        Remaining:`Gjenstående`,
        email_verification:`epostbekreftelse`,
        email_verification_info:`Vi har sendt bekreftelsesmeldingen til e-posten din <span class="email"></span>, og fullfør bekreftelsen. Etter verifisering vil fordelene synkroniseres automatisk.`,
        wrong_email:`Feil e-postadresse?`,
        click_here_to_modify:`Klikk her for å endre`,
        get_more_help:`Få mer hjelp?`,
        click_here:`Klikk her`,
        email_verification_info_success:`Gratulerer for bekreftelsen av e-postkontoen din.`,
        email_verification_info_error:`Bekreftelsen mislyktes på grunn av den utløpte koblingen.`,
        registration_succeeded:`Registrering lyktes`,
        registration_succeeded_info_1:`Gratulerer! Registreringen var vellykket. Vi har sendt bekreftelsesmeldingen til e-posten din <span class="email"></span>, og fullfør bekreftelsen for å bruke fordelene med denne e-posten.`,registration_succeeded_info_2:`Klikk "Ferdig" for å gå tilbake til hjemmesiden og bruke dette produktet.`,
        registration_succeeded_info_3:`Klikk "Ferdig" for å lukke gjeldende side og gå tilbake til startsiden for følgende operasjoner. Hvis lukkingen mislykkes, må du lukke fanen manuelt.`,
        verify_email:`verifiser e-post`,
        registered_email_not_verified:`Den registrerte e-posten er ikke bekreftet, vennligst bekreft den med en gang.`,
        email_verification_time_1:`Har du ikke mottatt en bekreftelses-e-post?`,
        email_verification_time_2:`Etter`,
        email_verification_time_3:`Klikk her for å sende den på nytt`,
        error_26301:`Feilkode: 26301, vennligst kontakt oss`,
        error_26302:`Feilkode: 26302, vennligst kontakt oss`,
        error_26303:`E-postformatfeil (feilkode: 26303). Vennligst skriv den inn igjen`,
        error_26304:`Passord over 8 tegn anbefales (feilkode: 26304)`,
        error_26305:`Reuqest mislyktes (feilkode: 26305). Vennligst prøv på nytt`,
        error_26306:`E-post er registrert, vennligst <a href="https://account.aiseesoft.com/login">gå til pålogging</a>`,
        error_26307:`Reuqest mislyktes (feilkode: 26307). Vennligst prøv på nytt`,
        error_26308:`Reuqest mislyktes (feilkode: 26308). Vennligst prøv på nytt`,
        error_26401:`Feilkode: 26401, prøv på nytt`,
        error_26402:`E-posten er bekreftet (feilkode: 26402), prøv på nytt`,
        error_26403:`Reuquest mislyktes (feilkode: 26403). Vennligst prøv på nytt`,
        error_26404:`Forespørselen mislyktes (feilkode: 26404). Vennligst prøv på nytt`,
        error_26501:`Feilkode: 26501, vennligst kontakt oss`,
        error_26502:`Feilkode: 26502, vennligst kontakt oss`,
        error_26503:`E-postformatfeil (feilkode: 26503). Vennligst skriv den inn igjen`,
        error_26504:`Reuqest mislyktes (feilkode: 26504). Vennligst prøv på nytt`,
        error_26505:`E-post har ikke blitt registrert, vennligst <a href="https://account.aiseesoft.com/register">registrer den først</a>`,
        error_26506:`E-post er bekreftet.`,
        error_26507:`Reuqest mislyktes (feilkode: 26507). Vennligst prøv på nytt`,
        error_26508:`Bekreftelse mislyktes (feilkode: 26508), prøv på nytt`,
        error_26509:`Forespørselen mislyktes (feilkode: 26509), prøv på nytt`,
        error_26510:`Feilkode: 26510, vennligst kontakt oss`,
        error_26601:`Feilkode: 26601, vennligst kontakt oss`,
        error_26602:`Feilkode: 26602, vennligst kontakt oss`,
        error_26603:`Reuqest mislyktes (feilkode: 26603). Vennligst prøv på nytt`,
        error_26604:`Feilkode: 26604, vennligst kontakt oss`,
        error_26605:`Feilkode: 26605, vennligst kontakt oss`,
        error_26701:`Feilkode: 26701, vennligst kontakt oss`,
        error_26702:`Reuqest mislyktes (feilkode: 26702). Vennligst prøv på nytt`,
        error_26703:`Feilkode: 26703, vennligst kontakt oss`,
        error_26704:`Feilkode: 26704, vennligst kontakt oss`,
        error_26705:`Vent på innlogging (feilkode: 26705). Vennligst prøv på nytt`,
        no_cookie:`Du har slått på funksjonen Blokker alle informasjonskapsler i nettleseren din, så du kan ikke logge på. Gå til Innstillinger for å merke av i boksen Tillat alle informasjonskapsler.`,
        error_26801:`Feilkode: 26801, vennligst kontakt oss`,
        error_26802:`Feilkode: 26802, vennligst kontakt oss`,
        error_26803:`Forespørselen mislyktes (feilkode: 26803). Vennligst prøv på nytt`,
        error_26804:`Forespørselen mislyktes (feilkode: 26804). Vennligst prøv på nytt`,
        error_order:`Forespørselen mislyktes (feilkode: 27098), vennligst prøv på nytt!`,
        error_order1:`Bestillingsforespørselen er ufullstendig (feilkode:`,
        error_order2:`）, vennligst oppdater og prøv på nytt.`,
        modify_email_title:`Endre e-post`,
        modify_email_info:`Du kan bruke den endrede e-posten til å logge på kontoen din.`,
        images_per:`Bilder pr`,
        error_26101:`Feil: 26101. Ta kontakt med oss`,
        error_26102:`Feil: 26102. Ta kontakt med oss`,
        error_26103:`Forespørselen mislyktes (feilkode:26103). Prøv det på nytt`,
        error_26104:`Feilkode: 26104, vennligst prøv på nytt`,
        error_26105:`Feilkode: 26105, vennligst prøv på nytt`,
        error_26106:`Sletting mislyktes (feilkode: 26106). Vennligst prøv igjen`,
        error_26201:`Feil: 26201. Ta kontakt med oss`,
        error_26202:`Forespørselen mislyktes (feilkode:26202). Prøv det på nytt`,
        error_26001:`Feil: 26001. Ta kontakt med oss`,
        error_26002:`Feil: 26002. Ta kontakt med oss`,
        error_26003:`Feil: 26003. Ta kontakt med oss`,
        error_26004:`Feil: 26004. Ta kontakt med oss`,
        error_26005:`Forespørselen mislyktes (feilkode:26005). Prøv det på nytt`,
        error_26006:`Feilkode: 26006, prøv på nytt`,
        error_26008:`Feil: 26008. Ta kontakt med oss`,
        go_to_the_home_page:`Gå til hjemmesiden`,
        error_27101:`Forespørselen mislyktes (feilkode: 27101). Vennligst prøv igjen`,
        error_27201:`Feilkode: 27201, vennligst kontakt oss`,
        error_27202:`Feilkode: 27202, prøv på nytt`,
        error_27203:`Forespørselen mislyktes (feilkode: 27203). Vennligst prøv på nytt`,
        error_27204:`Ugyldig kode (feilkode: 27204).`,
        error_27205:`Forespørselen mislyktes (feilkode: 27205). Vennligst prøv på nytt`,
        error_27206:`Forespørselen mislyktes (feilkode: 27206). Vennligst prøv på nytt`,
        error_27207:`Forespørselen mislyktes (feilkode: 27207). Vennligst prøv på nytt`,
        no_history_found:`Du har ikke brukt noe verktøy! <a href="/">Oppdater</a> eller <a href="https://no.aiseesoft.com/">gå til det offisielle nettstedet</a>`,
        error_25301:`Feil: 25301. Ta kontakt med oss`,
        error_25302:`Feil: 25302. Ta kontakt med oss`,
        error_25303:`Forespørselen mislyktes (feilkode: 25303). Vennligst prøv igjen`,
        error_25304:`Forespørselen mislyktes (feilkode: 25304). Vennligst prøv igjen`,
        error_25305:`Forespørselen mislyktes (feilkode: 25305). Vennligst prøv igjen`,
        error_25306:`Forespørselen mislyktes (feilkode: 25306). Vennligst prøv igjen`,
        image_upscaler_p:`Fordeler ved nedlasting av bilde uten vannmerke:`,
        Available_for:`Tilgjengelig for:`,
        credit_per:`%s kreditt(er) per HD-bilde`,
        still_valid:`Kjøpte plan(er) er fortsatt gyldige`,
        credit:`kreditt(er)`,
        pc_3rd_info:`Logg på vellykket. Vennligst gå til applikasjonen for videre drift.`,
        use_online:`Bruk den elektroniske tjenesten`,
        use_download:`Bruk skrivebordsprogrammet`,
        use_immediately:`Bruk umiddelbart`,
        Use_in_browser:`Bruk i nettleser`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    pl:{
        overtime_1:`Kod błędu:`,
        overtime_2:`, spróbuj ponownie`,
        isnetwork:`Błąd internetowy. Sprawdź i spróbuj ponownie`,
        email_placeholder:`E-mail`,
        email_empty:`Proszę wpisać e-mail`,
        email_not_valid:`adres email jest nieprawidłowy`,
        email_not_valid_1:`Wprowadź swój adres e-mail`,
        email_not_valid_2:`E-mail jest nieprawidłowy, użyj innego adresu.`,
        email_not_valid_3:`Brak wpisu e-mail`,
        password_placeholder:`Hasło`,
        password_empty:`Wprowadź hasło`,
        password_not_valid:`Nieprawidłowe konto lub hasło`,
        password_not_valid_1:`Wymagane jest hasło powyżej 8 znaków`,
        password_not_valid_2:`Proszę utwórz hasło`,
        password_placeholder_1:`Stwórz swoje hasło`,
        password_placeholder_2:`Potwierdź swoje hasło`,
        password_placeholder_3:`Utwórz nowe hasło`,
        password_placeholder_4:`Potwierdź nowe hasło`,
        password_placeholder_5:`Wprowadź stare hasło`,
        copy_password_empty:`Potwierdź hasło`,
        copy_password_not_valid:`Proszę, potwierdź swoje hasło`,
        copy_passwords_inconsistent:`Twoje potwierdzenie hasła nie pasuje`,
        code_empty:`Wprowadź kod weryfikacyjny`,
        code_not_valid:`nieprawidłowy kod weryfikacyjny`,
        code_placeholder:`Kod weryfikacyjny`,
        not_received_code:`Jeśli Twoja skrzynka pocztowa od dłuższego czasu nie otrzymuje kodu weryfikacyjnego, pobierz go ponownie.`,
        get_first_code:`Najpierw pobierz kod weryfikacyjny.`,
        connect_your_email_placeholder:`Połącz swój e-mail`,
        last_name_placeholder:`Proszę podać swoje nazwisko`,
        first_name_placeholder:`Proszę podaj swoje imię`,
        address_placeholder:`Podaj swój adres`,
        no_code_text:`Wysłaliśmy kod weryfikacyjny. Wprowadź swój kod. <span class='tips'>Nie otrzymałeś kodu?`,
        no_code_text_1:`1. Upewnij się, że adres e-mail jest prawidłowy i może odbierać wiadomości e-mail.`,
        no_code_text_2:`2. Ponieważ wiadomość e-mail jest wysyłana przez system automatycznie, może zostać oznaczona jako spam lub wiadomości-śmieci. Sprawdź, czy wiadomość e-mail znajduje się w folderze Kosz.`,
        no_code_text_3:`3. Nie możesz rozwiązać swojego problemu?`,
        no_code_text_3_span:`Kliknij tutaj, aby się z nami skontaktować.`,
        order_no:`Nie kupiłeś żadnych produktów. Wszelkie pytania proszę <a href="https://pl.aiseesoft.com/store/">Kup teraz</a> Lub <a href="https://pl.aiseesoft.com/contact.html"> Skontaktuj się z nami</a>`,
        error_24901:`Bieżące konto nie ma powiązanego adresu e-mail i nie można znaleźć zamówień. Proszę połączyć e-mail.`,
        user_guide:`Podręcznik użytkownika>>`,
        download:`Pobierać`,
        order_number:`Numer zamówienia`,
        Refund:`Zwrot`,
        Disabled:`Wyłączone`,
        Normal:`Normalna`,
        Modify:`Modyfikować`,
        Modify_1:`Modyfikuj>>`,
        Connect:`Łączyć`,
        unlink_success:`Odłącz pomyślnie`,
        connect_success:`Połącz się pomyślnie`,
        feedback_title:`Dziękuję za twój komentarz. Zostaw swój problem, a my odpowiemy w ciągu 24 godzin.`,
        feedback_thank_you:`Dziękujemy!<br />Twoja opinia została pomyślnie przesłana.`,
        feedback_email:`Wpisz tutaj swój adres e-mail!`,
        feedback_content:`Zostaw tutaj wszelkie napotkane problemy lub sugestie.`,
        feedback_submit:`Składać`,
        form_contents:`Nie podałeś żadnego opisu. Wprowadź go i prześlij ponownie.`,
        old_password:`Proszę wpisać stare hasło`,
        new_password:`Utwórz nowe hasło`,
        old_new_password:`Nowe hasło nie może być takie samo jak stare`,
        incorrect_password:`niepoprawne hasło`,
        delete_no:`Usuń teraz`,
        Caps:`Caps Lock jest włączony`,
        Get:`Dostwać`,
        Done:`Gotowe`,
        error_20001:`Błąd: 20001. Zaloguj się ponownie.`,
        error_20002:`Błąd: 20002. Zaloguj się ponownie.`,
        error_20003:`Błąd: 20003. Zaloguj się ponownie.`,
        error_20004:`Żądanie nie powiodło się (kod błędu: 20004). Spróbuj ponownie.`,
        error_20005:`Sesja logowania wygasła (Błąd: 20005). Proszę, zaloguj się ponownie.`,
        error_20006:`Żądanie nie powiodło się (kod błędu: 20006). Spróbuj ponownie.`,
        error_20007:`Sesja logowania wygasła (Błąd: 20007). Proszę, zaloguj się ponownie.`,
        error_20008:`Sesja logowania wygasła (Błąd: 20008). Proszę, zaloguj się ponownie.`,
        error_20009:`Sesja logowania wygasła (Błąd: 20009). Proszę, zaloguj się ponownie.`,
        error_20101:`Wprowadź swój adres e-mail (kod błędu: 20101)`,
        error_20102:`E-mail jest nieprawidłowy (kod błędu: 20102)`,
        error_20103:`Żądanie nie powiodło się (kod błędu: 20103). Spróbuj ponownie`,
        error_20104:`E-mail jest już w użyciu, <a href="https://account.aiseesoft.com/login">zaloguj się</a> lub zarejestruj się na nowy`,
        error_20105:`Żądanie nie powiodło się (kod błędu: 20105). Spróbuj ponownie`,
        error_20106:`Nie udało się wysłać e-maila, spróbuj ponownie`,
        error_20201:`Wprowadź swój adres e-mail (kod błędu: 20201)`,
        error_20202:`Wprowadź swoje hasło (kod błędu: 20202)`,
        error_20203:`Wprowadź kod weryfikacyjny (kod błędu: 20203)`,
        error_20204:`E-mail jest nieprawidłowy (kod błędu: 20204)`,
        error_20205:`Wymagane jest hasło powyżej 8 znaków (kod błędu: 20205)`,
        error_20206:`Żądanie nie powiodło się (kod błędu: 20206). Spróbuj ponownie`,
        error_20207:`nieprawidłowy kod weryfikacyjny`,
        error_20208:`Żądanie nie powiodło się (kod błędu: 20208). Spróbuj ponownie`,
        error_20209:`Żądanie nie powiodło się (kod błędu: 20209). Spróbuj ponownie`,
        error_20301:`Wprowadź swój adres e-mail (kod błędu: 20301)`,
        error_20302:`Błąd: 20302. Skontaktuj się z nami`,
        error_20303:`E-mail jest nieprawidłowy (kod błędu: 20303)`,
        error_20304:`Żądanie nie powiodło się (kod błędu: 20304). Spróbuj ponownie`,
        error_20305:`Konto nie istnieje. Wprowadź ponownie lub najpierw <a href="https://account.aiseesoft.com/register">Stwórz To</a>.`,
        error_20306:`Brak hasła. Użyj <a href="https://account.aiseesoft.com/passwordless-login">logowania bez hasła</a> lub <a href="create-password">ustaw hasło</a> i zaloguj się.`,
        error_20308:`Żądanie nie powiodło się (kod błędu: 20308). Spróbuj ponownie`,
        error_20401:`Nie udało się wylogować (kod błędu: 20401). Spróbuj ponownie`,
        error_20501:`Wprowadź swój adres e-mail (kod błędu: 20501)`,
        error_20502:`E-mail jest nieprawidłowy (kod błędu: 20502)`,
        error_20503:`Żądanie nie powiodło się (kod błędu: 20503). Spróbuj ponownie`,
        error_20504:`Nie udało się wysłać e-maila. Spróbuj ponownie.`,
        error_20601:`Wprowadź swój adres e-mail (kod błędu: 20601)`,
        error_20602:`Wprowadź kod weryfikacyjny (kod błędu: 20602)`,
        error_20603:`E-mail jest nieprawidłowy (kod błędu: 20603)`,
        error_20604:`Żądanie nie powiodło się (kod błędu: 20604). Spróbuj ponownie`,
        error_20606:`Żądanie nie powiodło się (kod błędu: 20606). Spróbuj ponownie`,
        error_20607:`Żądanie nie powiodło się (kod błędu: 20607). Spróbuj ponownie`,
        error_20608:`Żądanie nie powiodło się (kod błędu: 20608). Spróbuj ponownie`,
        error_20701:`Wprowadź swój adres e-mail (kod błędu: 20701)`,
        error_20702:`E-mail jest nieprawidłowy (kod błędu: 20702)`,
        error_20703:`Żądanie nie powiodło się (kod błędu: 20703). Spróbuj ponownie`,
        error_20704:`Konto nie istnieje. Wprowadź ponownie lub najpierw <a href="https://account.aiseesoft.com/register">Stwórz To</a>.`,
        error_20705:`Żądanie nie powiodło się (kod błędu: 20705). Spróbuj ponownie`,
        error_20706:`Nie udało się wysłać e-maila. Spróbuj ponownie`,
        error_20801:`Wprowadź swój adres e-mail (kod błędu: 20801)`,
        error_20802:`Błąd: 20802. Skontaktuj się z nami`,
        error_20803:`Wprowadź kod weryfikacyjny (kod błędu: 20803)`,
        error_20804:`E-mail jest nieprawidłowy (kod błędu: 20804)`,
        error_20805:`Wymagane jest hasło powyżej 8 znaków (kod błędu: 20805)`,
        error_20806:`Żądanie nie powiodło się (kod błędu: 20806). Spróbuj ponownie`,
        error_20808:`Żądanie nie powiodło się (kod błędu: 20808). Spróbuj ponownie`,
        error_20901:`Żądanie nie powiodło się (kod błędu: 20901). Spróbuj ponownie`,
        error_20902:`Żądanie nie powiodło się (kod błędu: 20902). Spróbuj ponownie`,
        error_21000:`Zmiany są zapisywane`,
        error_21001:`Nie przesłano żadnych informacji (kod błędu: 21001)`,
        error_21002:`Żądanie nie powiodło się (kod błędu: 21002). Spróbuj ponownie`,
        error_21101:`Wprowadź swój adres e-mail (kod błędu: 21101)`,
        error_21102:`E-mail jest nieprawidłowy (kod błędu: 21102)`,
        error_21103:`Żądanie nie powiodło się (kod błędu: 21103), spróbuj ponownie`,
        error_21104:`Poczta e-mail jest już podłączona, użyj nowego`,
        error_21105:`Żądanie nie powiodło się (kod błędu: 21105), spróbuj ponownie`,
        error_21106:`Nie udało się wysłać e-maila. Spróbuj ponownie`,
        error_21201:`Wprowadź swój adres e-mail (kod błędu: 21201)`,
        error_21202:`Wprowadź kod weryfikacyjny (kod błędu: 21202)`,
        error_21203:`E-mail jest nieprawidłowy (kod błędu: 21203)`,
        error_21204:`Błąd: 21204. Skontaktuj się z nami`,
        error_21205:`Błąd: 21205. Skontaktuj się z nami`,
        error_21206:`Wymagane jest hasło powyżej 8 znaków (kod błędu: 21206)`,
        error_21207:`Żądanie nie powiodło się (kod błędu: 21207). Spróbuj ponownie`,
        error_21209:`Żądanie nie powiodło się (kod błędu: 21209). Spróbuj ponownie`,
        error_21301:`Wprowadź stare hasło (kod błędu: 21301)`,
        error_21302:`Utwórz nowe hasło (kod błędu: 21302)`,
        error_21303:`Nowe hasło nie może być takie samo jak stare. (Błąd: 21303)`,
        error_21304:`Wymagane jest hasło powyżej 8 znaków (kod błędu: 21304)`,
        error_21306:`Żądanie nie powiodło się (kod błędu: 21306). Spróbuj ponownie`,
        error_21402:`Żądanie nie powiodło się (kod błędu: 21402). Spróbuj ponownie`,
        error_21403:`Nie udało się wysłać kodu weryfikacyjnego. Proszę, wyślij go ponownie`,
        error_21500:`Konto zostało usunięte`,
        error_21501:`Wprowadź kod weryfikacyjny (kod błędu: 21501)`,
        error_21502:`Sesja logowania wygasła (Błąd: 21502). Proszę, zaloguj się ponownie.`,
        error_21503:`Żądanie nie powiodło się (kod błędu: 21503). Spróbuj ponownie`,
        error_21505:`Żądanie nie powiodło się (kod błędu: 21505), spróbuj ponownie`,
        error_21601:`Błąd: 20601. Skontaktuj się z nami`,
        error_21602:`Nieprawidłowa weryfikacja (błąd: 20602). Spróbuj ponownie.`,
        error_21603:`Błąd: 20603. Spróbuj ponownie`,
        error_21604:`Żądanie nie powiodło się (kod błędu: 21604). Spróbuj ponownie`,
        error_21606:`Żądanie nie powiodło się (kod błędu: 21606). Spróbuj ponownie`,
        error_21611:`Żądanie nie powiodło się (kod błędu: 21611). Spróbuj ponownie`,
        error_21801:`Błąd: 21801. Skontaktuj się z nami`,
        error_21802:`Żądanie nie powiodło się (błąd: 21802). Spróbuj ponownie`,
        error_21803:`Błąd: 21803. Spróbuj ponownie`,
        error_21804:`Żądanie nie powiodło się (kod błędu: 21804). Spróbuj ponownie`,
        error_21806:`Błąd: 21806. Spróbuj ponownie`,
        error_21807:`Błąd: 21807. Skontaktuj się z nami`,
        error_21808:`Błąd: 21808. Skontaktuj się z nami`,
        error_21809:`Błąd: 21809. Skontaktuj się z nami`,
        error_21810:`Błąd: 21810. Skontaktuj się z nami`,
        error_21811:`Błąd: 21811. Skontaktuj się z nami`,
        error_21812:`Błąd: 21812. Skontaktuj się z nami`,
        error_21813:`Żądanie nie powiodło się (kod błędu: 21813). Spróbuj ponownie`,
        error_21814:`Błąd: 21814. Skontaktuj się z nami`,
        error_21815:`Żądanie nie powiodło się (kod błędu: 21815). Spróbuj ponownie`,
        error_21816:`Błąd: 21816. Skontaktuj się z nami`,
        error_21817:`Błąd: 21817. Skontaktuj się z nami`,
        error_21818:`Błąd: 21818. Skontaktuj się z nami`,
        error_21819:`Żądanie nie powiodło się (kod błędu: 21819). Spróbuj ponownie`,
        error_21820:`Błąd: 21820. Skontaktuj się z nami`,
        error_21821:`Błąd: 21821. Skontaktuj się z nami`,
        error_21822:`Błąd: 21822. Skontaktuj się z nami`,
        error_21823:`Żądanie nie powiodło się (kod błędu: 21823). Spróbuj ponownie`,
        error_21824:`Żądanie nie powiodło się (kod błędu: 21824). Spróbuj ponownie`,
        error_21825:`Żądanie nie powiodło się (kod błędu: 21825). Spróbuj ponownie`,
        error_21826:`Żądanie nie powiodło się (kod błędu: 21826). Spróbuj ponownie`,
        error_21828:`Błąd: 21828. Skontaktuj się z nami`,
        error_21901:`Błąd: 21901. Skontaktuj się z nami`,
        error_21902:`Żądanie nie powiodło się (kod błędu: 21902). Spróbuj ponownie`,
        error_21903:`Zmienił się status konta (kod błędu: 21903), odśwież stronę i spróbuj ponownie`,
        error_21904:`Błąd: 21904. Spróbuj ponownie`,
        error_21905:`Błąd: 21905. Spróbuj ponownie`,
        error_21906:`Żądanie nie powiodło się (kod błędu: 21906). Spróbuj ponownie`,
        error_21907:`Konto Google zostało połączone z innym kontem`,
        error_21908:`Żądanie nie powiodło się (kod błędu: 21908). Spróbuj ponownie`,
        error_22001:`Żądanie nie powiodło się (kod błędu: 22001). Spróbuj ponownie`,
        error_22002:`Odłączenie nie powiodło się bez dodatkowego logowania`,
        error_22003:`Żądanie nie powiodło się (kod błędu: 22003). Spróbuj ponownie`,
        error_22101:`Błąd: 22101. Skontaktuj się z nami`,
        error_22102:`Zmienił się status konta (kod błędu: 22102), odśwież stronę i spróbuj ponownie`,
        error_22103:`Żądanie nie powiodło się (kod błędu: 22103). Spróbuj ponownie`,
        error_22104:`Zmienił się status konta (kod błędu: 22104), odśwież stronę i spróbuj ponownie`,
        error_22105:`Błąd: 22105. Spróbuj ponownie`,
        error_22106:`Błąd: 22106. Spróbuj ponownie`,
        error_22107:`Błąd: 22107. Skontaktuj się z nami`,
        error_22108:`Żądanie nie powiodło się (kod błędu: 22108). Spróbuj ponownie`,
        error_22201:`Błąd: 22201. Skontaktuj się z nami`,
        error_22202:`Nieprawidłowa weryfikacja (błąd: 22202). Spróbuj ponownie.`,
        error_22203:`Błąd: 22203. Spróbuj ponownie"`,
        error_22204:`Żądanie nie powiodło się (kod błędu: 22204). Spróbuj ponownie`,
        error_22206:`Żądanie nie powiodło się (kod błędu: 22206). Spróbuj ponownie`,
        error_22401:`Błąd: 22401. Skontaktuj się z nami`,
        error_22402:`Nieprawidłowa weryfikacja (błąd: 22402). Spróbuj ponownie.`,
        error_22403:`Błąd: 22403. Spróbuj ponownie`,
        error_22404:`Żądanie nie powiodło się (kod błędu: 22404). Spróbuj ponownie`,
        error_22405:`Konto na Facebooku zostało połączone z innym adresem e-mail`,
        error_22406:`Błąd: 22406. Spróbuj ponownie`,
        error_22407:`Błąd: 22407. Skontaktuj się z nami`,
        error_22408:`Błąd: 22408. Skontaktuj się z nami`,
        error_22409:`Błąd: 22409. Skontaktuj się z nami`,
        error_22410:`Błąd: 224010. Skontaktuj się z nami`,
        error_22411:`Błąd: 224011. Skontaktuj się z nami`,
        error_22412:`Błąd: 224012. Skontaktuj się z nami`,
        error_22413:`Błąd: 22413. Skontaktuj się z nami`,
        error_22414:`Żądanie nie powiodło się (kod błędu: 22414). Spróbuj ponownie`,
        error_22415:`Błąd: 22415. Skontaktuj się z nami`,
        error_22416:`Błąd: 22416. Skontaktuj się z nami`,
        error_22417:`Błąd: 22417. Skontaktuj się z nami`,
        error_22418:`Żądanie nie powiodło się (kod błędu: 22418). Spróbuj ponownie`,
        error_22419:`Błąd: 22419. Skontaktuj się z nami`,
        error_22420:`Błąd: 22420. Skontaktuj się z nami`,
        error_22421:`Błąd: 22421. Skontaktuj się z nami`,
        error_22422:`Żądanie nie powiodło się (kod błędu: 22422). Spróbuj ponownie`,
        error_22423:`Żądanie nie powiodło się (kod błędu: 22423). Spróbuj ponownie`,
        error_22424:`Żądanie nie powiodło się (kod błędu: 22424). Spróbuj ponownie`,
        error_22425:`Żądanie nie powiodło się (kod błędu: 22425). Spróbuj ponownie`,
        error_20098:`Kod błędu: 20098. Jeśli przeglądasz w trybie prywatnym, przełącz się na tryb normalny i spróbuj ponownie.`,
        error_22298:`Żądanie do Google nie powiodło się (błąd: 22298). Spróbuj ponownie.`,
        error_22498:`Żądanie dla Facebooka nie powiodło się (błąd: 22498). Spróbuj ponownie.`,
        error_24902:`Żądanie nie powiodło się (kod błędu: 24902). Spróbuj ponownie`,
        error_24903:`Żądanie nie powiodło się (kod błędu: 24903). Spróbuj ponownie`,
        error_24904:`Żądanie nie powiodło się (kod błędu: 24904). Spróbuj ponownie`,
        error_24905:`Żądanie nie powiodło się (kod błędu: 24905). Spróbuj ponownie`,
        login_title:`Zaloguj się do Aiseesoft`,
        password_placeholder:`Hasło`,
        log_in:`Zaloguj sie`,
        no_account:`Brak konta?`,
        create_it:`Stwórz To`,
        or_log_in_with:`Lub zaloguj się za pomocą`,
        passwordless_login:`Logowanie bez hasła`,
        log_in_done:`Zaloguj się Gotowe`,
        three_rd_account_connect_info:`Gratulacje! Zalogowałeś się pomyślnie. Teraz możesz podłączyć jedno konto e-mail, które będzie używane do logowania w przyszłości.`,
        connect_now:`Połącz teraz`,
        see_my_account:`Zobacz moje konto`,
        three_rd_login_merge_account:`Adres e-mail konta innej firmy został zarejestrowany, czy chcesz się połączyć i zalogować bezpośrednio przy użyciu tego adresu e-mail?`,
        connect_log_in:`Połącz i zaloguj się`,
        create_an_account:`Utwórz konto`,
        connect_account:`Połącz konto`,
        connect_account_info:`Po połączeniu możesz zalogować się na to konto.`,
        back_to_log_in:`Powrót do logowania`,
        create_password:`Stwórz hasło`,
        create_now:`Stwórz teraz`,
        password_login_subtitle:`Logowanie bez hasła przez e-mail`,
        account_login:`Login do konta`,
        rights:`Tworząc to konto, wyrażasz zgodę na <a href="https://pl.aiseesoft.com/company/terms-and-conditions.html">Warunki korzystania z usługi</a> i <a href="https://pl.aiseesoft.com/privacy-policy.html">Polityka prywatności</a>`,
        passwordless_login_done:`Logowanie bez hasła gotowe`,
        passwordless_login_info:`Gratulacje, pomyślnie zakończyłeś logowanie bez hasła. Możesz utworzyć hasło do tego konta i logować się za pomocą konta i hasła w przyszłości. <a href="/create-password" style="display: initial;">Utwórz teraz</a>`,
        sign_up:`Zapisać się`,
        register_info:`Utwórz swoje konto`,
        reset_now:`Zresetuj teraz`,
        forgot_password:`Zapomniałeś hasła`,
        reset_password_subtitle:`Użyj swojego adresu e-mail, aby zresetować hasło`,
        plan_products:`Plan i produkty`,
        nick_name:`Nazwa użytkownika`,
        email:`E-mail`,
        my_products:`Moje produkty`,
        my_orders:`Moje zamówienia`,
        unlink:`Odczepić`,
        link:`Połączyć`,
        connected_accounts:`Połączone konta`,
        last_name:`Nazwisko`,
        first_name:`Imię`,
        Gender:`Płeć`,
        Birth:`Narodziny`,
        Month:`Miesiąc`,
        Year:`Rok`,
        Country_Region:`Kraj/region`,
        Address:`Adres`,
        Save:`Zapisać`,
        Date:`Data`,
        Male:`Męski`,
        Female:`Płeć żeńska`,
        Unspecified:`Nieokreślony`,
        Security:`Bezpieczeństwo`,
        change_password:`Zmień hasło`,
        change_now:`Zmień teraz`,
        connect_email:`Połącz e-mail`,
        delete_account:`Usuń konto`,
        delete_account_info:`Gdy Twoje konto zostanie usunięte, wszystkie dane w Planach i produktach powiązane z Twoim kontem zostaną trwale usunięte i możesz nie być w stanie ich odzyskać. Zalecamy ostrożne stąpanie.`,
        Delete:`Kasować`,
        Logout:`Wyloguj`,
        my_profile:`Mój profil`,
        guides_faqs:`Przewodniki i często zadawane pytania`,
        More:`Więcej`,
        guides:`Przewodniki`,
        register:`Zarejestruj się`,
        hot_faq:`Gorące FAQ`,
        Contents:`Zawartość:`,
        contact_us:`Skontaktuj się z nami>>`,
        plan:`Plan`,
        unregistered:`Niezarejestrowany`,
        buy_more:`Kup więcej`,
        buy_again:`Kup znowu`,
        buy_now:`Kup Teraz`,
        free_no_limit:`Bezpłatnie i bez limitu`,
        expired:`Wygasły`,
        lifetime:`Dożywotni`,
        remain:`Pozostawać`,
        day_s:`Dni)`,
        error_24801:`Żądanie nie powiodło się (kod błędu: 24801). Spróbuj ponownie`,
        no_app_found:`Nie znaleziono aplikacji！<a href="/">Odśwież</a> lub <a href="https://pl.aiseesoft.com/">Przejdź do oficjalnej strony internetowej</a>`,
        get_more:`Dowiedz się więcej >>`,
        edit_photo:`Edytuj zdjęcie`,
        select_photo:`Wybierz zdjęcie`,
        change_photo:`Zmień zdjęcie`,
        cancel:`Anulować`,
        hide_password:`Ukryj hasło`,
        show_password:`Pokaż hasło`,
        zoom_in:`Zbliżenie`,
        zoom_out:`Pomniejsz`,
        rotate:`Obracać się`,
        horizontal_flip:`Odwróć w poziomie`,
        vertical_flip:`Odwróć w pionie`,
        country:`Kraj`,
        country_1:`Wybierz swój kraj/region`,
        country_2:`Wyspy Alandzkie`,
        country_3:`Afganistan`,
        country_4:`Albania`,
        country_5:`Algieria`,
        country_6:`Samoa Amerykańskie`,
        country_7:`Andora`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktyda`,
        country_11:`Antigua i Barbuda`,
        country_12:`Argentyna`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbejdżan`,
        country_18:`Bahrajn`,
        country_19:`Bangladesz`,
        country_20:`Barbados`,
        country_21:`Białoruś`,
        country_22:`Belgia`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermudy`,
        country_26:`Bhutan`,
        country_27:`Boliwia`,
        country_28:`Bośnia i Hercegowina`,
        country_29:`Botswana`,
        country_30:`Wyspa Bouveta`,
        country_31:`Brazylia`,
        country_32:`Brytyjskie Terytorium Oceanu Indyjskiego`,
        country_33:`Brytyjskie Wyspy Dziewicze`,
        country_34:`Brunei`,
        country_35:`Bułgaria`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodża`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Wyspy Zielonego Przylądka`,
        country_42:`Karaiby Holandia`,
        country_43:`Kajmany`,
        country_44:`Republika Środkowoafrykańska`,
        country_45:`Czad`,
        country_46:`Chile`,
        country_47:`Chiny`,
        country_48:`Wyspa Bożego Narodzenia`,
        country_49:`Wyspy Kokosowe (Keelinga)`,
        country_50:`Kolumbia`,
        country_51:`Wyspy Cooka`,
        country_52:`Kostaryka`,
        country_53:`„Wybrzeże Kości Słoniowej”`,
        country_54:`Chorwacja`,
        country_55:`Kuba`,
        country_56:`Cypr`,
        country_57:`Republika Czeska`,
        country_58:`Demokratyczna Republika Konga`,
        country_59:`Dania`,
        country_60:`Dżibuti`,
        country_61:`Dominika`,
        country_62:`Republika Dominikany`,
        country_63:`Ekwador`,
        country_64:`Egipt`,
        country_65:`Salwador`,
        country_66:`Gwinea Równikowa`,
        country_67:`Erytrea`,
        country_68:`Estonia`,
        country_69:`Etiopia`,
        country_70:`Falklandy`,
        country_71:`Wyspy Owcze`,
        country_72:`Sfederowane Stany Mikronezji`,
        country_73:`Fidżi`,
        country_74:`Finlandia`,
        country_75:`Francja`,
        country_76:`Gujana Francuska`,
        country_77:`Polinezja Francuska`,
        country_78:`Francuskie Terytoria Południowe`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Gruzja`,
        country_82:`Niemcy`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Wielka Brytania (Wielka Brytania; Anglia)`,
        country_86:`Grecja`,
        country_87:`Grenlandia`,
        country_88:`Grenada`,
        country_89:`Gwadelupa`,
        country_90:`Guam`,
        country_91:`Gwatemala`,
        country_92:`Guernsey`,
        country_93:`Gwinea`,
        country_94:`Gwinea Bissau`,
        country_95:`Gujana`,
        country_96:`Haiti`,
        country_97:`Wyspy Heard i Wyspy McDonalda`,
        country_98:`Honduras`,
        country_99:`Hongkong`,
        country_100:`Węgry`,
        country_101:`Islandia`,
        country_102:`Indie`,
        country_103:`Indonezja`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irlandia`,
        country_107:`Wyspa Man`,
        country_108:`Izrael`,
        country_109:`Włochy`,
        country_110:`Jamajka`,
        country_111:`Japonia`,
        country_112:`Golf`,
        country_113:`Jordania`,
        country_114:`Kazachstan`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwejt`,
        country_118:`Kirgistan`,
        country_119:`Laos`,
        country_120:`Łotwa`,
        country_121:`Liban`,
        country_122:`Lesoto`,
        country_123:`Liberia`,
        country_124:`Libia`,
        country_125:`Liechtenstein`,
        country_126:`Litwa`,
        country_127:`Luksemburg`,
        country_128:`Makao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malezja`,
        country_132:`Malediwy`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Wyspy Marshalla`,
        country_136:`Martynika`,
        country_137:`Mauretania`,
        country_138:`Mauritius`,
        country_139:`Majotta`,
        country_140:`Meksyk`,
        country_141:`Moldova`,
        country_142:`Monako`,
        country_143:`Mongolia`,
        country_144:`Czarnogóra`,
        country_145:`Montserrat`,
        country_146:`Maroko`,
        country_147:`Mozambik`,
        country_148:`Birma (Birma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Holandia`,
        country_153:`Nowa Kaledonia`,
        country_154:`Nowa Zelandia`,
        country_155:`Nikaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Wyspa Norfolk`,
        country_160:`Korea Północna`,
        country_161:`Mariany Północne`,
        country_162:`Norwegia`,
        country_163:`Oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`terytoria palestyńskie`,
        country_167:`Panama`,
        country_168:`Papua Nowa Gwinea`,
        country_169:`Paragwaj`,
        country_170:`Peru`,
        country_171:`Wyspy Pitcairn`,
        country_172:`Polska`,
        country_173:`Portugalia`,
        country_174:`Portoryko`,
        country_175:`Katar`,
        country_176:`Republika Macedonii (BJRM)`,
        country_177:`Republika Konga`,
        country_178:`Zjazd`,
        country_179:`Rumunia`,
        country_180:`Federacja Rosyjska`,
        country_181:`Rwanda`,
        country_182:`Saint Barthelemy`,
        country_183:`Święty Marcin (Francja)`,
        country_184:`Saint-Pierre i Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Wyspy Świętego Tomasza i Książęca`,
        country_188:`Arabia Saudyjska`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seszele`,
        country_192:`Sierra Leone`,
        country_193:`Singapur`,
        country_194:`Słowacja`,
        country_195:`Słowenia`,
        country_196:`Wyspy Salomona`,
        country_197:`Somali`,
        country_198:`Afryka Południowa`,
        country_199:`Georgia Południowa i South Sandwich Isla`,
        country_200:`Korea Południowa`,
        country_201:`Południowy Sudan`,
        country_202:`Hiszpania`,
        country_203:`Sri Lanka`,
        country_204:`Św. Helena i Zależności`,
        country_205:`St. Kitts i Nevis`,
        country_206:`Św. Łucja`,
        country_207:`Saint Vincent i Grenadyny`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Suazi`,
        country_211:`Szwecja`,
        country_212:`Szwajcaria`,
        country_213:`Syria`,
        country_214:`Tajwan`,
        country_215:`Tadżykistan`,
        country_216:`Tanzania`,
        country_217:`Szablon:Dane krajowe SJM Svalbard`,
        country_218:`Tajlandia`,
        country_219:`Bahamy`,
        country_220:`Komory`,
        country_221:`Filipiny`,
        country_222:`Timor Wschodni (Timor Wschodni)`,
        country_223:`Iść`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trynidad i Tobago`,
        country_227:`Tunezja`,
        country_228:`indyk`,
        country_229:`Turkmenia`,
        country_230:`Turks i Caicos`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraina`,
        country_234:`Zjednoczone Emiraty Arabskie`,
        country_235:`Stany Zjednoczone Dalekie Wyspy Mniejsze`,
        country_236:`Stany Zjednoczone Ameryki (USA)`,
        country_237:`Wyspy Dziewicze Stanów Zjednoczonych`,
        country_238:`Urugwaj`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Watykan (Stolica Apostolska)`,
        country_242:`Wenezuela`,
        country_243:`Wietnam`,
        country_244:`Wallis i Futuna`,
        country_245:`Sahara Zachodnia`,
        country_246:`Jemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Zaloguj się przez Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Wszelkie prawa zastrzeżone.`,
        State:`Stan`,
        Activation_code:`Kod aktywacyjny`,
        Question:`Wymień wszystkie aplikacje, w których się zalogowałeś`,
        Copy_complete:`Kopiowanie zakończone`,


        store:`Sklep`,
        resource:`Ratunek`,
        support:`Pomoc`,
        video_converter:`Konwerter wideo`,
        multimedia:`Multimedia`,
        data_recovery:`Odzyskiwanie danych`,
        utility:`Pożytek`,
        free_tools:`Darmowe narzędzia`,
        for_windows:`Dla Windowsa`,
        for_mac:`Dla Maca`,
        pdf_solutions:`Rozwiązania PDF`,
        data_manager:`Menedżer danych`,
        other_tools:`Inne narzędzia`,
        free_online_solutions:`Darmowe rozwiązania online`,
        free_online_tools:`Darmowe narzędzia online`,
        desktop_freeware:`Darmowe oprogramowanie komputerowe`,
        product_1:`Konwerter wideo Ultimate`,
        product_2:`Całkowity konwerter wideo`,
        product_3:`Konwerter MTS`,
        product_4:`Konwerter 4K`,
        product_5:`Konwerter MXF`,
        product_6:`Konwerter 3D`,
        product_7:`Konwerter wideo Mac Ultimate`,
        product_8:`Konwerter wideo dla komputerów Mac`,
        product_9:`Konwerter MTS dla komputerów Mac`,
        product_10:`Konwerter 4K dla komputerów Mac`,
        product_11:`Konwerter MXF dla komputerów Mac`,
        product_12:`Konwerter 3D dla komputerów Mac`,
        product_13:`Odtwarzacz Blu-ray`,
        product_14:`Wzmacniacz wideo`,
        product_15:`Edytor wideo`,
        product_16:`Kreator pokazu slajdów`,
        product_17:`Twórca Blu-ray`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`Kreator DVD`,
        product_20:`Odtwarzacz Blu-ray Mac`,
        product_21:`Wzmacniacz wideo Mac`,
        product_22:`Edytor wideo dla komputerów Mac`,
        product_23:`Kreator DVD dla komputerów Mac`,
        product_24:`Konwerter MP4 dla komputerów Mac`,
        product_25:`Odzyskiwanie danych systemu Windows`,
        product_26:`FoneLab Odzyskiwanie danych iPhone'a`,
        product_27:`Odzyskiwanie systemu FoneLab iOS`,
        product_28:`Kopia zapasowa danych FoneLab iOS i amp; Przywrócić`,
        product_29:`FoneLab Odzyskiwanie danych Androida`,
        product_30:`Uszkodzone wyodrębnianie danych Androida`,
        product_31:`Kopia zapasowa danych Androida i Przywrócić`,
        product_32:`Odzyskiwanie danych Mac`,
        product_33:`Mac FoneLab Odzyskiwanie danych iPhone'a`,
        product_34:`Odzyskiwanie systemu Mac FoneLab iOS`,
        product_35:`Mac FoneLab iOS Kopia zapasowa danych i amp; Przywrócić`,
        product_36:`Mac FoneLab Odzyskiwanie danych Androida`,
        product_37:`Kopia zapasowa danych na Maca i Androida Przywrócić`,
        product_38:`Konwerter PDF Ultimate`,
        product_39:`PDF Converter Ultimate dla komputerów Mac`,
        product_40:`Połączenie PDF`,
        product_41:`Konwerter plików Mac na ePub`,
        product_42:`Narzędzie do czyszczenia komputerów Mac`,
        product_43:`FoneTrans`,
        product_44:`Transfer WhatsApp na iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Środek do czyszczenia iPhone'a`,
        product_48:`Konwerter PPT na wideo`,
        product_49:`Konwerter HEIC`,
        product_50:`Odblokowywanie iPhone'a`,
        product_51:`Darmowy konwerter wideo online`,
        product_52:`Darmowy konwerter audio online`,
        product_53:`Darmowy kompresor wideo online`,
        product_54:`Bezpłatne połączenie wideo online`,
        product_55:`Darmowy konwerter HEIC`,
        product_56:`Darmowy kompresor obrazu`,
        product_57:`Bezpłatne usuwanie znaków wodnych`,
        product_58:`Darmowy kompresor PDF online`,
        product_59:`Darmowe narzędzie do skalowania obrazu online`,
        product_60:`Darmowe narzędzie do usuwania tła online`,
        product_61:`Darmowy konwerter obrazów online`,
        product_62:`Darmowy konwerter PDF PNG online`,
        product_63:`Darmowy konwerter wideo`,
        product_64:`Darmowy konwerter wideo na GIF`,
        product_65:`Bezpłatne odzyskiwanie danych Androida`,
        product_66:`Kreator pokazu slajdów`,
        product_67:`Darmowy edytor wideo`,
        product_68:`Bezpłatna przeglądarka plików PDF`,
        product_69:`Nagrywarka ekranu`,
        product_70:`Rejestrator ekranu Mac`,
        product_71:`Darmowy rejestrator ekranu online`,
        product_72:`Darmowy rejestrator audio online`,
        product_73:`Lustro telefonu`,
        product_74:`AnyCoord - zmieniacz lokalizacji`,
        product_75:`Menedżer haseł iPhone'a`,
        product_76:`Darmowy kompresor audio online`,
        product_77:`Bezpłatny trymer wideo online`,
        product_78:`Darmowy program do przycinania wideo online`,
        product_79:`Darmowy rotator wideo online`,
        product_80:`Darmowy Looper wideo online`,
        product_81:`Darmowy konwerter PDF JPG online`,
        product_81:`Darmowy konwerter plików JPG online`,
        category_1:`Edycja zdjęć`,
        category_2:`Edycja wideo`,
        category_3:`gumka do mazania`,
        category_4:`Rejestrator multimediów`,
        posted_by:`Wysłane przez`,
        to:`do`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Jak skutecznie usunąć tekst ze zdjęć?`,
        article_2:`Jak zwiększyć rozmiar obrazu w KB do MB bez szkody dla jakości?`,
        article_3:`Jak usunąć znak wodny Getty Images z systemu Windows i Mac?`,
        article_4:`Najlepsza metoda bezpłatnej konwersji obrazu o niskiej rozdzielczości do wysokiej rozdzielczości online`,
        article_5:`Przewodnik po TikToku | Oto jak zrobić kolaż wideo na TikTok`,
        article_6:`5 łatwych sposobów łączenia plików VOB, które powinieneś znać`,
        article_7:`Jak przeskalować wideo i łatwo uzyskać wyższą rozdzielczość`,
        article_8:`Jak łączyć pliki MP4 na MacBooku Pro/iMac/MacBook i innych`,
        article_9:`2 proste sposoby usuwania historii zakupów na iPhonie [rozwiązane]`,
        article_10:`Jak trwale usunąć zdjęcia Snapchata z czatu i wspomnień?`,
        article_11:`Jak usunąć sugestie i historię wyszukiwania Spotlight na iPhonie / iPadzie?`,
        article_12:`[Rozwiązany] Jak wyczyścić pamięć podręczną Instagram na iPhonie za pomocą 3 łatwych sposobów`,
        article_13:`[Rozwiązany] Jak naprawić odtwarzacz QuickTime, który nie działa na komputerze Mac`,
        article_14:`Jak nagrywać dźwięk systemowy na komputerze Mac (Big Sur/Catalina/Mojave…)`,
        article_15:`Skuteczne sposoby naprawy niedziałającego nagrywania ekranu PowerPoint`,
        article_16:`Jak nagrywać dźwięk za pomocą VLC [rozwiązany]`,
        faq_title_1:`1. Jak naprawić błąd 4104?`,
        faq_title_2:`2. Kiedy mogę odzyskać pieniądze po zatwierdzeniu wniosku o zwrot pieniędzy?`,
        faq_title_3:`3. Co to jest „roczna subskrypcja” i jak wyłączyć „usługę automatycznego odnawiania”?`,
        faq_title_4:`4. Czy mogę anulować zamówienie po złożeniu produktu Aiseesoft?`,
        faq_title_5:`5. Jak przesłać Ci recenzję?`,
        faq_info_1:`Wynika to z ograniczonej licencji, którą masz. Przyjmujemy Jednolitą Licencję w przypadku kradzieży kodu rejestracyjnego i zapobiegamy naruszeniu użycia kodu rejestracyjnego. Więc Reg Code, który otrzymałeś, to pojedyncza licencja, która może być używana tylko na jednym komputerze. Jeśli używasz go na więcej niż jednym komputerze, pojawi się błąd 4104. Nie możesz go użyć, dopóki go nie zresetujemy.`,
        faq_info_2:`Jeśli zmienisz lub ponownie zainstalujesz komputer, możemy go zresetować. Skontaktuj się z obsługą klienta: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>, aby poprosić o zresetowanie kodu rejestracyjnego.`,
        faq_info_3:`Po zatwierdzeniu wniosku o zwrot środków środki pojawią się na Twoim koncie w ciągu 3–7 dni roboczych. Czasami w wakacje może to potrwać dłużej, ale nie przewiduje się, że zajmie to więcej niż 10 dni. Jeśli 20 dni po zatwierdzeniu nie otrzymasz zwrotu, skontaktuj się z nami pod adresem <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>, aby sprawdzić zwrot.`,
        faq_info_4:`Usługa automatycznego odnawiania oznacza, że jeśli kupisz roczną subskrypcję, platforma płatności automatycznie pobierze kwotę (w tym obowiązujące podatki) po wygaśnięciu licencji produktu. Ale platforma płatności (Swreg lub 2Checkout) wyśle Ci list z przypomnieniem, że zamówienie wygaśnie 7 dni przed wygaśnięciem. W e-mailu znajdą się również instrukcje, jak wyłączyć „Usługę automatycznego odnawiania”.`,
        faq_info_5:`Ponieważ zamówienie jest przetwarzane automatycznie, nie można anulować zamówienia. Poza tym darmowa wersja próbna pozwoli Ci dowiedzieć się więcej o programie Aiseesoft i sprawdzić, czy spełnia on Twoje potrzeby przed zakupem.`,
        faq_info_6:`Jeśli naprawdę chcesz otrzymać zwrot pieniędzy, zapoznaj się z naszą <a href="https://pl.aiseesoft.com/refund-policy.html">Polityką zwrotów</a>.`,
        faq_info_7:`Dziękujemy za poświęcony czas i uwagę na przesłanie nam recenzji. Skontaktuj się z nami (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>), aby przesłać swoją recenzję, a my poważnie rozważymy Twoją recenzję dotyczącą aktualizacji naszych produktów.`,
        change_password_success:`Hasło zostało pomyślnie zmienione`,
        successful_login_title:`Sukces logowania`,
        product_page:`Strona produktu>>`,
        successful_login_info:`Logowanie zakończone. Zamknij bieżącą stronę i wróć do oryginalnej karty, aby kontynuować następujący proces. Bieżąca strona zostanie automatycznie zamknięta za 5 sekund. Jeśli automatyczne zamknięcie lub zamknięcie przez kliknięcie przycisku „Gotowe” nie powiedzie się, zamknij tę kartę bezpośrednio.`,
        successful_login_info_firefox:`Logowanie zakończone. Zamknij bieżącą stronę i wróć do oryginalnej karty, aby kontynuować następujący proces.`,
        my_account:`Moje konto`,
        my_history:`Moja historia`,
        remove_watermark:`Usuń znak wodny`,
        no_history:`Brak historii`,
        history_all:`Zaznacz wszystko`,
        history_open:`otwarty`,
        history_down:`Ściągnij`,
        history_delete:`Kasować`,
        history_clear:`Usuń nieprawidłowe`,
        images:`obrazy)`,
        use_this_function:`Użyj tej funkcji>>`,
        hd_downloading:`Korzyści z pobierania oryginalnych obrazów HD:`,
        lifetimeRemaining:`Pozostały czas życia`,
        Remaining:`Pozostały`,
        email_verification:`Weryfikacja adresu e-mail`,
        email_verification_info:`Wysłaliśmy wiadomość weryfikacyjną na Twój adres e-mail <span class="email"></span> i prosimy o dokończenie weryfikacji. Po weryfikacji korzyści zostaną automatycznie zsynchronizowane.`,
        wrong_email:`Zły adres email?`,
        click_here_to_modify:`Kliknij tutaj, aby zmodyfikować`,
        get_more_help:`Uzyskaj dodatkową pomoc?`,
        click_here:`Kliknij tutaj`,
        email_verification_info_success:`Gratulujemy weryfikacji konta e-mail.`,
        email_verification_info_error:`Weryfikacja nie powiodła się z powodu wygasłego linku.`,
        registration_succeeded:`Rejestracja powiodła się`,
        registration_succeeded_info_1:`Gratulacje! Zarejestrowałeś się pomyślnie. Wysłaliśmy wiadomość weryfikacyjną na Twój adres e-mail <span class="email"></span> i zakończ weryfikację, aby skorzystać z zalet tego e-maila.`,
        registration_succeeded_info_2:`Kliknij „Gotowe”, aby powrócić do strony głównej i korzystać z tego produktu.`,
        registration_succeeded_info_3:`Kliknij „Gotowe”, aby zamknąć bieżącą stronę i powrócić do strony głównej, aby wykonać następujące operacje. Jeśli zamknięcie się nie powiedzie, zamknij kartę ręcznie.`,
        verify_email:`zweryfikuj adres e-mail`,
        registered_email_not_verified:`Zarejestrowany adres e-mail nie został zweryfikowany, zweryfikuj go natychmiast.`,
        email_verification_time_1:`Nie otrzymałeś e-maila weryfikacyjnego?`,
        email_verification_time_2:`Później`,
        email_verification_time_3:`Kliknij tutaj, aby wysłać go ponownie`,
        error_26301:`Kod błędu: 26301, prosimy o kontakt`,
        error_26302:`Kod błędu: 26302, skontaktuj się z nami`,
        error_26303:`Błąd formatu e-mail (kod błędu: 26303). Proszę wpisać go ponownie`,
        error_26304:`Zalecane jest hasło powyżej 8 znaków (kod błędu: 26304)`,
        error_26305:`Reuqest nie powiodło się (kod błędu: 26305). Proszę spróbować ponownie`,
        error_26306:`E-mail został zarejestrowany, <a href="https://account.aiseesoft.com/login">przejdź do logowania</a>`,
        error_26307:`Reuqest nie powiodło się (kod błędu: 26307). Proszę spróbować ponownie`,
        error_26308:`Reuqest nie powiodło się (kod błędu: 26308). Proszę spróbować ponownie`,
        error_26401:`Kod błędu: 26401, spróbuj ponownie`,
        error_26402:`E-mail został zweryfikowany (kod błędu: 26402), spróbuj ponownie`,
        error_26403:`Reuqest nie powiodło się (kod błędu: 26403). Proszę spróbować ponownie`,
        error_26404:`Żądanie nie powiodło się (kod błędu: 26404). Proszę spróbować ponownie`,
        error_26501:`Kod błędu: 26501, prosimy o kontakt`,
        error_26502:`Kod błędu: 26502, prosimy o kontakt`,
        error_26503:`Błąd formatu e-mail (kod błędu: 26503). Proszę wpisać go ponownie`,
        error_26504:`Reuqest nie powiodło się (kod błędu: 26504). Proszę spróbować ponownie`,
        error_26505:`E-mail nie został zarejestrowany, proszę <a href="https://account.aiseesoft.com/register">najpierw go zarejestruj</a>`,
        error_26506:`E-mail został zweryfikowany.`,
        error_26507:`Reuqest nie powiodło się (kod błędu: 26507). Proszę spróbować ponownie`,
        error_26508:`Weryfikacja nie powiodła się (kod błędu: 26508), spróbuj ponownie`,
        error_26509:`Żądanie nie powiodło się (kod błędu: 26509), spróbuj ponownie`,
        error_26510:`Kod błędu: 26510, prosimy o kontakt`,
        error_26601:`Kod błędu: 26601, prosimy o kontakt`,
        error_26602:`Kod błędu: 26602, skontaktuj się z nami`,
        error_26603:`Reuqest nie powiodło się (kod błędu: 26603). Proszę spróbować ponownie`,
        error_26604:`Kod błędu: 26604, prosimy o kontakt`,
        error_26605:`Kod błędu: 26605, prosimy o kontakt`,
        error_26701:`Kod błędu: 26701, skontaktuj się z nami`,
        error_26702:`Reuqest nie powiodło się (kod błędu: 26702). Proszę spróbować ponownie`,
        error_26703:`Kod błędu: 26703, prosimy o kontakt`,
        error_26704:`Kod błędu: 26704, prosimy o kontakt`,
        error_26705:`Poczekaj na zalogowanie się (kod błędu: 26705). Proszę spróbować ponownie`,
        no_cookie:`W przeglądarce została włączona funkcja Blokuj wszystkie pliki cookie, więc nie możesz się zalogować. Przejdź do Ustawień, aby zaznaczyć pole Zezwalaj na wszystkie pliki cookie.`,
        error_26801:`Kod błędu: 26801, prosimy o kontakt`,
        error_26802:`Kod błędu: 26802, prosimy o kontakt`,
        error_26803:`Żądanie nie powiodło się (kod błędu: 26803). Proszę spróbować ponownie`,
        error_26804:`Żądanie nie powiodło się (kod błędu: 26804). Proszę spróbować ponownie`,
        error_order:`Żądanie nie powiodło się (kod błędu: 27098), spróbuj ponownie!`,
        error_order1:`Zapytanie o zamówienie jest niekompletne, kod błędu:`,
        error_order2:`）, odśwież i spróbuj ponownie.`,
        modify_email_title:`Zmodyfikuj e-mail`,
        modify_email_info:`Możesz użyć zmodyfikowanego adresu e-mail, aby zalogować się na swoje konto.`,
        images_per:`Obrazy na`,
        error_26101:`Błąd: 26101. Skontaktuj się z nami`,
        error_26102:`Błąd: 26102. Skontaktuj się z nami`,
        error_26103:`Żądanie nie powiodło się (kod błędu: 26103). Spróbuj ponownie`,
        error_26104:`Kod błędu: 26104, spróbuj ponownie`,
        error_26105:`Kod błędu: 26105, spróbuj ponownie`,
        error_26106:`Nie udało się usunąć (kod błędu: 26106). Spróbuj ponownie`,
        error_26201:`Błąd: 26201. Skontaktuj się z nami`,
        error_26202:`Żądanie nie powiodło się (kod błędu: 26202). Spróbuj ponownie`,
        error_26001:`Błąd: 26001. Skontaktuj się z nami`,
        error_26002:`Błąd: 26002. Skontaktuj się z nami`,
        error_26003:`Błąd: 26003. Skontaktuj się z nami`,
        error_26004:`Błąd: 26004. Skontaktuj się z nami`,
        error_26005:`Żądanie nie powiodło się (kod błędu: 26005). Spróbuj ponownie`,
        error_26006:`Kod błędu: 26006, spróbuj ponownie`,
        error_26008:`Błąd: 26008. Skontaktuj się z nami`,
        go_to_the_home_page:`Przejdź do strony głównej`,
        error_27101:`Żądanie nie powiodło się (kod błędu: 27101). Spróbuj ponownie ist`,
        error_27201:`Kod błędu: 27201, prosimy o kontakt`,
        error_27202:`Kod błędu: 27202, spróbuj ponownie`,
        error_27203:`Żądanie nie powiodło się (kod błędu: 27203). Proszę spróbować ponownie`,
        error_27204:`Nieprawidłowy kod (kod błędu: 27204).`,
        error_27205:`Żądanie nie powiodło się (kod błędu: 27205). Proszę spróbować ponownie`,
        error_27206:`Żądanie nie powiodło się (kod błędu: 27206). Proszę spróbować ponownie`,
        error_27207:`Żądanie nie powiodło się (kod błędu: 27207). Proszę spróbować ponownie`,
        no_history_found:`Nie użyłeś żadnego narzędzia! <a href="/">Odśwież</a> lub <a href="https://pl.aiseesoft.com/">Przejdź do oficjalnej strony internetowej</a>`,
        error_25301:`Błąd: 25301. Skontaktuj się z nami`,
        error_25302:`Błąd: 25302. Skontaktuj się z nami`,
        error_25303:`Żądanie nie powiodło się (kod błędu: 25303). Spróbuj ponownie`,
        error_25304:`Żądanie nie powiodło się (kod błędu: 25304). Spróbuj ponownie`,
        error_25305:`Żądanie nie powiodło się (kod błędu: 25305). Spróbuj ponownie`,
        error_25306:`Żądanie nie powiodło się (kod błędu: 25306). Spróbuj ponownie`,
        image_upscaler_p:`Obraz bez korzyści związanych z pobieraniem znaku wodnego:`,
        Available_for:`Dostępne dla:`,
        credit_per:`%s kredytów na obraz HD`,
        still_valid:`Zakupione plany są nadal ważne`,
        credit:`kredyt(y)`,
        pc_3rd_info:`Zaloguj się pomyślnie. Proszę przejść do aplikacji w celu dalszej obsługi.`,
        use_online:`Skorzystaj z usługi online`,
        use_download:`Skorzystaj z programu komputerowego`,
        use_immediately:`Użyj natychmiast`,
        Use_in_browser:`Użyj w przeglądarce`,
        win_desktop:`Okna`,
        Mac_desktop:`Prochowiec`,
    },
    ru:{
        overtime_1:`Код ошибки:`,
        overtime_2:`Пожалуйста, попробуйте еще раз`,
        isnetwork:`Ошибка Интернета. Пожалуйста, проверьте и повторите попытку`,
        email_placeholder:`Эл. адрес`,
        email_empty:`Пожалуйста, введите адрес электронной почты`,
        email_not_valid:`Email не является допустимым`,
        email_not_valid_1:`Пожалуйста, введите свой адрес электронной почты`,
        email_not_valid_2:`Электронная почта недействительна, используйте другой адрес.`,
        email_not_valid_3:`Нет ввода электронной почты`,
        password_placeholder:`Пароль`,
        password_empty:`Пожалуйста, введите пароль`,
        password_not_valid:`Неверный аккаунт или пароль`,
        password_not_valid_1:`Требуется пароль длиной более 8 символов.`,
        password_not_valid_2:`Пожалуйста, придумайте пароль`,
        password_placeholder_1:`Создайте свой пароль`,
        password_placeholder_2:`Подтвердите ваш пароль`,
        password_placeholder_3:`Создать новый пароль`,
        password_placeholder_4:`Подтвердите новый пароль`,
        password_placeholder_5:`Введите старый пароль`,
        copy_password_empty:`Пожалуйста, подтвердите пароль`,
        copy_password_not_valid:`Пожалуйста, подтвердите свой пароль`,
        copy_passwords_inconsistent:`Подтверждение вашего пароля не совпадает`,
        code_empty:`Пожалуйста, введите проверочный код`,
        code_not_valid:`неверный проверочный код`,
        code_placeholder:`Код подтверждения`,
        not_received_code:`Если ваш почтовый ящик долгое время не получал проверочный код, пожалуйста, получите проверочный код еще раз.`,
        get_first_code:`Пожалуйста, сначала получите код подтверждения.`,
        connect_your_email_placeholder:`Подключите вашу электронную почту`,
        last_name_placeholder:`Пожалуйста, введите свою фамилию`,
        first_name_placeholder:`Пожалуйста, введите ваше имя`,
        address_placeholder:`Пожалуйста, введите ваш адрес`,
        no_code_text:`Мы отправили проверочный код. Введите свой код. <span class='tips'>Не получили код?`,
        no_code_text_1:`1.Убедитесь, что адрес электронной почты действителен и на него можно получать электронные письма.`,
        no_code_text_2:`2. Поскольку электронное письмо отправляется системой автоматически, оно может быть помечено как спам или нежелательная почта. Пожалуйста, проверьте, находится ли электронное письмо в папке "Корзина".`,
        no_code_text_3:`3. Не можете решить вашу проблему?`,
        no_code_text_3_span:`Тогда нажмите здесь, чтобы связаться с нами.`,
        order_no:`Вы не купили никаких товаров. По любым вопросам <a href="https://ru.aiseesoft.com/store/"> Делайте покупки сейчас </a> Или <a href="https://ru.aiseesoft.com/contact.html"> Свяжитесь с нами </a>`,
        error_24901:`Текущий счет не связан с электронной почтой и не может найти заказы. Пожалуйста, подключите электронную почту.`,
        user_guide:`Руководство пользователя >>`,
        download:`Скачать`,
        order_number:`Номер заказа`,
        Refund:`Возвращать деньги`,
        Disabled:`Отключено`,
        Normal:`Обычный`,
        Modify:`Изменить`,
        Modify_1:`Изменить >>`,
        Connect:`Соединять`,
        unlink_success:`Отключить успешно`,
        connect_success:`Подключиться успешно`,
        feedback_title:`Спасибо за ваш отзыв. Оставьте проблему, и мы ответим вам в течение 24 часов.`,
        feedback_thank_you:`Спасибо! <br /> Ваш отзыв был успешно отправлен.`,
        feedback_email:`Введите сюда свой адрес электронной почты!`,
        feedback_content:`Оставьте здесь любую проблему или предложение, с которыми вы столкнулись.`,
        feedback_submit:`Представлять на рассмотрение`,
        form_contents:`Вы не ввели описание. Пожалуйста, введите его и отправьте еще раз.`,
        old_password:`Пожалуйста, введите старый пароль`,
        new_password:`Пожалуйста, создайте новый пароль`,
        old_new_password:`Новый пароль не может совпадать со старым.`,
        incorrect_password:`неверный пароль`,
        delete_no:`Удалить сейчас`,
        Caps:`Включен верхний регистр`,
        Get:`Получать`,
        Done:`Выполнено`,
        error_20001:`Ошибка: 20001. Пожалуйста, войдите снова.`,
        error_20002:`Ошибка: 20002. Пожалуйста, войдите снова.`,
        error_20003:`Ошибка: 20003. Пожалуйста, войдите снова.`,
        error_20004:`Запрос не выполнен (код ошибки: 20004). Пожалуйста, попробуйте еще раз.`,
        error_20005:`Срок действия сеанса входа истек (ошибка: 20005). Пожалуйста, войдите снова.`,
        error_20006:`Запрос не выполнен (код ошибки: 20006). Пожалуйста, попробуйте еще раз.`,
        error_20007:`Срок действия сеанса входа истек (ошибка: 20007). Пожалуйста, войдите снова.`,
        error_20008:`Срок действия сеанса входа истек (ошибка: 20008). Пожалуйста, войдите снова.`,
        error_20009:`Срок действия сеанса входа истек (ошибка: 20009). Пожалуйста, войдите снова.`,
        error_20101:`Введите свой адрес электронной почты (код ошибки: 20101)`,
        error_20102:`Электронная почта недействительна (код ошибки: 20102)`,
        error_20103:`Ошибка запроса (код ошибки: 20103). Пожалуйста, попробуйте еще раз`,
        error_20104:`Электронная почта уже используется, пожалуйста, <a href="https://account.aiseesoft.com/login"> войдите в систему </a> или зарегистрируйтесь, используя новую.`,
        error_20105:`Ошибка запроса (код ошибки: 20105). Пожалуйста, попробуйте еще раз`,
        error_20106:`Не удалось отправить электронное письмо. Повторите попытку.`,
        error_20201:`Введите свой адрес электронной почты (код ошибки: 20201)`,
        error_20202:`Пожалуйста, введите свой пароль (код ошибки: 20202)`,
        error_20203:`Пожалуйста, введите проверочный код (код ошибки: 20203)`,
        error_20204:`Электронная почта недействительна (код ошибки: 20204)`,
        error_20205:`Требуется пароль длиной более 8 символов (код ошибки: 20205)`,
        error_20206:`Ошибка запроса (код ошибки: 20206). Пожалуйста, попробуйте еще раз`,
        error_20207:`неверный проверочный код`,
        error_20208:`Ошибка запроса (код ошибки: 20208). Пожалуйста, попробуйте еще раз`,
        error_20209:`Ошибка запроса (код ошибки: 20209). Пожалуйста, попробуйте еще раз`,
        error_20301:`Введите свой адрес электронной почты (код ошибки: 20301)`,
        error_20302:`Ошибка: 20302. Свяжитесь с нами.`,
        error_20303:`Электронная почта недействительна (код ошибки: 20303)`,
        error_20304:`Ошибка запроса (код ошибки: 20304). Пожалуйста, попробуйте еще раз`,
        error_20305:`Пользователь не существует. Пожалуйста, войдите повторно или сначала <a href="https://account.aiseesoft.com/register">Создать это</a>.`,
        error_20306:`Пароль еще нет. Используйте <a href="https://account.aiseesoft.com/passwordless-login"> вход без пароля </a> или <a href="create-password"> задайте пароль </a> и войдите в систему.`,
        error_20308:`Ошибка запроса (код ошибки: 20308). Пожалуйста, попробуйте еще раз`,
        error_20401:`Не удалось выйти из системы (код ошибки: 20401). Пожалуйста, попробуйте еще раз`,
        error_20501:`Введите свой адрес электронной почты (код ошибки: 20501)`,
        error_20502:`Электронная почта недействительна (код ошибки: 20502)`,
        error_20503:`Ошибка запроса (код ошибки: 20503). Пожалуйста, попробуйте еще раз`,
        error_20504:`Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз.`,
        error_20601:`Введите свой адрес электронной почты (код ошибки: 20601)`,
        error_20602:`Введите проверочный код (код ошибки: 20602)`,
        error_20603:`Электронная почта недействительна (код ошибки: 20603)`,
        error_20604:`Ошибка запроса (код ошибки: 20604). Пожалуйста, попробуйте еще раз`,
        error_20606:`Ошибка запроса (код ошибки: 20606). Пожалуйста, попробуйте еще раз`,
        error_20607:`Ошибка запроса (код ошибки: 20607). Пожалуйста, попробуйте еще раз`,
        error_20608:`Ошибка запроса (код ошибки: 20608). Пожалуйста, попробуйте еще раз`,
        error_20701:`Введите свой адрес электронной почты (код ошибки: 20701)`,
        error_20702:`Электронная почта недействительна (код ошибки: 20702)`,
        error_20703:`Ошибка запроса (код ошибки: 20703). Пожалуйста, попробуйте еще раз`,
        error_20704:`Пользователь не существует. Пожалуйста, войдите повторно или сначала <a href="https://account.aiseesoft.com/register">Создать это</a>.`,
        error_20705:`Ошибка запроса (код ошибки: 20705). Пожалуйста, попробуйте еще раз`,
        error_20706:`Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз`,
        error_20801:`Введите свой адрес электронной почты (код ошибки: 20801)`,
        error_20802:`Ошибка: 20802. Свяжитесь с нами.`,
        error_20803:`Введите проверочный код (код ошибки: 20803)`,
        error_20804:`Электронная почта недействительна (код ошибки: 20804)`,
        error_20805:`Требуется пароль длиной более 8 символов (код ошибки: 20805)`,
        error_20806:`Ошибка запроса (код ошибки: 20806). Пожалуйста, попробуйте еще раз`,
        error_20808:`Ошибка запроса (код ошибки: 20808). Пожалуйста, попробуйте еще раз`,
        error_20901:`Ошибка запроса (код ошибки: 20901). Пожалуйста, попробуйте еще раз`,
        error_20902:`Ошибка запроса (код ошибки: 20902). Пожалуйста, попробуйте еще раз`,
        error_21000:`Изменения сохранены`,
        error_21001:`Информация не отправлена (код ошибки: 21001)`,
        error_21002:`Ошибка запроса (код ошибки: 21002). Пожалуйста, попробуйте еще раз`,
        error_21101:`Введите свой адрес электронной почты (код ошибки: 21101)`,
        error_21102:`Электронная почта недействительна (код ошибки: 21102)`,
        error_21103:`Запрос не выполнен (код ошибки: 21103), пожалуйста, попробуйте еще раз.`,
        error_21104:`Электронная почта уже подключена, используйте новую`,
        error_21105:`Запрос не выполнен (код ошибки: 21105), пожалуйста, повторите его еще раз`,
        error_21106:`Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз`,
        error_21201:`Введите свой адрес электронной почты (код ошибки: 21201)`,
        error_21202:`Пожалуйста, введите проверочный код (код ошибки: 21202)`,
        error_21203:`Электронная почта недействительна (код ошибки: 21203)`,
        error_21204:`Ошибка: 21204. Свяжитесь с нами.`,
        error_21205:`Ошибка: 21205. Свяжитесь с нами.`,
        error_21206:`Требуется пароль длиной более 8 символов (код ошибки: 21206)`,
        error_21207:`Ошибка запроса (код ошибки: 21207). Пожалуйста, попробуйте еще раз`,
        error_21209:`Ошибка запроса (код ошибки: 21209). Пожалуйста, попробуйте еще раз`,
        error_21301:`Введите старый пароль (код ошибки: 21301)`,
        error_21302:`Пожалуйста, создайте новый пароль (код ошибки: 21302)`,
        error_21303:`Новый пароль не может совпадать со старым. (Ошибка: 21303)`,
        error_21304:`Требуется пароль длиной более 8 символов (код ошибки: 21304)`,
        error_21306:`Ошибка запроса (код ошибки: 21306). Пожалуйста, попробуйте еще раз`,
        error_21402:`Ошибка запроса (код ошибки: 21402). Пожалуйста, попробуйте еще раз`,
        error_21403:`Не удалось отправить проверочный код. Пожалуйста, отправьте его повторно`,
        error_21500:`Аккаунт удален`,
        error_21501:`Пожалуйста, введите проверочный код (код ошибки: 21501)`,
        error_21502:`Срок действия сеанса входа истек (ошибка: 21502). Пожалуйста, войдите снова.`,
        error_21503:`Ошибка запроса (код ошибки: 21503). Пожалуйста, попробуйте еще раз`,
        error_21505:`Запрос не выполнен (код ошибки: 21505), пожалуйста, повторите попытку.`,
        error_21601:`Ошибка: 20601. Свяжитесь с нами.`,
        error_21602:`Недействительная проверка (ошибка: 20602). Пожалуйста, попробуйте еще раз.`,
        error_21603:`Ошибка: 20603. Повторите попытку.`,
        error_21604:`Ошибка запроса (код ошибки: 21604). Пожалуйста, попробуйте еще раз`,
        error_21606:`Ошибка запроса (код ошибки: 21606). Пожалуйста, попробуйте еще раз`,
        error_21611:`Ошибка запроса (код ошибки: 21611). Пожалуйста, попробуйте еще раз`,
        error_21801:`Ошибка: 21801. Свяжитесь с нами.`,
        error_21802:`Ошибка запроса (ошибка 21802). Пожалуйста, попробуйте еще раз`,
        error_21803:`Ошибка: 21803. Повторите попытку.`,
        error_21804:`Ошибка запроса (код ошибки: 21804). Пожалуйста, попробуйте еще раз`,
        error_21806:`Ошибка: 21806. Повторите попытку.`,
        error_21807:`Ошибка: 21807. Свяжитесь с нами.`,
        error_21808:`Ошибка: 21808. Свяжитесь с нами.`,
        error_21809:`Ошибка: 21809. Свяжитесь с нами.`,
        error_21810:`Ошибка: 21810. Свяжитесь с нами.`,
        error_21811:`Ошибка: 21811. Свяжитесь с нами.`,
        error_21812:`Ошибка: 21812. Свяжитесь с нами.`,
        error_21813:`Ошибка запроса (код ошибки: 21813). Пожалуйста, попробуйте еще раз`,
        error_21814:`Ошибка: 21814. Свяжитесь с нами.`,
        error_21815:`Ошибка запроса (код ошибки: 21815). Пожалуйста, попробуйте еще раз`,
        error_21816:`Ошибка: 21816. Свяжитесь с нами.`,
        error_21817:`Ошибка: 21817. Свяжитесь с нами.`,
        error_21818:`Ошибка: 21818. Свяжитесь с нами.`,
        error_21819:`Ошибка запроса (код ошибки: 21819). Пожалуйста, попробуйте еще раз`,
        error_21820:`Ошибка: 21820. Свяжитесь с нами.`,
        error_21821:`Ошибка: 21821. Свяжитесь с нами.`,
        error_21822:`Ошибка: 21822. Свяжитесь с нами.`,
        error_21823:`Ошибка запроса (код ошибки: 21823). Пожалуйста, попробуйте еще раз`,
        error_21824:`Ошибка запроса (код ошибки: 21824). Пожалуйста, попробуйте еще раз`,
        error_21825:`Ошибка запроса (код ошибки: 21825). Пожалуйста, попробуйте еще раз`,
        error_21826:`Ошибка запроса (код ошибки: 21826). Пожалуйста, попробуйте еще раз`,
        error_21828:`Ошибка: 21828. Свяжитесь с нами.`,
        error_21901:`Ошибка: 21901. Свяжитесь с нами.`,
        error_21902:`Ошибка запроса (код ошибки: 21902). Пожалуйста, попробуйте еще раз`,
        error_21903:`Статус учетной записи изменился (код ошибки: 21903), обновите страницу и повторите попытку.`,
        error_21904:`Ошибка: 21904. Повторите попытку.`,
        error_21905:`Ошибка: 21905. Повторите попытку.`,
        error_21906:`Ошибка запроса (код ошибки: 21906). Пожалуйста, попробуйте еще раз`,
        error_21907:`Аккаунт Google был связан с другим аккаунтом`,
        error_21908:`Ошибка запроса (код ошибки: 21908). Пожалуйста, попробуйте еще раз`,
        error_22001:`Ошибка запроса (код ошибки: 22001). Пожалуйста, попробуйте еще раз`,
        error_22002:`Не удалось отменить связь без дополнительного входа`,
        error_22003:`Ошибка запроса (код ошибки: 22003). Пожалуйста, попробуйте еще раз`,
        error_22101:`Ошибка: 22101. Свяжитесь с нами.`,
        error_22102:`Статус учетной записи изменился (код ошибки: 22102), обновите страницу и повторите попытку.`,
        error_22103:`Ошибка запроса (код ошибки: 22103). Пожалуйста, попробуйте еще раз`,
        error_22104:`Статус учетной записи изменился (код ошибки: 22104), обновите страницу и повторите попытку.`,
        error_22105:`Ошибка: 22105. Повторите попытку.`,
        error_22106:`Ошибка: 22106. Повторите попытку.`,
        error_22107:`Ошибка: 22107. Свяжитесь с нами.`,
        error_22108:`Ошибка запроса (код ошибки: 22108). Пожалуйста, попробуйте еще раз`,
        error_22201:`Ошибка: 22201. Свяжитесь с нами.`,
        error_22202:`Недействительная проверка (ошибка: 22202). Пожалуйста, попробуйте еще раз.`,
        error_22203:`Ошибка: 22203. Пожалуйста, попробуйте еще раз »`,
        error_22204:`Ошибка запроса (код ошибки: 22204). Пожалуйста, попробуйте еще раз`,
        error_22206:`Ошибка запроса (код ошибки: 22206). Пожалуйста, попробуйте еще раз`,
        error_22401:`Ошибка: 22401. Свяжитесь с нами.`,
        error_22402:`Неверная проверка (ошибка: 22402). Пожалуйста, попробуйте еще раз.`,
        error_22403:`Ошибка: 22403. Повторите попытку.`,
        error_22404:`Ошибка запроса (код ошибки: 22404). Пожалуйста, попробуйте еще раз`,
        error_22405:`Аккаунт Facebook был связан с другим адресом электронной почты`,
        error_22406:`Ошибка: 22406. Повторите попытку.`,
        error_22407:`Ошибка: 22407. Свяжитесь с нами.`,
        error_22408:`Ошибка: 22408. Свяжитесь с нами.`,
        error_22409:`Ошибка: 22409. Свяжитесь с нами.`,
        error_22410:`Ошибка: 224010. Свяжитесь с нами.`,
        error_22411:`Ошибка: 224011. Свяжитесь с нами.`,
        error_22412:`Ошибка: 224012. Свяжитесь с нами.`,
        error_22413:`Ошибка: 22413. Свяжитесь с нами.`,
        error_22414:`Ошибка запроса (код ошибки: 22414). Пожалуйста, попробуйте еще раз`,
        error_22415:`Ошибка: 22415. Свяжитесь с нами.`,
        error_22416:`Ошибка: 22416. Свяжитесь с нами.`,
        error_22417:`Ошибка: 22417. Свяжитесь с нами.`,
        error_22418:`Ошибка запроса (код ошибки: 22418). Пожалуйста, попробуйте еще раз`,
        error_22419:`Ошибка: 22419. Свяжитесь с нами.`,
        error_22420:`Ошибка: 22420. Свяжитесь с нами.`,
        error_22421:`Ошибка: 22421. Свяжитесь с нами.`,
        error_22422:`Ошибка запроса (код ошибки: 22422). Пожалуйста, попробуйте еще раз`,
        error_22423:`Ошибка запроса (код ошибки: 22423). Пожалуйста, попробуйте еще раз`,
        error_22424:`Ошибка запроса (код ошибки: 22424). Пожалуйста, попробуйте еще раз`,
        error_22425:`Ошибка запроса (код ошибки: 22425). Пожалуйста, попробуйте еще раз`,
        error_20098:`Код ошибки: 20098. Если вы просматриваете Интернет в приватном режиме, переключитесь в нормальный режим и попробуйте еще раз.`,
        error_22298:`Запрос Google не удался (ошибка: 22298). Пожалуйста, попробуйте еще раз.`,
        error_22498:`Запрос Facebook не удался (ошибка: 22498). Пожалуйста, попробуйте еще раз.`,
        error_24902:`Ошибка запроса (код ошибки: 24902). Пожалуйста, попробуйте еще раз`,
        error_24903:`Ошибка запроса (код ошибки: 24903). Пожалуйста, попробуйте еще раз`,
        error_24904:`Ошибка запроса (код ошибки: 24904). Пожалуйста, попробуйте еще раз`,
        error_24905:`Ошибка запроса (код ошибки: 24905). Пожалуйста, попробуйте еще раз`,
        login_title:`Войти в Aiseesoft`,
        password_placeholder:`Пароль`,
        log_in:`Авторизоваться`,
        no_account:`Нет аккаунта?`,
        create_it:`Создать это`,
        or_log_in_with:`Или войдите с помощью`,
        passwordless_login:`Вход без пароля`,
        log_in_done:`Войти Готово`,
        three_rd_account_connect_info:`Поздравляю! Вы успешно вошли в систему. Теперь вы можете подключить одну учетную запись электронной почты, которая будет использоваться для входа в систему в будущем.`,
        connect_now:`Подключиться сейчас`,
        see_my_account:`Посмотреть мой аккаунт`,
        three_rd_login_merge_account:`Адрес электронной почты сторонней учетной записи был зарегистрирован, вы хотите подключиться и войти в систему с этим адресом электронной почты напрямую?`,
        connect_log_in:`Подключиться и войти`,
        create_an_account:`Завести аккаунт`,
        connect_account:`Подключить аккаунт`,
        connect_account_info:`После подключения вы можете войти в систему с этой учетной записью.`,
        back_to_log_in:`Вернуться на страницу входа`,
        create_password:`Придумать пароль`,
        create_now:`Создать сейчас`,
        password_login_subtitle:`Вход без пароля по электронной почте`,
        account_login:`Логин аккаунта`,
        rights:`Создавая этот аккаунт, вы соглашаетесь с <a href="https://ru.aiseesoft.com/company/terms-and-conditions.html"> Условиями использования </a> и <a href="https://ru.aiseesoft.com/privacy-policy.html"> Политика конфиденциальности </a>`,
        passwordless_login_done:`Вход без пароля выполнен`,
        passwordless_login_info:`Поздравляем, вы успешно выполнили вход без пароля. Вы можете создать пароль для этой учетной записи и в будущем войти в систему с этой учетной записью и паролем. <a href="/create-password" style="display: initial;"> Создать сейчас </a>`,
        sign_up:`Зарегистрироваться`,
        register_info:`Создать аккаунт`,
        reset_now:`Сбросить сейчас`,
        forgot_password:`Забыл пароль`,
        reset_password_subtitle:`Используйте адрес электронной почты вашего аккаунта, чтобы сбросить пароль`,
        plan_products:`План и продукты`,
        nick_name:`Имя пользователя`,
        email:`Эл. адрес`,
        my_products:`Мои продукты`,
        my_orders:`Мои заказы`,
        unlink:`Отменить связь`,
        link:`Ссылка`,
        connected_accounts:`Подключенные аккаунты`,
        last_name:`Фамилия`,
        first_name:`Имя`,
        Gender:`Пол`,
        Birth:`Рождение`,
        Month:`Месяц`,
        Year:`Год`,
        Country_Region:`Страна / регион`,
        Address:`Адрес`,
        Save:`Сохранить`,
        Date:`Дата`,
        Male:`Мужчина`,
        Female:`женский`,
        Unspecified:`Неопределенные`,
        Security:`Безопасность`,
        change_password:`Измени пароль`,
        change_now:`Изменить сейчас`,
        connect_email:`Подключить электронную почту`,
        delete_account:`Удалить аккаунт`,
        delete_account_info:`Когда ваша учетная запись будет удалена, все данные в Планах и продуктах, связанные с вашей учетной записью, будут безвозвратно удалены, и вы не сможете их восстановить. Мы рекомендуем действовать осторожно.`,
        Delete:`Удалить`,
        Logout:`Выйти`,
        my_profile:`Мой профайл`,
        guides_faqs:`Руководства и часто задаваемые вопросы`,
        More:`Более`,
        guides:`Гиды`,
        register:`регистр`,
        hot_faq:`Горячие FAQ`,
        Contents:`Содержание:`,
        contact_us:`Свяжитесь с нами >>`,
        plan:`План`,
        unregistered:`Незарегистрированный`,
        buy_more:`Купить больше`,
        buy_again:`Купить снова`,
        buy_now:`купить сейчас`,
        free_no_limit:`Бесплатно и без ограничений`,
        expired:`Истекший`,
        lifetime:`Продолжительность жизни`,
        remain:`Оставаться`,
        day_s:`День (дни)`,
        error_24801:`Ошибка запроса (код ошибки: 24801). Пожалуйста, попробуйте еще раз`,
        no_app_found:`Приложение не найдено！ <a href="/"> Обновите </a> или <a href="https://ru.aiseesoft.com/"> перейдите на официальный сайт </a>`,
        get_more:`Получить больше >>`,
        edit_photo:`Редактировать фотографию`,
        select_photo:`Выбрать фото`,
        change_photo:`Измени фотографию`,
        cancel:`Отмена`,
        hide_password:`Скрыть пароль`,
        show_password:`Показать пароль`,
        zoom_in:`Увеличить`,
        zoom_out:`Уменьшить`,
        rotate:`Повернуть`,
        horizontal_flip:`Горизонтальный флип`,
        vertical_flip:`Вертикальный флип`,
        country:`Страна`,
        country_1:`Выберите вашу страну / регион`,
        country_2:`Остров Аланд`,
        country_3:`Афганистан`,
        country_4:`Албания`,
        country_5:`Алжир`,
        country_6:`американское Самоа`,
        country_7:`Андорра`,
        country_8:`Ангола`,
        country_9:`Ангилья`,
        country_10:`Антарктида`,
        country_11:`Антигуа и Барбуда`,
        country_12:`Аргентина`,
        country_13:`Армения`,
        country_14:`Аруба`,
        country_15:`Австралия`,
        country_16:`Австрия`,
        country_17:`Азербайджан`,
        country_18:`Бахрейн`,
        country_19:`Бангладеш`,
        country_20:`Барбадос`,
        country_21:`Беларусь`,
        country_22:`Бельгия`,
        country_23:`Белиз`,
        country_24:`Бенин`,
        country_25:`Бермуды`,
        country_26:`Бутан`,
        country_27:`Боливия`,
        country_28:`Босния и Герцеговина`,
        country_29:`Ботсвана`,
        country_30:`Остров Буве`,
        country_31:`Бразилия`,
        country_32:`Британская территория Индийского океана`,
        country_33:`Британские Виргинские острова`,
        country_34:`Бруней`,
        country_35:`Болгария`,
        country_36:`Буркина`,
        country_37:`Бурунди`,
        country_38:`Камбоджа`,
        country_39:`Камерун`,
        country_40:`Канада`,
        country_41:`Кабо-Верде`,
        country_42:`Карибские Нидерланды`,
        country_43:`Каймановы острова`,
        country_44:`Центрально-Африканская Республика`,
        country_45:`Чад`,
        country_46:`Чили`,
        country_47:`Китай`,
        country_48:`Остров Рождества`,
        country_49:`Кокосовые (Килинг) острова`,
        country_50:`Колумбия`,
        country_51:`Острова Кука`,
        country_52:`Коста-Рика`,
        country_53:`"Берег Слоновой Кости"`,
        country_54:`Хорватия`,
        country_55:`Куба`,
        country_56:`Кипр`,
        country_57:`Чехия`,
        country_58:`Демократическая Республика Конго`,
        country_59:`Дания`,
        country_60:`Джибути`,
        country_61:`Доминика`,
        country_62:`Доминиканская Республика`,
        country_63:`Эквадор`,
        country_64:`Египет`,
        country_65:`Сальвадор`,
        country_66:`Экваториальная Гвинея`,
        country_67:`Эритрея`,
        country_68:`Эстония`,
        country_69:`Эфиопия`,
        country_70:`Фолклендские острова`,
        country_71:`Фарерские острова`,
        country_72:`Федеративные Штаты Микронезии`,
        country_73:`Фиджи`,
        country_74:`Финляндия`,
        country_75:`Франция`,
        country_76:`Французская Гвиана`,
        country_77:`Французская Полинезия`,
        country_78:`Южные Французские Территории`,
        country_79:`Габон`,
        country_80:`Гамбия`,
        country_81:`Грузия`,
        country_82:`Германия`,
        country_83:`Гана`,
        country_84:`Гибралтар`,
        country_85:`Великобритания (Великобритания; Англия)`,
        country_86:`Греция`,
        country_87:`Гренландия`,
        country_88:`Гренада`,
        country_89:`Гваделупа`,
        country_90:`Гуам`,
        country_91:`Гватемала`,
        country_92:`Гернси`,
        country_93:`Гвинея`,
        country_94:`Гвинея-Бисау`,
        country_95:`Гайана`,
        country_96:`Гаити`,
        country_97:`Остров Херд и острова Макдональд`,
        country_98:`Гондурас`,
        country_99:`Гонконг`,
        country_100:`Венгрия`,
        country_101:`Исландия`,
        country_102:`Индия`,
        country_103:`Индонезия`,
        country_104:`Иран`,
        country_105:`Ирак`,
        country_106:`Ирландия`,
        country_107:`Остров Мэн`,
        country_108:`Израиль`,
        country_109:`Италия`,
        country_110:`Ямайка`,
        country_111:`Япония`,
        country_112:`Джерси`,
        country_113:`Иордания`,
        country_114:`Казахстан`,
        country_115:`Кения`,
        country_116:`Кирибати`,
        country_117:`Кувейт`,
        country_118:`Кыргызстан`,
        country_119:`Лаос`,
        country_120:`Латвия`,
        country_121:`Ливан`,
        country_122:`Лесото`,
        country_123:`Либерия`,
        country_124:`Ливия`,
        country_125:`Лихтенштейн`,
        country_126:`Литва`,
        country_127:`Люксембург`,
        country_128:`Макао`,
        country_129:`Мадагаскар`,
        country_130:`Малави`,
        country_131:`Малайзия`,
        country_132:`Мальдивы`,
        country_133:`Мали`,
        country_134:`Мальта`,
        country_135:`Маршалловы острова`,
        country_136:`Мартиника`,
        country_137:`Мавритания`,
        country_138:`Маврикий`,
        country_139:`Майотта`,
        country_140:`Мексика`,
        country_141:`Молдова`,
        country_142:`Монако`,
        country_143:`Монголия`,
        country_144:`Черногория`,
        country_145:`Монтсеррат`,
        country_146:`Марокко`,
        country_147:`Мозамбик`,
        country_148:`Мьянма (Бирма)`,
        country_149:`Намибия`,
        country_150:`Науру`,
        country_151:`Непал`,
        country_152:`Нидерланды`,
        country_153:`Новая Каледония`,
        country_154:`Новая Зеландия`,
        country_155:`Никарагуа`,
        country_156:`Нигер`,
        country_157:`Нигерия`,
        country_158:`Ниуэ`,
        country_159:`Остров Норфолк`,
        country_160:`Северная Корея`,
        country_161:`Северные Марианские острова`,
        country_162:`Норвегия`,
        country_163:`Оман`,
        country_164:`Пакистан`,
        country_165:`Палау`,
        country_166:`Территории Палестины`,
        country_167:`Панама`,
        country_168:`Папуа - Новая Гвинея`,
        country_169:`Парагвай`,
        country_170:`Перу`,
        country_171:`Острова Питкэрн`,
        country_172:`Польша`,
        country_173:`Португалия`,
        country_174:`Пуэрто-Рико`,
        country_175:`Катар`,
        country_176:`Республика Македония (БЮРМ)`,
        country_177:`Республика Конго`,
        country_178:`Реюньон`,
        country_179:`Румыния`,
        country_180:`Российская Федерация`,
        country_181:`Руанда`,
        country_182:`Сен-Бартелеми`,
        country_183:`Сен-Мартен (Франция)`,
        country_184:`Сен-Пьер и Микелон`,
        country_185:`Самоа`,
        country_186:`Сан-Марино`,
        country_187:`Сан-Томе и Принсипи`,
        country_188:`Саудовская Аравия`,
        country_189:`Сенегал`,
        country_190:`Сербия`,
        country_191:`Сейшельские острова`,
        country_192:`Сьерра-Леоне`,
        country_193:`Сингапур`,
        country_194:`Словакия`,
        country_195:`Словения`,
        country_196:`Соломоновы острова`,
        country_197:`Сомали`,
        country_198:`Южная Африка`,
        country_199:`Южная Георгия и Южный Сэндвич-Айла`,
        country_200:`Южная Корея`,
        country_201:`южный Судан`,
        country_202:`Испания`,
        country_203:`Шри-Ланка`,
        country_204:`Остров Св. Елены и зависимые территории`,
        country_205:`Сент-Китс и Невис`,
        country_206:`Сент-Люсия`,
        country_207:`Сент-Винсент и Гренадины`,
        country_208:`Судан`,
        country_209:`Суринам`,
        country_210:`Свазиленд`,
        country_211:`Швеция`,
        country_212:`Швейцария`,
        country_213:`Сирия`,
        country_214:`Тайвань`,
        country_215:`Таджикистан`,
        country_216:`Танзания`,
        country_217:`Шаблон: Данные страны SJM Svalbard`,
        country_218:`Таиланд`,
        country_219:`Багамские острова`,
        country_220:`Коморские острова`,
        country_221:`Филиппины`,
        country_222:`Тимор-Лешти (Восточный Тимор)`,
        country_223:`Идти`,
        country_224:`Токелау`,
        country_225:`Тонга`,
        country_226:`Тринидад и Тобаго`,
        country_227:`Тунис`,
        country_228:`Турция`,
        country_229:`Туркменистан`,
        country_230:`Острова Теркс и Кайкос`,
        country_231:`Тувалу`,
        country_232:`Уганда`,
        country_233:`Украина`,
        country_234:`Объединенные Арабские Эмираты`,
        country_235:`Внешние малые острова США`,
        country_236:`Соединенные Штаты Америки (США)`,
        country_237:`Виргинские острова США`,
        country_238:`Уругвай`,
        country_239:`Узбекистан`,
        country_240:`Вануату`,
        country_241:`Ватикан (Святой Престол)`,
        country_242:`Венесуэла`,
        country_243:`Вьетнам`,
        country_244:`Уоллис и Футуна`,
        country_245:`Западная Сахара`,
        country_246:`Йемен`,
        country_247:`Замбия`,
        country_248:`Зимбабве`,
        google_login:`Войти через Google`,
        footer:`Авторские права © 2024 Aiseesoft Studio. Все права защищены.`,
        State:`Состояние`,
        Activation_code:`Код активации`,
        Question:`Список всех приложений, в которые вы вошли`,
        Copy_complete:`Копирование завершено`,


        store:`Магазин`,
        resource:`Ресурс`,
        support:`служба поддержки`,
        video_converter:`Видео конвертер`,
        multimedia:`Мультимедиа`,
        data_recovery:`Восстановление данных`,
        utility:`Утилита`,
        free_tools:`Бесплатные инструменты`,
        for_windows:`Для Windows`,
        for_mac:`Для Mac`,
        pdf_solutions:`Решения PDF`,
        data_manager:`Менеджер данных`,
        other_tools:`Прочие инструменты`,
        free_online_solutions:`Бесплатные онлайн-решения`,
        free_online_tools:`Бесплатные онлайн-инструменты`,
        desktop_freeware:`Бесплатное ПО для настольных ПК`,
        product_1:`Конвертер видео Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`Конвертер MTS`,
        product_4:`Конвертер 4K`,
        product_5:`Конвертер MXF`,
        product_6:`3D конвертер`,
        product_7:`Конвертер видео для Mac Ultimate`,
        product_8:`Конвертер видео для Mac`,
        product_9:`Конвертер MTS для Mac`,
        product_10:`Конвертер 4K для Mac`,
        product_11:`Конвертер MXF для Mac`,
        product_12:`Конвертер 3D для Mac`,
        product_13:`Блю-рей проигрыватель`,
        product_14:`Video Enhancer`,
        product_15:`Видео редактор`,
        product_16:`Создатель слайд-шоу`,
        product_17:`Создатель Blu-ray`,
        product_18:`Burnova - Создатель DVD Pro`,
        product_19:`Создатель DVD`,
        product_20:`Mac Blu-ray Player`,
        product_21:`Mac Video Enhancer`,
        product_22:`Видеоредактор для Mac`,
        product_23:`DVD Creator для Mac`,
        product_24:`Конвертер MP4 для Mac`,
        product_25:`Восстановление данных Windows`,
        product_26:`FoneLab iPhone Восстановление данных`,
        product_27:`Восстановление системы FoneLab iOS`,
        product_28:`FoneLab iOS Data Backup & amp; Восстановить`,
        product_29:`FoneLab Android Восстановление данных`,
        product_30:`Извлечение сломанных данных Android`,
        product_31:`Резервное копирование данных Android & amp; Восстановить`,
        product_32:`Восстановление данных Mac`,
        product_33:`Mac FoneLab iPhone Восстановление данных`,
        product_34:`Восстановление системы Mac FoneLab iOS`,
        product_35:`Mac FoneLab iOS Data Backup & amp; Восстановить`,
        product_36:`Mac FoneLab Android Восстановление данных`,
        product_37:`Резервное копирование данных Android на Mac & amp; Восстановить`,
        product_38:`Конвертер PDF Ultimate`,
        product_39:`PDF Converter Ultimate для Mac`,
        product_40:`PDF слияние`,
        product_41:`Конвертер Mac PDF в ePub`,
        product_42:`Mac Cleaner`,
        product_43:`FoneTrans`,
        product_44:`WhatsApp Transfer для iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Очиститель iPhone`,
        product_48:`Конвертер PPT в видео`,
        product_49:`Конвертер HEIC`,
        product_50:`Разблокировка iPhone`,
        product_51:`Бесплатный конвертер видео онлайн`,
        product_52:`Бесплатный аудио конвертер онлайн`,
        product_53:`Бесплатный видео компрессор онлайн`,
        product_54:`Бесплатное слияние видео онлайн`,
        product_55:`Бесплатный конвертер HEIC`,
        product_56:`Бесплатный компрессор изображений`,
        product_57:`Бесплатное средство для удаления водяных знаков`,
        product_58:`Бесплатный онлайн-компрессор PDF`,
        product_59:`Бесплатное масштабирование изображений в Интернете`,
        product_60:`Бесплатная программа для удаления фона онлайн`,
        product_61:`Бесплатный онлайн-конвертер изображений`,
        product_62:`Бесплатный онлайн-конвертер PDF PNG`,
        product_63:`Бесплатный конвертер видео`,
        product_64:`Бесплатный конвертер видео в GIF`,
        product_65:`Бесплатное восстановление данных Android`,
        product_66:`Создатель слайд-шоу`,
        product_67:`Бесплатный редактор видео`,
        product_68:`Бесплатная программа для просмотра PDF-файлов`,
        product_69:`Запись экрана`,
        product_70:`Программа записи экрана Mac`,
        product_71:`Бесплатная запись экрана онлайн`,
        product_72:`Бесплатный аудиорекордер онлайн`,
        product_73:`Зеркало телефона`,
        product_74:`AnyCoord - Смена местоположения`,
        product_75:`Менеджер паролей iPhone`,
        product_76:`Бесплатный аудио компрессор онлайн`,
        product_77:`Бесплатный видеотриммер онлайн`,
        product_78:`Бесплатная обрезка видео онлайн`,
        product_79:`Бесплатный ротатор видео онлайн`,
        product_80:`Бесплатный лупер видео онлайн`,
        product_81:`Бесплатный конвертер PDF в JPG онлайн`,
        product_81:`Бесплатный онлайн-конвертер JPG Word`,
        category_1:`Фоторедактирование`,
        category_2:`Редактирование видео`,
        category_3:`Ластик`,
        category_4:`Медиа Рекордер`,
        posted_by:`Сообщение от`,
        to:`к`,
        author_1:`Эшли Мэй`,
        author_2:`Дженни Райан`,
        author_3:`Николь Джонс`,
        author_4:`Аманда Браун`,
        article_1:`Как эффективно удалить текст с изображений`,
        article_2:`Как увеличить размер изображения в КБ до МБ без ущерба для качества`,
        article_3:`Как удалить водяной знак Getty Images на Windows и Mac`,
        article_4:`Лучший способ бесплатно конвертировать изображения с низким разрешением в изображения с высоким разрешением онлайн`,
        article_5:`Руководство по TikTok | Вот как сделать видеоколлаж на TikTok`,
        article_6:`5 простых способов объединения файлов VOB, о которых вы должны знать`,
        article_7:`Как повысить качество видео и легко получить более высокое разрешение`,
        article_8:`Как объединить файлы MP4 на MacBook Pro / iMac / MacBook и др.`,
        article_9:`2 простых способа удалить историю покупок на iPhone [решено]`,
        article_10:`Как удалить фотографии Snapchat навсегда из чата и воспоминаний`,
        article_11:`Как удалить предложения и историю поиска Spotlight на iPhone / iPad`,
        article_12:`[Решено] Как очистить кеш Instagram на iPhone с помощью простых способов 3`,
        article_13:`[Решено] Как исправить, что QuickTime Player не работает на Mac`,
        article_14:`Как записать системный звук на Mac (Big Sur / Catalina / Mojave…)`,
        article_15:`Эффективные способы исправить неработающую запись экрана PowerPoint`,
        article_16:`Как записывать звук с помощью VLC [решено]`,
        faq_title_1:`1. Как исправить ошибку 4104?`,
        faq_title_2:`2. Когда я могу получить обратно свои деньги после утверждения запроса на возврат?`,
        faq_title_3:`3. Что такое «Подписка на 1 год» и как отключить «Услугу автоматического продления»?`,
        faq_title_4:`4. Могу ли я отменить свой заказ после размещения продукта Aiseesoft?`,
        faq_title_5:`5. Как отправить вам отзыв?`,
        faq_info_1:`Это связано с ограниченной лицензией, которую вы получили. Мы принимаем Единую лицензию на случай кражи регистрационного кода и предотвращаем несанкционированное использование регистрационного кода. Таким образом, у вас есть регистрационный код, который можно использовать только на одном компьютере. Если вы используете его на нескольких компьютерах, будет ошибка 4104. Вы не сможете использовать его, пока мы не сбросим его для вас.`,
        faq_info_2:`Если вы измените или переустановите свой компьютер, мы можем сбросить его для вас. Обратитесь в службу поддержки клиентов: <a href="mailto:support@aiseesoft.com"> support@aiseesoft.com </a>, чтобы запросить сброс регистрационного кода для вас.`,
        faq_info_3:`Как правило, после утверждения вашего запроса на возврат средств на вашем счете может пройти от 3 до 7 рабочих дней. Иногда во время праздников это может занять больше времени, но не ожидается, что это займет более 10 дней. Если вы не получили возмещение через 20 дней после утверждения, свяжитесь с нами по адресу <a href="mailto:support@aiseesoft.com"> support@aiseesoft.com </a>, чтобы проверить возврат.`,
        faq_info_4:`Услуга автоматического продления означает, что если вы покупаете подписку на 1 год, платежная платформа автоматически взимает сумму (включая применимые налоги) по истечении срока действия лицензии на продукт. Но форма оплаты (Swreg или 2Checkout) отправит вам письмо, чтобы напомнить вам, что срок действия заказа истекает за 7 дней до истечения срока. В электронном письме также будут содержаться инструкции по отключению «Службы автоматического продления».`,
        faq_info_5:`Поскольку заказ обрабатывается автоматически, его нельзя отменить. Кроме того, бесплатная пробная версия позволит вам узнать больше о программе Aiseesoft и проверить, соответствует ли она вашим потребностям, прежде чем покупать ее.`,
        faq_info_6:`Если вы действительно хотите получить возмещение, ознакомьтесь с нашей <a href="https://ru.aiseesoft.com/refund-policy.html"> Политикой возврата </a>.`,
        faq_info_7:`Мы ценим ваше время и внимание, чтобы оставить нам отзыв. Свяжитесь с нами (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>), чтобы оставить свой отзыв, и мы серьезно рассмотрим ваш отзыв для обновлений наших продуктов.`,
        change_password_success:`Пароль успешно изменен`,
        successful_login_title:`Успешный вход в систему`,
        product_page:`Страница продукта >>`,
        successful_login_info:`Авторизация завершена. Закройте текущую страницу и вернитесь на исходную вкладку, чтобы продолжить следующий процесс. Текущая страница будет закрыта автоматически через 5 секунд. Если автоматическое закрытие или закрытие нажатием кнопки «Готово» не удается, закройте эту вкладку напрямую.`,
        successful_login_info_firefox:`Авторизация завершена. Закройте текущую страницу и вернитесь на исходную вкладку, чтобы продолжить следующий процесс.`,
        my_account:`Мой счет`,
        my_history:`Моя история`,
        remove_watermark:`Удалить водяной знак`,
        no_history:`Нет истории`,
        history_all:`Выбрать все`,
        history_open:`Открытым`,
        history_down:`Скачать`,
        history_delete:`Удалить`,
        history_clear:`Удалить недействительный`,
        images:`картинки)`,
        use_this_function:`Используйте эту функцию >>`,
        hd_downloading:`Преимущества загрузки исходных изображений HD:`,
        lifetimeRemaining:`Оставшееся время жизни`,
        Remaining:`Оставшийся`,
        email_verification:`Подтверждение по элетронной почте`,
        email_verification_info:`Мы отправили сообщение с подтверждением на ваш адрес электронной почты <span class="email"></span>, и, пожалуйста, завершите проверку. После проверки преимущества будут синхронизированы автоматически.`,
        wrong_email:`Неверный адрес электронной почты?`,
        click_here_to_modify:`Нажмите здесь, чтобы изменить`,
        get_more_help:`Получить дополнительную помощь?`,
        click_here:`кликните сюда`,
        email_verification_info_success:`Поздравляем с подтверждением вашей учетной записи электронной почты.`,
        email_verification_info_error:`Проверка не удалась из-за просроченной ссылки.`,
        registration_succeeded:`Регистрация прошла успешно`,
        registration_succeeded_info_1:`Поздравляем! Вы успешно зарегистрировались. Мы отправили сообщение с подтверждением на ваш адрес электронной почты <span class="email"></span>. Пожалуйста, завершите проверку, чтобы воспользоваться преимуществами этого сообщения.`,
        registration_succeeded_info_2:`Нажмите «Готово», чтобы вернуться на домашнюю страницу и использовать этот продукт.`,
        registration_succeeded_info_3:`Нажмите «Готово», чтобы закрыть текущую страницу и вернуться на домашнюю страницу для следующих операций. Если закрыть не удается, закройте вкладку вручную.`,
        verify_email:`подтвердить электронную почту`,
        registered_email_not_verified:`Зарегистрированный адрес электронной почты не был подтвержден, пожалуйста, подтвердите его немедленно.`,
        email_verification_time_1:`Не получили письмо с подтверждением?`,
        email_verification_time_2:`После`,
        email_verification_time_3:`Нажмите здесь, чтобы отправить его повторно`,
        error_26301:`Код ошибки: 26301, свяжитесь с нами`,
        error_26302:`Код ошибки: 26302, свяжитесь с нами`,
        error_26303:`Ошибка формата электронной почты (код ошибки: 26303). Пожалуйста, введите его снова`,
        error_26304:`Рекомендуется пароль длиной более 8 символов (код ошибки: 26304)`,
        error_26305:`Ошибка повторного запроса (код ошибки: 26305). Пожалуйста, повторите попытку`,
        error_26306:`Электронная почта зарегистрирована, пожалуйста, <a href="https://account.aiseesoft.com/login">перейдите, чтобы войти</a>`,
        error_26307:`Ошибка повторного запроса (код ошибки: 26307). Пожалуйста, повторите попытку`,
        error_26308:`Ошибка повторного запроса (код ошибки: 26308). Пожалуйста, повторите попытку`,
        error_26401:`Код ошибки: 26401, повторите попытку.`,
        error_26402:`Электронная почта подтверждена (код ошибки: 26402), повторите попытку.`,
        error_26403:`Ошибка повторного запроса (код ошибки: 26403). Пожалуйста, повторите попытку`,
        error_26404:`Запрос не выполнен (код ошибки: 26404). Пожалуйста, повторите попытку`,
        error_26501:`Код ошибки: 26501, свяжитесь с нами`,
        error_26502:`Код ошибки: 26502, свяжитесь с нами`,
        error_26503:`Ошибка формата электронной почты (код ошибки: 26503). Пожалуйста, введите его снова`,
        error_26504:`Ошибка повторного запроса (код ошибки: 26504). Пожалуйста, повторите попытку`,
        error_26505:`Электронная почта не зарегистрирована, <a href="https://account.aiseesoft.com/register">сначала зарегистрируйте ее</a>`,
        error_26506:`Электронная почта подтверждена.`,
        error_26507:`Ошибка повторного запроса (код ошибки: 26507). Пожалуйста, повторите попытку`,
        error_26508:`Пройдено подтверждение (код ошибки: 26508). Повторите попытку.`,
        error_26509:`Запрос не выполнен (код ошибки: 26509), повторите попытку.`,
        error_26510:`Код ошибки: 26510, свяжитесь с нами`,
        error_26601:`Код ошибки: 26601, свяжитесь с нами`,
        error_26602:`Код ошибки: 26602, свяжитесь с нами`,
        error_26603:`Ошибка повторного запроса (код ошибки: 26603). Пожалуйста, повторите попытку`,
        error_26604:`Код ошибки: 26604, свяжитесь с нами`,
        error_26605:`Код ошибки: 26605, свяжитесь с нами`,
        error_26701:`Код ошибки: 26701, свяжитесь с нами`,
        error_26702:`Ошибка повторного запроса (код ошибки: 26702). Пожалуйста, повторите попытку`,
        error_26703:`Код ошибки: 26703, свяжитесь с нами`,
        error_26704:`Код ошибки: 26704, свяжитесь с нами`,
        error_26705:`Дождитесь входа в систему (код ошибки: 26705). Пожалуйста, повторите попытку`,
        no_cookie:`Вы включили функцию «Блокировать все файлы cookie» в своем браузере, поэтому не можете войти в систему. Перейдите в «Настройки» и установите флажок «Разрешить все файлы cookie».`,
        error_26801:`Код ошибки: 26801, свяжитесь с нами`,
        error_26802:`Код ошибки: 26802, свяжитесь с нами`,
        error_26803:`Запрос не выполнен (код ошибки: 26803). Пожалуйста, повторите попытку`,
        error_26804:`Запрос не выполнен (код ошибки: 26804). Пожалуйста, повторите попытку`,
        error_order:`Запрос не выполнен (код ошибки: 27098), попробуйте еще раз!`,
        error_order1:`Запрос заказа неполный (код ошибки:`,
        error_order2:`）, обновите и повторите попытку.`,
        modify_email_title:`Изменить адрес электронной почты`,
        modify_email_info:`Вы можете использовать измененный адрес электронной почты для входа в свою учетную запись.`,
        images_per:`Изображения за`,
        error_26101:`Ошибка: 26101. Пожалуйста, свяжитесь с нами`,
        error_26102:`Ошибка: 26102. Пожалуйста, свяжитесь с нами`,
        error_26103:`Запрос не выполнен (код ошибки: 26103). Пожалуйста, попробуйте еще раз`,
        error_26104:`Код ошибки: 26104, повторите попытку.`,
        error_26105:`Код ошибки: 26105, повторите попытку.`,
        error_26106:`Не удалось удалить (код ошибки: 26106). Пожалуйста, попробуйте еще раз`,
        error_26201:`Ошибка: 26201. Пожалуйста, свяжитесь с нами`,
        error_26202:`Запрос не выполнен (код ошибки: 26202). Пожалуйста, попробуйте еще раз`,
        error_26001:`Ошибка: 26001. Пожалуйста, свяжитесь с нами`,
        error_26002:`Ошибка: 26002. Пожалуйста, свяжитесь с нами`,
        error_26003:`Ошибка: 26003. Пожалуйста, свяжитесь с нами`,
        error_26004:`Ошибка: 26004. Пожалуйста, свяжитесь с нами`,
        error_26005:`Запрос не выполнен (код ошибки: 26005). Пожалуйста, попробуйте еще раз`,
        error_26006:`Код ошибки: 26006, повторите попытку.`,
        error_26008:`Ошибка: 26008. Пожалуйста, свяжитесь с нами`,
        go_to_the_home_page:`Перейти на главную страницу`,
        error_27101:`Запрос не выполнен (код ошибки: 27101). Пожалуйста, повторите попытку`,
        error_27201:`Код ошибки: 27201, свяжитесь с нами`,
        error_27202:`Код ошибки: 27202, повторите попытку.`,
        error_27203:`Запрос не выполнен (код ошибки: 27203). Пожалуйста, повторите попытку`,
        error_27204:`Неверный код (код ошибки: 27204).`,
        error_27205:`Запрос не выполнен (код ошибки: 27205). Пожалуйста, повторите попытку`,
        error_27206:`Запрос не выполнен (код ошибки: 27206). Пожалуйста, повторите попытку`,
        error_27207:`Запрос не выполнен (код ошибки: 27207). Пожалуйста, повторите попытку`,
        no_history_found:`Вы не использовали ни один инструмент! <a href="/"> Обновите </a> или <a href="https://ru.aiseesoft.com/"> перейдите на официальный сайт </a>`,
        error_25301:`Ошибка: 25301. Пожалуйста, свяжитесь с нами`,
        error_25302:`Ошибка: 25302. Пожалуйста, свяжитесь с нами`,
        error_25303:`Запрос не выполнен (код ошибки: 25303). Пожалуйста, попробуйте еще раз`,
        error_25304:`Запрос не выполнен (код ошибки: 25304). Пожалуйста, попробуйте еще раз`,
        error_25305:`Запрос не выполнен (код ошибки: 25305). Пожалуйста, попробуйте еще раз`,
        error_25306:`Запрос не выполнен (код ошибки: 25306). Пожалуйста, попробуйте еще раз`,
        image_upscaler_p:`Преимущества загрузки изображения без водяного знака:`,
        Available_for:`Доступны для:`,
        credit_per:`%s кредитов за изображение HD`,
        still_valid:`Приобретенные планы все еще действительны.`,
        credit:`кредит(ы)`,
        pc_3rd_info:`Войдите успешно. Пожалуйста, перейдите в приложение для дальнейшей работы.`,
        use_online:`Воспользуйтесь онлайн-сервисом`,
        use_download:`Используйте настольную программу`,
        use_immediately:`Использовать немедленно`,
        Use_in_browser:`Использовать в браузере`,
        win_desktop:`Окна`,
        Mac_desktop:`Мак`,
    },
    sv:{
        overtime_1:`Felkod:`,
        overtime_2:`, försök igen`,
        isnetwork:`Internetfel. Kontrollera och försök igen`,
        email_placeholder:`E-post`,
        email_empty:`Ange e -post`,
        email_not_valid:`E-post är inte giltig`,
        email_not_valid_1:`Ange din e -post`,
        email_not_valid_2:`E -postadressen är ogiltig, använd en annan adress.`,
        email_not_valid_3:`Ingen e -postinmatning`,
        password_placeholder:`Lösenord`,
        password_empty:`Ange lösenord`,
        password_not_valid:`Fel konto eller lösenord`,
        password_not_valid_1:`Lösenord över 8 tecken krävs`,
        password_not_valid_2:`Skapa ett lösenord`,
        password_placeholder_1:`Skapa ditt lösenord`,
        password_placeholder_2:`Bekräfta ditt lösenord`,
        password_placeholder_3:`Skapa nytt lösenord`,
        password_placeholder_4:`Bekräfta nytt lösenord`,
        password_placeholder_5:`Ange gammalt lösenord`,
        copy_password_empty:`Bekräfta lösenordet`,
        copy_password_not_valid:`Bekräfta ditt lösenord`,
        copy_passwords_inconsistent:`Din lösenordsbekräftelse matchar inte`,
        code_empty:`Ange verifieringskod`,
        code_not_valid:`Ogiltig verifieringskod`,
        code_placeholder:`Verifierings kod`,
        not_received_code:`Om din brevlåda inte har fått verifieringskoden på länge, skicka verifieringskoden igen.`,
        get_first_code:`Hämta verifieringskoden först.`,
        connect_your_email_placeholder:`Anslut din e -post`,
        last_name_placeholder:`Ange ditt efternamn`,
        first_name_placeholder:`Ange ditt förnamn`,
        address_placeholder:`Ange din adress`,
        no_code_text:`Vi har skickat en verifieringskod. Ange din kod. <span class='tips'>Fick du ingen kod?`,
        no_code_text_1:`1.Vänligen se till att e -postadressen är giltig och att den kan ta emot e -post.`,
        no_code_text_2:`2. För att e -postmeddelandet skickas av systemet automatiskt kan det flaggas som skräppost eller skräppost. Kontrollera om e -postmeddelandet finns i papperskorgen.`,
        no_code_text_3:`3.Kan inte lösa ditt problem?`,
        no_code_text_3_span:`Klicka sedan här för att kontakta oss.`,
        order_no:`Du har inte köpt några produkter. Har du frågor, vänligen <a href="https://sv.aiseesoft.com/store/">Handla nu</a> Eller <a href="https://sv.aiseesoft.com/contact.html"> Kontakta oss</a>`,
        error_24901:`Nuvarande konto har ingen länkad e -postadress och kan inte hitta beställningarna. Anslut ett mejl.`,
        user_guide:`Användarhandbok >>`,
        download:`Ladda ner`,
        order_number:`Ordernummer`,
        Refund:`Återbetalning`,
        Disabled:`Inaktiverad`,
        Normal:`Vanligt`,
        Modify:`Ändra`,
        Modify_1:`Ändra >>`,
        Connect:`Ansluta`,
        unlink_success:`Koppla bort länken`,
        connect_success:`Anslut framgångsrikt`,
        feedback_title:`Tack för din feedback. Lämna ditt problem så svarar vi dig inom 24 timmar.`,
        feedback_thank_you:`Tack! <br /> Din feedback har skickats in.`,
        feedback_email:`Ange din e -postadress här!`,
        feedback_content:`Lämna alla problem eller förslag du stötte på här.`,
        feedback_submit:`Skicka in`,
        form_contents:`Du har inte angett någon beskrivning. Ange det och skicka igen.`,
        old_password:`Ange det gamla lösenordet`,
        new_password:`Skapa ett nytt lösenord`,
        old_new_password:`Det nya lösenordet kan inte vara detsamma som det gamla`,
        incorrect_password:`fel lösenord`,
        delete_no:`Radera nu`,
        Caps:`Caps Lock är på`,
        Get:`Skaffa sig`,
        Done:`Gjort`,
        error_20001:`Fel: 20001. Logga in igen.`,
        error_20002:`Fel: 20002. Logga in igen.`,
        error_20003:`Fel: 20003. Logga in igen.`,
        error_20004:`Begäran misslyckades (felkod: 20004). Försök igen.`,
        error_20005:`Inloggningssessionen har gått ut (fel: 20005). Logga in igen.`,
        error_20006:`Begäran misslyckades (felkod: 20006). Försök igen.`,
        error_20007:`Inloggningssessionen har gått ut (fel: 20007). Logga in igen.`,
        error_20008:`Inloggningssessionen har gått ut (fel: 20008). Logga in igen.`,
        error_20009:`Inloggningssessionen har gått ut (fel: 20009). Logga in igen.`,
        error_20101:`Ange din e -post (felkod: 20101)`,
        error_20102:`E -postadressen är ogiltig (felkod: 20102)`,
        error_20103:`Begäran misslyckades (felkod: 20103). Försök igen`,
        error_20104:`E -posten används redan. <a href="https://account.aiseesoft.com/login"> logga in </a> eller registrera dig med en ny`,
        error_20105:`Begäran misslyckades (felkod: 20105). Försök igen`,
        error_20106:`Det gick inte att skicka e -post, försök igen`,
        error_20201:`Ange din e -post (felkod: 20201)`,
        error_20202:`Ange ditt lösenord (felkod: 20202)`,
        error_20203:`Ange verifieringskod (felkod: 20203)`,
        error_20204:`E -postadressen är ogiltig (felkod: 20204)`,
        error_20205:`Lösenord över 8 tecken krävs (felkod: 20205)`,
        error_20206:`Begäran misslyckades (felkod: 20206). Försök igen`,
        error_20207:`Ogiltig verifieringskod`,
        error_20208:`Begäran misslyckades (felkod: 20208). Försök igen`,
        error_20209:`Begäran misslyckades (felkod: 20209). Försök igen`,
        error_20301:`Ange din e -post (felkod: 20301)`,
        error_20302:`Fel: 20302. Vänligen kontakta oss`,
        error_20303:`E -postadressen är ogiltig (felkod: 20303)`,
        error_20304:`Begäran misslyckades (felkod: 20304). Försök igen`,
        error_20305:`Kontot finns inte. Ange igen eller <a href="https://account.aiseesoft.com/register">Skapa det</a> först.`,
        error_20306:`Inget lösenord ännu. Använd <a href="https://account.aiseesoft.com/passwordless-login"> inloggning utan lösenord </a> eller <a href="create-password"> ange ett lösenord </a> och logga in.`,
        error_20308:`Begäran misslyckades (felkod: 20308). Försök igen`,
        error_20401:`Det gick inte att logga ut (felkod: 20401). Försök igen`,
        error_20501:`Ange din e -post (felkod: 20501)`,
        error_20502:`E -postadressen är ogiltig (felkod: 20502)`,
        error_20503:`Begäran misslyckades (felkod: 20503). Försök igen`,
        error_20504:`Det gick inte att skicka e -post. Försök igen.`,
        error_20601:`Ange din e -post (felkod: 20601)`,
        error_20602:`Ange verifieringskod (felkod: 20602)`,
        error_20603:`E -postadressen är ogiltig (felkod: 20603)`,
        error_20604:`Begäran misslyckades (felkod: 20604). Försök igen`,
        error_20606:`Begäran misslyckades (felkod: 20606). Försök igen`,
        error_20607:`Begäran misslyckades (felkod: 20607). Försök igen`,
        error_20608:`Begäran misslyckades (felkod: 20608). Försök igen`,
        error_20701:`Ange din e -post (felkod: 20701)`,
        error_20702:`E -postadressen är ogiltig (felkod: 20702)`,
        error_20703:`Begäran misslyckades (felkod: 20703). Försök igen`,
        error_20704:`Kontot finns inte. Ange igen eller <a href="https://account.aiseesoft.com/register">Skapa det</a> först.`,
        error_20705:`Begäran misslyckades (felkod: 20705). Försök igen`,
        error_20706:`Det gick inte att skicka e -post. Försök igen`,
        error_20801:`Ange din e -post (felkod: 20801)`,
        error_20802:`Fel: 20802. Vänligen kontakta oss`,
        error_20803:`Ange verifieringskod (felkod: 20803)`,
        error_20804:`E -postadressen är ogiltig (felkod: 20804)`,
        error_20805:`Lösenord över 8 tecken krävs (felkod: 20805)`,
        error_20806:`Begäran misslyckades (felkod: 20806). Försök igen`,
        error_20808:`Begäran misslyckades (felkod: 20808). Försök igen`,
        error_20901:`Begäran misslyckades (felkod: 20901). Försök igen`,
        error_20902:`Begäran misslyckades (felkod: 20902). Försök igen`,
        error_21000:`Ändringar sparas`,
        error_21001:`Ingen information lämnas (felkod: 21001)`,
        error_21002:`Begäran misslyckades (felkod: 21002). Försök igen`,
        error_21101:`Ange din e -post (felkod: 21101)`,
        error_21102:`E -postadressen är ogiltig (felkod: 21102)`,
        error_21103:`Begäran misslyckades (felkod: 21103), försök igen`,
        error_21104:`E -post är redan ansluten, använd en ny`,
        error_21105:`Begäran misslyckades (felkod: 21105), försök igen`,
        error_21106:`Det gick inte att skicka e -post. Försök igen`,
        error_21201:`Ange din e -post (felkod: 21201)`,
        error_21202:`Ange verifieringskod (felkod: 21202)`,
        error_21203:`E -postadressen är ogiltig (felkod: 21203)`,
        error_21204:`Fel: 21204. Vänligen kontakta oss`,
        error_21205:`Fel: 21205. Vänligen kontakta oss`,
        error_21206:`Lösenord över 8 tecken krävs (felkod: 21206)`,
        error_21207:`Begäran misslyckades (felkod: 21207). Försök igen`,
        error_21209:`Begäran misslyckades (felkod: 21209). Försök igen`,
        error_21301:`Ange gammalt lösenord (felkod: 21301)`,
        error_21302:`Skapa ett nytt lösenord (felkod: 21302)`,
        error_21303:`Det nya lösenordet kan inte vara detsamma som det gamla. (Fel: 21303)`,
        error_21304:`Lösenord över 8 tecken krävs (felkod: 21304)`,
        error_21306:`Begäran misslyckades (felkod: 21306). Försök igen`,
        error_21402:`Begäran misslyckades (felkod: 21402). Försök igen`,
        error_21403:`Det gick inte att skicka verifieringskoden. Skicka den igen`,
        error_21500:`Kontot har raderats`,
        error_21501:`Ange verifieringskod (felkod: 21501)`,
        error_21502:`Inloggningssessionen har gått ut (fel: 21502). Logga in igen.`,
        error_21503:`Begäran misslyckades (felkod: 21503). Försök igen`,
        error_21505:`Begäran misslyckades (felkod: 21505), försök igen`,
        error_21601:`Fel: 20601. Vänligen kontakta oss`,
        error_21602:`Ogiltig verifiering (fel: 20602). Försök igen.`,
        error_21603:`Fel: 20603. Försök igen`,
        error_21604:`Begäran misslyckades (felkod: 21604). Försök igen`,
        error_21606:`Begäran misslyckades (felkod: 21606). Försök igen`,
        error_21611:`Begäran misslyckades (felkod: 21611). Försök igen`,
        error_21801:`Fel: 21801. Vänligen kontakta oss`,
        error_21802:`Begäran misslyckades (fel: 21802). Försök igen`,
        error_21803:`Fel: 21803. Försök igen`,
        error_21804:`Begäran misslyckades (felkod: 21804). Försök igen`,
        error_21806:`Fel: 21806. Försök igen`,
        error_21807:`Fel: 21807. Vänligen kontakta oss`,
        error_21808:`Fel: 21808. Kontakta oss`,
        error_21809:`Fel: 21809. Vänligen kontakta oss`,
        error_21810:`Fel: 21810. Vänligen kontakta oss`,
        error_21811:`Fel: 21811. Vänligen kontakta oss`,
        error_21812:`Fel: 21812. Vänligen kontakta oss`,
        error_21813:`Begäran misslyckades (felkod: 21813). Försök igen`,
        error_21814:`Fel: 21814. Vänligen kontakta oss`,
        error_21815:`Begäran misslyckades (felkod: 21815). Försök igen`,
        error_21816:`Fel: 21816. Vänligen kontakta oss`,
        error_21817:`Fel: 21817. Vänligen kontakta oss`,
        error_21818:`Fel: 21818. Kontakta oss`,
        error_21819:`Begäran misslyckades (felkod: 21819). Försök igen`,
        error_21820:`Fel: 21820. Kontakta oss`,
        error_21821:`Fel: 21821. Vänligen kontakta oss`,
        error_21822:`Fel: 21822. Kontakta oss`,
        error_21823:`Begäran misslyckades (felkod: 21823). Försök igen`,
        error_21824:`Begäran misslyckades (felkod: 21824). Försök igen`,
        error_21825:`Begäran misslyckades (felkod: 21825). Försök igen`,
        error_21826:`Begäran misslyckades (felkod: 21826). Försök igen`,
        error_21828:`Fel: 21828. Kontakta oss`,
        error_21901:`Fel: 21901. Vänligen kontakta oss`,
        error_21902:`Begäran misslyckades (felkod: 21902). Försök igen`,
        error_21903:`Kontostatusen har ändrats (felkod: 21903). Uppdatera sidan och försök igen`,
        error_21904:`Fel: 21904. Försök igen`,
        error_21905:`Fel: 21905. Försök igen`,
        error_21906:`Begäran misslyckades (felkod: 21906). Försök igen`,
        error_21907:`Google -kontot har länkats till ett annat konto`,
        error_21908:`Begäran misslyckades (felkod: 21908). Försök igen`,
        error_22001:`Begäran misslyckades (felkod: 22001). Försök igen`,
        error_22002:`Avlänkning misslyckades utan ytterligare inloggning`,
        error_22003:`Begäran misslyckades (felkod: 22003). Försök igen`,
        error_22101:`Fel: 22101. Vänligen kontakta oss`,
        error_22102:`Kontostatusen har ändrats (felkod: 22102). Uppdatera sidan och försök igen`,
        error_22103:`Begäran misslyckades (felkod: 22103). Försök igen`,
        error_22104:`Kontostatusen har ändrats (felkod: 22104). Uppdatera sidan och försök igen`,
        error_22105:`Fel: 22105. Försök igen`,
        error_22106:`Fel: 22106. Försök igen`,
        error_22107:`Fel: 22107. Vänligen kontakta oss`,
        error_22108:`Begäran misslyckades (felkod: 22108). Försök igen`,
        error_22201:`Fel: 22201. Vänligen kontakta oss`,
        error_22202:`Ogiltig verifiering (fel: 22202). Försök igen.`,
        error_22203:`Fel: 22203. Försök igen "`,
        error_22204:`Begäran misslyckades (felkod: 22204). Försök igen`,
        error_22206:`Begäran misslyckades (felkod: 22206). Försök igen`,
        error_22401:`Fel: 22401. Vänligen kontakta oss`,
        error_22402:`Ogiltig verifiering (fel: 22402). Försök igen.`,
        error_22403:`Fel: 22403. Försök igen`,
        error_22404:`Begäran misslyckades (felkod: 22404). Försök igen`,
        error_22405:`Facebook -kontot har länkats till annan e -post`,
        error_22406:`Fel: 22406. Försök igen`,
        error_22407:`Fel: 22407. Kontakta oss`,
        error_22408:`Fel: 22408. Kontakta oss`,
        error_22409:`Fel: 22409. Kontakta oss`,
        error_22410:`Fel: 224010. Vänligen kontakta oss`,
        error_22411:`Fel: 224011. Kontakta oss`,
        error_22412:`Fel: 224012. Vänligen kontakta oss`,
        error_22413:`Fel: 22413. Vänligen kontakta oss`,
        error_22414:`Begäran misslyckades (felkod: 22414). Försök igen`,
        error_22415:`Fel: 22415. Kontakta oss`,
        error_22416:`Fel: 22416. Vänligen kontakta oss`,
        error_22417:`Fel: 22417. Kontakta oss`,
        error_22418:`Begäran misslyckades (felkod: 22418). Försök igen`,
        error_22419:`Fel: 22419. Kontakta oss`,
        error_22420:`Fel: 22420. Vänligen kontakta oss`,
        error_22421:`Fel: 22421. Vänligen kontakta oss`,
        error_22422:`Begäran misslyckades (felkod: 22422). Försök igen`,
        error_22423:`Begäran misslyckades (felkod: 22423). Försök igen`,
        error_22424:`Begäran misslyckades (felkod: 22424). Försök igen`,
        error_22425:`Begäran misslyckades (felkod: 22425). Försök igen`,
        error_20098:`Felkod: 20098. Om du surfar i privat läge, växla till normalt läge och försök igen.`,
        error_22298:`Begäran om Google misslyckades (fel: 22298). Försök igen.`,
        error_22498:`Begäran om Facebook misslyckades (fel: 22498). Försök igen.`,
        error_24902:`Begäran misslyckades (felkod: 24902). Försök igen`,
        error_24903:`Begäran misslyckades (felkod: 24903). Försök igen`,
        error_24904:`Begäran misslyckades (felkod: 24904). Försök igen`,
        error_24905:`Begäran misslyckades (felkod: 24905). Försök igen`,
        login_title:`Logga in på Aiseesoft`,
        password_placeholder:`Lösenord`,
        log_in:`Logga in`,
        no_account:`Inget konto?`,
        create_it:`Skapa det`,
        or_log_in_with:`Eller logga in med`,
        passwordless_login:`Lösenordslös inloggning`,
        log_in_done:`Logga in Klar`,
        three_rd_account_connect_info:`Grattis! Du har loggat in. Nu kan du ansluta ett e -postkonto som används för att logga in i framtiden.`,
        connect_now:`Anslut nu`,
        see_my_account:`Se mitt konto`,
        three_rd_login_merge_account:`Tredjepartskontots e-postadress har registrerats, vill du ansluta och logga in med den här e-postadressen direkt?`,
        connect_log_in:`Anslut och logga in`,
        create_an_account:`Skapa ett konto`,
        connect_account:`Anslut konto`,
        connect_account_info:`Efter anslutning kan du logga in med det här kontot.`,
        back_to_log_in:`Tillbaka till login`,
        create_password:`Skapa lösenord`,
        create_now:`Skapa nu`,
        password_login_subtitle:`Lösenordslös inloggning med e -post`,
        account_login:`Kontoinloggning`,
        rights:`Genom att skapa detta konto godkänner du <a href="https://sv.aiseesoft.com/company/terms-and-conditions.html"> användarvillkor </a> och <a href="https://sv.aiseesoft.com/privacy-policy.html"> Sekretesspolicy </a>`,
        passwordless_login_done:`Lösenordslös inloggning klar`,
        passwordless_login_info:`Grattis, du har slutfört inloggning utan lösenord. Du kan skapa ett lösenord för det här kontot och logga in med kontot och lösenordet i framtiden. <a href="/create-password" style="display: initial;"> Skapa nu </a>`,
        sign_up:`Bli Medlem`,
        register_info:`Skapa ditt konto`,
        reset_now:`Återställ nu`,
        forgot_password:`Glömt ditt lösenord`,
        reset_password_subtitle:`Använd ditt e -postkonto för att återställa lösenordet`,
        plan_products:`Plan & Produkter`,
        nick_name:`Användarnamn`,
        email:`E-post`,
        my_products:`Mina produkter`,
        my_orders:`Mina order`,
        unlink:`Ta bort länken`,
        link:`Länk`,
        connected_accounts:`Anslutna konton`,
        last_name:`Efternamn`,
        first_name:`Förnamn`,
        Gender:`Kön`,
        Birth:`Födelse`,
        Month:`Månad`,
        Year:`År`,
        Country_Region:`Land/region`,
        Address:`Adress`,
        Save:`Spara`,
        Date:`Datum`,
        Male:`Manlig`,
        Female:`Kvinna`,
        Unspecified:`Ospecificerad`,
        Security:`säkerhet`,
        change_password:`ändra lösenord`,
        change_now:`Ändra nu`,
        connect_email:`Anslut e -post`,
        delete_account:`Radera konto`,
        delete_account_info:`När ditt konto raderas kommer all data i Plan & Produkter som är kopplade till ditt konto att raderas permanent, och du kanske inte kan återställa den. Vi rekommenderar att du går försiktigt.`,
        Delete:`Radera`,
        Logout:`Logga ut`,
        my_profile:`Min profil`,
        guides_faqs:`Guider och vanliga frågor`,
        More:`Mer`,
        guides:`Guider`,
        register:`Registrera`,
        hot_faq:`Hot FAQ`,
        Contents:`Innehåll:`,
        contact_us:`Kontakta oss >>`,
        plan:`Planen`,
        unregistered:`Oregistrerad`,
        buy_more:`Köp mer`,
        buy_again:`Köp igen`,
        buy_now:`Köp nu`,
        free_no_limit:`Gratis & ingen gräns`,
        expired:`Utgånget`,
        lifetime:`Livstid`,
        remain:`Förbli`,
        day_s:`Dag(ar)`,
        error_24801:`Begäran misslyckades (felkod: 24801). Försök igen`,
        no_app_found:`Ingen app hittades!<a href="/">Uppdatera</a> eller <a href="https://sv.aiseesoft.com/">gå till den officiella webbplatsen</a>`,
        get_more:`Få mer >>`,
        edit_photo:`Redigera Foto`,
        select_photo:`Välj foto`,
        change_photo:`Förändring Foto`,
        cancel:`Avbryt`,
        hide_password:`Dölj lösenord`,
        show_password:`Visa lösenord`,
        zoom_in:`Zooma in`,
        zoom_out:`Zooma ut`,
        rotate:`Rotera`,
        horizontal_flip:`Horisontell vändning`,
        vertical_flip:`Vertikal flip`,
        country:`Land`,
        country_1:`Välj ditt land/din region`,
        country_2:`Åaland`,
        country_3:`Afghanistan`,
        country_4:`Albanien`,
        country_5:`Algeriet`,
        country_6:`Amerikanska Samoa`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguilla`,
        country_10:`Antarktis`,
        country_11:`Antigua och Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenien`,
        country_14:`Aruba`,
        country_15:`Australien`,
        country_16:`Österrike`,
        country_17:`Azerbajdzjan`,
        country_18:`Bahrain`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Vitryssland`,
        country_22:`Belgien`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Bhutan`,
        country_27:`Bolivia`,
        country_28:`Bosnien och Hercegovina`,
        country_29:`Botswana`,
        country_30:`Bouvetön`,
        country_31:`Brasilien`,
        country_32:`Brittiska territoriet i Indiska oceanen`,
        country_33:`Brittiska Jungfruöarna`,
        country_34:`Brunei`,
        country_35:`Bulgarien`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kambodja`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Cap Verde`,
        country_42:`Karibiska Nederländerna`,
        country_43:`Caymanöarna`,
        country_44:`Centralafrikanska republiken`,
        country_45:`Tchad`,
        country_46:`Chile`,
        country_47:`Kina`,
        country_48:`Julön`,
        country_49:`Cocos (Keeling) Islands`,
        country_50:`Colombia`,
        country_51:`Cooköarna`,
        country_52:`Costa Rica`,
        country_53:`"Elfenbenskusten"`,
        country_54:`Kroatien`,
        country_55:`Kuba`,
        country_56:`Cypern`,
        country_57:`Tjeckien`,
        country_58:`Demokratiska republiken Kongo`,
        country_59:`Danmark`,
        country_60:`Djibouti`,
        country_61:`Dominica`,
        country_62:`Dominikanska republiken`,
        country_63:`Ecuador`,
        country_64:`Egypten`,
        country_65:`El Salvador`,
        country_66:`Ekvatorialguinea`,
        country_67:`Eritrea`,
        country_68:`Estland`,
        country_69:`Etiopien`,
        country_70:`Falklandsöarna`,
        country_71:`Färöarna`,
        country_72:`Mikronesiens federerade stater`,
        country_73:`Fiji`,
        country_74:`Finland`,
        country_75:`Frankrike`,
        country_76:`Franska Guyana`,
        country_77:`franska polynesien`,
        country_78:`franska sydterritorierna`,
        country_79:`Gabon`,
        country_80:`Gambia`,
        country_81:`Georgien`,
        country_82:`Tyskland`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Storbritannien (Storbritannien; England)`,
        country_86:`Grekland`,
        country_87:`Grönland`,
        country_88:`Grenada`,
        country_89:`Guadeloupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Island och McDonald Islands`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Ungern`,
        country_101:`Island`,
        country_102:`Indien`,
        country_103:`Indonesien`,
        country_104:`Iran`,
        country_105:`Irak`,
        country_106:`Irland`,
        country_107:`ö av man`,
        country_108:`Israel`,
        country_109:`Italien`,
        country_110:`Jamaica`,
        country_111:`Japan`,
        country_112:`Jersey`,
        country_113:`Jordanien`,
        country_114:`Kazakstan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirgizistan`,
        country_119:`Laos`,
        country_120:`Lettland`,
        country_121:`Libanon`,
        country_122:`Lesotho`,
        country_123:`Liberia`,
        country_124:`Libyen`,
        country_125:`Liechtenstein`,
        country_126:`Litauen`,
        country_127:`Luxemburg`,
        country_128:`Macao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malaysia`,
        country_132:`Maldiverna`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marshallöarna`,
        country_136:`Martinique`,
        country_137:`Mauretanien`,
        country_138:`Mauritius`,
        country_139:`Mayotte`,
        country_140:`Mexiko`,
        country_141:`Moldavien`,
        country_142:`Monaco`,
        country_143:`mongoliet`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marocko`,
        country_147:`Moçambique`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Nederländerna`,
        country_153:`Nya Kaledonien`,
        country_154:`Nya Zeeland`,
        country_155:`Nicaragua`,
        country_156:`Niger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Norfolkön`,
        country_160:`Nordkorea`,
        country_161:`Nordmarianerna`,
        country_162:`Norge`,
        country_163:`oman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Palestinska territorier`,
        country_167:`Panama`,
        country_168:`Papua Nya Guinea`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairnöarna`,
        country_172:`Polen`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Qatar`,
        country_176:`Republiken Makedonien (FYROM)`,
        country_177:`Republiken Kongo`,
        country_178:`Återförening`,
        country_179:`Rumänien`,
        country_180:`Ryska Federationen`,
        country_181:`Rwanda`,
        country_182:`Saint Barthélemy`,
        country_183:`Saint Martin (Frankrike)`,
        country_184:`Saint-Pierre och Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Tomé och Principe`,
        country_188:`Saudiarabien`,
        country_189:`Senegal`,
        country_190:`Serbien`,
        country_191:`Seychellerna`,
        country_192:`Sierra Leone`,
        country_193:`Singapore`,
        country_194:`Slovakien`,
        country_195:`Slovenien`,
        country_196:`Salomonöarna`,
        country_197:`Somalia`,
        country_198:`Sydafrika`,
        country_199:`South Georgia och South Sandwich Island`,
        country_200:`Sydkorea`,
        country_201:`södra Sudan`,
        country_202:`Spanien`,
        country_203:`Sri Lanka`,
        country_204:`S:t Helena & Beroende`,
        country_205:`St. Kitts och Nevis`,
        country_206:`Sankta Lucia`,
        country_207:`St. Vincent och Grenadinerna`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Swaziland`,
        country_211:`Sverige`,
        country_212:`Schweiz`,
        country_213:`Syrien`,
        country_214:`Taiwan`,
        country_215:`Tadzjikistan`,
        country_216:`Tanzania`,
        country_217:`Mall:Landsdata SJM Svalbard`,
        country_218:`Thailand`,
        country_219:`Bahamas`,
        country_220:`Komorerna`,
        country_221:`Filippinerna`,
        country_222:`Östtimor (Östtimor)`,
        country_223:`Togo`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad och Tobago`,
        country_227:`Tunisien`,
        country_228:`Kalkon`,
        country_229:`Turkmenistan`,
        country_230:`Turks- och Caicosöarna`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukraina`,
        country_234:`Förenade arabemiraten`,
        country_235:`Förenta staternas mindre avlägsna öar`,
        country_236:`USA (USA)`,
        country_237:`USA Jungfruöarna`,
        country_238:`Uruguay`,
        country_239:`Uzbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikanstaten (den Heliga stolen)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis och Futuna`,
        country_245:`Västra Sahara`,
        country_246:`Jemen`,
        country_247:`Zambia`,
        country_248:`Zimbabwe`,
        google_login:`Logga in med Google`,
        footer:`Copyright © 2024 Aiseesoft Studio. Alla rättigheter förbehållna.`,
        State:`stat`,
        Activation_code:`Aktiveringskod`,
        Question:`Lista alla appar du har loggat in`,
        Copy_complete:`Kopian klar`,

        store:`Lagra`,
        resource:`Resurs`,
        support:`Stöd`,
        video_converter:`Video Converter`,
        multimedia:`Multimedia`,
        data_recovery:`Dataåterställning`,
        utility:`Verktyg`,
        free_tools:`Gratis verktyg`,
        for_windows:`För Windows`,
        for_mac:`För Mac`,
        pdf_solutions:`PDF-lösningar`,
        data_manager:`Datahanterare`,
        other_tools:`Andra verktyg`,
        free_online_solutions:`Gratis onlinelösningar`,
        free_online_tools:`Gratis onlineverktyg`,
        desktop_freeware:`Desktop gratisprogram`,
        product_1:`Video Converter Ultimate`,
        product_2:`Total Video Converter`,
        product_3:`MTS omvandlare`,
        product_4:`4K-konverterare`,
        product_5:`MXF-omvandlare`,
        product_6:`3D-omvandlare`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Video Converter för Mac`,
        product_9:`MTS Converter för Mac`,
        product_10:`4K-konverterare för Mac`,
        product_11:`MXF Converter för Mac`,
        product_12:`3D-konverterare för Mac`,
        product_13:`Blu-ray spelare`,
        product_14:`Video Enhancer`,
        product_15:`Videoredigerare`,
        product_16:`Bildspelsskapare`,
        product_17:`Blu-ray Creator`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD Creator`,
        product_20:`Mac Blu-ray-spelare`,
        product_21:`Mac Video Enhancer`,
        product_22:`Videoredigerare för Mac`,
        product_23:`DVD Creator för Mac`,
        product_24:`MP4-konverterare för Mac`,
        product_25:`Windows dataåterställning`,
        product_26:`FoneLab iPhone Data Recovery`,
        product_27:`FoneLab iOS-systemåterställning`,
        product_28:`FoneLab iOS Data Backup &amp; Återställ`,
        product_29:`FoneLab Android Data Recovery`,
        product_30:`Trasig Android-dataextraktion`,
        product_31:`Android Data Backup &amp; Återställ`,
        product_32:`Mac Data Recovery`,
        product_33:`Mac FoneLab iPhone Data Recovery`,
        product_34:`Mac FoneLab iOS-systemåterställning`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Återställ`,
        product_36:`Mac FoneLab Android Data Recovery`,
        product_37:`Mac Android Data Backup &amp; Återställ`,
        product_38:`PDF Converter Ultimate`,
        product_39:`PDF Converter Ultimate för Mac`,
        product_40:`PDF sammanslagning`,
        product_41:`Mac PDF till ePub Converter`,
        product_42:`Mac Cleaner`,
        product_43:`foneTrans`,
        product_44:`WhatsApp Transfer för iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`iPhone Cleaner`,
        product_48:`PPT till Video Converter`,
        product_49:`HEIC-omvandlare`,
        product_50:`iPhone Unlocker`,
        product_51:`Gratis videokonverterare online`,
        product_52:`Gratis Audio Converter online`,
        product_53:`Gratis videokompressor online`,
        product_54:`Gratis videofusion online`,
        product_55:`Gratis HEIC-omvandlare`,
        product_56:`Gratis bildkompressor`,
        product_57:`Gratis vattenstämpelborttagare`,
        product_58:`Gratis PDF-kompressor online`,
        product_59:`Gratis Image Upscaler Online`,
        product_60:`Gratis bakgrundsborttagning online`,
        product_61:`Gratis bildkonverterare online`,
        product_62:`Gratis PDF PNG Converter online`,
        product_63:`Gratis videokonverterare`,
        product_64:`Gratis Video till GIF Converter`,
        product_65:`Gratis Android Data Recovery`,
        product_66:`Slideshow Maker`,
        product_67:`Gratis videoredigerare`,
        product_68:`Gratis PDF Viewer`,
        product_69:`Skärminspelare`,
        product_70:`Mac-skärminspelare`,
        product_71:`Gratis skärminspelare online`,
        product_72:`Gratis ljudinspelare online`,
        product_73:`Telefonspegel`,
        product_74:`AnyCoord - Location Changer`,
        product_75:`iPhone Password Manager`,
        product_76:`Gratis ljudkompressor online`,
        product_77:`Gratis videotrimmer online`,
        product_78:`Gratis Video Cropper Online`,
        product_79:`Gratis videorotator online`,
        product_80:`Gratis Video Looper Online`,
        product_81:`Gratis PDF JPG Converter online`,
        product_81:`Gratis JPG Word Converter online`,
        category_1:`Fotoredigering`,
        category_2:`Videoredigering`,
        category_3:`Suddgummi`,
        category_4:`Media Recorder`,
        posted_by:`Postat av`,
        to:`till`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Hur man tar bort text från bilder effektivt`,
        article_2:`Hur man ökar bildstorleken i KB till MB utan att skada kvaliteten`,
        article_3:`Hur man tar bort Getty Images Watermark på Windows och Mac`,
        article_4:`Den bästa metoden för att konvertera lågupplöst bild till högupplöst online gratis`,
        article_5:`TikTok-guide | Så här gör du ett videokollage på TikTok`,
        article_6:`5 enkla sätt att kombinera VOB-filer du bör känna till`,
        article_7:`Hur man uppskalar video och enkelt får högre upplösning`,
        article_8:`Hur man kombinerar MP4-filer på MacBook Pro/iMac/MacBook och mer`,
        article_9:`2 enkla sätt att radera köphistorik på iPhone [Löst]`,
        article_10:`Hur man tar bort Snapchat-bilder permanent från chatt och minnen`,
        article_11:`Hur man tar bort Spotlight-sökförslag och historik på iPhone/iPad`,
        article_12:`[Löst] Hur man rensar Instagram-cache på iPhone med 3 enkla sätt`,
        article_13:`[Löst] Hur man fixar QuickTime Player som inte fungerar på Mac`,
        article_14:`Hur man spelar in systemljud på Mac (Big Sur/Catalina/Mojave …)`,
        article_15:`Effektiva sätt att åtgärda PowerPoint-skärminspelning som inte fungerar`,
        article_16:`Hur man spelar in ljud med VLC [Löst]`,
        faq_title_1:`1. Hur fixar jag 4104-felet?`,
        faq_title_2:`2. När kan jag få tillbaka mina pengar efter att begäran om återbetalning har godkänts?`,
        faq_title_3:`3. Vad är "1 års prenumeration" och hur stänger man av "Automatisk förnyelsetjänst"?`,
        faq_title_4:`4. Kan jag avbryta min beställning efter att ha placerat en Aiseesoft-produkt?`,
        faq_title_5:`5. Hur skickar jag en recension till dig?`,
        faq_info_1:`Detta beror på den begränsade licensen du har. Vi använder Single License om registreringskoden blir stulen och förhindrar kränkning av registreringskoden. Så Reg Code du fick är Single License som bara kan användas på en dator. Om du använder det på mer än en dator kommer det att uppstå fel 4104. Du kan inte använda det förrän vi har återställt det åt dig.`,
        faq_info_2:`Om du ändrar eller installerar om din dator kan vi återställa den åt dig. Vänligen kontakta kundtjänst: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> för att begära återställning av registreringskoden åt dig.`,
        faq_info_3:`När din begäran om återbetalning har godkänts tar det normalt 3~7 arbetsdagar innan krediten visas på ditt konto. Ibland under semester kan det ta längre tid men det förväntas inte ta över 10 dagar. Om du inte har fått återbetalningen 20 dagar efter godkännandet, kontakta oss på <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> för att kontrollera återbetalningen.`,
        faq_info_4:`Automatisk förnyelsetjänst innebär att om du köper en 1-årsprenumeration kommer betalningsplattformen att debitera beloppet automatiskt (inklusive tillämpliga skatter) när produktlicensen löper ut. Men betalningsformuläret (Swreg eller 2Checkout) kommer att skicka ett brev till dig för att påminna dig om att beställningen kommer att förfalla 7 dagar innan utgången. E-postmeddelandet kommer också att innehålla instruktioner om hur du stänger av "Automatisk förnyelsetjänst".`,
        faq_info_5:`Eftersom beställningen behandlas automatiskt är det inte tillåtet att annullera beställningen. Dessutom kommer den kostnadsfria testversionen att göra det möjligt för dig att veta mer om Aiseesoft-programmet och kontrollera om det kan uppfylla dina behov innan du köper det.`,
        faq_info_6:`Om du verkligen vill få en återbetalning, kontrollera vår <a href="https://sv.aiseesoft.com/refund-policy.html">återbetalningspolicy</a>.`,
        faq_info_7:`Vi uppskattar din tid och uppmärksamhet för att skicka en recension till oss. Kontakta oss (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) för att skicka in din recension så kommer vi att seriöst överväga din recension för våra produktuppdateringar.`,
        change_password_success:`lösenordet har ändrats`,
        successful_login_title:`Inloggning lyckad`,
        product_page:`Produktsida >>`,
        successful_login_info:`Inloggningen är klar. Stäng den aktuella sidan och gå tillbaka till den ursprungliga fliken för att fortsätta med följande process. Den aktuella sidan stängs automatiskt om 5 sekunder. Om automatisk stängning eller stängning genom att klicka på knappen "Klar" misslyckas, stäng den här fliken direkt.`,
        successful_login_info_firefox:`Inloggningen är klar. Stäng den aktuella sidan och gå tillbaka till den ursprungliga fliken för att fortsätta med följande process.`,
        my_account:`Mitt konto`,
        my_history:`Min historia`,
        remove_watermark:`Ta bort vattenstämpel`,
        no_history:`Ingen historia`,
        history_all:`Välj alla`,
        history_open:`Öppen`,
        history_down:`Ladda ner`,
        history_delete:`Radera`,
        history_clear:`Rensa ogiltig`,
        images:`bilder)`,
        use_this_function:`Använd den här funktionen >>`,
        hd_downloading:`Fördelar med att ladda ner HD-originalbilder:`,
        lifetimeRemaining:`Livstid kvar`,
        Remaining:`Återstående`,
        email_verification:`mejl verifikation`,
        email_verification_info:`Vi har skickat verifieringsmeddelandet till din e-post <span class="email"></span> och avsluta verifieringen. Efter verifiering synkroniseras förmånerna automatiskt.`,
        wrong_email:`Fel e-postadress?`,
        click_here_to_modify:`Klicka här för att ändra`,
        get_more_help:`Få mer hjälp?`,
        click_here:`Klicka här`,
        email_verification_info_success:`Grattis för att du har verifierat ditt e-postkonto.`,
        email_verification_info_error:`Verifieringen misslyckades på grund av den utgångna länken.`,
        registration_succeeded:`Registreringen lyckades`,
        registration_succeeded_info_1:`Grattis! Din registrering lyckades. Vi har skickat verifieringsmeddelandet till din e-post <span class="email"></span>, och slutför verifieringen för att använda fördelarna med detta e-postmeddelande.`,registration_succeeded_info_2:`Klicka på "Klar" för att gå tillbaka till startsidan och använda den här produkten.`,
        registration_succeeded_info_3:`Klicka på "Klar" för att stänga den aktuella sidan och återgå till startsidan för följande åtgärder. Om stängningen misslyckas, stäng fliken manuellt.`,
        verify_email:`Verifiera Email`,
        registered_email_not_verified:`Den registrerade e-postadressen har inte verifierats, vänligen verifiera den på en gång.`,
        email_verification_time_1:`Fick du inget verifieringsmail?`,
        email_verification_time_2:`Efter`,
        email_verification_time_3:`Klicka här för att skicka den igen`,
        error_26301:`Felkod: 26301, vänligen kontakta oss`,
        error_26302:`Felkod: 26302, vänligen kontakta oss`,
        error_26303:`E-postformatfel (felkod: 26303). Vänligen ange den igen`,
        error_26304:`Lösenord över 8 tecken rekommenderas (felkod: 26304)`,
        error_26305:`Reuqest misslyckades (felkod: 26305). Försök igen`,
        error_26306:`E-post har registrerats, vänligen <a href="https://account.aiseesoft.com/login">gå till att logga in</a>`,
        error_26307:`Reuqest misslyckades (felkod: 26307). Försök igen`,
        error_26308:`Reuqest misslyckades (felkod: 26308). Försök igen`,
        error_26401:`Felkod: 26401, försök igen`,
        error_26402:`E-posten har verifierats (felkod: 26402), försök igen`,
        error_26403:`Reuqest misslyckades (felkod: 26403). Försök igen`,
        error_26404:`Begäran misslyckades (felkod: 26404). Försök igen`,
        error_26501:`Felkod: 26501, vänligen kontakta oss`,
        error_26502:`Felkod: 26502, vänligen kontakta oss`,
        error_26503:`E-postformatfel (felkod: 26503). Vänligen ange den igen`,
        error_26504:`Reuqest misslyckades (felkod: 26504). Försök igen`,
        error_26505:`E-post har inte registrerats, vänligen <a href="https://account.aiseesoft.com/register">registrera det först</a>`,
        error_26506:`E-post har verifierats.`,
        error_26507:`Reuqest misslyckades (felkod: 26507). Försök igen`,
        error_26508:`Verifieringen misslyckades (felkod: 26508), försök igen`,
        error_26509:`Begäran misslyckades (felkod: 26509), försök igen`,
        error_26510:`Felkod: 26510, vänligen kontakta oss`,
        error_26601:`Felkod: 26601, vänligen kontakta oss`,
        error_26602:`Felkod: 26602, vänligen kontakta oss`,
        error_26603:`Reuqest misslyckades (felkod: 26603). Försök igen`,
        error_26604:`Felkod: 26604, vänligen kontakta oss`,
        error_26605:`Felkod: 26605, vänligen kontakta oss`,
        error_26701:`Felkod: 26701, vänligen kontakta oss`,
        error_26702:`Reuqest misslyckades (felkod: 26702). Försök igen`,
        error_26703:`Felkod: 26703, vänligen kontakta oss`,
        error_26704:`Felkod: 26704, vänligen kontakta oss`,
        error_26705:`Vänta på att logga in (felkod: 26705). Försök igen`,
        no_cookie:`Du har aktiverat funktionen Blockera alla cookies i din webbläsare, så du kan inte logga in. Gå till Inställningar för att markera rutan Tillåt alla cookies.`,
        error_26801:`Felkod: 26801, vänligen kontakta oss`,
        error_26802:`Felkod: 26802, vänligen kontakta oss`,
        error_26803:`Begäran misslyckades (felkod: 26803). Försök igen`,
        error_26804:`Begäran misslyckades (felkod: 26804). Försök igen`,
        error_order:`Begäran misslyckades (felkod: 27098), försök igen!`,
        error_order1:`Beställningsfrågan är ofullständig (felkod:`,
        error_order2:`）, vänligen uppdatera och försök igen.`,
        modify_email_title:`Ändra e-post`,
        modify_email_info:`Du kan använda den ändrade e-posten för att logga in på ditt konto.`,
        images_per:`Bilder pr`,
        error_26101:`Fel: 26101. Kontakta oss`,
        error_26102:`Fel: 26102. Kontakta oss`,
        error_26103:`Begäran misslyckades (felkod:26103). Vänligen försök igen`,
        error_26104:`Felkod: 26104, försök igen`,
        error_26105:`Felkod: 26105, försök igen`,
        error_26106:`Det gick inte att radera (felkod: 26106). Försök igen`,
        error_26201:`Fel: 26201. Kontakta oss`,
        error_26202:`Begäran misslyckades (felkod:26202). Vänligen försök igen`,
        error_26001:`Fel: 26001. Kontakta oss`,
        error_26002:`Fel: 26002. Kontakta oss`,
        error_26003:`Fel: 26003. Kontakta oss`,
        error_26004:`Fel: 26004. Kontakta oss`,
        error_26005:`Begäran misslyckades (felkod:26005). Vänligen försök igen`,
        error_26006:`Felkod: 26006, försök igen`,
        error_26008:`Fel: 26008. Kontakta oss`,
        go_to_the_home_page:`Gå till startsidan`,
        error_27101:`Begäran misslyckades (felkod: 27101). Vänligen försök igen`,
        error_27201:`Felkod: 27201, vänligen kontakta oss`,
        error_27202:`Felkod: 27202, försök igen`,
        error_27203:`Begäran misslyckades (felkod: 27203). Försök igen`,
        error_27204:`Ogiltig kod (felkod: 27204).`,
        error_27205:`Begäran misslyckades (felkod: 27205). Försök igen`,
        error_27206:`Begäran misslyckades (felkod: 27206). Försök igen`,
        error_27207:`Begäran misslyckades (felkod: 27207). Försök igen`,
        no_history_found:`Du har inte använt något verktyg! <a href="/">Uppdatera</a> eller <a href="https://sv.aiseesoft.com/">gå till den officiella webbplatsen</a>`,
        error_25301:`Fel: 25301. Kontakta oss`,
        error_25302:`Fel: 25302. Kontakta oss`,
        error_25303:`Begäran misslyckades (felkod: 25303). Försök igen`,
        error_25304:`Begäran misslyckades (felkod: 25304). Försök igen`,
        error_25305:`Begäran misslyckades (felkod: 25305). Försök igen`,
        error_25306:`Begäran misslyckades (felkod: 25306). Försök igen`,
        image_upscaler_p:`Fördelar med nedladdning av bild utan vattenstämpel:`,
        Available_for:`Tillgänglig för:`,
        credit_per:`%s kredit(er) per HD-bild`,
        still_valid:`Köpta plan(er) är fortfarande giltiga`,
        credit:`kredit(er)`,
        pc_3rd_info:`Logga in framgångsrikt. Gå till applikationen för vidare drift.`,
        use_online:`Använd onlinetjänsten`,
        use_download:`Använd skrivbordsprogrammet`,
        use_immediately:`Använd omedelbart`,
        Use_in_browser:`Använd i webbläsare`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    tr:{
        overtime_1:`Hata kodu:`,
        overtime_2:`, lütfen tekrar deneyin`,
        isnetwork:`İnternet hatası. Lütfen kontrol edip tekrar deneyin`,
        email_placeholder:`E-posta`,
        email_empty:`Lütfen e-posta girin`,
        email_not_valid:`E-posta geçerli değil`,
        email_not_valid_1:`Lütfen e-postanızı girin`,
        email_not_valid_2:`E-posta geçerli değil, lütfen farklı bir adres kullanın.`,
        email_not_valid_3:`E-posta girişi yok`,
        password_placeholder:`Parola`,
        password_empty:`Lütfen şifrenizi girin`,
        password_not_valid:`Yanlış hesap veya şifre`,
        password_not_valid_1:`8 karakterden uzun şifre gerekli`,
        password_not_valid_2:`Lütfen bir şifre oluşturun`,
        password_placeholder_1:`Şifreni oluştur`,
        password_placeholder_2:`Parolanızı doğrulayın`,
        password_placeholder_3:`Yeni şifre oluştur`,
        password_placeholder_4:`Yeni şifreyi onayla`,
        password_placeholder_5:`Eski şifreyi girin`,
        copy_password_empty:`Lütfen şifreyi onaylayın`,
        copy_password_not_valid:`Lütfen şifrenizi onaylayınız`,
        copy_passwords_inconsistent:`Şifre onayınız eşleşmiyor`,
        code_empty:`Lütfen doğrulama kodunu girin`,
        code_not_valid:`Geçersiz doğrulama kodu`,
        code_placeholder:`Doğrulama kodu`,
        not_received_code:`Posta kutunuza uzun bir süre doğrulama kodu gelmediyse, lütfen doğrulama kodunu tekrar alın.`,
        get_first_code:`Lütfen önce doğrulama kodunu alın.`,
        connect_your_email_placeholder:`E-postanızı bağlayın`,
        last_name_placeholder:`Lütfen soyadınızı giriniz`,
        first_name_placeholder:`Lütfen isminizi giriniz`,
        address_placeholder:`Lütfen adresinizi girin`,
        no_code_text:`Bir doğrulama kodu gönderdik. Lütfen kodunuzu girin. <span class='tips'>Bir kod almadınız mı?`,
        no_code_text_1:`1.Lütfen e-posta adresinin geçerli olduğundan ve e-posta alabildiğinden emin olun.`,
        no_code_text_2:`2. E-posta sistem tarafından otomatik olarak gönderildiği için spam veya önemsiz e-posta olarak işaretlenebilir. Lütfen e-postanın Çöp Kutusu klasöründe olup olmadığını kontrol edin.`,
        no_code_text_3:`3. Sorununuzu çözemiyor musunuz?`,
        no_code_text_3_span:`O halde bizimle iletişime geçmek için buraya tıklayın.`,
        order_no:`Herhangi bir ürün satın almadınız. Herhangi bir sorunuz varsa lütfen <a href="https://tr.aiseesoft.com/store/">Şimdi alışveriş yapın</a> Veya <a href="https://tr.aiseesoft.com/contact.html"> Bize ulaşın</a>`,
        error_24901:`Cari hesaba bağlı e-posta yok ve siparişleri bulamıyor. Lütfen bir e-posta bağlayın.`,
        user_guide:`Kullanım Kılavuzu >>`,
        download:`İndirmek`,
        order_number:`Sipariş numarası`,
        Refund:`Geri ödeme`,
        Disabled:`Engelli`,
        Normal:`Normal`,
        Modify:`değiştir`,
        Modify_1:`Değiştir >>`,
        Connect:`Bağlamak`,
        unlink_success:`Bağlantıyı başarıyla kaldır`,
        connect_success:`Başarıyla bağlan`,
        feedback_title:`Geri bildiriminiz için teşekkürler. Lütfen sorununuzu bırakın, size 24 saat içinde cevap vereceğiz.`,
        feedback_thank_you:`Teşekkürler!<br />Geri bildiriminiz başarıyla gönderildi.`,
        feedback_email:`E-posta adresinizi buraya girin!`,
        feedback_content:`Karşılaştığınız herhangi bir sorunu veya öneriyi burada bırakın.`,
        feedback_submit:`Göndermek`,
        form_contents:`Herhangi bir açıklama girmediniz. Lütfen girin ve tekrar gönderin.`,
        old_password:`Lütfen eski şifreyi girin`,
        new_password:`Lütfen yeni bir şifre oluşturun`,
        old_new_password:`Yeni şifre eskisi ile aynı olamaz`,
        incorrect_password:`yanlış parola`,
        delete_no:`Şimdi sil`,
        Caps:`Caps Lock açık`,
        Get:`Elde etmek`,
        Done:`Tamamlandı`,
        error_20001:`Hata: 20001. Lütfen tekrar giriş yapın.`,
        error_20002:`Hata: 20002. Lütfen tekrar giriş yapın.`,
        error_20003:`Hata: 20003. Lütfen tekrar giriş yapın.`,
        error_20004:`İstek başarısız oldu (hata kodu: 20004). Lütfen tekrar deneyin.`,
        error_20005:`Oturum açma oturumunun süresi doldu (Hata: 20005). Lütfen tekrar giriş yapın.`,
        error_20006:`İstek başarısız oldu (hata kodu: 20006). Lütfen tekrar deneyin.`,
        error_20007:`Oturum açma oturumunun süresi doldu (Hata: 20007). Lütfen tekrar giriş yapın.`,
        error_20008:`Oturum açma oturumunun süresi doldu (Hata: 20008). Lütfen tekrar giriş yapın.`,
        error_20009:`Oturum açma oturumunun süresi doldu (Hata: 20009). Lütfen tekrar giriş yapın.`,
        error_20101:`Lütfen e-posta adresinizi girin (hata kodu: 20101)`,
        error_20102:`E-posta geçerli değil (hata kodu: 20102)`,
        error_20103:`İstek başarısız oldu (hata kodu: 20103). Lütfen tekrar deneyin`,
        error_20104:`E-posta zaten kullanılıyor, lütfen <a href="https://account.aiseesoft.com/login">giriş yapın</a> veya yeni bir tane ile kaydolun`,
        error_20105:`İstek başarısız oldu (hata kodu: 20105). Lütfen tekrar deneyin`,
        error_20106:`E-posta gönderilemedi, lütfen tekrar deneyin`,
        error_20201:`Lütfen e-posta adresinizi girin (hata kodu: 20201)`,
        error_20202:`Lütfen şifrenizi girin (hata kodu: 20202)`,
        error_20203:`Lütfen doğrulama kodunu girin (hata kodu: 20203)`,
        error_20204:`E-posta geçerli değil (hata kodu: 20204)`,
        error_20205:`8 karakterden uzun şifre gerekli (hata kodu: 20205)`,
        error_20206:`İstek başarısız oldu (hata kodu: 20206). Lütfen tekrar deneyin`,
        error_20207:`Geçersiz doğrulama kodu`,
        error_20208:`İstek başarısız oldu (hata kodu: 20208). Lütfen tekrar deneyin`,
        error_20209:`İstek başarısız oldu (hata kodu: 20209). Lütfen tekrar deneyin`,
        error_20301:`Lütfen e-posta adresinizi girin (hata kodu: 20301)`,
        error_20302:`Hata: 20302. Lütfen bizimle iletişime geçin`,
        error_20303:`E-posta geçerli değil (hata kodu: 20303)`,
        error_20304:`İstek başarısız oldu (hata kodu: 20304). Lütfen tekrar deneyin`,
        error_20305:`Hesap mevcut değil. Lütfen önce yeniden girin veya <a href="https://account.aiseesoft.com/register">Oluştur</a>.`,
        error_20306:`Henüz şifre yok. <a href="https://account.aiseesoft.com/passwordless-login">Parolasız giriş</a> kullanın veya <a href="create-password">bir şifre belirleyin</a> ve giriş yapın.`,
        error_20308:`İstek başarısız oldu (hata kodu: 20308). Lütfen tekrar deneyin`,
        error_20401:`Oturum kapatılamadı (hata kodu: 20401). Lütfen tekrar deneyin`,
        error_20501:`Lütfen e-posta adresinizi girin (hata kodu: 20501)`,
        error_20502:`E-posta geçerli değil (hata kodu: 20502)`,
        error_20503:`İstek başarısız oldu (hata kodu: 20503). Lütfen tekrar deneyin`,
        error_20504:`E-posta gönderilemedi. Lütfen tekrar deneyin.`,
        error_20601:`Lütfen e-posta adresinizi girin (hata kodu: 20601)`,
        error_20602:`Lütfen doğrulama kodunu girin (hata kodu: 20602)`,
        error_20603:`E-posta geçerli değil (hata kodu: 20603)`,
        error_20604:`İstek başarısız oldu (hata kodu: 20604). Lütfen tekrar deneyin`,
        error_20606:`İstek başarısız oldu (hata kodu: 20606). Lütfen tekrar deneyin`,
        error_20607:`İstek başarısız oldu (hata kodu: 20607). Lütfen tekrar deneyin`,
        error_20608:`İstek başarısız oldu (hata kodu: 20608). Lütfen tekrar deneyin`,
        error_20701:`Lütfen e-posta adresinizi girin (hata kodu: 20701)`,
        error_20702:`E-posta geçerli değil (hata kodu: 20702)`,
        error_20703:`İstek başarısız oldu (hata kodu: 20703). Lütfen tekrar deneyin`,
        error_20704:`Hesap mevcut değil. Lütfen önce yeniden girin veya <a href="https://account.aiseesoft.com/register">Oluştur</a>.`,
        error_20705:`İstek başarısız oldu (hata kodu: 20705). Lütfen tekrar deneyin`,
        error_20706:`E-posta gönderilemedi. Lütfen tekrar deneyin`,
        error_20801:`Lütfen e-posta adresinizi girin (hata kodu: 20801)`,
        error_20802:`Hata: 20802. Lütfen bize ulaşın`,
        error_20803:`Lütfen doğrulama kodunu girin (hata kodu: 20803)`,
        error_20804:`E-posta geçerli değil (hata kodu: 20804)`,
        error_20805:`8 karakterden uzun şifre gerekli (hata kodu: 20805)`,
        error_20806:`İstek başarısız oldu (hata kodu: 20806). Lütfen tekrar deneyin`,
        error_20808:`İstek başarısız oldu (hata kodu: 20808). Lütfen tekrar deneyin`,
        error_20901:`İstek başarısız oldu (hata kodu: 20901). Lütfen tekrar deneyin`,
        error_20902:`İstek başarısız oldu (hata kodu: 20902). Lütfen tekrar deneyin`,
        error_21000:`Değişiklikler kaydedildi`,
        error_21001:`Hiçbir bilgi gönderilmedi (hata kodu: 21001)`,
        error_21002:`İstek başarısız oldu (hata kodu: 21002). Lütfen tekrar deneyin`,
        error_21101:`Lütfen e-posta adresinizi girin (hata kodu: 21101)`,
        error_21102:`E-posta geçerli değil (hata kodu: 21102)`,
        error_21103:`İstek başarısız oldu (hata kodu: 21103), lütfen tekrar deneyin`,
        error_21104:`E-posta zaten bağlı, lütfen yeni bir tane kullanın`,
        error_21105:`İstek başarısız oldu (hata kodu: 21105), lütfen tekrar deneyin`,
        error_21106:`E-posta gönderilemedi. Lütfen tekrar deneyin`,
        error_21201:`Lütfen e-posta adresinizi girin (hata kodu: 21201)`,
        error_21202:`Lütfen doğrulama kodunu girin (hata kodu: 21202)`,
        error_21203:`E-posta geçerli değil (hata kodu: 21203)`,
        error_21204:`Hata: 21204. Lütfen bize ulaşın`,
        error_21205:`Hata: 21205. Lütfen bize ulaşın`,
        error_21206:`8 karakterden uzun şifre gerekli (hata kodu: 21206)`,
        error_21207:`İstek başarısız oldu (hata kodu: 21207). Lütfen tekrar deneyin`,
        error_21209:`İstek başarısız oldu (hata kodu: 21209). Lütfen tekrar deneyin`,
        error_21301:`Lütfen eski şifreyi girin (hata kodu: 21301)`,
        error_21302:`Lütfen yeni bir şifre oluşturun (hata kodu: 21302)`,
        error_21303:`Yeni şifre eskisi ile aynı olamaz. (Hata: 21303)`,
        error_21304:`8 karakterden uzun şifre gerekli (hata kodu: 21304)`,
        error_21306:`İstek başarısız oldu (hata kodu: 21306). Lütfen tekrar deneyin`,
        error_21402:`İstek başarısız oldu (hata kodu: 21402). Lütfen tekrar deneyin`,
        error_21403:`Doğrulama kodu gönderilemedi. lütfen tekrar gönderin`,
        error_21500:`hesap silindi`,
        error_21501:`Lütfen doğrulama kodunu girin (hata kodu: 21501)`,
        error_21502:`Oturum açma oturumunun süresi doldu (Hata: 21502). Lütfen tekrar giriş yapın.`,
        error_21503:`İstek başarısız oldu (hata kodu: 21503). Lütfen tekrar deneyin`,
        error_21505:`İstek başarısız oldu (hata kodu: 21505), lütfen yeniden deneyin`,
        error_21601:`Hata: 20601. Lütfen bizimle iletişime geçin`,
        error_21602:`Geçersiz Doğrulama (Hata: 20602). Lütfen tekrar deneyin.`,
        error_21603:`Hata: 20603. Lütfen tekrar deneyin`,
        error_21604:`İstek başarısız oldu (hata kodu: 21604). Lütfen tekrar deneyin`,
        error_21606:`İstek başarısız oldu (hata kodu: 21606). Lütfen tekrar deneyin`,
        error_21611:`İstek başarısız oldu (hata kodu: 21611). Lütfen tekrar deneyin`,
        error_21801:`Hata: 21801. Lütfen bizimle iletişime geçin`,
        error_21802:`İstek başarısız oldu (Hata: 21802). Lütfen tekrar deneyin`,
        error_21803:`Hata: 21803. Lütfen tekrar deneyin`,
        error_21804:`İstek başarısız oldu (hata kodu: 21804). Lütfen tekrar deneyin`,
        error_21806:`Hata: 21806. Lütfen tekrar deneyin`,
        error_21807:`Hata: 21807. Lütfen bizimle iletişime geçin`,
        error_21808:`Hata: 21808. Lütfen bize ulaşın`,
        error_21809:`Hata: 21809. Lütfen bize ulaşın`,
        error_21810:`Hata: 21810. Lütfen bize ulaşın`,
        error_21811:`Hata: 21811. Lütfen bize ulaşın`,
        error_21812:`Hata: 21812. Lütfen bize ulaşın`,
        error_21813:`İstek başarısız oldu (hata kodu: 21813). Lütfen tekrar deneyin`,
        error_21814:`Hata: 21814. Lütfen bize ulaşın`,
        error_21815:`İstek başarısız oldu (hata kodu: 21815). Lütfen tekrar deneyin`,
        error_21816:`Hata: 21816. Lütfen bize ulaşın`,
        error_21817:`Hata: 21817. Lütfen bizimle iletişime geçin`,
        error_21818:`Hata: 21818. Lütfen bize ulaşın`,
        error_21819:`İstek başarısız oldu (hata kodu: 21819). Lütfen tekrar deneyin`,
        error_21820:`Hata: 21820. Lütfen bize ulaşın`,
        error_21821:`Hata: 21821. Lütfen bize ulaşın`,
        error_21822:`Hata: 21822. Lütfen bize ulaşın`,
        error_21823:`İstek başarısız oldu (hata kodu: 21823). Lütfen tekrar deneyin`,
        error_21824:`İstek başarısız oldu (hata kodu: 21824). Lütfen tekrar deneyin`,
        error_21825:`İstek başarısız oldu (hata kodu: 21825). Lütfen tekrar deneyin`,
        error_21826:`İstek başarısız oldu (hata kodu: 21826). Lütfen tekrar deneyin`,
        error_2128:`Hata: 2128. Lütfen bize ulaşın`,
        error_21901:`Hata: 21901. Lütfen bizimle iletişime geçin`,
        error_21902:`İstek başarısız oldu (hata kodu: 21902). Lütfen tekrar deneyin`,
        error_21903:`Hesap durumu değişti (hata kodu: 21903), lütfen sayfayı yenileyin ve tekrar deneyin`,
        error_21904:`Hata: 21904. Lütfen tekrar deneyin`,
        error_21905:`Hata: 21905. Lütfen tekrar deneyin`,
        error_21906:`İstek başarısız oldu (hata kodu: 21906). Lütfen tekrar deneyin`,
        error_21907:`Google hesabı başka bir hesaba bağlandı`,
        error_21908:`İstek başarısız oldu (hata kodu: 21908). Lütfen tekrar deneyin`,
        error_22001:`İstek başarısız oldu (hata kodu: 22001). Lütfen tekrar deneyin`,
        error_22002:`Ek giriş yapılmadan bağlantı kaldırılamadı`,
        error_22003:`İstek başarısız oldu (hata kodu: 2203). Lütfen tekrar deneyin`,
        error_22101:`Hata: 22101. Lütfen bizimle iletişime geçin`,
        error_22102:`Hesap durumu değişti (hata kodu: 22102), lütfen sayfayı yenileyin ve tekrar deneyin`,
        error_22103:`İstek başarısız oldu (hata kodu: 22103). Lütfen tekrar deneyin`,
        error_22104:`Hesap durumu değişti (hata kodu: 22104), lütfen sayfayı yenileyin ve tekrar deneyin`,
        error_22105:`Hata: 22105. Lütfen tekrar deneyin`,
        error_22106:`Hata: 22106. Lütfen tekrar deneyin`,
        error_22107:`Hata: 22107. Lütfen bizimle iletişime geçin`,
        error_22108:`İstek başarısız oldu (hata kodu: 22108). Lütfen tekrar deneyin`,
        error_22201:`Hata: 22201. Lütfen bize ulaşın`,
        error_22202:`Geçersiz Doğrulama (Hata: 22202). Lütfen tekrar deneyin.`,
        error_22203:`Hata: 22203. Lütfen tekrar deneyin"`,
        error_22204:`İstek başarısız oldu (hata kodu: 22204). Lütfen tekrar deneyin`,
        error_22206:`İstek başarısız oldu (hata kodu: 22206). Lütfen tekrar deneyin`,
        error_22401:`Hata: 22401. Lütfen bize ulaşın`,
        error_22402:`Geçersiz Doğrulama (Hata: 22402). Lütfen tekrar deneyin.`,
        error_22403:`Hata: 22403. Lütfen tekrar deneyin`,
        error_22404:`İstek başarısız oldu (hata kodu: 22404). Lütfen tekrar deneyin`,
        error_22405:`Facebook hesabı başka bir e-postaya bağlandı`,
        error_22406:`Hata: 22406. Lütfen tekrar deneyin`,
        error_22407:`Hata: 22407. Lütfen bize ulaşın`,
        error_22408:`Hata: 22408. Lütfen bize ulaşın`,
        error_22409:`Hata: 22409. Lütfen bize ulaşın`,
        error_22410:`Hata: 224010. Lütfen bize ulaşın`,
        error_22411:`Hata: 224011. Lütfen bize ulaşın`,
        error_22412:`Hata: 224012. Lütfen bize ulaşın`,
        error_22413:`Hata: 22413. Lütfen bize ulaşın`,
        error_22414:`İstek başarısız oldu (hata kodu: 22414). Lütfen tekrar deneyin`,
        error_22415:`Hata: 22415. Lütfen bize ulaşın`,
        error_22416:`Hata: 22416. Lütfen bize ulaşın`,
        error_22417:`Hata: 22417. Lütfen bize ulaşın`,
        error_22418:`İstek başarısız oldu (hata kodu: 22418). Lütfen tekrar deneyin`,
        error_22419:`Hata: 22419. Lütfen bize ulaşın`,
        error_22420:`Hata: 22420. Lütfen bize ulaşın`,
        error_22421:`Hata: 22421. Lütfen bize ulaşın`,
        error_22422:`İstek başarısız oldu (hata kodu: 22422). Lütfen tekrar deneyin`,
        error_22423:`İstek başarısız oldu (hata kodu: 22423). Lütfen tekrar deneyin`,
        error_22424:`İstek başarısız oldu (hata kodu: 22424). Lütfen tekrar deneyin`,
        error_22425:`İstek başarısız oldu (hata kodu: 22425). Lütfen tekrar deneyin`,
        error_20098:`Hata kodu: 20098. Özel modda göz atıyorsanız, lütfen Normal moda geçin ve tekrar deneyin.`,
        error_22298:`Google isteği başarısız oldu (Hata: 22298). Lütfen tekrar deneyin.`,
        error_22498:`Facebook isteği başarısız oldu (Hata: 22498). Lütfen tekrar deneyin.`,
        error_24902:`İstek başarısız oldu (hata kodu: 24902). Lütfen tekrar deneyin`,
        error_24903:`İstek başarısız oldu (hata kodu: 24903). Lütfen tekrar deneyin`,
        error_24904:`İstek başarısız oldu (hata kodu: 24904). Lütfen tekrar deneyin`,
        error_24905:`İstek başarısız oldu (hata kodu: 24905). Lütfen tekrar deneyin`,
        login_title:`Aiseesoft'ta oturum açın`,
        password_placeholder:`Parola`,
        log_in:`Giriş yapmak`,
        no_account:`Hesap yok?`,
        create_it:`Oluştur`,
        or_log_in_with:`Veya ile giriş yapın`,
        passwordless_login:`Şifresiz oturum açma`,
        log_in_done:`Giriş Yap Yapıldı`,
        three_rd_account_connect_info:`Tebrikler! Başarıyla giriş yaptınız. Artık gelecekte oturum açmak için kullanılan bir e-posta hesabını bağlayabilirsiniz.`,
        connect_now:`Şimdi Bağlan`,
        see_my_account:`hesabımı gör`,
        three_rd_login_merge_account:`Üçüncü taraf hesap e-posta adresi kaydedildi, doğrudan bu e-posta adresiyle bağlanmak ve giriş yapmak istiyor musunuz?`,
        connect_log_in:`Bağlan ve Giriş Yap`,
        create_an_account:`Bir hesap oluşturun`,
        connect_account:`Hesabı Bağla`,
        connect_account_info:`Bağlandıktan sonra bu hesapla giriş yapabilirsiniz.`,
        back_to_log_in:`Giriş yapmak için geri dön`,
        create_password:`Şifre oluştur`,
        create_now:`Şimdi oluştur`,
        password_login_subtitle:`E-posta ile şifresiz giriş`,
        account_login:`Hesap girişi`,
        rights:`Bu hesabı oluşturarak <a href="https://tr.aiseesoft.com/company/terms-and-conditions.html">Hizmet Şartları</a> ve <a href="https://tr.aiseesoft.com/privacy-policy.html">Gizlilik Politikası</a>`,
        passwordless_login_done:`Şifresiz giriş yapıldı`,
        passwordless_login_info:`Tebrikler, şifresiz oturum açma işlemini başarıyla tamamladınız. Bu hesap için bir şifre oluşturabilir ve gelecekte hesap ve şifre ile giriş yapabilirsiniz. <a href="/create-password" style="display: initial;">Şimdi Oluşturun</a>`,
        sign_up:`Üye olmak`,
        register_info:`hesabını oluştur`,
        reset_now:`Şimdi Sıfırla`,
        forgot_password:`Parolanızı mı unuttunuz`,
        reset_password_subtitle:`Şifreyi sıfırlamak için hesap e-postanızı kullanın`,
        plan_products:`Plan ve Ürünler`,
        nick_name:`Kullanıcı adı`,
        email:`E-posta`,
        my_products:`Ürünlerim`,
        my_orders:`Siparişlerim`,
        unlink:`Bağlantıyı kaldır`,
        link:`Bağlantı`,
        connected_accounts:`Bağlı hesaplar`,
        last_name:`Soyadı`,
        first_name:`İlk adı`,
        Gender:`Cinsiyet`,
        Birth:`Doğum`,
        Month:`Ay`,
        Year:`Yıl`,
        Country_Region:`Ülke/Bölge`,
        Address:`Adres`,
        Save:`Kaydetmek`,
        Date:`Tarih`,
        Male:`Erkek`,
        Female:`Dişi`,
        Unspecified:`Belirtilmemiş`,
        Security:`Güvenlik`,
        change_password:`Şifre değiştir`,
        change_now:`Şimdi değiştir`,
        connect_email:`E-postayı bağla`,
        delete_account:`Hesabı sil`,
        delete_account_info:`Hesabınız silindiğinde, Plan ve Ürünler'deki hesabınızla ilişkili tüm veriler kalıcı olarak silinecek ve bunları kurtaramayabilirsiniz. Dikkatli yürümenizi öneririz.`,
        Delete:`Silmek`,
        Logout:`Çıkış Yap`,
        my_profile:`Benim profilim`,
        guides_faqs:`Kılavuzlar ve SSS`,
        More:`Daha`,
        guides:`Kılavuzlar`,
        register:`Kayıt ol`,
        hot_faq:`Sıcak SSS`,
        Contents:`İçindekiler:`,
        contact_us:`Bize ulaşın>>`,
        plan:`Plan`,
        unregistered:`Kayıtsız`,
        buy_more:`Daha fazla satın al`,
        buy_again:`Tekrar satın al`,
        buy_now:`Şimdi al`,
        free_no_limit:`Ücretsiz ve Sınırsız`,
        expired:`Süresi doldu`,
        lifetime:`Ömür`,
        remain:`Geriye kalmak`,
        day_s:`Gün(ler)`,
        error_24801:`İstek başarısız oldu (hata kodu: 24801). Lütfen tekrar deneyin`,
        no_app_found:`Uygulama bulunamadı！<a href="/">Yenile</a> veya <a href="https://tr.aiseesoft.com/">Resmi web sitesine gidin</a>`,
        get_more:`Daha Fazlasını Alın >>`,
        edit_photo:`Fotoğrafı Düzenle`,
        select_photo:`Fotoğraf seç`,
        change_photo:`Fotoğrafı değiştir`,
        cancel:`İptal`,
        hide_password:`Şifreyi gizle`,
        show_password:`Şifreyi göster`,
        zoom_in:`Yakınlaştır`,
        zoom_out:`Uzaklaştırmak`,
        rotate:`Döndür`,
        horizontal_flip:`yatay çevirme`,
        vertical_flip:`Dikey çevirme`,
        country:`Ülke`,
        country_1:`Ülkenizi/bölgenizi seçin`,
        country_2:`Åaland Adası`,
        country_3:`Afganistan`,
        country_4:`Arnavutluk`,
        country_5:`Cezayir`,
        country_6:`Amerikan Samoası`,
        country_7:`Andora`,
        country_8:`Angola`,
        country_9:`anguilla`,
        country_10:`Antarktika`,
        country_11:`Antigua ve Barbuda`,
        country_12:`Arjantin`,
        country_13:`Ermenistan`,
        country_14:`Aruba`,
        country_15:`Avustralya`,
        country_16:`Avusturya`,
        country_17:`Azerbaycan`,
        country_18:`Bahreyn`,
        country_19:`Bangladeş`,
        country_20:`Barbados`,
        country_21:`Belarus`,
        country_22:`Belçika`,
        country_23:`Belize`,
        country_24:`Benin`,
        country_25:`Bermuda`,
        country_26:`Butan`,
        country_27:`Bolivya`,
        country_28:`Bosna-Hersek`,
        country_29:`Botsvana`,
        country_30:`Bouvet Adası`,
        country_31:`Brezilya`,
        country_32:`Britanya Hint Okyanusu Toprakları`,
        country_33:`Britanya Virjin Adaları`,
        country_34:`Brunei`,
        country_35:`Bulgaristan`,
        country_36:`Burkina`,
        country_37:`Burundi`,
        country_38:`Kamboçya`,
        country_39:`Kamerun`,
        country_40:`Kanada`,
        country_41:`Yeşil Burun Adaları`,
        country_42:`Karayip Hollandası`,
        country_43:`Cayman Adaları`,
        country_44:`Orta Afrika Cumhuriyeti`,
        country_45:`Çad`,
        country_46:`Şili`,
        country_47:`Çin`,
        country_48:`Noel Adası`,
        country_49:`Cocos (Keyling) Adaları`,
        country_50:`Kolombiya`,
        country_51:`Cook Adaları`,
        country_52:`Kosta Rika`,
        country_53:`"Fildişi Sahili"`,
        country_54:`Hırvatistan`,
        country_55:`Küba`,
        country_56:`Kıbrıs`,
        country_57:`Çek Cumhuriyeti`,
        country_58:`Kongo Demokratik Cumhuriyeti`,
        country_59:`Danimarka`,
        country_60:`Cibuti`,
        country_61:`Dominika`,
        country_62:`Dominik Cumhuriyeti`,
        country_63:`Ekvador`,
        country_64:`Mısır`,
        country_65:`El Salvador`,
        country_66:`Ekvator Ginesi`,
        country_67:`Eritre`,
        country_68:`Estonya`,
        country_69:`Etiyopya`,
        country_70:`Falkland adaları`,
        country_71:`Faroe Adaları`,
        country_72:`Mikronezya Federe Devletleri`,
        country_73:`Fiji`,
        country_74:`Finlandiya`,
        country_75:`Fransa`,
        country_76:`Fransız Guyanası`,
        country_77:`Fransız Polinezyası`,
        country_78:`Fransız Güney Toprakları`,
        country_79:`Gabon`,
        country_80:`Gambiya`,
        country_81:`Gürcistan`,
        country_82:`Almanya`,
        country_83:`Gana`,
        country_84:`Cebelitarık`,
        country_85:`Büyük Britanya (Birleşik Krallık; İngiltere)`,
        country_86:`Yunanistan`,
        country_87:`Grönland`,
        country_88:`Grenada`,
        country_89:`Guadelup`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Gine`,
        country_94:`Gine-Bissau`,
        country_95:`Guyana`,
        country_96:`Haiti`,
        country_97:`Heard Adası ve McDonald Adaları`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Macaristan`,
        country_101:`İzlanda`,
        country_102:`Hindistan`,
        country_103:`Endonezya`,
        country_104:`İran`,
        country_105:`Irak`,
        country_106:`İrlanda`,
        country_107:`Man Adası`,
        country_108:`İsrail`,
        country_109:`İtalya`,
        country_110:`Jamaika`,
        country_111:`Japonya`,
        country_112:`Jersey`,
        country_113:`Ürdün`,
        country_114:`Kazakistan`,
        country_115:`Kenya`,
        country_116:`Kiribati`,
        country_117:`Kuveyt`,
        country_118:`Kırgızistan`,
        country_119:`Laos`,
        country_120:`Letonya`,
        country_121:`Lübnan`,
        country_122:`Lesoto`,
        country_123:`Liberya`,
        country_124:`Libya`,
        country_125:`Lihtenştayn`,
        country_126:`Litvanya`,
        country_127:`Lüksemburg`,
        country_128:`Makao`,
        country_129:`Madagaskar`,
        country_130:`Malawi`,
        country_131:`Malezya`,
        country_132:`Maldivler`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Marşal Adaları`,
        country_136:`Martinik`,
        country_137:`Moritanya`,
        country_138:`Mauritius`,
        country_139:`mayo`,
        country_140:`Meksika`,
        country_141:`Moldova`,
        country_142:`Monako`,
        country_143:`Moğolistan`,
        country_144:`Karadağ`,
        country_145:`Montserrat`,
        country_146:`Fas`,
        country_147:`Mozambik`,
        country_148:`Myanmar (Burma)`,
        country_149:`Namibya`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Hollanda`,
        country_153:`Yeni Kaledonya`,
        country_154:`Yeni Zelanda`,
        country_155:`Nikaragua`,
        country_156:`Nijer`,
        country_157:`Nijerya`,
        country_158:`Niue`,
        country_159:`Norfolk Adası`,
        country_160:`Kuzey Kore`,
        country_161:`Kuzey Mariana Adaları`,
        country_162:`Norveç`,
        country_163:`Umman`,
        country_164:`Pakistan`,
        country_165:`Palau`,
        country_166:`Filistin Bölgesi`,
        country_167:`Panama`,
        country_168:`Papua Yeni Gine`,
        country_169:`Paraguay`,
        country_170:`Peru`,
        country_171:`Pitcairn Adaları`,
        country_172:`Polonya`,
        country_173:`Portekiz`,
        country_174:`Porto Riko`,
        country_175:`Katar`,
        country_176:`Makedonya Cumhuriyeti (FYROM)`,
        country_177:`Kongo Cumhuriyeti`,
        country_178:`yeniden birleşme`,
        country_179:`Romanya`,
        country_180:`Rusya Federasyonu`,
        country_181:`Ruanda`,
        country_182:`Aziz Barthelemy`,
        country_183:`Saint Martin (Fransa)`,
        country_184:`Saint-Pierre ve Miquelon`,
        country_185:`Samoa`,
        country_186:`San Marino`,
        country_187:`Sao Kitabı ve Prensibi`,
        country_188:`Suudi Arabistan`,
        country_189:`Senegal`,
        country_190:`Sırbistan`,
        country_191:`Seyşeller`,
        country_192:`Sierra Leone`,
        country_193:`Singapur`,
        country_194:`Slovakya`,
        country_195:`Slovenya`,
        country_196:`Solomon Adaları`,
        country_197:`Somali`,
        country_198:`Güney Afrika`,
        country_199:`Güney Georgia ve Güney Sandwich Isla`,
        country_200:`Güney Kore`,
        country_201:`Güney Sudan`,
        country_202:`ispanya`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena ve Bağımlılıklar`,
        country_205:`Aziz Kitts ve Nevis`,
        country_206:`Aziz Lucia`,
        country_207:`Saint Vincent ve Grenadinler`,
        country_208:`Sudan`,
        country_209:`Surinam`,
        country_210:`Svaziland`,
        country_211:`İsveç`,
        country_212:`İsviçre`,
        country_213:`Suriye`,
        country_214:`Tayvan`,
        country_215:`Tacikistan`,
        country_216:`Tanzanya`,
        country_217:`Şablon:Ülke verileri SJM Svalbard`,
        country_218:`Tayland`,
        country_219:`Bahamalar`,
        country_220:`Komorlar`,
        country_221:`Filipinler`,
        country_222:`Doğu Timor (Doğu Timor)`,
        country_223:`Gitmek`,
        country_224:`Tokelau`,
        country_225:`Tonga`,
        country_226:`Trinidad & Tobago`,
        country_227:`Tunus`,
        country_228:`Türkiye`,
        country_229:`Türkmenistan`,
        country_230:`Turks ve Caicos Adaları`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ukrayna`,
        country_234:`Birleşik Arap Emirlikleri`,
        country_235:`Amerika Birleşik Devletleri Küçük Dış Adaları`,
        country_236:`Amerika Birleşik Devletleri (ABD)`,
        country_237:`Amerika Birleşik Devletleri Virjin Adaları`,
        country_238:`Uruguay`,
        country_239:`Özbekistan`,
        country_240:`Vanuatu`,
        country_241:`Vatikan Şehri (Vatikan)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis ve Futuna`,
        country_245:`Batı Sahra`,
        country_246:`Yemen`,
        country_247:`Zambiya`,
        country_248:`Zimbabve`,
        google_login:`Google ile giriş yap`,
        footer:`Telif hakkı © 2024 Aiseesoft Studio. Her hakkı saklıdır.`,
        State:`Durum`,
        Activation_code:`Aktivasyon kodu`,
        Question:`Giriş yaptığınız tüm uygulamaları listeleyin`,
        Copy_complete:`Kopyalama tamamlandı`,

        store:`Mağaza`,
        resource:`Kaynak`,
        support:`destek`,
        video_converter:`Video dönüştürücü`,
        multimedia:`Multimedya`,
        data_recovery:`Veri kurtarma`,
        utility:`Yarar`,
        free_tools:`Ücretsiz Araçlar`,
        for_windows:`Pencereler için`,
        for_mac:`Mac için`,
        pdf_solutions:`PDF Çözümleri`,
        data_manager:`Veri Yöneticisi`,
        other_tools:`Diğer Aletler`,
        free_online_solutions:`Ücretsiz Çevrimiçi Çözümler`,
        free_online_tools:`Ücretsiz Çevrimiçi Araçlar`,
        desktop_freeware:`Masaüstü Ücretsiz Yazılım`,
        product_1:`Video Dönüştürücü Ultimate`,
        product_2:`Toplam video dönüştürücü`,
        product_3:`MTS Dönüştürücü`,
        product_4:`4K Dönüştürücü`,
        product_5:`MXF Dönüştürücü`,
        product_6:`3D Dönüştürücü`,
        product_7:`Mac Video Dönüştürücü Ultimate`,
        product_8:`Mac için Video Dönüştürücü`,
        product_9:`Mac için MTS Dönüştürücü`,
        product_10:`Mac için 4K Dönüştürücü`,
        product_11:`Mac için MXF Dönüştürücü`,
        product_12:`Mac için 3D Dönüştürücü`,
        product_13:`Blu-ray oynatıcı`,
        product_14:`Video Geliştirici`,
        product_15:`Video Düzenleyici`,
        product_16:`Slayt Gösterisi Oluşturucu`,
        product_17:`Blu-ray Oluşturucu`,
        product_18:`Burnova - DVD Oluşturucu Pro`,
        product_19:`DVD Oluşturucu`,
        product_20:`Mac Blu-ray Oynatıcı`,
        product_21:`Mac Video Geliştirici`,
        product_22:`Mac için Video Düzenleyici`,
        product_23:`Mac için DVD Oluşturucu`,
        product_24:`Mac için MP4 Dönüştürücü`,
        product_25:`Windows Veri Kurtarma`,
        product_26:`FoneLab iPhone Veri Kurtarma`,
        product_27:`FoneLab iOS Sistem Kurtarma`,
        product_28:`FoneLab iOS Veri Yedekleme &amp; Onarmak`,
        product_29:`FoneLab Android Veri Kurtarma`,
        product_30:`Bozuk Android Veri Çıkarma`,
        product_31:`Android Veri Yedekleme &amp; Onarmak`,
        product_32:`Mac Veri Kurtarma`,
        product_33:`Mac FoneLab iPhone Veri Kurtarma`,
        product_34:`Mac FoneLab iOS Sistem Kurtarma`,
        product_35:`Mac FoneLab iOS Veri Yedekleme &amp; Onarmak`,
        product_36:`Mac FoneLab Android Veri Kurtarma`,
        product_37:`Mac Android Veri Yedekleme &amp; Onarmak`,
        product_38:`PDF Dönüştürücü Ultimate`,
        product_39:`Mac için PDF Dönüştürücü Ultimate`,
        product_40:`PDF Birleştirme`,
        product_41:`Mac PDF'den ePub'a Dönüştürücü`,
        product_42:`Mac Temizleyici`,
        product_43:`FoneTrans`,
        product_44:`iOS için WhatsApp Transferi`,
        product_45:`MobieSync`,
        product_46:`FoneSilgi`,
        product_47:`iPhone Temizleyici`,
        product_48:`PPT'den Video Dönüştürücüye`,
        product_49:`HEIC Dönüştürücü`,
        product_50:`iPhone Unlocker`,
        product_51:`Çevrimiçi Ücretsiz Video Dönüştürücü`,
        product_52:`Ücretsiz Çevrimiçi Ses Dönüştürücü`,
        product_53:`Çevrimiçi Ücretsiz Video Kompresör`,
        product_54:`Çevrimiçi Ücretsiz Video Birleşmesi`,
        product_55:`Ücretsiz HEIC Dönüştürücü`,
        product_56:`Ücretsiz Görüntü Kompresörü`,
        product_57:`Ücretsiz Filigran Kaldırıcı`,
        product_58:`Çevrimiçi Ücretsiz PDF Kompresör`,
        product_59:`Ücretsiz Çevrimiçi Görüntü Yükseltici`,
        product_60:`Ücretsiz Çevrimiçi Arka Plan Sökücü`,
        product_61:`Ücretsiz Görüntü Dönüştürücü Çevrimiçi`,
        product_62:`Ücretsiz PDF PNG Dönüştürücü Çevrimiçi`,
        product_63:`Ücretsiz Video Dönüştürücü`,
        product_64:`Ücretsiz Videodan GIF'e Dönüştürücü`,
        product_65:`Ücretsiz Android Veri Kurtarma`,
        product_66:`Slayt Gösterisi Oluşturucu`,
        product_67:`Ücretsiz Video Düzenleyici`,
        product_68:`Ücretsiz PDF Görüntüleyici`,
        product_69:`Ekran kaydedicisi`,
        product_70:`Mac Ekran Kaydedici`,
        product_71:`Ücretsiz Ekran Kaydedici Çevrimiçi`,
        product_72:`Ücretsiz Çevrimiçi Ses Kaydedici`,
        product_73:`Telefon Aynası`,
        product_74:`AnyCoord - Konum Değiştirici`,
        product_75:`iPhone Parola Yöneticisi`,
        product_76:`Ücretsiz Çevrimiçi Ses Kompresörü`,
        product_77:`Çevrimiçi Ücretsiz Video Kırpıcı`,
        product_78:`Çevrimiçi Ücretsiz Video Kırpıcı`,
        product_79:`Ücretsiz Çevrimiçi Video Döndürücü`,
        product_80:`Ücretsiz Çevrimiçi Video Looper`,
        product_81:`Ücretsiz Çevrimiçi PDF JPG Dönüştürücü`,
        product_81:`Ücretsiz Çevrimiçi JPG Word Dönüştürücü`,
        category_1:`Fotoğraf düzenleme`,
        category_2:`Video düzenleme`,
        category_3:`Silgi`,
        category_4:`Medya Kaydedici`,
        posted_by:`tarafından gönderildi`,
        to:`ile`,
        author_1:`ashley mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`amanda kahverengi`,
        article_1:`Resimlerden Metni Etkili Bir Şekilde Kaldırma`,
        article_2:`Kaliteye Zarar Vermeden Görüntü Boyutunu KB'den MB'ye Nasıl Artırırsınız`,
        article_3:`Windows ve Mac'te Getty Images Filigranı Nasıl Kaldırılır`,
        article_4:`Düşük Çözünürlüklü Görüntüyü Ücretsiz Olarak Yüksek Çözünürlüklü Çevrimiçi Dönüştürmenin En İyi Yöntemi`,
        article_5:`TikTok Rehberi | İşte TikTok'ta Video Kolajı Nasıl Yapılır?`,
        article_6:`Bilmeniz Gereken VOB Dosyalarını Birleştirmenin 5 Kolay Yolu`,
        article_7:`Videoyu Nasıl Yükseltebilir ve Kolayca Daha Yüksek Çözünürlük Elde Edebilirsiniz`,
        article_8:`MacBook Pro/iMac/MacBook ve Daha Fazlasında MP4 Dosyaları Nasıl Birleştirilir`,
        article_9:`iPhone'da Satın Alma Geçmişini Silmenin 2 Basit Yolu [Çözüldü]`,
        article_10:`Snapchat Resimleri Sohbet ve Anılardan Kalıcı Olarak Nasıl Silinir`,
        article_11:`iPhone/iPad'de Spotlight Arama Önerileri ve Geçmişi Nasıl Silinir`,
        article_12:`[Çözüldü] 3 Kolay Yolla iPhone'da Instagram Önbelleği Nasıl Temizlenir`,
        article_13:`[Çözüldü] QuickTime Player'ın Mac'te Çalışmaması Nasıl Onarılır`,
        article_14:`Mac'te Sistem Sesi Nasıl Kaydedilir (Big Sur/Catalina/Mojave…)`,
        article_15:`PowerPoint Ekran Kaydının Çalışmamasını Düzeltmenin Etkili Yolları`,
        article_16:`VLC ile Ses Nasıl Kaydedilir [Çözüldü]`,
        faq_title_1:`1. 4104 hatası nasıl düzeltilir?`,
        faq_title_2:`2. İade talebim onaylandıktan sonra paramı ne zaman geri alabilirim?`,
        faq_title_3:`3. "1 Yıllık Abonelik" nedir ve "Otomatik Yenileme Hizmeti" nasıl kapatılır?`,
        faq_title_4:`4. Aiseesoft ürününü yerleştirdikten sonra siparişimi iptal edebilir miyim?`,
        faq_title_5:`5. Size bir inceleme nasıl gönderilir?`,
        faq_info_1:`Bunun nedeni, sahip olduğunuz sınırlı Lisanstır. Kayıt kodunun çalınması durumunda Tek Lisans'ı benimsiyor ve kayıt kodunun ihlal edilmesini önlüyoruz. Yani sahip olduğunuz Kayıt Kodu, yalnızca bir bilgisayarda kullanılabilen Tek Lisanstır. Birden fazla bilgisayarda kullanıyorsanız Hata 4104 olacaktır. Biz sizin için sıfırlayana kadar kullanamazsınız.`,
        faq_info_2:`Bilgisayarınızı değiştirir veya yeniden kurarsanız, sizin için sıfırlayabiliriz. Lütfen müşteri hizmetleriyle iletişime geçin: <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>, kayıt kodunu sizin için sıfırlamayı talep edin.`,
        faq_info_3:`Geri ödeme talebiniz onaylandıktan sonra, kredinin hesabınızda görünmesi normalde 3~7 iş günü sürer. Bazen tatillerde daha uzun sürebilir ancak 10 günden fazla sürmesi beklenmez. Onaydan 20 gün sonra geri ödeme almadıysanız, geri ödemeyi kontrol etmek için lütfen <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> adresinden bizimle iletişime geçin.`,
        faq_info_4:`Otomatik Yenileme Hizmeti, 1 Yıllık Abonelik satın alırsanız, ürün lisansının süresi dolduğunda ödeme platformunun tutarı (geçerli vergiler dahil) otomatik olarak tahsil edeceği anlamına gelir. Ancak ödeme düz formu (Swreg veya 2Checkout), siparişin sona erme tarihinden 7 gün önce sona ereceğini hatırlatmak için size bir mektup gönderecektir. E-posta ayrıca "Otomatik Yenileme Hizmetinin" nasıl kapatılacağına ilişkin talimatları da içerecektir.`,
        faq_info_5:`Sipariş otomatik olarak işlendiğinden siparişin iptaline izin verilmez. Ayrıca ücretsiz deneme sürümü, Aiseesoft programı hakkında daha fazla bilgi edinmenizi ve satın almadan önce ihtiyaçlarınızı karşılayıp karşılamadığını kontrol etmenizi sağlayacaktır.`,
        faq_info_6:`Gerçekten geri ödeme almak istiyorsanız lütfen <a href="https://tr.aiseesoft.com/refund-policy.html">Geri Ödeme Politikamıza</a> bakın.`,
        faq_info_7:`Bize bir inceleme göndermek için ayırdığınız zaman ve dikkat için teşekkür ederiz. İncelemenizi göndermek için bizimle (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) iletişime geçin; ürün güncellemelerimiz için incelemenizi ciddi olarak değerlendireceğiz.`,
        change_password_success:`parola başarıyla değiştirildi`,
        successful_login_title:`Giriş başarılı`,
        product_page:`Ürün Sayfası>>`,
        successful_login_info:`Giriş tamamlandı. Lütfen mevcut sayfayı kapatın ve aşağıdaki işleme devam etmek için orijinal sekmeye dönün. Geçerli sayfa 5 saniye içinde otomatik olarak kapatılacaktır. "Bitti" düğmesine tıklayarak otomatik kapanma veya kapanma başarısız olursa, lütfen bu sekmeyi doğrudan kapatın.`,
        successful_login_info_firefox:`Giriş tamamlandı. Lütfen mevcut sayfayı kapatın ve aşağıdaki işleme devam etmek için orijinal sekmeye dönün.`,
        my_account:`Hesabım`,
        my_history:`Benim tarihim`,
        remove_watermark:`Filigranı Kaldır`,
        no_history:`Geçmiş Yok`,
        history_all:`Hepsini seç`,
        history_open:`Açık`,
        history_down:`İndirmek`,
        history_delete:`Silmek`,
        history_clear:`Geçersizi temizle`,
        images:`Görüntüler)`,
        use_this_function:`Bu İşlevi Kullan >>`,
        hd_downloading:`HD orijinal görüntüleri indirme avantajları:`,
        lifetimeRemaining:`Ömür boyu-Kalan`,
        Remaining:`Geriye kalan`,
        email_verification:`Eposta Doğrulama`,
        email_verification_info:`Doğrulama mesajını <span class="email"></span> e-posta adresinize gönderdik ve lütfen doğrulamayı tamamlayın. Doğrulamadan sonra, avantajlar otomatik olarak senkronize edilecektir.`,
        wrong_email:`Yanlış elektronik posta adresi?`,
        click_here_to_modify:`Değiştirmek için buraya tıklayın`,
        get_more_help:`Daha fazla yardım mı alıyorsunuz?`,
        click_here:`Buraya Tıkla`,
        email_verification_info_success:`E-posta hesabınızı doğruladığınız için tebrikler.`,
        email_verification_info_error:`Süresi dolmuş bağlantı nedeniyle doğrulama başarısız oldu.`,
        registration_succeeded:`Kayıt Başarılı`,
        registration_succeeded_info_1:`Tebrikler! Başarıyla kayıt oldunuz. Doğrulama mesajını <span class="email"></span> e-posta adresinize gönderdik ve bu e-postanın avantajlarından yararlanmak için lütfen doğrulama işlemini tamamlayın.`,
        registration_succeeded_info_2:`Ana sayfaya dönmek ve bu ürünü kullanmak için "Bitti"ye tıklayın.`,
        registration_succeeded_info_3:`Mevcut sayfayı kapatmak ve aşağıdaki işlemler için Ana sayfaya dönmek için "Bitti"ye tıklayın. Kapatma başarısız olursa, lütfen sekmeyi manuel olarak kapatın.`,
        verify_email:`E-mail'i doğrula`,
        registered_email_not_verified:`Kayıtlı e-posta doğrulanmadı, lütfen bir kerede doğrulayın.`,
        email_verification_time_1:`Doğrulama e-postası almadınız mı?`,
        email_verification_time_2:`Sonrasında`,
        email_verification_time_3:`Yeniden göndermek için buraya tıklayın`,
        error_26301:`Hata kodu: 26301, lütfen bize ulaşın`,
        error_26302:`Hata kodu: 26302, lütfen bize ulaşın`,
        error_26303:`E-posta biçimi hatası (hata kodu: 26303). Lütfen tekrar girin`,
        error_26304:`8 karakterden uzun şifre önerilir (hata kodu: 26304)`,
        error_26305:`Reuqest başarısız oldu (hata kodu: 26305). lütfen tekrar deneyin`,
        error_26306:`E-posta kaydedildi, lütfen <a href="https://account.aiseesoft.com/login">giriş yapmak için gidin</a>`,
        error_26307:`Reuqest başarısız oldu (hata kodu: 26307). lütfen tekrar deneyin`,
        error_26308:`Reuqest başarısız oldu (hata kodu: 26308). lütfen tekrar deneyin`,
        error_26401:`Hata kodu: 26401, lütfen tekrar deneyin`,
        error_26402:`E-posta doğrulandı (hata kodu: 26402), lütfen tekrar deneyin`,
        error_26403:`Reuqest başarısız oldu (hata kodu: 26403). lütfen tekrar deneyin`,
        error_26404:`İstek başarısız oldu (hata kodu: 26404). lütfen tekrar deneyin`,
        error_26501:`Hata kodu: 26501, lütfen bize ulaşın`,
        error_26502:`Hata kodu: 26502, lütfen bize ulaşın`,
        error_26503:`E-posta biçimi hatası (hata kodu: 26503). Lütfen tekrar girin`,
        error_26504:`Reuqest başarısız oldu (hata kodu: 26504). lütfen tekrar deneyin`,
        error_26505:`E-posta kaydedilmedi, lütfen <a href="https://account.aiseesoft.com/register">önce onu kaydedin</a>`,
        error_26506:`E-posta doğrulandı.`,
        error_26507:`Reuqest başarısız oldu (hata kodu: 26507). lütfen tekrar deneyin`,
        error_26508:`Doğrulama gerçekleşti (hata kodu: 26508), lütfen tekrar deneyin`,
        error_26509:`İstek başarısız oldu (hata kodu: 26509), lütfen tekrar deneyin`,
        error_26510:`Hata kodu: 26510, lütfen bize ulaşın`,
        error_26601:`Hata kodu: 26601, lütfen bize ulaşın`,
        error_26602:`Hata kodu: 26602, lütfen bize ulaşın`,
        error_26603:`Reuqest başarısız oldu (hata kodu: 26603). lütfen tekrar deneyin`,
        error_26604:`Hata kodu: 26604, lütfen bize ulaşın`,
        error_26605:`Hata kodu: 26605, lütfen bize ulaşın`,
        error_26701:`Hata kodu: 26701, lütfen bize ulaşın`,
        error_26702:`Reuqest başarısız oldu (hata kodu: 26702). lütfen tekrar deneyin`,
        error_26703:`Hata kodu: 26703, lütfen bize ulaşın`,
        error_26704:`Hata kodu: 26704, lütfen bize ulaşın`,
        error_26705:`Oturum açmayı bekleyin (hata kodu: 26705). lütfen tekrar deneyin`,
        no_cookie:`Tarayıcınızda Tüm çerezleri engelle işlevini açtınız, bu nedenle giriş yapamazsınız. Lütfen Ayarlar'a giderek Tüm çerezlere izin ver kutusunu işaretleyin.`,
        error_26801:`Hata kodu: 26801, lütfen bize ulaşın`,
        error_26802:`Hata kodu: 26802, lütfen bize ulaşın`,
        error_26803:`İstek başarısız oldu (hata kodu: 26803). lütfen tekrar deneyin`,
        error_26804:`İstek başarısız oldu (hata kodu: 26804). lütfen tekrar deneyin`,
        error_order:`İstek başarısız oldu (hata kodu: 27098), lütfen tekrar deneyin!`,
        error_order1:`Sipariş sorgusu eksik; hata kodu:`,
        error_order2:`）, lütfen yenileyin ve tekrar deneyin.`,
        modify_email_title:`E-postayı Değiştir`,
        modify_email_info:`Hesabınıza giriş yapmak için değiştirilen e-postayı kullanabilirsiniz.`,
        images_per:`Görüntü başına`,
        error_26101:`Hata: 26101. Lütfen bize ulaşın`,
        error_26102:`Hata: 26102. Lütfen bize ulaşın`,
        error_26103:`İstek başarısız oldu (hata kodu:26103). Lütfen tekrar deneyin`,
        error_26104:`Hata kodu: 26104, lütfen tekrar deneyin`,
        error_26105:`Hata kodu: 26105, lütfen tekrar deneyin`,
        error_26106:`Silme başarısız oldu (hata kodu: 26106). Lütfen tekrar deneyin`,
        error_26201:`Hata: 26201. Lütfen bizimle iletişime geçin`,
        error_26202:`İstek başarısız oldu (hata kodu:26202). Lütfen tekrar deneyin`,
        error_26001:`Hata: 26001. Lütfen bize ulaşın`,
        error_26002:`Hata: 26002. Lütfen bize ulaşın`,
        error_26003:`Hata: 26003. Lütfen bizimle iletişime geçin`,
        error_26004:`Hata: 26004. Lütfen bize ulaşın`,
        error_26005:`İstek başarısız oldu (hata kodu:26005). Lütfen tekrar deneyin`,
        error_26006:`Hata kodu: 26006, lütfen tekrar deneyin`,
        error_26008:`Hata: 26008. Lütfen bizimle iletişime geçin`,
        go_to_the_home_page:`Ana sayfaya git`,
        error_27101:`İstek başarısız oldu (hata kodu: 27101). lütfen tekrar deneyin`,
        error_27201:`Hata kodu: 27201, lütfen bize ulaşın`,
        error_27202:`Hata kodu: 27202, lütfen tekrar deneyin`,
        error_27203:`İstek başarısız oldu (hata kodu: 27203). lütfen tekrar deneyin`,
        error_27204:`Geçersiz kod (hata kodu: 27204).`,
        error_27205:`İstek başarısız oldu (hata kodu: 27205). lütfen tekrar deneyin`,
        error_27206:`İstek başarısız oldu (hata kodu: 27206). lütfen tekrar deneyin`,
        error_27207:`İstek başarısız oldu (hata kodu: 27207). lütfen tekrar deneyin`,
        no_history_found:`Herhangi bir araç kullanmadınız! <a href="/">Yenile</a> veya <a href="https://tr.aiseesoft.com/">Resmi web sitesine gidin</a>`,
        error_25301:`Hata: 25301. Lütfen bizimle iletişime geçin`,
        error_25302:`Hata: 25302. Lütfen bize ulaşın`,
        error_25303:`İstek başarısız oldu (hata kodu: 25303). Lütfen tekrar deneyin`,
        error_25304:`İstek başarısız oldu (hata kodu: 25304). Lütfen tekrar deneyin`,
        error_25305:`İstek başarısız oldu (hata kodu: 25305). Lütfen tekrar deneyin`,
        error_25306:`İstek başarısız oldu (hata kodu: 25306). Lütfen tekrar deneyin`,
        image_upscaler_p:`Filigransız resim indirme avantajları:`,
        Available_for:`İçin uygun:`,
        credit_per:`HD görüntü başına %s kredi`,
        still_valid:`Satın alınan plan(lar) hala geçerlidir`,
        credit:`kredi)`,
        pc_3rd_info:`Giriş başarılı. Daha fazla işlem için lütfen uygulamaya gidin.`,
        use_online:`Çevrimiçi hizmeti kullanın`,
        use_download:`Masaüstü programını kullanın`,
        use_immediately:`Hemen Kullanın`,
        Use_in_browser:`Tarayıcıda Kullan`,
        win_desktop:`pencereler`,
        Mac_desktop:`Mac`,
    },
    es:{
        overtime_1:`Código de error:`,
        overtime_2:`, por favor vuelve a intentarlo`,
        isnetwork:`Error de Internet. Por favor verifique y vuelva a intentarlo`,
        email_placeholder:`Correo electrónico`,
        email_empty:`Por favor ingrese el correo electrónico`,
        email_not_valid:`El correo no es válido`,
        email_not_valid_1:`Por favor ingrese su correo electrónico`,
        email_not_valid_2:`El correo electrónico no es válido, utilice una dirección diferente.`,
        email_not_valid_3:`Sin entrada de correo electrónico`,
        password_placeholder:`Contraseña`,
        password_empty:`Por favor ingrese la contraseña`,
        password_not_valid:`Cuenta o contraseña incorrecta`,
        password_not_valid_1:`Se requiere una contraseña de más de 8 caracteres`,
        password_not_valid_2:`Por favor crea una contraseña`,
        password_placeholder_1:`Crea tu contraseña`,
        password_placeholder_2:`Confirmar la contraseña`,
        password_placeholder_3:`Crear nueva contraseña`,
        password_placeholder_4:`Confirmar nueva contraseña`,
        password_placeholder_5:`Ingrese la contraseña anterior`,
        copy_password_empty:`Por favor confirme la contraseña`,
        copy_password_not_valid:`Por favor, confirme su contraseña`,
        copy_passwords_inconsistent:`Su confirmación de contraseña no coincide`,
        code_empty:`Por favor ingrese el código de verificación`,
        code_not_valid:`código de verificación invalido`,
        code_placeholder:`Código de verificación`,
        not_received_code:`Si su buzón no ha recibido el código de verificación durante mucho tiempo, obtenga el código de verificación nuevamente.`,
        get_first_code:`Primero obtenga el código de verificación.`,
        connect_your_email_placeholder:`Conecta tu correo electrónico`,
        last_name_placeholder:`Por favor ingrese su apellido`,
        first_name_placeholder:`Por favor, introduzca su nombre de pila`,
        address_placeholder:`Por favor ingrese su dirección`,
        no_code_text:`Hemos enviado un código de verificación. Por favor, introduzca su código. <span class='tips'>¿No recibió un código?`,
        no_code_text_1:`1. Asegúrese de que la dirección de correo electrónico sea válida y pueda recibir correos electrónicos.`,
        no_code_text_2:`2. Para que el sistema envíe el correo electrónico automáticamente, se puede marcar como spam o correo no deseado. Compruebe si el correo electrónico está en la carpeta Papelera.`,
        no_code_text_3:`3. ¿No puede resolver su problema?`,
        no_code_text_3_span:`Entonces haz clic aquí para contactarnos.`,
        order_no:`No has comprado ningún producto, <a href="https://www.aiseesoft.es/store/">¡compra ahora</a>! Si tiene alguna pregunta, <a href="https:/www.aiseesoft.es/contact.html">contáctenos</a>.`,
        error_24901:`La cuenta actual no tiene un correo electrónico vinculado y no puede encontrar los pedidos. Por favor conecte un correo electrónico.`,
        user_guide:`Guía del usuario>>`,
        download:`Descargar`,
        order_number:`Número de orden:`,
        Refund:`Reembolso`,
        Disabled:`Desactivado`,
        Normal:`Normal`,
        Modify:`Modificar`,
        Modify_1:`Modificar>>`,
        Connect:`Conectar`,
        unlink_success:`Desvincular con éxito`,
        connect_success:`Conectar con éxito`,
        feedback_title:`Gracias por tus comentarios. Deje su problema y le responderemos dentro de las 24 horas.`,
        feedback_thank_you:`¡Gracias!<br />Sus comentarios se han enviado correctamente.`,
        feedback_email:`¡Introduzca su correo electrónico aquí!`,
        feedback_content:`Deje cualquier problema o sugerencia que haya encontrado aquí.`,
        feedback_submit:`Entregar`,
        form_contents:`No has introducido ninguna descripción. Por favor, introdúzcalo y envíelo de nuevo.`,
        old_password:`Por favor ingrese la contraseña anterior`,
        new_password:`Por favor crea una nueva contraseña`,
        old_new_password:`La nueva contraseña no puede ser la misma que la anterior`,
        incorrect_password:`Contraseña incorrecta`,
        delete_no:`Elimina ahora`,
        Caps:`Bloqueo de mayúsculas está activado`,
        Get:`Conseguir`,
        Done:`Hecho`,
        error_20001:`Error: 20001. Vuelva a iniciar sesión.`,
        error_20002:`Error: 20002. Vuelva a iniciar sesión.`,
        error_20003:`Error: 20003. Vuelva a iniciar sesión.`,
        error_20004:`Solicitud fallida (código de error: 20004). Inténtalo de nuevo.`,
        error_20005:`La sesión de inicio de sesión ha caducado (Error: 20005). Inicie sesión de nuevo.`,
        error_20006:`Solicitud fallida (código de error: 20006). Inténtalo de nuevo.`,
        error_20007:`La sesión de inicio de sesión ha caducado (Error: 20007). Inicie sesión de nuevo.`,
        error_20008:`La sesión de inicio de sesión ha caducado (Error: 20008). Inicie sesión de nuevo.`,
        error_20009:`La sesión de inicio de sesión ha caducado (Error: 20009). Inicie sesión de nuevo.`,
        error_20101:`Ingrese su correo electrónico (código de error: 20101)`,
        error_20102:`El correo electrónico no es válido (código de error: 20102)`,
        error_20103:`Solicitud fallida (código de error: 20103). Inténtalo de nuevo`,
        error_20104:`El correo electrónico ya está en uso, por favor <a href="https://account.aiseesoft.com/login">inicie sesión</a> o regístrese con uno nuevo`,
        error_20105:`Solicitud fallida (código de error: 20105). Inténtalo de nuevo`,
        error_20106:`No se pudo enviar el correo electrónico, por favor vuelva a intentarlo`,
        error_20201:`Ingrese su correo electrónico (código de error: 20201)`,
        error_20202:`Ingrese su contraseña (código de error: 20202)`,
        error_20203:`Ingrese el código de verificación (código de error: 20203)`,
        error_20204:`El correo electrónico no es válido (código de error: 20204)`,
        error_20205:`Se requiere una contraseña de más de 8 caracteres (código de error: 20205)`,
        error_20206:`Solicitud fallida (código de error: 20206). Inténtalo de nuevo`,
        error_20207:`código de verificación invalido`,
        error_20208:`Solicitud fallida (código de error: 20208). Inténtalo de nuevo`,
        error_20209:`Solicitud fallida (código de error: 20209). Inténtalo de nuevo`,
        error_20301:`Ingrese su correo electrónico (código de error: 20301)`,
        error_20302:`Error: 20302. Por favor contáctenos`,
        error_20303:`El correo electrónico no es válido (código de error: 20303)`,
        error_20304:`Solicitud fallida (código de error: 20304). Inténtalo de nuevo`,
        error_20305:`La cuenta no existe. Vuelva a ingresar o <a href="https://account.aiseesoft.com/register">Créelo</a> primero.`,
        error_20306:`Sin contraseña todavía. Use <a href="https://account.aiseesoft.com/passwordless-login">Inicio de sesión sin contraseña</a> o <a href="create-password">establezca una contraseña</a> e inicie sesión.`,
        error_20308:`Solicitud fallida (código de error: 20308). Inténtalo de nuevo`,
        error_20401:`Error al cerrar sesión (código de error: 20401). Inténtalo de nuevo`,
        error_20501:`Ingrese su correo electrónico (código de error: 20501)`,
        error_20502:`El correo electrónico no es válido (código de error: 20502)`,
        error_20503:`Solicitud fallida (código de error: 20503). Inténtalo de nuevo`,
        error_20504:`No se pudo enviar el correo electrónico. Inténtalo de nuevo.`,
        error_20601:`Ingrese su correo electrónico (código de error: 20601)`,
        error_20602:`Ingrese el código de verificación (código de error: 20602)`,
        error_20603:`El correo electrónico no es válido (código de error: 20603)`,
        error_20604:`Solicitud fallida (código de error: 20604). Inténtalo de nuevo`,
        error_20606:`Solicitud fallida (código de error: 20606). Inténtalo de nuevo`,
        error_20607:`Solicitud fallida (código de error: 20607). Inténtalo de nuevo`,
        error_20608:`Solicitud fallida (código de error: 20608). Inténtalo de nuevo`,
        error_20701:`Ingrese su correo electrónico (código de error: 20701)`,
        error_20702:`El correo electrónico no es válido (código de error: 20702)`,
        error_20703:`Solicitud fallida (código de error: 20703). Inténtalo de nuevo`,
        error_20704:`La cuenta no existe. Vuelva a ingresar o <a href="https://account.aiseesoft.com/register">Créelo</a> primero.`,
        error_20705:`Solicitud fallida (código de error: 20705). Inténtalo de nuevo`,
        error_20706:`No se pudo enviar el correo electrónico. Inténtalo de nuevo`,
        error_20801:`Ingrese su correo electrónico (código de error: 20801)`,
        error_20802:`Error: 20802. Por favor contáctenos`,
        error_20803:`Ingrese el código de verificación (código de error: 20803)`,
        error_20804:`El correo electrónico no es válido (código de error: 20804)`,
        error_20805:`Se requiere una contraseña de más de 8 caracteres (código de error: 20805)`,
        error_20806:`Solicitud fallida (código de error: 20806). Inténtalo de nuevo`,
        error_20808:`Solicitud fallida (código de error: 20808). Inténtalo de nuevo`,
        error_20901:`Solicitud fallida (código de error: 20901). Inténtalo de nuevo`,
        error_20902:`Solicitud fallida (código de error: 20902). Inténtalo de nuevo`,
        error_21000:`Los cambios se guardan`,
        error_21001:`No se envía información (código de error: 21001)`,
        error_21002:`Solicitud fallida (código de error: 21002). Inténtalo de nuevo`,
        error_21101:`Ingrese su correo electrónico (código de error: 21101)`,
        error_21102:`El correo electrónico no es válido (código de error: 21102)`,
        error_21103:`Solicitud fallida (código de error: 21103), por favor vuelva a intentarlo`,
        error_21104:`El correo electrónico ya está conectado, utilice uno nuevo`,
        error_21105:`Solicitud fallida (código de error: 21105), por favor vuelva a intentarlo`,
        error_21106:`No se pudo enviar el correo electrónico. Inténtalo de nuevo`,
        error_21201:`Ingrese su correo electrónico (código de error: 21201)`,
        error_21202:`Ingrese el código de verificación (código de error: 21202)`,
        error_21203:`El correo electrónico no es válido (código de error: 21203)`,
        error_21204:`Error: 21204. Por favor contáctenos`,
        error_21205:`Error: 21205. Por favor contáctenos`,
        error_21206:`Se requiere una contraseña de más de 8 caracteres (código de error: 21206)`,
        error_21207:`Solicitud fallida (código de error: 21207). Inténtalo de nuevo`,
        error_21209:`Solicitud fallida (código de error: 21209). Inténtalo de nuevo`,
        error_21301:`Ingrese la contraseña anterior (código de error: 21301)`,
        error_21302:`Cree una nueva contraseña (código de error: 21302)`,
        error_21303:`La nueva contraseña no puede ser la misma que la anterior. (Error: 21303)`,
        error_21304:`Se requiere una contraseña de más de 8 caracteres (código de error: 21304)`,
        error_21306:`Solicitud fallida (código de error: 21306). Inténtalo de nuevo`,
        error_21402:`Solicitud fallida (código de error: 21402). Inténtalo de nuevo`,
        error_21403:`No se pudo enviar el código de verificación. por favor reenvialo`,
        error_21500:`La cuenta ha sido eliminada`,
        error_21501:`Ingrese el código de verificación (código de error: 21501)`,
        error_21502:`La sesión de inicio de sesión ha caducado (Error: 21502). Inicie sesión de nuevo.`,
        error_21503:`Solicitud fallida (código de error: 21503). Inténtalo de nuevo`,
        error_21505:`Solicitud fallida (código de error: 21505), por favor vuelva a intentarlo`,
        error_21601:`Error: 20601. Por favor contáctenos`,
        error_21602:`Verificación no válida (Error: 20602). Inténtalo de nuevo.`,
        error_21603:`Error: 20603. Vuelva a intentarlo.`,
        error_21604:`Solicitud fallida (código de error: 21604). Inténtalo de nuevo`,
        error_21606:`Solicitud fallida (código de error: 21606). Inténtalo de nuevo`,
        error_21611:`Solicitud fallida (código de error: 21611). Inténtalo de nuevo`,
        error_21801:`Error: 21801. Por favor contáctenos`,
        error_21802:`Solicitud fallida (Error: 21802). Inténtalo de nuevo`,
        error_21803:`Error: 21803. Vuelva a intentarlo.`,
        error_21804:`Solicitud fallida (código de error: 21804). Inténtalo de nuevo`,
        error_21806:`Error: 21806. Vuelva a intentarlo.`,
        error_21807:`Error: 21807. Por favor contáctenos`,
        error_21808:`Error: 21808. Por favor contáctenos`,
        error_21809:`Error: 21809. Por favor contáctenos`,
        error_21810:`Error: 21810. Por favor contáctenos`,
        error_21811:`Error: 21811. Por favor contáctenos`,
        error_21812:`Error: 21812. Por favor contáctenos`,
        error_21813:`Solicitud fallida (código de error: 21813). Inténtalo de nuevo`,
        error_21814:`Error: 21814. Por favor contáctenos`,
        error_21815:`Solicitud fallida (código de error: 21815). Inténtalo de nuevo`,
        error_21816:`Error: 21816. Por favor contáctenos`,
        error_21817:`Error: 21817. Por favor contáctenos`,
        error_21818:`Error: 21818. Por favor contáctenos`,
        error_21819:`Solicitud fallida (código de error: 21819). Inténtalo de nuevo`,
        error_21820:`Error: 21820. Por favor contáctenos`,
        error_21821:`Error: 21821. Por favor contáctenos`,
        error_21822:`Error: 21822. Por favor contáctenos`,
        error_21823:`Solicitud fallida (código de error: 21823). Inténtalo de nuevo`,
        error_21824:`Solicitud fallida (código de error: 21824). Inténtalo de nuevo`,
        error_21825:`Solicitud fallida (código de error: 21825). Inténtalo de nuevo`,
        error_21826:`Solicitud fallida (código de error: 21826). Inténtalo de nuevo`,
        error_21828:`Error: 21828. Por favor contáctenos`,
        error_21901:`Error: 21901. Por favor contáctenos`,
        error_21902:`Solicitud fallida (código de error: 21902). Inténtalo de nuevo`,
        error_21903:`El estado de la cuenta ha cambiado (código de error: 21903), actualice la página e intente nuevamente`,
        error_21904:`Error: 21904. Vuelva a intentarlo.`,
        error_21905:`Error: 21905. Vuelva a intentarlo.`,
        error_21906:`Solicitud fallida (código de error: 21906). Inténtalo de nuevo`,
        error_21907:`La cuenta de Google se ha vinculado a otra cuenta`,
        error_21908:`Solicitud fallida (código de error: 21908). Inténtalo de nuevo`,
        error_22001:`Solicitud fallida (código de error: 22001). Inténtalo de nuevo`,
        error_22002:`Error al desvincular sin inicio de sesión adicional`,
        error_22003:`Solicitud fallida (código de error: 22003). Inténtalo de nuevo`,
        error_22101:`Error: 22101. Por favor contáctenos`,
        error_22102:`El estado de la cuenta ha cambiado (código de error: 22102), actualice la página e intente nuevamente`,
        error_22103:`Solicitud fallida (código de error: 22103). Inténtalo de nuevo`,
        error_22104:`El estado de la cuenta ha cambiado (código de error: 22104), actualice la página e intente nuevamente`,
        error_22105:`Error: 22105. Vuelva a intentarlo.`,
        error_22106:`Error: 22106. Vuelva a intentarlo.`,
        error_22107:`Error: 22107. Por favor contáctenos`,
        error_22108:`Solicitud fallida (código de error: 22108). Inténtalo de nuevo`,
        error_22201:`Error: 22201. Por favor contáctenos`,
        error_22202:`Verificación no válida (Error: 22202). Inténtalo de nuevo.`,
        error_22203:`Error: 22203. Vuelve a intentarlo"`,
        error_22204:`Solicitud fallida (código de error: 22204). Inténtalo de nuevo`,
        error_22206:`Solicitud fallida (código de error: 22206). Inténtalo de nuevo`,
        error_22401:`Error: 22401. Por favor contáctenos`,
        error_22402:`Verificación no válida (Error: 22402). Inténtalo de nuevo.`,
        error_22403:`Error: 22403. Vuelva a intentarlo.`,
        error_22404:`Solicitud fallida (código de error: 22404). Inténtalo de nuevo`,
        error_22405:`La cuenta de Facebook se ha vinculado a otro correo electrónico`,
        error_22406:`Error: 22406. Vuelva a intentarlo.`,
        error_22407:`Error: 22407. Por favor contáctenos`,
        error_22408:`Error: 22408. Por favor contáctenos`,
        error_22409:`Error: 22409. Por favor contáctenos`,
        error_22410:`Error: 224010. Por favor contáctenos`,
        error_22411:`Error: 224011. Por favor contáctenos`,
        error_22412:`Error: 224012. Por favor contáctenos`,
        error_22413:`Error: 22413. Por favor contáctenos`,
        error_22414:`Solicitud fallida (código de error: 22414). Inténtalo de nuevo`,
        error_22415:`Error: 22415. Por favor contáctenos`,
        error_22416:`Error: 22416. Por favor contáctenos`,
        error_22417:`Error: 22417. Por favor contáctenos`,
        error_22418:`Solicitud fallida (código de error: 22418). Inténtalo de nuevo`,
        error_22419:`Error: 22419. Por favor contáctenos`,
        error_22420:`Error: 22420. Por favor contáctenos`,
        error_22421:`Error: 22421. Por favor contáctenos`,
        error_22422:`Solicitud fallida (código de error: 22422). Inténtalo de nuevo`,
        error_22423:`Solicitud fallida (código de error: 22423). Inténtalo de nuevo`,
        error_22424:`Solicitud fallida (código de error: 22424). Inténtalo de nuevo`,
        error_22425:`Solicitud fallida (código de error: 22425). Inténtalo de nuevo`,
        error_20098:`Código de error: 20098. Si está navegando en modo Privado, cambie al modo Normal y vuelva a intentarlo.`,
        error_22298:`La solicitud de Google falló (Error: 22298). Inténtalo de nuevo.`,
        error_22498:`La solicitud de Facebook falló (Error: 22498). Inténtalo de nuevo.`,
        error_24902:`Solicitud fallida (código de error: 24902). Inténtalo de nuevo`,
        error_24903:`Solicitud fallida (código de error: 24903). Inténtalo de nuevo`,
        error_24904:`Solicitud fallida (código de error: 24904). Inténtalo de nuevo`,
        error_24905:`Solicitud fallida (código de error: 24905). Inténtalo de nuevo`,
        login_title:`Iniciar sesión en Aiseesoft`,
        password_placeholder:`Contraseña`,
        log_in:`Acceso`,
        no_account:`¿Sin cuenta?`,
        create_it:`créalo`,
        or_log_in_with:`O inicia sesión con`,
        passwordless_login:`Inicio de sesión sin contraseña`,
        log_in_done:`Iniciar sesión Listo`,
        three_rd_account_connect_info:`¡Felicidades! Has iniciado sesión correctamente. Ahora puede conectar una cuenta de correo electrónico que se utiliza para iniciar sesión en el futuro`,
        connect_now:`Conecte ahora`,
        see_my_account:`ver mi cuenta`,
        three_rd_login_merge_account:`La dirección de correo electrónico de la cuenta de terceros se ha registrado, ¿desea conectarse e iniciar sesión con esta dirección de correo electrónico directamente?`,
        connect_log_in:`Conectar e iniciar sesión`,
        create_an_account:`Crea una cuenta`,
        connect_account:`Conectar cuenta`,
        connect_account_info:`Después de conectarse, puede iniciar sesión con esta cuenta.`,
        back_to_log_in:`Atrás para iniciar sesión`,
        create_password:`Crear contraseña`,
        create_now:`Crea ahora`,
        password_login_subtitle:`Inicio de sesión sin contraseña con correo electrónico`,
        account_login:`Cuenta de Ingreso`,
        rights:`Al crear esta cuenta, acepta los <a href="https://www.aiseesoft.es/company/terms-and-conditions.html">Términos de servicio</a> y <ahref="https:// www.aiseesoft.es/privacy-policy.html">Política de privacidad</ahref=>`,
        passwordless_login_done:`Inicio de sesión sin contraseña realizado`,
        passwordless_login_info:`Enhorabuena, ha completado correctamente el inicio de sesión sin contraseña. Puede crear una contraseña para esta cuenta e iniciar sesión con la cuenta y la contraseña en el futuro. <a href="/create-password" style="display: initial;">Crear ahora</a>`,
        sign_up:`Inscribirse`,
        register_info:`Crea tu cuenta`,
        reset_now:`Restablecer ahora`,
        forgot_password:`Has olvidado tu contraseña`,
        reset_password_subtitle:`Use el correo electrónico de su cuenta para restablecer la contraseña`,
        plan_products:`Planes y productos`,
        nick_name:`Nombre de usuario:`,
        email:`Correo electrónico:`,
        my_products:`Mis productos`,
        my_orders:`Mis ordenes`,
        unlink:`Desconectar`,
        link:`Enlace`,
        connected_accounts:`Cuentas conectadas`,
        last_name:`Apellido:`,
        first_name:`Nombre de pila:`,
        Gender:`Género:`,
        Birth:`Nacimiento:`,
        Month:`Mes`,
        Year:`Año`,
        Country_Region:`País/Región:`,
        Address:`DIRECCIÓN:`,
        Save:`Ahorrar`,
        Date:`Fecha`,
        Male:`Masculino`,
        Female:`Femenino`,
        Unspecified:`sin especificar`,
        Security:`Seguridad`,
        change_password:`Cambiar la contraseña`,
        change_now:`Cambia ahora`,
        connect_email:`Conectar correo electrónico`,
        delete_account:`Borrar cuenta`,
        delete_account_info:`Cuando se elimine su cuenta, todos los datos en Plan y productos asociados con su cuenta se eliminarán de forma permanente y es posible que no pueda recuperarlos. Te recomendamos andar con cautela.`,
        Delete:`Borrar`,
        Logout:`Cerrar sesión`,
        my_profile:`Mi perfil`,
        guides_faqs:`Guías y preguntas frecuentes`,
        More:`Más`,
        guides:`Guías`,
        register:`registro`,
        hot_faq:`Preguntas más frecuentes`,
        Contents:`Contenido:`,
        contact_us:`Contáctenos>>`,
        plan:`Plan`,
        unregistered:`No registrado`,
        buy_more:`Compra más`,
        buy_again:`Compra otra vez`,
        buy_now:`Comprar ahora`,
        free_no_limit:`Gratis y sin límite`,
        expired:`Venció`,
        lifetime:`Toda la vida`,
        remain:`Permanecer`,
        day_s:`Días)`,
        error_24801:`Solicitud fallida (código de error: 24801). Inténtalo de nuevo`,
        no_app_found:`¡No se encontró ninguna aplicación! <a href="/">Actualizar</a> o <a href="https://www.aiseesoft.es/">Ir al sitio web oficial</a>`,
        get_more:`Obtener más >>`,
        edit_photo:`Editar foto`,
        select_photo:`Seleccione Foto`,
        change_photo:`Cambiar foto`,
        cancel:`Cancelar`,
        hide_password:`Contraseña oculta`,
        show_password:`Mostrar contraseña`,
        zoom_in:`Acercarse`,
        zoom_out:`Disminuir el zoom`,
        rotate:`Girar`,
        horizontal_flip:`Volteo horizontal`,
        vertical_flip:`Volteo vertical`,
        country:`País`,
        country_1:`Seleccione su país/región`,
        country_2:`Isla de Åaland`,
        country_3:`Afganistán`,
        country_4:`Albania`,
        country_5:`Argelia`,
        country_6:`Samoa Americana`,
        country_7:`Andorra`,
        country_8:`angola`,
        country_9:`anguila`,
        country_10:`Antártida`,
        country_11:`Antigua y Barbuda`,
        country_12:`Argentina`,
        country_13:`Armenia`,
        country_14:`Aruba`,
        country_15:`Australia`,
        country_16:`Austria`,
        country_17:`Azerbaiyán`,
        country_18:`Baréin`,
        country_19:`bangladesh`,
        country_20:`barbados`,
        country_21:`Bielorrusia`,
        country_22:`Bélgica`,
        country_23:`Belice`,
        country_24:`Benín`,
        country_25:`islas Bermudas`,
        country_26:`Bután`,
        country_27:`Bolivia`,
        country_28:`Bosnia y Herzegovina`,
        country_29:`Botsuana`,
        country_30:`Isla Bouvet`,
        country_31:`Brasil`,
        country_32:`Territorio Británico del Océano Índico`,
        country_33:`Islas Vírgenes Británicas`,
        country_34:`Brunéi`,
        country_35:`Bulgaria`,
        country_36:`Burkina Faso`,
        country_37:`Burundi`,
        country_38:`Camboya`,
        country_39:`Camerún`,
        country_40:`Canadá`,
        country_41:`Cabo Verde`,
        country_42:`Caribe Países Bajos`,
        country_43:`Islas Caimán`,
        country_44:`República Centroafricana`,
        country_45:`Chad`,
        country_46:`Chile`,
        country_47:`Porcelana`,
        country_48:`Isla de Navidad`,
        country_49:`Islas Cocos (Keeling)`,
        country_50:`Colombia`,
        country_51:`Islas Cook`,
        country_52:`Costa Rica`,
        country_53:`Costa de Marfil`,
        country_54:`Croacia`,
        country_55:`Cuba`,
        country_56:`Chipre`,
        country_57:`República Checa`,
        country_58:`República Democrática del Congo`,
        country_59:`Dinamarca`,
        country_60:`Yibuti`,
        country_61:`Dominica`,
        country_62:`República Dominicana`,
        country_63:`Ecuador`,
        country_64:`Egipto`,
        country_65:`El Salvador`,
        country_66:`Guinea Ecuatorial`,
        country_67:`Eritrea`,
        country_68:`Estonia`,
        country_69:`Etiopía`,
        country_70:`Islas Malvinas`,
        country_71:`Islas Faroe`,
        country_72:`Estados Federados de Micronesia`,
        country_73:`Fiyi`,
        country_74:`Finlandia`,
        country_75:`Francia`,
        country_76:`Guayana Francesa`,
        country_77:`Polinesia francés`,
        country_78:`Territorios Franceses del Sur`,
        country_79:`Gabón`,
        country_80:`Gambia`,
        country_81:`Georgia`,
        country_82:`Alemania`,
        country_83:`Ghana`,
        country_84:`Gibraltar`,
        country_85:`Gran Bretaña (Reino Unido; Inglaterra)`,
        country_86:`Grecia`,
        country_87:`Groenlandia`,
        country_88:`Granada`,
        country_89:`Guadalupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernesey`,
        country_93:`Guinea`,
        country_94:`Guinea-Bisáu`,
        country_95:`Guayana`,
        country_96:`Haití`,
        country_97:`Isla Heard e Islas McDonald`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hungría`,
        country_101:`Islandia`,
        country_102:`India`,
        country_103:`Indonesia`,
        country_104:`Irán`,
        country_105:`Irak`,
        country_106:`Irlanda`,
        country_107:`Isla del hombre`,
        country_108:`Israel`,
        country_109:`Italia`,
        country_110:`Jamaica`,
        country_111:`Japón`,
        country_112:`Jersey`,
        country_113:`Jordán`,
        country_114:`Kazajstán`,
        country_115:`Kenia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Kirguistán`,
        country_119:`Laos`,
        country_120:`letonia`,
        country_121:`Líbano`,
        country_122:`Lesoto`,
        country_123:`Liberia`,
        country_124:`Libia`,
        country_125:`Liechtenstein`,
        country_126:`Lituania`,
        country_127:`luxemburgo`,
        country_128:`Macao`,
        country_129:`Madagascar`,
        country_130:`Malaui`,
        country_131:`Malasia`,
        country_132:`Maldivas`,
        country_133:`Malí`,
        country_134:`Malta`,
        country_135:`Islas Marshall`,
        country_136:`Martinica`,
        country_137:`Mauritania`,
        country_138:`Mauricio`,
        country_139:`Mayotte`,
        country_140:`México`,
        country_141:`Moldavia`,
        country_142:`Mónaco`,
        country_143:`Mongolia`,
        country_144:`montenegro`,
        country_145:`Montserrat`,
        country_146:`Marruecos`,
        country_147:`Mozambique`,
        country_148:`Myanmar (Birmania)`,
        country_149:`Namibia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Países Bajos`,
        country_153:`Nueva Caledonia`,
        country_154:`Nueva Zelanda`,
        country_155:`Nicaragua`,
        country_156:`Níger`,
        country_157:`Nigeria`,
        country_158:`Niue`,
        country_159:`Isla Norfolk`,
        country_160:`Corea del Norte`,
        country_161:`Islas Marianas del Norte`,
        country_162:`Noruega`,
        country_163:`Omán`,
        country_164:`Pakistán`,
        country_165:`palaos`,
        country_166:`Territorios palestinos`,
        country_167:`Panamá`,
        country_168:`Papúa Nueva Guinea`,
        country_169:`Paraguay`,
        country_170:`Perú`,
        country_171:`Islas Pitcairn`,
        country_172:`Polonia`,
        country_173:`Portugal`,
        country_174:`Puerto Rico`,
        country_175:`Katar`,
        country_176:`República de Macedonia (FYROM)`,
        country_177:`República del Congo`,
        country_178:`Reunión`,
        country_179:`Rumania`,
        country_180:`Federación Rusa`,
        country_181:`Ruanda`,
        country_182:`San Bartolomé`,
        country_183:`San Martín (Francia)`,
        country_184:`San Pedro y Miquelón`,
        country_185:`samoa`,
        country_186:`San Marino`,
        country_187:`Santo Tomé y Príncipe`,
        country_188:`Arabia Saudita`,
        country_189:`Senegal`,
        country_190:`Serbia`,
        country_191:`Seychelles`,
        country_192:`Sierra Leona`,
        country_193:`Singapur`,
        country_194:`Eslovaquia`,
        country_195:`Eslovenia`,
        country_196:`Islas Salomón`,
        country_197:`Somalia`,
        country_198:`Sudáfrica`,
        country_199:`Georgia del Sur y la Isla Sándwich del Sur`,
        country_200:`Corea del Sur`,
        country_201:`Sudán del Sur`,
        country_202:`España`,
        country_203:`Sri Lanka`,
        country_204:`Santa Elena y Dependencias`,
        country_205:`San Cristóbal y Nieves`,
        country_206:`Santa Lucía`,
        country_207:`San Vicente y las Granadinas`,
        country_208:`Sudán`,
        country_209:`Surinam`,
        country_210:`Suazilandia`,
        country_211:`Suecia`,
        country_212:`Suiza`,
        country_213:`Siria`,
        country_214:`Taiwán`,
        country_215:`Tayikistán`,
        country_216:`Tanzania`,
        country_217:`Plantilla: datos del país SJM Svalbard`,
        country_218:`Tailandia`,
        country_219:`Las Bahamas`,
        country_220:`Las Comoras`,
        country_221:`Las Filipinas`,
        country_222:`Timor-Leste (Timor Oriental)`,
        country_223:`Ir`,
        country_224:`Tokelau`,
        country_225:`tonga`,
        country_226:`Trinidad y Tobago`,
        country_227:`Túnez`,
        country_228:`Pavo`,
        country_229:`turkmenistán`,
        country_230:`Islas Turcas y Caicos`,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ucrania`,
        country_234:`Emiratos Árabes Unidos`,
        country_235:`Islas menores alejadas de los Estados Unidos`,
        country_236:`Estados Unidos de América (EE. UU.)`,
        country_237:`Islas Vírgenes de los Estados Unidos`,
        country_238:`Uruguay`,
        country_239:`Uzbekistán`,
        country_240:`Vanuatu`,
        country_241:`Ciudad del Vaticano (La Santa Sede)`,
        country_242:`Venezuela`,
        country_243:`Vietnam`,
        country_244:`Wallis y Futuna`,
        country_245:`Sahara Occidental`,
        country_246:`Yemen`,
        country_247:`Zambia`,
        country_248:`Zimbabue`,
        google_login:`Inicia sesión con Google`,
        State:`Estado`,
        Activation_code:`Código de activación`,
        Question:`Enumera todas las aplicaciones en las que has iniciado sesión`,
        Copy_complete:`copia completa`,
        footer:`Copyright © 2024 Aiseesoft Estudio. Reservados todos los derechos.`,
        store:`Almacenar`,
        resource:`Recurso`,
        support:`apoyo`,
        video_converter:`Convertidor de video`,
        multimedia:`Multimedia`,
        data_recovery:`Recuperación de datos`,
        utility:`Utilidad`,
        free_tools:`Herramientas gratuitas`,
        for_windows:`Para ventanas`,
        for_mac:`Para Mac`,
        pdf_solutions:`Soluciones PDF`,
        data_manager:`Administrador de datos`,
        other_tools:`Otras herramientas`,
        free_online_solutions:`Soluciones en línea gratuitas`,
        free_online_tools:`Herramientas gratuitas en línea`,
        desktop_freeware:`Programa gratuito de escritorio`,
        product_1:`Convertidor de video último`,
        product_2:`Convertidor de vídeo total`,
        product_3:`Convertidor MTS`,
        product_4:`Convertidor 4K`,
        product_5:`Convertidor MXF`,
        product_6:`Convertidor 3D`,
        product_7:`Mac Video Converter último`,
        product_8:`Convertidor de vídeo para Mac`,
        product_9:`Convertidor MTS para Mac`,
        product_10:`Convertidor 4K para Mac`,
        product_11:`Convertidor MXF para Mac`,
        product_12:`Convertidor 3D para Mac`,
        product_13:`Reproductor Blu-ray`,
        product_14:`Mejorador de video`,
        product_15:`Editor de video`,
        product_16:`Creador de presentaciones de diapositivas`,
        product_17:`Creador de Blu-ray`,
        product_18:`Burnova - Creador de DVD Pro`,
        product_19:`Creador de DVD`,
        product_20:`Reproductor de Blu-ray Mac`,
        product_21:`Mejorador de video para Mac`,
        product_22:`Editor de vídeo para Mac`,
        product_23:`Creador de DVD para Mac`,
        product_24:`Convertidor MP4 para Mac`,
        product_25:`Recuperación de datos de Windows`,
        product_26:`Recuperación de datos de iPhone FoneLab`,
        product_27:`Recuperación del sistema FoneLab iOS`,
        product_28:`Copia de seguridad de datos de FoneLab iOS &amp; Restaurar`,
        product_29:`Recuperación de datos de Android FoneLab`,
        product_30:`Extracción de datos de Android rotos`,
        product_31:`Copia de seguridad de datos de Android y Restaurar`,
        product_32:`Recuperación de datos de Mac`,
        product_33:`Recuperación de datos de iPhone de Mac FoneLab`,
        product_34:`Recuperación del sistema Mac FoneLab iOS`,
        product_35:`Mac FoneLab iOS Copia de seguridad de datos y amp; Restaurar`,
        product_36:`Recuperación de datos de Android Mac FoneLab`,
        product_37:`Mac Android Copia de seguridad de datos y amp; Restaurar`,
        product_38:`Convertidor de PDF último`,
        product_39:`PDF Converter Ultimate para Mac`,
        product_40:`Fusión de PDF`,
        product_41:`Convertidor de PDF a ePub para Mac`,
        product_42:`Limpiador de Mac`,
        product_43:`FoneTrans`,
        product_44:`Transferencia de WhatsApp para iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraser`,
        product_47:`Limpiador de iPhone`,
        product_48:`Convertidor de PPT a vídeo`,
        product_49:`Convertidor HEIC`,
        product_50:`Desbloqueador de iPhone`,
        product_51:`Convertidor de vídeo gratuito en línea`,
        product_52:`Convertidor de audio gratuito en línea`,
        product_53:`Compresor de video gratis en línea`,
        product_54:`Fusión de video gratis en línea`,
        product_55:`Convertidor HEIC gratuito`,
        product_56:`Compresor de imagen gratis`,
        product_57:`Eliminador de marcas de agua gratis`,
        product_58:`Compresor de PDF gratuito en línea`,
        product_59:`Mejorador de imagen gratuito en línea`,
        product_60:`Removedor de fondo gratis en línea`,
        product_61:`Convertidor de imágenes gratuito en línea`,
        product_62:`Convertidor PDF PNG gratuito en línea`,
        product_63:`Convertidor de vídeo gratuito`,
        product_64:`Convertidor de video a GIF gratuito`,
        product_65:`Recuperación de datos de Android gratis`,
        product_66:`Creador de presentaciones de diapositivas`,
        product_67:`Editor de vídeo gratuito`,
        product_68:`Visor de PDF gratuito`,
        product_69:`Grabador de pantalla`,
        product_70:`Grabador de pantalla de Mac`,
        product_71:`Grabador de pantalla gratuito en línea`,
        product_72:`Grabador de audio gratis en línea`,
        product_73:`Espejo de teléfono`,
        product_74:`AnyCoord - Cambiador de ubicación`,
        product_75:`Administrador de contraseñas de iPhone`,
        product_76:`Compresor de audio gratis en línea`,
        product_77:`Video Trimmer gratis en línea`,
        product_78:`Recortador de video gratis en línea`,
        product_79:`Rotador de video gratis en línea`,
        product_80:`Looper de video gratis en línea`,
        product_81:`Convertidor PDF JPG gratuito en línea`,
        product_81:`Convertidor de JPG gratuito en línea`,
        category_1:`Edición de fotos`,
        category_2:`Edición de video`,
        category_3:`Borrador`,
        category_4:`Grabadora multimedia`,
        posted_by:`publicado por`,
        to:`a`,
        author_1:`ashley mae`,
        author_2:`jenny ryan`,
        author_3:`nicole jones`,
        author_4:`amanda marrón`,
        article_1:`Cómo quitar texto de las imágenes de manera eficiente`,
        article_2:`Cómo aumentar el tamaño de la imagen en KB a MB sin dañar la calidad`,
        article_3:`Cómo quitar la marca de agua de Getty Images en Windows y Mac`,
        article_4:`El mejor método para convertir imágenes de baja resolución a alta resolución en línea de forma gratuita`,
        article_5:`Guía TikTok | Aquí se explica cómo hacer un collage de video en TikTok`,
        article_6:`5 formas sencillas de combinar archivos VOB que debe conocer`,
        article_7:`Cómo mejorar el video y obtener una resolución más alta fácilmente`,
        article_8:`Cómo combinar archivos MP4 en MacBook Pro/iMac/MacBook y más`,
        article_9:`2 formas simples de eliminar el historial de compras en iPhone [resuelto]`,
        article_10:`Cómo eliminar imágenes de Snapchat de forma permanente del chat y los recuerdos`,
        article_11:`Cómo eliminar las sugerencias de búsqueda y el historial de Spotlight en iPhone/iPad`,
        article_12:`[Resuelto] Cómo borrar el caché de Instagram en iPhone con 3 formas fáciles`,
        article_13:`[Resuelto] Cómo arreglar QuickTime Player que no funciona en Mac`,
        article_14:`Cómo grabar audio del sistema en Mac (Big Sur/Catalina/Mojave...)`,
        article_15:`Maneras efectivas de arreglar la grabación de pantalla de PowerPoint que no funciona`,
        article_16:`Cómo Grabar Audio con VLC [Resuelto]`,
        faq_title_1:`1. ¿Cómo reparar el error 4104?`,
        faq_title_2:`2. ¿Cuándo puedo recuperar mi dinero después de que se apruebe la solicitud de reembolso?`,
        faq_title_3:`3. ¿Qué es la "Suscripción de 1 año" y cómo desactivar el "Servicio de renovación automática"?`,
        faq_title_4:`4. ¿Puedo cancelar mi pedido después de colocar el producto Aiseesoft?`,
        faq_title_5:`5. ¿Cómo enviarle una reseña?`,
        faq_info_1:`Esto se debe a la licencia limitada que obtuvo. Adoptamos la Licencia Única en caso de que el código de registro sea robado y evitamos la violación del uso del código de registro. Entonces, el código de registro que obtuvo es una licencia única que solo se puede usar en una computadora. Si lo usa en más de una computadora, aparecerá el error 4104. No puede usarlo hasta que lo restablezcamos por usted.`,
        faq_info_2:`Si cambia o reinstala su computadora, podemos restablecerla por usted. Póngase en contacto con el servicio de atención al cliente: <a href="mailto:support@aiseesoftcom">support@aiseesoft.com</a> para solicitar que se restablezca el código de registro.`,
        faq_info_3:`Una vez que se aprueba su solicitud de reembolso, normalmente tomará de 3 a 7 días hábiles para que el crédito aparezca en su cuenta. A veces, durante las vacaciones, puede llevar más tiempo, pero no se espera que tarde más de 10 días. Si no ha recibido el reembolso 20 días después de la aprobación, comuníquese con nosotros a <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> para verificar el reembolso.`,
        faq_info_4:`El Servicio de Renovación Automática significa que si compra una Suscripción de 1 año, la plataforma de pago cargará el monto automáticamente (incluidos los impuestos aplicables) cuando expire la licencia del producto. Pero la plataforma de pago (Swreg o 2Checkout) le enviará una carta para recordarle que el pedido vencerá 7 días antes del vencimiento. El correo electrónico también incluirá instrucciones sobre cómo desactivar el "Servicio de renovación automática".`,
        faq_info_5:`Dado que el pedido se procesa automáticamente, no se permite cancelar el pedido. Además, la versión de prueba gratuita le permitirá saber más sobre el programa Aiseesoft y verificar si puede satisfacer sus necesidades antes de comprarlo.`,
        faq_info_6:`Si realmente desea obtener un reembolso, consulte nuestra <a href="https://www.aiseesoft.es/refund-policy.html">Política de reembolso</a>.`,
        faq_info_7:`Agradecemos su tiempo y atención para enviarnos una reseña. Comuníquese con nosotros (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) para enviar su revisión y consideraremos seriamente su revisión para nuestras actualizaciones de productos.`,
        change_password_success:`Contraseña cambiada con éxito`,
        successful_login_title:`Acceso exitoso`,
        product_page:`Página del producto>>`,
        successful_login_info:`Inicio de sesión completado. Cierre la página actual y vuelva a la pestaña original para continuar con el siguiente proceso. La página actual se cerrará automáticamente en 5 segundos. Si falla el cierre automático o el cierre haciendo clic en el botón "Listo", cierre esta pestaña directamente.`,
        successful_login_info_firefox:`Inicio de sesión completado. Cierre la página actual y vuelva a la pestaña original para continuar con el siguiente proceso.`,
        my_account:`Mi cuenta`,
        my_history:`Mi historia`,
        remove_watermark:`Quitar marca de agua`,
        no_history:`No historia`,
        history_all:`Seleccionar todo`,
        history_open:`Abierto`,
        history_down:`Descargar`,
        history_delete:`Borrar`,
        history_clear:`Borrar inválido`,
        images:`imagen(es)`,
        use_this_function:`Utilice este producto>>`,
        hd_downloading:`Beneficios de la descarga de imágenes originales en HD:`,
        lifetimeRemaining:`Vida útil restante`,
        Remaining:`Restante`,
        email_verification:`verificacion de email`,
        email_verification_info:`Hemos enviado el mensaje de verificación a su correo electrónico <span class="email"></span> y finalice la verificación. Después de verificar, los beneficios se sincronizarán automáticamente.`,
        wrong_email:`¿Dirección de correo errónea?`,
        click_here_to_modify:`Haga clic aquí para modificar`,
        get_more_help:`¿Obtener más ayuda?`,
        click_here:`haga clic aquí`,
        email_verification_info_success:`Felicitaciones por verificar su cuenta de correo electrónico.`,
        email_verification_info_error:`La verificación falló debido a que el enlace caducó.`,
        registration_succeeded:`Registro Exitoso`,
        registration_succeeded_info_1:`¡Felicidades! Te has registrado exitosamente. Hemos enviado el mensaje de verificación a su correo electrónico <span class="email"><span>, y termine de verificar para usar los beneficios de este correo electrónico.`,
        registration_succeeded_info_2:`Haga clic en "Listo" para volver a la página de inicio y utilizar este producto.`,
        registration_succeeded_info_3:`Haga clic en "Listo" para cerrar la página actual y volver a la página de inicio para las siguientes operaciones. Si el cierre falla, cierre manualmente la pestaña.`,
        verify_email:`Verificar correo electrónico`,
        registered_email_not_verified:`El correo electrónico registrado no ha sido verificado, por favor verifíquelo de inmediato.`,
        email_verification_time_1:`¿No recibiste un correo electrónico de verificación?`,
        email_verification_time_2:`Después`,
        email_verification_time_3:`Haga clic aquí para volver a enviarlo`,
        error_26301:`Código de error: 26301, contáctenos`,
        error_26302:`Código de error: 26302, contáctenos`,
        error_26303:`Error de formato de correo electrónico (código de error: 26303). Por favor, introdúcelo de nuevo.`,
        error_26304:`Se recomienda una contraseña de más de 8 caracteres (código de error: 26304)`,
        error_26305:`Solicitud fallida (código de error: 26305). Vuelva a intentarlo`,
        error_26306:`El correo electrónico se ha registrado, <a href="https://account.aiseesoft.com/login">vaya a iniciar sesión</a>`,
        error_26307:`Solicitud fallida (código de error: 26307). Vuelva a intentarlo`,
        error_26308:`Solicitud fallida (código de error: 26308). Vuelva a intentarlo`,
        error_26401:`Código de error: 26401, vuelva a intentarlo`,
        error_26402:`El correo electrónico ha sido verificado (código de error: 26402), vuelve a intentarlo`,
        error_26403:`Solicitud fallida (código de error: 26403). Vuelva a intentarlo`,
        error_26404:`Solicitud fallida (código de error: 26404). Vuelva a intentarlo`,
        error_26501:`Código de error: 26501, contáctenos`,
        error_26502:`Código de error: 26502, contáctenos`,
        error_26503:`Error de formato de correo electrónico (código de error: 26503). Por favor, introdúcelo de nuevo.`,
        error_26504:`Solicitud fallida (código de error: 26504). Vuelva a intentarlo`,
        error_26505:`El correo electrónico no se ha registrado, <a href="https://account.aiseesoft.com/register">regístrelo primero</a>`,
        error_26506:`El correo electrónico ha sido verificado.`,
        error_26507:`Solicitud fallida (código de error: 26507). Vuelva a intentarlo`,
        error_26508:`Verificación fallida (código de error: 26508), vuelva a intentarlo`,
        error_26509:`Solicitud fallida (código de error: 26509), vuelva a intentarlo`,
        error_26510:`Código de error: 26510, contáctenos`,
        error_26601:`Código de error: 26601, contáctenos`,
        error_26602:`Código de error: 26602, contáctenos`,
        error_26603:`Solicitud fallida (código de error: 26603). Vuelva a intentarlo`,
        error_26604:`Código de error: 26604, contáctenos`,
        error_26605:`Código de error: 26605, contáctenos`,
        error_26701:`Código de error: 26701, contáctenos`,
        error_26702:`Solicitud fallida (código de error: 26702). Vuelva a intentarlo`,
        error_26703:`Código de error: 26703, contáctenos`,
        error_26704:`Código de error: 26704, contáctenos`,
        error_26705:`Espere a iniciar sesión (código de error: 26705). Vuelva a intentarlo`,
        no_cookie:`Ha activado la función Bloquear todas las cookies en su navegador, por lo que no puede iniciar sesión. Vaya a Configuración para marcar la casilla Permitir todas las cookies.`,
        error_26801:`Código de error: 26801, contáctenos`,
        error_26802:`Código de error: 26802, contáctenos`,
        error_26803:`Solicitud fallida (código de error: 26803). Vuelva a intentarlo`,
        error_26804:`Solicitud fallida (código de error: 26804). Vuelva a intentarlo`,
        error_order:`Solicitud fallida (código de error: 27098), inténtalo de nuevo.`,
        error_order1:`La consulta del pedido está incompleta (código de error:`,
        error_order2:`）, actualice y vuelva a intentarlo.`,
        modify_email_title:`Modificar correo electrónico`,
        modify_email_info:`Puede utilizar el correo electrónico modificado para iniciar sesión en su cuenta.`,
        images_per:`Imágenes por`,
        error_26101:`Error: 26101. Por favor contáctenos`,
        error_26102:`Error: 26102. Por favor contáctenos`,
        error_26103:`Solicitud fallida (código de error: 26103). Por favor, inténtalo de nuevo`,
        error_26104:`Código de error: 26104, vuelva a intentarlo`,
        error_26105:`Código de error: 26105, vuelva a intentarlo`,
        error_26106:`Error al eliminar (código de error: 26106). Inténtalo de nuevo`,
        error_26201:`Error: 26201. Por favor contáctenos`,
        error_26202:`Solicitud fallida (código de error: 26202). Por favor, inténtalo de nuevo`,
        error_26001:`Error: 26001. Por favor contáctenos`,
        error_26002:`Error: 26002. Por favor contáctenos`,
        error_26003:`Error: 26003. Por favor contáctenos`,
        error_26004:`Error: 26004. Por favor contáctenos`,
        error_26005:`Solicitud fallida (código de error: 26005). Por favor, inténtalo de nuevo`,
        error_26006:`Código de error: 26006, vuelva a intentarlo`,
        error_26008:`Error: 26008. Por favor contáctenos`,
        go_to_the_home_page:`Ir a la pagina principal`,
        error_27101:`Solicitud fallida (código de error: 27101). Vuelva a intentarlo`,
        error_27201:`Código de error: 27201, contáctenos`,
        error_27202:`Código de error: 27202, vuelva a intentarlo`,
        error_27203:`Solicitud fallida (código de error: 27203). Vuelva a intentarlo`,
        error_27204:`Código no válido (código de error: 27204).`,
        error_27205:`Solicitud fallida (código de error: 27205). Vuelva a intentarlo`,
        error_27206:`Solicitud fallida (código de error: 27206). Vuelva a intentarlo`,
        error_27207:`Solicitud fallida (código de error: 27207). Vuelva a intentarlo`,
        no_history_found:`¡No has usado ninguna herramienta! <a href="/">Actualizar</a> o <a href="https://www.aiseesoft.es/">Ir al sitio web oficial</a>`,
        error_25301:`Error: 25301. Por favor contáctenos`,
        error_25302:`Error: 25302. Por favor contáctenos`,
        error_25303:`Solicitud fallida (código de error: 25303). Inténtalo de nuevo`,
        error_25304:`Solicitud fallida (código de error: 25304). Inténtalo de nuevo`,
        error_25305:`Solicitud fallida (código de error: 25305). Inténtalo de nuevo`,
        error_25306:`Solicitud fallida (código de error: 25306). Inténtalo de nuevo`,
        image_upscaler_p:`Beneficios de la descarga de imágenes sin marca de agua:`,
        Available_for:`Disponible para:`,
        credit_per:`%s crédito(s) por imagen HD`,
        still_valid:`Los planes adquiridos siguen siendo válidos`,
        credit:`crédito(s)`,
        pc_3rd_info:`Inicie sesión exitosamente. Vaya a la aplicación para realizar más operaciones.`,
        use_online:`Utilice el servicio en línea`,
        use_download:`Utilice el programa de escritorio`,
        use_immediately:`Usar inmediatamente`,
        Use_in_browser:`Usar en el navegador`,
        win_desktop:`ventanas`,
        Mac_desktop:`Mac`,
    },
    pt:{
        overtime_1:`Erro de código:`,
        overtime_2:`, tente novamente`,
        isnetwork:`Erro de Internet. Verifique e tente novamente`,
        email_placeholder:`E-mail`,
        email_empty:`Insira o e-mail`,
        email_not_valid:`E-mail não é válido`,
        email_not_valid_1:`Por favor, insira seu e-mail`,
        email_not_valid_2:`O e-mail não é válido, por favor, use um endereço diferente.`,
        email_not_valid_3:`Nenhuma entrada de e-mail`,
        password_placeholder:`Senha`,
        password_empty:`Insira a senha`,
        password_not_valid:`Conta ou senha incorreta`,
        password_not_valid_1:`Senha acima de 8 caracteres é necessária`,
        password_not_valid_2:`Por favor, crie uma senha`,
        password_placeholder_1:`Crie sua senha`,
        password_placeholder_2:`Confirme sua senha`,
        password_placeholder_3:`Criar nova senha`,
        password_placeholder_4:`Confirme a nova senha`,
        password_placeholder_5:`Insira a senha antiga`,
        copy_password_empty:`Por favor, confirme a senha`,
        copy_password_not_valid:`Por favor, confirme sua senha`,
        copy_passwords_inconsistent:`A confirmação da sua senha não corresponde`,
        code_empty:`Insira o código de verificação`,
        code_not_valid:`Código de verificação inválido`,
        code_placeholder:`Código de verificação`,
        not_received_code:`Se sua caixa de correio não receber o código de verificação por muito tempo, obtenha o código de verificação novamente.`,
        get_first_code:`Por favor, obtenha o código de verificação primeiro.`,
        connect_your_email_placeholder:`Conecte seu e-mail`,
        last_name_placeholder:`Por favor insira seu sobrenome`,
        first_name_placeholder:`por favor entre com seu primeiro nome`,
        address_placeholder:`Por favor, insira seu endereço`,
        no_code_text:`Enviamos um código de verificação. Digite seu código. <span class='tips'>Não recebeu um código?`,
        no_code_text_1:`1. Verifique se o endereço de e-mail é válido e pode receber e-mails.`,
        no_code_text_2:`2. Para o e-mail ser enviado pelo sistema automaticamente, ele pode ser sinalizado como spam ou lixo eletrônico. Verifique se o e-mail está na pasta Lixeira.`,
        no_code_text_3:`3. Não consegue resolver o seu problema?`,
        no_code_text_3_span:`Então clique aqui para entrar em contato conosco.`,
        order_no:`Você não comprou nenhum produto, <a href="https://www.aiseesoft.com.br/store/">compre agora</a>! Se você tiver alguma dúvida, <a href="https:/www.aiseesoft.com.br/contact.html">entre em contato conosco</a>.`,
        error_24901:`A conta atual não tem e-mail vinculado e não consegue encontrar os pedidos. Conecte um e-mail.`,
        user_guide:`Guia do usuário>>`,
        download:`Download`,
        order_number:`Número do pedido:`,
        Refund:`Reembolso`,
        Disabled:`Desabilitado`,
        Normal:`Normal`,
        Modify:`Modificar`,
        Modify_1:`Modificar>>`,
        Connect:`Conectar`,
        unlink_success:`Desvincular com sucesso`,
        connect_success:`Conectar com sucesso`,
        feedback_title:`Obrigado pelo seu feedback! Por favor, deixe o seu problema e nós responderemos a você dentro de 24 horas.`,
        feedback_thank_you:`Obrigado!<br />Seu feedback foi enviado com sucesso.`,
        feedback_email:`Digite seu email aqui!`,
        feedback_content:`Deixe aqui qualquer problema ou sugestão que encontrou.`,
        feedback_submit:`Enviar`,
        form_contents:`Você não inseriu nenhuma descrição. Insira-o e envie novamente.`,
        old_password:`Insira a senha antiga`,
        new_password:`Por favor, crie uma nova senha`,
        old_new_password:`A nova senha não pode ser igual à antiga`,
        incorrect_password:`Senha incorreta`,
        delete_no:`Excluir agora`,
        Caps:`Caps Lock está ativado`,
        Get:`Pegar`,
        Done:`Feito`,
        error_20001:`Erro: 20001. Faça login novamente.`,
        error_20002:`Erro: 20002. Faça login novamente.`,
        error_20003:`Erro: 20003. Faça login novamente.`,
        error_20004:`Falha na solicitação (código de erro: 20004). Tente novamente.`,
        error_20005:`A sessão de login expirou (erro: 20005). Por favor faça login novamente.`,
        error_20006:`Falha na solicitação (código de erro: 20006). Tente novamente.`,
        error_20007:`A sessão de login expirou (erro: 20007). Por favor faça login novamente.`,
        error_20008:`A sessão de login expirou (erro: 20008). Por favor faça login novamente.`,
        error_20009:`A sessão de login expirou (erro: 20009). Por favor faça login novamente.`,
        error_20101:`Por favor, insira seu e-mail (código de erro: 20101)`,
        error_20102:`O e-mail não é válido (código de erro: 20102)`,
        error_20103:`Falha na solicitação (código de erro: 20103). Por favor, tente novamente`,
        error_20104:`O e-mail já está em uso, <a href="https://account.aiseesoft.com/login">faça login</a> ou registre-se com um novo`,
        error_20105:`Falha na solicitação (código de erro: 20105). Por favor, tente novamente`,
        error_20106:`Falha ao enviar e-mail, tente novamente`,
        error_20201:`Por favor, insira seu e-mail (código de erro: 20201)`,
        error_20202:`Por favor, insira sua senha (código de erro: 20202)`,
        error_20203:`Insira o código de verificação (código de erro: 20203)`,
        error_20204:`O e-mail não é válido (código de erro: 20204)`,
        error_20205:`Senha acima de 8 caracteres é necessária (código de erro: 20205)`,
        error_20206:`Falha na solicitação (código de erro: 20206). Por favor, tente novamente`,
        error_20207:`Código de verificação inválido`,
        error_20208:`Falha na solicitação (código de erro: 20208). Por favor, tente novamente`,
        error_20209:`Falha na solicitação (código de erro: 20209). Por favor, tente novamente`,
        error_20301:`Por favor, insira seu e-mail (código de erro: 20301)`,
        error_20302:`Erro: 20302. Entre em contato conosco`,
        error_20303:`O e-mail não é válido (código de erro: 20303)`,
        error_20304:`Falha na solicitação (código de erro: 20304). Por favor, tente novamente`,
        error_20305:`Conta não existe. Digite novamente ou <a href="https://account.aiseesoft.com/register">Crie</a> primeiro.`,
        error_20306:`Ainda não há senha. Use <a href="https://account.aiseesoft.com/passwordless-login">login sem senha</a> ou <a href="create-password">defina uma senha</a> e faça login.`,
        error_20308:`Falha na solicitação (código de erro: 20308). Por favor, tente novamente`,
        error_20401:`Falha ao sair (código de erro: 20401). Por favor, tente novamente`,
        error_20501:`Por favor, insira seu e-mail (código de erro: 20501)`,
        error_20502:`O e-mail não é válido (código de erro: 20502)`,
        error_20503:`Falha na solicitação (código de erro: 20503). Por favor, tente novamente`,
        error_20504:`Falha ao enviar e-mail. Tente novamente.`,
        error_20601:`Por favor, insira seu e-mail (código de erro: 20601)`,
        error_20602:`Insira o código de verificação (código de erro: 20602)`,
        error_20603:`O e-mail não é válido (código de erro: 20603)`,
        error_20604:`Falha na solicitação (código de erro: 20604). Por favor, tente novamente`,
        error_20606:`Falha na solicitação (código de erro: 20606). Por favor, tente novamente`,
        error_20607:`Falha na solicitação (código de erro: 20607). Por favor, tente novamente`,
        error_20608:`Falha na solicitação (código de erro: 20608). Por favor, tente novamente`,
        error_20701:`Por favor, insira seu e-mail (código de erro: 20701)`,
        error_20702:`O e-mail não é válido (código de erro: 20702)`,
        error_20703:`Falha na solicitação (código de erro: 20703). Por favor, tente novamente`,
        error_20704:`Conta não existe. Digite novamente ou <a href="https://account.aiseesoft.com/register">Crie</a> primeiro.`,
        error_20705:`Falha na solicitação (código de erro: 20705). Por favor, tente novamente`,
        error_20706:`Falha ao enviar e-mail. Por favor, tente novamente`,
        error_20801:`Por favor, insira seu e-mail (código de erro: 20801)`,
        error_20802:`Erro: 20802. Entre em contato conosco`,
        error_20803:`Insira o código de verificação (código de erro: 20803)`,
        error_20804:`O e-mail não é válido (código de erro: 20804)`,
        error_20805:`Senha acima de 8 caracteres é necessária (código de erro: 20805)`,
        error_20806:`Falha na solicitação (código de erro: 20806). Por favor, tente novamente`,
        error_20808:`Falha na solicitação (código de erro: 20808). Por favor, tente novamente`,
        error_20901:`Falha na solicitação (código de erro: 20901). Por favor, tente novamente`,
        error_20902:`Falha na solicitação (código de erro: 20902). Por favor, tente novamente`,
        error_21000:`As alterações são salvas`,
        error_21001:`Nenhuma informação é enviada (código de erro: 21001)`,
        error_21002:`Falha na solicitação (código de erro: 21002). Por favor, tente novamente`,
        error_21101:`Por favor, insira seu e-mail (código de erro: 21101)`,
        error_21102:`O e-mail não é válido (código de erro: 21102)`,
        error_21103:`Falha na solicitação (código de erro: 21103), tente novamente`,
        error_21104:`O e-mail já está conectado, use um novo`,
        error_21105:`Falha na solicitação (código de erro: 21105), tente novamente`,
        error_21106:`Falha ao enviar e-mail. Por favor, tente novamente`,
        error_21201:`Por favor, insira seu e-mail (código de erro: 21201)`,
        error_21202:`Insira o código de verificação (código de erro: 21202)`,
        error_21203:`E-mail inválido (código de erro: 21203)`,
        error_21204:`Erro: 21204. Entre em contato conosco`,
        error_21205:`Erro: 21205. Entre em contato conosco`,
        error_21206:`Senha acima de 8 caracteres é necessária (código de erro: 21206)`,
        error_21207:`Falha na solicitação (código de erro: 21207). Por favor, tente novamente`,
        error_21209:`Falha na solicitação (código de erro: 21209). Por favor, tente novamente`,
        error_21301:`Insira a senha antiga (código de erro: 21301)`,
        error_21302:`Crie uma nova senha (código de erro: 21302)`,
        error_21303:`A nova senha não pode ser igual à antiga. (Erro: 21303)`,
        error_21304:`Senha acima de 8 caracteres é necessária (código de erro: 21304)`,
        error_21306:`Falha na solicitação (código de erro: 21306). Por favor, tente novamente`,
        error_21402:`Falha na solicitação (código de erro: 21402). Por favor, tente novamente`,
        error_21403:`Falha ao enviar o código de verificação. Por favor reenvie`,
        error_21500:`A conta foi deletada`,
        error_21501:`Insira o código de verificação (código de erro: 21501)`,
        error_21502:`A sessão de login expirou (erro: 21502). Por favor faça login novamente.`,
        error_21503:`Falha na solicitação (código de erro: 21503). Por favor, tente novamente`,
        error_21505:`Falha na solicitação (código de erro: 21505), tente novamente`,
        error_21601:`Erro: 20601. Entre em contato conosco`,
        error_21602:`Verificação inválida (erro: 20602). Tente novamente.`,
        error_21603:`Erro: 20603. Tente novamente`,
        error_21604:`Falha na solicitação (código de erro: 21604). Por favor, tente novamente`,
        error_21606:`Falha na solicitação (código de erro: 21606). Por favor, tente novamente`,
        error_21611:`Falha na solicitação (código de erro: 21611). Por favor, tente novamente`,
        error_21801:`Erro: 21801. Entre em contato conosco`,
        error_21802:`Falha na solicitação (erro: 21802). Por favor, tente novamente`,
        error_21803:`Erro: 21803. Tente novamente`,
        error_21804:`Falha na solicitação (código de erro: 21804). Por favor, tente novamente`,
        error_21806:`Erro: 21806. Tente novamente`,
        error_21807:`Erro: 21807. Entre em contato conosco`,
        error_21808:`Erro: 21808. Entre em contato conosco`,
        error_21809:`Erro: 21809. Entre em contato conosco`,
        error_21810:`Erro: 21810. Entre em contato conosco`,
        error_21811:`Erro: 21811. Entre em contato conosco`,
        error_21812:`Erro: 21812. Entre em contato conosco`,
        error_21813:`Falha na solicitação (código de erro: 21813). Por favor, tente novamente`,
        error_21814:`Erro: 21814. Entre em contato conosco`,
        error_21815:`Falha na solicitação (código de erro: 21815). Por favor, tente novamente`,
        error_21816:`Erro: 21816. Entre em contato conosco`,
        error_21817:`Erro: 21817. Entre em contato conosco`,
        error_21818:`Erro: 21818. Entre em contato conosco`,
        error_21819:`Falha na solicitação (código de erro: 21819). Por favor, tente novamente`,
        error_21820:`Erro: 21820. Entre em contato conosco`,
        error_21821:`Erro: 21821. Entre em contato conosco`,
        error_21822:`Erro: 21822. Entre em contato conosco`,
        error_21823:`Falha na solicitação (código de erro: 21823). Por favor, tente novamente`,
        error_21824:`Falha na solicitação (código de erro: 21824). Por favor, tente novamente`,
        error_21825:`Falha na solicitação (código de erro: 21825). Por favor, tente novamente`,
        error_21826:`Falha na solicitação (código de erro: 21826). Por favor, tente novamente`,
        error_21828:`Erro: 21828. Entre em contato conosco`,
        error_21901:`Erro: 21901. Entre em contato conosco`,
        error_21902:`Falha na solicitação (código de erro: 21902). Por favor, tente novamente`,
        error_21903:`O status da conta mudou (código de erro: 21903), atualize a página e tente novamente`,
        error_21904:`Erro: 21904. Tente novamente`,
        error_21905:`Erro: 21905. Tente novamente`,
        error_21906:`Falha na solicitação (código de erro: 21906). Por favor, tente novamente`,
        error_21907:`A conta do Google foi vinculada a outra conta`,
        error_21908:`Falha na solicitação (código de erro: 21908). Por favor, tente novamente`,
        error_22001:`Falha na solicitação (código de erro: 22001). Por favor, tente novamente`,
        error_22002:`Falha ao desvincular sem login adicional`,
        error_22003:`Falha na solicitação (código de erro: 22003). Por favor, tente novamente`,
        error_22101:`Erro: 22101. Entre em contato conosco`,
        error_22102:`O status da conta mudou (código de erro: 22102), atualize a página e tente novamente`,
        error_22103:`Falha na solicitação (código de erro: 22103). Por favor, tente novamente`,
        error_22104:`O status da conta mudou (código de erro: 22104), atualize a página e tente novamente`,
        error_22105:`Erro: 22105. Tente novamente`,
        error_22106:`Erro: 22106. Tente novamente`,
        error_22107:`Erro: 22107. Entre em contato conosco`,
        error_22108:`Falha na solicitação (código de erro: 22108). Por favor, tente novamente`,
        error_22201:`Erro: 22201. Entre em contato conosco`,
        error_22202:`Verificação inválida (erro: 22202). Tente novamente.`,
        error_22203:`Erro: 22203. Tente novamente"`,
        error_22204:`Falha na solicitação (código de erro: 22204). Por favor, tente novamente`,
        error_22206:`Falha na solicitação (código de erro: 22206). Por favor, tente novamente`,
        error_22401:`Erro: 22401. Entre em contato conosco`,
        error_22402:`Verificação inválida (erro: 22402). Tente novamente.`,
        error_22403:`Erro: 22403. Tente novamente`,
        error_22404:`Falha na solicitação (código de erro: 22404). Por favor, tente novamente`,
        error_22405:`A conta do Facebook foi vinculada a outro e-mail`,
        error_22406:`Erro: 22406. Tente novamente`,
        error_22407:`Erro: 22407. Entre em contato conosco`,
        error_22408:`Erro: 22408. Entre em contato conosco`,
        error_22409:`Erro: 22409. Entre em contato conosco`,
        error_22410:`Erro: 224010. Entre em contato conosco`,
        error_22411:`Erro: 224011. Entre em contato conosco`,
        error_22412:`Erro: 224012. Entre em contato conosco`,
        error_22413:`Erro: 22413. Entre em contato conosco`,
        error_22414:`Falha na solicitação (código de erro: 22414). Por favor, tente novamente`,
        error_22415:`Erro: 22415. Entre em contato conosco`,
        error_22416:`Erro: 22416. Entre em contato conosco`,
        error_22417:`Erro: 22417. Entre em contato conosco`,
        error_22418:`Falha na solicitação (código de erro: 22418). Por favor, tente novamente`,
        error_22419:`Erro: 22419. Entre em contato conosco`,
        error_22420:`Erro: 22420. Entre em contato conosco`,
        error_22421:`Erro: 22421. Entre em contato conosco`,
        error_22422:`Falha na solicitação (código de erro: 22422). Por favor, tente novamente`,
        error_22423:`Falha na solicitação (código de erro: 22423). Por favor, tente novamente`,
        error_22424:`Falha na solicitação (código de erro: 22424). Por favor, tente novamente`,
        error_22425:`Falha na solicitação (código de erro: 22425). Por favor, tente novamente`,
        error_20098:`Código de erro: 20098. Se estiver navegando no modo Privado, alterne para o modo Normal e tente novamente.`,
        error_22298:`A solicitação para o Google falhou (erro: 22298). Tente novamente.`,
        error_22498:`A solicitação para o Facebook falhou (erro: 22498). Tente novamente.`,
        error_24902:`Falha na solicitação (código de erro: 24902). Por favor, tente novamente`,
        error_24903:`Falha na solicitação (código de erro: 24903). Por favor, tente novamente`,
        error_24904:`Falha na solicitação (código de erro: 24904). Por favor, tente novamente`,
        error_24905:`Falha na solicitação (código de erro: 24905). Por favor, tente novamente`,
        login_title:`Entrar no Aiseesoft`,
        password_placeholder:`Senha`,
        log_in:`Conecte-se`,
        no_account:`Sem conta?`,
        create_it:`Crie`,
        or_log_in_with:`Ou faça login com`,
        passwordless_login:`Login sem senha`,
        log_in_done:`Login feito`,
        three_rd_account_connect_info:`Parabéns! Você se conectou com sucesso. Agora você pode conectar uma conta de e-mail que será usada para fazer login no futuro`,
        connect_now:`Conecte agora`,
        see_my_account:`Ver minha conta`,
        three_rd_login_merge_account:`O endereço de e-mail da conta de terceiros foi registrado, você deseja se conectar e fazer login com este endereço de e-mail diretamente`,
        connect_log_in:`Conecte-se e faça login`,
        create_an_account:`Crie a sua conta aqui`,
        connect_account:`Conectar conta`,
        connect_account_info:`Depois de se conectar, você pode fazer login com esta conta.`,
        back_to_log_in:`Volte ao login`,
        create_password:`Criar senha`,
        create_now:`Crie agora`,
        password_login_subtitle:`Login sem senha com e-mail`,
        account_login:`Login da conta`,
        rights:`Ao criar esta conta, você concorda com os <a href="https://www.aiseesoft.com.br/company/terms-and-conditions.html">Termos de Serviço</a> e <ahref="https:// www.aiseesoft.com.br/privacy-policy.html">Política de Privacidade</ahref=>`,
        passwordless_login_done:`Login sem senha feito`,
        passwordless_login_info:`Parabéns, você concluiu o login sem senha com sucesso. Você pode criar uma senha para esta conta e fazer login com a conta e a senha no futuro. <a href="/create-password" style="display: initial;">Criar agora</a>`,
        sign_up:`Inscrever-se`,
        register_info:`crie sua conta`,
        reset_now:`Redefinir agora`,
        forgot_password:`Esqueceu sua senha`,
        reset_password_subtitle:`Use o e-mail da sua conta para redefinir a senha`,
        plan_products:`Planos & Produtos`,
        nick_name:`Nome de usuário:`,
        email:`E-mail:`,
        my_products:`Meus produtos`,
        my_orders:`minhas ordens`,
        unlink:`Desvincular`,
        link:`Link`,
        connected_accounts:`contas conectadas`,
        last_name:`Sobrenome:`,
        first_name:`Primeiro nome:`,
        Gender:`Gênero:`,
        Birth:`Aniversário:`,
        Month:`Mês`,
        Year:`Ano`,
        Country_Region:`País/Região:`,
        Address:`Endereço:`,
        Save:`Salvar`,
        Date:`Data`,
        Male:`Macho`,
        Female:`Fêmea`,
        Unspecified:`não especificado`,
        Security:`Segurança`,
        change_password:`Alterar a senha`,
        change_now:`Mude agora`,
        connect_email:`Conectar e-mail`,
        delete_account:`Deletar conta`,
        delete_account_info:`Quando sua conta for excluída, todos os dados em Plano e produtos associados à sua conta serão excluídos permanentemente e talvez você não consiga recuperá-los. Recomendamos que você pise com cautela.`,
        Delete:`Excluir`,
        Logout:`Sair`,
        my_profile:`Meu perfil`,
        guides_faqs:`Guias e perguntas frequentes`,
        More:`Mais`,
        guides:`Guias`,
        register:`registro`,
        hot_faq:`FAQ quente`,
        Contents:`Conteúdo:`,
        contact_us:`Entre em contato conosco>>`,
        plan:`Plano`,
        unregistered:`Não registrado`,
        buy_more:`Compre mais`,
        buy_again:`Comprar novamente`,
        buy_now:`Comprar agora`,
        free_no_limit:`Gratuito e sem limite`,
        expired:`Expirado`,
        lifetime:`Vida`,
        remain:`Permanecer`,
        day_s:`Dias)`,
        error_24801:`Falha na solicitação (código de erro: 24801). Por favor, tente novamente`,
        no_app_found:`Nenhum aplicativo encontrado！<a href="/">Atualize</a> ou <a href="https://www.aiseesoft.com.br/">Vá para o site oficial</a>`,
        get_more:`Obtenha mais >>`,
        edit_photo:`Editar foto`,
        select_photo:`Selecione a foto`,
        change_photo:`Mudar foto`,
        cancel:`Cancelar`,
        hide_password:`Esconder a senha`,
        show_password:`Mostrar senha`,
        zoom_in:`Mais Zoom`,
        zoom_out:`Reduzir o zoom`,
        rotate:`Girar`,
        horizontal_flip:`Inversão horizontal`,
        vertical_flip:`Inversão vertical`,
        country:`País`,
        country_1:`Selecione seu país/região`,
        country_2:`Ilha de Åaland`,
        country_3:`Afeganistão`,
        country_4:`Albânia`,
        country_5:`Argélia`,
        country_6:`Samoa Americana`,
        country_7:`Andorra`,
        country_8:`Angola`,
        country_9:`Anguila`,
        country_10:`Antártica`,
        country_11:`Antígua e Barbuda`,
        country_12:`Argentina`,
        country_13:`Armênia`,
        country_14:`Aruba`,
        country_15:`Austrália`,
        country_16:`Áustria`,
        country_17:`Azerbaijão`,
        country_18:`Bahrein`,
        country_19:`Bangladesh`,
        country_20:`Barbados`,
        country_21:`Bielorrússia`,
        country_22:`Bélgica`,
        country_23:`Belize`,
        country_24:`Benim`,
        country_25:`Bermudas`,
        country_26:`Butão`,
        country_27:`Bolívia`,
        country_28:`Bósnia e Herzegovina`,
        country_29:`Botsuana`,
        country_30:`Ilha Bouvet`,
        country_31:`Brasil`,
        country_32:`Território Britânico do Oceano Índico`,
        country_33:`Ilhas Virgens Britânicas`,
        country_34:`Brunei`,
        country_35:`Bulgária`,
        country_36:`Burquina`,
        country_37:`Burundi`,
        country_38:`Camboja`,
        country_39:`Camarões`,
        country_40:`Canadá`,
        country_41:`Cabo Verde`,
        country_42:`Caribe Holanda`,
        country_43:`Ilhas Cayman`,
        country_44:`República Centro-Africana`,
        country_45:`Chade`,
        country_46:`Chile`,
        country_47:`China`,
        country_48:`Ilha do Natal`,
        country_49:`Cocos (Keeling) Ilhas`,
        country_50:`Colômbia`,
        country_51:`Ilhas Cook`,
        country_52:`Costa Rica`,
        country_53:`Costa do Marfim`,
        country_54:`Croácia`,
        country_55:`Cuba`,
        country_56:`Chipre`,
        country_57:`República Checa`,
        country_58:`República Democrática do Congo`,
        country_59:`Dinamarca`,
        country_60:`Djibuti`,
        country_61:`Dominica`,
        country_62:`República Dominicana`,
        country_63:`Equador`,
        country_64:`Egito`,
        country_65:`El Salvador`,
        country_66:`Guiné Equatorial`,
        country_67:`Eritreia`,
        country_68:`Estônia`,
        country_69:`Etiópia`,
        country_70:`Ilhas Malvinas`,
        country_71:`Ilhas Faroe`,
        country_72:`Estados Federados da Micronésia`,
        country_73:`Fiji`,
        country_74:`Finlândia`,
        country_75:`França`,
        country_76:`Guiana Francesa`,
        country_77:`Polinésia Francesa`,
        country_78:`Territórios Franceses do Sul`,
        country_79:`Gabão`,
        country_80:`Gâmbia`,
        country_81:`Geórgia`,
        country_82:`Alemanha`,
        country_83:`Gana`,
        country_84:`Gibraltar`,
        country_85:`Grã-Bretanha (Reino Unido; Inglaterra)`,
        country_86:`Grécia`,
        country_87:`Groenlândia`,
        country_88:`Granada`,
        country_89:`Guadalupe`,
        country_90:`Guam`,
        country_91:`Guatemala`,
        country_92:`Guernsey`,
        country_93:`Guiné`,
        country_94:`Guiné-Bissau`,
        country_95:`Guiana`,
        country_96:`Haiti`,
        country_97:`Ilha Heard e McDonald Ilhas`,
        country_98:`Honduras`,
        country_99:`Hong Kong`,
        country_100:`Hungria`,
        country_101:`Islândia`,
        country_102:`Índia`,
        country_103:`Indonésia`,
        country_104:`Irã`,
        country_105:`Iraque`,
        country_106:`Irlanda`,
        country_107:`Ilha de Man`,
        country_108:`Israel`,
        country_109:`Itália`,
        country_110:`Jamaica`,
        country_111:`Japão`,
        country_112:`Camisa`,
        country_113:`Jordânia`,
        country_114:`Cazaquistão`,
        country_115:`Quênia`,
        country_116:`Kiribati`,
        country_117:`Kuwait`,
        country_118:`Quirguistão`,
        country_119:`Laos`,
        country_120:`Letônia`,
        country_121:`Líbano`,
        country_122:`Lesoto`,
        country_123:`Libéria`,
        country_124:`Líbia`,
        country_125:`Liechtenstein`,
        country_126:`Lituânia`,
        country_127:`Luxemburgo`,
        country_128:`Macau`,
        country_129:`Madagáscar`,
        country_130:`Malauí`,
        country_131:`Malásia`,
        country_132:`Maldivas`,
        country_133:`Mali`,
        country_134:`Malta`,
        country_135:`Ilhas Marshall`,
        country_136:`Martinica`,
        country_137:`Mauritânia`,
        country_138:`maurício`,
        country_139:`Maiote`,
        country_140:`México`,
        country_141:`Moldávia`,
        country_142:`Mônaco`,
        country_143:`Mongólia`,
        country_144:`Montenegro`,
        country_145:`Montserrat`,
        country_146:`Marrocos`,
        country_147:`Moçambique`,
        country_148:`Mianmar (Birmânia)`,
        country_149:`Namíbia`,
        country_150:`Nauru`,
        country_151:`Nepal`,
        country_152:`Holanda`,
        country_153:`Nova Caledônia`,
        country_154:`Nova Zelândia`,
        country_155:`Nicarágua`,
        country_156:`Níger`,
        country_157:`Nigéria`,
        country_158:`Niue`,
        country_159:`Ilha Norfolk`,
        country_160:`Coréia do Norte`,
        country_161:`Ilhas Marianas do Norte`,
        country_162:`Noruega`,
        country_163:`Omã`,
        country_164:`Paquistão`,
        country_165:`Palau`,
        country_166:`Territórios Palestinos`,
        country_167:`Panamá`,
        country_168:`Papua Nova Guiné`,
        country_169:`Paraguai`,
        country_170:`Peru`,
        country_171:`Ilhas Pitcairn`,
        country_172:`Polônia`,
        country_173:`Portugal`,
        country_174:`Porto Rico`,
        country_175:`Catar`,
        country_176:`República da Macedônia (ARJM)`,
        country_177:`República do Congo`,
        country_178:`Reunião`,
        country_179:`Romênia`,
        country_180:`Federação Russa`,
        country_181:`Ruanda`,
        country_182:`São Bartolomeu`,
        country_183:`Saint Martin (França)`,
        country_184:`Saint-Pierre e Miquelon`,
        country_185:`Samoa`,
        country_186:`São Marino`,
        country_187:`São Tomé e Príncipe`,
        country_188:`Arábia Saudita`,
        country_189:`Senegal`,
        country_190:`Sérvia`,
        country_191:`Seychelles`,
        country_192:`Serra Leoa`,
        country_193:`Cingapura`,
        country_194:`Eslováquia`,
        country_195:`Eslovênia`,
        country_196:`Ilhas Salomão`,
        country_197:`Somália`,
        country_198:`África do Sul`,
        country_199:`Sul da Geórgia e a Ilha do Sanduíche do Sul`,
        country_200:`Coreia do Sul`,
        country_201:`Sudão do Sul`,
        country_202:`Espanha`,
        country_203:`Sri Lanka`,
        country_204:`St. Helena e Dependências`,
        country_205:`São Cristóvão e Nevis`,
        country_206:`Santa Lúcia`,
        country_207:`São Vicente e Granadinas`,
        country_208:`Sudão`,
        country_209:`Suriname`,
        country_210:`Suazilândia`,
        country_211:`Suécia`,
        country_212:`Suíça`,
        country_213:`Síria`,
        country_214:`Taiwan`,
        country_215:`Tadjiquistão`,
        country_216:`Tanzânia`,
        country_217:`Predefinição:dados do país SJM Svalbard`,
        country_218:`tailândia`,
        country_219:`As Bahamas`,
        country_220:`As Comores`,
        country_221:`As Filipinas`,
        country_222:`Timor-Leste (Timor Leste)`,
        country_223:`Ir`,
        country_224:`Toquelau`,
        country_225:`Tonga`,
        country_226:`Trinidad e Tobago`,
        country_227:`Tunísia`,
        country_228:`Peru`,
        country_229:`Turquemenistão`,
        country_230:`Ilhas Turks e Caicos `,
        country_231:`Tuvalu`,
        country_232:`Uganda`,
        country_233:`Ucrânia`,
        country_234:`Emirados Árabes Unidos`,
        country_235:`Ilhas Menores Periféricas dos Estados Unidos `,
        country_236:`Estados Unidos da América (EUA)`,
        country_237:`Ilhas Virgens Estados Unidos`,
        country_238:`Uruguai`,
        country_239:`Uzbequistão`,
        country_240:`Vanuatu`,
        country_241:`Cidade do Vaticano (A Santa Sé)`,
        country_242:`Venezuela`,
        country_243:`Vietnã`,
        country_244:`Wallis e Futuna`,
        country_245:`Saara Ocidental`,
        country_246:`Iémen`,
        country_247:`Zâmbia`,
        country_248:`Zimbábue`,
        google_login:`Faça login no Google`,
        State:`Estado`,
        Activation_code:`Código de ativação`,
        Question:`Liste todos os aplicativos nos quais você se conectou`,
        Copy_complete:`cópia concluída`,
        footer:`Direitos autorais © 2024 Aiseesoft Studio. Todos os direitos reservados.`,
        store:`Loja`,
        resource:`Recurso`,
        support:`apoiar`,
        video_converter:`Conversor de vídeo`,
        multimedia:`Multimídia`,
        data_recovery:`Recuperação de dados`,
        utility:`Utilitário`,
        free_tools:`Ferramentas Gratuitas`,
        for_windows:`Para Windows`,
        for_mac:`Para Mac`,
        pdf_solutions:`Soluções de PDF`,
        data_manager:`Gerenciador de dados`,
        other_tools:`Outras ferramentas`,
        free_online_solutions:`Soluções on-line gratuitas`,
        free_online_tools:`Ferramentas on-line gratuitas`,
        desktop_freeware:`Desktop gratuito`,
        product_1:`Conversor de Vídeo Definitivo`,
        product_2:`Total Video Converter`,
        product_3:`Conversor MTS`,
        product_4:`Conversor 4K`,
        product_5:`Conversor MXF`,
        product_6:`Conversor 3D`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Conversor de Vídeo para Mac`,
        product_9:`Conversor MTS para Mac`,
        product_10:`Conversor 4K para Mac`,
        product_11:`Conversor MXF para Mac`,
        product_12:`Conversor 3D para Mac`,
        product_13:`Leitor de Blu-ray`,
        product_14:`Melhorador de vídeo`,
        product_15:`Editor de vídeo`,
        product_16:`Criador de apresentação de slides`,
        product_17:`Criador de Blu-ray`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`Criador de DVD`,
        product_20:`Reprodutor de Blu-ray para Mac`,
        product_21:`Mac Video Enhancer`,
        product_22:`Editor de vídeo para Mac`,
        product_23:`Criador de DVD para Mac`,
        product_24:`Conversor de MP4 para Mac`,
        product_25:`Recuperação de Dados do Windows`,
        product_26:`Recuperação de dados do iPhone FoneLab`,
        product_27:`FoneLab Recuperação do Sistema iOS`,
        product_28:`FoneLab iOS Data Backup &amp; Restaurar`,
        product_29:`Recuperação de dados Android FoneLab`,
        product_30:`Extração de dados quebrados do Android`,
        product_31:`Backup de dados do Android &amp; Restaurar`,
        product_32:`Recuperação de Dados do Mac`,
        product_33:`Recuperação de Dados do Mac FoneLab iPhone`,
        product_34:`Recuperação do sistema Mac FoneLab iOS`,
        product_35:`Mac FoneLab iOS Data Backup &amp; Restaurar`,
        product_36:`Mac FoneLab Recuperação de Dados Android`,
        product_37:`Mac Android Data Backup &amp; Restaurar`,
        product_38:`Conversor de PDF Definitivo`,
        product_39:`Conversor de PDF Definitivo para Mac`,
        product_40:`Fusão de PDF`,
        product_41:`Conversor de PDF para ePub do Mac`,
        product_42:`Limpador de Mac`,
        product_43:`FoneTrans`,
        product_44:`Transferência do WhatsApp para iOS`,
        product_45:`MobieSync`,
        product_46:`FoneEraserName`,
        product_47:`Limpador de iPhone`,
        product_48:`Conversor PPT para Vídeo`,
        product_49:`Conversor HEIC`,
        product_50:`Desbloqueador de iPhone`,
        product_51:`Conversor de Vídeo Gratuito Online`,
        product_52:`Conversor de Áudio Online Gratuito`,
        product_53:`Compressor de vídeo online gratuito`,
        product_54:`Fusão de Vídeo Gratuita Online`,
        product_55:`Conversor HEIC Gratuito`,
        product_56:`Compressor de imagem gratuito`,
        product_57:`Removedor de marca d'água grátis`,
        product_58:`Compressor de PDF Grátis Online`,
        product_59:`Upscaler de imagem gratuito on-line`,
        product_60:`Removedor de fundo online gratuito`,
        product_61:`Conversor de Imagens Gratuito Online`,
        product_62:`Conversor PDF PNG Grátis On-line`,
        product_63:`Conversor de Vídeo Gratuito`,
        product_64:`Conversor gratuito de vídeo para GIF`,
        product_65:`Recuperação gratuita de dados do Android`,
        product_66:`Criador de apresentações de slides`,
        product_67:`Editor de vídeo gratuito`,
        product_68:`Visualizador de PDF gratuito`,
        product_69:`Gravador de ecrã`,
        product_70:`Gravador de Tela Mac`,
        product_71:`Gravador de Tela Gratuito Online`,
        product_72:`Gravador de áudio online gratuito`,
        product_73:`Espelho do telefone`,
        product_74:`AnyCoord - Alterador de localização`,
        product_75:`Gerenciador de Senhas do iPhone`,
        product_76:`Compressor de áudio online grátis`,
        product_77:`Aparador de Vídeo Gratuito Online`,
        product_78:`Cortador de Vídeo Gratuito Online`,
        product_79:`Rotator de vídeo on-line gratuito`,
        product_80:`Looper de Vídeo Gratuito Online`,
        product_81:`Conversor PDF JPG Gratuito Online`,
        product_81:`Conversor de palavras JPG gratuito on-line`,
        category_1:`Edição de fotos`,
        category_2:`Edição de vídeo`,
        category_3:`Apagador`,
        category_4:`gravador de mídia`,
        posted_by:`postado por`,
        to:`para`,
        author_1:`Ashley Mae`,
        author_2:`Jenny Ryan`,
        author_3:`Nicole Jones`,
        author_4:`Amanda Brown`,
        article_1:`Como remover texto de imagens com eficiência`,
        article_2:`Como aumentar o tamanho da imagem em KB para MB sem prejudicar a qualidade`,
        article_3:`Como remover a marca d'água Getty Images no Windows e Mac`,
        article_4:`O melhor método para converter imagens de baixa resolução para alta resolução online gratuitamente`,
        article_5:`Guia TikTok | Veja como fazer uma colagem de vídeo no TikTok`,
        article_6:`5 maneiras fáceis de combinar arquivos VOB que você deve saber`,
        article_7:`Como melhorar o vídeo e obter uma resolução mais alta facilmente`,
        article_8:`Como combinar arquivos MP4 no MacBook Pro/iMac/MacBook e mais`,
        article_9:`2 maneiras simples de excluir o histórico de compras no iPhone [Resolvido]`,
        article_10:`Como excluir fotos do Snapchat permanentemente do bate-papo e das memórias`,
        article_11:`Como excluir sugestões e histórico de pesquisa do Spotlight no iPhone/iPad`,
        article_12:`[Resolvido] Como limpar o cache do Instagram no iPhone com 3 maneiras fáceis`,
        article_13:`[Resolvido] Como corrigir o QuickTime Player não funcionando no Mac`,
        article_14:`Como gravar o áudio do sistema no Mac (Big Sur/Catalina/Mojave…)`,
        article_15:`Maneiras eficazes de corrigir a gravação de tela do PowerPoint que não está funcionando`,
        article_16:`Como gravar áudio com VLC [Resolvido]`,
        faq_title_1:`1. Como corrigir o erro 4104?`,
        faq_title_2:`2. Quando posso receber meu dinheiro de volta depois que o pedido de reembolso for aprovado?`,
        faq_title_3:`3. O que é "Assinatura de 1 ano" e como desativar o "Serviço de renovação automática"?`,
        faq_title_4:`4. Posso cancelar meu pedido depois de colocar o produto Aiseesoft?`,
        faq_title_5:`5. Como enviar uma avaliação para você?`,
        faq_info_1:`Isso se deve à licença limitada que você obteve. Adotamos Licença Única em caso de roubo do código de registro e evitamos a violação do uso do código de registro. Portanto, o Reg Code que você obteve é uma licença única, que só pode ser usada em um computador. Se você usá-lo em mais de um computador, haverá o erro 4104. Você não pode usá-lo até que o redefinamos para você.`,
        faq_info_2:`Se você alterar ou reinstalar seu computador, podemos redefini-lo para você. Entre em contato com o atendimento ao cliente: <a href="mailto:support@aiseesoftcom">support@aiseesoft.com</a> para solicitar a redefinição do código de registro para você.`,
        faq_info_3:`Depois que sua solicitação de reembolso for aprovada, normalmente levará de 3 a 7 dias úteis para que o crédito apareça em sua conta. Às vezes, durante as férias, pode demorar mais, mas não se espera que demore mais de 10 dias. Se você não receber o reembolso 20 dias após a aprovação, entre em contato conosco em <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> para verificar o reembolso.`,
        faq_info_4:`O serviço de renovação automática significa que, se você comprar uma assinatura de 1 ano, a plataforma de pagamento cobrará o valor automaticamente (incluindo impostos aplicáveis) quando a licença do produto expirar. Mas o formulário de pagamento (Swreg ou 2Checkout) enviará uma carta para lembrá-lo de que o pedido expirará 7 dias antes do vencimento. O e-mail também incluirá instruções de como desligar o "Serviço de Renovação Automática".`,
        faq_info_5:`Como o pedido é processado automaticamente, não é permitido cancelar o pedido. Além disso, a versão de avaliação gratuita permitirá que você saiba mais sobre o programa Aiseesoft e verifique se ele atende às suas necessidades antes de comprá-lo.`,
        faq_info_6:`Se você realmente deseja obter um reembolso, verifique nossa <a href="https://www.aiseesoft.com.br/refund-policy.html">Política de reembolso</a>.`,
        faq_info_7:`Agradecemos o seu tempo e atenção para enviar uma revisão para nós. Entre em contato conosco (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) para enviar sua análise e iremos considerá-la seriamente para as atualizações de nossos produtos.`,
        change_password_success:`Senha alterada com sucesso`,
        successful_login_title:`Sucesso de login`,
        product_page:`Página do produto>>`,
        successful_login_info:`Login concluído. Feche a página atual e volte para a guia original para continuar com o processo a seguir. A página atual será fechada automaticamente em 5 segundos. Se o fechamento automático ou o fechamento clicando no botão "Concluído" falhar, feche esta guia diretamente.`,
        successful_login_info_firefox:`Login concluído. Feche a página atual e volte para a guia original para continuar com o processo a seguir.`,
        my_account:`Minha conta`,
        my_history:`Minha história`,
        remove_watermark:`Remover marca d'água`,
        no_history:`Sem histórico`,
        history_all:`Selecionar tudo`,
        history_open:`Abrir`,
        history_down:`Download`,
        history_delete:`Excluir`,
        history_clear:`Limpar inválido`,
        images:`imagem(ns)`,
        use_this_function:`Use este produto>>`,
        hd_downloading:`Benefícios do download de imagens originais em HD:`,
        lifetimeRemaining:`Tempo de vida restante`,
        Remaining:`Restante`,
        email_verification:`verificação de e-mail`,
        email_verification_info:`Enviamos a mensagem de verificação para o seu e-mail <span class="email"></span> e finalize a verificação. Após a verificação, os benefícios serão sincronizados automaticamente.`,
        wrong_email:`Endereço de email errado?`,
        click_here_to_modify:`Clique aqui para modificar`,
        get_more_help:`Obter mais ajuda?`,
        click_here:`Clique aqui`,
        email_verification_info_success:`Parabéns por verificar sua conta de e-mail.`,
        email_verification_info_error:`A verificação falhou devido ao link expirado.`,
        registration_succeeded:`Registro bem-sucedido`,
        registration_succeeded_info_1:`Parabéns! Você se registrou com sucesso. Enviamos a mensagem de verificação para o seu e-mail <span class="email"><span> e conclua a verificação para usar os benefícios deste e-mail.`,
        registration_succeeded_info_2:`Clique em "Concluído" para retornar à página inicial e usar este produto.`,
        registration_succeeded_info_3:`Clique em "Concluído" para fechar a página atual e retornar à página inicial para as seguintes operações. Se o fechamento falhar, feche a guia manualmente.`,
        verify_email:`Verificar e-mail`,
        registered_email_not_verified:`O e-mail cadastrado não foi verificado, favor verificar imediatamente.`,
        email_verification_time_1:`Não recebeu um e-mail de verificação?`,
        email_verification_time_2:`Depois`,
        email_verification_time_3:`Clique aqui para reenviar`,
        error_26301:`Código de erro: 26301, entre em contato conosco`,
        error_26302:`Código de erro: 26302, entre em contato conosco`,
        error_26303:`Erro de formato de e-mail (código de erro: 26303). Por favor, digite-o novamente`,
        error_26304:`Senha acima de 8 caracteres é recomendada (código de erro: 26304)`,
        error_26305:`Reuqest falhou (código de erro: 26305). Por favor, tente novamente`,
        error_26306:`O e-mail foi registrado, <a href="https://account.aiseesoft.com/login">faça login</a>`,
        error_26307:`Reuqest falhou (código de erro: 26307). Por favor, tente novamente`,
        error_26308:`Reuqest falhou (código de erro: 26308). Por favor, tente novamente`,
        error_26401:`Código de erro: 26401, tente novamente`,
        error_26402:`O e-mail foi verificado (código de erro: 26402), tente novamente`,
        error_26403:`Reuqest falhou (código de erro: 26403). Por favor, tente novamente`,
        error_26404:`Falha na solicitação (código de erro: 26404). Por favor, tente novamente`,
        error_26501:`Código de erro: 26501, entre em contato conosco`,
        error_26502:`Código de erro: 26502, entre em contato conosco`,
        error_26503:`Erro de formato de e-mail (código de erro: 26503). Por favor, digite-o novamente`,
        error_26504:`Reuqest falhou (código de erro: 26504). Por favor, tente novamente`,
        error_26505:`O e-mail não foi registrado, <a href="https://account.aiseesoft.com/register">registre-o primeiro</a>`,
        error_26506:`O e-mail foi verificado.`,
        error_26507:`Reuqest falhou (código de erro: 26507). Por favor, tente novamente`,
        error_26508:`Verificação finalizada (código de erro: 26508), tente novamente`,
        error_26509:`Falha na solicitação (código de erro: 26509), tente novamente`,
        error_26510:`Código de erro: 26510, entre em contato conosco`,
        error_26601:`Código de erro: 26601, entre em contato conosco`,
        error_26602:`Código de erro: 26602, entre em contato conosco`,
        error_26603:`Reuqest falhou (código de erro: 26603). Por favor, tente novamente`,
        error_26604:`Código de erro: 26604, entre em contato conosco`,
        error_26605:`Código de erro: 26605, entre em contato conosco`,
        error_26701:`Código de erro: 26701, entre em contato conosco`,
        error_26702:`Reuqest falhou (código de erro: 26702). Por favor, tente novamente`,
        error_26703:`Código de erro: 26703, entre em contato conosco`,
        error_26704:`Código de erro: 26704, entre em contato conosco`,
        error_26705:`Aguarde o login (código de erro: 26705). Por favor, tente novamente`,
        no_cookie:`Você ativou a função Bloquear todos os cookies em seu navegador, portanto não pode fazer login. Vá para Configurações para marcar a caixa Permitir todos os cookies.`,
        error_26801:`Código de erro: 26801, entre em contato conosco`,
        error_26802:`Código de erro: 26802, entre em contato conosco`,
        error_26803:`Falha na solicitação (código de erro: 26803). Por favor, tente novamente`,
        error_26804:`Falha na solicitação (código de erro: 26804). Por favor, tente novamente`,
        error_order:`Falha na solicitação (código de erro: 27098), tente novamente!`,
        error_order1:`A consulta do pedido está incompleta（código de erro:`,
        error_order2:`）, atualize e tente novamente.`,
        modify_email_title:`Modificar e-mail`,
        modify_email_info:`Você pode usar o e-mail modificado para fazer login na sua conta.`,
        images_per:`Imagens por`,
        error_26101:`Erro: 26101. Entre em contato conosco`,
        error_26102:`Erro: 26102. Entre em contato conosco`,
        error_26103:`Falha na solicitação (código do erro: 26103). Por favor tente novamente`,
        error_26104:`Código de erro: 26104, tente novamente`,
        error_26105:`Código de erro: 26105, tente novamente`,
        error_26106:`Falha na exclusão (código do erro: 26106). Por favor, tente novamente`,
        error_26201:`Erro: 26201. Entre em contato conosco`,
        error_26202:`Falha na solicitação (código do erro: 26202). Por favor tente novamente`,
        error_26001:`Erro: 26001. Entre em contato conosco`,
        error_26002:`Erro: 26002. Entre em contato conosco`,
        error_26003:`Erro: 26003. Entre em contato conosco`,
        error_26004:`Erro: 26004. Entre em contato conosco`,
        error_26005:`Falha na solicitação (código do erro: 26005). Por favor tente novamente`,
        error_26006:`Código de erro: 26006, tente novamente`,
        error_26008:`Erro: 26008. Entre em contato conosco`,
        go_to_the_home_page:`Vá para a página inicial`,
        error_27101:`Falha na solicitação (código de erro: 27101). Tente novamente`,
        error_27201:`Código de erro: 27201, entre em contato conosco`,
        error_27202:`Código de erro: 27202, tente novamente`,
        error_27203:`Falha na solicitação (código de erro: 27203). Por favor, tente novamente`,
        error_27204:`Código inválido (código de erro: 27204).`,
        error_27205:`Falha na solicitação (código de erro: 27205). Por favor, tente novamente`,
        error_27206:`Falha na solicitação (código de erro: 27206). Por favor, tente novamente`,
        error_27207:`Falha na solicitação (código de erro: 27207). Por favor, tente novamente`,
        no_history_found:`Você não usou nenhuma ferramenta! <a href="/">Atualize</a> ou <a href="https://www.aiseesoft.com.br/">Acesse o site oficial</a>`,
        error_25301:`Erro: 25301. Entre em contato conosco`,
        error_25302:`Erro: 25302. Entre em contato conosco`,
        error_25303:`Falha na solicitação (código do erro: 25303). Por favor, tente novamente`,
        error_25304:`Falha na solicitação (código do erro: 25304). Por favor, tente novamente`,
        error_25305:`Falha na solicitação (código do erro: 25305). Por favor, tente novamente`,
        error_25306:`Falha na solicitação (código do erro: 25306). Por favor, tente novamente`,
        image_upscaler_p:`Benefícios de download de imagem sem marca d'água:`,
        Available_for:`Disponível para:`,
        credit_per:`%s crédito(s) por imagem HD`,
        still_valid:`O(s) plano(s) adquirido(s) ainda é válido(s)`,
        credit:`crédito(s)`,
        pc_3rd_info:`Login com sucesso. Por favor, acesse o aplicativo para operações adicionais.`,
        use_online:`Utilize o serviço on-line`,
        use_download:`Use o programa de desktop`,
        use_immediately:`Use imediatamente`,
        Use_in_browser:`Usar no navegador`,
        win_desktop:`janelas`,
        Mac_desktop:`Mac`,
    },
    ko:{
        overtime_1:`에러 코드:`,
        overtime_2:`, 다시 시도해 주세요`,
        isnetwork:`인터넷 오류입니다. 확인하고 다시 시도해 주세요.`,
        email_placeholder:`이메일`,
        email_empty:`이메일을 입력해주세요`,
        email_not_valid:`이메일이 유효하지 않습니다`,
        email_not_valid_1:`이메일을 입력해주세요`,
        email_not_valid_2:`이메일이 유효하지 않습니다. 다른 주소를 사용해 주세요.`,
        email_not_valid_3:`이메일 입력이 없습니다`,
        password_placeholder:`비밀번호`,
        password_empty:`비밀번호를 입력해주세요`,
        password_not_valid:`잘못된 계정 또는 비밀번호`,
        password_not_valid_1:`8자 이상의 비밀번호가 필요합니다`,
        password_not_valid_2:`비밀번호를 생성해주세요`,
        password_placeholder_1:`비밀번호를 만드세요`,
        password_placeholder_2:`암호를 확인`,
        password_placeholder_3:`새 비밀번호 만들기`,
        password_placeholder_4:`새 암호를 확인합니다`,
        password_placeholder_5:`이전 비밀번호를 입력하세요`,
        copy_password_empty:`비밀번호를 확인해 주세요`,
        copy_password_not_valid:`비밀번호를 확인해 주세요`,
        copy_passwords_inconsistent:`비밀번호 확인이 일치하지 않습니다`,
        code_empty:`인증번호를 입력해주세요`,
        code_not_valid:`잘못된 인증 코드`,
        code_placeholder:`확인 코드`,
        not_received_code:`오랫동안 메일함이 인증번호를 받지 못했다면 인증코드를 다시 받아보세요.`,
        get_first_code:`먼저 인증번호를 받아주세요.`,
        connect_your_email_placeholder:`이메일을 연결하세요`,
        last_name_placeholder:`당신의 성을 입력하세요`,
        first_name_placeholder:`이름을 입력하세요`,
        address_placeholder:`주소를 입력해주세요`,
        no_code_text:`인증 코드를 보냈습니다. 코드를 입력하세요. <span class='tips'>코드를 받지 못하셨나요?`,
        no_code_text_1:`1. 이메일 주소가 유효한지, 이메일을 받을 수 있는지 확인하세요.`,
        no_code_text_2:`2. 이메일이 시스템에 의해 자동으로 전송되는 경우 스팸 또는 정크 이메일로 표시될 수 있습니다. 이메일이 휴지통에 있는지 확인해주세요.`,
        no_code_text_3:`3. 문제를 해결할 수 없나요?`,
        no_code_text_3_span:`그런 다음 여기를 클릭하여 문의해 주세요.`,
        order_no:`구매한 제품이 없습니다. <a href="https://www.aiseesoft.com/store/">지금 구매</a>하세요! 질문이 있는 경우 <a href="https://www.aiseesoft.com/contacthtml">저희에게 연락</a>해 주세요.`,
        error_24901:`현재 계정에는 연결된 이메일이 없으며 주문을 찾을 수 없습니다. 이메일을 연결해주세요.`,
        user_guide:`이용안내>>`,
        download:`다운로드`,
        order_number:`주문 번호:`,
        Refund:`환불하다`,
        Disabled:`장애가 있는`,
        Normal:`정상`,
        Modify:`수정하다`,
        Modify_1:`수정>>`,
        Connect:`연결하다`,
        unlink_success:`연결이 해제되었습니다.`,
        connect_success:`성공적으로 연결되었습니다`,
        feedback_title:`피드백을 보내주셔서 감사합니다. 문제를 남겨주시면 24시간 이내에 답변해드리겠습니다.`,
        feedback_thank_you:`감사합니다!<br />귀하의 의견이 성공적으로 제출되었습니다.`,
        feedback_email:`여기에 이메일을 입력하세요!`,
        feedback_content:`발생한 문제나 제안 사항을 여기에 남겨주세요.`,
        feedback_submit:`제출하다`,
        form_contents:`설명을 입력하지 않았습니다. 입력하신 후 다시 제출해 주세요.`,
        old_password:`이전 비밀번호를 입력해주세요`,
        new_password:`새로운 비밀번호를 만들어주세요`,
        old_new_password:`새 비밀번호는 이전 비밀번호와 같을 수 없습니다.`,
        incorrect_password:`잘못된 비밀번호`,
        delete_no:`지금 삭제`,
        Caps:`Caps Lock이 켜져 있습니다.`,
        Get:`얻다`,
        Done:`완료`,
        error_20001:`오류: 20001. 다시 로그인하십시오.`,
        error_20002:`오류: 20002. 다시 로그인하십시오.`,
        error_20003:`오류: 20003. 다시 로그인하십시오.`,
        error_20004:`요청이 실패했습니다(오류 코드: 20004). 다시 시도해 주세요.`,
        error_20005:`로그인 세션이 만료되었습니다(오류: 20005). 다시 로그인해주세요.`,
        error_20006:`요청이 실패했습니다(오류 코드: 20006). 다시 시도해 주세요.`,
        error_20007:`로그인 세션이 만료되었습니다(오류: 20007). 다시 로그인해주세요.`,
        error_20008:`로그인 세션이 만료되었습니다(오류: 20008). 다시 로그인해주세요.`,
        error_20009:`로그인 세션이 만료되었습니다(오류: 20009). 다시 로그인해주세요.`,
        error_20101:`이메일을 입력해주세요. (오류코드: 20101)`,
        error_20102:`이메일이 유효하지 않습니다(오류 코드: 20102).`,
        error_20103:`요청이 실패했습니다(오류 코드: 20103). 다시 시도해 주세요`,
        error_20104:`이메일이 이미 사용 중입니다. <a href="https://account.aiseesoft.com/login">로그인</a>하거나 새 이메일로 등록하세요.`,
        error_20105:`요청이 실패했습니다(오류 코드: 20105). 다시 시도해 주세요`,
        error_20106:`이메일을 보내지 못했습니다. 다시 시도해 주세요.`,
        error_20201:`이메일을 입력해주세요. (오류코드: 20201)`,
        error_20202:`비밀번호를 입력해주세요. (오류코드: 20202)`,
        error_20203:`인증코드를 입력해주세요. (오류코드: 20203)`,
        error_20204:`이메일이 유효하지 않습니다(오류 코드: 20204).`,
        error_20205:`8자 이상의 비밀번호가 필요합니다. (에러코드: 20205)`,
        error_20206:`요청이 실패했습니다(오류 코드: 20206). 다시 시도해 주세요`,
        error_20207:`잘못된 인증 코드`,
        error_20208:`요청이 실패했습니다(오류 코드: 20208). 다시 시도해 주세요`,
        error_20209:`요청이 실패했습니다(오류 코드: 20209). 다시 시도해 주세요`,
        error_20301:`이메일을 입력해주세요. (오류코드: 20301)`,
        error_20302:`오류: 20302. 문의해 주세요.`,
        error_20303:`이메일이 유효하지 않습니다(오류 코드: 20303).`,
        error_20304:`요청이 실패했습니다(오류 코드: 20304). 다시 시도해 주세요`,
        error_20305:`계정이 존재하지 않습니다. 먼저 다시 입력하거나 <a href="https://account.aiseesoft.com/register">만들기</a>하세요.`,
        error_20306:`아직 비밀번호가 없습니다. <a href="https://account.aiseesoft.com/passwordless-login">비밀번호 없는 로그인</a>을 사용하거나 <a href="create-password">비밀번호를 설정</a>하여 로그인하세요.`,
        error_20308:`요청이 실패했습니다(오류 코드: 20308). 다시 시도해 주세요`,
        error_20401:`로그아웃에 실패했습니다(오류 코드: 20401). 다시 시도해 주세요`,
        error_20501:`이메일을 입력해주세요. (오류코드: 20501)`,
        error_20502:`이메일이 유효하지 않습니다(오류 코드: 20502).`,
        error_20503:`요청이 실패했습니다(오류 코드: 20503). 다시 시도해 주세요`,
        error_20504:`이메일을 보내지 못했습니다. 다시 시도해 주세요.`,
        error_20601:`이메일을 입력해주세요. (오류코드: 20601)`,
        error_20602:`인증코드를 입력해주세요. (오류코드: 20602)`,
        error_20603:`이메일이 유효하지 않습니다(오류 코드: 20603).`,
        error_20604:`요청이 실패했습니다(오류 코드: 20604). 다시 시도해 주세요`,
        error_20606:`요청이 실패했습니다(오류 코드: 20606). 다시 시도해 주세요`,
        error_20607:`요청이 실패했습니다(오류 코드: 20607). 다시 시도해 주세요`,
        error_20608:`요청이 실패했습니다(오류 코드: 20608). 다시 시도해 주세요`,
        error_20701:`이메일을 입력해주세요. (오류코드: 20701)`,
        error_20702:`이메일이 유효하지 않습니다(오류 코드: 20702).`,
        error_20703:`요청이 실패했습니다(오류 코드: 20703). 다시 시도해 주세요`,
        error_20704:`계정이 존재하지 않습니다. 먼저 다시 입력하거나 <a href="https://account.aiseesoft.com/register">만들기</a>하세요.`,
        error_20705:`요청이 실패했습니다(오류 코드: 20705). 다시 시도해 주세요`,
        error_20706:`이메일을 보내지 못했습니다. 다시 시도해 주세요`,
        error_20801:`이메일을 입력해주세요. (오류코드: 20801)`,
        error_20802:`오류: 20802. 문의해 주세요.`,
        error_20803:`인증번호를 입력해주세요. (오류코드: 20803)`,
        error_20804:`이메일이 유효하지 않습니다(오류 코드: 20804).`,
        error_20805:`8자 이상의 비밀번호가 필요합니다. (에러코드: 20805)`,
        error_20806:`요청이 실패했습니다(오류 코드: 20806). 다시 시도해 주세요`,
        error_20808:`요청이 실패했습니다(오류 코드: 20808). 다시 시도해 주세요`,
        error_20901:`요청이 실패했습니다(오류 코드: 20901). 다시 시도해 주세요`,
        error_20902:`요청이 실패했습니다(오류 코드: 20902). 다시 시도해 주세요`,
        error_21000:`변경사항이 저장되었습니다.`,
        error_21001:`제출된 정보가 없습니다(오류 코드: 21001).`,
        error_21002:`요청이 실패했습니다(오류 코드: 21002). 다시 시도해 주세요`,
        error_21101:`이메일을 입력해주세요. (오류코드: 21101)`,
        error_21102:`이메일이 유효하지 않습니다(오류 코드: 21102).`,
        error_21103:`요청이 실패했습니다(오류 코드: 21103). 다시 시도해 주세요.`,
        error_21104:`이메일이 이미 연결되어 있습니다. 새 이메일을 사용해 주세요.`,
        error_21105:`요청이 실패했습니다(오류 코드: 21105). 다시 시도해 주세요.`,
        error_21106:`이메일을 보내지 못했습니다. 다시 시도해 주세요`,
        error_21201:`이메일을 입력해주세요. (오류코드: 21201)`,
        error_21202:`인증코드를 입력해주세요. (오류코드: 21202)`,
        error_21203:`이메일이 유효하지 않습니다(오류 코드: 21203).`,
        error_21204:`오류: 21204. 문의해 주세요.`,
        error_21205:`오류: 21205. 문의해 주세요.`,
        error_21206:`8자 이상의 비밀번호가 필요합니다. (에러코드: 21206)`,
        error_21207:`요청이 실패했습니다(오류 코드: 21207). 다시 시도해 주세요`,
        error_21209:`요청이 실패했습니다(오류 코드: 21209). 다시 시도해 주세요`,
        error_21301:`이전 비밀번호를 입력해주세요. (오류코드: 21301)`,
        error_21302:`새로운 비밀번호를 만드세요. (오류코드: 21302)`,
        error_21303:`새 비밀번호는 이전 비밀번호와 같을 수 없습니다. (오류: 21303)`,
        error_21304:`8자 이상의 비밀번호가 필요합니다. (에러코드: 21304)`,
        error_21306:`요청이 실패했습니다(오류 코드: 21306). 다시 시도해 주세요`,
        error_21402:`요청이 실패했습니다(오류 코드: 21402). 다시 시도해 주세요`,
        error_21403:`인증코드 전송에 실패했습니다. 다시 보내주세요`,
        error_21500:`계정이 삭제되었습니다`,
        error_21501:`인증코드를 입력해주세요. (오류코드: 21501)`,
        error_21502:`로그인 세션이 만료되었습니다(오류: 21502). 다시 로그인해주세요.`,
        error_21503:`요청이 실패했습니다(오류 코드: 21503). 다시 시도해 주세요`,
        error_21505:`요청이 실패했습니다(오류 코드: 21505). 다시 시도해 주세요.`,
        error_21601:`오류: 20601. 문의해 주세요.`,
        error_21602:`잘못된 검증(오류: 20602). 다시 시도해 주세요.`,
        error_21603:`오류: 20603. 다시 시도해 주세요.`,
        error_21604:`요청이 실패했습니다(오류 코드: 21604). 다시 시도해 주세요`,
        error_21606:`요청이 실패했습니다(오류 코드: 21606). 다시 시도해 주세요`,
        error_21611:`요청이 실패했습니다(오류 코드: 21611). 다시 시도해 주세요`,
        error_21801:`오류: 21801. 문의해 주세요.`,
        error_21802:`요청이 실패했습니다(오류: 21802). 다시 시도해 주세요`,
        error_21803:`오류: 21803. 다시 시도해 주세요.`,
        error_21804:`요청이 실패했습니다(오류 코드: 21804). 다시 시도해 주세요`,
        error_21806:`오류: 21806. 다시 시도해 주세요.`,
        error_21807:`오류: 21807. 문의해 주세요.`,
        error_21808:`오류: 21808. 문의해 주세요.`,
        error_21809:`오류: 21809. 문의해 주세요.`,
        error_21810:`오류: 21810. 문의해 주세요.`,
        error_21811:`오류: 21811. 문의해 주세요.`,
        error_21812:`오류: 21812. 문의해 주세요.`,
        error_21813:`요청이 실패했습니다(오류 코드: 21813). 다시 시도해 주세요`,
        error_21814:`오류: 21814. 문의해 주세요.`,
        error_21815:`요청이 실패했습니다(오류 코드: 21815). 다시 시도해 주세요`,
        error_21816:`오류: 21816. 문의해 주세요.`,
        error_21817:`오류: 21817. 문의해 주세요.`,
        error_21818:`오류: 21818. 문의해 주세요.`,
        error_21819:`요청이 실패했습니다(오류 코드: 21819). 다시 시도해 주세요`,
        error_21820:`오류: 21820. 문의해 주세요.`,
        error_21821:`오류: 21821. 문의해 주세요.`,
        error_21822:`오류: 21822. 문의해 주세요.`,
        error_21823:`요청이 실패했습니다(오류 코드: 21823). 다시 시도해 주세요`,
        error_21824:`요청이 실패했습니다(오류 코드: 21824). 다시 시도해 주세요`,
        error_21825:`요청이 실패했습니다(오류 코드: 21825). 다시 시도해 주세요`,
        error_21826:`요청이 실패했습니다(오류 코드: 21826). 다시 시도해 주세요`,
        error_21828:`오류: 21828. 문의해 주세요.`,
        error_21901:`오류: 21901. 문의해 주세요.`,
        error_21902:`요청이 실패했습니다(오류 코드: 21902). 다시 시도해 주세요`,
        error_21903:`계정 상태가 변경되었습니다(오류 코드: 21903). 페이지를 새로 고친 후 다시 시도해 주세요.`,
        error_21904:`오류: 21904. 다시 시도해 주세요.`,
        error_21905:`오류: 21905. 다시 시도해 주세요.`,
        error_21906:`요청이 실패했습니다(오류 코드: 21906). 다시 시도해 주세요`,
        error_21907:`Google 계정이 다른 계정에 연결되었습니다`,
        error_21908:`요청이 실패했습니다(오류 코드: 21908). 다시 시도해 주세요`,
        error_22001:`요청이 실패했습니다(오류 코드: 22001). 다시 시도해 주세요`,
        error_22002:`추가 로그인 없이 연결 해제에 실패했습니다.`,
        error_22003:`요청이 실패했습니다(오류 코드: 22003). 다시 시도해 주세요`,
        error_22101:`오류: 22101. 문의해 주세요.`,
        error_22102:`계정 상태가 변경되었습니다(오류 코드: 22102). 페이지를 새로 고친 후 다시 시도해 주세요.`,
        error_22103:`요청이 실패했습니다(오류 코드: 22103). 다시 시도해 주세요`,
        error_22104:`계정 상태가 변경되었습니다(오류 코드: 22104). 페이지를 새로 고친 후 다시 시도해 주세요.`,
        error_22105:`오류: 22105. 다시 시도해 주세요.`,
        error_22106:`오류: 22106. 다시 시도해 주세요.`,
        error_22107:`오류: 22107. 문의해 주세요.`,
        error_22108:`요청이 실패했습니다(오류 코드: 22108). 다시 시도해 주세요`,
        error_22201:`오류: 22201. 문의해 주세요.`,
        error_22202:`잘못된 검증(오류: 22202). 다시 시도해 주세요.`,
        error_22203:`오류: 22203. 다시 시도해 주세요."`,
        error_22204:`요청이 실패했습니다(오류 코드: 22204). 다시 시도해 주세요`,
        error_22206:`요청이 실패했습니다(오류 코드: 22206). 다시 시도해 주세요`,
        error_22401:`오류: 22401. 문의해 주세요.`,
        error_22402:`잘못된 검증(오류: 22402). 다시 시도해 주세요.`,
        error_22403:`오류: 22403. 다시 시도해 주세요.`,
        error_22404:`요청이 실패했습니다(오류 코드: 22404). 다시 시도해 주세요`,
        error_22405:`Facebook 계정이 다른 이메일에 연결되었습니다`,
        error_22406:`오류: 22406. 다시 시도해 주세요.`,
        error_22407:`오류: 22407. 문의해 주세요.`,
        error_22408:`오류: 22408. 문의해 주세요.`,
        error_22409:`오류: 22409. 문의해 주세요.`,
        error_22410:`오류: 224010. 문의해 주세요.`,
        error_22411:`오류: 224011. 문의해 주세요.`,
        error_22412:`오류: 224012. 문의해 주세요.`,
        error_22413:`오류: 22413. 문의해 주세요.`,
        error_22414:`요청이 실패했습니다(오류 코드: 22414). 다시 시도해 주세요`,
        error_22415:`오류: 22415. 문의해 주세요.`,
        error_22416:`오류: 22416. 문의해 주세요.`,
        error_22417:`오류: 22417. 문의해 주세요.`,
        error_22418:`요청이 실패했습니다(오류 코드: 22418). 다시 시도해 주세요`,
        error_22419:`오류: 22419. 문의해 주세요.`,
        error_22420:`오류: 22420. 문의해 주세요.`,
        error_22421:`오류: 22421. 문의해 주세요.`,
        error_22422:`요청이 실패했습니다(오류 코드: 22422). 다시 시도해 주세요`,
        error_22423:`요청이 실패했습니다(오류 코드: 22423). 다시 시도해 주세요`,
        error_22424:`요청이 실패했습니다(오류 코드: 22424). 다시 시도해 주세요`,
        error_22425:`요청이 실패했습니다(오류 코드: 22425). 다시 시도해 주세요`,
        error_20098:`오류 코드: 20098. 비공개 모드에서 검색하는 경우 일반 모드로 전환한 후 다시 시도하세요.`,
        error_22298:`Google 요청이 실패했습니다(오류: 22298). 다시 시도해 주세요.`,
        error_22498:`Facebook 요청이 실패했습니다(오류: 22498). 다시 시도해 주세요.`,
        error_24902:`요청이 실패했습니다(오류 코드: 24902). 다시 시도해 주세요`,
        error_24903:`요청이 실패했습니다(오류 코드: 24903). 다시 시도해 주세요`,
        error_24904:`요청이 실패했습니다(오류 코드: 24904). 다시 시도해 주세요`,
        error_24905:`요청이 실패했습니다(오류 코드: 24905). 다시 시도해 주세요`,
        login_title:`Aiseesoft에 로그인`,
        password_placeholder:`비밀번호`,
        log_in:`로그인`,
        no_account:`계정이 없나요?`,
        create_it:`만들기`,
        or_log_in_with:`또는 다음으로 로그인하세요.`,
        passwordless_login:`비밀번호 없는 로그인`,
        log_in_done:`로그인 완료`,
        three_rd_account_connect_info:`축하해요! 성공적으로 로그인되었습니다. 이제 향후 로그인에 사용되는 하나의 이메일 계정을 연결할 수 있습니다.`,
        connect_now:`지금 연결하세요`,
        see_my_account:`내 계정 보기`,
        three_rd_login_merge_account:`타사 계정 이메일 주소가 가입되어 있습니다. 해당 이메일 주소로 직접 접속하여 로그인하시겠습니까?`,
        connect_log_in:`연결 및 로그인`,
        create_an_account:`계정 만들기`,
        connect_account:`계정 연결`,
        connect_account_info:`연결 후 이 계정으로 로그인하실 수 있습니다.`,
        back_to_log_in:`로그인으로 돌아가기`,
        create_password:`비밀번호 생성`,
        create_now:`지금 만들기`,
        password_login_subtitle:`이메일을 이용한 비밀번호 없는 로그인`,
        account_login:`계정 로그인`,
        rights:`이 계정을 만들면 <a href="https://www.aiseesoft.com/company/terms-and-conditions.html">서비스 약관</a> 및 <a href="https://www.aiseesoft.comprivacy-policy.html">개인정보 보호정책</a>`,
        passwordless_login_done:`비밀번호 없이 로그인 완료`,
        passwordless_login_info:`축하합니다. 비밀번호 없는 로그인이 성공적으로 완료되었습니다. 이 계정에 대한 비밀번호를 생성하고 나중에 해당 계정과 비밀번호로 로그인할 수 있습니다. <a href="/create-password" style="display:initial;">지금 만들기</a>`,
        sign_up:`가입하기`,
        register_info:`계정 만들기`,
        reset_now:`지금 재설정`,
        forgot_password:`비밀번호를 잊으 셨나요`,
        reset_password_subtitle:`계정 이메일을 사용하여 비밀번호를 재설정하세요.`,
        plan_products:`계획 및 제품`,
        nick_name:`사용자 이름:`,
        email:`이메일:`,
        my_products:`내 제품`,
        my_orders:`내 주문`,
        unlink:`풀리다`,
        link:`링크`,
        connected_accounts:`연결된 계정`,
        last_name:`성:`,
        first_name:`이름:`,
        Gender:`성별:`,
        Birth:`출생:`,
        Month:`월`,
        Year:`년도`,
        Country_Region:`국가/지역:`,
        Address:`주소:`,
        Save:`구하다`,
        Date:`날짜`,
        Male:`남성`,
        Female:`여성`,
        Unspecified:`지정되지 않음`,
        Security:`보안`,
        change_password:`비밀번호 변경`,
        change_now:`지금 변경`,
        connect_email:`이메일 연결`,
        delete_account:`계정 삭제`,
        delete_account_info:`계정이 삭제되면 해당 계정과 연결된 플랜 및 상품의 모든 데이터가 영구적으로 삭제되며 복구가 불가능할 수 있습니다. 주의해서 진행하시기 바랍니다.`,
        Delete:`삭제`,
        Logout:`로그 아웃`,
        my_profile:`내 프로필`,
        guides_faqs:`가이드 및 FAQ`,
        More:`더`,
        guides:`가이드`,
        register:`등록하다`,
        hot_faq:`뜨거운 FAQ`,
        Contents:`,`,
        contact_us:`문의하기>>`,
        plan:`계획`,
        unregistered:`미등록`,
        buy_more:`추가 구매`,
        buy_again:`다시 구매`,
        buy_now:`지금 구매`,
        free_no_limit:`무료 & 제한 없음`,
        expired:`만료됨`,
        lifetime:`일생`,
        remain:`유지하다`,
        day_s:`날)`,
        error_24801:`요청이 실패했습니다(오류 코드: 24801). 다시 시도해 주세요`,
        no_app_found:`앱을 찾을 수 없습니다!<a href="/">새로 고침</a> 또는 <a href="https://www.aiseesoft.com/">공식 웹사이트로 이동</a>`,
        get_more:`더 알아보기 >>`,
        edit_photo:`사진 편집`,
        select_photo:`사진 선택`,
        change_photo:`사진 변경`,
        cancel:`취소`,
        hide_password:`비밀번호 숨기기`,
        show_password:`비밀번호 표시`,
        zoom_in:`확대`,
        zoom_out:`축소`,
        rotate:`회전`,
        horizontal_flip:`수평 뒤집기`,
        vertical_flip:`수직 뒤집기`,
        country:`국가`,
        country_1:`국가/지역을 선택하세요.`,
        country_2:`올란드섬`,
        country_3:`아프가니스탄`,
        country_4:`알바니아`,
        country_5:`알제리`,
        country_6:`아메리칸사모아`,
        country_7:`안도라`,
        country_8:`앙골라`,
        country_9:`앵귈라`,
        country_10:`남극 대륙`,
        country_11:`앤티가 바부다`,
        country_12:`아르헨티나`,
        country_13:`아르메니아`,
        country_14:`아루바`,
        country_15:`호주`,
        country_16:`오스트리아`,
        country_17:`아제르바이잔`,
        country_18:`바레인`,
        country_19:`방글라데시`,
        country_20:`바베이도스`,
        country_21:`벨라루스`,
        country_22:`벨기에`,
        country_23:`벨리즈`,
        country_24:`베냉`,
        country_25:`버뮤다`,
        country_26:`부탄`,
        country_27:`볼리비아`,
        country_28:`보스니아 및 헤르체고비나`,
        country_29:`보츠와나`,
        country_30:`부베 섬`,
        country_31:`브라질`,
        country_32:`영국령 인도양 지역`,
        country_33:`영국령 버진아일랜드`,
        country_34:`브루나이`,
        country_35:`불가리아`,
        country_36:`부르키나`,
        country_37:`부룬디`,
        country_38:`캄보디아`,
        country_39:`카메룬`,
        country_40:`캐나다`,
        country_41:`카보베르데`,
        country_42:`카리브해 네덜란드`,
        country_43:`케이맨 제도`,
        country_44:`중앙 아프리카 공화국`,
        country_45:`차드`,
        country_46:`칠레`,
        country_47:`중국`,
        country_48:`크리스마스 섬`,
        country_49:`코코스(킬링) 제도`,
        country_50:`콜롬비아`,
        country_51:`쿡 제도`,
        country_52:`코스타리카`,
        country_53:`코트디부아르`,
        country_54:`크로아티아`,
        country_55:`쿠바`,
        country_56:`키프로스`,
        country_57:`체코 공화국`,
        country_58:`콩고민주공화국`,
        country_59:`덴마크`,
        country_60:`지부티`,
        country_61:`도미니카`,
        country_62:`도미니카 공화국`,
        country_63:`에콰도르`,
        country_64:`이집트`,
        country_65:`엘살바도르`,
        country_66:`적도기니`,
        country_67:`에리트레아`,
        country_68:`에스토니아`,
        country_69:`에티오피아`,
        country_70:`포클랜드 제도`,
        country_71:`페로 제도`,
        country_72:`미크로네시아 연방`,
        country_73:`피지`,
        country_74:`핀란드`,
        country_75:`프랑스`,
        country_76:`프랑스령 기아나`,
        country_77:`프랑스 령 폴리네시아의`,
        country_78:`프랑스 남부 영토`,
        country_79:`가봉`,
        country_80:`감비아`,
        country_81:`그루지야`,
        country_82:`독일`,
        country_83:`가나`,
        country_84:`지브롤터`,
        country_85:`영국(영국, 잉글랜드)`,
        country_86:`그리스`,
        country_87:`그린란드`,
        country_88:`그레나다`,
        country_89:`과들루프`,
        country_90:`괌`,
        country_91:`과테말라`,
        country_92:`건지`,
        country_93:`기니`,
        country_94:`기니비사우`,
        country_95:`가이아나`,
        country_96:`아이티`,
        country_97:`허드 섬 및 맥도날드 섬`,
        country_98:`온두라스`,
        country_99:`홍콩`,
        country_100:`헝가리`,
        country_101:`아이슬란드`,
        country_102:`인도`,
        country_103:`인도네시아`,
        country_104:`이란`,
        country_105:`이라크`,
        country_106:`아일랜드`,
        country_107:`맨 섬`,
        country_108:`이스라엘`,
        country_109:`이탈리아`,
        country_110:`자메이카`,
        country_111:`일본`,
        country_112:`저지`,
        country_113:`요르단`,
        country_114:`카자흐스탄`,
        country_115:`케냐`,
        country_116:`키리바시`,
        country_117:`쿠웨이트`,
        country_118:`키르기스스탄`,
        country_119:`라오스`,
        country_120:`라트비아`,
        country_121:`레바논`,
        country_122:`레소토`,
        country_123:`라이베리아`,
        country_124:`리비아`,
        country_125:`리히텐슈타인`,
        country_126:`리투아니아`,
        country_127:`룩셈부르크`,
        country_128:`마카오`,
        country_129:`마다가스카르`,
        country_130:`말라위`,
        country_131:`말레이시아`,
        country_132:`몰디브`,
        country_133:`말리`,
        country_134:`몰타`,
        country_135:`마샬 제도`,
        country_136:`마르티니크`,
        country_137:`모리타니`,
        country_138:`모리셔스`,
        country_139:`마요트`,
        country_140:`멕시코`,
        country_141:`몰도바`,
        country_142:`모나코`,
        country_143:`몽골리아`,
        country_144:`몬테네그로`,
        country_145:`몬세라트`,
        country_146:`모로코`,
        country_147:`모잠비크`,
        country_148:`미얀마(버마)`,
        country_149:`나미비아`,
        country_150:`나우루`,
        country_151:`네팔`,
        country_152:`네덜란드`,
        country_153:`뉴 칼레도니아`,
        country_154:`뉴질랜드`,
        country_155:`니카라과`,
        country_156:`니제르`,
        country_157:`나이지리아`,
        country_158:`니우에`,
        country_159:`노퍽 섬`,
        country_160:`북한`,
        country_161:`북마리아나 제도`,
        country_162:`노르웨이`,
        country_163:`오만`,
        country_164:`파키스탄`,
        country_165:`팔라우`,
        country_166:`팔레스타인 영토`,
        country_167:`파나마`,
        country_168:`파푸아 뉴기니`,
        country_169:`파라과이`,
        country_170:`페루`,
        country_171:`핏케언 제도`,
        country_172:`폴란드`,
        country_173:`포르투갈`,
        country_174:`푸에르토 리코`,
        country_175:`카타르`,
        country_176:`마케도니아 공화국(FYROM)`,
        country_177:`콩고공화국`,
        country_178:`재결합`,
        country_179:`루마니아`,
        country_180:`러시아 연방`,
        country_181:`르완다`,
        country_182:`생 바르텔레미`,
        country_183:`세인트 마틴(프랑스)`,
        country_184:`생피에르와 미클롱`,
        country_185:`사모아`,
        country_186:`산 마리노`,
        country_187:`상투메 프린시페`,
        country_188:`사우디 아라비아`,
        country_189:`세네갈`,
        country_190:`세르비아`,
        country_191:`세이셸`,
        country_192:`시에라리온`,
        country_193:`싱가포르`,
        country_194:`슬로바키아`,
        country_195:`슬로베니아`,
        country_196:`솔로몬 제도`,
        country_197:`소말리아`,
        country_198:`남아프리카`,
        country_199:`사우스조지아 및 사우스샌드위치 섬`,
        country_200:`대한민국`,
        country_201:`남 수단`,
        country_202:`스페인`,
        country_203:`스리랑카`,
        country_204:`세인트 헬레나 및 종속성`,
        country_205:`세인트 키츠 & 네비스`,
        country_206:`세인트루시아`,
        country_207:`세인트 빈센트 그레나딘`,
        country_208:`수단`,
        country_209:`수리남`,
        country_210:`스와질란드`,
        country_211:`스웨덴`,
        country_212:`스위스`,
        country_213:`시리아`,
        country_214:`대만`,
        country_215:`타지키스탄`,
        country_216:`탄자니아`,
        country_217:`템플릿:국가 데이터 SJM 스발바르`,
        country_218:`태국`,
        country_219:`바하마 제도`,
        country_220:`코모로`,
        country_221:`필리핀`,
        country_222:`동티모르(동티모르)`,
        country_223:`토고`,
        country_224:`토켈라우`,
        country_225:`통가`,
        country_226:`트리니다드 토바고`,
        country_227:`튀니지`,
        country_228:`칠면조`,
        country_229:`투르크메니스탄`,
        country_230:`터크스 케이커스 제도`,
        country_231:`투발루`,
        country_232:`우간다`,
        country_233:`우크라이나`,
        country_234:`아랍 에미리트`,
        country_235:`미국 군소 외딴 섬`,
        country_236:`미국(USA)`,
        country_237:`미국 버진아일랜드`,
        country_238:`우루과이`,
        country_239:`우즈베키스탄`,
        country_240:`바누아투`,
        country_241:`바티칸 시티(교황청)`,
        country_242:`베네수엘라`,
        country_243:`베트남`,
        country_244:`월리스 푸투나`,
        country_245:`서부 사하라`,
        country_246:`예멘`,
        country_247:`잠비아`,
        country_248:`짐바브웨`,
        google_login:`Google로 로그인`,
        State:`상태`,
        Activation_code:`활성화 코드`,
        Question:`로그인한 모든 앱 나열`,
        Copy_complete:`복사 완료`,
        footer:`저작권 © 2024 Aiseesoft Studio. 판권 소유.`,
        store:`가게`,
        resource:`자원`,
        support:`지원하다`,
        video_converter:`비디오 변환기`,
        multimedia:`멀티미디어`,
        data_recovery:`데이터 복구`,
        utility:`공익사업`,
        free_tools:`무료 도구`,
        for_windows:`윈도우의 경우`,
        for_mac:`맥의 경우`,
        pdf_solutions:`PDF 솔루션`,
        data_manager:`데이터 관리자`,
        other_tools:`기타 도구`,
        free_online_solutions:`무료 온라인 솔루션`,
        free_online_tools:`무료 온라인 도구`,
        desktop_freeware:`데스크탑 프리웨어`,
        product_1:`비디오 컨버터 궁극`,
        product_2:`총 비디오 변환기`,
        product_3:`MTS 변환기`,
        product_4:`4K 변환기`,
        product_5:`MXF 변환기`,
        product_6:`3D 변환기`,
        product_7:`Mac 비디오 변환기 궁극`,
        product_8:`Mac용 비디오 변환기`,
        product_9:`Mac용 MTS 변환기`,
        product_10:`Mac용 4K 변환기`,
        product_11:`Mac용 MXF 변환기`,
        product_12:`Mac용 3D 변환기`,
        product_13:`블루레이 플레이어`,
        product_14:`비디오 인핸서`,
        product_15:`동영상 편집기`,
        product_16:`슬라이드쇼 생성기`,
        product_17:`블루레이 크리에이터`,
        product_18:`Burnova - DVD 크리에이터 프로`,
        product_19:`DVD 크리에이터`,
        product_20:`맥 블루레이 플레이어`,
        product_21:`맥 비디오 인핸서`,
        product_22:`Mac용 비디오 편집기`,
        product_23:`Mac용 DVD 크리에이터`,
        product_24:`Mac용 MP4 변환기`,
        product_25:`윈도우 데이터 복구`,
        product_26:`FoneLab 아이폰 데이터 복구`,
        product_27:`FoneLab iOS 시스템 복구`,
        product_28:`FoneLab iOS 데이터 백업 및amp; 복원하다`,
        product_29:`FoneLab 안드로이드 데이터 복구`,
        product_30:`깨진 안드로이드 데이터 추출`,
        product_31:`Android 데이터 백업 및 앰프; 복원하다`,
        product_32:`맥 데이터 복구`,
        product_33:`맥 FoneLab 아이폰 데이터 복구`,
        product_34:`맥 FoneLab iOS 시스템 복구`,
        product_35:`Mac FoneLab iOS 데이터 백업 및 앰프; 복원하다`,
        product_36:`맥 FoneLab 안드로이드 데이터 복구`,
        product_37:`Mac Android 데이터 백업 및amp; 복원하다`,
        product_38:`PDF 변환기 궁극`,
        product_39:`Mac용 PDF 변환기 궁극`,
        product_40:`PDF 병합`,
        product_41:`Mac PDF를 ePub으로 변환기`,
        product_42:`맥 클리너`,
        product_43:`폰트랜스`,
        product_44:`iOS용 WhatsApp 전송`,
        product_45:`MobieSync`,
        product_46:`폰이레이저`,
        product_47:`아이폰 클리너`,
        product_48:`PPT를 비디오 변환기로`,
        product_49:`HEIC 변환기`,
        product_50:`아이폰 잠금해제`,
        product_51:`온라인 무료 비디오 변환기`,
        product_52:`온라인 무료 오디오 변환기`,
        product_53:`온라인 무료 비디오 압축기`,
        product_54:`무료 비디오 합병 온라인`,
        product_55:`무료 HEIC 변환기`,
        product_56:`무료 이미지 압축기`,
        product_57:`무료 워터마크 제거제`,
        product_58:`무료 PDF 압축기 온라인`,
        product_59:`무료 이미지 업스케일러 온라인`,
        product_60:`무료 온라인 배경 제거제`,
        product_61:`무료 이미지 변환기 온라인`,
        product_62:`무료 PDF PNG 변환기 온라인`,
        product_63:`무료 비디오 변환기`,
        product_64:`무료 비디오를 GIF로 변환기`,
        product_65:`무료 안드로이드 데이터 복구`,
        product_66:`슬라이드쇼 메이커`,
        product_67:`무료 비디오 편집기`,
        product_68:`무료 PDF 뷰어`,
        product_69:`스크린 레코더`,
        product_70:`맥 스크린 레코더`,
        product_71:`무료 스크린 레코더 온라인`,
        product_72:`무료 오디오 레코더 온라인`,
        product_73:`전화거울`,
        product_74:`AnyCoord - 위치 체인저`,
        product_75:`아이폰 비밀번호 관리자`,
        product_76:`무료 오디오 압축기 온라인`,
        product_77:`무료 비디오 트리머 온라인`,
        product_78:`무료 온라인 비디오 자르기 도구`,
        product_79:`무료 비디오 로테이터 온라인`,
        product_80:`무료 비디오 루퍼 온라인`,
        product_81:`온라인 무료 PDF JPG 변환기`,
        product_81:`온라인 무료 JPG Word 변환기`,
        category_1:`사진 편집`,
        category_2:`영상 편집`,
        category_3:`지우개`,
        category_4:`미디어 레코더`,
        posted_by:`게시자:`,
        to:`에게`,
        author_1:`애슐리 매`,
        author_2:`제니 라이언`,
        author_3:`니콜 존스`,
        author_4:`아만다 브라운`,
        article_1:`사진에서 텍스트를 효율적으로 제거하는 방법`,
        article_2:`품질을 손상시키지 않고 이미지 크기를 KB 단위에서 MB 단위로 늘리는 방법`,
        article_3:`Windows 및 Mac에서 Getty Images 워터마크를 제거하는 방법`,
        article_4:`저해상도 이미지를 온라인 고해상도로 무료로 변환하는 가장 좋은 방법`,
        article_5:`틱톡 가이드 | TikTok에서 비디오 콜라주를 만드는 방법은 다음과 같습니다.`,
        article_6:`꼭 알아야 할 VOB 파일을 결합하는 5가지 쉬운 방법`,
        article_7:`비디오를 향상하고 더 높은 해상도를 쉽게 얻는 방법`,
        article_8:`MacBook Pro/iMac/MacBook 등에서 MP4 파일을 결합하는 방법`,
        article_9:`iPhone에서 구매 내역을 삭제하는 2가지 간단한 방법 [해결됨]`,
        article_10:`채팅 및 추억에서 Snapchat 사진을 영구적으로 삭제하는 방법`,
        article_11:`iPhone/iPad에서 Spotlight 검색 제안 및 기록을 삭제하는 방법`,
        article_12:`[해결됨] iPhone에서 Instagram 캐시를 지우는 3가지 쉬운 방법`,
        article_13:`[해결됨] Mac에서 QuickTime Player가 작동하지 않는 문제를 해결하는 방법`,
        article_14:`Mac에서 시스템 오디오를 녹음하는 방법(Big Sur/Catalina/Mojave …)`,
        article_15:`PowerPoint 화면 녹화가 작동하지 않는 문제를 해결하는 효과적인 방법`,
        article_16:`VLC로 오디오를 녹음하는 방법 [해결됨]`,
        faq_title_1:`1. 4104 오류를 해결하는 방법은 무엇입니까?`,
        faq_title_2:`2. 환불요청 승인 후 언제 환불받을 수 있나요?`,
        faq_title_3:`3. "1년 구독"이란 무엇이며 "자동 갱신 서비스"를 끄는 방법은 무엇입니까?`,
        faq_title_4:`4. Aiseesoft 제품을 배치한 후 주문을 취소할 수 있나요?`,
        faq_title_5:`5. 리뷰를 어떻게 제출하나요?`,
        faq_info_1:`이는 귀하가 취득한 라이센스가 제한되어 있기 때문입니다. 등록코드가 도용된 경우를 대비하여 Single License를 채택하고 있으며, 등록코드 위반 사용을 방지하고 있습니다. 그래서 당신이 얻은 RegCode는 하나의 컴퓨터에서만 사용할 수 있는 단일 라이센스입니다. 두 대 이상의 컴퓨터에서 사용하는 경우 오류 4104가 발생합니다. 재설정할 때까지 사용할 수 없습니다.`,
        faq_info_2:`컴퓨터를 변경하거나 다시 설치하는 경우 당사에서 재설정해 드릴 수 있습니다. 등록 코드 재설정을 요청하려면 고객 서비스(<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>)에 문의하세요.`,
        faq_info_3:`환불 요청이 승인되면 일반적으로 계정에 크레딧이 표시되기까지 영업일 기준 3~7일이 소요됩니다. 경우에 따라 연휴 기간이 길어질 수 있으나 10일을 넘지 않을 것으로 예상됩니다. 승인 후 20일이 지나도 환불을 받지 못한 경우 <a href="mailto:support@aiseesoft.com">support@aiseesoft.com<a>으로 문의하여 환불 내역을 확인하시기 바랍니다.`,
        faq_info_4:`자동 갱신 서비스는 귀하가 1년 구독을 구매하는 경우 제품 라이센스가 만료되면 결제 플랫폼에서 해당 금액(해당 세금 포함)을 자동으로 청구한다는 의미입니다. 하지만 결제 플랫폼(Swreg 또는 2Checkout)에서는 주문이 만료되기 7일 전에 만료될 것임을 알리는 편지를 보내드립니다. 이메일에는 "자동 갱신 서비스"를 끄는 방법에 대한 지침도 포함되어 있습니다.`,
        faq_info_5:`주문은 자동으로 처리되므로 주문을 취소할 수 없습니다. 게다가 무료 평가판을 사용하면 Aiseesoft 프로그램에 대해 더 많이 알 수 있고 구매하기 전에 프로그램이 귀하의 요구 사항을 충족할 수 있는지 확인할 수 있습니다.`,
        faq_info_6:`정말로 환불을 받고 싶다면 <a href="https://www.aiseesoft.com/refund-policy.html">환불 정책</a>을 확인하세요.`,
        faq_info_7:`시간을 내어 리뷰를 제출해 주셔서 감사합니다. 귀하의 리뷰를 제출하려면 저희(<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>)에 연락해 주십시오. 제품 업데이트에 대한 귀하의 리뷰를 진지하게 고려할 것입니다.`,
        change_password_success:`비밀번호가 성공적으로 변경되었습니다.`,
        successful_login_title:`로그인 성공`,
        product_page:`제품 페이지>>`,
        successful_login_info:`로그인이 완료되었습니다. 다음 프로세스를 계속하려면 현재 페이지를 닫고 원래 탭으로 돌아가세요. 현재 페이지는 5초 후에 자동으로 닫힙니다. 자동 종료나 '완료' 버튼 클릭으로 닫을 수 없는 경우에는 이 탭을 직접 닫아주세요.`,
        successful_login_info_firefox:`로그인이 완료되었습니다. 다음 프로세스를 계속하려면 현재 페이지를 닫고 원래 탭으로 돌아가세요.`,
        my_account:`내 계정`,
        my_history:`내 역사`,
        remove_watermark:`워터마크 제거`,
        no_history:`기록 없음`,
        history_all:`모두 선택`,
        history_open:`열려 있는`,
        history_down:`다운로드`,
        history_delete:`삭제`,
        history_clear:`무효 삭제`,
        images:`이미지`,
        use_this_function:`이 제품을 사용하세요>>`,
        hd_downloading:`HD 원본 이미지 다운로드 혜택:`,
        lifetimeRemaining:`남은 수명`,
        Remaining:`남은`,
        email_verification:`이메일 확인`,
        email_verification_info:`귀하의 이메일 <span class="email"></span>으로 확인 메시지를 보냈으니 확인을 완료해 주시기 바랍니다. 확인 후 혜택은 자동으로 동기화되지 않습니다.`,
        wrong_email:`이메일 주소가 잘못되었나요?`,
        click_here_to_modify:`수정하려면 여기를 클릭하세요.`,
        get_more_help:`추가 도움을 받으시겠습니까?`,
        click_here:`여기를 클릭하세요`,
        email_verification_info_success:`이메일 계정을 확인하신 것을 축하드립니다.`,
        email_verification_info_error:`링크가 만료되어 확인에 실패했습니다.`,
        registration_succeeded:`등록이 성공했습니다`,
        registration_succeeded_info_1:`축하해요! 성공적으로 등록되었습니다. 귀하의 이메일 <span class="email"></span>으로 확인 메시지가 전송되었습니다. 이 이메일의 혜택을 이용하시려면 확인을 완료해 주시기 바랍니다.`,
        registration_succeeded_info_2:`홈 페이지로 돌아가서 이 제품을 사용하려면 "완료"를 클릭하세요.`,
        registration_succeeded_info_3:`현재 페이지를 닫고 다음 작업을 위해 홈 페이지로 돌아가려면 "완료"를 클릭하세요. 닫지 못한 경우 탭을 수동으로 닫아주세요.`,
        verify_email:`이메일 확인`,
        registered_email_not_verified:`등록된 이메일이 확인되지 않았습니다. 한번 확인해주세요.`,
        email_verification_time_1:`확인 이메일을 받지 못하셨나요?`,
        email_verification_time_2:`후에`,
        email_verification_time_3:`다시 보내려면 여기를 클릭하세요.`,
        error_26301:`오류 코드: 26301, 문의해 주세요.`,
        error_26302:`오류 코드: 26302, 문의해 주세요.`,
        error_26303:`이메일 형식 오류(오류 코드: 26303) 다시 입력해 주세요`,
        error_26304:`비밀번호는 8자 이상을 권장합니다. (에러코드: 26304)`,
        error_26305:`Reuqest가 실패했습니다(오류 코드: 26305). 다시 시도해주세요`,
        error_26306:`이메일이 등록되었습니다. <a href="https://account.aiseesoft.com/login">로그인으로 이동</a>해 주세요.`,
        error_26307:`Reuqest가 실패했습니다(오류 코드: 26307). 다시 시도해주세요`,
        error_26308:`Reuqest가 실패했습니다(오류 코드: 26308). 다시 시도해주세요`,
        error_26401:`오류 코드: 26401, 다시 시도해 주세요.`,
        error_26402:`이메일이 확인되었습니다(오류 코드: 26402). 다시 시도해 주세요.`,
        error_26403:`Reuqest가 실패했습니다(오류 코드: 26403). 다시 시도해주세요`,
        error_26404:`요청이 실패했습니다(오류 코드: 26404). 다시 시도해주세요`,
        error_26501:`오류 코드: 26501, 문의해 주세요.`,
        error_26502:`오류 코드: 26502, 문의해 주세요.`,
        error_26503:`이메일 형식 오류(오류 코드: 26503) 다시 입력해 주세요`,
        error_26504:`Reuqest가 실패했습니다(오류 코드: 26504). 다시 시도해주세요`,
        error_26505:`이메일이 등록되지 않았습니다. <a href="https://account.aiseesoft.com/register">먼저 등록</a>해 주세요.`,
        error_26506:`이메일이 확인되었습니다.`,
        error_26507:`Reuqest가 실패했습니다(오류 코드: 26507). 다시 시도해주세요`,
        error_26508:`확인이 실패했습니다(오류 코드: 26508). 다시 시도해 주세요.`,
        error_26509:`요청이 실패했습니다(오류 코드: 26509). 다시 시도해 주세요.`,
        error_26510:`오류 코드: 26510, 문의해 주세요.`,
        error_26601:`오류 코드: 26601, 문의해 주세요.`,
        error_26602:`오류 코드: 26602, 문의해 주세요.`,
        error_26603:`Reuqest가 실패했습니다(오류 코드: 26603). 다시 시도해주세요`,
        error_26604:`오류 코드: 26604, 문의해 주세요.`,
        error_26605:`오류 코드: 26605, 문의해 주세요.`,
        error_26701:`오류 코드: 26701, 문의해 주세요.`,
        error_26702:`Reuqest가 실패했습니다(오류 코드: 26702). 다시 시도해주세요`,
        error_26703:`오류 코드: 26703, 문의해 주세요.`,
        error_26704:`오류 코드: 26704, 문의해 주세요.`,
        error_26705:`로그인을 기다립니다(오류 코드: 26705). 다시 시도해주세요`,
        no_cookie:`귀하의 브라우저에서 모든 쿠키 차단 기능이 켜져 있어 로그인할 수 없습니다. 설정으로 이동하여 모든 쿠키 허용 확인란을 선택하세요.`,
        error_26801:`오류 코드: 26801, 문의해 주세요.`,
        error_26802:`오류 코드: 26802, 문의해 주세요.`,
        error_26803:`요청이 실패했습니다(오류 코드: 26803). 다시 시도해주세요`,
        error_26804:`요청이 실패했습니다(오류 코드: 26804). 다시 시도해주세요`,
        error_order:`요청이 실패했습니다(오류 코드: 27098). 다시 시도해 주세요!`,
        error_order1:`주문 쿼리가 불완전합니다(오류 코드:`,
        error_order2:`), 새로고침한 후 다시 시도해 주세요.`,
        modify_email_title:`이메일 수정`,
        modify_email_info:`수정된 이메일을 사용하여 계정에 로그인할 수 있습니다.`,
        images_per:`당 이미지`,
        error_26101:`오류: 26101. 문의해 주세요.`,
        error_26102:`오류: 26102. 문의해 주세요.`,
        error_26103:`요청이 실패했습니다(오류 코드:26103). 다시 시도해 보세요.`,
        error_26104:`오류 코드: 26104, 다시 시도해 주세요.`,
        error_26105:`오류 코드: 26105, 다시 시도해 주세요.`,
        error_26106:`삭제에 실패했습니다(오류 코드: 26106). 다시 시도해 주세요`,
        error_26201:`오류: 26201. 문의해 주세요.`,
        error_26202:`요청이 실패했습니다(오류 코드:26202). 다시 시도해 보세요.`,
        error_26001:`오류: 26001. 문의해 주세요.`,
        error_26002:`오류: 26002. 문의해 주세요.`,
        error_26003:`오류: 26003. 문의해 주세요.`,
        error_26004:`오류: 26004. 문의해 주세요.`,
        error_26005:`요청이 실패했습니다(오류 코드: 26005). 다시 시도해 보세요.`,
        error_26006:`오류 코드: 26006, 다시 시도해 주세요.`,
        error_26008:`오류: 26008. 문의해 주세요.`,
        go_to_the_home_page:`홈 페이지로 이동`,
        error_27101:`요청이 실패했습니다(오류 코드: 27101). 다시 시도해 주세요.`,
        error_27201:`오류 코드: 27201, 문의해 주세요.`,
        error_27202:`오류 코드: 27202, 다시 시도해 주세요.`,
        error_27203:`요청이 실패했습니다(오류 코드: 27203). 다시 시도해주세요`,
        error_27204:`잘못된 코드(오류 코드: 27204).`,
        error_27205:`요청이 실패했습니다(오류 코드: 27205). 다시 시도해주세요`,
        error_27206:`요청이 실패했습니다(오류 코드: 27206). 다시 시도해주세요`,
        error_27207:`요청이 실패했습니다(오류 코드: 27207). 다시 시도해주세요`,
        no_history_found:`어떤 도구도 사용하지 않았습니다! <a href="/">새로고침</a> 또는 <a href="https://www.aiseesoft.com/">공식 웹사이트로 이동</a>`,
        error_25301:`오류: 25301. 문의해 주세요.`,
        error_25302:`오류: 25302. 문의해 주세요.`,
        error_25303:`요청이 실패했습니다(오류 코드: 25303). 다시 시도해 주세요`,
        error_25304:`요청이 실패했습니다(오류 코드: 25304). 다시 시도해 주세요`,
        error_25305:`요청이 실패했습니다(오류 코드: 25305). 다시 시도해 주세요`,
        error_25306:`요청이 실패했습니다(오류 코드: 25306). 다시 시도해 주세요`,
        image_upscaler_p:`워터마크가 없는 이미지 다운로드 이점:`,
        Available_for:`사용 가능 대상:`,
        credit_per:`HD 이미지당 %s 크레딧`,
        still_valid:`구매한 요금제는 아직 유효합니다.`,
        credit:`크레딧`,
        pc_3rd_info:`성공적으로 로그인했습니다. 추가 작업을 위해 응용 프로그램으로 이동하십시오.`,
        use_online:`온라인 서비스를 이용하세요`,
        use_download:`데스크톱 프로그램 사용`,
        use_immediately:`즉시 사용`,
        Use_in_browser:`브라우저에서 사용`,
        win_desktop:`Windows`,
        Mac_desktop:`Mac`,
    },
    'zh-cn':{
        overtime_1:`错误代码：`,
        overtime_2:`，请重试`,
        isnetwork:`互联网错误。请检查并重试`,
        email_placeholder:`电子邮件`,
        email_empty:`请输入邮箱`,
        email_not_valid:`电子邮件无效`,
        email_not_valid_1:`请输入您的电子邮件`,
        email_not_valid_2:`电子邮件无效，请使用其他地址。`,
        email_not_valid_3:`没有电子邮件输入`,
        password_placeholder:`密码`,
        password_empty:`请输入密码`,
        password_not_valid:`帐户或密码不正确`,
        password_not_valid_1:`需要8个字符以上的密码`,
        password_not_valid_2:`请创建密码`,
        password_placeholder_1:`创建你的密码`,
        password_placeholder_2:`确认你的密码`,
        password_placeholder_3:`创建新密码`,
        password_placeholder_4:`确认新密码`,
        password_placeholder_5:`输入旧密码`,
        copy_password_empty:`请确认密码`,
        copy_password_not_valid:`请确认您的密码`,
        copy_passwords_inconsistent:`您的密码确认不匹配`,
        code_empty:`请输入验证码`,
        code_not_valid:`无效验证码`,
        code_placeholder:`验证码`,
        not_received_code:`如果您的邮箱长时间未收到验证码，请重新获取验证码。`,
        get_first_code:`请先获取验证码。`,
        connect_your_email_placeholder:`连接您的电子邮件`,
        last_name_placeholder:`请输入您的姓氏`,
        first_name_placeholder:`请输入您的名字`,
        address_placeholder:`请输入您的地址`,
        no_code_text:`我们已发送验证码。请输入您的验证码。<span class='tips'>没有收到验证码？`,
        no_code_text_1:`1.请确保邮箱地址有效且可以接收邮件。`,
        no_code_text_2:`2.由于系统自动发送的邮件，可能会被标记为垃圾邮件或垃圾邮件。请检查电子邮件是否在垃圾箱文件夹中。`,
        no_code_text_3:`3.无法解决您的问题？`,
        no_code_text_3_span:`然后点击这里联系我们。`,
        order_no:`您还没有购买任何产品。任何问题，请<a href="https://zh-cn.aiseesoft.com/store/">立即购买</a>或<a href="https://zh-cn.aiseesoft.com/contact.html">联系我们</a>`,
        error_24901:`当前帐户没有电子邮件链接，无法找到订单。请连接电子邮件。`,
        user_guide:`使用指南>>`,
        download:`下载`,
        order_number:`订单号`,
        Refund:`退款`,
        Disabled:`已禁用`,
        Normal:`普通的`,
        Modify:`调整`,
        Modify_1:`修改>>`,
        Connect:`连接`,
        unlink_success:`成功解除关联`,
        connect_success:`连接成功`,
        feedback_title:`感谢您的反馈意见。请留下您的问题，我们将在 24 小时内回复您。`,
        feedback_thank_you:`谢谢！<br />您的反馈已成功提交。`,
        feedback_email:`在此处输入您的电子邮件！`,
        feedback_content:`在这里留下您遇到的任何问题或建议。`,
        feedback_submit:`提交`,
        form_contents:`您尚未输入任何说明。请输入并再次提交。`,
        old_password:`请输入旧密码`,
        new_password:`请创建一个新密码`,
        old_new_password:`新密码不能与旧密码相同`,
        incorrect_password:`密码错误`,
        delete_no:`立即删除`,
        Caps:`大写锁定已开启`,
        Get:`得到`,
        Done:`完毕`,
        error_20001:`错误：20001。请重新登录。`,
        error_20002:`错误：20002。请重新登录。`,
        error_20003:`错误：20003。请重新登录。`,
        error_20004:`请求失败（错误代码：20004）。请再试一次。`,
        error_20005:`登录会话已过期（错误：20005）。请重新登录。`,
        error_20006:`请求失败（错误代码：20006）。请再试一次。`,
        error_20007:`登录会话已过期（错误：20007）。请重新登录。`,
        error_20008:`登录会话已过期（错误：20008）。请重新登录。`,
        error_20009:`登录会话已过期（错误：20009）。请重新登录。`,
        error_20101:`请输入您的邮箱（错误代码：20101）`,
        error_20102:`电子邮件无效（错误代码：20102）`,
        error_20103:`请求失败（错误代码：20103）。请再试一次`,
        error_20104:`邮箱已被使用，请<a href="https://account.aiseesoft.com/login">登录</a>或注册新邮箱`,
        error_20105:`请求失败（错误代码：20105）。请再试一次`,
        error_20106:`发送邮件失败，请重试`,
        error_20201:`请输入您的邮箱（错误代码：20201）`,
        error_20202:`请输入您的密码（错误代码：20202）`,
        error_20203:`请输入验证码（错误码：20203）`,
        error_20204:`电子邮件无效（错误代码：20204）`,
        error_20205:`需要8个字符以上的密码（错误代码：20205）`,
        error_20206:`请求失败（错误代码：20206）。请再试一次`,
        error_20207:`无效验证码`,
        error_20208:`请求失败（错误代码：20208）。请再试一次`,
        error_20209:`请求失败（错误代码：20209）。请再试一次`,
        error_20301:`请输入您的邮箱（错误代码：20301）`,
        error_20302:`错误：20302。请联系我们`,
        error_20303:`电子邮件无效（错误代码：20303）`,
        error_20304:`请求失败（错误代码：20304）。请再试一次`,
        error_20305:`帐户不存在。请重新输入或先<a href="https://account.aiseesoft.com/register">创造它</a>。`,
        error_20306:`还没有密码。使用<a href="https://account.aiseesoft.com/passwordless-login">无密码登录</a>或<a href="create-password">设置密码</a>并登录。`,
        error_20308:`请求失败（错误代码：20308）。请再试一次`,
        error_20401:`注销失败（错误代码：20401）。请再试一次`,
        error_20501:`请输入您的邮箱（错误代码：20501）`,
        error_20502:`电子邮件无效（错误代码：20502）`,
        error_20503:`请求失败（错误代码：20503）。请再试一次`,
        error_20504:`无法发送电子邮件。请再试一次。`,
        error_20601:`请输入您的邮箱（错误代码：20601）`,
        error_20602:`请输入验证码（错误码：20602）`,
        error_20603:`电子邮件无效（错误代码：20603）`,
        error_20604:`请求失败（错误代码：20604）。请再试一次`,
        error_20606:`请求失败（错误代码：20606）。请再试一次`,
        error_20607:`请求失败（错误代码：20607）。请再试一次`,
        error_20608:`请求失败（错误代码：20608）。请再试一次`,
        error_20701:`请输入您的邮箱（错误代码：20701）`,
        error_20702:`电子邮件无效（错误代码：20702）`,
        error_20703:`请求失败（错误代码：20703）。请再试一次`,
        error_20704:`帐户不存在。请重新输入或先<a href="https://account.aiseesoft.com/register">创造它</a>。`,
        error_20705:`请求失败（错误代码：20705）。请再试一次`,
        error_20706:`无法发送电子邮件。请再试一次`,
        error_20801:`请输入您的邮箱（错误代码：20801）`,
        error_20802:`错误：20802。请联系我们`,
        error_20803:`请输入验证码（错误码：20803）`,
        error_20804:`电子邮件无效（错误代码：20804）`,
        error_20805:`需要8个字符以上的密码（错误代码：20805）`,
        error_20806:`请求失败（错误代码：20806）。请再试一次`,
        error_20808:`请求失败（错误代码：20808）。请再试一次`,
        error_20901:`请求失败（错误代码：20901）。请再试一次`,
        error_20902:`请求失败（错误代码：20902）。请再试一次`,
        error_21000:`更改已保存`,
        error_21001:`没有提交信息（错误代码：21001）`,
        error_21002:`请求失败（错误代码：21002）。请再试一次`,
        error_21101:`请输入您的邮箱（错误代码：21101）`,
        error_21102:`电子邮件无效（错误代码：21102）`,
        error_21103:`请求失败（错误代码：21103），请重试`,
        error_21104:`邮箱已连接，请使用新邮箱`,
        error_21105:`请求失败（错误代码：21105），请重试`,
        error_21106:`无法发送电子邮件。请再试一次`,
        error_21201:`请输入您的邮箱（错误代码：21201）`,
        error_21202:`请输入验证码（错误码：21202）`,
        error_21203:`电子邮件无效（错误代码：21203）`,
        error_21204:`错误：21204。请联系我们`,
        error_21205:`错误：21205。请联系我们`,
        error_21206:`需要8位以上的密码（错误代码：21206）`,
        error_21207:`请求失败（错误代码：21207）。请再试一次`,
        error_21209:`请求失败（错误代码：21209）。请再试一次`,
        error_21301:`请输入旧密码（错误代码：21301）`,
        error_21302:`请创建一个新密码（错误代码：21302）`,
        error_21303:`新密码不能与旧密码相同。 （错误：21303）`,
        error_21304:`需要8个字符以上的密码（错误代码：21304）`,
        error_21306:`请求失败（错误代码：21306）。请再试一次`,
        error_21402:`请求失败（错误代码：21402）。请再试一次`,
        error_21403:`发送验证码失败。请重新发送`,
        error_21500:`该帐户已被删除`,
        error_21501:`请输入验证码（错误码：21501）`,
        error_21502:`登录会话已过期（错误：21502）。请重新登录。`,
        error_21503:`请求失败（错误代码：21503）。请再试一次`,
        error_21505:`请求失败（错误代码：21505），请重试`,
        error_21601:`错误：20601。请联系我们`,
        error_21602:`验证无效（错误：20602）。请再试一次。`,
        error_21603:`错误：20603。请重试`,
        error_21604:`请求失败（错误代码：21604）。请再试一次`,
        error_21606:`请求失败（错误代码：21606）。请再试一次`,
        error_21611:`请求失败（错误代码：21611）。请再试一次`,
        error_21801:`错误：21801。请联系我们`,
        error_21802:`请求失败（错误：21802）。请再试一次`,
        error_21803:`错误：21803。请重试`,
        error_21804:`请求失败（错误代码：21804）。请再试一次`,
        error_21806:`错误：21806。请重试`,
        error_21807:`错误：21807。请联系我们`,
        error_21808:`错误：21808。请联系我们`,
        error_21809:`错误：21809。请联系我们`,
        error_21810:`错误：21810。请联系我们`,
        error_21811:`错误：21811。请联系我们`,
        error_21812:`错误：21812。请联系我们`,
        error_21813:`请求失败（错误代码：21813）。请再试一次`,
        error_21814:`错误：21814。请联系我们`,
        error_21815:`请求失败（错误代码：21815）。请再试一次`,
        error_21816:`错误：21816。请联系我们`,
        error_21817:`错误：21817。请联系我们`,
        error_21818:`错误：21818。请联系我们`,
        error_21819:`请求失败（错误代码：21819）。请再试一次`,
        error_21820:`错误：21820。请联系我们`,
        error_21821:`错误：21821。请联系我们`,
        error_21822:`错误：21822。请联系我们`,
        error_21823:`请求失败（错误代码：21823）。请再试一次`,
        error_21824:`请求失败（错误代码：21824）。请再试一次`,
        error_21825:`请求失败（错误代码：21825）。请再试一次`,
        error_21826:`请求失败（错误代码：21826）。请再试一次`,
        error_21828:`错误：21828。请联系我们`,
        error_21901:`错误：21901。请联系我们`,
        error_21902:`请求失败（错误代码：21902）。请再试一次`,
        error_21903:`帐户状态已更改（错误代码：21903），请刷新页面并重试`,
        error_21904:`错误：21904。请重试`,
        error_21905:`错误：21905。请重试`,
        error_21906:`请求失败（错误代码：21906）。请再试一次`,
        error_21907:`Google 帐户已关联到其他帐户`,
        error_21908:`请求失败（错误代码：21908）。请再试一次`,
        error_22001:`请求失败（错误代码：22001）。请再试一次`,
        error_22002:`取消链接失败，没有额外登录`,
        error_22003:`请求失败（错误代码：22003）。请再试一次`,
        error_22101:`错误：22101。请联系我们`,
        error_22102:`帐户状态已更改（错误代码：22102），请刷新页面并重试`,
        error_22103:`请求失败（错误代码：22103）。请再试一次`,
        error_22104:`帐户状态已更改（错误代码：22104），请刷新页面并重试`,
        error_22105:`错误：22105。请重试`,
        error_22106:`错误：22106。请重试`,
        error_22107:`错误：22107。请联系我们`,
        error_22108:`请求失败（错误代码：22108）。请再试一次`,
        error_22201:`错误：22201。请联系我们`,
        error_22202:`验证无效（错误：22202）。请再试一次。`,
        error_22203:`错误：22203。请重试”`,
        error_22204:`请求失败（错误代码：22204）。请再试一次`,
        error_22206:`请求失败（错误代码：22206）。请再试一次`,
        error_22401:`错误：22401。请联系我们`,
        error_22402:`验证无效（错误：22402）。请再试一次。`,
        error_22403:`错误：22403。请重试`,
        error_22404:`请求失败（错误代码：22404）。请再试一次`,
        error_22405:`Facebook 帐户已链接到其他电子邮件`,
        error_22406:`错误：22406。请重试`,
        error_22407:`错误：22407。请联系我们`,
        error_22408:`错误：22408。请联系我们`,
        error_22409:`错误：22409。请联系我们`,
        error_22410:`错误：224010。请联系我们`,
        error_22411:`错误：224011。请联系我们`,
        error_22412:`错误：224012。请联系我们`,
        error_22413:`错误：22413。请联系我们`,
        error_22414:`请求失败（错误代码：22414）。请再试一次`,
        error_22415:`错误：22415。请联系我们`,
        error_22416:`错误：22416。请联系我们`,
        error_22417:`错误：22417。请联系我们`,
        error_22418:`请求失败（错误代码：22418）。请再试一次`,
        error_22419:`错误：22419。请联系我们`,
        error_22420:`错误：22420。请联系我们`,
        error_22421:`错误：22421。请联系我们`,
        error_22422:`请求失败（错误代码：22422）。请再试一次`,
        error_22423:`请求失败（错误代码：22423）。请再试一次`,
        error_22424:`请求失败（错误代码：22424）。请再试一次`,
        error_22425:`请求失败（错误代码：22425）。请再试一次`,
        error_20098:`错误代码：20098。如果您在隐私模式下浏览，请切换到普通模式并重试。`,
        error_22298:`对 Google 的请求失败（错误：22298）。请再试一次。`,
        error_22498:`请求 Facebook 失败（错误：22498）。请再试一次。`,
        error_24902:`请求失败（错误代码：24902）。请再试一次`,
        error_24903:`请求失败（错误代码：24903）。请再试一次`,
        error_24904:`请求失败（错误代码：24904）。请再试一次`,
        error_24905:`请求失败（错误代码：24905）。请再试一次`,
        login_title:`登录 Aiseesoft`,
        password_placeholder:`密码`,
        log_in:`登录`,
        no_account:`没有账户？`,
        create_it:`创造它`,
        or_log_in_with:`或登录`,
        passwordless_login:`无密码登录`,
        log_in_done:`登录完成`,
        three_rd_account_connect_info:`恭喜！您已成功登录。现在您可以连接一个用于将来登录的电子邮件帐户。`,
        connect_now:`立即连接`,
        see_my_account:`查看我的帐户`,
        three_rd_login_merge_account:`第三方账号邮箱已经注册，要直接用这个邮箱连接登录吗？`,
        connect_log_in:`连接和登录`,
        create_an_account:`创建一个帐户`,
        connect_account:`连接帐户`,
        connect_account_info:`连接后，您可以使用此帐户登录。`,
        back_to_log_in:`回到登入`,
        create_password:`创建密码`,
        create_now:`立即创建`,
        password_login_subtitle:`使用电子邮件无密码登录`,
        account_login:`帐号登录`,
        rights:`创建此帐户即表示您同意<a href="https://zh-cn.aiseesoft.com/company/terms-and-conditions.html">服务条款</a>和<a href="https://zh-cn.aiseesoft.com/privacy-policy.html">隐私政策</a>`,
        passwordless_login_done:`无密码登录完成`,
        passwordless_login_info:`恭喜，您已成功完成无密码登录。您可以为此帐户创建密码，并在以后使用该帐户和密码登录。 <a href="/create-password" style="display: initial;">立即创建</a>`,
        sign_up:`报名`,
        register_info:`创建您的帐户`,
        reset_now:`立即重置`,
        forgot_password:`忘记密码`,
        reset_password_subtitle:`使用您的帐户电子邮件重置密码`,
        plan_products:`计划和产品`,
        nick_name:`用户名`,
        email:`电子邮件`,
        my_products:`我的产品`,
        my_orders:`我的订单`,
        unlink:`取消链接`,
        link:`关联`,
        connected_accounts:`关联账户`,
        last_name:`姓`,
        first_name:`名`,
        Gender:`性别`,
        Birth:`出生`,
        Month:`月`,
        Year:`年`,
        Country_Region:`国家/地区`,
        Address:`地址`,
        Save:`节省`,
        Date:`日期`,
        Male:`男性`,
        Female:`女性`,
        Unspecified:`未指定`,
        Security:`安全`,
        change_password:`更改密码`,
        change_now:`立即更改`,
        connect_email:`连接电子邮件`,
        delete_account:`删除帐户`,
        delete_account_info:`当您的帐户被删除时，与您的帐户相关联的计划和产品中的所有数据都将被永久删除，并且您可能无法恢复这些数据。我们建议您谨慎行事。`,
        Delete:`删除`,
        Logout:`登出`,
        my_profile:`我的简历`,
        guides_faqs:`指南和常见问题`,
        More:`更多的`,
        guides:`指南`,
        register:`登记`,
        hot_faq:`热门常见问题`,
        Contents:`内容：`,
        contact_us:`联系我们>>`,
        plan:`计划`,
        unregistered:`未注册`,
        buy_more:`买多点`,
        buy_again:`再买`,
        buy_now:`立即购买`,
        free_no_limit:`免费且无限制`,
        expired:`已到期`,
        lifetime:`寿命`,
        remain:`保持`,
        day_s:`天）`,
        error_24801:`请求失败（错误代码：24801）。请再试一次`,
        no_app_found:`没有找到应用！<a href="/">刷新</a>或<a href="https://zh-cn.aiseesoft.com/">去官网</a>`,
        get_more:`获取更多>>`,
        edit_photo:`编辑照片`,
        select_photo:`选择照片`,
        change_photo:`更改照片`,
        cancel:`取消`,
        hide_password:`隐藏密码`,
        show_password:`显示密码`,
        zoom_in:`放大`,
        zoom_out:`缩小`,
        rotate:`旋转`,
        horizontal_flip:`水平翻转`,
        vertical_flip:`垂直翻转`,
        country:`国家`,
        country_1:`选择您的国家/地区`,
        country_2:`奥兰岛`,
        country_3:`阿富汗`,
        country_4:`阿尔巴尼亚`,
        country_5:`阿尔及利亚`,
        country_6:`美属萨摩亚`,
        country_7:`安道尔`,
        country_8:`安哥拉`,
        country_9:`安圭拉`,
        country_10:`南极洲`,
        country_11:`安提瓜和巴布达`,
        country_12:`阿根廷`,
        country_13:`亚美尼亚`,
        country_14:`阿鲁巴岛`,
        country_15:`澳大利亚`,
        country_16:`奥地利`,
        country_17:`阿塞拜疆`,
        country_18:`巴林`,
        country_19:`孟加拉国`,
        country_20:`巴巴多斯`,
        country_21:`白俄罗斯`,
        country_22:`比利时`,
        country_23:`伯利兹`,
        country_24:`贝宁`,
        country_25:`百慕大`,
        country_26:`不丹`,
        country_27:`玻利维亚`,
        country_28:`波斯尼亚和黑塞哥维那`,
        country_29:`博茨瓦纳`,
        country_30:`布韦岛`,
        country_31:`巴西`,
        country_32:`英属印度洋领地`,
        country_33:`英属维尔京群岛`,
        country_34:`文莱`,
        country_35:`保加利亚`,
        country_36:`布基纳`,
        country_37:`布隆迪`,
        country_38:`柬埔寨`,
        country_39:`喀麦隆`,
        country_40:`加拿大`,
        country_41:`佛得角`,
        country_42:`加勒比海荷兰`,
        country_43:`开曼群岛`,
        country_44:`中非共和国`,
        country_45:`乍得`,
        country_46:`智利`,
        country_47:`中国`,
        country_48:`圣诞岛`,
        country_49:`科科斯（基林）群岛`,
        country_50:`哥伦比亚`,
        country_51:`库克群岛`,
        country_52:`哥斯达黎加`,
        country_53:`“科特迪瓦”`,
        country_54:`克罗地亚`,
        country_55:`古巴`,
        country_56:`塞浦路斯`,
        country_57:`捷克共和国`,
        country_58:`刚果民主共和国`,
        country_59:`丹麦`,
        country_60:`吉布提`,
        country_61:`多米尼加`,
        country_62:`多明尼加共和国`,
        country_63:`厄瓜多尔`,
        country_64:`埃及`,
        country_65:`萨尔瓦多`,
        country_66:`赤道几内亚`,
        country_67:`厄立特里亚`,
        country_68:`爱沙尼亚`,
        country_69:`埃塞俄比亚`,
        country_70:`福克兰群岛`,
        country_71:`法罗群岛`,
        country_72:`密克罗尼西亚联邦`,
        country_73:`斐济`,
        country_74:`芬兰`,
        country_75:`法国`,
        country_76:`法属圭亚那`,
        country_77:`法属波利尼西亚`,
        country_78:`法属南部领地`,
        country_79:`加蓬`,
        country_80:`冈比亚`,
        country_81:`乔治亚州`,
        country_82:`德国`,
        country_83:`加纳`,
        country_84:`直布罗陀`,
        country_85:`英国（英国；英格兰）`,
        country_86:`希腊`,
        country_87:`格陵兰`,
        country_88:`格林纳达`,
        country_89:`瓜德罗普岛`,
        country_90:`关岛`,
        country_91:`危地马拉`,
        country_92:`根西岛`,
        country_93:`几内亚`,
        country_94:`几内亚比绍`,
        country_95:`圭亚那`,
        country_96:`海地`,
        country_97:`赫德岛和麦克唐纳群岛`,
        country_98:`洪都拉斯`,
        country_99:`香港`,
        country_100:`匈牙利`,
        country_101:`冰岛`,
        country_102:`印度`,
        country_103:`印度尼西亚`,
        country_104:`伊朗`,
        country_105:`伊拉克`,
        country_106:`爱尔兰`,
        country_107:`马恩岛`,
        country_108:`以色列`,
        country_109:`意大利`,
        country_110:`牙买加`,
        country_111:`日本`,
        country_112:`球衣`,
        country_113:`约旦`,
        country_114:`哈萨克斯坦`,
        country_115:`肯尼亚`,
        country_116:`基里巴斯`,
        country_117:`科威特`,
        country_118:`吉尔吉斯斯坦`,
        country_119:`老挝`,
        country_120:`拉脱维亚`,
        country_121:`黎巴嫩`,
        country_122:`莱索托`,
        country_123:`利比里亚`,
        country_124:`利比亚`,
        country_125:`列支敦士登`,
        country_126:`立陶宛`,
        country_127:`卢森堡`,
        country_128:`澳门`,
        country_129:`马达加斯加`,
        country_130:`马拉维`,
        country_131:`马来西亚`,
        country_132:`马尔代夫`,
        country_133:`马里`,
        country_134:`马耳他`,
        country_135:`马绍尔群岛`,
        country_136:`马提尼克`,
        country_137:`毛里塔尼亚`,
        country_138:`毛里求斯`,
        country_139:`马约特岛`,
        country_140:`墨西哥`,
        country_141:`摩尔多瓦`,
        country_142:`摩纳哥`,
        country_143:`蒙古`,
        country_144:`黑山`,
        country_145:`蒙特塞拉特`,
        country_146:`摩洛哥`,
        country_147:`莫桑比克`,
        country_148:`缅甸（缅甸）`,
        country_149:`纳米比亚`,
        country_150:`瑙鲁`,
        country_151:`尼泊尔`,
        country_152:`荷兰`,
        country_153:`新喀里多尼亚`,
        country_154:`新西兰`,
        country_155:`尼加拉瓜`,
        country_156:`尼日尔`,
        country_157:`尼日利亚`,
        country_158:`纽埃`,
        country_159:`诺福克岛`,
        country_160:`北朝鲜`,
        country_161:`北马里亚纳群岛`,
        country_162:`挪威`,
        country_163:`阿曼`,
        country_164:`巴基斯坦`,
        country_165:`帕劳`,
        country_166:`巴勒斯坦领土`,
        country_167:`巴拿马`,
        country_168:`巴布亚新几内亚`,
        country_169:`巴拉圭`,
        country_170:`秘鲁`,
        country_171:`皮特凯恩群岛`,
        country_172:`波兰`,
        country_173:`葡萄牙`,
        country_174:`波多黎各`,
        country_175:`卡塔尔`,
        country_176:`马其顿共和国 (FYROM)`,
        country_177:`刚果共和国`,
        country_178:`团圆`,
        country_179:`罗马尼亚`,
        country_180:`俄罗斯联邦`,
        country_181:`卢旺达`,
        country_182:`圣巴泰勒米`,
        country_183:`圣马丁（法国）`,
        country_184:`圣皮埃尔和密克隆`,
        country_185:`萨摩亚`,
        country_186:`圣马力诺`,
        country_187:`圣多美和普林西比`,
        country_188:`沙特阿拉伯`,
        country_189:`塞内加尔`,
        country_190:`塞尔维亚`,
        country_191:`塞舌尔`,
        country_192:`塞拉利昂`,
        country_193:`新加坡`,
        country_194:`斯洛伐克`,
        country_195:`斯洛文尼亚`,
        country_196:`所罗门群岛`,
        country_197:`索马里`,
        country_198:`南非`,
        country_199:`南乔治亚岛和南桑威奇岛`,
        country_200:`韩国`,
        country_201:`南苏丹`,
        country_202:`西班牙`,
        country_203:`斯里兰卡`,
        country_204:`圣赫勒拿岛和属地`,
        country_205:`圣基茨和尼维斯`,
        country_206:`圣卢西亚`,
        country_207:`圣文森特和格林纳丁斯`,
        country_208:`苏丹`,
        country_209:`苏里南`,
        country_210:`斯威士兰`,
        country_211:`瑞典`,
        country_212:`瑞士`,
        country_213:`叙利亚`,
        country_214:`台湾`,
        country_215:`塔吉克斯坦`,
        country_216:`坦桑尼亚`,
        country_217:`模板：国家数据 SJM Svalbard`,
        country_218:`泰国`,
        country_219:`巴哈马`,
        country_220:`科摩罗`,
        country_221:`菲律宾人`,
        country_222:`东帝汶（东帝汶）`,
        country_223:`多哥`,
        country_224:`托克劳`,
        country_225:`汤加`,
        country_226:`特立尼达和多巴哥`,
        country_227:`突尼斯`,
        country_228:`火鸡`,
        country_229:`土库曼斯坦`,
        country_230:`特克斯和凯科斯群岛`,
        country_231:`图瓦卢`,
        country_232:`乌干达`,
        country_233:`乌克兰`,
        country_234:`阿拉伯联合酋长国`,
        country_235:`美国本土外小岛屿`,
        country_236:`美利坚合众国（美国）`,
        country_237:`美属维尔京群岛`,
        country_238:`乌拉圭`,
        country_239:`乌兹别克斯坦`,
        country_240:`瓦努阿图`,
        country_241:`梵蒂冈城（教廷）`,
        country_242:`委内瑞拉`,
        country_243:`越南`,
        country_244:`瓦利斯和富图纳`,
        country_245:`西撒哈拉`,
        country_246:`也门`,
        country_247:`赞比亚`,
        country_248:`津巴布韦`,
        google_login:`使用 Google 登录`,
        footer:`版权所有 © 2024 Aiseesoft Studio。版权所有。`,
        State:`状态`,
        Activation_code:`激活码`,
        Question:`列出您已登录的所有应用程序`,
        Copy_complete:`复制完成`,
        store:`店铺`,
        resource:`资源`,
        support:`支持`,
        video_converter:`视频转换器`,
        multimedia:`多媒体`,
        data_recovery:`数据恢复`,
        utility:`公用事业`,
        free_tools:`免费工具`,
        for_windows:`对于 Windows`,
        for_mac:`对于 Mac`,
        pdf_solutions:`PDF解决方案`,
        data_manager:`数据管理器`,
        other_tools:`其他工具`,
        free_online_solutions:`免费在线解决方案`,
        free_online_tools:`免费在线工具`,
        desktop_freeware:`桌面免费软件`,
        product_1:`视频转换器终极版`,
        product_2:`总视频转换器`,
        product_3:`MTS 转换器`,
        product_4:`4K转换器`,
        product_5:`MXF 转换器`,
        product_6:`3D转换器`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Mac 视频转换器`,
        product_9:`适用于 Mac 的 MTS 转换器`,
        product_10:`适用于 Mac 的 4K 转换器`,
        product_11:`适用于 Mac 的 MXF 转换器`,
        product_12:`适用于 Mac 的 3D 转换器`,
        product_13:`蓝光播放器`,
        product_14:`视频增强器`,
        product_15:`视频编辑`,
        product_16:`幻灯片制作者`,
        product_17:`蓝光创作者`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD 创作者`,
        product_20:`Mac 蓝光播放器`,
        product_21:`Mac 视频增强器`,
        product_22:`Mac 视频编辑器`,
        product_23:`适用于 Mac 的 DVD 制作器`,
        product_24:`适用于 Mac 的 MP4 转换器`,
        product_25:`视窗数据恢复`,
        product_26:`FoneLab iPhone 数据恢复`,
        product_27:`FoneLab iOS 系统恢复`,
        product_28:`FoneLab iOS 数据备份 &amp;恢复`,
        product_29:`FoneLab Android 数据恢复`,
        product_30:`破碎的Android数据提取`,
        product_31:`Android 数据备份 &amp;恢复`,
        product_32:`Mac 数据恢复`,
        product_33:`Mac FoneLab iPhone 数据恢复`,
        product_34:`Mac FoneLab iOS 系统恢复`,
        product_35:`Mac FoneLab iOS 数据备份 &amp;恢复`,
        product_36:`Mac FoneLab Android 数据恢复`,
        product_37:`Mac Android 数据备份 &amp;恢复`,
        product_38:`PDF Converter Ultimate`,
        product_39:`适用于 Mac 的 PDF Converter Ultimate`,
        product_40:`PDF合并`,
        product_41:`Mac PDF 到 ePub 转换器`,
        product_42:`Mac 清洁器`,
        product_43:`FoneTrans`,
        product_44:`适用于 iOS 的 WhatsApp 传输`,
        product_45:`手机同步`,
        product_46:`FoneEraser`,
        product_47:`iPhone清洁剂`,
        product_48:`PPT到视频转换器`,
        product_49:`HEIC转换器`,
        product_50:`iPhone 解锁器`,
        product_51:`免费在线视频转换器`,
        product_52:`免费在线音频转换器`,
        product_53:`免费在线视频压缩器`,
        product_54:`免费在线视频合并`,
        product_55:`免费 HEIC 转换器`,
        product_56:`免费图像压缩器`,
        product_57:`免费水印去除剂`,
        product_58:`免费在线 PDF 压缩器`,
        product_59:`免费在线图像升频器`,
        product_60:`在线免费背景卸妆`,
        product_61:`免费在线图像转换器`,
        product_62:`在线免费 PDF PNG 转换器`,
        product_63:`免费视频转换器`,
        product_64:`免费视频转 GIF 转换器`,
        product_65:`免费安卓数据恢复`,
        product_66:`幻灯片制作工具`,
        product_67:`免费视频编辑器`,
        product_68:`免费的 PDF 查看器`,
        product_69:`屏幕录像机`,
        product_70:`Mac 屏幕录像机`,
        product_71:`免费在线屏幕录像机`,
        product_72:`免费在线录音机`,  
        product_73:`手机镜像`,
        product_74:`AnyCoord - 位置转换器`,
        product_75:`iPhone 密码管理器`,
        product_76:`免费在线音频压缩器`,
        product_77:`免费在线视频修剪器`,
        product_78:`免费在线视频裁剪器`,
        product_79:`免费在线视频旋转器`,
        product_80:`免费在线视频 Looper`,
        product_81:`免费 PDF JPG 在线转换器`,
        product_81:`免费的 JPG Word 在线转换器`,      
        category_1:`照片编辑`,
        category_2:`视频编辑`,
        category_3:`橡皮`,
        category_4:`媒体记录器`,
        posted_by:`发表者`,
        to:`到`,
        author_1:`阿什莉·梅`,
        author_2:`珍妮瑞恩`,
        author_3:`妮可琼斯`,
        author_4:`阿曼达·布朗`,
        article_1:`如何有效去除图片中的文字`,
        article_2:`如何在不降低质量的情况下将图像大小（以 KB 为单位）增加到 MB`,
        article_3:`如何在 Windows 和 Mac 上删除 Getty Images 水印`,
        article_4:`免费在线将低分辨率图像转换为高分辨率的最佳方法`,
        article_5:`抖音指南 |这是在 TikTok 上制作视频拼贴画的方法`,
        article_6:`5 种简单的方法来组合你应该知道的 VOB 文件`,
        article_7:`如何轻松升级视频并获得更高的分辨率`,
        article_8:`如何在 MacBook Pro/iMac/MacBook 等设备上合并 MP4 文件`,
        article_9:`在 iPhone 上删除购买历史记录的 2 种简单方法 [已解决]`,
        article_10:`如何从聊天和记忆中永久删除 Snapchat 图片`,
        article_11:`如何在 iPhone/iPad 上删除 Spotlight 搜索建议和历史记录`,
        article_12:`[已解决] 如何使用 3 种简单方法清除 iPhone 上的 Instagram 缓存`,
        article_13:`[已解决] 如何修复 QuickTime Player 在 Mac 上无法运行`,
        article_14:`如何在 Mac 上录制系统音频（Big Sur/Catalina/Mojave ...）`,
        article_15:`修复 PowerPoint 屏幕录制不起作用的有效方法`,
        article_16:`如何使用 VLC 录制音频 [已解决]`,
        faq_title_1:`1.如何修复4104错误？`,
        faq_title_2:`2. 退款申请获得批准后，我什么时候可以拿回我的钱？`,
        faq_title_3:`3. 什么是“1年订阅”以及如何关闭“自动续订服务”？`,
        faq_title_4:`4. Aiseesoft 产品下单后可以取消订单吗？`,
        faq_title_5:`5. 如何向您提交评论？`,
        faq_info_1:`这是由于您获得的许可证有限。我们采用Single License，以防注册码被盗，防止注册码被违规使用。因此，您获得的注册代码是单一许可证，只能在一台计算机上使用。如果您在多台计算机上使用它，则会出现错误 4104。在我们为您重置之前，您无法使用它。`,
        faq_info_2:`如果您更换或重新安装计算机，我们可以为您重置。请联系客服：<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> 为您申请重置注册码。`,
        faq_info_3:`一旦您的退款请求获得批准，通常需要 3 到 7 个工作日才能在您的帐户中显示退款。有时在假期期间，可能需要更长的时间，但预计不会超过 10 天。如果您在批准后 20 天仍未收到退款，请通过 <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> 联系我们以查看退款情况。`,
        faq_info_4:`自动续费服务是指，如果您购买了 1 年订阅，则支付平台将在产品许可证到期时自动收取该金额（包括适用税费）。但是付款平台（Swreg 或 2Checkout）会发信提醒您订单将在到期前 7 天到期。该电子邮件还将包含有关如何关闭“自动续订服务”的说明。`,
        faq_info_5:`由于订单是自动处理的，所以不允许取消订单。此外，免费试用版将使您更了解Aiseesoft 程序，并在购买前检查它是否能满足您的需求。`,
        faq_info_6:`如果您确实想获得退款，请查看我们的<a href="https://zh-cn.aiseesoft.com/refund-policy.html">退款政策</a>。`,
        faq_info_7:`感谢您抽出时间和关注向我们提交评论。联系我们 (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) 提交您的评论，我们将认真考虑您对我们产品更新的评论。`,
        change_password_success:`密码修改成功`,
        successful_login_title:`登录成功`,
        product_page:`产品页面>>`,
        successful_login_info:`登录完成。请关闭当前页面并返回原始选项卡以继续以下过程。当前页面将在 5 秒后自动关闭。如果自动关闭或单击“完成”按钮关闭失败，请直接关闭此选项卡。`,
        successful_login_info_firefox:`登录完成。请关闭当前页面并返回原始选项卡以继续以下过程。`,
        my_account:`我的帐户`,
        my_history:`我的历史`,
        remove_watermark:`去除水印`,
        no_history:`没有历史`,
        history_all:`全选`,
        history_open:`打开`,
        history_down:`下载`,
        history_delete:`删除`,
        history_clear:`清除无效`,
        images:`图片）`,
        use_this_function:`使用此功能>>`,
        hd_downloading:`高清原图下载好处：`,
        lifetimeRemaining:`剩余寿命`,
        Remaining:`其余的`,
        email_verification:`电子邮件验证`,
        email_verification_info:`我们已将验证信息发送至您的邮箱<span class="email"></span>，请完成验证。验证后，福利将自动同步。`,
        wrong_email:`错误的电子邮件地址？`,
        click_here_to_modify:`点击这里修改`,
        get_more_help:`获得更多帮助？`,
        click_here:`点击这里`,
        email_verification_info_success:`恭喜您验证了您的电子邮件帐户。`,
        email_verification_info_error:`由于链接过期，验证失败。`,
        registration_succeeded:`注册成功`,
        registration_succeeded_info_1:`恭喜！您已成功注册。我们已将验证消息发送到您的电子邮件 <span class="email"></span>，请完成验证以使用此电子邮件的好处。`,
        registration_succeeded_info_2:`单击“完成”返回主页并使用该产品。`,
        registration_succeeded_info_3:`点击“完成”关闭当前页面，返回首页进行以下操作。如果关闭失败，请手动关闭选项卡。`,
        verify_email:`验证邮件`,
        registered_email_not_verified:`注册邮箱未验证，请立即验证。`,
        email_verification_time_1:`没有收到验证邮件吗？`,
        email_verification_time_2:`后`,
        email_verification_time_3:`点击这里重新发送`,
        error_26301:`错误代码：26301，请联系我们`,
        error_26302:`错误代码：26302，请联系我们`,
        error_26303:`电子邮件格式错误（错误代码：26303）。请重新输入`,
        error_26304:`密码建议8位以上（错误代码：26304）`,
        error_26305:`请求失败（错误代码：26305）。请重试`,
        error_26306:`邮箱已注册，请<a href="https://account.aiseesoft.com/login">去登录</a>`,
        error_26307:`请求失败（错误代码：26307）。请重试`,
        error_26308:`请求失败（错误代码：26308）。请重试`,
        error_26401:`错误代码：26401，请重试`,
        error_26402:`邮箱已验证（错误代码：26402），请重试`,
        error_26403:`请求失败（错误代码：26403）。请重试`,
        error_26404:`请求失败（错误代码：26404）。请重试`,
        error_26501:`错误代码：26501，请联系我们`,
        error_26502:`错误代码：26502，请联系我们`,
        error_26503:`电子邮件格式错误（错误代码：26503）。请重新输入`,
        error_26504:`请求失败（错误代码：26504）。请重试`,
        error_26505:`邮箱尚未注册，请<a href="https://account.aiseesoft.com/register">先注册</a>`,
        error_26506:`电子邮件已通过验证。`,
        error_26507:`请求失败（错误代码：26507）。请重试`,
        error_26508:`验证失败（错误码：26508），请重试`,
        error_26509:`请求失败（错误代码：26509），请重试`,
        error_26510:`错误代码：26510，请联系我们`,
        error_26601:`错误代码：26601，请联系我们`,
        error_26602:`错误代码：26602，请联系我们`,
        error_26603:`请求失败（错误代码：26603）。请重试`,
        error_26604:`错误代码：26604，请联系我们`,
        error_26605:`错误代码：26605，请联系我们`,
        error_26701:`错误代码：26701，请联系我们`,
        error_26702:`请求失败（错误代码：26702）。请重试`,
        error_26703:`错误代码：26703，请联系我们`,
        error_26704:`错误代码：26704，请联系我们`,
        error_26705:`等待登录（错误代码：26705）。请重试`,
        no_cookie:`您在浏览器中开启了阻止所有cookies功能，因此您无法登录。请进入设置，勾选允许所有cookies。`,
        error_26801:`错误代码：26801，请联系我们`,
        error_26802:`错误代码：26802，请联系我们`,
        error_26803:`请求失败（错误代码：26803）。请重试`,
        error_26804:`请求失败（错误代码：26804）。请重试`,
        error_order:`请求失败（错误代码：27098），请重试！`,
        error_order1:`订单查询不完整（错误码：`,
        error_order2:`），请刷新并重试。`,
        modify_email_title:`修改邮箱`,
        modify_email_info:`您可以使用修改后的电子邮件登录您的帐户。`,
        images_per:`每张图片`,
        error_26101:`错误：26101。请联系我们`,
        error_26102:`错误：26102。请联系我们`,
        error_26103:`请求失败（错误代码：26103）。请再试一次`,
        error_26104:`错误代码：26104，请重试`,
        error_26105:`错误代码：26105，请重试`,
        error_26106:`删除失败（错误代码：26106）。请再试一次`,
        error_26201:`错误：26201。请联系我们`,
        error_26202:`请求失败（错误代码：26202）。请再试一次`,
        error_26001:`错误：26001。请联系我们`,
        error_26002:`错误：26002。请联系我们`,
        error_26003:`错误：26003。请联系我们`,
        error_26004:`错误：26004。请联系我们`,
        error_26005:`请求失败（错误代码：26005）。请再试一次`,
        error_26006:`错误代码：26006，请重试`,
        error_26008:`错误：26008。请联系我们`,
        go_to_the_home_page:`转到主页`,
        error_27101:`请求失败（错误代码：27101）。请重试`,
        error_27201:`错误代码：27201，请联系我们`,
        error_27202:`错误代码：27202，请重试`,
        error_27203:`请求失败（错误代码：27203）。请重试`,
        error_27204:`无效代码（错误代码：27204）。`,
        error_27205:`请求失败（错误代码：27205）。请重试`,
        error_27206:`请求失败（错误代码：27206）。请重试`,
        error_27207:`请求失败（错误代码：27207）。请重试`,
        no_history_found:`你还没有使用任何工具！<a href="/">刷新</a>或<a href="https://zh-cn.aiseesoft.com/">去官网</a>`,
        error_25301:`错误：25301。请联系我们`,
        error_25302:`错误：25302。请联系我们`,
        error_25303:`请求失败（错误代码：25303）。请再试一次`,
        error_25304:`请求失败（错误代码：25304）。请再试一次`,
        error_25305:`请求失败（错误代码：25305）。请再试一次`,
        error_25306:`请求失败（错误代码：25306）。请再试一次`,
        image_upscaler_p:`无水印图片下载权益：`,
        Available_for:`可用于：`,
        credit_per:`保存1张高清图消耗%s点`,
        still_valid:`以下计划依旧生效`,
        credit:`点数`,
        pc_3rd_info:`已登录成功，请去应用中继续操作使用`,
        use_online:`使用此online程序`,
        use_download:`使用此桌面程序`,
        use_immediately:`去使用`,
        Use_in_browser:`浏览器中使用`,
        win_desktop:`Windows 程序`,
        Mac_desktop:`Mac 程序`,
    },
    'zh-tw':{
        overtime_1:`錯誤代碼：`,
        overtime_2:`，請重試`,
        isnetwork:`互聯網錯誤。請檢查並重試`,
        email_placeholder:`電子郵件`,
        email_empty:`請輸入郵箱`,
        email_not_valid:`電子郵件無效`,
        email_not_valid_1:`請輸入您的電子郵件`,
        email_not_valid_2:`電子郵件無效，請使用其他地址。`,
        email_not_valid_3:`沒有電子郵件輸入`,
        password_placeholder:`密碼`,
        password_empty:`請輸入密碼`,
        password_not_valid:`帳戶或密碼不正確`,
        password_not_valid_1:`需要8個字符以上的密碼`,
        password_not_valid_2:`請創建密碼`,
        password_placeholder_1:`創建你的密碼`,
        password_placeholder_2:`確認你的密碼`,
        password_placeholder_3:`創建新密碼`,
        password_placeholder_4:`確認新密碼`,
        password_placeholder_5:`輸入舊密碼`,
        copy_password_empty:`請確認密碼`,
        copy_password_not_valid:`請確認您的密碼`,
        copy_passwords_inconsistent:`您的密碼確認不匹配`,
        code_empty:`請輸入驗證碼`,
        code_not_valid:`無效驗證碼`,
        code_placeholder:`驗證碼`,
        not_received_code:`如果您的郵箱長時間未收到驗證碼，請重新獲取驗證碼。`,
        get_first_code:`請先獲取驗證碼。`,
        connect_your_email_placeholder:`連接您的電子郵件`,
        last_name_placeholder:`請輸入您的姓氏`,
        first_name_placeholder:`請輸入您的名字`,
        address_placeholder:`請輸入您的地址`,
        no_code_text:`我們已發送驗證碼。請輸入您的驗證碼。<span class='tips'>沒有收到驗證碼？`,
        no_code_text_1:`1.請確保郵箱地址有效且可以接收郵件。`,
        no_code_text_2:`2.由於系統自動發送的郵件，可能會被標記為垃圾郵件或垃圾郵件。請檢查電子郵件是否在垃圾箱文件夾中。`,
        no_code_text_3:`3.無法解決您的問題？`,
        no_code_text_3_span:`然後點擊這里聯系我們。`,
        order_no:`您還沒有購買任何產品。任何問題，請<a href="https://zh-tw.aiseesoft.com/store/">立即購買</a>或<a href="https://zh-tw.aiseesoft.com/contact.html">聯繫我們</a>`,
        error_24901:`當前帳戶沒有電子郵件鏈接，無法找到訂單。請連接電子郵件。`,
        user_guide:`使用指南>>`,
        download:`下載`,
        order_number:`訂單號`,
        Refund:`退款`,
        Disabled:`已禁用`,
        Normal:`普通的`,
        Modify:`調整`,
        Modify_1:`修改>>`,
        Connect:`連接`,
        unlink_success:`成功解除關聯`,
        connect_success:`連接成功`,
        feedback_title:`感謝您的反饋意見。請留下您的問題，我們將在 24 小時內回复您。`,
        feedback_thank_you:`謝謝！<br />您的反饋已成功提交。`,
        feedback_email:`在此處輸入您的電子郵件！`,
        feedback_content:`在這裡留下您遇到的任何問題或建議。`,
        feedback_submit:`提交`,
        form_contents:`您尚未輸入任何說明。請輸入並再次提交。`,
        old_password:`請輸入舊密碼`,
        new_password:`請創建一個新密碼`,
        old_new_password:`新密碼不能與舊密碼相同`,
        incorrect_password:`密碼錯誤`,
        delete_no:`立即刪除`,
        Caps:`大寫鎖定已開啟`,
        Get:`得到`,
        Done:`完畢`,
        error_20001:`錯誤：20001。請重新登錄。`,
        error_20002:`錯誤：20002。請重新登錄。`,
        error_20003:`錯誤：20003。請重新登錄。`,
        error_20004:`請求失敗（錯誤代碼：20004）。請再試一次。`,
        error_20005:`登錄會話已過期（錯誤：20005）。請重新登錄。`,
        error_20006:`請求失敗（錯誤代碼：20006）。請再試一次。`,
        error_20007:`登錄會話已過期（錯誤：20007）。請重新登錄。`,
        error_20008:`登錄會話已過期（錯誤：20008）。請重新登錄。`,
        error_20009:`登錄會話已過期（錯誤：20009）。請重新登錄。`,
        error_20101:`請輸入您的郵箱（錯誤代碼：20101）`,
        error_20102:`電子郵件無效（錯誤代碼：20102）`,
        error_20103:`請求失敗（錯誤代碼：20103）。請再試一次`,
        error_20104:`郵箱已被使用，請<a href="https://account.aiseesoft.com/login">登錄</a>或註冊新郵箱`,
        error_20105:`請求失敗（錯誤代碼：20105）。請再試一次`,
        error_20106:`發送郵件失敗，請重試`,
        error_20201:`請輸入您的郵箱（錯誤代碼：20201）`,
        error_20202:`請輸入您的密碼（錯誤代碼：20202）`,
        error_20203:`請輸入驗證碼（錯誤碼：20203）`,
        error_20204:`電子郵件無效（錯誤代碼：20204）`,
        error_20205:`需要8個字符以上的密碼（錯誤代碼：20205）`,
        error_20206:`請求失敗（錯誤代碼：20206）。請再試一次`,
        error_20207:`無效驗證碼`,
        error_20208:`請求失敗（錯誤代碼：20208）。請再試一次`,
        error_20209:`請求失敗（錯誤代碼：20209）。請再試一次`,
        error_20301:`請輸入您的郵箱（錯誤代碼：20301）`,
        error_20302:`錯誤：20302。請聯繫我們`,
        error_20303:`電子郵件無效（錯誤代碼：20303）`,
        error_20304:`請求失敗（錯誤代碼：20304）。請再試一次`,
        error_20305:`帳戶不存在。請重新輸入或先<a href="https://account.aiseesoft.com/register">創造它</a>。`,
        error_20306:`還沒有密碼。使用<a href="https://account.aiseesoft.com/passwordless-login">無密碼登錄</a>或<a href="create-password">設置密碼</a>並登錄。`,
        error_20308:`請求失敗（錯誤代碼：20308）。請再試一次`,
        error_20401:`註銷失敗（錯誤代碼：20401）。請再試一次`,
        error_20501:`請輸入您的郵箱（錯誤代碼：20501）`,
        error_20502:`電子郵件無效（錯誤代碼：20502）`,
        error_20503:`請求失敗（錯誤代碼：20503）。請再試一次`,
        error_20504:`無法發送電子郵件。請再試一次。`,
        error_20601:`請輸入您的郵箱（錯誤代碼：20601）`,
        error_20602:`請輸入驗證碼（錯誤碼：20602）`,
        error_20603:`電子郵件無效（錯誤代碼：20603）`,
        error_20604:`請求失敗（錯誤代碼：20604）。請再試一次`,
        error_20606:`請求失敗（錯誤代碼：20606）。請再試一次`,
        error_20607:`請求失敗（錯誤代碼：20607）。請再試一次`,
        error_20608:`請求失敗（錯誤代碼：20608）。請再試一次`,
        error_20701:`請輸入您的郵箱（錯誤代碼：20701）`,
        error_20702:`電子郵件無效（錯誤代碼：20702）`,
        error_20703:`請求失敗（錯誤代碼：20703）。請再試一次`,
        error_20704:`帳戶不存在。請重新輸入或先<a href="https://account.aiseesoft.com/register">創造它</a>。`,
        error_20705:`請求失敗（錯誤代碼：20705）。請再試一次`,
        error_20706:`無法發送電子郵件。請再試一次`,
        error_20801:`請輸入您的郵箱（錯誤代碼：20801）`,
        error_20802:`錯誤：20802。請聯繫我們`,
        error_20803:`請輸入驗證碼（錯誤碼：20803）`,
        error_20804:`電子郵件無效（錯誤代碼：20804）`,
        error_20805:`需要8個字符以上的密碼（錯誤代碼：20805）`,
        error_20806:`請求失敗（錯誤代碼：20806）。請再試一次`,
        error_20808:`請求失敗（錯誤代碼：20808）。請再試一次`,
        error_20901:`請求失敗（錯誤代碼：20901）。請再試一次`,
        error_20902:`請求失敗（錯誤代碼：20902）。請再試一次`,
        error_21000:`更改已保存`,
        error_21001:`沒有提交信息（錯誤代碼：21001）`,
        error_21002:`請求失敗（錯誤代碼：21002）。請再試一次`,
        error_21101:`請輸入您的郵箱（錯誤代碼：21101）`,
        error_21102:`電子郵件無效（錯誤代碼：21102）`,
        error_21103:`請求失敗（錯誤代碼：21103），請重試`,
        error_21104:`郵箱已連接，請使用新郵箱`,
        error_21105:`請求失敗（錯誤代碼：21105），請重試`,
        error_21106:`無法發送電子郵件。請再試一次`,
        error_21201:`請輸入您的郵箱（錯誤代碼：21201）`,
        error_21202:`請輸入驗證碼（錯誤碼：21202）`,
        error_21203:`電子郵件無效（錯誤代碼：21203）`,
        error_21204:`錯誤：21204。請聯繫我們`,
        error_21205:`錯誤：21205。請聯繫我們`,
        error_21206:`需要8位以上的密碼（錯誤代碼：21206）`,
        error_21207:`請求失敗（錯誤代碼：21207）。請再試一次`,
        error_21209:`請求失敗（錯誤代碼：21209）。請再試一次`,
        error_21301:`請輸入舊密碼（錯誤代碼：21301）`,
        error_21302:`請創建一個新密碼（錯誤代碼：21302）`,
        error_21303:`新密碼不能與舊密碼相同。 （錯誤：21303）`,
        error_21304:`需要8個字符以上的密碼（錯誤代碼：21304）`,
        error_21306:`請求失敗（錯誤代碼：21306）。請再試一次`,
        error_21402:`請求失敗（錯誤代碼：21402）。請再試一次`,
        error_21403:`發送驗證碼失敗。請重新發送`,
        error_21500:`該帳戶已被刪除`,
        error_21501:`請輸入驗證碼（錯誤碼：21501）`,
        error_21502:`登錄會話已過期（錯誤：21502）。請重新登錄。`,
        error_21503:`請求失敗（錯誤代碼：21503）。請再試一次`,
        error_21505:`請求失敗（錯誤代碼：21505），請重試`,
        error_21601:`錯誤：20601。請聯繫我們`,
        error_21602:`驗證無效（錯誤：20602）。請再試一次。`,
        error_21603:`錯誤：20603。請重試`,
        error_21604:`請求失敗（錯誤代碼：21604）。請再試一次`,
        error_21606:`請求失敗（錯誤代碼：21606）。請再試一次`,
        error_21611:`請求失敗（錯誤代碼：21611）。請再試一次`,
        error_21801:`錯誤：21801。請聯繫我們`,
        error_21802:`請求失敗（錯誤：21802）。請再試一次`,
        error_21803:`錯誤：21803。請重試`,
        error_21804:`請求失敗（錯誤代碼：21804）。請再試一次`,
        error_21806:`錯誤：21806。請重試`,
        error_21807:`錯誤：21807。請聯繫我們`,
        error_21808:`錯誤：21808。請聯繫我們`,
        error_21809:`錯誤：21809。請聯繫我們`,
        error_21810:`錯誤：21810。請聯繫我們`,
        error_21811:`錯誤：21811。請聯繫我們`,
        error_21812:`錯誤：21812。請聯繫我們`,
        error_21813:`請求失敗（錯誤代碼：21813）。請再試一次`,
        error_21814:`錯誤：21814。請聯繫我們`,
        error_21815:`請求失敗（錯誤代碼：21815）。請再試一次`,
        error_21816:`錯誤：21816。請聯繫我們`,
        error_21817:`錯誤：21817。請聯繫我們`,
        error_21818:`錯誤：21818。請聯繫我們`,
        error_21819:`請求失敗（錯誤代碼：21819）。請再試一次`,
        error_21820:`錯誤：21820。請聯繫我們`,
        error_21821:`錯誤：21821。請聯繫我們`,
        error_21822:`錯誤：21822。請聯繫我們`,
        error_21823:`請求失敗（錯誤代碼：21823）。請再試一次`,
        error_21824:`請求失敗（錯誤代碼：21824）。請再試一次`,
        error_21825:`請求失敗（錯誤代碼：21825）。請再試一次`,
        error_21826:`請求失敗（錯誤代碼：21826）。請再試一次`,
        error_21828:`錯誤：21828。請聯繫我們`,
        error_21901:`錯誤：21901。請聯繫我們`,
        error_21902:`請求失敗（錯誤代碼：21902）。請再試一次`,
        error_21903:`帳戶狀態已更改（錯誤代碼：21903），請刷新頁面並重試`,
        error_21904:`錯誤：21904。請重試`,
        error_21905:`錯誤：21905。請重試`,
        error_21906:`請求失敗（錯誤代碼：21906）。請再試一次`,
        error_21907:`Google 帳戶已關聯到其他帳戶`,
        error_21908:`請求失敗（錯誤代碼：21908）。請再試一次`,
        error_22001:`請求失敗（錯誤代碼：22001）。請再試一次`,
        error_22002:`取消鏈接失敗，沒有額外登錄`,
        error_22003:`請求失敗（錯誤代碼：22003）。請再試一次`,
        error_22101:`錯誤：22101。請聯繫我們`,
        error_22102:`帳戶狀態已更改（錯誤代碼：22102），請刷新頁面並重試`,
        error_22103:`請求失敗（錯誤代碼：22103）。請再試一次`,
        error_22104:`帳戶狀態已更改（錯誤代碼：22104），請刷新頁面並重試`,
        error_22105:`錯誤：22105。請重試`,
        error_22106:`錯誤：22106。請重試`,
        error_22107:`錯誤：22107。請聯繫我們`,
        error_22108:`請求失敗（錯誤代碼：22108）。請再試一次`,
        error_22201:`錯誤：22201。請聯繫我們`,
        error_22202:`驗證無效（錯誤：22202）。請再試一次。`,
        error_22203:`錯誤：22203。請重試”`,
        error_22204:`請求失敗（錯誤代碼：22204）。請再試一次`,
        error_22206:`請求失敗（錯誤代碼：22206）。請再試一次`,
        error_22401:`錯誤：22401。請聯繫我們`,
        error_22402:`驗證無效（錯誤：22402）。請再試一次。`,
        error_22403:`錯誤：22403。請重試`,
        error_22404:`請求失敗（錯誤代碼：22404）。請再試一次`,
        error_22405:`Facebook 帳戶已鏈接到其他電子郵件`,
        error_22406:`錯誤：22406。請重試`,
        error_22407:`錯誤：22407。請聯繫我們`,
        error_22408:`錯誤：22408。請聯繫我們`,
        error_22409:`錯誤：22409。請聯繫我們`,
        error_22410:`錯誤：224010。請聯繫我們`,
        error_22411:`錯誤：224011。請聯繫我們`,
        error_22412:`錯誤：224012。請聯繫我們`,
        error_22413:`錯誤：22413。請聯繫我們`,
        error_22414:`請求失敗（錯誤代碼：22414）。請再試一次`,
        error_22415:`錯誤：22415。請聯繫我們`,
        error_22416:`錯誤：22416。請聯繫我們`,
        error_22417:`錯誤：22417。請聯繫我們`,
        error_22418:`請求失敗（錯誤代碼：22418）。請再試一次`,
        error_22419:`錯誤：22419。請聯繫我們`,
        error_22420:`錯誤：22420。請聯繫我們`,
        error_22421:`錯誤：22421。請聯繫我們`,
        error_22422:`請求失敗（錯誤代碼：22422）。請再試一次`,
        error_22423:`請求失敗（錯誤代碼：22423）。請再試一次`,
        error_22424:`請求失敗（錯誤代碼：22424）。請再試一次`,
        error_22425:`請求失敗（錯誤代碼：22425）。請再試一次`,
        error_20098:`錯誤代碼：20098。如果您在隱私模式下瀏覽，請切換到普通模式並重試。`,
        error_22298:`對 Google 的請求失敗（錯誤：22298）。請再試一次。`,
        error_22498:`請求 Facebook 失敗（錯誤：22498）。請再試一次。`,
        error_24902:`請求失敗（錯誤代碼：24902）。請再試一次`,
        error_24903:`請求失敗（錯誤代碼：24903）。請再試一次`,
        error_24904:`請求失敗（錯誤代碼：24904）。請再試一次`,
        error_24905:`請求失敗（錯誤代碼：24905）。請再試一次`,
        login_title:`登錄 Aiseesoft`,
        password_placeholder:`密碼`,
        log_in:`登錄`,
        no_account:`沒有賬戶？`,
        create_it:`創造它`,
        or_log_in_with:`或登錄`,
        passwordless_login:`無密碼登錄`,
        log_in_done:`登錄完成`,
        three_rd_account_connect_info:`恭喜！您已成功登錄。現在您可以連接一個用於將來登錄的電子郵件帳戶。`,
        connect_now:`立即連接`,
        see_my_account:`查看我的帳戶`,
        three_rd_login_merge_account:`第三方賬號郵箱已經註冊，要直接用這個郵箱連接登錄嗎？`,
        connect_log_in:`連接和登錄`,
        create_an_account:`創建一個帳戶`,
        connect_account:`連接帳戶`,
        connect_account_info:`連接後，您可以使用此帳戶登錄。`,
        back_to_log_in:`回到登入`,
        create_password:`創建密碼`,
        create_now:`立即創建`,
        password_login_subtitle:`使用電子郵件無密碼登錄`,
        account_login:`帳號登錄`,
        rights:`創建此帳戶即表示您同意<a href="https://zh-tw.aiseesoft.com/company/terms-and-conditions.html">服務條款</a>和<a href="https://zh-tw.aiseesoft.com/privacy-policy.html">隱私政策</a>`,
        passwordless_login_done:`無密碼登錄完成`,
        passwordless_login_info:`恭喜，您已成功完成無密碼登錄。您可以為此帳戶創建密碼，並在以後使用該帳戶和密碼登錄。 <a href="/create-password" style="display: initial;">立即創建</a>`,
        sign_up:`報名`,
        register_info:`創建您的帳戶`,
        reset_now:`立即重置`,
        forgot_password:`忘記密碼`,
        reset_password_subtitle:`使用您的帳戶電子郵件重置密碼`,
        plan_products:`計劃和產品`,
        nick_name:`用戶名`,
        email:`電子郵件`,
        my_products:`我的產品`,
        my_orders:`我的訂單`,
        unlink:`取消鏈接`,
        link:`關聯`,
        connected_accounts:`關聯賬戶`,
        last_name:`姓`,
        first_name:`名`,
        Gender:`性別`,
        Birth:`出生`,
        Month:`月`,
        Year:`年`,
        Country_Region:`國家/地區`,
        Address:`地址`,
        Save:`節省`,
        Date:`日期`,
        Male:`男性`,
        Female:`女性`,
        Unspecified:`未指定`,
        Security:`安全`,
        change_password:`更改密碼`,
        change_now:`立即更改`,
        connect_email:`連接電子郵件`,
        delete_account:`刪除帳戶`,
        delete_account_info:`當您的帳戶被刪除時，與您的帳戶相關聯的計劃和產品中的所有數據都將被永久刪除，並且您可能無法恢復這些數據。我們建議您謹慎行事。`,
        Delete:`刪除`,
        Logout:`登出`,
        my_profile:`我的簡歷`,
        guides_faqs:`指南和常見問題`,
        More:`更多的`,
        guides:`指南`,
        register:`登記`,
        hot_faq:`熱門常見問題`,
        Contents:`內容：`,
        contact_us:`聯繫我們>>`,
        plan:`計劃`,
        unregistered:`未註冊`,
        buy_more:`買多點`,
        buy_again:`再買`,
        buy_now:`立即購買`,
        free_no_limit:`免費且無限制`,
        expired:`已到期`,
        lifetime:`壽命`,
        remain:`保持`,
        day_s:`天）`,
        error_24801:`請求失敗（錯誤代碼：24801）。請再試一次`,
        no_app_found:`沒有找到應用！<a href="/">刷新</a>或<a href="https://zh-tw.aiseesoft.com/">去官網</a>`,
        get_more:`獲取更多>>`,
        edit_photo:`編輯照片`,
        select_photo:`選擇照片`,
        change_photo:`更改照片`,
        cancel:`取消`,
        hide_password:`隱藏密碼`,
        show_password:`顯示密碼`,
        zoom_in:`放大`,
        zoom_out:`縮小`,
        rotate:`旋轉`,
        horizontal_flip:`水平翻轉`,
        vertical_flip:`垂直翻轉`,
        country:`國家`,
        country_1:`選擇您的國家/地區`,
        country_2:`奧蘭島`,
        country_3:`阿富汗`,
        country_4:`阿爾巴尼亞`,
        country_5:`阿爾及利亞`,
        country_6:`美屬薩摩亞`,
        country_7:`安道爾`,
        country_8:`安哥拉`,
        country_9:`安圭拉`,
        country_10:`南極洲`,
        country_11:`安提瓜和巴布達`,
        country_12:`阿根廷`,
        country_13:`亞美尼亞`,
        country_14:`阿魯巴島`,
        country_15:`澳大利亞`,
        country_16:`奧地利`,
        country_17:`阿塞拜疆`,
        country_18:`巴林`,
        country_19:`孟加拉國`,
        country_20:`巴巴多斯`,
        country_21:`白俄羅斯`,
        country_22:`比利時`,
        country_23:`伯利茲`,
        country_24:`貝寧`,
        country_25:`百慕大`,
        country_26:`不丹`,
        country_27:`玻利維亞`,
        country_28:`波斯尼亞和黑塞哥維那`,
        country_29:`博茨瓦納`,
        country_30:`布韋島`,
        country_31:`巴西`,
        country_32:`英屬印度洋領地`,
        country_33:`英屬維爾京群島`,
        country_34:`文萊`,
        country_35:`保加利亞`,
        country_36:`布基納`,
        country_37:`布隆迪`,
        country_38:`柬埔寨`,
        country_39:`喀麥隆`,
        country_40:`加拿大`,
        country_41:`佛得角`,
        country_42:`加勒比海荷蘭`,
        country_43:`開曼群島`,
        country_44:`中非共和國`,
        country_45:`乍得`,
        country_46:`智利`,
        country_47:`中國`,
        country_48:`聖誕島`,
        country_49:`科科斯（基林）群島`,
        country_50:`哥倫比亞`,
        country_51:`庫克群島`,
        country_52:`哥斯達黎加`,
        country_53:`“科特迪瓦”`,
        country_54:`克羅地亞`,
        country_55:`古巴`,
        country_56:`塞浦路斯`,
        country_57:`捷克共和國`,
        country_58:`剛果民主共和國`,
        country_59:`丹麥`,
        country_60:`吉布提`,
        country_61:`多米尼加`,
        country_62:`多明尼加共和國`,
        country_63:`厄瓜多爾`,
        country_64:`埃及`,
        country_65:`薩爾瓦多`,
        country_66:`赤道幾內亞`,
        country_67:`厄立特里亞`,
        country_68:`愛沙尼亞`,
        country_69:`埃塞俄比亞`,
        country_70:`福克蘭群島`,
        country_71:`法羅群島`,
        country_72:`密克羅尼西亞聯邦`,
        country_73:`斐濟`,
        country_74:`芬蘭`,
        country_75:`法國`,
        country_76:`法屬圭亞那`,
        country_77:`法屬波利尼西亞`,
        country_78:`法屬南部領地`,
        country_79:`加蓬`,
        country_80:`岡比亞`,
        country_81:`喬治亞州`,
        country_82:`德國`,
        country_83:`加納`,
        country_84:`直布羅陀`,
        country_85:`英國（英國；英格蘭）`,
        country_86:`希臘`,
        country_87:`格陵蘭`,
        country_88:`格林納達`,
        country_89:`瓜德羅普島`,
        country_90:`關島`,
        country_91:`危地馬拉`,
        country_92:`根西島`,
        country_93:`幾內亞`,
        country_94:`幾內亞比紹`,
        country_95:`圭亞那`,
        country_96:`海地`,
        country_97:`赫德島和麥克唐納群島`,
        country_98:`洪都拉斯`,
        country_99:`香港`,
        country_100:`匈牙利`,
        country_101:`冰島`,
        country_102:`印度`,
        country_103:`印度尼西亞`,
        country_104:`伊朗`,
        country_105:`伊拉克`,
        country_106:`愛爾蘭`,
        country_107:`馬恩島`,
        country_108:`以色列`,
        country_109:`意大利`,
        country_110:`牙買加`,
        country_111:`日本`,
        country_112:`球衣`,
        country_113:`約旦`,
        country_114:`哈薩克斯坦`,
        country_115:`肯尼亞`,
        country_116:`基里巴斯`,
        country_117:`科威特`,
        country_118:`吉爾吉斯斯坦`,
        country_119:`老撾`,
        country_120:`拉脫維亞`,
        country_121:`黎巴嫩`,
        country_122:`萊索托`,
        country_123:`利比里亞`,
        country_124:`利比亞`,
        country_125:`列支敦士登`,
        country_126:`立陶宛`,
        country_127:`盧森堡`,
        country_128:`澳門`,
        country_129:`馬達加斯加`,
        country_130:`馬拉維`,
        country_131:`馬來西亞`,
        country_132:`馬爾代夫`,
        country_133:`馬里`,
        country_134:`馬耳他`,
        country_135:`馬紹爾群島`,
        country_136:`馬提尼克`,
        country_137:`毛里塔尼亞`,
        country_138:`毛里求斯`,
        country_139:`馬約特島`,
        country_140:`墨西哥`,
        country_141:`摩爾多瓦`,
        country_142:`摩納哥`,
        country_143:`蒙古`,
        country_144:`黑山`,
        country_145:`蒙特塞拉特`,
        country_146:`摩洛哥`,
        country_147:`莫桑比克`,
        country_148:`緬甸（緬甸）`,
        country_149:`納米比亞`,
        country_150:`瑙魯`,
        country_151:`尼泊爾`,
        country_152:`荷蘭`,
        country_153:`新喀裡多尼亞`,
        country_154:`新西蘭`,
        country_155:`尼加拉瓜`,
        country_156:`尼日爾`,
        country_157:`尼日利亞`,
        country_158:`紐埃`,
        country_159:`諾福克島`,
        country_160:`北朝鮮`,
        country_161:`北馬里亞納群島`,
        country_162:`挪威`,
        country_163:`阿曼`,
        country_164:`巴基斯坦`,
        country_165:`帕勞`,
        country_166:`巴勒斯坦領土`,
        country_167:`巴拿馬`,
        country_168:`巴布亞新幾內亞`,
        country_169:`巴拉圭`,
        country_170:`秘魯`,
        country_171:`皮特凱恩群島`,
        country_172:`波蘭`,
        country_173:`葡萄牙`,
        country_174:`波多黎各`,
        country_175:`卡塔爾`,
        country_176:`馬其頓共和國 (FYROM)`,
        country_177:`剛果共和國`,
        country_178:`團圓`,
        country_179:`羅馬尼亞`,
        country_180:`俄羅斯聯邦`,
        country_181:`盧旺達`,
        country_182:`聖巴泰勒米`,
        country_183:`聖馬丁（法國）`,
        country_184:`聖皮埃爾和密克隆`,
        country_185:`薩摩亞`,
        country_186:`聖馬力諾`,
        country_187:`聖多美和普林西比`,
        country_188:`沙特阿拉伯`,
        country_189:`塞內加爾`,
        country_190:`塞爾維亞`,
        country_191:`塞舌爾`,
        country_192:`塞拉利昂`,
        country_193:`新加坡`,
        country_194:`斯洛伐克`,
        country_195:`斯洛文尼亞`,
        country_196:`所羅門群島`,
        country_197:`索馬里`,
        country_198:`南非`,
        country_199:`南喬治亞島和南桑威奇島`,
        country_200:`韓國`,
        country_201:`南蘇丹`,
        country_202:`西班牙`,
        country_203:`斯里蘭卡`,
        country_204:`聖赫勒拿島和依賴`,
        country_205:`聖基茨和尼維斯`,
        country_206:`聖盧西亞`,
        country_207:`聖文森特和格林納丁斯`,
        country_208:`蘇丹`,
        country_209:`蘇里南`,
        country_210:`斯威士蘭`,
        country_211:`瑞典`,
        country_212:`瑞士`,
        country_213:`敘利亞`,
        country_214:`台灣`,
        country_215:`塔吉克斯坦`,
        country_216:`坦桑尼亞`,
        country_217:`模板：國家數據 SJM Svalbard`,
        country_218:`泰國`,
        country_219:`巴哈馬`,
        country_220:`科摩羅`,
        country_221:`菲律賓人`,
        country_222:`東帝汶（東帝汶）`,
        country_223:`多哥`,
        country_224:`托克勞`,
        country_225:`湯加`,
        country_226:`特立尼達和多巴哥`,
        country_227:`突尼斯`,
        country_228:`火雞`,
        country_229:`土庫曼斯坦`,
        country_230:`特克斯和凱科斯群島`,
        country_231:`圖瓦盧`,
        country_232:`烏干達`,
        country_233:`烏克蘭`,
        country_234:`阿拉伯聯合酋長國`,
        country_235:`美國本土外小島嶼`,
        country_236:`美利堅合眾國（美國）`,
        country_237:`美屬維爾京群島`,
        country_238:`烏拉圭`,
        country_239:`烏茲別克斯坦`,
        country_240:`瓦努阿圖`,
        country_241:`梵蒂岡城（教廷）`,
        country_242:`委內瑞拉`,
        country_243:`越南`,
        country_244:`瓦利斯和富圖納`,
        country_245:`西撒哈拉`,
        country_246:`也門`,
        country_247:`贊比亞`,
        country_248:`津巴布韋`,
        google_login:`使用 Google 登錄`,
        footer:`版權所有 © 2024 Aiseesoft Studio。版權所有。`,
        State:`狀態`,
        Activation_code:`激活碼`,
        Question:`列出您已登錄的所有應用程序`,
        Copy_complete:`複製完成`,
        store:`店鋪`,
        resource:`資源`,
        support:`支持`,
        video_converter:`視頻轉換器`,
        multimedia:`多媒體`,
        data_recovery:`數據恢復`,
        utility:`公用事業`,
        free_tools:`免費工具`,
        for_windows:`對於 Windows`,
        for_mac:`對於 Mac`,
        pdf_solutions:`PDF解決方案`,
        data_manager:`數據管理器`,
        other_tools:`其他工具`,
        free_online_solutions:`免費在線解決方案`,
        free_online_tools:`免費在線工具`,
        desktop_freeware:`桌面免費軟件`,
        product_1:`視頻轉換器終極版`,
        product_2:`總視頻轉換器`,
        product_3:`MTS 轉換器`,
        product_4:`4K轉換器`,
        product_5:`MXF 轉換器`,
        product_6:`3D轉換器`,
        product_7:`Mac Video Converter Ultimate`,
        product_8:`Mac 視頻轉換器`,
        product_9:`適用於 Mac 的 MTS 轉換器`,
        product_10:`適用於 Mac 的 4K 轉換器`,
        product_11:`適用於 Mac 的 MXF 轉換器`,
        product_12:`適用於 Mac 的 3D 轉換器`,
        product_13:`藍光播放器`,
        product_14:`視頻增強器`,
        product_15:`視頻編輯`,
        product_16:`幻燈片製作者`,
        product_17:`藍光創作者`,
        product_18:`Burnova - DVD Creator Pro`,
        product_19:`DVD 創作者`,
        product_20:`Mac 藍光播放器`,
        product_21:`Mac 視頻增強器`,
        product_22:`Mac 視頻編輯器`,
        product_23:`適用於 Mac 的 DVD 製作器`,
        product_24:`適用於 Mac 的 MP4 轉換器`,
        product_25:`視窗數據恢復`,
        product_26:`FoneLab iPhone 數據恢復`,
        product_27:`FoneLab iOS 系統恢復`,
        product_28:`FoneLab iOS 數據備份 &amp;恢復`,
        product_29:`FoneLab Android 數據恢復`,
        product_30:`破碎的Android數據提取`,
        product_31:`Android 數據備份 &amp;恢復`,
        product_32:`Mac 數據恢復`,
        product_33:`Mac FoneLab iPhone 數據恢復`,
        product_34:`Mac FoneLab iOS 系統恢復`,
        product_35:`Mac FoneLab iOS 數據備份 &amp;恢復`,
        product_36:`Mac FoneLab Android 數據恢復`,
        product_37:`Mac Android 數據備份 &amp;恢復`,
        product_38:`PDF Converter Ultimate`,
        product_39:`適用於 Mac 的 PDF Converter Ultimate`,
        product_40:`PDF合併`,
        product_41:`Mac PDF 到 ePub 轉換器`,
        product_42:`Mac 清潔器`,
        product_43:`FoneTrans`,
        product_44:`適用於 iOS 的 WhatsApp 傳輸`,
        product_45:`手機同步`,
        product_46:`FoneEraser`,
        product_47:`iPhone清潔劑`,
        product_48:`PPT到視頻轉換器`,
        product_49:`HEIC轉換器`,
        product_50:`iPhone 解鎖器`,
        product_51:`免費在線視頻轉換器`,
        product_52:`免費在線音頻轉換器`,
        product_53:`免費在線視頻壓縮器`,
        product_54:`免費在線視頻合併`,
        product_55:`免費 HEIC 轉換器`,
        product_56:`免費圖像壓縮器`,
        product_57:`免費水印去除劑`,
        product_58:`免費在線 PDF 壓縮器`,
        product_59:`免費在線圖像升頻器`,
        product_60:`在線免費背景卸妝`,
        product_61:`免費在線圖像轉換器`,
        product_62:`在線免費 PDF PNG 轉換器`,
        product_63:`免費視頻轉換器`,
        product_64:`免費視頻轉 GIF 轉換器`,
        product_65:`免費安卓數據恢復`,
        product_66:`幻燈片製作工具`,
        product_67:`免費視頻編輯器`,
        product_68:`免費的 PDF 查看器`,
        product_69:`屏幕錄像機`,
        product_70:`Mac 屏幕錄像機`,
        product_71:`免費在線屏幕錄像機`,
        product_72:`免費在線錄音機`,       
        product_73:`手機鏡像`,
        product_74:`AnyCoord - 位置轉換器`,
        product_75:`iPhone 密碼管理器`,
        product_76:`免費在線音頻壓縮器`,
        product_77:`免費在線視頻修剪器`,
        product_78:`免費在線視頻裁剪器`,
        product_79:`免費在線視頻旋轉器`,
        product_80:`免費在線視頻 Looper`,
        product_81:`免費 PDF JPG 在線轉換器`,
        product_81:`免費的 JPG Word 在線轉換器`, 
        category_1:`照片編輯`,
        category_2:`視頻編輯`,
        category_3:`橡皮`,
        category_4:`媒體記錄器`,
        posted_by:`發表者`,
        to:`到`,
        author_1:`阿什莉·梅`,
        author_2:`珍妮瑞恩`,
        author_3:`妮可瓊斯`,
        author_4:`阿曼達·布朗`,
        article_1:`如何有效去除圖片中的文字`,
        article_2:`如何在不降低質量的情況下將圖像大小（以 KB 為單位）增加到 MB`,
        article_3:`如何在 Windows 和 Mac 上刪除 Getty Images 水印`,
        article_4:`免費在線將低分辨率圖像轉換為高分辨率的最佳方法`,
        article_5:`抖音指南 |這是在 TikTok 上製作視頻拼貼畫的方法`,
        article_6:`5 種簡單的方法來組合你應該知道的 VOB 文件`,
        article_7:`如何輕鬆升級視頻並獲得更高的分辨率`,
        article_8:`如何在 MacBook Pro/iMac/MacBook 等設備上合併 MP4 文件`,
        article_9:`在 iPhone 上刪除購買歷史記錄的 2 種簡單方法 [已解決]`,
        article_10:`如何從聊天和記憶中永久刪除 Snapchat 圖片`,
        article_11:`如何在 iPhone/iPad 上刪除 Spotlight 搜索建議和歷史記錄`,
        article_12:`[已解決] 如何使用 3 種簡單方法清除 iPhone 上的 Instagram 緩存`,
        article_13:`[已解決] 如何修復 QuickTime Player 在 Mac 上無法運行`,
        article_14:`如何在 Mac 上錄製系統音頻（Big Sur/Catalina/Mojave ...）`,
        article_15:`修復 PowerPoint 屏幕錄製不起作用的有效方法`,
        article_16:`如何使用 VLC 錄製音頻 [已解決]`,
        faq_title_1:`1.如何修復4104錯誤？`,
        faq_title_2:`2. 退款申請獲得批准後，我什麼時候可以拿回我的錢？`,
        faq_title_3:`3. 什麼是“1年訂閱”以及如何關閉“自動續訂服務”？`,
        faq_title_4:`4. Aiseesoft 產品下單後可以取消訂單嗎？`,
        faq_title_5:`5. 如何向您提交評論？`,
        faq_info_1:`這是由於您獲得的許可證有限。我們採用Single License，以防註冊碼被盜，防止註冊碼被違規使用。因此，您獲得的註冊代碼是單一許可證，只能在一台計算機上使用。如果您在多台計算機上使用它，則會出現錯誤 4104。在我們為您重置之前，您無法使用它。`,
        faq_info_2:`如果您更換或重新安裝計算機，我們可以為您重置。請聯繫客服：<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> 為您申請重置註冊碼。`,
        faq_info_3:`一旦您的退款請求獲得批准，通常需要 3 到 7 個工作日才能在您的帳戶中顯示退款。有時在假期期間，可能需要更長的時間，但預計不會超過 10 天。如果您在批准後 20 天仍未收到退款，請通過 <a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a> 聯繫我們以查看退款情況。`,
        faq_info_4:`自動續費服務是指，如果您購買了 1 年訂閱，則支付平台將在產品許可證到期時自動收取該金額（包括適用稅費）。但是付款平台（Swreg 或 2Checkout）會發信提醒您訂單將在到期前 7 天到期。該電子郵件還將包含有關如何關閉“自動續訂服務”的說明。`,
        faq_info_5:`由於訂單是自動處理的，所以不允許取消訂單。此外，免費試用版將使您更了解Aiseesoft 程序，並在購買前檢查它是否能滿足您的需求。`,
        faq_info_6:`如果您確實想獲得退款，請查看我們的<a href="https://zh-tw.aiseesoft.com/refund-policy.html">退款政策</a>。`,
        faq_info_7:`感謝您抽出時間和關注向我們提交評論。聯繫我們 (<a href="mailto:support@aiseesoft.com">support@aiseesoft.com</a>) 提交您的評論，我們將認真考慮您對我們產品更新的評論。`,
        change_password_success:`密碼修改成功`,
        successful_login_title:`登錄成功`,
        product_page:`產品頁面>>`,
        successful_login_info:`登錄完成。請關閉當前頁面並返回原始選項卡以繼續以下過程。當前頁面將在 5 秒後自動關閉。如果自動關閉或單擊“完成”按鈕關閉失敗，請直接關閉此選項卡。`,
        successful_login_info_firefox:`登錄完成。請關閉當前頁面並返回原始選項卡以繼續以下過程。`,
        my_account:`我的帳戶`,
        my_history:`我的歷史`,
        remove_watermark:`去除水印`,
        no_history:`沒有歷史`,
        history_all:`全選`,
        history_open:`打開`,
        history_down:`下載`,
        history_delete:`刪除`,
        history_clear:`清除無效`,
        images:`圖片）`,
        use_this_function:`使用此功能>>`,
        hd_downloading:`高清原圖下載好處：`,
        lifetimeRemaining:`剩餘壽命`,
        Remaining:`其餘的`,
        email_verification:`電子郵件驗證`,
        email_verification_info:`我們已將驗證信息發送至您的郵箱<span class="email"></span>，請完成驗證。驗證後，福利將自動同步。`,
        wrong_email:`錯誤的電子郵件地址？`,
        click_here_to_modify:`點擊這裡修改`,
        get_more_help:`獲得更多幫助？`,
        click_here:`點擊這裡`,
        email_verification_info_success:`恭喜您驗證了您的電子郵件帳戶。`,
        email_verification_info_error:`由於鏈接過期，驗證失敗。`,
        registration_succeeded:`註冊成功`,
        registration_succeeded_info_1:`恭喜！您已成功註冊。我們已將驗證消息發送到您的電子郵件 <span class="email"></span>，請完成驗證以使用此電子郵件的好處。`,
        registration_succeeded_info_2:`單擊“完成”返回主頁並使用該產品。`,
        registration_succeeded_info_3:`點擊“完成”關閉當前頁面，返回首頁進行以下操作。如果關閉失敗，請手動關閉選項卡。`,
        verify_email:`驗證郵件`,
        registered_email_not_verified:`註冊郵箱未驗證，請立即驗證。`,
        email_verification_time_1:`沒有收到驗證郵件？`,
        email_verification_time_2:`後`,
        email_verification_time_3:`點擊這裡重新發送`,
        error_26301:`錯誤代碼：26301，請聯繫我們`,
        error_26302:`錯誤代碼：26302，請聯繫我們`,
        error_26303:`電子郵件格式錯誤（錯誤代碼：26303）。請重新輸入`,
        error_26304:`密碼建議8位以上（錯誤代碼：26304）`,
        error_26305:`請求失敗（錯誤代碼：26305）。請重試`,
        error_26306:`郵箱已註冊，請<a href="https://account.aiseesoft.com/login">去登錄</a>`,
        error_26307:`請求失敗（錯誤代碼：26307）。請重試`,
        error_26308:`請求失敗（錯誤代碼：26308）。請重試`,
        error_26401:`錯誤代碼：26401，請重試`,
        error_26402:`郵箱已驗證（錯誤代碼：26402），請重試`,
        error_26403:`請求失敗（錯誤代碼：26403）。請重試`,
        error_26404:`請求失敗（錯誤代碼：26404）。請重試`,
        error_26501:`錯誤代碼：26501，請聯繫我們`,
        error_26502:`錯誤代碼：26502，請聯繫我們`,
        error_26503:`電子郵件格式錯誤（錯誤代碼：26503）。請重新輸入`,
        error_26504:`請求失敗（錯誤代碼：26504）。請重試`,
        error_26505:`郵箱尚未註冊，請<a href="https://account.aiseesoft.com/register">先註冊</a>`,
        error_26506:`電子郵件已通過驗證。`,
        error_26507:`請求失敗（錯誤代碼：26507）。請重試`,
        error_26508:`驗證失敗（錯誤碼：26508），請重試`,
        error_26509:`請求失敗（錯誤代碼：26509），請重試`,
        error_26510:`錯誤代碼：26510，請聯繫我們`,
        error_26601:`錯誤代碼：26601，請聯繫我們`,
        error_26602:`錯誤代碼：26602，請聯繫我們`,
        error_26603:`請求失敗（錯誤代碼：26603）。請重試`,
        error_26604:`錯誤代碼：26604，請聯繫我們`,
        error_26605:`錯誤代碼：26605，請聯繫我們`,
        error_26701:`錯誤代碼：26701，請聯繫我們`,
        error_26702:`請求失敗（錯誤代碼：26702）。請重試`,
        error_26703:`錯誤代碼：26703，請聯繫我們`,
        error_26704:`錯誤代碼：26704，請聯繫我們`,
        error_26705:`等待登錄（錯誤代碼：26705）。請重試`,
        no_cookie:`您在瀏覽器中開啟了阻止所有cookies功能，因此您無法登錄。請進入設置，勾選允許所有cookies。`,
        error_26801:`錯誤代碼：26801，請聯繫我們`,
        error_26802:`錯誤代碼：26802，請聯繫我們`,
        error_26803:`請求失敗（錯誤代碼：26803）。請重試`,
        error_26804:`請求失敗（錯誤代碼：26804）。請重試`,
        error_order:`請求失敗（錯誤代碼：27098），請重試！`,
        error_order1:`訂單查詢不完整（錯誤碼：`,
        error_order2:`），請刷新並重試。`,
        modify_email_title:`修改郵箱`,
        modify_email_info:`您可以使用修改後的電子郵件登錄您的帳戶。`,
        images_per:`每張圖片`,
        error_26101:`錯誤：26101。請聯繫我們`,
        error_26102:`錯誤：26102。請聯繫我們`,
        error_26103:`請求失敗（錯誤代碼：26103）。請再試一次`,
        error_26104:`錯誤代碼：26104，請重試`,
        error_26105:`錯誤代碼：26105，請重試`,
        error_26106:`刪除失敗（錯誤代碼：26106）。請再試一次`,
        error_26201:`錯誤：26201。請聯繫我們`,
        error_26202:`請求失敗（錯誤代碼：26202）。請再試一次`,
        error_26001:`錯誤：26001。請聯繫我們`,
        error_26002:`錯誤：26002。請聯繫我們`,
        error_26003:`錯誤：26003。請聯繫我們`,
        error_26004:`錯誤：26004。請聯繫我們`,
        error_26005:`請求失敗（錯誤代碼：26005）。請再試一次`,
        error_26006:`錯誤代碼：26006，請重試`,
        error_26008:`錯誤：26008。請聯繫我們`,
        go_to_the_home_page:`轉到主頁`,
        error_27101:`請求失敗（錯誤代碼：27101）。請重試`,
        error_27201:`錯誤代碼：27201，請聯繫我們`,
        error_27202:`錯誤代碼：27202，請重試`,
        error_27203:`請求失敗（錯誤代碼：27203）。請重試`,
        error_27204:`無效代碼（錯誤代碼：27204）。`,
        error_27205:`請求失敗（錯誤代碼：27205）。請重試`,
        error_27206:`請求失敗（錯誤代碼：27206）。請重試`,
        error_27207:`請求失敗（錯誤代碼：27207）。請重試`,
        no_history_found:`你還沒有使用任何工具！<a href="/">刷新</a>或<a href="https://zh-tw.aiseesoft.com/">去官網</a>`,
        error_25301:`錯誤：25301。請聯繫我們`,
        error_25302:`錯誤：25302。請聯繫我們`,
        error_25303:`請求失敗（錯誤代碼：25303）。請再試一次`,
        error_25304:`請求失敗（錯誤代碼：25304）。請再試一次`,
        error_25305:`請求失敗（錯誤代碼：25305）。請再試一次`,
        error_25306:`請求失敗（錯誤代碼：25306）。請再試一次`,
        image_upscaler_p:`無水印圖片下載權益：`,
        Available_for:`可用於：`,
        credit_per:`保存1張高清圖消耗%s點`,
        still_valid:`以下計劃依舊生效`,
        credit:`點數`,
        pc_3rd_info:`已登錄成功，請去應用中繼續操作使用`,
        use_online:`使用此在線程序`,
        use_download:`使用此桌面程式`,
        use_immediately:`去使用`,
        Use_in_browser:`在瀏覽器中使用`,
        win_desktop:`Windows 程序`,
        Mac_desktop:`Mac 程序`,
    }
};